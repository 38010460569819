import { Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { BaseApplication } from '@core/typings/application.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { REF_COMPONENT_TYPE_PREFIX } from '@core/typings/ui/reference-fields.typing';
import { FormAnswerValues, FormDefinitionComponent, ReportFieldDataOptions } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { TypeToken } from '@yourcause/common';
import { BaseFormComponent } from '../../base/base.component';
import { TypeSafeFormBuilder } from '@yourcause/common/core-forms';

@Component({
  selector: 'gc-form-report-field',
  templateUrl: './form-report-field.component.html',
  styleUrls: ['./form-report-field.component.scss']
})
export class FormReportFieldComponent extends BaseFormComponent<FormAnswerValues> implements OnInit, OnChanges {
  @Input() reportFieldDataOptions: ReportFieldDataOptions;
  @Input() displayType: AdHocReportingUI.DisplayTypes;
  @Input() notAutoSave: boolean;
  @Input() editable: boolean;
  @Input() isManagerForm: boolean;
  @Input() masked: boolean;

  row: AdHocReportingUI.ReportFieldResponseRow;
  DisplayTypes = AdHocReportingUI.DisplayTypes;
  column: AdHocReportingUI.ColumnImplementation;
  value: FormAnswerValues;
  $string = new TypeToken<string>();
  isNominationField = false;
  component: FormDefinitionComponent;

  constructor (
    private portalDeterminationService: PortalDeterminationService,
    formBuilder: TypeSafeFormBuilder,
    formBuilderService: FormBuilderService,
    public componentHelper: ComponentHelperService,
    @Optional() formGroupDir: FormGroupDirective,
    @Optional() renderer: FormRendererComponent<BaseApplication>
  ) {
    super(renderer, formGroupDir, formBuilder, formBuilderService, componentHelper);
  }

  get portal () {
    return this.portalDeterminationService.isManager ? 'manager' : 'portal';
  }

  get applicationId (): number  {
    return this.parentFields?.applicationId || null;
  }

  get reportFieldResponse (): AdHocReportingUI.ReportFieldResponseRow {
    return this.parentFields ? this.parentFields.reportFieldResponse : null;
  }

  ngOnInit () {
    super.ngOnInit();
    this.value = this.comp.value;
    this.isNominationField = this.componentHelper.isReportNominationField(
      this.comp.reportFieldDataOptions?.reportFieldObject
    );
    if (this.isNominationField) {
      const key = this.reportFieldDataOptions.reportFieldDisplay;
      this.component = {
        type: `${REF_COMPONENT_TYPE_PREFIX}${key}`,
        key: this.componentHelper.getNominatorReportKey(key),
        disabled: true,
        value: this.value,
        validate: {
          required: false
        }
      };
    } else {
      this.setFormGroup(this.value, []);
    }
  }

  ngOnChanges (changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }
}
