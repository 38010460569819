<div *ngIf="ready"
  [class.yc-layout]="!isModalView">
  <yc-panel *ngIf="!isModalView"
    [title]="'CONFIG:hdrAudiences' | ycI18n: 'Audiences'">
    <ng-template #panel>
      <div>
        <yc-button
          [primary]="true"
          [fontAwesomeIcon]="'plus'"
          [langKey]="'CONFIG:textCreateNewAudience'"
          [defaultText]="'Create new audience'"
          (onClick)="audienceModal()">
        </yc-button>
      </div>
    </ng-template>
  </yc-panel>
 
  <div [class.yc-layout-table]="!isModalView">
    <div *ngIf="isModalView">
      <yc-i18n
        [key]="'WORKFLOW:textSelectTheAudiencesYouWantToAddToTheSelectedWorkflowLevels'"
        [defaultValue]="'Select the audiences you want to add to the selected workflow levels.'">
      </yc-i18n>

    </div>
    <yc-table
      [key]="tableKey"
      [rows]="audiences"
      [topLevelFilters]="topLevelFilters"
      [hidePagination]="isModalView"
      [maxHeightBeforeScroll]="isModalView ? '50vh' : undefined"
      [hoverRows]="isModalView"
      [hideTableOptions]="isModalView"
      [hideTableFilters]="isModalView"
      [bulkOptions]="isModalView ? bulkOptions : undefined"
      [bulkSelected]="selectedAudiences"
      (bulkSelectedChanged)="selectedAudiences = $event;
        onSelectedAudiencesChange.emit(selectedAudiences)">

      <!-- Name -->
      <ng-container *ycTableColumn="'common:lblName';
        prop: 'name',
        defaultSortDirection: 'asc',
        filterType: 'text';
        let row;">
        <yc-button  
          class="d-block"
          [link]="true"
          [translatedText]="row.name"
          (onClick)="openFlyout(row)">
        </yc-button>
      </ng-container>

      <!-- Description -->
      <ng-container *ycTableColumn="'common:textDescription';
        prop: 'description',
        filterType: 'text';
        let row;">
        {{ row.description }}
      </ng-container>

      <!-- Members -->
      <ng-container *ycTableColumn="'CONFIG:textMembers';
        filterModalKey: 'CONFIG:textTotalMembers',
        prop: 'totalMembers',
        filterType: 'number';
        let row;">
        <yc-button *ngIf="row.totalMembers > 0"
          [link]="true"
          [translatedText]="'common:textNumberUsers' | ycI18n: '__number__ users': {
            number: row.totalMembers
          }"
          (onClick)="viewUsers(row)">
        </yc-button>
        <ng-container *ngIf="row.totalMembers === 0">
          <yc-i18n
            [key]="'common:textNumberUsers'"
            [context]="{
              number: row.totalMembers
            }"
            [defaultValue]="'__number__ users'">
          </yc-i18n>
        </ng-container>
      </ng-container>

      <!-- Created By -->
      <ng-container *ycTableColumn="'common:hdrCreatedBy';
        prop: 'createdBy',
        visible: !isModalView,
        filterType: 'text';
        let row;">
        <span
          [tooltip]="row.createImpersonatedBy">
          {{ row.createdBy }}
        </span>
        <small class="d-block">
          {{ row.createdDate | ycFormatDate }}
        </small>
      </ng-container>


      <!-- Last Modified By -->
      <ng-container *ycTableColumn="'common:hdrLastModifiedBy';
        prop: 'updatedBy',
        visible: !isModalView,
        filterType: 'text';
        let row;">
        <span
          [tooltip]="row.impersonatedBy">
          {{ row.updatedBy }}
        </span>
        <small class="d-block">
          {{ row.updatedDate | ycFormatDate }}
        </small>
      </ng-container>

      <!-- Actions -->
      <ng-container *ycTableColumn="''
        class: !isModalView ? 'actions' : ''
        let row;">
        <div *ngIf="!isModalView"
          class="btn-group" dropdown>
          <yc-button dropdownToggle
            [icon]="true"
            [value]="'common:btnOptions' | ycI18n">
            <yc-fa icon="ellipsis-v"></yc-fa>
          </yc-button>
          <ul *dropdownMenu
            class="dropdown-menu dropdown-left"
            role="menu">
            <!-- Edit -->
            <li class="dropdown-item" 
              role="menuitem"
              [ycTrackClick]="'Open audience modal'"
              (click)="audienceModal(row)">
              <yc-fa
                [light]="true" 
                icon="pencil"
                class="text-link me-2">
              </yc-fa>
              <yc-i18n
                key="common:textEdit"
                defaultValue="Edit">
              </yc-i18n>
            </li>
            <!-- Delete -->
            <li *ngIf="row.canDelete"
              role="menuitem"
              class="dropdown-item"
              [ycTrackClick]="'Delete audience'"
              (click)="deleteAudience(row)">
              <yc-fa
                [light]="true" 
                icon="times"
                class="text-danger me-2">
              </yc-fa>
              <yc-i18n
                key="common:btnDelete"
                defaultValue="Delete">
              </yc-i18n>
            </li>
          </ul>
        </div>

        <div *ngIf="isModalView">
          <yc-button
            [link]="true"
            [fontAwesomeIcon]="'pencil'"
            [tooltipText]="'common:textEdit' | ycI18n: 'Edit'"
            (onClick)="audienceModal(row)">
          </yc-button>
        </div>
      </ng-container>


    </yc-table>
  </div>
</div>

