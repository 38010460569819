import { CountryWithRegions, Region } from '@core/typings/location.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class CountriesState extends BaseYcState {
  readonly countrySelects: TypeaheadSelectOption[];
  readonly countriesWithRegions: CountryWithRegions[];
  readonly countryToRegionsMap: Record<string, Region[]> = {};
  readonly countryIdToNameMap: Record<number, string> = {};
  readonly countryCodeToNameMap: Record<string, string> = {};
  readonly countryCodeToRegionOptionsMap: Record<string, TypeaheadSelectOption[]> = {};
  readonly countryIdToRegionOptionsMap: Record<number, TypeaheadSelectOption[]> = {};
  readonly countryIdToCountryMap: Record<number, CountryWithRegions> = {};
  readonly regionIdToRegionMap: Record<number, Region> = {};
}
