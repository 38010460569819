<yc-modal
  [modalHeader]="isNomination ?
    ('APPLICATION:hdrDownloadNomination' | ycI18n:'Download Nomination') :
    ('APPLICATION:hdrDownloadApplication' | ycI18n:'Download Application')"
  [primaryButtonText]="submissionMaps?.length ? ('common:textDownload' | ycI18n:'Download') : ''"
  [cancelButtonText]="'common:btnCancel' | ycI18n:'Cancel'"
  (onPrimaryClick)="handleSubmit()"
  (onCancel)="closeModal.emit()"
  [primaryDisabled]="!valid">

  <yc-alert
    [message]="isNomination ?
      ('common:textDownloadNomPDFAlert2' | ycI18n: 'Select the nomination forms for the nomination you want to download as a PDF. All form attachments will automatically be included in the download as a zip file.') :
      ('common:textDownloadAppPDFAlert3' | ycI18n: 'Select the application data you want to download as a PDF. All form attachments will automatically be included in the download as a zip file.')"
    alertClass="info">
  </yc-alert>

  <div *ngFor="let submissionMap of submissionMaps">
    <div class="mt-3">
      <span *ngIf="submissionMap.parentWorkflowLevelName"
        class="d-block">
        {{ submissionMap.parentWorkflowLevelName }}
      </span>
      <strong>
        {{ submissionMap.workflowLevelName }}
      </strong>
    </div>
    <div *ngFor="let form of submissionMap.forms" 
      class="d-flex justify-content-between mt-3">
      <div class="d-flex">
        <yc-checkbox
          [noMargin]="true" 
          [(value)]="form.selected" 
          [optionLabel]="form.formName"
          (valueChange)="handleCheckboxChange()">
        </yc-checkbox>
      </div>
      <div *ngIf="form.formAudience === FormAudience.APPLICANT">
        <yc-i18n *ngIf="!form.submittedOn"
          key="GLOBAL:textNotSubmitted"
          defaultValue="Not submitted">
        </yc-i18n>
        <yc-i18n
          *ngIf="form.submittedOn"
          key="common:textSubmittedDate"
          [context]="{
            date: form.submittedOn | ycFormatDate: DATE_TIME_FORMAT
          }"
          defaultValue="Submitted __date__">
        </yc-i18n>
      </div>
      <div *ngIf="form.formAudience === FormAudience.MANAGER">
        <yc-i18n
          key="common:textNumberResponses"
          [context]="{
            number: form.submissions.length
          }"
          defaultValue="__number__ responses">
        </yc-i18n>
      </div>
    </div>
  </div>

  <!-- Additional Data -->
  <div class="mt-3">
    <strong>
      <yc-i18n
        key="common:hdrAdditionalData"
        defaultValue="Additional Data">
      </yc-i18n>
    </strong>
    <!-- Activity Trail -->
    <div class="mt-3">
      <yc-checkbox
        name="activityTrail"
        [(value)]="activityTrail" 
        [label]="'common:textActivityTrail' | ycI18n: 'Activity trail'"
        [srOnlyLabel]="true"
        [optionLabel]="'common:textActivityTrail' | ycI18n: 'Activity trail'">
      </yc-checkbox>
    </div>

    <!-- Emails -->
    <div *ngIf="!applicantIsMaskedAndCantView"
      class="mt-3">
      <yc-checkbox
        name="includeEmails"
        [(value)]="includeEmails" 
        [label]="isNomination ?
          ('common:textEmailsSentByGcForNom' | ycI18n: 'Emails sent by GrantsConnect for nomination') :
          ('common:textEmailsSentByGcForApp' | ycI18n: 'Emails sent by GrantsConnect for application')"
        [srOnlyLabel]="true"
        [optionLabel]="isNomination ?
          ('common:textEmailsSentByGcForNom' | ycI18n: 'Emails sent by GrantsConnect for nomination') :
          ('common:textEmailsSentByGcForApp' | ycI18n: 'Emails sent by GrantsConnect for application')">
      </yc-checkbox>
    </div>

    <!-- Award / Payment Info -->
    <div *ngIf="!awardOptionNotVisible"
      class="mt-3">
      <yc-checkbox
        name="includeAwards"
        [(value)]="includeAwards" 
        [label]="'common:textIncludeAwardAndPaymentDetail' | ycI18n: 'Include award and payment detail'"
        [srOnlyLabel]="true"
        [optionLabel]="'common:textIncludeAwardAndPaymentDetail' | ycI18n: 'Include award and payment detail'">
      </yc-checkbox>
    </div>
  </div>

  <!-- Attachments -->
  <div *ngIf="!applicantIsMaskedAndCantView"
    class="mt-3">
    <strong>
      <yc-i18n
        key="common:textAttachments"
        defaultValue="Attachments">
      </yc-i18n>
    </strong>
    <div class="mt-3">
      <yc-radio-buttons
        name="attachmentsType"
        [(value)]="attachmentsType"
        [label]="'common:textAttachments' | ycI18n: 'Attachments'"
        [srOnlyLabel]="true"
        [options]="attachmentOptions">
      </yc-radio-buttons>
    </div>

  </div>

  <!-- Masking -->  
  <div *ngIf="showMaskedDataToggle"
    class="mt-3">
    <strong>
      <yc-i18n
        key="common:textMasking"
        defaultValue="Masking">
      </yc-i18n>
    </strong>
    <div class="mt-3">
      <yc-checkbox
        name="notMasked"
        [(value)]="notMasked" 
        [label]="'common:textMasking' | ycI18n: 'Masking'"
        [srOnlyLabel]="true"
        [optionLabel]="'common:textIncludeMaskedFormDataInDownload' | ycI18n: 'Include masked form data in download'">
      </yc-checkbox>
    </div>
  </div>
</yc-modal>