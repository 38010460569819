import { Injectable } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { HelpContentResources } from './help-content.resources';
import { HelpContentState } from './help-content.state';
import { BaseHelpContent, HelpContent } from './help-content.typing';

@Injectable({ providedIn: 'root' })
@AttachYCState(HelpContentState)
export class HelpContentService extends BaseYCService<HelpContentState> {
  constructor (
    private resources: HelpContentResources,
    private confirmAndTakeActionService: ConfirmAndTakeActionService,
    private i18n: I18nService
  ) {
    super();
  }

  get contents () {
    return this.get('contents');
  }

  t (...args: Parameters<I18nService['translate']>) {
    return this.i18n.translate(...args);
  }

  async getContents () {
    if (!this.contents) {
      const contents = await this.resources.getContents();

      this.set('contents', contents);
    }
  }

  async resetContents () {
    this.set('contents', undefined);
    await this.getContents();
  }

  async saveHelpContent (
    content: BaseHelpContent | HelpContent
  ) {
    await this.confirmAndTakeActionService.genericTakeAction(
      () => this.resources.saveHelpContent(content),
      this.t(
        'ADMIN:notificationSavedHelpContent',
        {},
        'Successfully saved help content'
      ),
      this.t(
        'ADMIN:notificationFailedSaveHelpContent',
        {},
        'Failed to save help content'
      )
    );
    await this.resetContents();
  }

  async deleteHelpContent (id: number) {
    await this.confirmAndTakeActionService.genericConfirmAndTakeAction(
      () => this.resources.deleteHelpContent(id),
      this.t(
        'ADMIN:hdrDeleteHelpContent',
        {},
        'Delete Help Content'
      ),
      '',
      this.t(
        'ADMIN:textAreYouSureDeleteHelpContent',
        {},
        'Are you sure you want to delete this help content?'
      ),
      this.t(
        'common:btnDelete',
        {},
        'Delete'
      ),
      this.t(
        'ADMIN:notificationDeletedHelpContent',
        {},
        'Successfully delete help content'
      ),
      this.t(
        'ADMIN:notificationFailedDeleteHelpContent',
        {},
        'Failed to delete help content'
      )
    );
    await this.resetContents();
  }
}
