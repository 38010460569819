import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { EmailService } from '@features/system-emails/email.service';
import { EmailNotificationType, EmailStatus } from '@features/system-emails/email.typing';
import { ExportEmailService } from '@features/system-emails/export-emails/export-emails.service';
import { DATE_TIME_FORMAT, DateService } from '@yourcause/common/date';
import { FileService, FileTypeService, YcFile } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { isAfter } from 'date-fns';
import { CommunicationsResources } from './communications.resources';
import { CommunicationsState } from './communications.state';
import { ApplicationAPICommunication, Communication, CommunicationTypes, CommunicationsByDate, OrganizationAPICommunication, SimpleCommunication, SimpleEmail } from './communications.typing';

@AttachYCState(CommunicationsState)
@Injectable({ providedIn: 'root' })
export class CommunicationsService extends BaseYCService<CommunicationsState> {

   constructor (
    private logger: LogService,
    private i18n: I18nService,
    private communicationResources: CommunicationsResources,
    private notifier: NotifierService,
    private fileService: FileService,
    private dateService: DateService,
    private emailService: EmailService,
    private exportEmailService: ExportEmailService,
    private fileTypeService: FileTypeService
  ) {
    super();
  }

  get applicationCommunicationsMap () {
    return this.get('applicationCommunicationsMap');
  }

  get nonprofitCommunicationsMap () {
    return this.get('nonprofitCommunicationsMap');
  }

  async adaptCommunicationToApplicantCommunication (
    comm: Communication,
    applicationId: number
  ): Promise<ApplicationAPICommunication> {

    return {
      [
        comm.joinCommunicationId ?
          'applicationCommunicationsId' :
          'applicationCommunicationId'
      ]: comm.joinCommunicationId,
      applicationId,
      type: comm.type,
      content: comm.content,
      visibility: comm.visibility,
      subject: comm.subject,
      date: comm.date,
      publishToNonprofit: comm.publishToNonprofit,
      fileUploadIds: comm.files.map((file) => file.fileUploadId)
    };
  }

  async adaptCommunicationToNonprofitCommunication (
    comm: Communication,
    organizationId: number
  ): Promise<OrganizationAPICommunication> {
    return {
      organizationCommunicationsId: comm.joinCommunicationId,
      organizationId,
      type: comm.type,
      content: comm.content,
      visibility: comm.visibility,
      subject: comm.subject,
      date: comm.date,
      fileUploadIds: comm.files.map((file) => file.fileUploadId)
    };
  }


  async handleMultipleCommFileUploads (fileRequests: YcFile[]) {
    try {
      const filesResponse = await Promise.all(fileRequests.map(async (fileRequest: YcFile) => {
        const fileUploadId = await this.communicationResources.uploadCommunicationFile(fileRequest);

        return {
          fileUploadId,
          fileName: fileRequest.fileName
        };
      }));

      return filesResponse;
    } catch (err) {
      const e = err as HttpErrorResponse;
      this.fileTypeService.displayInvalidFileUploadErrorMessage(e?.error?.message, e);

      return null;
    }
  }

  adaptSimpleCommunication (
    communication: SimpleCommunication,
    isManual = false
  ): Communication {
    return {
      communicationId: communication.communicationId,
      joinCommunicationId: 'applicationCommunicationId' in communication ?
        communication.applicationCommunicationId :
        communication.organizationCommunicationId,
      type: communication.type,
      subject: communication.subject,
      date: communication.date,
      updatedDate: communication.updatedDate,
      fileName: communication.fileName,
      content: '',
      createdBy: communication.createdBy,
      updatedBy: communication.updatedBy,
      isManual,
      visibility: communication.visibility,
      emailNotificationType: communication.emailNotificationType,
      files: communication.files
    };
  }

  sortCommunications (
    communicationsByDate: CommunicationsByDate[]
  ): Communication[][] {
    return communicationsByDate.sort((comm1, comm2) => {
      if (isAfter(new Date(comm1.date), new Date(comm2.date))) {
        return -1;
      } else if (comm1.date === comm2.date) {
        return 0;
      } else {
        return 1;
      }
    }).map((comm) => {
      return comm.communications;
    });
  }

  stripExtraText (text: string) {
    const commentReplacement = /\/\*.*\*\//gm;
    const styleReplacement = /<style>(.|\r|\n)*<\/style>/gm;

    const stripped = text
      .replace(commentReplacement, '')
      .replace(styleReplacement, '');

    return this.exportEmailService.getBodyWithoutButton(stripped).body;
  }

  async addApplicationCommunication (
    communication: Communication,
    applicationId: number
  ) {
    try {
      const apiCommunication = await this.adaptCommunicationToApplicantCommunication(
        communication,
        applicationId
      );
      await this.communicationResources.addApplicationCommunication(
        apiCommunication
      );
      this.notifier.success(this.i18n.translate(
        'nonprofits:textSuccessfullyAddedCommunication',
        {},
        'Successfully added the communication'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'nonprofits:textErrorAddingCommunication',
        {},
        'There was an error adding the communication'
      ));
    }
  }

  async updateApplicationCommunication (
    communication: Communication,
    applicationId: number
  ) {
    try {
      const apiCommunication = await this.adaptCommunicationToApplicantCommunication(
        communication,
        applicationId
      );
      await this.communicationResources.updateApplicationCommunication(
        apiCommunication
      );
      this.notifier.success(this.i18n.translate(
        'nonprofits:textSuccessfullyUpdatedCommunication',
        {},
        'Successfully updated the communication'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'nonprofits:textErrorUpdatingCommunication',
        {},
        'There was an error updating the communication'
      ));
    }
  }

  async deleteApplicationCommunication (
    applicationCommunicationsId: number
  ) {
    try {
      await this.communicationResources.deleteApplicationCommunication(
        applicationCommunicationsId
      );
      this.notifier.success(this.i18n.translate(
        'nonprofits:textSuccessfullyRemovedCommunication',
        {},
        'Successfully removed the communication'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'nonprofits:textErrorRemovingCommunication',
        {},
        'There was an error removing the communication'
      ));
    }
  }

  getEmailDetails (joinCommunicationId: number) {
    return this.communicationResources.getEmailDetails(
      joinCommunicationId
    );
  }

  async getCommunicationDetail (
    communication: Communication,
    isForNonprofit: boolean
  ) {
    if (communication.content) {
      return communication;
    }

    switch (communication.type) {
      case CommunicationTypes.SYSTEM_EMAIL: {
        const result = await this.getEmailDetails(
          communication.joinCommunicationId
        );
        communication.emailAttachments = [
          ...(result.emailAttachments ?? []),
          ...(result.emailMergeDocuments ?? [])
        ];
        communication.toFriendlyName = result.toFriendlyName;
        communication.content = this.stripExtraText(result.body);
        break;
      }
      case CommunicationTypes.NONPROFIT_DOC:
      case CommunicationTypes.MEETING_AND_INTERACTION:
      case CommunicationTypes.EXTERNAL_EMAIL:
      case CommunicationTypes.EXTERNAL_COMMUNICATION:
      case CommunicationTypes.DOCUMENTATION: {
        const result = await (isForNonprofit ?
          this.communicationResources.getOrganizationCommunicationDetails(
            communication.joinCommunicationId
          ) :
          this.communicationResources.getApplicationCommunicationDetails(
            communication.joinCommunicationId
          )
        );
        communication.fileAccessURL = result.fileAccessURL;
        communication.fileName = result.fileName;
        communication.files = result.files;
        communication.applicationId = result.applicationId;
        communication.content = result.content;
      }
      break;
    }

    return communication;
  }

  async getCommunicationsForApplication (
    applicationId: number,
    forceFetch: boolean
  ): Promise<Communication[][]>  {
    const commsOnState = this.applicationCommunicationsMap[applicationId];
    if (forceFetch || !commsOnState) {
      return this.setCommunicationsForApplication(applicationId);
    } else {
      return commsOnState;
    }
  }

  async setCommunicationsForApplication (
    applicationId: number
  ): Promise<Communication[][]> {
    const result = await this.communicationResources.getCommunicationsForApplication(
      applicationId
    );
    const comms: CommunicationsByDate[] = [
      ...result.comments.map((comment) => {
        const adapted: Communication[] = [{
          type: CommunicationTypes.WORKFLOW_COMMENT,
          subject: comment.formName,
          date: comment.updatedDate,
          updatedDate: comment.updatedDate,
          content: comment.notes,
          isRich: false,
          createdBy: comment.createdBy,
          updatedBy: comment.updatedBy,
          emailNotificationType: null,
          description: this.i18n.translate('nonprofits:textLevelDynamic', {
            level: comment.workflowLevelName
          }, 'Level: __level__'),
          files: null
        }];

        return {
          date: comment.updatedDate,
          communications: adapted
        };
      }),
      ...result.manualCommunicationRecords.map(record => {
        const adapted = this.adaptSimpleCommunication(record, true);

        return {
          date: adapted.date,
          communications: [adapted]
        };
      })
    ];
    const emails = result.emails.reduce<Record<string, Communication[]>>((acc, email) => {
      const dateSent = this.dateService.formatDate(email.sentDate, DATE_TIME_FORMAT);
      const adapted = this.adaptEmailToCommunication(email);
      adapted.description = this.getDescription(adapted);
      const key = `${email.emailNotificationType}.${dateSent}`;
      if (!acc[key]) {
        return {
          ...acc,
          [key]: [adapted]
        };
      } else {
        return {
          ...acc,
          [key]: [
            ...acc[key],
            adapted
          ]
        };
      }
    }, {});
    Object.keys(emails).forEach((key) => {
      const groupedEmails = emails[key];
      comms.push({
        date: groupedEmails[0].date,
        communications: groupedEmails
      });
    });
    const sortedCommunications = this.sortCommunications(comms);

    this.set('applicationCommunicationsMap', {
      ...this.applicationCommunicationsMap,
      [applicationId]: sortedCommunications
    });

    return sortedCommunications;
  }

  adaptEmailToCommunication (email: SimpleEmail): Communication {
    return {
      communicationId: email.id,
      type: CommunicationTypes.SYSTEM_EMAIL,
      joinCommunicationId: email.id,
      subject: email.subject,
      date: email.sentDate,
      updatedDate: email.updatedDate,
      content: '',
      isRich: true,
      createdBy: null,
      updatedBy: null,
      emailStatusType: email.emailStatusType,
      ccEmails: email.ccEmails,
      bccEmails: email.bccEmails,
      emailAttachments: email.emailAttachments,
      emailNotificationType: email.emailNotificationType,
      emailNumber: email.emailNumber,
      files: null
    };
  }

  async addOrganizationCommunication (
    communication: Communication,
    organizationId: number
  ) {
    try {
      const apiCommunication = await this.adaptCommunicationToNonprofitCommunication(
        communication,
        organizationId
      );
      await  this.communicationResources.addOrganizationCommunication(apiCommunication);
      this.notifier.success(this.i18n.translate(
        'nonprofits:textSuccessfullyAddedCommunication',
        {},
        'Successfully added the communication'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'nonprofits:textErrorAddingCommunication',
        {},
        'There was an error adding the communication'
      ));
    }
  }

  async updateOrganizationCommunication (
    communication: Communication,
    organizationId: number
  ) {
    try {
      const apiCommunication = await this.adaptCommunicationToNonprofitCommunication(
        communication,
        organizationId
      );
      await this.communicationResources.updateOrganizationCommunication(
        apiCommunication
      );
      this.notifier.success(this.i18n.translate(
        'nonprofits:textSuccessfullyUpdatedCommunication',
        {},
        'Successfully updated the communication'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'nonprofits:textErrorUpdatingCommunication',
        {},
        'There was an error updating the communication'
      ));
    }
  }

  async deleteOrganizationCommunication (
    organizationCommunicationsId: number,
    organizationId: number
  ) {
    try {
      await this.communicationResources.deleteOrganizationCommunication(
        organizationCommunicationsId,
        organizationId
      );
      this.notifier.success(this.i18n.translate(
        'nonprofits:textSuccessfullyRemovedCommunication',
        {},
        'Successfully removed the communication'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'nonprofits:textErrorRemovingCommunication',
        {},
        'There was an error removing the communication'
      ));
    }
  }

  async getCommunicationsForOrganization (
    organizationId: number
  ): Promise<Communication[][]> {
    const result = await this.communicationResources.getCommunicationsForOrganization(
      organizationId
    );

    const communications =  this.sortCommunications(
      result.manualCommunicationRecords.map((record) => {
        return {
          date: record.date,
          communications: [this.adaptSimpleCommunication(record, true)]
        };
      })
    );

    this.set('nonprofitCommunicationsMap', {
      ...this.nonprofitCommunicationsMap,
      [organizationId]: communications
    });

    return communications;
  }

  async handleSendInviteEmail (
    comm: Communication,
    applicationId: number
  ) {
    try {
      await this.emailService.sendYouHaveBeenNominatedEmail(
        applicationId,
        comm.joinCommunicationId
      );
      this.notifier.success(this.i18n.translate(
        'GLOBAL:textSuccesSendingEmail',
        {},
        'Successfully sent the email'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'GLOBAL:textErrorSendingEmail',
        {},
        'There was an error sending the email'
      ));
    }
  }

  getAccessUrlForNonprofitCommunication (
    organizationCommunicationId: number,
    fileUploadId: number
  ) {
    return this.communicationResources.getAccessUrlForNonprofitCommunication(
      organizationCommunicationId,
      fileUploadId
    );
  }
  async openFileForNonprofitCommunication (
    organizationCommunicationId: number,
    fileUploadId: number
  ) {
    const accessUrl = await this.communicationResources.getAccessUrlForNonprofitCommunication(
      organizationCommunicationId,
      fileUploadId
    );
    const blob = await this.fileService.getBlob(accessUrl) as File;
    const blobUrl = this.fileService.convertFileToUrl(blob);

    window.open(blobUrl, '_blank');
  }

  async downloadAccessUrlForNonprofitCommunication (
    organizationCommunicationId: number,
    fileUploadId: number,
    fileName: string
  ) {
    const accessUrl = await this.communicationResources.getAccessUrlForNonprofitCommunication(
      organizationCommunicationId,
      fileUploadId
    );
    this.fileService.downloadUrlAs(accessUrl, fileName);
  }

  getApplicationEmailAddress (applicationGuid: string) {
    let adaptedLocationBase;
    if (environment.locationBase === 'localhost') {
      adaptedLocationBase = 'yourcausegrantsqa.com';
    } else {
      adaptedLocationBase = environment.locationBase;
    }

    return applicationGuid + '@applications.' + adaptedLocationBase;
  }

  getDescription (comm: Communication) {
    const date = this.dateService.formatDate(comm.date, DATE_TIME_FORMAT);
    const inviteEmailActive = this.emailService.isEmailActive(
      EmailNotificationType.NomineeApplicantInvitation
    );
    switch (comm.type) {
      case CommunicationTypes.EXTERNAL_EMAIL: {
        return `<div>${
          this.i18n.translate(
            'common:textAttachedToApplicationBy',
            {
              fullName: comm.createdBy.firstName + ' ' + comm.createdBy.lastName,
              date
            },
            'Attached to application by __fullName__ on __date__'
        )}</div>`;
      }
      case CommunicationTypes.SYSTEM_EMAIL: {
        let returnVal = this.i18n.translate(
          'GLOBAL:textSystemEmailWithDate',
          { date },
          'System email: __date__'
        );
        if (comm.emailStatusType === EmailStatus.OnHold) {
          returnVal = `
            <span class="me-2">
              ${this.i18n.translate(
                'GLOBAL:textSystemEmail',
                {},
                'System email'
              )}
            </span>
            <span class="text-danger">
              ${this.i18n.translate(
                inviteEmailActive ?
                  'GLOBAL:textNotSent' :
                  'GLOBAL:textNotSentEmailIsDisabled',
                {},
                `${inviteEmailActive ? 'Not sent' : 'Not sent (email is disabled)'}`
              )}
            </span>`;
        }

        return `${returnVal}<div>${this.i18n.translate(
          'common:textEmailId',
          {},
          'Email ID'
        )}: ${comm.emailNumber}</div>`;
      }
      default:
      case CommunicationTypes.DOCUMENTATION:
      case CommunicationTypes.WORKFLOW_COMMENT:
      case CommunicationTypes.CYCLE_COMMENT:
      case CommunicationTypes.MEETING_AND_INTERACTION:
      case CommunicationTypes.EXTERNAL_COMMUNICATION: {
        const createdByOnDate = this.i18n.translate(
          'GLOBAL:textCreatedByDynamic',
          {
            user: `${comm.createdBy.firstName} ${comm.createdBy.lastName}`,
            date
          },
          'Created by __user__ on __date__'
        );

        const updatedByOnDate = comm.createdBy &&
          comm.updatedBy &&
          comm.createdBy.email !== comm.updatedBy.email ?
            this.i18n.translate(
              'GLOBAL:textStatusLastUpdatedByOnDate',
              {
                name: `${comm.updatedBy.firstName} ${comm.updatedBy.lastName}`,
                date: this.dateService.formatDate(
                  comm.updatedDate,
                  DATE_TIME_FORMAT
                )
              },
              'Updated by __name__ on __date__'
            ) :
            '';

        return `
          <div>
            ${createdByOnDate}
          </div>
          <div>
          ${updatedByOnDate}
          </div>
          <div>
            ${comm.description || this.translateCommType(comm)}
          </div>
        `;
      }
    }
  }

  translateCommType (comm: Communication) {
    switch (comm.type) {
      case CommunicationTypes.DOCUMENTATION:
        return this.i18n.translate(
          'GLOBAL:textDocumentation'
        );
      case CommunicationTypes.WORKFLOW_COMMENT:
        return this.i18n.translate(
          'GLOBAL:textWorkflowComment',
          {},
          'Workflow comment'
        );
      case CommunicationTypes.CYCLE_COMMENT:
        return this.i18n.translate(
          'GLOBAL:textCycleComment',
          {},
          'Cycle comment'
        );
      case CommunicationTypes.EXTERNAL_COMMUNICATION:
        return this.i18n.translate(
          'GLOBAL:textExternalCommunication',
          {},
          'External communication'
        );
      case CommunicationTypes.MEETING_AND_INTERACTION:
        return this.i18n.translate(
          'GLOBAL:textMeetingAndInteraction',
          {},
          'Meeting and interaction'
        );
      default:
        return '';
    }
  }
}
