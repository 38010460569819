import { Injectable } from '@angular/core';
import { ApplicationFileService } from '@core/services/application-file.service';
import { CurrencyService } from '@core/services/currency.service';
import { GC_Feature_Flags } from '@core/services/feature-flags.service';
import { TranslationService } from '@core/services/translation.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { BaseApplication } from '@core/typings/application.typing';
import { AttributesToMatchForMergeField, REF_COMPONENT_TYPE_PREFIX, ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormAnswerValues, FormAudience, FormChangeTracker, FormChanges, FormDecisionTypes, FormDefinitionComponent, FormDefinitionComponentForExport, FormDefinitionForUi, FormRevisionRefFields } from '@features/configure-forms/form.typing';
import { CustomDataTable, PicklistDataType } from '@features/custom-data-tables/custom-data-tables.typing';
import { DefaultValType } from '@features/forms/component-configuration/component-configuration.typing';
import { BucketComp } from '@features/forms/form-builder/form-builder.typing';
import { SpecialHandling } from '@features/forms/form-renderer-components/standard-form-components/form-special-handling/form-special-handling.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { InKindRequestedItem } from '@features/in-kind/in-kind.typing';
import { ExistingRefField } from '@features/quick-add/quick-add-fields/quick-add-fields.component';
import { UserService } from '@features/users/user.service';
import { ALL_SKIP_FILTER, InflectService, PaginationOptions, SimpleNumberMap, SimpleStringMap, TableRepositoryFactory, TopLevelFilter, TopLevelFilterOption } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { CurrencyValue } from '@yourcause/common/currency';
import { DateService, TIMESTAMP_FORMAT } from '@yourcause/common/date';
import { FeatureFlagService } from '@yourcause/common/feature-flag';
import { FileService, YcFile } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { intersection, uniq } from 'lodash';
import * as parse from 'papaparse';
import { FormFieldHelperState } from '../states/form-field-helper.state';

export const STANDARD_FIELD_PREFIX = 'gcStandardField_';

@Injectable({ providedIn: 'root' })
@AttachYCState(FormFieldHelperState)
export class FormFieldHelperService extends BaseYCService<FormFieldHelperState> {

  constructor (
    private arrayHelper: ArrayHelpersService,
    private applicationFileService: ApplicationFileService,
    private i18n: I18nService,
    private userService: UserService,
    private currencyService: CurrencyService,
    private clientSettingsService: ClientSettingsService,
    private componentHelper: ComponentHelperService,
    private inflect: InflectService,
    private translationService: TranslationService,
    private featureFlagService: FeatureFlagService,
    private tableFactory: TableRepositoryFactory,
    private dateService: DateService,
    private fileService: FileService
  ) {
    super();
  }

  get allReferenceFields () {
    return this.get('allReferenceFields');
  }

  get referenceFieldMap () {
    return this.get('referenceFieldMap');
  }

  get referenceFieldMapById () {
    return this.get('referenceFieldMapById');
  }

  get isParentRefFieldMap () {
    return this.get('isParentRefFieldMap');
  }

  get parentPicklistValueMap () {
    return this.get('parentPicklistValueMap');
  }

  get allFieldKeys () {
    return this.get('allFieldKeys');
  }

  get currentFormRefFields () {
    return this.get('currentFormRefFields');
  }

  /**
   * List of form field types we support
   *
   * @returns a list of form field types that are supported
   */
  getReferenceFieldTypesList () {
    return [
      ReferenceFieldsUI.ReferenceFieldTypes.TextField,
      ReferenceFieldsUI.ReferenceFieldTypes.Number,
      ReferenceFieldsUI.ReferenceFieldTypes.TextArea,
      ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable,
      ReferenceFieldsUI.ReferenceFieldTypes.Checkbox,
      ReferenceFieldsUI.ReferenceFieldTypes.Date,
      ReferenceFieldsUI.ReferenceFieldTypes.Radio,
      ReferenceFieldsUI.ReferenceFieldTypes.SelectBoxes,
      ReferenceFieldsUI.ReferenceFieldTypes.FileUpload,
      ReferenceFieldsUI.ReferenceFieldTypes.ExternalAPI,
      ReferenceFieldsUI.ReferenceFieldTypes.Aggregate,
      ReferenceFieldsUI.ReferenceFieldTypes.Table,
      ReferenceFieldsUI.ReferenceFieldTypes.Currency,
      ReferenceFieldsUI.ReferenceFieldTypes.Subset,
      ReferenceFieldsUI.ReferenceFieldTypes.DataPoint,
      this.featureFlagService.getFeatureFlagEnabled(GC_Feature_Flags.AddressFormField) ?
        ReferenceFieldsUI.ReferenceFieldTypes.Address :
        undefined
    ].filter((type) => !!type);
  }

  setAllFieldsOnState (fields: ReferenceFieldAPI.ReferenceFieldDisplayModel[]) {
    this.set('allReferenceFields', fields);
  }

  setReferenceFieldMap (map: SimpleStringMap<ReferenceFieldAPI.ReferenceFieldDisplayModel>) {
    this.set('referenceFieldMap', map);
  }

  setReferenceFieldIdMap (map: SimpleNumberMap<ReferenceFieldAPI.ReferenceFieldDisplayModel>) {
    this.set('referenceFieldMapById', map);
  }

  setAllFieldKeys (keys: string[]) {
    this.set('allFieldKeys', keys);
  }

  setAllReferenceFields (
    fields: ReferenceFieldAPI.ReferenceFieldDisplayModel[]
  ) {
    this.setAllFieldsOnState(fields);
    this.setReferenceFieldMaps();
  }

  setReferenceFieldMaps () {
    const keyMap: SimpleStringMap<ReferenceFieldAPI.ReferenceFieldDisplayModel> = {};
    const idMap: SimpleNumberMap<ReferenceFieldAPI.ReferenceFieldDisplayModel> = {};
    const isParentMap: Record<number, boolean> = {};
    const allKeys: string[] = [];
    this.allReferenceFields.forEach((field) => {
      allKeys.push(field.key);
      keyMap[field.key] = field;
      idMap[field.referenceFieldId] = field;
      const isParent = this.getIsParentRefField(field.referenceFieldId);
      isParentMap[field.referenceFieldId] = isParent;
    });
    this.setIsParentFieldMap(isParentMap);
    this.setReferenceFieldMap(keyMap);
    this.setReferenceFieldIdMap(idMap);
    this.setAllFieldKeys(allKeys);
  }

  /**
   * Get the form fields available given a form's audience, field's audience, and field's type
   *
   * @param existingRefFieldKeys: Ref Field Keys already in use
   * @param type Type of the reference field
   * @param fieldAudience Audience for the field
   * @param formAudience Audience for the form the field is being added to
   */
  getFormFieldsByTypeAndAudience (
    existingRefFields: ExistingRefField[],
    type: ReferenceFieldsUI.ReferenceFieldTypes,
    fieldAudience: FormAudience,
    formAudience: FormAudience,
    onlyReturnPublished = false // used for Root zone scenario
  ): ReferenceFieldAPI.ReferenceFieldDisplayModel[] {
    const addingManagerFieldToApplicantForm = formAudience === FormAudience.APPLICANT &&
      fieldAudience === FormAudience.MANAGER;

    return this.allReferenceFields.filter((field) => {
      // allow this field to be selected if
      // the field's type matches
      return (field.type === type) &&
        // the field's audience matches
        (field.formAudience === fieldAudience) &&
        // and the field does not belong on a table field
        (!field.isTableField) &&
        // if adding a manager field to an applicant form, only allow single response, otherwise continue (#1670627)
        (addingManagerFieldToApplicantForm ? field.isSingleResponse : true) &&
        // Only return published for root zone
        (onlyReturnPublished ? field.standardComponentIsPublished : true) &&
        // and finally make sure it isn't on the form already
        !this.isExistingRefField(field, existingRefFields)
    });
  }

  isExistingRefField (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    existingRefFields: ExistingRefField[]
  ) {
    return existingRefFields.some((_field) => {
      const sameKey = _field.key === field.key;
      if (sameKey && !!_field.referenceFieldTableId) {
        return _field.referenceFieldTableId === field.referenceFieldTableId;
      }

      return sameKey;
    });
  }

  /**
   * Get the Component's Type Translated
   *
   * @param component: Component to evaluate
   * @returns the component's type, translated
   */
  getComponentTypeTranslated (
    component: FormDefinitionComponent
  ) {
    if (!!component) {
      const isRefField = this.componentHelper.isReferenceFieldComp(component.type);
      const isStandardField = this.componentHelper.isStandardComponent(component.type);
      const isEmployeeSsoField = this.componentHelper.isEmployeeSsoComponent(component.type);
      const isReportField = this.componentHelper.isReportFieldComp(component.type);
      if (isRefField) {
        const field = this.getReferenceFieldFromCompType(component.type);

        return field.name;
      } else if (isStandardField) {
        const standardHelper = this.componentHelper.getStandardComponentHelperMap();
        const details = standardHelper[component.type];

        return this.i18n.translate(details.key, {}, details.defaultValue);
      } else if (isEmployeeSsoField) {
        return this.i18n.translate('common:textEmployeeSSO', {}, 'Employee SSO');
      } else if (isReportField) {
        return this.i18n.translate('common:textReportField', {}, 'Report field');
      }
    }

    return '';
  }

  getApplicableAggregateFormFields (
    customDataTables: CustomDataTable[]
  ) {
    const unsortedOptions =  [
      ...this.getFormFieldsByType(ReferenceFieldsUI.ReferenceFieldTypes.Number),
      ...this.getFormFieldsByType(
        ReferenceFieldsUI.ReferenceFieldTypes.Currency
      ),
      ...[
        ...this.getFormFieldsByType(
          ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable
        ),
        ...this.getFormFieldsByType(
          ReferenceFieldsUI.ReferenceFieldTypes.Radio
        )
      ].filter(field => {
        const dataTable = customDataTables.find(table => {
          return table.guid === field.customDataTableGuid;
        });

        return dataTable?.dataType === PicklistDataType.Numeric && !field.supportsMultiple;
      })
    ].filter(field => {
      return field.formAudience === FormAudience.MANAGER &&
        !field.isTableField &&
        !field.isEncrypted &&
        !field.isSingleResponse;
    }).map((field) => {
      return {
        label: field.name,
        value: field.referenceFieldId
      };
    });

    return this.arrayHelper.sort(unsortedOptions, 'label');
  }

  getIdNameMap () {
    return this.allReferenceFields.reduce((acc, ref) => {
      return {
        ...acc,
        [ref.referenceFieldId]: ref.name
      };
    }, {});
  }

  getReferenceFieldByKey (key: string) {
    return this.referenceFieldMap[key];
  }

  resetRefFieldRepo () {
    const repo = this.tableFactory.getRepository('REFERENCE_FIELDS');
    if (repo) {
      repo.reset();
    }
  }

  setIsParentFieldMap (isParentMap: Record<number, boolean>) {
    this.set('isParentRefFieldMap', isParentMap);
  }

  getIsParentRefField (refFieldId: number) {
    return this.allReferenceFields.some((field) => {
      return field.parentReferenceFieldId === refFieldId;
    });
  }

  getChildrenOfParentRefField (refFieldId: number) {
    return this.allReferenceFields.filter((field) => {
      return field.parentReferenceFieldId === refFieldId;
    });
  }

  setParentPicklistValueMap (
    refFieldId: number,
    value: string|string[]
  ) {
    const isParent = this.getIsParentRefField(refFieldId);
    if (isParent) {
      this.set('parentPicklistValueMap', {
        ...this.parentPicklistValueMap,
        [refFieldId]: value
      });
    }
  }

  resetParentPicklistValueMap () {
    this.set('parentPicklistValueMap', {});
  }

  getAllAggregateFields () {
    return this.allReferenceFields.filter((field) => {
      return !!field.aggregateType;
    });
  }

  getReferenceFieldFromCompType (
    type: string,
    ignoreCasing = false
  ) {
    const key = this.componentHelper.getRefFieldKeyFromCompType(type);
    let field = this.getReferenceFieldByKey(key);
    if (ignoreCasing && !field && !!key) {
      field = (this.allReferenceFields || []).find((_field) => {
        return _field.key.toLowerCase() === key.toLowerCase();
      });
    }

    return field;
  }

  setCurrentFormRefFields (definition: FormDefinitionForUi[]) {
    const components = this.componentHelper.getAllComponents(definition, false, true);
    const fields = components.map((comp) => {
      const key = this.componentHelper.getRefFieldKeyFromCompType(comp.type);

      return this.referenceFieldMap[key];
    }).filter((item) => !!item);
    this.set('currentFormRefFields', fields);
  }


  getTypeSelectOptionsForFilters (
    hideOptionForAll = false,
    includeStandardProductFieldOption = false
  ) {
    const typeOptions: TopLevelFilterOption[] = [
      ...this.getReferenceFieldTypeOptions().map((opt) => {
        let label = opt.label;
        const value = opt.value;
        let customColumn: string;
        let customValue: string;
        let filterTypeOverride: string;
        if (opt.value === ReferenceFieldsUI.ReferenceFieldTypes.Aggregate) {
          label = this.i18n.translate(
            'common:textAggregationField',
            {},
            'Aggregation field'
          );
          customColumn = 'aggregateType';
          customValue = '';
          filterTypeOverride = 'nb';
        }

        return {
          label,
          value,
          customColumn,
          customValue,
          filterTypeOverride
        };
      }),
      includeStandardProductFieldOption ?
        {
          label: this.i18n.translate(
            'common:textStandardProductFields',
            {},
            'Standard product fields'
          ),
          value: true,
          customColumn: 'isStandardProductField'
        } :
        undefined
    ].filter((item) => !!item);
    const options = this.arrayHelper.sort(typeOptions, 'label');

    return [
      !hideOptionForAll ?
        {
          display: this.i18n.translate(
            'GLOBAL:textAllFormFieldTypes',
            {},
            'All form field types'
          ),
          value: ALL_SKIP_FILTER
        } : undefined,
      ...options
    ].filter((item) => !!item);
  }

  getFormFieldsByType (
    type: ReferenceFieldsUI.ReferenceFieldTypes
  ) {
    return this.allReferenceFields.filter(field => {
      return field.type === type;
    });
  }

  getFormFieldOptionsByType (
    type: ReferenceFieldsUI.ReferenceFieldTypes
  ): TypeaheadSelectOption<number>[] {
    return this.getFormFieldsByType(type)
      .map(field => {
        return {
          label: field.name,
          value: field.referenceFieldId
        };
      });
  }

  getReferenceFieldTypeToLabelMap () {
    const map = {} as Record<ReferenceFieldsUI.ReferenceFieldTypes, string>;
    const options = this.getReferenceFieldTypeOptions();

    return options.reduce((acc, option) => {
      return {
        ...acc,
        [option.value]: option.label
      };
    }, map);
  }

  getFormattingMap () {
    const options = this.getFormattingTypeOptions();

    return options.reduce<Record<ReferenceFieldAPI.ReferenceFieldFormattingType, string>>((acc, option) => {
      return {
        ...acc,
        [option.value]: option.label
      };
    }, {} as Record<ReferenceFieldAPI.ReferenceFieldFormattingType, string>);
  }

  getReferenceFieldTypeOptions (): TypeaheadSelectOption<ReferenceFieldsUI.ReferenceFieldTypes>[] {
    const types = this.getReferenceFieldTypesList();

    return this.arrayHelper.sort(
      types.map((type) => {
        return this.getFieldOption(type);
      }),
      'label'
    );
  }

  getFieldOption (
    type: ReferenceFieldsUI.ReferenceFieldTypes
  ): TypeaheadSelectOption<ReferenceFieldsUI.ReferenceFieldTypes> {
    return {
      label: this.getFieldTypeTranslatedWithIcon(type).label,
      value: type
    };
  }

  getFieldTypeTranslatedWithIcon (
    type: ReferenceFieldsUI.ReferenceFieldTypes
  ): { label: string; icon: string } {
    switch (type) {
      case ReferenceFieldsUI.ReferenceFieldTypes.Subset:
        return {
          label: this.i18n.translate(
            'common:textFieldGroup',
            {},
            'Field group'
          ),
          icon: 'list'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.Currency:
        return {
          label: this.i18n.translate(
            'common:textCurrencyField',
            {},
            'Currency field'
          ),
          icon: 'money-bill'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.Aggregate:
        return {
          label: this.i18n.translate(
            'common:textAggregationField',
            {},
            'Aggregation field'
          ),
          icon: 'plus'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.Checkbox:
        return {
          label: this.i18n.translate(
            'common:textCheckbox',
            {},
            'Checkbox'
          ),
          icon: 'check'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable:
        return {
          label: this.i18n.translate(
            'common:textPicklist',
            {},
            'Picklist'
          ),
          icon: 'list-alt'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.Date:
        return {
          label: this.i18n.translate(
            'common:hdrDate',
            {},
            'Date'
          ),
          icon: 'calendar'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.FileUpload:
        return {
          label: this.i18n.translate(
            'common:textFileUpload',
            {},
            'File upload'
          ),
          icon: 'upload'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.Number:
        return {
          label: this.i18n.translate(
            'common:textNumber',
            {},
            'Number'
          ),
          icon: 'hashtag'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.Radio:
        return {
            label: this.i18n.translate(
            'common:textRadioButtons',
            {},
            'Radio buttons'
          ),
          icon: 'dot-circle'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.SelectBoxes:
        return {
          label: this.i18n.translate(
            'common:textSelectBoxes',
            {},
            'Select boxes'
          ),
          icon: 'ballot-check'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.TextArea:
        return {
          label: this.i18n.translate(
            'common:textTextArea',
            {},
            'Text area'
          ),
          icon: 'align-left'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.TextField:
        return {
          label: this.i18n.translate(
            'common:textText',
            {},
            'Text'
          ),
          icon: 'font'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.ExternalAPI:
        return {
          label: this.i18n.translate(
            'common:textExternalAPI',
            {},
            'External API'
          ),
          icon: 'arrow-up-right-from-square'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.Table:
        return {
          label: this.i18n.translate(
            'FORMS:textTable',
            {},
            'Table'
          ),
          icon: 'table'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.DataPoint:
        return {
          label: this.i18n.translate(
            'FORMS:textFieldGroupOption',
            {},
            'Field group option'
          ),
          icon: 'triangle'
        };
      case ReferenceFieldsUI.ReferenceFieldTypes.Address:
        return {
          label: this.i18n.translate(
            'common:textAddress',
            {},
            'Address'
          ),
          icon: 'map-pin'
        };
    }
  }

  getFormattingTypeOptions (): TypeaheadSelectOption<ReferenceFieldAPI.ReferenceFieldFormattingType>[] {
    const baseList = this.arrayHelper.sort([{
      label: this.i18n.translate(
        'common:textEmailWithExample',
        {},
        'Email (e.g. name@host.com)'
      ),
      value: ReferenceFieldAPI.ReferenceFieldFormattingType.EMAIL
    }, {
      label: this.i18n.translate(
        'common:textTimeTwelveHourWithExample',
        {},
        'Time (12 hour, e.g. 12:34 PM)'
      ),
      value: ReferenceFieldAPI.ReferenceFieldFormattingType.TIME_12_HOUR
    }, {
      label: this.i18n.translate(
        'common:textTimeTwentyFourHourWithExample',
        {},
        'Time (24 hour, e.g. 21:00)'
      ),
      value: ReferenceFieldAPI.ReferenceFieldFormattingType.TIME_24_HOUR
    }, {
      label: this.i18n.translate(
        'common:textEINWithExample',
        {},
        'EIN (e.g. 12-1234567)'
      ),
      value: ReferenceFieldAPI.ReferenceFieldFormattingType.EIN
    }, {
      label: this.i18n.translate(
        'common:textURLWithExample',
        {},
        'URL (e.g. www.website.com)'
      ),
      value: ReferenceFieldAPI.ReferenceFieldFormattingType.URL_OPT_HTTP
    }, {
      label: this.i18n.translate(
        'common:textURLHttpRequiredWithExample',
        {},
        'URL (http/https required, e.g. http://website.com)'
      ),
      value: ReferenceFieldAPI.ReferenceFieldFormattingType.URL_REQ_HTTP
    }]);

    baseList.unshift({
      label: this.i18n.translate(
        'common:lblNoFormatting',
        {},
        'No formatting'
      ),
      value: ReferenceFieldAPI.ReferenceFieldFormattingType.NONE
    });

    return baseList;
  }

  getAggregateTypeOptions (): TypeaheadSelectOption<ReferenceFieldAPI.ReferenceFieldAggregateType>[] {
    return this.arrayHelper.sort([{
      label: this.i18n.translate('GLOBAL:textSum', {}, 'Sum'),
      value: ReferenceFieldAPI.ReferenceFieldAggregateType.Sum
    }, {
      label: this.i18n.translate('GLOBAL:textMax', {}, 'Max'),
      value: ReferenceFieldAPI.ReferenceFieldAggregateType.Max
    }, {
      label: this.i18n.translate('GLOBAL:textMin', {}, 'Min'),
      value: ReferenceFieldAPI.ReferenceFieldAggregateType.Min
    }, {
      label: this.i18n.translate('GLOBAL:textCount', {}, 'Count'),
      value: ReferenceFieldAPI.ReferenceFieldAggregateType.Count
    }, {
      label: this.i18n.translate('GLOBAL:textAverage', {}, 'Average'),
      value: ReferenceFieldAPI.ReferenceFieldAggregateType.Average
    }]);
  }

  formatReferenceFieldResponses (
    responseMap: ReferenceFieldsUI.RefResponseMapForAdapting = {},
    applicationId: number,
    applicationFormId: number
  ) {
    Object.keys(responseMap).forEach((key) => {
      const field = this.referenceFieldMap[key];
      const response = responseMap[key];
      responseMap[key].value = this.formatFieldForUi(field, response, applicationId, applicationFormId);
      const isTableOrSubset = [
        ReferenceFieldsUI.ReferenceFieldTypes.Table,
        ReferenceFieldsUI.ReferenceFieldTypes.Subset
      ].includes(field.type);
      if (isTableOrSubset) {
        const rows = response.value as ReferenceFieldsUI.TableResponseRowForUi[];
        rows?.forEach((row) => {
          row.columns.forEach((column) => {
            const columnRefField = this.referenceFieldMapById[column.referenceFieldId];
            if (!!columnRefField) {
              column.value = this.formatFieldForUi(columnRefField, column, applicationId, applicationFormId);
            }
          });
        });
      }
    });
  }

  prepareValueForMapping (
    response: ReferenceFieldAPI.ApplicationRefFieldResponse,
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel
  ): FormAnswerValues {
    let value: FormAnswerValues = response.value || '';
    const isNumber = [
      ReferenceFieldsUI.ReferenceFieldTypes.Aggregate,
      ReferenceFieldsUI.ReferenceFieldTypes.Number,
      ReferenceFieldsUI.ReferenceFieldTypes.DataPoint
    ].includes(field?.type);
    if (isNumber) {
      value = response.numericValue ?? response.value;
    } else if (field?.type === ReferenceFieldsUI.ReferenceFieldTypes.Date) {
      value = response.dateValue || (response.value as string) || '';
      if (value) {
        value = this.dateService.getStartOrEndOfDayInUtc(value);
      }
    }

    return value;
  }

  getFilesFromApplicationRefFieldResponse (
    files: ReferenceFieldAPI.ApplicationRefFieldFile[],
    applicationId: number,
    applicationFormId: number
  ): YcFile[] {
    return (files || []).filter((file) => {
      return !!file?.value;
    }).map((file) => {
      const fileUrl = this.applicationFileService.convertParamsToApplicationFileUrl(
        applicationId,
        applicationFormId,
        file.fileId,
        file.fileName
      );

      return new YcFile(
        file.fileName,
        null,
        fileUrl,
        file.fileId
      );
    });
  }

  mapReferenceFieldResponsesForAPI (
    responses: ReferenceFieldsUI.RefResponseMap,
    isManagerForm: boolean,
    refMapByKey: SimpleStringMap<ReferenceFieldAPI.ReferenceFieldDisplayModel>
  ): ReferenceFieldAPI.ApplicationRefFieldResponseForApi[] {
    return Object.keys(responses || {}).filter((referenceFieldKey) => {
      const field = refMapByKey[referenceFieldKey];
      if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Table) {
        return false;
      }
      if (isManagerForm) {
        return field.formAudience === FormAudience.MANAGER;
      }

      return true;
    }).map((referenceFieldKey) => {
      const field = refMapByKey[referenceFieldKey];
      const {
        value,
        dateValue,
        currencyValue,
        addressValue
      } = this.adaptResponseValueForApi(
        referenceFieldKey,
        responses[referenceFieldKey]
      );

      const returnVal: ReferenceFieldAPI.ApplicationRefFieldResponseForApi = {
        referenceFieldKey,
        referenceFieldId: field?.referenceFieldId,
        value: value ?? '',
        numericValue: null,
        dateValue,
        currencyValue,
        addressValue
      };

      return returnVal;
    });
  }

  formatFieldForUi (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    response: ReferenceFieldAPI.ApplicationRefFieldResponse,
    applicationId: number,
    applicationFormId: number
  ) {
    const value = response.value;
    let adaptedValue = value;
    // All reference field responses are stored as a string,
    // so parse to get boolean value for checkbox
    // and parse to get array for any that support multiple
    if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Checkbox) {
      adaptedValue = this.convertRefValueToBool(value);
    } else if (field.supportsMultiple) {
      if (value) {
        switch (field.type) {
          case ReferenceFieldsUI.ReferenceFieldTypes.TextArea:
          case ReferenceFieldsUI.ReferenceFieldTypes.TextField:
          case ReferenceFieldsUI.ReferenceFieldTypes.FileUpload:
            // For text fields with multiple, we separate the answers with a pipe
            adaptedValue = this.convertPipeSeparatedAnswersToArray(value as string);
            if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload) {
              adaptedValue = this.getFilesFromApplicationRefFieldResponse(
                response.files,
                applicationId,
                applicationFormId
              );
            } else {
              if (adaptedValue.length === 0) {
                // Text fields and areas with multiple require at least one item so input shows
                adaptedValue = [''];
              }
            }
            break;
          default:
            // Everything else multiple, we separate with a comma
            adaptedValue = (value as string).split(',').filter(item => item);
            break;
        }
      } else {
        adaptedValue = [];
      }
    } else if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload) {
      adaptedValue = this.getFilesFromApplicationRefFieldResponse(
        [response.file],
        applicationId,
        applicationFormId
      );
    } else if (
      field.type === ReferenceFieldsUI.ReferenceFieldTypes.DataPoint ||
      field.type === ReferenceFieldsUI.ReferenceFieldTypes.Number
    ) {
      adaptedValue = this.convertRefValueToNumber(value);
    } else if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Currency) {
      const amount = response.numericValue ?? response.value as number;
      const currency = response.currencyValue;
      adaptedValue = {
        amountInDefaultCurrency: amount,
        amountEquivalent: amount,
        amountForControl: amount,
        currency
      };
    } else if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Address) {
      adaptedValue = response.addressValue;
    }

    return adaptedValue;
  }

  convertRefValueToNumber (response: FormAnswerValues) {
    return (response || response === 0) ? parseFloat(response as string) : null;
  }

  convertRefValueToBool (response: FormAnswerValues) {
    if (response) {
      return JSON.parse(response as string);
    } else {
      return false;
    }
  }

  adaptResponseValueForApi (
    key: string,
    value: FormAnswerValues
  ): {
    value: string;
    dateValue: string;
    currencyValue: string;
    addressValue: ReferenceFieldAPI.FormFieldAddressResponse;
  } {
    const field = this.referenceFieldMap[key];
    const dateValue = this.adaptResponseDateValueForApi(value, field);
    const isCurrency = field.type === ReferenceFieldsUI.ReferenceFieldTypes.Currency;
    let currencyValue = '';
    let addressValue: ReferenceFieldAPI.FormFieldAddressResponse = null;
    if (isCurrency) {
      currencyValue = (value as CurrencyValue)?.currency;
    }
    // Before we send to API, convert all responses to strings
    if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload) {
      const fileUrls = (value as YcFile<File>[] || []).map((file) => {
        return file.fileUrl;
      });

      value = this.convertPipeSeparatedAnswersToString(fileUrls);
    } else if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Checkbox) {
      value = value ? 'true' : 'false';
    } else if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Currency) {
      value = (value as CurrencyValue)?.amountForControl;
    } else if (value instanceof Array) {
      if (
        field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextArea ||
        field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextField
      ) {
        value = this.convertPipeSeparatedAnswersToString(value as string[]);
      } else {
        const sorted = this.arrayHelper.sort(
          ((value as string[]) || [])
        );

        value = sorted.join(',');
      }
    } else if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Date) {
      value = dateValue;
    } else if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Address) {
      if (!!value) {
        addressValue = value as ReferenceFieldAPI.FormFieldAddressResponse;
        value = addressValue.formattedAddress;
      }
    }

    return {
      value: ((value ?? '') as string).toString(),
      dateValue,
      currencyValue,
      addressValue
    };
  }

  adaptResponseDateValueForApi (
    value: FormAnswerValues,
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel
  ): string {
    // dateValue should be null for all non-dates
    if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Date) {
      if (!!value) {
        return this.dateService.formatStartOfDay(this.dateService.formatDate(
          value as string,
          TIMESTAMP_FORMAT
        ));
      }
    }

    return null;
  }

  convertPipeSeparatedAnswersToArray (response: string) {
    return response.split(/(?!\\)\|/).map((item, i, arr) => {
      if (item && item.endsWith('\\')) {
        arr[i + 1] = item.slice(0, -1) + '|' + arr[i + 1];
        item = null;
      }

      return item;
    }).filter(item => item !== null);
  }

  convertPipeSeparatedAnswersToString (value: string[]) {
    return (value || []).map((item) => {
      return item.replace(/\|/g, '\\|');
    }).join('|');
  }

  adaptAggregateTypeFromImport (
    aggregateType: 'sum'|'min'|'max'|'count'|'average'
  ) {
    const types = ReferenceFieldAPI.ReferenceFieldAggregateType;
    switch (aggregateType) {
      case 'sum':
        return types.Sum;
      case 'min':
        return types.Min;
      case 'max':
        return types.Max;
      case 'count':
        return types.Count;
      case 'average':
        return types.Average;
    }
  }

  formatPaginationOptions (
    paginationOptions: PaginationOptions<ReferenceFieldAPI.ReferenceFieldDisplayModel>
  ): {
    paginationOptions: PaginationOptions<ReferenceFieldAPI.ReferenceFieldDisplayModel>;
    formIds: number[];
  } {
    const formIdsFilterIndex = paginationOptions.filterColumns.findIndex((col) => {
      return col.columnName === 'formIds';
    });
    const formIdsFilter = paginationOptions.filterColumns[formIdsFilterIndex];
    if (formIdsFilterIndex > -1) {
      paginationOptions = {
        ...paginationOptions,
        filterColumns: [
          ...paginationOptions.filterColumns.slice(0, formIdsFilterIndex),
          ...paginationOptions.filterColumns.slice(formIdsFilterIndex + 1)
        ]
      };
    }
    let formIds: number[];
    if (formIdsFilter) {
      formIds = formIdsFilter.filters.map((filter) => {
        return +filter.filterValue;
      });
    }

    return {
      paginationOptions,
      formIds
    };
  }

  updateApplicationWithNewValues (
    applicationFieldChanges: FormChanges[],
    application: Partial<BaseApplication>
  ) {
    applicationFieldChanges.forEach((change) => {
      switch (change.type) {
        case 'amountRequested':
          const details = (change.value as CurrencyValue);
          application.amountRequestedForEdit = details.amountForControl;
          application.currencyRequested = details.currency || application.currencyRequested;
          break;
        case 'inKindItems':
          application.inKindItems = change.value as InKindRequestedItem[] || [];
          break;
        case 'designation':
          application.designation = change.value as string;
          break;
        case 'careOf':
          application.careOf = change.value as string;
          break;
        case 'specialHandling':
          application.specialHandling = change.value as SpecialHandling;
          break;
        case 'decision':
          application.decision = change.value as FormDecisionTypes;
          break;
        case 'reviewerRecommendedFundingAmount':
          const value = (change.value as CurrencyValue);
          application.reviewerRecommendedFundingAmount = value.amountForControl;
          break;
        case 'reportField':
        case 'employeeSSO':
          break;
      }
    });
  }

  /**
   *
   * @param field: the reference field
   * @param component: the form component
   * @param applyDefaultValue: should we apply the default?
   * @returns the blank value for the field type
   */
  getBlankValueForFormField (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    component: FormDefinitionComponent,
    applyDefaultValue: boolean
  ): FormAnswerValues {
    if (!!field) {
      if (
        field.supportsMultiple ||
        field.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload
      ) {
        const requiresOneItem = [
          ReferenceFieldsUI.ReferenceFieldTypes.TextField,
          ReferenceFieldsUI.ReferenceFieldTypes.TextArea
        ].includes(field.type);
        if (requiresOneItem) {
          return [''];
        }

        return [];
      } else if (
        field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextField ||
        field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextArea ||
        field.type === ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable
      ) {
        if (applyDefaultValue && !!component.defaultVal) {
          component.appliedDefaultVal = true;

          return component.defaultVal;
        }

        return '';
      } else if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Checkbox) {
        // If the checkbox has no value (has never been touched)
        // We want the value of "false" to be autosaved
        if (applyDefaultValue) {
          component.appliedDefaultVal = true;

          return false;
        }
      } else if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Currency) {
        const defaultCurrency = this.clientSettingsService.defaultCurrency;
        const currencyOptions = this.currencyService.getCurrencyOptionsForComponent(
          '',
          component.useCustomCurrency,
          component.customCurrency
        );
        let amountForControl = null;
        if (applyDefaultValue && !!component.defaultVal) {
          component.appliedDefaultVal = true;

          amountForControl = +component.defaultVal;
        }

        return {
          amountInDefaultCurrency: null,
          amountEquivalent: null,
          amountForControl,
          currency: this.componentHelper.getCurrencyForFormFieldControl(
            null,
            component.useCustomCurrency,
            component.customCurrency,
            currencyOptions,
            defaultCurrency,
            this.userService.get('lastSelectedCurrency')
          )
        };
      }
    }

    if (applyDefaultValue && !!component.defaultVal) {
      component.appliedDefaultVal = true;

      return component.defaultVal;
    }

    return null;
  }

  handleChangeTracking (
    changes: FormChanges[],
    application: Partial<BaseApplication>
  ): FormChangeTracker {
    let appNeedsUpdated = false;
    const refChangeTracker: ReferenceFieldsUI.RefResponseMap = {};
    const applicationFieldChanges = changes.filter((change) => {
      return !change.isReferenceField;
    });
    if (applicationFieldChanges.length > 0) {
      appNeedsUpdated = true;
      this.updateApplicationWithNewValues(
        applicationFieldChanges,
        application
      );
    }
    const refFieldChanges = changes.filter((change) => change.isReferenceField);
    if (refFieldChanges.length > 0) {
      refFieldChanges.forEach((change) => {
        const refField = this.referenceFieldMap[change.key];
        const answer = change.value;
        const untrackedField = !!refField.aggregateType;
        // make sure we aren't clearing out answers
        // when a user doesn't have permission to view masked data
        // also make sure we aren't trying to save aggregate fields which should be untracked
        if (
          (!refField.isMasked || answer !== '') &&
          !untrackedField
        ) {
          refChangeTracker[change.key] = answer;
          application.referenceFields[change.key] = answer;
        }
      });
    }

    return {
      appNeedsUpdated,
      refChangeTracker
    };
  }

  /**
   *
   * @param type: reference field type
   * @returns can it be multi?
   */
  canBeMulti (type: ReferenceFieldsUI.ReferenceFieldTypes) {
    return [
      ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable,
      ReferenceFieldsUI.ReferenceFieldTypes.TextArea,
      ReferenceFieldsUI.ReferenceFieldTypes.TextField,
      ReferenceFieldsUI.ReferenceFieldTypes.FileUpload,
      ReferenceFieldsUI.ReferenceFieldTypes.SelectBoxes
    ].includes(type);
  }

  canBeTableField (
    type: ReferenceFieldsUI.ReferenceFieldTypes,
    supportsMultiple: boolean,
    formAudience: FormAudience,
    isRichText: boolean,
    isSingleResponse: boolean
  ) {
    const typePasses = [
      ReferenceFieldsUI.ReferenceFieldTypes.TextField,
      ReferenceFieldsUI.ReferenceFieldTypes.TextArea,
      ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable,
      ReferenceFieldsUI.ReferenceFieldTypes.Number,
      ReferenceFieldsUI.ReferenceFieldTypes.Checkbox,
      ReferenceFieldsUI.ReferenceFieldTypes.Date,
      ReferenceFieldsUI.ReferenceFieldTypes.Radio,
      ReferenceFieldsUI.ReferenceFieldTypes.Currency,
      ReferenceFieldsUI.ReferenceFieldTypes.FileUpload,
      ReferenceFieldsUI.ReferenceFieldTypes.Address
    ].includes(type);
    const passesSupportsMulti = supportsMultiple && type === ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable ?
      true :
      !supportsMultiple;

    return typePasses &&
      passesSupportsMulti &&
      (formAudience === FormAudience.APPLICANT || (formAudience === FormAudience.MANAGER && isSingleResponse)) &&
      !isRichText;
  }

  /**
   * Should we show the supports multiple checkbox?
   * Selectboxes are required to be multi, so we don't show the option
   *
   * @param type: reference field type
   * @param isRichText: Is rich text field?
   * @returns do we show the checkbox?
   */
  showSupportsMultiCheckbox (
    type: ReferenceFieldsUI.ReferenceFieldTypes,
    isRichText: boolean
  ) {
    return !isRichText && [
      ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable,
      ReferenceFieldsUI.ReferenceFieldTypes.TextArea,
      ReferenceFieldsUI.ReferenceFieldTypes.TextField,
      ReferenceFieldsUI.ReferenceFieldTypes.FileUpload
    ].includes(type);
  }

  isCorrectTypeForMaskAndEncyrpt (
    type: ReferenceFieldsUI.ReferenceFieldTypes,
    isTableField: boolean
  ) {
    const passesType = [
      ReferenceFieldsUI.ReferenceFieldTypes.Number,
      ReferenceFieldsUI.ReferenceFieldTypes.TextField,
      ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable,
      ReferenceFieldsUI.ReferenceFieldTypes.Radio,
      ReferenceFieldsUI.ReferenceFieldTypes.Date,
      ReferenceFieldsUI.ReferenceFieldTypes.SelectBoxes,
      ReferenceFieldsUI.ReferenceFieldTypes.ExternalAPI,
      ReferenceFieldsUI.ReferenceFieldTypes.TextArea,
      ReferenceFieldsUI.ReferenceFieldTypes.FileUpload
    ].includes(type);

    return passesType && !isTableField;
  }

  doesTypeHaveOptions (type: ReferenceFieldsUI.ReferenceFieldTypes) {
    return [
      ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable,
      ReferenceFieldsUI.ReferenceFieldTypes.Radio,
      ReferenceFieldsUI.ReferenceFieldTypes.SelectBoxes
    ].includes(type);
  }

  getSupportsMultipleSeparator (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel
  ) {
    if (!field || !field.supportsMultiple) {
      return '';
    }

    switch (field.type) {
      case ReferenceFieldsUI.ReferenceFieldTypes.TextArea:
      case ReferenceFieldsUI.ReferenceFieldTypes.TextField:
      case ReferenceFieldsUI.ReferenceFieldTypes.FileUpload:
        return '|';
      default:
        return ',';
    }
  }

  extractReferenceFieldsFromForm (
    definitions: FormDefinitionForUi[],
    allFields: ReferenceFieldAPI.ReferenceFieldDisplayModel[]
  ): {
      referenceFieldIds: FormRevisionRefFields[];
      customDataTableGuids: string[];
      referenceFields: ReferenceFieldAPI.ReferenceFieldDisplayModel[];
      nominationReferenceFieldIds: number[];
      employeeApplicantInfoKeys: string[];
      nominatorInfoKeys: string[];
    } {
    const referenceFieldIds: FormRevisionRefFields[] = [];
    const customDataTableGuids: string[] = [];
    const referenceFields: ReferenceFieldAPI.ReferenceFieldDisplayModel[] = [];
    const nominationReferenceFieldIds: number[] = [];
    const employeeApplicantInfoKeys: string[] = [];
    const nominatorInfoKeys: string[] = [];
    definitions.forEach((definition) => {
      this.componentHelper.eachComponent(definition.components, (component) => {
        if (this.componentHelper.isReferenceFieldComp(component.type)) {
          const key = this.componentHelper.getRefFieldKeyFromCompType(component.type);
          const found = allFields.find((field) => {
            return field.key === key;
          });
          if (found) {
            referenceFieldIds.push({
              referenceFieldId: found.referenceFieldId,
              pullFromBBGM: component.pullFromBBGM
            });
            referenceFields.push(found);
            const dataTableGuid = found.customDataTableGuid;
            if (dataTableGuid) {
              customDataTableGuids.push(dataTableGuid);
            }
          }
        } else if (this.componentHelper.isReportFieldComp(component.type)) {
          const object = component.reportFieldDataOptions.reportFieldObject;
          const key = component.reportFieldDataOptions.reportFieldDisplay;
          if (object === 'relatedNominator') {
            if (key === 'fullName' || key === 'email') {
              nominatorInfoKeys.push(key);
            } else {
              employeeApplicantInfoKeys.push(key);
            }
          } else if (this.componentHelper.isReportNominationField(object)) {
            const refId = this.referenceFieldMap[key].referenceFieldId;
            nominationReferenceFieldIds.push(refId);
          }
        }
      });
    });

    return {
      referenceFieldIds,
      customDataTableGuids: uniq(customDataTableGuids),
      referenceFields,
      nominationReferenceFieldIds,
      employeeApplicantInfoKeys,
      nominatorInfoKeys
    };
  }

  /**
   *
   * @param canBeDeleted: Can the field be deleted?
   * @param formCount number of forms this component is used on
   * @param usedOnReports boolean true means field is used on reports
   * @param referenceFieldId used to check for parent/child field relationship. cannot remove if parent
   * @param standardComponentIsPublished true if published standard product field, we don't support removing these
   * @param isRootZone if true we allow removing standard product fields that are not published
   * @returns string if unable to remove, void if able to remove
   */
  getTooltipForDeleteRefField (
    canBeDeleted: boolean,
    formCount: number,
    usedOnReports: boolean,
    referenceFieldId: number,
    standardComponentIsPublished: boolean,
    isRootZone: boolean,
    usedOnATable: boolean,
    type: ReferenceFieldsUI.ReferenceFieldTypes
  ) {
    if (!canBeDeleted) {
      if (isRootZone && standardComponentIsPublished) {
        return this.i18n.translate(
          'FORMS:textCannotDeletePublishedRootZoneField',
          {},
          'This field cannot be removed because it is published'
        );
      } else if (standardComponentIsPublished) {
        return this.i18n.translate(
          'FORMS:textCannotDeleteStandardProductField',
          {},
          'Standard product fields cannot be removed'
        );
      }
      if (formCount > 0) {
        return this.i18n.translate(
          'FORMS:textCannotRemoveReferenceField',
          {
            count: formCount
          },
          'This field cannot be removed, it is currently in use on __count__ form(s)'
        );
      } else if (usedOnReports) {
        return this.i18n.translate(
          'FORMS:textCannotRemoveReferenceFieldOnReport',
          {},
          'This field cannot be removed, it is currently in use on at least one report'
        );
      } else if (this.isParentRefFieldMap[referenceFieldId]) {
        return this.i18n.translate(
          'FORMS:textCannotRemoveReferenceFieldParent',
          {},
          'This field cannot be removed because it relates to another field'
        );
      } else if (usedOnATable && type !== ReferenceFieldsUI.ReferenceFieldTypes.DataPoint) {
        return this.i18n.translate(
          'FORMS:textCannotRemoveReferenceFieldOnTable',
          {},
          'This field cannot be removed because it belongs to a table'
        );
      } else if (usedOnATable && type === ReferenceFieldsUI.ReferenceFieldTypes.DataPoint) {
        return this.i18n.translate(
          'FORMS:textCannotRemoveReferenceFieldBelongsToFieldGroup',
          {},
          'This field cannot be removed because it belongs to a field group'
        );
      } else {
        return this.i18n.translate(
          'FORMS:textThisFieldCannotBeDeleted',
          {},
          'This field cannot be deleted'
        );
      }
    }

    return '';
  }

  guessKey (
    name: string,
    additionalGeneratedKeys: string[] = []
  ) {
    const pascal = this.inflect.pascalize(
      (name.match(/[a-z]|[A-Z]|\ |\d/g) || []).join('')
    );
    let guessedKey = pascal ? pascal[0].toLowerCase() + pascal.slice(1) : '';
    const isRootZone = this.clientSettingsService.clientSettings.isRootClient;
    const prependedText = isRootZone ? STANDARD_FIELD_PREFIX : '';
    if (prependedText) {
      guessedKey = prependedText + guessedKey;
    }

    return this.guessBasedOnExisting(guessedKey, 'key', 0, additionalGeneratedKeys);
  }

  guessBasedOnExisting (
    val: string,
    prop: 'key'|'name',
    incCount = 0,
    additionalGeneratedKeys: string[] = []
  ): string {
    const desired = `${val}${incCount ? `${prop === 'name' ? ' ' : ''}${incCount + 1}` : ''}`;
    const allExisting = this.allReferenceFields.map((field) => field[prop]);
    const allExistingAndAdditional = allExisting.concat(additionalGeneratedKeys);
    const existingKey = allExistingAndAdditional.find(keyOrName => {
      const existing = keyOrName || '';

      return existing.toLowerCase() === desired.toLowerCase();
    });

    if (existingKey) {
      return this.guessBasedOnExisting(
        val,
        prop,
        incCount + 1,
        additionalGeneratedKeys
      );
    }

    return desired;
  }

  /**
   * When pasting a copied form component, this ensures we are setting the correct parentReferenceFieldKey
   *
   * @param newFields: New copied fields we will create with the bulk insert
   * @param newKeyToOldKeyMap: map of new keys to old keys when copying
   */
  updateCopiedParentKeys (
    newFields: ReferenceFieldAPI.BulkCreateReferenceField[],
    newKeyToOldKeyMap: Record<string, string>
  ) {
    newFields.forEach((field) => {
      if (!!field.parentReferenceFieldKey) {
        // If we are copying a reference field that has a parent,
        // It's likely that we are also making a copy of that parent field.
        // If so, we need to re-map the parent to the newly created parent
        // as opposed to the old parent.
        const foundParentToCopy = newFields.find((_field) => {
          const oldFieldKey = newKeyToOldKeyMap[_field.key];

          return oldFieldKey === field.parentReferenceFieldKey;
        });
        if (!!foundParentToCopy) {
          field.parentReferenceFieldKey = foundParentToCopy.key;
        }
      }
    });
  }

  adaptReferenceFields<C extends boolean> (
    records: ReferenceFieldAPI.ReferenceFieldPaginatedModel[]|ReferenceFieldAPI.ReferenceFieldDisplayModel[],
    isPaginatedModel: C
  ): C extends true ?
    ReferenceFieldAPI.ReferenceFieldPaginatedModelForUi[] :
    ReferenceFieldAPI.ReferenceFieldDisplayModel[] {
    const adapted = records.map((record) => {
      if (
        record.type === ReferenceFieldsUI.ReferenceFieldTypes.Number &&
        record.aggregateType
      ) {
        // the API stores aggregate reference fields as numbers
        // we need to make the UI aware of this
        record.type = ReferenceFieldsUI.ReferenceFieldTypes.Aggregate;
      }
      if (!record.categoryId) {
        // This will represent No category or "Other"
        record.categoryId = 0;
      }
      if (!record.formAudience) {
        record.formAudience = FormAudience.APPLICANT;
      }
      if (record.type === ReferenceFieldsUI.ReferenceFieldTypes.SelectBoxes) {
        record.supportsMultiple = true;
      }

      if (isPaginatedModel) {
        const isAggregate = !!record.aggregateType;
        const adapted: ReferenceFieldAPI.ReferenceFieldPaginatedModelForUi = {
          ...record as ReferenceFieldAPI.ReferenceFieldPaginatedModel,
          isAggregate,
          notInUse: (record as ReferenceFieldAPI.ReferenceFieldPaginatedModel).canBeDeleted,
          typeMapped: {
            id: record.type,
            translated: this.getFieldTypeTranslatedWithIcon(record.type).label
          }
        };

        return adapted;
      } else {
        return record as ReferenceFieldAPI.ReferenceFieldDisplayModel;
      }
    });

    return adapted as C extends true ?
      ReferenceFieldAPI.ReferenceFieldPaginatedModelForUi[] :
      ReferenceFieldAPI.ReferenceFieldDisplayModel[];
  }

  getEditFormSupportsSettings (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    formAudience: FormAudience
  ) {
    const isForDisplayOnly = field.formAudience !== formAudience;
    const {
      isCheckbox,
      isRadio,
      isSelectBoxes,
      isNumber,
      isTextArea,
      isTextField,
      isFileUpload,
      isTable,
      isDate,
      isCurrencyField,
      isSubset,
      isAggregateField,
      isExternalApiField,
      isAddress
    } = this.getTypeHelpers(field);
    const supportsDefaultValue = !isCheckbox &&
      !field.supportsMultiple &&
      !isSelectBoxes &&
      !isAddress &&
      !isFileUpload &&
      !isAggregateField &&
      !isExternalApiField &&
      !isTable &&
      !isSubset &&
      !field.isRichText &&
      !isForDisplayOnly;
    const defaultValType = supportsDefaultValue ?
      (isDate ? DefaultValType.Date : DefaultValType.Text) :
      DefaultValType.None;
    const supportsPlaceholder = !isCheckbox &&
      !isRadio &&
      !isSelectBoxes &&
      !isAggregateField &&
      !isCurrencyField &&
      !isTable &&
      !isSubset &&
      !isExternalApiField &&
      !field.isRichText;
    const supportsWordCount = isTextArea || isTextField;
    const supportsMinMax = (
      isNumber ||
      isTable ||
      isSubset ||
      (isFileUpload && field?.supportsMultiple) ||
      (!!field?.customDataTableGuid && field?.supportsMultiple)
    ) && !isAggregateField && !isForDisplayOnly;
    const supportsDataTab = !((isTextArea || isTextField) && field.supportsMultiple) &&
      !isFileUpload &&
      !isAggregateField &&
      !isTable &&
      !isSubset &&
      !isForDisplayOnly;
    const supportsValidationTab = !isAggregateField && !isExternalApiField;
    const supportsDisabled = !isAggregateField &&
      !isExternalApiField &&
      !isForDisplayOnly &&
      !field.isRichText;
    const supportsTooltip = !isCurrencyField && !isExternalApiField;
    const supportsClearWhenHidden = !isAggregateField &&
      !isTable &&
      !isSubset &&
      !isForDisplayOnly;
    const supportsHideLabel = !isCheckbox && !isExternalApiField;
    const supportsSetValueTab = !isFileUpload &&
      !isTable &&
      !isAggregateField &&
      !isSubset &&
      !isExternalApiField &&
      !isAddress &&
      !field.isRichText &&
      !isForDisplayOnly;
    const supportsFormulaBuilder = (isNumber || isCurrencyField) && !isForDisplayOnly;

    return {
      defaultValType,
      supportsDisabled,
      supportsFormulaBuilder,
      supportsMinMax,
      supportsPlaceholder,
      supportsSetValueTab,
      supportsTooltip,
      supportsValidationTab,
      supportsWordCount,
      supportsClearWhenHidden,
      supportsDataTab,
      supportsHideLabel
    };
  }

  getTypeHelpers (field: ReferenceFieldAPI.ReferenceFieldDisplayModel) {
    const isCheckbox = field.type === ReferenceFieldsUI.ReferenceFieldTypes.Checkbox;
    const isRadio = field.type === ReferenceFieldsUI.ReferenceFieldTypes.Radio;
    const isDate = field.type === ReferenceFieldsUI.ReferenceFieldTypes.Date;
    const isSelectBoxes = field.type === ReferenceFieldsUI.ReferenceFieldTypes.SelectBoxes;
    const isNumber = field.type === ReferenceFieldsUI.ReferenceFieldTypes.Number;
    const isTextField = field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextField;
    const isTextArea = field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextArea;
    const isFileUpload = field.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload;
    const isTable = field.type === ReferenceFieldsUI.ReferenceFieldTypes.Table;
    const isAggregateField = field.type === ReferenceFieldsUI.ReferenceFieldTypes.Aggregate;
    const isCurrencyField = field.type === ReferenceFieldsUI.ReferenceFieldTypes.Currency;
    const isSubset = field.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset;
    const isDependentPicklist = field.customDataTableGuid &&
      field.parentReferenceFieldId;
    const isExternalApiField = field.type === ReferenceFieldsUI.ReferenceFieldTypes.ExternalAPI;
    const isAddress = field.type === ReferenceFieldsUI.ReferenceFieldTypes.Address;

    return {
      isCheckbox,
      isRadio,
      isAggregateField,
      isDate,
      isSelectBoxes,
      isNumber,
      isTextArea,
      isTextField,
      isFileUpload,
      isTable,
      isCurrencyField,
      isSubset,
      isDependentPicklist,
      isExternalApiField,
      isAddress
    };
  }

  convertMergeFieldsToOneField (
    fields: ReferenceFieldAPI.ReferenceFieldDisplayModel[]
  ): ReferenceFieldAPI.ReferenceFieldDisplayModel {
    const mappedName = uniq(fields.map((field) => field.name));
    const mappedDefaultLabel = uniq(fields.map((field) => field.defaultLabel));
    const mappedDesc = uniq(fields.map((field) => field.description));
    const mappedCategory = uniq(fields.map((field) => field.categoryId));
    const mappedGuids = uniq(fields.map((field) => field.customDataTableGuid));
    const mappedParentFieldIds = uniq(fields.map((field) => field.parentReferenceFieldId));
    const mappedResponseType = uniq(fields.map((field) => field.isSingleResponse));
    const mappedMulti = fields.every((field) => field.supportsMultiple);
    const mappedEncrypted = fields.every(field => field.isEncrypted);
    const mappedMasked = fields.every(field => field.isMasked);
    const mappedTableField = fields.every(field => field.isTableField);
    const mappedFormatType = fields.map(field => field.formatType);
    const mappedIsRichText = fields.map(field => field.isRichText);

    return {
      name: mappedName.length === 1 ? mappedName[0] : '',
      defaultLabel: mappedDefaultLabel.length === 1 ? mappedName[0] : '',
      description: mappedDesc.length === 1 ? mappedDesc[0] : '',
      key: '',
      type: fields[0].type,
      formAudience: fields[0].formAudience,
      categoryId: mappedCategory.length === 1 ? mappedCategory[0] : null,
      customDataTableGuid: mappedGuids.length === 1 ? mappedGuids[0] : '',
      parentReferenceFieldId: mappedParentFieldIds.length === 1 ?
        mappedParentFieldIds[0] :
        null,
      supportsMultiple: mappedMulti,
      aggregateType: null,
      isSingleResponse: mappedResponseType.length === 1 ?
        mappedResponseType[0] :
        false,
      isEncrypted: mappedEncrypted,
      isMasked: mappedMasked,
      isRichText: mappedIsRichText.length === 1 ? mappedIsRichText[0] : false,
      tableAllowsImport: false,
      isTableField: mappedTableField,
      aggregateTableReferenceFieldId: null,
      referenceFieldTableId: null,
      formatType: mappedFormatType?.[0],
      standardComponentIsPublished: true,
      isStandardProductField: false,
      subsetCollectionType: null,
      referenceFieldId: null,
      formCount: 0,
      usedOnReports: false,
      createdBy: '',
      createDate: '',
      updatedBy: '',
      updateDate: '',
      usedOnATable: false
    };
  }

  getMergeInvalidAlert (
    fieldsToMerge: ReferenceFieldAPI.ReferenceFieldDetail[]
  ) {
    const field1Forms = (fieldsToMerge[0].forms || []).map((form) => {
      return `${form.formId}.${form.formRevisionId}`;
    });
    const field2Forms = (fieldsToMerge[1].forms || []).map((form) => {
      return `${form.formId}.${form.formRevisionId}`;
    });
    const overlap = intersection(field1Forms, field2Forms);
    if (overlap.length > 0) {
      let formsString = '';
      const formsList = this.arrayHelper.sort(overlap.map((form) => {
        return this.translationService.viewTranslations.FormTranslation[
          form.split('.')[0]
        ]?.Name;
      }));
      formsList.forEach((formName) => {
        formsString += `<li>
          ${formName}
        </li>`;
      });
      const invalidFormMessage = this.i18n.translate(
        'FORMS:textCannotMergeFieldsBecauseOnSameForm',
        {},
        'The selected fields cannot be merged because they are on the same form. Form fields can only exist on one form at a time. Merging these fields would result in an invalid form.'
      );

      return `
        ${invalidFormMessage}
        <br>
        ${formsString}
      `;
    }

    return '';
  }

  convertCheckboxValueToNumber (value: boolean): number {
    if (value === true) {
      return 1;
    } else if (value === false) {
      return 0;
    } else {
      return null;
    };
  }

  convertNumberToCheckboxValue (value: number): boolean {
    if (value === 1) {
      return true;
    } else if (value === 0) {
      return false;
    } else {
      return false;
    };
  }

  getFilterForNameOrKey () {
    return new TopLevelFilter(
      'text',
      'nameOrKeyOrLabel',
      '',
      this.i18n.translate(
        'common:textSearchByNameKeyOrDefaultLabel',
        {},
        'Search by name, key, or default label'
      ),
      undefined,
      undefined,
      [{
        column: 'name',
        filterType: 'cn'
      }, {
        column: 'key',
        filterType: 'cn'
      }, {
        column: 'defaultLabel',
        filterType: 'cn'
      }]
    );
  }

  /* If the response is no longer on the form, remove it from responses map */
  filterOutResponsesNoLongerOnForm (
    formDefinition: FormDefinitionForUi[],
    responses: ReferenceFieldsUI.RefResponseMapForAdapting
  ) {
    const refKeysOnForm: string[] = [];
    formDefinition.forEach((tab) => {
      this.componentHelper.eachComponent(tab.components, (component) => {
        const key = this.componentHelper.getRefFieldKeyFromCompType(component.type);
        if (key) {
          refKeysOnForm.push(key);
        }
      });
    });

    Object.keys(responses).forEach((key) => {
      if (!refKeysOnForm.includes(key)) {
        delete responses[key];
      }
    });
  }

  getDuplicateFieldAlert (
    existingFieldId: number,
    formVal: ReferenceFieldAPI.ReferenceFieldBaseModel
  ) {
    if (
      !existingFieldId &&
      formVal.name &&
      formVal.categoryId &&
      formVal.type &&
      formVal.formAudience
    ) {
      const found = this.allReferenceFields.find((field) => {
        const matchesBasics = (field.name.toLowerCase() === formVal.name.toLowerCase()) &&
          (field.categoryId === formVal.categoryId) &&
          (field.type === formVal.type) &&
          (field.formAudience === formVal.formAudience);
        if (!matchesBasics) {
          return false;
        }
        let matchesMultiSettings = true;
        if (
          formVal.type === ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable ||
          formVal.type === ReferenceFieldsUI.ReferenceFieldTypes.TextArea ||
          formVal.type === ReferenceFieldsUI.ReferenceFieldTypes.TextField ||
          formVal.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload
        ) {
          matchesMultiSettings = field.supportsMultiple === formVal.supportsMultiple;
        }
        if (!matchesMultiSettings) {
          return false;
        }
        let matchesDataTable = true;
        if (this.doesTypeHaveOptions(formVal.type)) {
          matchesDataTable = field.customDataTableGuid === formVal.customDataTableGuid;
        }

        return matchesBasics && matchesMultiSettings && matchesDataTable;
      });

      if (found) {
        return this.i18n.translate(
          'FORMS:textSettingsMatchAnExistingField',
          {
            fieldType: this.getFieldTypeTranslatedWithIcon(
              formVal.type
            ).label.toLowerCase()
          },
          'The form field settings you entered match an existing __fieldType__ field.'
        );
      }
    }

    return undefined;
  }

  areAttributesTheSame<T extends ReferenceFieldAPI.ReferenceFieldBaseModel> (
    fields: T[]
  ) {
    const field1 = fields[0];
    const field2 = fields[1];

    return AttributesToMatchForMergeField.every((attr) => {
      return field1[attr] === field2[attr];
    });
  }

  validateAttributesMatch<T extends ReferenceFieldAPI.ReferenceFieldBaseModel> (
    fields: T[]
  ) {
    if (fields.length === 2) {
      const isValid = this.areAttributesTheSame(fields);

      if (isValid) {
        return [];
      } else {
        return [{
          i18nKey: 'common:textFieldsNotSimilarMErge',
          defaultValue: 'The selected fields are not similar enough to merge.'
        }];
      }
    }

    return [];
  }


  getFieldsAvailableToMergeWithStandard (
    standardRefFieldId: number
  ): ReferenceFieldAPI.ReferenceFieldDisplayModel[] {
    const standardField = this.referenceFieldMapById[standardRefFieldId];

    return this.allReferenceFields.filter((field) => {
      return !field.isStandardProductField &&
        this.areAttributesTheSame(
          [
            standardField,
            field
          ]
        );
    });
  }

  convertRefFieldKeysToIds (keys: string[]): number[] {
    return (keys || []).filter((key) => {
      return this.referenceFieldMap[key];
    }).map((key) => {
      return this.referenceFieldMap[key].referenceFieldId;
    });
  }

  getExistingFields (
    rows: ReferenceFieldsUI.TableFieldForUi[],
    availableFields: ReferenceFieldAPI.ReferenceFieldDisplayModel[]
  ) {
    const usedIds = rows.filter((row) => {
      return !!row.referenceFieldId;
    }).map((row) => row.referenceFieldId);
    const unusedFields = availableFields.filter((field) => {
      return !usedIds.includes(field.referenceFieldId);
    });

    return unusedFields.length > 0;
  }

  getAvailableFieldsMap (
    rows: ReferenceFieldsUI.TableFieldForUi[],
    availableFields: ReferenceFieldAPI.ReferenceFieldDisplayModel[]
  ) {
    return rows.reduce((acc, _, index) => {
      /* Determine which table/subset field ids are already used */
      const usedIds = rows.filter((_row, _index) => {
        return !!_row.referenceFieldId &&
          index !== _index;
      }).map((_row) => _row.referenceFieldId);
      const availableFieldsSorted = this.arrayHelper.sort(
          availableFields.filter((field) => {
          return !usedIds.includes(field.referenceFieldId);
        }).map((field) => {
          return {
            label: field.name,
            value: field.referenceFieldId
          };
        }),
        'label'
      );

      return {
        ...acc,
        [index]: availableFieldsSorted
      };
    }, {});
  }

  /**
   * Gets the icon to display with the component in the toolbox
   *
   * @param type: Component type
   * @returns the component's icon to display on the toolbox
   */
  getFieldIcon (type: string): string {
    if (this.componentHelper.isEmployeeSsoComponent(type)) {
      return 'user';
    }
    if (this.componentHelper.isReferenceFieldComp((type))) {
      const field = this.getReferenceFieldFromCompType(type);

      return this.getFieldTypeTranslatedWithIcon(field.type).icon;
    }
    switch (type) {
      case 'content':
        return 'align-center';
      case 'reportField':
        return 'analytics';
      case 'reviewerRecommendedFundingAmount':
        return 'comments-dollar';
      case 'decision':
        return 'thumbs-up';
      case 'specialHandling':
        return 'address-card';
      case 'designation':
        return 'star';
      case 'careOf':
        return 'font';
      case 'inKindItems':
        return 'gift';
      case 'amountRequested':
        return 'money-bill-wave-alt';
      case 'well':
        return 'draw-square';
      case 'table':
        return 'table';
      case 'panel':
        return 'heading';
      case 'fieldset':
        return 'bars';
      case 'columns':
        return 'columns';
    }

    return '';
  }

  /**
   * Gets the tooltip for the field/component in the toolbox
   *
   * @param field: Field to get tooltip for
   * @returns the components tooltip for the form toolbox
   */
  getFieldTooltip (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    categoryNameMap: Record<number, string>,
    customDataTables: CustomDataTable[],
    extraLabelProperty?: string
  ) {
    let returnVal = '';
    if (!!field) {
      const nameText = this.i18n.translate('common:lblName', {}, 'Name');
      const labelText = this.i18n.translate('common:hdrLabel', {}, 'Label');
      const keyText = this.i18n.translate('common:hdrKey', {}, 'Key');
      const typeText = this.i18n.translate('common:hdrType', {}, 'Type');
      const categoryText = this.i18n.translate('GLOBAL:textCategory', {}, 'Category');
      const descriptionText = this.i18n.translate('common:textDescription', {}, 'Description');
      const customDataTableText = this.i18n.translate('common:textCustomDataTable', {}, 'Custom data table');
      const audienceText = this.i18n.translate('common:textAudience', {}, 'Audience');
      const typeTranslated = this.getFieldTypeTranslatedWithIcon(field.type).label;
      const category = categoryNameMap[field.categoryId];
      const audience = this.i18n.translate(
        field.formAudience === FormAudience.APPLICANT ?
          'common:lblApplicant' :
          'GLOBAL:textManager',
        {},
        field.formAudience === FormAudience.APPLICANT ?
          'Applicant' :
          'Manager'
      );

      returnVal = `
        ${
          extraLabelProperty ? `<div><strong>${labelText}</strong>: ${field.name}</div>` : ''
        }
        <div><strong>${nameText}</strong>: ${field.name}</div>
        <div><strong>${keyText}</strong>: ${field.key}</div>
        <div><strong>${audienceText}</strong>: ${audience}</div>
        <div><strong>${typeText}</strong>: ${typeTranslated}</div>
        <div><strong>${categoryText}</strong>: ${category}</div>
      `;
      if (field.description) {
        returnVal = returnVal +
          `<div><strong>${descriptionText}</strong>: ${field.description}</div>`;
      }
      if (field.customDataTableGuid && !!customDataTables) {
        const customDataTable = customDataTables.find((table) => {
          return table.guid === field.customDataTableGuid;
        });
        returnVal = returnVal +
          `<div><strong>${customDataTableText}</strong>: ${customDataTable.name}</div>`;
      }
    }

    return returnVal;
  }

  /**
   * Adapts the field to a bucket component
   *
   * @param field: Reference field
   * @returns the bucket component for the field
   */
  getReferenceFieldBucketComp (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    categoryNameMap: Record<number, string>,
    customDataTables: CustomDataTable[],
    currentFormBuilderDefinition: FormDefinitionForUi[],
    notDraggable = false
  ): BucketComp {
    const type = `${REF_COMPONENT_TYPE_PREFIX}${field.key}`;

    return {
      key: field.key,
      label: field.defaultLabel || field.name,
      name: field.name,
      type,
      icon: this.getFieldIcon(type),
      tooltip: this.getFieldTooltip(field, categoryNameMap, customDataTables),
      fieldAudience: field.formAudience,
      isReferenceField: true,
      hidden: !this.componentHelper.componentIsAvailableToAdd(type, [], currentFormBuilderDefinition),
      notDraggable,
      markAsRequired: this.canFieldTypeBeRequired(field.type, field.supportsMultiple),
      categoryId: field.categoryId
    };
  }

  /**
   * Can the field type be required?
   *
   * @param type: Form Field Type
   * @param supportsMultiple: Does the field support multiple values?
   * @returns if the field can be required on a form
   */
  canFieldTypeBeRequired (
    type: ReferenceFieldsUI.ReferenceFieldTypes,
    supportsMultiple: boolean
  ) {
    switch (type) {
      default:
        return true;
      case ReferenceFieldsUI.ReferenceFieldTypes.FileUpload:
        return !supportsMultiple;
      case ReferenceFieldsUI.ReferenceFieldTypes.Table:
      case ReferenceFieldsUI.ReferenceFieldTypes.Subset:
      case ReferenceFieldsUI.ReferenceFieldTypes.Aggregate:
      case ReferenceFieldsUI.ReferenceFieldTypes.ExternalAPI:
        return false;
    }
  }

  /**
   * Exports all components of a form definition
   *
   * @param formDefs List of form definitions
   * @returns A list of components
   */
  exportAllComponents (
    formDefs: FormDefinitionForUi[],
    formName: string,
    categoryNameMap: Record<number, string>
  ) {
    const exportInfo: FormDefinitionComponentForExport[] = [];

    formDefs.forEach((formDef) => {
      this.componentHelper.eachComponent(formDef.components, (component) => {
        if (this.componentHelper.isReferenceFieldComp(component.type)) {
          const found = this.getReferenceFieldFromCompType(component.type);

          if (found) {
            exportInfo.push({
              'Form name': formName,
              'Component label': component.label,
              'Component description': component.description,
              'Component key': component.key,
              'Field name': found.name,
              'Field key': found.key,
              'Field type': this.getFieldTypeTranslatedWithIcon(found.type).label,
              'Field ID': found.referenceFieldId,
              'Field category': categoryNameMap[found.categoryId]
            });
          }
        }
      });
    }, []);

    const csv = parse.unparse(exportInfo);

    this.fileService.downloadCSV(csv);
  }

  hasAddressFields (
    components: FormDefinitionComponent[]
  ) {
    let hasAddressFields = false;
    components.forEach((comp) => {
      const field = this.getReferenceFieldFromCompType(comp.type);
      if (field?.type === ReferenceFieldsUI.ReferenceFieldTypes.Address) {
        hasAddressFields = true;
      }
    });

    return hasAddressFields;
  }
}

