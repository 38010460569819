import { Injectable } from '@angular/core';
import { PortalDeterminationService, PortalTypes } from '../portal-determination.service';

export const Behaviors: {
  [P in PortalTypes]: {
    userTokenKey: string;
    userKey: string;
    postLoginRedirect: string;
    clientIdentifierKey: string;
    impersonationSessionId: string;
    terminatedImpersonationSessionId: string;
  }
} = {
  apply: {
    userKey: '_yc_applicant',
    userTokenKey: '_yc_applicantToken2',
    postLoginRedirect: '/apply/applications',
    clientIdentifierKey: '_yc_applicantClientIdentifier',
    impersonationSessionId: '_yc_applicantImpersonationSessionId',
    terminatedImpersonationSessionId: '_yc_applicantTerminatedImpersonationSessionId'
  },
  management: {
    userKey: '_yc_manager',
    userTokenKey: '_yc_managerToken2',
    postLoginRedirect: '/management/home',
    clientIdentifierKey: '_yc_managerClientIdentifier',
    impersonationSessionId: '_yc_managerImpersonationSessionId',
    terminatedImpersonationSessionId: '_yc_managerTerminatedImpersonationSessionId'
  },
  platform: {
    userKey: '_yc_admin',
    userTokenKey: '_yc_adminUser2',
    postLoginRedirect: '/platform/home',
    clientIdentifierKey: '_yc_adminClientIdentifier',
    impersonationSessionId: '_yc_adminImpersonationSessionId',
    terminatedImpersonationSessionId: '_yc_adminTerminatedImpersonationSessionId'
  }
};

@Injectable({ providedIn: 'root' })
export class AuthBehaviors {

  current = Behaviors[this.portal.routeBase as PortalTypes] || Behaviors.management;

  constructor (
    private portal: PortalDeterminationService
  ) { }

}
