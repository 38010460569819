import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, QueryList, ViewChildren } from '@angular/core';
import { BaseApplication } from '@core/typings/application.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { BaseApplicationForLogic, FormChangesWithCompKey, FormComponentValidChange, FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { FormBuilderActionEvent } from '@features/forms/form-builder/form-builder.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { ErrorForDisplay } from '@features/forms/form-renderer/form-error-summary/form-error-summary.component';
import { FormInstanceComponent } from '@features/forms/form-renderer/form-instance/form-instance.component';
import { FormLogicService } from '@features/forms/services/form-logic/form-logic.service';
import { LogicState } from '@features/logic-builder/logic-builder.typing';
import { SimpleStringMap, TypeToken } from '@yourcause/common';

@Component({
  template: ''
})
export class BaseLayoutComponent implements AfterViewInit, OnDestroy {
  @Input() formComponent: FormDefinitionComponent;
  @Input() parentFields: Partial<BaseApplication>;
  @Input() validityState: LogicState<BaseApplicationForLogic, boolean>;
  @Input() showErrorSummary: boolean;
  @Input() errorMessages: ErrorForDisplay[] = [];
  @Input() inConfigModal: boolean;
  @Input() masked: boolean;
  @Input() translations: SimpleStringMap<string>;
  @Input() disabledOverride: boolean;
  @Input() notAutoSave: boolean;
  @Input() editable: boolean;
  @Input() isManagerEditingApplicantForm: boolean;
  @Input() isManagerForm: boolean;
  @Input() depth: number; // used to sort the drop containers REQUIRED
  @Input() refIdsChanged: number[];
  @Input() standardFieldsChanged: ReferenceFieldsUI.StandardFieldTypes[];
  @Input() isFormBuilderView: boolean;
  @Output() onValueChange = new EventEmitter<FormChangesWithCompKey>();
  @Output() onValidChange = new EventEmitter<FormComponentValidChange>();
  @Output() componentDropped = new EventEmitter<CdkDragDrop<FormDefinitionComponent[]>>();
  @Output() componentActionClick = new EventEmitter<FormBuilderActionEvent>();

  @ViewChildren(CdkDropList) dragDrop: QueryList<CdkDropList<FormDefinitionComponent[]>>;
  parent: FormInstanceComponent;
  $baseComponent = new TypeToken<FormDefinitionComponent>();

  trackBy = (_: number, comp: FormDefinitionComponent) => comp.key;

  constructor (
    public formBuilderService: FormBuilderService,
    public formLogicService: FormLogicService
  ) { }

   get inFormBuilder () {
    return this.formBuilderService.inFormBuilder;
  }

  ngAfterViewInit (): void {
    if (this.editable) {
      for (const dragDropItem of this.dragDrop.toArray()) {
        this.parent.registerDropList(dragDropItem, this.depth);
      }
    }
  }

  valueChanged (
    changes: FormChangesWithCompKey,
    component: FormDefinitionComponent
  ) {
    this.formLogicService.setValueForComp(component, changes.value);
    this.onValueChange.emit(changes);
  }

  handleDropEvent (drop: CdkDragDrop<FormDefinitionComponent[]>) {
    this.componentDropped.emit(drop);
  }

  ngOnDestroy () {
    if (this.editable) {
      for (const dragDropItem of this.dragDrop.toArray()) {
        this.parent.deregisterDropList(dragDropItem);
      }
    }
  }
}
