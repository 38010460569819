<div class="mb-3" *ngIf="evaluationTypeOptions">
  <gc-evaluation-type-selector
    [isViewOnly]="isViewOnly"
    [availableColumns]="availableColumns"
    [evaluationType]="logic.evaluationType"
    [evaluationTypeOptions]="evaluationTypeOptions"
    (onEvaluationTypeChange)="handleEvaluationTypeChange($event)">
  </gc-evaluation-type-selector>
</div>

<gc-logic-summary-sentence
  [logic]="logic"
  [availableColumns]="availableColumns">
</gc-logic-summary-sentence>

<gc-conditional-logic-builder-group *ngIf="logic.evaluationType | ycShowConditionalLogicBuilder"
  [availableColumns]="availableColumns"
  [isViewOnly]="isViewOnly"
  [maxDepth]="maxDepth"
  [currentDepth]="1"
  [group]="logic"
  (groupChange)="handleGroupChange($event)"
  (validChange)="handleValidityChange($event)">
</gc-conditional-logic-builder-group>
