import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import {
  BasicForm,
  ExportForm,
  ExportFormResponse,
  FlatFieldValidationResponse,
  FormWithFieldsDetail
} from './form.typing';


@Injectable({ providedIn: 'root' })
export class FormResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getForms (): Promise<BasicForm[]> {
    const endpoint = 'api/manager/forms?returnAllForms=true';

    return this.httpRestService.get(endpoint);
  }

  getFormsSegmented (): Promise<number[]> {
    const endpoint = 'api/manager/forms/datasegmented';

    return this.httpRestService.get(endpoint);
  }

  deleteRevision (formId: number, revisionId: number) {
    return this.httpRestService.delete(
      'api/manager/forms/' + formId + '/revisions/' + revisionId
    );
  }

  deleteAllRevisionsOfForm (formId: number) {
    return this.httpRestService.delete(
      `api/manager/forms/${formId}`
    );
  }

  flatFieldValidation (forProgramExport: boolean, ids: number[]): Promise<FlatFieldValidationResponse> {
    const endpoint = `api/manager/forms/flatFieldValidation/${forProgramExport}`;

    return this.httpRestService.post(endpoint, ids);
  }

  exportForms (formsToExport: ExportForm[]): Promise<ExportFormResponse[]> {
    const endpoint = 'api/manager/forms/export';

    return this.httpRestService.post(endpoint, {
      formsToExport
    });
  }

  importForms (formsToImport: ExportFormResponse[]) {
    const endpoint = 'api/manager/forms/import';

    return this.httpRestService.post(endpoint, {
      formsToImport
    });
  }

  getProgramsRelatedToForm (formId: number): Promise<number[]> {
    const endpoint = `api/manager/forms/programs?formid=${formId}`;

    return this.httpRestService.get(endpoint);
  }

  getGmFormsWithSingleResponseFields () {
    const endpoint = 'api/manager/forms/AtLeastOneSingleResponseReferenceField';

    return this.httpRestService.get<number[]>(endpoint);
  }

  getMyFormsWithFieldDetails (): Promise<FormWithFieldsDetail[]> {
    const endpoint = 'api/manager/forms/GetFormReferenceFields';

    return this.httpRestService.get(endpoint);
  }

  publishForm (formId: number, revisionId: number) {
    const endpoint = `api/manager/forms/${formId}/revisions/${revisionId}/publish`;

    return this.httpRestService.post(endpoint, {});
  }
}

