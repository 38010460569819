import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PaginatedResponse, PaginationOptions } from '@yourcause/common';
import { ImageUploadPayload } from '@yourcause/common/rich-text-editor';
import { BulkFetchTemplateFileTokenPayload, DocumentTemplateDetailFromAPI, DocumentTemplateFromApi, ExportDocumentTemplate, FetchTemplateFileTokenPayload, TemplateMarginsFromAPI, URLSasTokenObj } from './document-template.typing';

@Injectable({ providedIn: 'root' })
export class DocumentTemplateResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getDocumentTemplatesPaginated (
    paginationOptions: PaginationOptions<DocumentTemplateFromApi[]>
  ): Promise<PaginatedResponse<DocumentTemplateFromApi>> {
    const endpoint = `api/manager/DocumentTemplate/Templates`;

    return this.httpRestService.post(endpoint, {
      paginationOptions
    });
  }

  createDocumentTemplate (
    name: string
  ): Promise<number> {
    const endpoint = `api/manager/DocumentTemplate/CreateTemplate?name=${
      encodeURIComponent(name)
    }`;

    return this.httpRestService.post(endpoint, {});
  }

  updateDocumentTemplate (
    id: number,
    name: string,
    templateHtml: string,
    margins: TemplateMarginsFromAPI
  ) {
    const endpoint = 'api/manager/DocumentTemplate/UpdateTemplate';

    return this.httpRestService.post(endpoint, {
      id,
      name,
      templateHtml,
      margins
    });
  }

  getDocumentTemplateDetail (
    templateId: number
  ): Promise<DocumentTemplateDetailFromAPI> {
    const endpoint = `api/manager/DocumentTemplate/TemplateDetail/${templateId}`;

    return this.httpRestService.get(endpoint);
  }

  deleteDocumentTemplate (templateId: number) {
    const endpoint = `api/manager/DocumentTemplate/${templateId}/DeleteTemplate`;

    return this.httpRestService.delete(endpoint);
  }

  async importDocumentTemplates (documentTemplates: ExportDocumentTemplate[]) {
    const endpoint = 'api/manager/DocumentTemplate/import/DocumentTemplates';

    return this.httpRestService.post(endpoint, {
      documentTemplates
    });
  }

  async exportDocumentTemplates (documentTemplateIds: number[]) {
    const endpoint = 'api/manager/DocumentTemplate/export/DocumentTemplates';

    return this.httpRestService.post(endpoint, {
      documentTemplateIds
    });
  }

  async uploadFile (uploadRequest: ImageUploadPayload) {
    const endpoint = 'api/manager/DocumentTemplate/UploadFile';

    const response = await this.httpRestService.postFile<{ file: string }>(
      endpoint,
      uploadRequest.blob,
      undefined,
      uploadRequest.fileName
    );

    return response.file;
  }

  async getBulkTemplateFileSASTokens (payload: BulkFetchTemplateFileTokenPayload): Promise<URLSasTokenObj> {
    const endpoint = 'api/manager/DocumentTemplate/TemplateFilesSasTokens';

    return this.httpRestService.post(endpoint, payload);
  }

  async getTemplateFileSASToken (payload: FetchTemplateFileTokenPayload): Promise<string> {
    const endpoint = 'api/manager/DocumentTemplate/TemplateFileSasToken';

    const response = await this.httpRestService.post<{ token: string }>(endpoint, payload);

    return response.token;
  }
}
