export default {
  "formattingInfo": {
    "ZAR": {
      "symbol": "R",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "ETB": {
      "symbol": "ብር",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "AED": {
      "symbol": "د.إ.",
      "precision": 2,
      "thousand": "٬",
      "decimal": "٫"
    },
    "BHD": {
      "symbol": "د.ب.",
      "precision": 3,
      "thousand": "٬",
      "decimal": "٫"
    },
    "DZD": {
      "symbol": "د.ج.",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "EGP": {
      "symbol": "ج.م.",
      "precision": 2,
      "thousand": "٬",
      "decimal": "٫"
    },
    "IQD": {
      "symbol": "د.ع.",
      "precision": 0,
      "thousand": "٬",
      "decimal": "٫"
    },
    "JOD": {
      "symbol": "د.أ.",
      "precision": 3,
      "thousand": "٬",
      "decimal": "٫"
    },
    "KWD": {
      "symbol": "د.ك.",
      "precision": 3,
      "thousand": "٬",
      "decimal": "٫"
    },
    "LBP": {
      "symbol": "ل.ل.",
      "precision": 0,
      "thousand": "٬",
      "decimal": "٫"
    },
    "LYD": {
      "symbol": "د.ل.",
      "precision": 3,
      "thousand": ".",
      "decimal": ","
    },
    "MAD": {
      "symbol": "د.م.",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "OMR": {
      "symbol": "ر.ع.",
      "precision": 3,
      "thousand": "٬",
      "decimal": "٫"
    },
    "QAR": {
      "symbol": "ر.ق.",
      "precision": 2,
      "thousand": "٬",
      "decimal": "٫"
    },
    "SAR": {
      "symbol": "ر.س.",
      "precision": 2,
      "thousand": "٬",
      "decimal": "٫"
    },
    "SYP": {
      "symbol": "ل.س.",
      "precision": 0,
      "thousand": "٬",
      "decimal": "٫"
    },
    "TND": {
      "symbol": "د.ت.",
      "precision": 3,
      "thousand": ".",
      "decimal": ","
    },
    "YER": {
      "symbol": "ر.ي.",
      "precision": 0,
      "thousand": "٬",
      "decimal": "٫"
    },
    "CLP": {
      "symbol": "CLP",
      "precision": 0,
      "thousand": ",",
      "decimal": "."
    },
    "INR": {
      "symbol": "₹",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "AZN": {
      "symbol": "₼",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "RUB": {
      "symbol": "RUB",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "BYN": {
      "symbol": "Br",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "BGN": {
      "symbol": "лв.",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "BDT": {
      "symbol": "৳",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "CNY": {
      "symbol": "¥",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "EUR": {
      "symbol": "€",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "BAM": {
      "symbol": "КМ",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "CZK": {
      "symbol": "Kč",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "GBP": {
      "symbol": "£",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "DKK": {
      "symbol": "kr.",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "CHF": {
      "symbol": "CHF",
      "precision": 2,
      "thousand": "’",
      "decimal": "."
    },
    "MVR": {
      "symbol": "ރ.",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "BTN": {
      "symbol": "Nu.",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "AUD": {
      "symbol": "$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "BZD": {
      "symbol": "$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "CAD": {
      "symbol": "$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "HKD": {
      "symbol": "HK$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "JMD": {
      "symbol": "$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "MYR": {
      "symbol": "RM",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "NZD": {
      "symbol": "$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "PHP": {
      "symbol": "₱",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "SGD": {
      "symbol": "$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "TTD": {
      "symbol": "$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "USD": {
      "symbol": "$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "¤¤": {
      "symbol": "¤",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "ARS": {
      "symbol": "$",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "BOB": {
      "symbol": "Bs",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "COP": {
      "symbol": "$",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "CRC": {
      "symbol": "₡",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "CUP": {
      "symbol": "$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "DOP": {
      "symbol": "RD$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "GTQ": {
      "symbol": "Q",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "HNL": {
      "symbol": "L",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "MXN": {
      "symbol": "$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "NIO": {
      "symbol": "C$",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "PAB": {
      "symbol": "B/.",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "PEN": {
      "symbol": "S/",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "PYG": {
      "symbol": "Gs.",
      "precision": 0,
      "thousand": ".",
      "decimal": ","
    },
    "UYU": {
      "symbol": "$",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "VES": {
      "symbol": "Bs.S",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "IRR": {
      "symbol": "ریال",
      "precision": 0,
      "thousand": "٬",
      "decimal": "٫"
    },
    "XOF": {
      "symbol": "CFA",
      "precision": 0,
      "thousand": " ",
      "decimal": ","
    },
    "CDF": {
      "symbol": "FC",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "XAF": {
      "symbol": "FCFA",
      "precision": 0,
      "thousand": " ",
      "decimal": ","
    },
    "HTG": {
      "symbol": "G",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "ILS": {
      "symbol": "₪",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "HRK": {
      "symbol": "HRK",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "HUF": {
      "symbol": "Ft",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "AMD": {
      "symbol": "֏",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "IDR": {
      "symbol": "Rp",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "NGN": {
      "symbol": "₦",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "ISK": {
      "symbol": "ISK",
      "precision": 0,
      "thousand": ".",
      "decimal": ","
    },
    "JPY": {
      "symbol": "￥",
      "precision": 0,
      "thousand": ",",
      "decimal": "."
    },
    "GEL": {
      "symbol": "₾",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "KZT": {
      "symbol": "₸",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "KHR": {
      "symbol": "៛",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "KRW": {
      "symbol": "₩",
      "precision": 0,
      "thousand": ",",
      "decimal": "."
    },
    "KGS": {
      "symbol": "сом",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "LAK": {
      "symbol": "₭",
      "precision": 0,
      "thousand": ".",
      "decimal": ","
    },
    "MKD": {
      "symbol": "ден",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "MNT": {
      "symbol": "₮",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "BND": {
      "symbol": "$",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "MMK": {
      "symbol": "K",
      "precision": 0,
      "thousand": ",",
      "decimal": "."
    },
    "NOK": {
      "symbol": "kr",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "NPR": {
      "symbol": "नेरू",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "PKR": {
      "symbol": "ر",
      "precision": 2,
      "thousand": "٬",
      "decimal": "٫"
    },
    "PLN": {
      "symbol": "zł",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "AFN": {
      "symbol": "؋",
      "precision": 0,
      "thousand": "٬",
      "decimal": "٫"
    },
    "BRL": {
      "symbol": "R$",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "MDL": {
      "symbol": "L",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "RON": {
      "symbol": "RON",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "RWF": {
      "symbol": "RF",
      "precision": 0,
      "thousand": ".",
      "decimal": ","
    },
    "SEK": {
      "symbol": "kr",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "LKR": {
      "symbol": "රු.",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "SOS": {
      "symbol": "S",
      "precision": 0,
      "thousand": ",",
      "decimal": "."
    },
    "ALL": {
      "symbol": "Lekë",
      "precision": 0,
      "thousand": " ",
      "decimal": ","
    },
    "RSD": {
      "symbol": "RSD",
      "precision": 0,
      "thousand": ".",
      "decimal": ","
    },
    "KES": {
      "symbol": "Ksh",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "THB": {
      "symbol": "฿",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "ERN": {
      "symbol": "Nfk",
      "precision": 2,
      "thousand": ",",
      "decimal": "."
    },
    "TMT": {
      "symbol": "TMT",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "BWP": {
      "symbol": "P",
      "precision": 2,
      "thousand": " ",
      "decimal": "."
    },
    "TRY": {
      "symbol": "₺",
      "precision": 2,
      "thousand": ".",
      "decimal": ","
    },
    "UAH": {
      "symbol": "₴",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "UZS": {
      "symbol": "сўм",
      "precision": 2,
      "thousand": " ",
      "decimal": ","
    },
    "VND": {
      "symbol": "₫",
      "precision": 0,
      "thousand": ".",
      "decimal": ","
    }
  }
}