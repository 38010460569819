import { ViewTranslations } from '@core/typings/translation.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
export interface FormTranslationInfo {
  standardMap: Record<string, string>;
  richTextMap: Record<string, string>;
}

@RegisterYCState()
export class TranslationState extends BaseYcState {
  readonly viewTranslations: ViewTranslations;
  readonly formTranslationsMap: Record<number, FormTranslationInfo> = {};
}
