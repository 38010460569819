import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { GCCoreModule } from '@core/_core.module';
import { LogicBuilderModule } from '@features/logic-builder/logic-builder.module';
import { AdHocReportingModule } from '@features/reporting/ad-hoc-reporting.module';
import { FormBuilderEditComponentsModule } from './component-configuration/form-builder-edit-components.module';
import { FormBuilderComponent } from './form-builder/form-builder/form-builder.component';
import { RestoreDeletedComponentsModalComponent } from './form-builder/restore-deleted-components-modal/restore-deleted-components-modal.component';
import { BaseFormComponent } from './form-renderer-components/base/base.component';
import { FormReferenceFieldsModule } from './form-renderer-components/form-fields/form-reference-fields.module';
import { StandardFormComponentsModule } from './form-renderer-components/standard-form-components/standard-form-components.module';
import { FormErrorSummaryComponent } from './form-renderer/form-error-summary/form-error-summary.component';
import { FormInstanceComponent } from './form-renderer/form-instance/form-instance.component';
import { FormRendererComponent } from './form-renderer/form-renderer/form-renderer.component';
import { FormResponseManagerPortalComponent } from './form-renderer/form-response-manager-portal/form-response-manager-portal.component';
import { FlattenFormDefinitionPipe } from './pipes/flatten-form-definition.pipe';
import { FormBuilderSpellCheckCorrectionsModalComponent } from './form-builder/form-builder-spell-check-corrections-modal/form-builder-spell-check-corrections-modal.component';

@NgModule({
  imports: [
    DragDropModule,
    GCCoreModule,
    LogicBuilderModule,
    AdHocReportingModule,
    StandardFormComponentsModule,
    FormReferenceFieldsModule,
    FormBuilderEditComponentsModule
  ],
  declarations: [
    FormBuilderComponent,
    FormResponseManagerPortalComponent,
    BaseFormComponent,
    FormRendererComponent,
    FormInstanceComponent,
    FormErrorSummaryComponent,
    FlattenFormDefinitionPipe,
    RestoreDeletedComponentsModalComponent,
    FormBuilderSpellCheckCorrectionsModalComponent
  ],
  exports: [
    FormBuilderComponent,
    FormResponseManagerPortalComponent,
    FormRendererComponent,
    FormInstanceComponent,
    FormErrorSummaryComponent
  ]
})
export class FormRendererModule { }
