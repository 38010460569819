import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { I18nService } from '@yourcause/common/i18n';
interface ExtendedAddress {
  attribute: keyof ReferenceFieldAPI.AddressCraInfo;
  label: string;
}

@Component({
  selector: 'gc-address-form-field',
  templateUrl: './address-form-field.component.html',
  styleUrls: ['./address-form-field.component.scss']
})
export class AddressFormFieldComponent  {
  @Input() data: ReferenceFieldAPI.FormFieldAddressResponse;
  @Input() disabled: boolean;
  @Input() compKey: string;
  @Input() tooltipText: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() description: string;
  @Input() hideLabel: boolean;
  @Input() tabIndex: number;
  @Input() addRequiredAsterisk: boolean;
  @Input() showExtendedAddressInfo = false;
  @Input() formIsReadOnly: boolean;
  @Input() customLabelIcon: string;
  @Input() customLabelIconTooltip: string;
  @Input() customLabelIconClass: string;
  @Output() onChange = new EventEmitter<ReferenceFieldAPI.FormFieldAddressResponse>();

  constructor (
    private i18n: I18nService,
    private portal: PortalDeterminationService
  ) { }

  get isApply () {
    return this.portal.isApply;
  }

  extendedAddressAttrs: ExtendedAddress[] = [{
    attribute: 'craTractCode',
    label: this.i18n.translate('common:textTractCode', {}, 'Tract code')
  }, {
    attribute: 'craMsaCode',
    label: this.i18n.translate('common:textMsaCode', {}, 'MSA code')
  }, {
    attribute: 'craStateCode',
    label: this.i18n.translate('common:textStateCode', {}, 'State code')
  }, {
    attribute: 'craCountyCode',
    label: this.i18n.translate('common:textCountyCode', {}, 'County code')
  }];
}

