import { Component, OnDestroy } from '@angular/core';
import { GcFlyoutService } from '@core/services/gc-flyout.service';
import { SpinnerService } from '@core/services/spinner.service';
import { UserFromApi } from '@core/typings/client-user.typing';
import { AudienceForUser } from '@core/typings/user.typing';
import { AudienceService } from '@features/audience/audience.service';
import { RemoveUserFromAudienceModalComponent } from '@features/audience/remove-user-from-audience-modal/remove-user-from-audience-modal.component';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory } from '@yourcause/common/modals';
import { Subscription } from 'rxjs';
import { AddEditUserModalComponent } from '../add-edit-user-modal/add-edit-user-modal.component';
import { UserService } from '../user.service';

@Component({
  selector: 'gc-user-detail-flyout',
  templateUrl: './user-detail-flyout.component.html',
  styleUrls: ['./user-detail-flyout.component.scss']
})
export class UserDetailFlyoutComponent implements OnDestroy {
  user: UserFromApi;
  audiences: AudienceForUser[] = [];
  rolesSubHeader: string;
  workflowsSubHeader: string;
  audiencesSubHeader: string;
  sub = new Subscription();

  constructor (
    private userService: UserService,
    private gcFlyoutService: GcFlyoutService,
    private i18n: I18nService,
    private spinnerService: SpinnerService,
    private modalFactory: ModalFactory,
    private audienceService: AudienceService
  ) {
    this.sub.add(this.gcFlyoutService.changesTo$('currentIdForFlyout').subscribe(() => {
      this.setHelpers();
    }));
    this.sub.add(this.userService.changesTo$('userAudienceMap').subscribe(() => {
      this.setHelpers();
    }));
  }

  get userId () {
    return +this.gcFlyoutService.currentIdForFlyout;
  }

  setHelpers () {
    this.user = this.userService.allUsersDetailed.find((user) => {
      return user.userId === this.userId;
    });
    this.audiences = this.userService.userAudienceMap[this.userId] ?? [];
    this.rolesSubHeader = this.i18n.translate(
      this.user.roles.length === 1 ? 'common:textOneRole' : 'common:textNumberRoles',
      {
        number: this.user.roles.length
      },
      this.user.roles.length === 1 ?
        'One role' :
        '__number__ roles'
    );
    this.workflowsSubHeader = this.i18n.translate(
      this.user.workflows.length === 1 ? 'common:textOneWorkflow' : 'common:textNumberWorkflows',
      {
        number: this.user.workflows.length
      },
      this.user.workflows.length === 1 ?
        'One workflow' :
        '__number__ workflows'
    );
    this.audiencesSubHeader = this.i18n.translate(
      this.audiences.length === 1 ?
        'common:textOneAudience' :
        'common:textNumberAudiences',
      {
        number: this.audiences.length
      },
      this.audiences.length === 1 ?
        'One audience' :
        '__number__ audiences'
    );
  }

  async editUser () {
    const response = await this.modalFactory.open(
      AddEditUserModalComponent,
      {
        existingUser: {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          jobTitle: this.user.jobTitle,
          isSSO: this.user.isSSO,
          ssoId: this.user.ssoId,
          isBbidLinked: this.user.isBbidLinked
        }
      }
    );
    if (response) {
      this.spinnerService.startSpinner();
      await this.userService.addEditUser({
        ...response,
        id: this.user.id
      });
      this.setHelpers();
      this.spinnerService.stopSpinner();
    }
  }

  async removeFromAudience (audienceId: number) {
    const proceed = await this.modalFactory.open(
      RemoveUserFromAudienceModalComponent,
      {
        audienceId,
        user: this.user
      }
    );
    if (proceed) {
      const passed = await this.audienceService.handleRemoveUserFromAudience(
        this.user.userId,
        audienceId
      );
      if (passed) {
        this.spinnerService.startSpinner();
        await this.userService.resetUserAudienceMapForUser(this.user.userId);
        this.spinnerService.stopSpinner();
      }
    }
  }

  ngOnDestroy (): void {
    this.sub.unsubscribe();
  }
}
