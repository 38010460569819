import { Pipe, PipeTransform } from '@angular/core';
import { BaseApplicationForLogic, FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { LogicBuilderService } from '@features/logic-builder/logic-builder.service';
import { EvaluationType, LogicState } from '@features/logic-builder/logic-builder.typing';
import { FormLogicService } from '../services/form-logic/form-logic.service';

@Pipe({
  name: 'gcGetValidationStateValue'
})
/**
 * Returns whether the comp is valid or not
 */
export class GetValidationStateValuePipe implements PipeTransform {

  constructor (
    private logicBuilderService: LogicBuilderService,
    private formLogicService: FormLogicService
  ) { }

  transform (
    validityState: LogicState<BaseApplicationForLogic, boolean>,
    component: FormDefinitionComponent,
    customError: string|boolean
  ) {
    if (!validityState) {
      return true;
    }

    const [column, logic] = this.formLogicService.getComponentLogic(component) ?? [null, null];
    const componentHasLogic = logic?.validityGroup?.evaluationType === EvaluationType.ConditionallyTrue;
    if (componentHasLogic) {
      return this.logicBuilderService.getCurrentLogicValueOfColumn(
        column,
        validityState
      );
    } else if ((typeof(customError) === 'string') || customError === false) {
      return false;
    }

    return null;
  }
}
