import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputRegexService } from '@core/services/input-regex.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';

@Component({
  selector: 'gc-regex-validation-setting',
  templateUrl: './regex-validation-setting.component.html',
  styleUrls: ['./regex-validation-setting.component.scss']
})
export class RegexValidationSettingComponent extends BaseComponentConfigSettingsComponent implements OnInit {
  @Input() pattern: string;
  @Input() compType: string;
  @Output() onChange = new EventEmitter<string>();

  formatTypeName: string;

  constructor (
    private formFieldHelperService: FormFieldHelperService,
    private inputRegexService: InputRegexService
  ) {
    super();
  }

  ngOnInit () {
    const field = this.formFieldHelperService.getReferenceFieldFromCompType(
      this.compType
    );
    if (field?.formatType) {
      const { name } = this.inputRegexService.getSelectedFormattingDetails(
        field.formatType
      );
      this.formatTypeName = name;
    }
  }

}
