import { Component, Input } from '@angular/core';
import { AudienceMember } from '@features/audience/audience.typing';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';

@Component({
  selector: 'gc-audience-users-display-modal',
  templateUrl: './audience-users-display-modal.component.html',
  styleUrls: ['./audience-users-display-modal.component.scss']
})
export class AudienceUsersDisplayModalComponent extends YCModalComponent<void> {
  @Input() modalHeader = this.i18n.translate('CONFIG:textMembers', {}, 'Members');
  @Input() modalSubHeader: string;
  @Input() tableKey: string;
  @Input() members: AudienceMember[];

  constructor (
    private i18n: I18nService
  ) {
    super();
  }
}
