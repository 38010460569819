import { Injectable } from '@angular/core';
import { CsrConnectStats } from '../typings/organization.typing';
import { HttpRestService } from './http-rest.service';

export function shortNumberFilter (number: number, precision = 0) {
  let result;
  const powerOfTen = Math.floor(Math.log(Math.abs(number)) * Math.LOG10E);

  switch (powerOfTen) {
    case 3:
    case 4:
    case 5:
      result = (number / Math.pow(10, 3)).toFixed(precision) + 'K';
      break;
    case 6:
    case 7:
    case 8:
      result = (number / Math.pow(10, 6)).toFixed(precision) + 'M';
      break;
    case 9:
    case 10:
    case 11:
      result = (number / Math.pow(10, 9)).toFixed(precision) + 'B';
      break;
    case 12:
    case 13:
    case 14:
      result = (number / Math.pow(10, 12)).toFixed(precision) + 'T';
      break;
    default:
      result = number.toFixed(precision);
  }

  if (result === ('' + number)) {
    return (+number).toFixed(0);
  }

  return result;
}

@Injectable({ providedIn: 'root' })
export class AggregationService {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getCharityDonationsByYear (charityId: number): Promise<CsrConnectStats> {
    return this.httpRestService
      .get('api/aggregations/charities/' + charityId + '/activity');
  }

  shortNumberFilter (number: number, precision = 0) {
    return shortNumberFilter(number, precision);
  }


}
