import { Injectable } from '@angular/core';
import { ReleaseNotesModalComponent } from '@core/components/release-notes-modal/release-notes-modal.component';
import { SpinnerService } from '@core/services/spinner.service';
import { CachedAttr, CACHE_TYPES } from '@yourcause/common/cache';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmAndTakeActionService, ModalFactory } from '@yourcause/common/modals';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ScheduledAnnouncementsResources } from '../../scheduled-announcements/scheduled-announcements.resources';
import { ReleaseNoteFromApi } from '../../scheduled-announcements/scheduled-announcements.typing';
import { ReleaseNoteState } from '../release-notes.state';

@AttachYCState(ReleaseNoteState)
@Injectable({ providedIn: 'root' })
export class ReleaseNoteService extends BaseYCService<ReleaseNoteState> {
  @CachedAttr(CACHE_TYPES.SESSION, false)
  hasDisplayedReleaseNotes: boolean;

  constructor (
    private scheduledAnnouncementsResources: ScheduledAnnouncementsResources,
    private confirmAndTakeActionService: ConfirmAndTakeActionService,
    private i18n: I18nService,
    private modalFactory: ModalFactory,
    private spinnerService: SpinnerService
  ) {
    super();
  }

  get releaseNotes () {
    return this.get('releaseNotes');
  }

  get releaseNoteForManager () {
    return this.get('releaseNoteForManager');
  }

  /**
   * Fetches and sets release notes if they haven't been fetched
   */
  async setReleaseNotes () {
    if (!this.releaseNotes) {
      const notes = await this.scheduledAnnouncementsResources.getReleaseNotesForPlatform();
      this.set('releaseNotes', notes);
    }
  }

  /**
   * Resets release notes
   */
  async resetReleaseNotes () {
    this.set('releaseNotes', undefined);
    await this.setReleaseNotes();
  }

  /**
   * Sets the release note to surface to the GM
   */
  async setReleaseNoteForManager () {
    if (!this.releaseNoteForManager) {
      const releaseNotes = await this.scheduledAnnouncementsResources.getReleaseNotesForUser();
      this.set('releaseNotes', releaseNotes);
      if (releaseNotes?.length > 0) {
        const currentDate = new Date();
        // We only want to display the release note if the start date is today OR has passed
        // AND
        // We only want to display the release note if the end date is not today AND has not passed
        if (currentDate >= new Date(releaseNotes[0].startDate) && currentDate < new Date(releaseNotes[0].endDate)) {
          // We will only ever display one release note to user, so grab 1st item
          this.set('releaseNoteForManager', releaseNotes[0]);
        }
      }
    }
  }

  /**
   * Opens the release note modal if they exist and not already displayed to user
   */
  async handleOpenReleaseNoteModal () {
    if (!this.hasDisplayedReleaseNotes && !!this.releaseNoteForManager) {
      this.hasDisplayedReleaseNotes = true;
      const doNotShowAgain = await this.modalFactory.open(
        ReleaseNotesModalComponent,
        {}
      );
      if (doNotShowAgain) {
        await this.dismissReleaseNote();
        this.set('releaseNoteForManager', undefined);
      }
    }
  }

  /**
   * Dismisses a release note so the user won't see it again
   *   */
  async dismissReleaseNote () {
    await this.scheduledAnnouncementsResources.dismissReleaseNote(this.releaseNoteForManager.id);
  }

  /**
   * Saves the release note
   *
   * @param payload: payload for API
   */
  async saveReleaseNote (payload: ReleaseNoteFromApi) {
    const result =  await this.confirmAndTakeActionService.takeAction(
      '/api/admin/Configuration/SaveScheduledAnnouncement',
      payload,
      this.i18n.translate(
        payload.id ?
          'common:textSuccessfullyUpdatedReleaseNote' :
          'common:textSuccessfullyCreatedReleaseNote',
        {},
        payload.id ?
          'Successfully updated the release note' :
          'Successfully created the release note'
      ),
      this.i18n.translate(
        payload.id ?
          'common:textErrorUpdatingReleaseNote' :
          'common:textErrorCreatingReleaseNotes',
        {},
        payload.id ?
          'There was an error updating the release note' :
          'There was an error creating the release note'
      ),
      'post',
      false
    );
    if (result.passed) {
      await this.resetReleaseNotes();
    }
  }

  /**
   * Deletes the release note
   *
   * @param id: id of release note
   * @param releaseNumber: release number
   */
  async deleteReleaseNote (
    id: number,
    releaseNumber: string
  ) {
    const result = await this.confirmAndTakeActionService.confirmAndTakeAction(
      `/api/admin/Configuration/ScheduledAnnouncement/${id}`,
      {},
      this.i18n.translate(
        'common:hdrDeleteReleaseNote',
        {},
        'Delete Release Note'
      ),
      releaseNumber,
      this.i18n.translate(
        'common:textAreYouSureDeleteReleaseNote',
        {},
        'Are you sure you want to delete this release note?'
      ),
      this.i18n.translate(
        'common:btnDelete',
        {},
        'Delete'
      ),
      this.i18n.translate(
        'common:textSuccessfullyDeletedReleaseNote',
        {},
        'Successfully deleted the release note'
      ),
      this.i18n.translate(
        'common:textErrorDeletingReleaseNote',
        {},
        'There was an error deleting the release note'
      ),
      'delete',
      false
    );
    if (result?.passed) {
      this.spinnerService.startSpinner();
      await this.resetReleaseNotes();
      this.spinnerService.stopSpinner();
    }
  }
}
