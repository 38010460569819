import { Pipe, PipeTransform } from '@angular/core';
import { ComponentHelperService } from '../services/component-helper/component-helper.service';

@Pipe({
  name: 'gcIsInvalidComp'
})
export class IsInvalidCompPipe implements PipeTransform {

  constructor (
    private componentHelper: ComponentHelperService
  ) { }

  transform (compType: string) {
    return this.componentHelper.isInvalidComp(compType);
  }
}
