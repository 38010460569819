import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TopLevelFilter } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { AudienceService } from '../audience.service';
import { AudienceUsage, AudienceUsageForUi } from '../audience.typing';

@Component({
  selector: 'gc-audience-usage-table',
  templateUrl: './audience-usage-table.component.html',
  styleUrls: ['./audience-usage-table.component.scss']
})
export class AudienceUsageTableComponent implements OnInit, OnChanges {
  @Input() audienceId: number;
  @Input() audienceUsage: AudienceUsage;

  rows: AudienceUsageForUi[];
  topLevelFilters: TopLevelFilter[] = [
    new TopLevelFilter(
      'text',
      '',
      '',
      this.i18n.translate(
        'common:textSearchByName',
        {},
        'Search by name'
      ),
      undefined,
      '',
      [{
        column: 'usageName',
        filterType: 'cn'
      }, {
        column: 'usageSubName',
        filterType: 'cn'
      }]
    )
  ];
  afterInit = false;

  constructor (
    private i18n: I18nService,
    private audienceService: AudienceService
  ) { } 

  ngOnInit () {
    this.setRows();
    this.afterInit = true;
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (this.afterInit && !!changes.rows) {
      this.setRows();
    }
  }

  setRows () {
    this.rows = this.audienceService.adaptAudienceUsage(this.audienceUsage);
  }
}
