<yc-collapsible-section
  [header]="'common:textAdvancedValidation' | ycI18n: 'Advanced Validation'">
  <ng-template #section>    
    <div>
      <!-- Invalid Config Alert -->
      <yc-alert *ngIf="showInvalidConditionsMessage"
        alertClass="danger"
        [message]="'common:textClickManageConditionsToProceedValidityAlert' | ycI18n: 'To proceed with using custom validity, you must set up at least one condition.'">
      </yc-alert>

      <div class="d-flex flex-column mb-3">
        <yc-i18n *ngIf="customValidation?.conditions?.length > 0"
          key="common:textComponentValidWhenRulesPass"
          defaultValue="When the following validation rules are met, this component is valid">
        </yc-i18n>
        <!-- Logic Summary -->
        <gc-logic-summary-sentence 
          [logic]="dataWithoutResult"
          [availableColumns]="availableColumns"
          [noLogicMessage]="instructions"
          [fallbackToNoLogicMessage]="true">
        </gc-logic-summary-sentence>

        <!-- Manage Custom Validity -->
        <yc-button
          class="d-flex justify-content-end"
          [link]="true"
          [langKey]="'common:textManageCustomValidation'"
          [defaultText]="'Manage custom validation'"
          (onClick)="openLogicBuilderModal()">
        </yc-button>

        <yc-input *ngIf="customValidation?.conditions?.length > 0"
          [value]="customValidation.result"
          [disabled]="isViewOnly"
          [label]="'common:textShowMessageWhenComponentInvalid' | ycI18n: 'When the rules are not met, display the following error message'"
          [addRequiredAsterisk]="true"
          [customError]="customValidation?.conditions?.length > 0 && !errorMessage ? requiredError : null"
          (valueChange)="handleResultChange($event)">
        </yc-input>
      </div>
    </div>
  </ng-template>
</yc-collapsible-section>