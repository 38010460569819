import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PaginatedResponse, PaginationOptions } from '@yourcause/common';
import { Audience, AudienceMember, AudienceModalResponse, AudienceUsage } from './audience.typing';

@Injectable({ providedIn: 'root' })
export class AudienceResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getAudiencesPaginated (
    paginationOptions: PaginationOptions<Audience>
  ): Promise<PaginatedResponse<Audience>> {
    const endpoint = 'api/manager/audience/Paginated/Audience';

    return this.httpRestService.post(endpoint, {
      paginationOptions
    });
  }

  getAudienceMembers (audienceId: number): Promise<AudienceMember[]> {
    const endpoint = `api/manager/audience/AudienceMembers/${audienceId}`;

    return this.httpRestService.get(endpoint);
  }

  getAudienceUsage (audienceId: number): Promise<AudienceUsage> {
    const endpoint = `api/manager/audience/GetAudienceUsage/${audienceId}`;

    return this.httpRestService.get(endpoint);
  }

  createOrEditAudience (payload: AudienceModalResponse): Promise<number> {
    const endpoint = 'api/manager/audience/CreateAndEdit';

    return this.httpRestService.post(endpoint, payload);
  }

  deleteAudience (audienceId: number) {
    const endpoint = `api/manager/audience/Delete/${audienceId}`;

    return this.httpRestService.post(endpoint, {});
  }

  removeUserFromAudience (
    userId: number,
    audienceId: number
  ) {
    const endpoint = `api/manager/audience/RemoveUserFromAudience/${userId}/${audienceId}`;

    return this.httpRestService.delete(endpoint);
  }
}
