import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { UIExternalAPI } from '@core/typings/ui/external-api.typing';
import { FormDefinitionComponent, FormDefinitionForUi } from '@features/configure-forms/form.typing';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { TypeSafeFormBuilder, TypeSafeFormGroup, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { Subscription } from 'rxjs';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';

@Component({
  selector: 'gc-external-api-form-field-settings',
  templateUrl: './external-api-form-field-settings.component.html',
  styleUrls: ['./external-api-form-field-settings.component.scss']
})
export class ExternalApiFormFieldSettingsComponent extends BaseComponentConfigSettingsComponent implements OnInit, OnDestroy {
  @Input() relatedComponent: string;
  @Input() component: FormDefinitionComponent;
  @Input() formDefinition: FormDefinitionForUi[];
  @Output() isValidChange = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<string>();

  sub = new Subscription();
  formGroup: TypeSafeFormGroup<{
    relatedComponent: string;
  }>;
  relatedComponentOptions: TypeaheadSelectOption[] = [];

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private i18n: I18nService,
    private componentHelper: ComponentHelperService,
    private arrayHelper: ArrayHelpersService
  ) {
    super();
  }

  ngOnInit () {
    const options = [{
      label: this.i18n.translate(
        'common:textNone',
        {},
        'None'
      ),
      value: UIExternalAPI.EMPTY_FORM_FIELD_SOURCE
    }];
    this.formDefinition.forEach((tab) => {
      this.componentHelper.eachComponent(tab.components, (comp) => {
        if (
          comp.type !== 'button' &&
          comp.type !== 'content' &&
          comp.key !== this.component.key
        ) {
          options.push({
            label: comp.label,
            value: comp.key
          });
        }
      });
    });
    this.relatedComponentOptions = this.arrayHelper.sort(options, 'label');

    this.formGroup = this.formBuilder.group({
      relatedComponent: [
        this.relatedComponent,
        Validators.required
      ]
    });
    this.sub.add(this.formGroup.statusChanges.subscribe(() => {
      this.isValidChange.emit(this.formGroup.valid);
    }));
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }

}
