import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import {
  ApplicantFormFromApi,
  BulkCancelRevisionRequestPayload,
  BulkRevisionRequestPayload
} from '@core/typings/application.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { AllFormsResponse, FormForApplicantFromApi, FormReminderForApi, SaveFormResponse } from '@features/configure-forms/form.typing';
import { EmailOptionsModelForSave } from '@features/system-emails/email.typing';

@Injectable({ providedIn: 'root' })
export class ApplicationFormResources {

  constructor (
    private httpRestService: HttpRestService,
    private portal: PortalDeterminationService
  ) { }

  getAllApplicationForms (id: number): Promise<AllFormsResponse> {
    const endpoint = `api/manager/applications/GetAllFormsForApplication?applicationId=${id}`;

    return this.httpRestService.get<AllFormsResponse>(endpoint);
  }

  getAllApplicantForms (id: number):  Promise<FormForApplicantFromApi[]> {
    const endpoint = `api/portal/applications/${id}/getallapplicantforms`;

    return this.httpRestService.get(endpoint);
  }

  getApplicationForms (applicationId: number): Promise<ApplicantFormFromApi[]> {
    const endpoint = 'api/manager/applications/' + applicationId + '/forms';

    return this.httpRestService.get(endpoint);
  }

  getFormResponses (applicationId: number, formId: number) {
    const endpoint = `api/manager/applications/GetFormResponses?applicationId=${applicationId}&formId=${formId}`;

    return this.httpRestService.get(endpoint);
  }

  async saveFormResponse (
    data: SaveFormResponse,
    applicationId: number
  ): Promise<{
    applicationFormId: number;
    automaticallyRouted: boolean;
    currencyRequestedAmountEquivalent: number;
    amountRequested: number;
    currencyRequested: string;
  }> {
    const endpoint = `api/manager/applications/${applicationId}/SaveManagerResponse`;

    return this.httpRestService.post(endpoint, data);
  }

  sendReviewReminder (
    endpoint: string,
    clientUserId: number,
    clientEmailTemplateId: number,
    emailOptionsModel: EmailOptionsModelForSave
  ) {
    return this.httpRestService.post(endpoint, {
      clientUserId,
      clientEmailTemplateId,
      emailOptionsModel
    });
  }

  sendFormReminder (payload: FormReminderForApi) {
    const endpoint = 'api/manager/forms/SendReminderToCompleteForm';

    return this.httpRestService.post(endpoint, payload);
  }

  sendBulkRevisionReminder (
    payload: BulkRevisionRequestPayload
  ) {
    const endpoint = 'api/manager/applications/BulkSendFormRevisionReminder';

    return this.httpRestService.post(endpoint, payload);
  }

  sendBulkCancelRevisions (
      payload: BulkCancelRevisionRequestPayload
  ) {
    const endpoint = 'api/manager/applications/BulkSendCancelRevisions';

    return this.httpRestService.post(endpoint, payload);
  }

  requestBulkApplicationFormRevision (
    payload: BulkRevisionRequestPayload
  ) {
    const endpoint = 'api/manager/applications/BulkRevisionRequest';

    return this.httpRestService.post(endpoint, payload);
  }

  async saveReferenceFields (
    applicationId: number,
    formId: number,
    formRevisionId: number,
    applicationFormId: number,
    referenceFieldValues: ReferenceFieldAPI.ApplicationRefFieldResponseForApi[],
    tableReferenceFieldId: number,
    rowId: number,
    isManagerSavingApplicantForm = false,
    isEdit = false
  ): Promise<{
    rowId: number;
  }> {
    if (this.portal.isManager) {
      const endpoint = `api/manager/applications/${
        isManagerSavingApplicantForm ? 'ReferenceFields' : 'SaveManagerResponseReferenceFields'
      }`;

      return this.httpRestService.post(endpoint, {
        form: {
          formId,
          formRevisionId
        },
        applicationId,
        applicationFormId,
        referenceFieldValues,
        rowId,
        tableReferenceFieldId,
        editingApplicationView: isEdit
      });
    } else {
      const endpoint = 'api/portal/applications/ReferenceFields';

      return this.httpRestService.post(endpoint, {
        form: {
          formId,
          formRevisionId
        },
        applicationId,
        referenceFieldValues,
        tableReferenceFieldId,
        rowId
      });
    }
  }

 deleteTableRow (
    deletion: ReferenceFieldAPI.TableDeletion
  ) {
    const applicationId = deletion.applicationId;
    const applicationFormId = deletion.applicationFormId;
    const tableReferenceFieldId = deletion.tableReferenceFieldId;
    const rowId = deletion.rowId;
    const endpoint = this.portal.isManager ?
      `api/manager/applications/${applicationId}/ApplicationForms/${applicationFormId}/ReferenceFields/${tableReferenceFieldId}/TableData/${rowId}` :
      `api/portal/applications/${applicationId}/ApplicationForms/${applicationFormId}/ReferenceFields/${tableReferenceFieldId}/TableData/${rowId}`;

    return this.httpRestService.delete(endpoint);
  }

  shouldDisplayRevisionRequestChanges (
    applicationId: number,
    applicationFormId: number
  ): Promise<boolean> {
    const endpoint = `api/manager/applications/${applicationId}/${applicationFormId}/HasPreviousAnswers`;

    return this.httpRestService.get(endpoint);
  }

  getRevisionRequestChanges (
    applicationId: number,
    applicationFormId: number
  ): Promise<{
    referenceFieldIds: number[];
    applicationStandardFields: ReferenceFieldsUI.StandardFieldTypes[];
  }> {
    const endpoint = `api/manager/applications/${applicationId}/${applicationFormId}/PreviousAnswers`;

    return this.httpRestService.get(endpoint);
  }
}

