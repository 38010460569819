 import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { SimpleNumberMap, SimpleStringMap } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class FormFieldHelperState extends BaseYcState {
  readonly allReferenceFields: ReferenceFieldAPI.ReferenceFieldDisplayModel[];
  readonly referenceFieldMap: SimpleStringMap<ReferenceFieldAPI.ReferenceFieldDisplayModel> = {};
  readonly referenceFieldMapById: SimpleNumberMap<ReferenceFieldAPI.ReferenceFieldDisplayModel> = {};
  readonly isParentRefFieldMap: Record<number, boolean> = {};
  readonly parentPicklistValueMap: SimpleStringMap<string|string[]> = {};
  readonly currentFormRefFields: ReferenceFieldAPI.ReferenceFieldDisplayModel[];
  readonly allFieldKeys: string[];
}
