import { Pipe, PipeTransform } from '@angular/core';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';

@Pipe({
  name: 'getFilterTypeFromTableColumn',
  pure: true
})
export class GetFilterTypeFromTableColumn implements PipeTransform {

  constructor (
    private formFieldHelperService: FormFieldHelperService
  ) { }

  transform (
    column: ReferenceFieldsUI.TableFieldForUi
  ): string {
    const field = this.formFieldHelperService.referenceFieldMapById[
      column.referenceFieldId
    ];
    switch (field.type) {
      case ReferenceFieldsUI.ReferenceFieldTypes.Date:
        return 'date';
      case ReferenceFieldsUI.ReferenceFieldTypes.Number:
        return 'number';
      default:
        return 'text';
    }
  }
}
