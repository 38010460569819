import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '@core/services/currency.service';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';

@Pipe({
  name: 'gcMoney'
})
export class GcMoneyPipe implements PipeTransform {

  constructor (
    private clientSettingsService: ClientSettingsService,
    private currencyService: CurrencyService
  ) { }

  transform (
    amount: string|number,
    currency = this.clientSettingsService.defaultCurrency,
    showCurrencyAtEnd = false
  ): string {
    return this.currencyService.formatMoney(
      amount,
      currency,
      showCurrencyAtEnd
    );
  }

}
