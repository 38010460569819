import { Component, OnDestroy } from '@angular/core';
import { GcFlyoutService } from '@core/services/gc-flyout.service';
import { PolicyService } from '@core/services/policy.service';
import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { FundingSource, FundingSourceBudgetDetailModels, FundingSourceTypes } from '@core/typings/budget.typing';
import { ProcessingTypes } from '@core/typings/payment.typing';
import { BudgetService } from '@features/budgets/budget.service';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { PaymentProcessingService } from '@features/payment-processing/payment-processing.service';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory } from '@yourcause/common/modals';
import { Subscription } from 'rxjs';
import { AddEditFundingSourceModalComponent } from '../add-edit-funding-source-modal/add-edit-funding-source-modal.component';
import { TrackingEventNames } from '@yourcause/common/heap';
import { SupportService } from '@features/support/support.service';

@Component({
  selector: 'gc-funding-source-detail-flyout',
  templateUrl: './funding-source-detail-flyout.component.html',
  styleUrls: ['./funding-source-detail-flyout.component.scss']
})
export class FundingSourceDetailFlyoutComponent implements OnDestroy {
  fundingSource: FundingSource;
  FundingSourceTypes = FundingSourceTypes;
  ProcessingTypes = ProcessingTypes;
  unavailableHelpLink = 'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-faq-what-do-complex-budget-attributes-mean.html';
  reservedHelpLink = 'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-faq-what-do-complex-budget-attributes-mean.html';
  clientName = this.clientSettingsService.clientBranding.name;
  showProcessor = this.paymentProcessingService.processorType === ProcessingTypes.Both;
  canReserveFunds = this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.ReserveFunds);
  hasInsightsPermission = this.policyService.insights.canViewBudgets();
  budgets: FundingSourceBudgetDetailModels[] = [];
  programs: string[] = [];
  budgetSubHeader: string;
  programSubHeader: string;
  sub = new Subscription();

  constructor (
    private budgetService: BudgetService,
    private modalFactory: ModalFactory,
    private gcFlyoutService: GcFlyoutService,
    private clientSettingsService: ClientSettingsService,
    private paymentProcessingService: PaymentProcessingService,
    private policyService: PolicyService,
    private i18n: I18nService,
    private supportService: SupportService
  ) {
    this.sub.add(this.budgetService.changesTo$('fundingSourceDrilldownMap').subscribe(() => {
      if (!!this.budgetService.fundingSourceDrilldownMap[this.fundingSourceId]) {
        this.setHelpers();
      }
    }));
    this.sub.add(this.gcFlyoutService.changesTo$('currentIdForFlyout').subscribe(() => {
      this.setHelpers();
    }));
  }

  get fundingSourceId () {
    return +this.gcFlyoutService.currentIdForFlyout;
  }

  setHelpers () {
    this.fundingSource = this.budgetService.fundingSourceMap[this.fundingSourceId];
    const map = this.budgetService.fundingSourceDrilldownMap;
    this.budgets = map[this.fundingSourceId].fundingSourceBudgetDetailModels;
    this.programs = map[this.fundingSourceId].programNames;
    this.budgetSubHeader = this.i18n.translate(
      this.budgets.length === 1 ? 'BUDGET:textOneProgramUsingThisBudget' : 'BUDGET:textNumberBudgetsUsingFundingSource',
      {
        number: this.budgets.length
      },
      this.budgets.length === 1 ?
        '__number__ budget using this source' :
        '__number__ budgets using funding source'
    );
    this.programSubHeader = this.i18n.translate(
      this.programs.length === 1 ? 'BUDGET:textOneProgramUsingThisSource' : 'BUDGET:textNumberProgramsUsingFundingSource',
      {
        number: this.programs.length
      },
      this.programs.length === 1 ?
        '__number__ program using this source' :
        '__number__ programs using funding source'
    );
  }

  async editFundingSource () {
    const response = await this.modalFactory.open(
      AddEditFundingSourceModalComponent,
      {
        fundingSource: this.fundingSource
      }
    );
    if (response) {
      await this.budgetService.handleSaveFundingSource(response);
    }
  }

  viewInsights () {
    this.budgetService.navigateToFundingSourceInsights(this.fundingSourceId);
  }

  trackClick (link: string) {
    this.supportService.trackClick(TrackingEventNames.HelpLink, link)
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
