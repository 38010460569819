import { UserSignature } from '@core/typings/user.typing';
import { ApplicationFormSignature } from '@features/configure-forms/form.typing';
import { YcFile } from '@yourcause/common/files';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class SignatureState extends BaseYcState {
  readonly userSignature: UserSignature;
  readonly savedSignatureFile: YcFile;
  readonly formSignatureMap: Record<number, ApplicationFormSignature> = {};
}
