// import { Injectable } from '@angular/core';
// import { Resolve } from '@angular/router';
// import { ClassificationService } from '@core/services/classification.service';

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ClassificationService } from '@core/services/classification.service';

export const classificationResolver: ResolveFn<void> = () => {
  return inject(ClassificationService).setClassifications();
};
