import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';

@Injectable({ providedIn: 'root'})
export class ReportFieldResources {
  constructor (
    private http: HttpRestService,
    private portalDetermination: PortalDeterminationService
  ) { }

  get portal () {
    return this.portalDetermination.isManager ? 'manager' : 'portal';
  }

  getReportFieldResponse (
    applicationId: number
  ): Promise<AdHocReportingUI.ReportFieldResponseRow> {
    const endpoint = `api/${this.portal}/applications/${applicationId}/applicationData`;

    return this.http.get(endpoint);
  }

  getReportFieldNominationResponse (
    applicationId: number,
    formRevisionId: number
  ): Promise<ReferenceFieldAPI.NominationReportFieldResponse> {
    const endpoint = `api/${this.portal}/applications/GetNominationReportFieldData/${applicationId}/${formRevisionId}`;

    return this.http.get(endpoint);
  }

  getReportFieldResponsesBulk (
    applicationIds: number[]
  ): Promise<AdHocReportingUI.ReportFieldResponseRow[]> {
    const endpoint = 'api/manager/applications/BulkApplicationData';

    return this.http.post(endpoint, {
      applicationIds
    });
  }
}
