
<ng-container *ngIf="visible" [ngSwitch]="displayType">
  <yc-input *ngSwitchCase="DisplayTypes.TextField"
    [disabled]="disabled"
    [value]="response"
    [customLabelIcon]="customLabelIcon"
    [customLabelIconTooltip]="customLabelIconTooltip"
    [customLabelIconClass]="customLabelIconClass"
    [label]="label"
    [description]="description"
    (onChange)="handleDataChange($event)">
  </yc-input>
  <yc-textarea *ngSwitchCase="DisplayTypes.TextArea"
    [disabled]="disabled"
    [value]="response"
    [customLabelIcon]="customLabelIcon"
    [customLabelIconTooltip]="customLabelIconTooltip"
    [customLabelIconClass]="customLabelIconClass"
    [label]="label"
    [description]="description"
    (onChange)="handleDataChange($event)">
  </yc-textarea>
  <ng-container *ngSwitchCase="DisplayTypes.Raw">
    <yc-form-label
      [label]="label"
      [description]="description"
      [customIcon]="customLabelIcon"
      [customIconTooltip]="customLabelIconTooltip"
      [customIconClass]="customLabelIconClass">
    </yc-form-label>
    <div [innerHTML]="response">
    </div>
  </ng-container>
</ng-container>
