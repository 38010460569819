<yc-modal
  [modalHeader]="'GLOBAL:btnImport' | ycI18n: 'Import'"
  [modalSubHeader]="tableLabel"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  (onCancel)="closeModal.emit()">

  <yc-dynamic-csv-import
    [ValidationClass]="ValidationClass"
    (validChange)="handleUpdateValidation($event)"
    (contentsChange)="onContentsChange($event)">
    <ng-template #templateDownload>
      <div class="first-step-custom">
        <div class="header">
          <yc-i18n
            key="common:textStep1Download"
            defaultValue="Step 1: Download">
          </yc-i18n>
        </div>
        <div class="desc">
          <yc-i18n
            key="FORMS:textStep1ImportTableRowsDesc"
            defaultValue="Click the button below to download a file to use for your data import. Previously added rows will appear in the file. Imported data will replace any data previously added. Once you fill out the file, return here and proceed to step 2.">
          </yc-i18n>
        </div>
        <div class="lower">
          <yc-button
            [primary]="true"
            (onClick)="downloadTemplate()">
            <yc-fa
              icon="download"
              class="me-2">
            </yc-fa>
            <yc-i18n
              key="common:textDownload"
              defaultValue="Download">
            </yc-i18n>
          </yc-button>
        </div>
      </div>
    </ng-template>
  </yc-dynamic-csv-import>

    <!-- Modal Footer -->
  <div *ycModalFooter
    class="modal-footer">
    <div class="flex-grow-1 d-flex full-width justify-content-between">
      <div class="d-flex">
        <!-- Cancel left aligned -->
        <yc-button
          [link]="true"
          [langKey]="'common:btnCancel'"
          [defaultText]="'Cancel'"
          class="d-flex justify-content-start"
          (onClick)="closeModal.emit()">
        </yc-button>
      </div>
      <div class="d-flex align-items-center">

        <!-- Popover Primary Button -->
        <ng-container>
          <button #importPopover="bs-popover" 
            class="btn btn-primary"
            [disabled]="!importValid || saving"
            placement="top"
            [popover]="importPopoverTemplate"
            [popoverContext]="{
              importPopover: importPopover
            }"
            [outsideClick]="true"
            (click)="setCurrentPopover(importPopover)">
            <yc-i18n
              key="GLOBAL:btnImport"
              defaultValue="Import">
            </yc-i18n>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</yc-modal>

<ng-template #importPopoverTemplate
  let-importPopover="importPopover">
  <span>
    <yc-i18n
      key="GLOBAL:textAreYouSureImportTableRows"
      defaultValue="Previously added responses will be replaced by the data in the import file. Are you sure you want to import?">
    </yc-i18n>
  </span>
  <div class="d-flex justify-content-end mb-2 mt-2 align-items-center">
    <yc-button 
      [link]="true" 
      [langKey]="'common:textNo'"
      [defaultText]="'No'"
      class="me-3"
      (onClick)="importPopover.hide()">
    </yc-button>
    <yc-button 
      [primary]="true"
      [langKey]="'common:textYes'"
      [defaultText]="'Yes'"
      (onClick)="importPopover.hide(); onImport();">
    </yc-button>
  </div>
</ng-template>