import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { BulkCollaboratorResponse, Collaborator, CollaboratorForManager, CollaboratorForPortal, CollaboratorInfo } from '@core/typings/collaboration.typing';
import { AddressFormatterService } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class CollaborationResources {

  constructor (
    private httpRestService: HttpRestService,
    private determinationService: PortalDeterminationService,
    private addressFormatter: AddressFormatterService
  ) { }

   private getApplicationAPIBase (applicationId: number) {
    return `/api/${this.determinationService.isManager ? 'manager' : 'portal'}/applications/` + applicationId + '/collaborators';
  }

  getCollaborators (id: number): Promise<Collaborator[]> {
    if (this.determinationService.isManager) {
      return this.getCollaboratorsForManager(id);
    } else {
      return this.getCollaboratorsForPortal(id);
    }
  }

  getCollaboratorsBulk (
    applicationIds: number[]
  ): Promise<BulkCollaboratorResponse[]> {
    const endpoint = 'api/manager/applications/GetBulkCollaborators';

    return this.httpRestService.post(endpoint, {
      applicationIds
    });
  }

  async getCollaboratorsForPortal (id: number) {
    const endpoint = this.getApplicationAPIBase(id);
    const response: CollaboratorForPortal[] = await this.httpRestService.get(endpoint);

    return response.map((collab) => {
      return {
        id: collab.id,
        firstName: collab.firstName,
        lastName: collab.lastName,
        email: collab.email,
        canManageApplicants: collab.canManageApplicants,
        canReceiveEmails: collab.canReceiveEmails,
        isApplicationOwner: collab.isApplicationOwner,
        phoneNumber: collab.phoneNumber,
        addressString: this.addressFormatter.format({
          address1: collab.address,
          address2: collab.address2,
          city: collab.city,
          stateProvRegCode: collab.state,
          postalCode: collab.postalCode,
          country: collab.country
        }, true)
      };
    });
  }

  async getCollaboratorsForManager (id: number) {
    const endpoint = this.getApplicationAPIBase(id);
    const response: CollaboratorForManager[] = await this.httpRestService.get(endpoint);

    return response.map((collab) => {
      return {
        id: collab.id,
        firstName: collab.firstName,
        lastName: collab.lastName,
        email: collab.email,
        canManageApplicants: collab.canManageApplicants,
        canReceiveEmails: collab.canReceiveEmails,
        isApplicationOwner: collab.isApplicationOwner,
        phoneNumber: collab.phoneNumber,
        addressString: this.addressFormatter.format(
          {
            ...collab.address,
            address1: collab.address.address,
            stateProvRegCode: collab.address.state
          },
          true
        )
      };
    });
  }

  addCollaborator (
    applicationId: number,
    applicantInfo: CollaboratorInfo
  ) {
    const endpoint = this.getApplicationAPIBase(applicationId);

    return this.httpRestService.post(endpoint, applicantInfo);
  }

  makeCollaboratorOwner (
    applicationId: number,
    applicantId: number
  ) {
    const endpoint = this.getApplicationAPIBase(applicationId) + '/' + applicantId + '/MakeOwner';

    return this.httpRestService.post(endpoint, {});
  }

  async updateCollaboratorPermissions (
    applicationId: number,
    applicantInfo: CollaboratorInfo
  ) {
    const endpoint = this.getApplicationAPIBase(applicationId) + '/' + applicantInfo.applicantId;
    await this.httpRestService.post(endpoint, {
      canManageApplicants: applicantInfo.canManageApplicants,
      canReceiveEmails: applicantInfo.canReceiveEmails
    });
  }

  detachCollaborator (
    applicationId: number,
    applicantId: number
  ) {
    const endpoint = this.getApplicationAPIBase(applicationId) + '/' + applicantId;

    return this.httpRestService.delete(endpoint);
  }

}
