import { Pipe, PipeTransform } from '@angular/core';
import { WorkflowLevelPermissions } from '@core/typings/workflow.typing';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({ name: 'gcPermissionsDisplay' })
export class PermissionsDisplayPipe implements PipeTransform {
  workflowPermissionsMap = {
    [WorkflowLevelPermissions.LEVEL_ONLY]: this.i18n.translate(
      'WORKFLOW:textWorkflowLevelOnly',
      {},
      'Workflow level only'
    ),
    [WorkflowLevelPermissions.PASSTHROUGH]: this.i18n.translate(
      'WORKFLOW:textWorkflowPassthrough',
      {},
      'Workflow passthrough'
    ),
    [WorkflowLevelPermissions.MANAGER]: this.i18n.translate(
      'WORKFLOW:textWorkflowManager',
      {},
      'Workflow manager'
    )
  };

  constructor (
    private i18n: I18nService
  ) { }

  transform (permissions: WorkflowLevelPermissions[]): string {
    return permissions.map((permission) => {
      return this.workflowPermissionsMap[permission];
    }).join(', ');
  }
}
