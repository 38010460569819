import { ClientIntAuthorities, ClientSettings, ConfigureSettingsMap, Currency, CurrencySetting } from '@core/typings/api/admin-client.typing';
import { ClientBrandingForUi, InternationalSettings } from '@core/typings/branding.typing';
import { ClientAffiliateInfo } from '@core/typings/client-user.typing';
import { SearchFilter } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class ClientSettingsState extends BaseYcState {
  readonly langKeys: string[];
  readonly currencies: Currency[];
  readonly clientBranding: ClientBrandingForUi;
  readonly clientSettings: ClientSettings;
  readonly configureSettingsMap: ConfigureSettingsMap = {};
  readonly internationalSettings: InternationalSettings;
  readonly selectedLanguages: string[];
  readonly selectedCurrencies: CurrencySetting[];
  readonly defaultCurrency: string;
  readonly defaultLanguage: string;
  readonly clientIntAuthorities: ClientIntAuthorities;
  readonly domesticRegAuthFilters: SearchFilter[];
  readonly affiliateInfo: ClientAffiliateInfo;
}
