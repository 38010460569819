<div class="wrapper"
  [class.nested-group]="currentDepth > 1">

  <!-- Actions -->
  <div class="group-actions">

    <!-- Switch for All / Any -->
    <div class="global-and">
      <ng-container *ngIf="(group.conditions.length > 1) ||
          (currentDepth > 1)">
        <yc-switch
          [compact]="false"
          [disabled]="isViewOnly  || (group.conditions.length < 2)"
          [state]="group.conditions.length > 1 ? useAndSwitchState : SwitchState.None"
          [toggledText]="'common:lblAllCap' | ycI18n: 'All'"
          [untoggledText]="'common:lblAnyCap' | ycI18n: 'Any'"
          (stateChange)="andOrChange($event)">
        </yc-switch>
  
        <yc-i18n
          key="common:textAnyAllOfTheConditionsMatch"
          defaultValue="__anyOrAll__ of the conditions match"
          [context]="{ anyOrAll: '' }">
        </yc-i18n>
      </ng-container>
    </div>


    <div class="group-crud">
      <!-- Add Condition -->
      <yc-button
        [link]="true"
        [fontAwesomeIcon]="'clipboard-list'"
        [disabled]="isViewOnly"
        [langKey]="'common:btnAddCondition'"
        [defaultText]="'Add condition'"
        class="me-3"
        (onClick)="addCondition()">
      </yc-button>

      <!-- Add Rule -->
      <yc-button *ngIf="currentDepth < maxDepth"
        [link]="true"
        [disabled]="isViewOnly"
        class="me-3 align-self-end"
        [fontAwesomeIcon]="'folder-tree'"
        [langKey]="'common:btnAddNewRule'"
        [defaultText]="'Add new rule'"
        (onClick)="addGroup()">
      </yc-button>

      <!-- Remove -->
      <yc-button *ngIf="currentDepth > 1"
        [link]="true"
        [fontAwesomeIcon]="'times'"
        [textDanger]="true"
        [langKey]="'common:textDeleteRule'"
        [defaultText]="'Delete rule'"
        (onClick)="onRemove.emit()">
      </yc-button>
    </div>
  </div>
  <div class="inner-content"
    [class.one-condition]="group.conditions.length === 1 && currentDepth === 1">
  
    <div class="child-wrapper">
      <div *ngFor="let child of group.conditions;
        trackBy: trackBy;
        let index = index;
        let last = last;"
        class="group-wrapper"
        [class.last]="last">

        <!-- Tree Connector -->
        <div class="filter-liner"
          [class.for-condition]="!(child | gcIsLogicGroup)">
          <div class="top"></div>
          <div class="bottom"></div>
        </div>

        <!-- Group -->
        <gc-conditional-logic-builder-group *ngIf="child | gcIsLogicGroup; else condition"
          [availableColumns]="availableColumns"
          [currentDepth]="currentDepth + 1"
          [maxDepth]="maxDepth"
          [isViewOnly]="isViewOnly"
          [group]="child | ycAs: $logicGroupType"
          (groupChange)="handleConditionOrGroupChange($event, index)"
          (validChange)="handleConditionOrGroupValidChange($event, index)"
          (onRemove)="handleRemoveCondition(index)">
        </gc-conditional-logic-builder-group>

        <!-- Condition -->
        <ng-template #condition>
          <gc-conditional-logic-builder-condition
            [availableColumns]="availableColumns"
            [isViewOnly]="isViewOnly"
            [condition]="child | ycAs: $logicConditionType"
            (conditionChange)="handleConditionOrGroupChange($event, index)"
            (validChange)="handleConditionOrGroupValidChange($event, index)"
            (removeCondition)="handleRemoveCondition(index)">
          </gc-conditional-logic-builder-condition>
        </ng-template>
      </div>
    </div>
  </div>
</div>

