import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { BaseApplication } from '@core/typings/application.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { UIExternalAPI } from '@core/typings/ui/external-api.typing';
import { FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { ExternalAPIService } from '@features/external-api/external-api.service';
import { ExternalAPISelection } from '@features/forms/component-configuration/external-api-selector-settings/external-api-selector-settings.component';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gc-form-external-api-field',
  templateUrl: './form-external-api-field.component.html',
  styleUrls: ['./form-external-api-field.component.scss']
})
export class FormExternalAPIFieldComponent implements OnInit, OnDestroy {
  @Input() apiConfig: ExternalAPISelection;
  @Input() data: string;
  @Input() label: string;
  @Input() description: string;
  @Input() placeholder: string;
  @Input() displayType: AdHocReportingUI.DisplayTypes;
  @Input() relatedComponent: string;
  @Input() visibleToApplicants: boolean;
  @Input() visibleToManagers: boolean;
  @Input() dataUpdates: UIExternalAPI.DataUpdates;
  @Input() required: boolean;
  @Input() component: FormDefinitionComponent;
  @Input() parentFields: Partial<BaseApplication>;
  @Input() customLabelIcon: string;
  @Input() customLabelIconTooltip: string;
  @Input() customLabelIconClass: string;
  @Output() dataChange = new EventEmitter<string>();

  response: string;
  DisplayTypes = AdHocReportingUI.DisplayTypes;
  sub: Subscription;
  visible = true;
  disabled = true;

  constructor (
    private externalAPIService: ExternalAPIService,
    private portal: PortalDeterminationService,
    private formBuilderService: FormBuilderService
  ) { }

  ngOnInit () {
    this.disabled = !this.isInEligibilitySetup();
    this.response = this.data;
    const guid = this.apiConfig?.integrationId;

    if (guid && this.parentFields) {

      // we should be able to specify the webservice response
      // if filling out the eligibility form in program setup
      // make the call if not in eligibility setup AND either
      // the update is set to always make the call OR
      // the form is editable (not submitted)
      if (this.disabled) {
        if (!this.formBuilderService.inFormBuilder) {
          if (this.portal.isManager) {
            this.visible = this.visibleToManagers;
          }

          if (this.portal.isApply) {
            this.visible = this.visibleToApplicants;
          }
        }
        if (this.dataUpdates === UIExternalAPI.DataUpdates.Always || (!this.component.disabled)) {
          this.sub = this.externalAPIService
            .getIntegrationRequest(guid, this.relatedComponent, this.apiConfig.responseField)
            .subscribe(result => {
              this.response = '' + result;

              // set the data and emit the change only if it is set to the right setting
              if (this.dataUpdates === UIExternalAPI.DataUpdates.UntilSubmitted) {
                this.data = this.response;
                this.dataChange.emit(this.data);
              }
            });
        }
      }
    }
  }

  handleDataChange (
    event: string
  ) {
    this.data = event;
    this.response = event;
    this.dataChange.emit(event);
  }

  isInEligibilitySetup () {
    return location.pathname.startsWith('/management/program-setup/programs/program');
  }

  ngOnDestroy () {
    this.sub?.unsubscribe();
  }
}
