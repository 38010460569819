import { PortalTypes } from '@core/services/portal-determination.service';

const API_OVERRIDE = localStorage.getItem('YC_GC_QA_API_OVERRIDE');

const localhostLocationBase: keyof EnvironmentMap = 'localhost';

interface EnvironmentMap<T = string> {
  'localhost': T;
  'yourcausegrantsqa.com': T;
  'surge.sh': T;
  'azureedge.net': T;
  'yourcausegrantsuat.com': T;
  'yourcausegrants.com': T;
  // TODO: update to yourcausegrantsdev.com
  'dev-grantsconnect-ui.azurewebsites.net': T;
}

// change this variable to test against a different environment locally
// https://localhost:44392
const QA_FIREWALL_API_URL = 'https://api-secure.yourcausegrantsqa.com';

const apiUrls: EnvironmentMap<string> = {
  localhost: API_OVERRIDE || QA_FIREWALL_API_URL,
  'yourcausegrantsqa.com': API_OVERRIDE || QA_FIREWALL_API_URL,
  'surge.sh': API_OVERRIDE || QA_FIREWALL_API_URL,
  'azureedge.net': API_OVERRIDE || QA_FIREWALL_API_URL,
  'yourcausegrantsuat.com': 'https://api-secure.yourcausegrantsuat.com',
  'yourcausegrants.com': 'https://api-secure.yourcausegrants.com',
  'dev-grantsconnect-ui.azurewebsites.net': API_OVERRIDE || 'https://dev-grantsconnect-api.azurewebsites.net'
};

const appInsightsKeys: EnvironmentMap<string> = {
  localhost: null,
  'yourcausegrantsqa.com': 'd041c79b-2cf2-4545-bff6-e0e0e4597682',
  'surge.sh': 'd041c79b-2cf2-4545-bff6-e0e0e4597682',
  'azureedge.net': 'd041c79b-2cf2-4545-bff6-e0e0e4597682',
  'yourcausegrantsuat.com': '1a8b13d6-f89d-49fa-8c7c-b6e7041d8993',
  'yourcausegrants.com': '239f2983-e2ed-43e5-951b-6c3fa7e8ff4f',
  'dev-grantsconnect-ui.azurewebsites.net': '220a15c0-ae0c-497b-8864-8008ccde146b'
};

const fileUploadBases: EnvironmentMap<string> = {
  localhost: 'https://qagrantsconnect.blob.core.windows.net',
  'yourcausegrantsqa.com': 'https://qagrantsconnect.blob.core.windows.net',
  'surge.sh': null,
  'azureedge.net': null,
  'yourcausegrantsuat.com': 'https://uatgrantsconnect.blob.core.windows.net',
  'yourcausegrants.com': 'https://productiongrantsconnect.blob.core.windows.net',
  'dev-grantsconnect-ui.azurewebsites.net': null
};

const identityServers: EnvironmentMap<string> = {
  localhost: 'https://idsvr.yourcausetest.com',
  'yourcausegrantsqa.com': 'https://idsvr.yourcausetest.com',
  'surge.sh': 'https://idsvr.yourcausetest.com',
  'azureedge.net': 'https://idsvr.yourcausetest.com',
  'yourcausegrantsuat.com': 'https://idsvr.yourcauseuat.com',
  'yourcausegrants.com': 'https://idsvr.yourcause.com',
  'dev-grantsconnect-ui.azurewebsites.net': 'https://idsvr.yourcausetest.com'
};

const ssoClientIds: EnvironmentMap<string> = {
  localhost: 'f14e523b-8c3d-4bf4-bee6-215fed845fc0',
  'yourcausegrantsqa.com': 'f14e523b-8c3d-4bf4-bee6-215fed845fc0',
  'surge.sh': 'f14e523b-8c3d-4bf4-bee6-215fed845fc0',
  'azureedge.net': 'f14e523b-8c3d-4bf4-bee6-215fed845fc0',
  'yourcausegrantsuat.com': 'f14e523b-8c3d-4bf4-bee6-215fed845fc0',
  'yourcausegrants.com': 'f14e523b-8c3d-4bf4-bee6-215fed845fc0',
  'dev-grantsconnect-ui.azurewebsites.net': 'f14e523b-8c3d-4bf4-bee6-215fed845fc0'
};

const ssoApplicantClientIds: EnvironmentMap<string> = {
  localhost: 'b14e352d-5e3d-1bf4-dcc3-542bfd845bc1',
  'yourcausegrantsqa.com': 'b14e352d-5e3d-1bf4-dcc3-542bfd845bc1',
  'dev-grantsconnect-ui.azurewebsites.net': 'b14e352d-5e3d-1bf4-dcc3-542bfd845bc1',
  'surge.sh': 'b14e352d-5e3d-1bf4-dcc3-542bfd845bc1',
  'azureedge.net': 'b14e352d-5e3d-1bf4-dcc3-542bfd845bc1',
  'yourcausegrantsuat.com': 'b14e352d-5e3d-1bf4-dcc3-542bfd845bc1',
  'yourcausegrants.com': 'b14e352d-5e3d-1bf4-dcc3-542bfd845bc1'
};

const nppApiUrls: EnvironmentMap<string> = {
  localhost: 'https://yc-dev-qa.azurefd.net/qa-npp',
  'dev-grantsconnect-ui.azurewebsites.net': 'https://yc-dev-qa.azurefd.net/dev-npp',
  'yourcausegrantsqa.com': 'https://yc-dev-qa.azurefd.net/qa-npp',
  'surge.sh': 'https://yc-dev-qa.azurefd.net/qa-npp',
  'azureedge.net': 'https://yc-dev-qa.azurefd.net/qa-npp',
  'yourcausegrantsuat.com': 'https://yc-prod.azurefd.net/uat-npp',
  'yourcausegrants.com': 'https://yc-prod.azurefd.net/prod-npp'
};

const subdomainSupport: EnvironmentMap<boolean> = {
  localhost: false,
  'surge.sh': false,
  'azureedge.net': false,
  'dev-grantsconnect-ui.azurewebsites.net': false,
  'yourcausegrantsqa.com': true,
  'yourcausegrantsuat.com': true,
  'yourcausegrants.com': true
};

const supportsDevTools: EnvironmentMap<boolean> = {
  localhost: true,
  'surge.sh': false,
  'azureedge.net': false,
  'dev-grantsconnect-ui.azurewebsites.net': false,
  'yourcausegrantsqa.com': true,
  'yourcausegrantsuat.com': false,
  'yourcausegrants.com': false
};

const maxAdHocReportColumns: EnvironmentMap<number> = {
  localhost: 100,
  'surge.sh': 100,
  'azureedge.net': 100,
  'dev-grantsconnect-ui.azurewebsites.net': 100,
  'yourcausegrantsqa.com': 100,
  'yourcausegrantsuat.com': 100,
  'yourcausegrants.com': 100
};

const supportsPushStandardFields: EnvironmentMap<boolean> = {
  localhost: true,
  'surge.sh': true,
  'azureedge.net': true,
  'dev-grantsconnect-ui.azurewebsites.net': false,
  'yourcausegrantsqa.com': true,
  'yourcausegrantsuat.com': true,
  'yourcausegrants.com': false
};

const supportsImportStandardFields: EnvironmentMap<boolean> = {
  localhost: true,
  'surge.sh': true,
  'azureedge.net': true,
  'dev-grantsconnect-ui.azurewebsites.net': true,
  'yourcausegrantsqa.com': false,
  'yourcausegrantsuat.com': false,
  'yourcausegrants.com': true
};

const mixpanelProjectIds: EnvironmentMap<string> = {
  localhost: '',
  'surge.sh': 'd0da4fef58da57384faf87347666214b',
  'azureedge.net': 'd0da4fef58da57384faf87347666214b',
  'dev-grantsconnect-ui.azurewebsites.net': 'd0da4fef58da57384faf87347666214b',
  'yourcausegrantsqa.com': 'd0da4fef58da57384faf87347666214b',
  'yourcausegrantsuat.com': '84087f9a38a5c2a83f37d37b98dc91b8',
  'yourcausegrants.com': 'be6c388bfdbde480c5a7a7ebc5277598'
};

// if we are on an azure domain we can't use or have a subdomain
let actualLocationBase = (location.hostname.includes('azurewebsites.net') ?
  location.hostname : location.hostname
    .split('.')
    .slice(-2)
    .join('.')) as keyof EnvironmentMap;

let locationBase = actualLocationBase;

const isTest = navigator.userAgent.includes('Node.js') ||
  navigator.userAgent.includes('jsdom');

if (locationBase.includes('localhost') || !apiUrls[locationBase]) {
  actualLocationBase = 'localhost';
  locationBase = localhostLocationBase;
}

if (isTest) {
  locationBase = 'localhost';
}

const version = require('./version.json');
export const environment = {
  production: (actualLocationBase !== 'localhost') && !isTest,
  hmr: false,
  apiUrl: apiUrls[locationBase],
  nppApiUrl: nppApiUrls[locationBase],
  version,
  appInsightsKey: appInsightsKeys[locationBase],
  identityServer: identityServers[locationBase],
  ssoClientId: ssoClientIds[locationBase],
  ssoApplicantClientId: ssoApplicantClientIds[locationBase],
  locationBase,
  actualLocationBase,
  googleApiKey: 'AIzaSyCr6Ur98TNl2WQBm7wfbuG-y039INALkOY',
  isTest,
  isUat: locationBase === 'yourcausegrantsuat.com',
  get routeBase () {
    return isTest ?
      'management' :
      (location.pathname.split('/')[1] as PortalTypes);
  },
  get isLocalhost () {
    return actualLocationBase === 'localhost';
  },
  supportsSubdomains: subdomainSupport[actualLocationBase],
  supportsDevTools: supportsDevTools[actualLocationBase],
  maxAdHocReportColumns: maxAdHocReportColumns[locationBase],
  fileUploadBases: fileUploadBases[locationBase],
  supportsPushStandardFields: supportsPushStandardFields[locationBase],
  supportsImportStandardFields: supportsImportStandardFields[locationBase],
  useNewRenderer: true,
  mixpanelProjectId: mixpanelProjectIds[locationBase],
  bbidGcEnvironmentId: 'p-KOuG7duXXUWiUr4pil5H2g',
  bbidPreviousEnvironmentIds: ['t-1NCioWIpA02ncaQYqQLWNw'],
  bbidGcServiceId: 'ycgc'
};
