import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { YcFile } from '@yourcause/common/files';
import { APIApplicationCommunicationList, APIOrganizationCommunicationList, ApplicationAPICommunication, ApplicationCommunication, FullEmail, OrganizationAPICommunication } from './communications.typing';

@Injectable({ providedIn: 'root' })
export class CommunicationsResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getCommunicationsForApplication (id: number) {
    return this.httpRestService.get<APIApplicationCommunicationList>(
      '/api/manager/communications/GetApplicationCommunications?applicationId=' + id
    );
  }

  getCommunicationsForOrganization (id: number) {
    const endpoint = '/api/manager/communications/GetOrganizationCommunications?organizationId=' + id;

    return this.httpRestService.get<APIOrganizationCommunicationList>(endpoint);
  }

  getApplicationCommunicationDetails (applicationCommunicationId: number) {
    return this.httpRestService.get<ApplicationCommunication>(
      '/api/manager/communications/GetApplicationCommunicationDetails?applicationCommunicationId=' + applicationCommunicationId
    );
  }

  getOrganizationCommunicationDetails (
    organizationCommunicationId: number
  ) {
    return this.httpRestService.get<ApplicationCommunication>(
      'api/manager/communications/GetOrganizationCommunicationDetails?organizationCommunicationId=' + organizationCommunicationId
    );
  }

  getEmailDetails (emailQueueId: number) {
    return this.httpRestService.get<FullEmail>(
      '/api/manager/communications/GetEmailDetails?emailQueueId=' + emailQueueId
    );
  }

  addApplicationCommunication (
    apiCommunication: ApplicationAPICommunication
  ) {
    return this.httpRestService.post(
      '/api/manager/communications/AddApplicationCommunicationRecord',
      apiCommunication
    );
  }

  updateApplicationCommunication (
    apiCommunication: ApplicationAPICommunication
  ) {
    return this.httpRestService.post(
      '/api/manager/communications/UpdateApplicationCommunicationRecord',
      apiCommunication
    );
  }

  deleteApplicationCommunication (
    applicationCommunicationsId: number
  ) {
    return this.httpRestService.post(
      '/api/manager/communications/DeleteApplicationCommunicationRecord',
      { applicationCommunicationsId }
    );
  }

  addOrganizationCommunication (
    apiCommunication: OrganizationAPICommunication
  ) {
    return this.httpRestService.post(
      '/api/manager/communications/AddOrganizationCommunicationRecord',
      apiCommunication
    );
  }

  updateOrganizationCommunication (
    apiCommunication: OrganizationAPICommunication
  ) {
    return this.httpRestService.post(
      'api/manager/communications/UpdateOrganizationCommunicationRecord',
      apiCommunication
    );
  }

  deleteOrganizationCommunication (
    organizationCommunicationsId: number,
    organizationId: number
  ) {
    const endpoint = '/api/manager/communications/DeleteOrganizationCommunicationRecord';

    return this.httpRestService.post(endpoint, {
      organizationCommunicationsId,
      organizationId
    });
  }

  uploadCommunicationFile (uploadRequest: YcFile): Promise<number> {
    const endpoint = 'api/manager/communications/File';

    return this.httpRestService.postFile(endpoint, uploadRequest.file as File);
  }

  async getAccessUrlForNonprofitCommunication (
    organizationCommunicationId: number,
    fileUploadId: number
  ): Promise<string> {
    const endpoint = `api/manager/applications/${organizationCommunicationId}/CommunicationAccessUrl/${fileUploadId}`;
    const response = await this.httpRestService.get<{ file: string }>(endpoint);

    return response.file;
  }
}

