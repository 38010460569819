import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { AppSharedModule } from './app.shared.module';

@NgModule({
  imports: [
    AppSharedModule,
    RouterModule.forRoot(
      routes,
      {
        paramsInheritanceStrategy: 'always'
      }
    )
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
