import { Component, Input, OnInit } from '@angular/core';
import { BulkOptions } from '@yourcause/common';
import { YCModalComponent } from '@yourcause/common/modals';
import { cloneDeep } from 'lodash';
import { SpellCheckCorrection } from '../form-builder.typing';
import { FormBuilderService } from '../services/form-builder/form-builder.service';

@Component({
  selector: 'gc-form-builder-spell-check-corrections-modal',
  templateUrl: './form-builder-spell-check-corrections-modal.component.html',
  styleUrls: ['./form-builder-spell-check-corrections-modal.component.scss']
})
export class FormBuilderSpellCheckCorrectionsModalComponent extends YCModalComponent<SpellCheckCorrection[]> implements OnInit {
  @Input() corrections: SpellCheckCorrection[];

  hasPages = false;
  bulkOptions: BulkOptions<SpellCheckCorrection> = {
    recordIdentifierAttribute: 'type',
    onlyForTrackingRows: true,
    retainBulkSelectedItems: true,
    bulkActions: [],
    addBulkFilterToOnlyShowSelected: true
  };
  bulkSelected: SpellCheckCorrection[];

  constructor (
    private formBuilderService: FormBuilderService
  ) {
    super();
  }

  ngOnInit () {
    this.bulkSelected = cloneDeep(this.corrections);
    this.hasPages = this.formBuilderService.currentFormBuilderDefinition.length > 1;
  }

}
