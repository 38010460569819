import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { CyclesAPI } from '@core/typings/api/cycles.typing';

@Injectable({ providedIn: 'root' })
export class CyclesResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getArchiveCycleStats (cycleId: number): Promise<CyclesAPI.ArchiveCycleStats> {
    const endpoint = `api/manager/programs/ArchivedCycleStats?cycleId=${cycleId}`;

    return this.httpRestService.get(endpoint);
  }

  archiveCycle (cycleId: number, comment: string) {
    const payload = {
      grantProgramCycleId: cycleId,
      archive: true,
      archiveNotes: comment
    };
    const endpoint = 'api/manager/programs/ArchiveProgramCycleAndApplications';

    return this.httpRestService.post(endpoint, payload);
  }

  unarchiveCycle (cycleId: number) {
    const payload = {
      grantProgramCycleId: cycleId,
      archive: false
    };
    const endpoint = 'api/manager/programs/ArchiveProgramCycleAndApplications';

    return this.httpRestService.post(endpoint, payload);
  }

  deleteCycle (cycleId: number) {
    const endpoint = `api/manager/programs/DeleteProgramCycle/${cycleId}`;

    return this.httpRestService.post(endpoint, {});
  }

  getAllCycles (): Promise<CyclesAPI.SimpleCycle[]> {
    const endpoint = 'api/manager/programs/Cycles';

    return this.httpRestService.get(endpoint);
  }

  bulkCycleUpdate (payload: CyclesAPI.BulkCycleUpdatePayload) {
    return this.httpRestService.post('api/manager/applications/BulkUpdateApplicationCycles', payload);
  }
}
