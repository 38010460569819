import { CommunicationPrefs, Email, EmailDetail, EmailMergeModel, EmailTemplateTranslationsMap } from '@features/system-emails/email.typing';
import { SimpleStringMap } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class EmailState extends BaseYcState {
  readonly emails: Email[];
  readonly emailSubjectMap: SimpleStringMap<string>;
  readonly templateMap: {
    [x: string]: EmailDetail;
  } = {};

  readonly mergeModelMap: {
    [emailId: string]: EmailMergeModel;
  };
  readonly emailTemplateTranslationMap: EmailTemplateTranslationsMap;
  readonly communicationPrefs: CommunicationPrefs[];
}
