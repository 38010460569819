import { Budget, BudgetDashboardMap, BudgetDetailMap, BudgetDrilldownMap, BudgetFundingSourceAudit, FundingSource, FundingSourceDashboardMap, FundingSourceDrilldownMap, FundingSourceInsights, SimpleBudgetFundingSource } from '@core/typings/budget.typing';
import { SystemTags } from '@features/system-tags/typings/system-tags.typing';
import { SimpleStringMap } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class BudgetState extends BaseYcState {
  readonly budgets: Budget[];
  readonly budgetDetailMap: BudgetDetailMap = {};
  readonly budgetNameMap: {
    [b: string]: string;
  } = {};
  readonly budgetTagsMap: Record<number, SystemTags.TagDefinitionForUi[]> = {};
  readonly fundingSourceMap: Record<number, FundingSource> = {};
  readonly simpleBudgetMap: SimpleStringMap<Budget> = {};

  readonly budgetFundingSources: SimpleBudgetFundingSource[];

  readonly openFundingSources: FundingSource[];
  readonly closedFundingSources: FundingSource[];
  readonly fundingSourcesForDashboard: FundingSourceInsights[];
  readonly budgetsForDashboard: Budget[];

  readonly allBudgetOptions: TypeaheadSelectOption[];
  readonly cashBudgetOptions: TypeaheadSelectOption[];
  readonly inKindBudgetOptions: TypeaheadSelectOption[];
  readonly allSourceOptions: TypeaheadSelectOption[];
  readonly cashSourceOptions: TypeaheadSelectOption[];
  readonly inKindSourceOptions: TypeaheadSelectOption[];
  readonly myBudgetOptions: TypeaheadSelectOption[]; // segmented
  readonly mySourceOptions: TypeaheadSelectOption[]; // segmented

  readonly budgetDashboardMap: BudgetDashboardMap = {};
  readonly fundingSourceDashboardMap: FundingSourceDashboardMap = {};
  readonly unallocatedSourceMap: SimpleStringMap<number> = {};

  readonly closedBudgets: Budget[];
  readonly openBudgets: Budget[];

  readonly budgetDrilldownMap: BudgetDrilldownMap = {};
  readonly fundingSourceDrilldownMap: FundingSourceDrilldownMap = {};

  readonly budgetAuditTrailMap: Record<number, BudgetFundingSourceAudit[]> = {};
}
