<ng-container [formGroup]="calculationOptionsFormGroup">
  <!-- plus or minus -->
  <yc-typeahead-select
    class="me-2"
    name="operator"
    [srOnlyLabel]="true"
    [disabled]="isViewOnly"
    [label]="'common:textOperatorForCalculation' | ycI18n: 'Operator for calculation'"
    [showClear]="false"
    [items]="operatorOptions"
    (onChange)="onCalculationChange.emit()">
  </yc-typeahead-select>

  <!-- this number -->
  <yc-input
    name="constant"
    [isNumber]="true"
    [srOnlyLabel]="true"
    [disabled]="isViewOnly"
    [label]="'common:textNumericValueForCalculation' | ycI18n: 'Numeric value for calculation'"
    class="me-2 flex-basis-20"
    [maxLength]="3"
    [min]="1"
    (onChange)="onCalculationChange.emit()">
  </yc-input>

  <!-- of days, weeks, years -->
  <yc-typeahead-select
    name="constantUnits"
    [srOnlyLabel]="true"
    [disabled]="isViewOnly"
    [label]="'common:textUnitsForCalculation' | ycI18n: 'Units for calculation'"
    [showClear]="false"
    [items]="constantUnitOptions"
    (onChange)="onCalculationChange.emit()">
  </yc-typeahead-select>
</ng-container>