import { Injectable } from '@angular/core';
import { TimeZoneState } from '@core/states/time-zone.state';
import timeZones from '@core/static-assets/time-zones';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';

@AttachYCState(TimeZoneState)
@Injectable({ providedIn: 'root'})
export class TimeZoneService extends BaseYCService<TimeZoneState> {

  get timeZones () {
    return this.get('timeZones');
  }

  setTimeZones () {
    if (!this.timeZones) {
      this.set('timeZones', timeZones);
    }
  }

  getTimeZones () {
    return this.get('timeZones');
  }

  returnTimeZoneFromID (timezoneId: string) {
    return this.timeZones.find((tz) => {
      return (tz.id === timezoneId);
    }) || {
      id: 'UTC',
      displayName: 'UTC',
      offset: 0
    };
  }

  getTimezoneOptions () {
    return this.timeZones.map((zone) => {
      return {
        label: zone.displayName,
        value: zone.id
      };
    });
  }
}
