import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { FilterModalTypes, FilterOption, FilterOptions, FilterTypes, OneOfFilterOption, TypeToken } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { LogicBuilderService } from '../logic-builder.service';
import { ConditionalLogicResultType, LogicColumn, LogicColumnDisplay, LogicCondition, RelatedLogicValueCondition, RelativeDateCalculationConfig, ValueLogicCondition } from '../logic-builder.typing';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { TypeSafeFormBuilder, TypeSafeFormGroup, TypeaheadSelectOption } from '@yourcause/common/core-forms';
export type ConditionalLogicGroup<T> = RelatedLogicValueCondition<T, LogicColumn<T>, LogicColumn<T>>&{
  resultType: ConditionalLogicResultType;
  applyCalculation: boolean;
};

@Component({
  selector: 'gc-conditional-logic-builder-condition',
  templateUrl: './conditional-logic-builder-condition.component.html',
  styleUrls: ['./conditional-logic-builder-condition.component.scss']
})
export class ConditionalLogicBuilderConditionComponent<T> implements OnInit, OnDestroy {
  @Input() condition: LogicCondition<T, LogicColumn<T>>;
  @Input() availableColumns: LogicColumnDisplay<T>[];
  @Input() isViewOnly = false;
  @Output() conditionChange = new EventEmitter<LogicCondition<T, LogicColumn<T>>>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() removeCondition = new EventEmitter();

  formGroup: TypeSafeFormGroup<ConditionalLogicGroup<T>>;
  formSub: Subscription;
  columnOptions: TypeaheadSelectOption<LogicColumn<T>>[];
  comparisonOptions: TypeaheadSelectOption<FilterModalTypes>[];
  sourceColumnDisplay: LogicColumnDisplay<T>;
  filterOptions: TypeaheadSelectOption[];
  filter: FilterOption;
  resultTypeOptions: TypeaheadSelectOption[] = [];
  $filterOptionsType = new TypeToken<FilterTypes>();
  ConditionalLogicResultType = ConditionalLogicResultType;
  otherComponentOptions: TypeaheadSelectOption[] = [];
  calculationOptionsFormGroup: TypeSafeFormGroup<RelativeDateCalculationConfig>;
  showDateCalculationCheckbox = false;
  sourceColumnIsDate = false;

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private logicBuilderService: LogicBuilderService
  ) { }

  get apiComparison () {
    return this.formGroup?.value.comparison;
  }

  get sourceColumn () {
    return this.formGroup?.value.sourceColumn;
  }

  get value () {
    return this.formGroup?.value.value;
  }

  get relatedColumn () {
    return this.formGroup?.value.relatedColumn;
  }

  get resultType () {
    return this.formGroup?.value.resultType;
  }

  get enableDateCalculations () {
    return this.formGroup?.value.applyCalculation;
  }

  ngOnInit () {
    this.setSourceColumnDisplay(this.condition.sourceColumn);
    this.setFormGroup();
    this.setFilterOptions(this.condition.sourceColumn);
    this.setSourceColumnIsDate();
    this.setResultTypeOptions();
    this.setFilterOption(true);
    this.setColumnOptions();
  }

  getInitialValue (resultType: ConditionalLogicResultType) {
    if (resultType === ConditionalLogicResultType.STATIC_VALUE) {
      let value = (this.condition as ValueLogicCondition<T, LogicColumn<T>>).value;

      const applicableOptions = this.getApplicableOptions();

      if (
        applicableOptions.includes(OneOfFilterOption) &&
        this.condition.comparison === FilterModalTypes.equals &&
        !(value instanceof Array)
      ) {
        value = [value] as any;
      }

      return value;
    }

    return null;
  }

  private setFormGroup () {
    const {
      applyCalculation,
      resultType,
      relatedColumn
    }  = this.logicBuilderService.getConditionHelpers(this.condition);

    this.formGroup = this.formBuilder.group<ConditionalLogicGroup<T>>({
      sourceColumn: [this.condition.sourceColumn, Validators.required],
      comparison: [this.condition.comparison as any, Validators.required],
      value: [this.getInitialValue(resultType)],
      useAnd: [this.condition.useAnd],
      resultType,
      relatedColumn: [relatedColumn],
      identifier: this.condition.identifier,
      applyCalculation
    }, {
      validator: [
        this.valueRequiredValidator()
      ]
    });
    this.calculationOptionsFormGroup = this.logicBuilderService.getRelativeDateCalculationsFormGroup(
      this.condition.relativeDateCalcConfig
    );
    this.emitValidity();
    this.formSub = this.formGroup.valueChanges.subscribe(() => {
      this.emitCondition();
    });
  }

  emitCondition () {
    const formVal = this.formGroup.value;
    let value = formVal.value;
    let relatedColumn: LogicColumn<T> = null;
    if (formVal.resultType === ConditionalLogicResultType.OTHER_COLUMN) {
      value = null;
      relatedColumn = this.formGroup.value.relatedColumn;
    }
    const updatedCondition: LogicCondition<T, LogicColumn<T>> = {
      sourceColumn: formVal.sourceColumn,
      comparison: formVal.comparison,
      useAnd: formVal.useAnd,
      identifier: formVal.identifier,
      value,
      relatedColumn,
      resultType: formVal.resultType,
      relativeDateCalcConfig: this.getRelativeDateConfig()
    };
    this.conditionChange.emit(updatedCondition);
    this.emitValidity();
  }

  getRelativeDateConfig (): RelativeDateCalculationConfig {
    if (this.enableDateCalculations) {
      return this.calculationOptionsFormGroup.value;
    }

    return null;
  }

  emitValidity () {
    let valid = this.formGroup.valid;
    if (this.enableDateCalculations) {
      valid = valid && this.calculationOptionsFormGroup.valid;
    }
    this.validChange.emit(valid);
  }

  setSourceColumnIsDate () {
    let sourceIsDate = false;
    if (!!this.sourceColumn) {
      const foundCol = this.availableColumns.find((col) => {
        return isEqual(col.column, this.sourceColumn);
      });
      if (!!foundCol) {
        sourceIsDate = foundCol.type === 'date';
      }
    }

    this.sourceColumnIsDate = sourceIsDate;

    // If the source column is not date and calculations are on, turn it off
    if (!this.sourceColumnIsDate && this.enableDateCalculations) {
      this.formGroup.get('applyCalculation').setValue(false);
    }
  }

  setResultTypeOptions () {
    this.resultTypeOptions = this.logicBuilderService.getResultTypeOptions(this.sourceColumnIsDate);
  }

  setFilterOption (isInit: boolean) {
    if (this.sourceColumn && this.apiComparison) {
      const applicableOptions = this.getApplicableOptions();
      this.filter = applicableOptions.find((option) => {
        return option.api === this.apiComparison;
      });
      const shouldShowOtherColumnSelect = this.logicBuilderService.shouldShowOtherColumnSelector(
        this.otherComponentOptions || [],
        this.apiComparison
      );
      if (
        !shouldShowOtherColumnSelect &&
        this.resultType === ConditionalLogicResultType.OTHER_COLUMN
      ) {
        this.formGroup.get('resultType').setValue(ConditionalLogicResultType.STATIC_VALUE);
      }
    } else {
      this.filter = null;
    }
    /* If the filter changes and this is not init, clear value */
    if (!isInit) {
      this.clearValue();
    }
  }

  onColumnChange () {
    this.setSourceColumnIsDate();
    this.setResultTypeOptions();
    this.setSourceColumnDisplay(this.formGroup.value.sourceColumn);
    this.setFilterOptions(this.formGroup.value.sourceColumn);
    this.setComparisonOptions();
    /* Anytime the column changes, we should clear filter and value */
    this.clearFilter();
    this.clearValue();
  }

  handleResultTypeChange (resultType: ConditionalLogicResultType) {
    if (resultType === ConditionalLogicResultType.OTHER_COLUMN) {
      this.formGroup.get('relatedColumn').setValue(this.otherComponentOptions[0].value);
    } else if (
      resultType === ConditionalLogicResultType.STATIC_VALUE ||
      resultType === ConditionalLogicResultType.RELATIVE_DATE
    ) {
      this.clearValue();
    }
  }

  handleDeleteClick () {
    this.removeCondition.emit();
  }

  private clearFilter () {
    this.formGroup.get('comparison').setValue(null);
  }

  private clearValue () {
    this.formGroup.get('value').setValue(null);
  }

  private setSourceColumnDisplay (sourceColumn: LogicColumn<T>) {
    this.sourceColumnDisplay = this.availableColumns.find(column => {
      return isEqual(column.column, sourceColumn);
    });
  }

  private setFilterOptions (sourceColumn: LogicColumn<T>) {
    if (this.sourceColumnDisplay) {
      const foundCurrentCol = this.availableColumns.find((col) => {
        return isEqual(col.column, sourceColumn);
      });
      this.otherComponentOptions = foundCurrentCol?.otherColumnOptions ?? [];
      if ('filterOptions' in this.sourceColumnDisplay) {
        const options = this.sourceColumnDisplay.filterOptions;
        const hiddenOptions: string[] = options.filter((opt) => opt.hidden).map((opt) => opt.value);
        this.filterOptions = options.filter((opt) => !opt.hidden);
        const missingValuesInOptions: string[] = [];
        if (this.value instanceof Array) {
          this.value.forEach((val) => {
            if (hiddenOptions.includes(val)) {
              missingValuesInOptions.push(val);
            }
          });
        } else {
          if (hiddenOptions.includes(this.value as string)) {
            missingValuesInOptions.push(this.value as string);
          }
        }
        if (missingValuesInOptions.length > 0) {
          this.filterOptions = options.filter((opt) => {
            if (opt.hidden) {
              return missingValuesInOptions.includes(opt.value);
            }

            return true;
          }).map((opt) => {
            return {
              ...opt,
              hidden: false
            };
          });
        }
      }
    } else {
      this.otherComponentOptions = [];
    }
  }

  private setColumnOptions () {
    this.columnOptions = this.arrayHelper.sort(
      this.availableColumns.map((availableColumn) => {
        return {
          value: availableColumn.column,
          label: availableColumn.label
        };
      }), 'label');
    this.setComparisonOptions();
  }

  private setComparisonOptions () {
    const applicableOptions = this.getApplicableOptions()
      .map((type) => {
        return {
          value: type.api,
          label: this.i18n.translate(type.displayKey, {}, type.display)
        };
      });
    this.comparisonOptions = this.arrayHelper.sort(applicableOptions, 'label');
  }

  private getApplicableOptions () {
    return FilterOptions.filter(type => {
      return type.types.includes(this.sourceColumnDisplay?.type);
    });
  }

  valueRequiredValidator () {
    return (group: AbstractControl) => {
      const value = group.value.value;
      const resultType = group.value.resultType;
      const hideValueColumn = !!this.filter ? this.filter.hideValueColumn : false;

      if (
        !hideValueColumn &&
        resultType === ConditionalLogicResultType.STATIC_VALUE
      ) {
        const hasValue = !!value || value === 0;
        if (!hasValue) {
          return {
            value: {
              required: {
                i18nKey: 'common:textThisInputIsRequired',
                defaultValue: 'This input is required'
              }
            }
          };
        }
     }

      return null;
    };
  }

  ngOnDestroy () {
    this.formSub.unsubscribe();
  }
}
