import { Component, Input, OnInit } from '@angular/core';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { YCModalComponent } from '@yourcause/common/modals';
import { cloneDeep } from 'lodash';
import { FormulaBuilderService } from '../formula-builder.service';
import { RootFormula } from '../formula-builder.typing';

@Component({
  selector: 'gc-formula-builder-modal',
  templateUrl: './formula-builder-modal.component.html',
  styleUrls: ['./formula-builder-modal.component.scss']
})
export class FormulaBuilderModalComponent<T> extends YCModalComponent<RootFormula<T>> implements OnInit {
  @Input() columns: TypeaheadSelectOption<string>[] = [];
  @Input() formula: RootFormula<T>;
  @Input() rootProperty: string;
  @Input() otherFormulas: RootFormula<T>[];
  @Input() isViewOnly = false;

  formulaToOutput: RootFormula<T>;
  valid = false;

  constructor (
    private formulaBuilderService: FormulaBuilderService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit () {
    const formula = this.formula?.step ? this.formula : this.formulaBuilderService.getDefaultRootFormula<T>(this.rootProperty);
    this.formula = cloneDeep(formula);
  }

  clear () {
    this.closeModal.emit({
      ...this.formulaToOutput,
      step: null
    });
  }

  handlePrimaryClick () {
    this.closeModal.emit(this.formulaToOutput);
    this.analyticsService.emitEvent({
      eventName: 'Formula builder modal submit',
      eventType: EventType.Click,
      extras: null
    });
  }
}
