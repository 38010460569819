
import { Injectable } from '@angular/core';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { FormDefinitionForUi } from '@features/configure-forms/form.typing';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { PolicyService } from './policy.service';

@Injectable({ providedIn: 'root' })
export class FormMaskingService {

  constructor (
    private policyService: PolicyService,
    private formFieldHelperService: FormFieldHelperService,
    private componentHelper: ComponentHelperService
  ) { }

  checkShouldShowMaskToggleForAdHoc (
    columns: AdHocReportingUI.ColumnImplementation[]
  ) {
    const applicable = this.checkIfAnyColumnIsMasked(columns);

    return {
      shouldShow: this.policyService.grantApplication.canSeeMaskedApplicants() && applicable,
      defaultSetting: applicable
    };
  }

  checkShouldShowMaskToggleForFormView (formDefinition: FormDefinitionForUi[]) {
    const applicable = this.checkIfFormHasMaskedField(formDefinition);

    return {
      shouldShow: this.policyService.grantApplication.canSeeMaskedApplicants() && applicable,
      defaultSetting: applicable
    };
  }

  // default to unmasked (false) for users who have the right permission
  getDefaultMaskSetting () {
    return !this.policyService.grantApplication.canSeeMaskedApplicants();
  }

  get formFieldMask () {
    return '******';
  }

  private checkIfAnyColumnIsMasked (
    columns: AdHocReportingUI.ColumnImplementation[]
  ) {
    return columns.some(column => {
      if (column.definition.isReferenceField) {
        const refFieldKey = column.definition.column.split('.')[0];

        const field = this.formFieldHelperService.getReferenceFieldByKey(refFieldKey);

        return field.isMasked;
      }

      return false;
    });
  }

  private checkIfFormHasMaskedField (form: FormDefinitionForUi[]) {
    let hasMasked = false;
    form.forEach((tab) => {
      this.componentHelper.eachComponent(tab.components, (component) => {
        const key = this.componentHelper.getRefFieldKeyFromCompType(component.type);
        const field = this.formFieldHelperService.getReferenceFieldByKey(key);

        if (field?.isMasked) {
          hasMasked = true;
        }
      });
    });

    return hasMasked;
  }
}
