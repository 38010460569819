export const Audience_Table_Key = 'AUDIENCES';

export interface Audience {
  name: string;
  id: number;
  description: string;
  totalMembers: number;
  createdDate: string;
  createdBy: string;
  createImpersonatedBy: string;
  updatedDate: string;
  updatedBy: string;
  impersonatedBy: string;
  canDelete: boolean;
}

export interface AudienceDetail extends Audience {
  members: AudienceMember[];
  usage: AudienceUsage;
}

export interface AudiencePermissionAttrs {
  canManage?: boolean;
  hasPassthrough?: boolean;
}

export interface AudienceMember extends AudiencePermissionAttrs {
  id: number;
  name: string;
  email: string;
  external?: boolean;
  isAudienceMember?: boolean;
  audienceNames?: string;
  audienceIds?: number[];
}

export interface AudienceWithPermission extends AudiencePermissionAttrs {
  audienceId: number;
}

export interface AudienceModalResponse {
  id: number;
  name: string;
  description: string;
  members: AudienceMember[];
}

export interface WorkflowTiedToAudience {
  workflowLevelId: number;
  workflowLevelName: string;
  workflowId: number;
  workflowName: string;
}

export interface ScheduledReportTiedToAudience {
  reportName: string;
  reportId: number;
}

export interface AudienceUsage {
  workflowLevels: WorkflowTiedToAudience[];
  scheduledReports: ScheduledReportTiedToAudience[];
  accountsPayableSettings: {
    accountsPayableSettingsId: number;
  }[];
}

export enum UsageType {
  Workflow = 1,
  Reports = 2,
  ApConfig =  3
}

export interface AudienceUsageForUi {
  usageName: string;
  usageSubName?: string;
  usageType: UsageType;
  usageTypeTranslated: string;
}
