import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { EmployeeSSOField, EmployeeSSOFieldBulkFromApi, EmployeeSSOFieldFromApi, EmployeeSSOFieldsData } from './employee-sso-fields.typing';

@Injectable({ providedIn: 'root' })
export class EmployeeSSOFieldsResources {

  constructor (
    private httpRestService: HttpRestService,
    private portal: PortalDeterminationService
  ) { }

  getEmployeeSSOFields (): Promise<EmployeeSSOFieldFromApi[]> {
    const endpoint = 'api/client/ssocolumns';

    return this.httpRestService.get(endpoint);
  }

  getEmployeeSSOFieldsForApp (appId: number): Promise<EmployeeSSOFieldsData> {
    const endpoint = this.portal.isApply ?
      `api/portal/applications/${appId}/SSOFields` :
      `api/manager/applications/${appId}/SSOFields`;

    return this.httpRestService.get(endpoint);
  }

  getEmployeeSSOFieldsBulk (
    applicationIds: number[]
  ): Promise<EmployeeSSOFieldBulkFromApi[]> {
    const endpoint = 'api/manager/applications/GetBulkSSOFields';

    return this.httpRestService.post(endpoint, {
      applicationIds
    });
  }

  updateEmployeeSSOFields (columns: EmployeeSSOField[]) {
    const endpoint = 'api/client/ssocolumns/update';

    return this.httpRestService.post(endpoint, {
      columns
    });
  }

  getEmployeeSSOFieldData (): Promise<EmployeeSSOFieldsData[]> {
    const endpoint = 'api/client/ssocolumns/data';

    return this.httpRestService.get(endpoint);
  }

}

