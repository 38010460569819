import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentTabSettingWithValue } from '../component-configuration.typing';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';
@Component({
  selector: 'gc-custom-javascript-settings',
  templateUrl: './custom-javascript-settings.component.html',
  styleUrls: ['./custom-javascript-settings.component.scss']
})
export class CustomJavascriptSettingsComponent extends BaseComponentConfigSettingsComponent {
  @Input() component: ComponentTabSettingWithValue;
  @Output() onChange = new EventEmitter<string>();
  isCollapsed: boolean;
}

