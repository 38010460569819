import { Injectable } from '@angular/core';
import { Applicant, ApplicantAdminUser, ApplicantExistsResponse, ApplicantForApi, ApplicantFromSearch, CreateApplicantResponse, LinkSSOApplicantPayload, SearchApplicantsPayload, UpdateApplicant, UpdateProfile } from '@core/typings/applicant.typing';
import { ApplicantOrganizationFromApi } from '@core/typings/organization.typing';
import { PaginationOptions } from '@yourcause/common';
import { HttpRestService } from '../services/http-rest.service';
import { PortalDeterminationService } from '../services/portal-determination.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicantResources {
  constructor (
    private determinationService: PortalDeterminationService,
    private httpRestService: HttpRestService
  ) { }

  getApplicant (): Promise<Applicant> {
    return this.httpRestService.get<Applicant>('api/portal/applicant');
  }

  linkHRData (linkHRDataPayload: LinkSSOApplicantPayload) {
    return this.httpRestService.post('api/manager/applications/LinkSsoApplicant', linkHRDataPayload);
  }

  createApplicant (applicant: ApplicantForApi): Promise<CreateApplicantResponse> {
    const endpoint = `api/${
      this.determinationService.isManager ? 'manager' : 'portal'
    }/applications/applicant`;

    return this.httpRestService.post(endpoint, applicant);
  }

  searchApplicants (payload: SearchApplicantsPayload): Promise<ApplicantFromSearch[]> {
    return this.httpRestService.post(`api/manager/applications/applicantDetail`, payload);
  }

  searchApplicantsFromPortal (email = ''): Promise<ApplicantFromSearch> {
    const encodedEmail = encodeURIComponent(email);

    return this.httpRestService.get(`api/portal/applications/applicant?email=${encodedEmail}`);
  }

  updateProfile (data: UpdateProfile) {
    return this.httpRestService.post('api/portal/applicant/updateprofile', data);
  }

  getApplicantOrganizations (): Promise<ApplicantOrganizationFromApi[]> {
    return this.httpRestService.get(`api/portal/applicant/organizations`);
  }

  deactivateAccount () {
    const endpoint = 'api/portal/applicant/DeactivateAccount';

    return this.httpRestService.post(endpoint, { clearPersonalData: true });
  }

  doesApplicantExist (
    email: string,
    grantProgramGuid: string
  ): Promise<ApplicantExistsResponse> {
    const endpoint = 'api/portal/applicant/exists';

    return this.httpRestService.post(endpoint, {
      email,
      grantProgramGuid
    });
  }

  lookupEmployee (externalEmployeeId: string, applicationId: number) {
    const endpoint = 'api/manager/applications/findSsoApplicant';

    return this.httpRestService.post(endpoint, {
      externalEmployeeId,
      applicationId
    });
  }

  adminApplicantsPaginated (
    paginationOptions: PaginationOptions<ApplicantAdminUser>,
    clientIds: number[] = []
  ) {
    const endpoint = 'api/admin/applicant/paginated';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      clientIds
    });
  }

  updateApplicant (
    applicant: UpdateApplicant
  ) {
    const endpoint = `api/admin/applicant/edit`;

    return this.httpRestService.post(endpoint, applicant);
  }
}
