import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormDefinitionComponent, FormValueChange } from '@features/configure-forms/form.typing';
import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
interface ContentGroup {
  [x: string]: string;
}

@Component({
  selector: 'gc-form-content',
  templateUrl: './form-content.component.html',
  styleUrls: ['./form-content.component.scss']
})
export class FormContentComponent implements OnInit {
  @Input() html: string;
  @Input() disabled: boolean;
  @Input() isContentEditMode: boolean;
  @Input() isFormBuilderView: boolean;
  @Input() formComponent: FormDefinitionComponent;
  @Output() onValueChange = new EventEmitter<FormValueChange>();

  formGroup: TypeSafeFormGroup<ContentGroup>;

  constructor (
    private formBuilder: TypeSafeFormBuilder
  ) { }

  get comp () {
    return this.formComponent;
  }

  ngOnInit () {
    if (this.comp) {
      this.comp.validate.required = false;
      this.html = this.replaceRgb();
      this.html = this.replaceHttp();
      this.formGroup = this.formBuilder.group({
        [this.comp.key]: this.html
      });
    }
  }

  replaceHttp () {
    return this.html.replace(new RegExp('http://', 'g'), 'https://');
  }

  replaceRgb () {
    return (this.html || '').replace(/\srgb\(([0-9]{1,3}(,\ )?){3}\)/g, (match) => {
      const numbers = match.match(/[0-9]{1,3}/g);

      return this.getHexFromRgb(
        +numbers[0],
        +numbers[1],
        +numbers[2]
      );
    });
  }

  getHex (num: number) {
    let hex = Number(num).toString(16);
    if (hex.length < 2) {
      hex = '0' + hex;
    }

    return hex;
  }

  getHexFromRgb (red: number, green: number, blue: number) {
    return `#${this.getHex(red)}${this.getHex(green)}${this.getHex(blue)}`;
  }

  onChange (value: string) {
    this.html = value;
    this.comp.html = value;
    this.onValueChange.emit({
      value,
      updateFormGroup: false
    });
  }
}
