import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PolicyService } from '@core/services/policy.service';
import { StaticResourceService } from '@core/services/static-resource.service';
import { StatusService } from '@core/services/status.service';
import { TimeZoneService } from '@core/services/time-zone.service';
import { TranslationService } from '@core/services/translation.service';
import { CyclesAPI } from '@core/typings/api/cycles.typing';
import { TimeZone } from '@core/typings/api/time-zone.typing';
import { Budget, FundingSourceTypes } from '@core/typings/budget.typing';
import { ProcessingTypes } from '@core/typings/payment.typing';
import { ArchiveProgramPayload, CanOrgSubmitMultipleAppsOptions, ConfigureProgram, ConfigureProgramMap, CreateEditProgramApi, CreateProgramModalResponse, GrantProgramCycleBudgetFundingSource, MasterResponse, OfflineProgramDetail, PaymentStats, PaymentStatusStat, Program, ProgramApplicantType, ProgramChange, ProgramChangeTypes, ProgramCyclesPayload, ProgramDashboardMap, ProgramDetail, ProgramDetailFromApi, ProgramDropdownOptionsForInsights, ProgramExport, ProgramForDashboard, ProgramFormPayload, ProgramImport, ProgramStatusStat, ProgramStatusStatApi, ProgramTypes, ProgramWorkflowLevelFormPayload, SimpleProgramCycle, WorkflowFormSortUpdate } from '@core/typings/program.typing';
import { ApplicationStatuses, PaymentStatus } from '@core/typings/status.typing';
import { CyclesUI } from '@core/typings/ui/cycles.typing';
import { environment } from '@environment';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormTypes, WorkflowLevelFormApi } from '@features/configure-forms/form.typing';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { CyclesService } from '@features/cycles/cycles.service';
import { CycleEmitDetail } from '@features/cycles/program-cycles/program-cycles.component';
import { FormFieldService } from '@features/form-fields/services/form-field.service';
import { FormLogicService } from '@features/forms/services/form-logic/form-logic.service';
import { InKindService } from '@features/in-kind/in-kind.service';
import { InvitationApplicantHelpers } from '@features/invitations/invitation.typing';
import { EmailService } from '@features/system-emails/email.service';
import { UserService } from '@features/users/user.service';
import { WorkflowService } from '@features/workflow/workflow.service';
import { Base64, SimpleStringMap, TableRepositoryFactory } from '@yourcause/common';
import { SelectOption, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { DateService, TIMESTAMP_FORMAT } from '@yourcause/common/date';
import { FileService, FileTypeService } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { isEqual, uniqBy } from 'lodash';
import { Subscription } from 'rxjs';
import { ProgramResources } from '../program.resources';
import { ProgramState } from '../program.state';

@AttachYCState(ProgramState)
@Injectable({ providedIn: 'root' })
export class ProgramService extends BaseYCService<ProgramState> {
  sub = new Subscription();
  programTypes = [{
    label: this.i18n.translate(
      'FORMS:textNomination',
      {},
      'Nomination'
    ),
    value: ProgramTypes.NOMINATION
  }, {
    label: this.i18n.translate(
      'GLOBAL:textGrant',
      {},
      'Grant'
    ),
    value: ProgramTypes.GRANT
  }];

   constructor (
    private logger: LogService,
    private i18n: I18nService,
    private userService: UserService,
    private clientSettingsService: ClientSettingsService,
    private workflowService: WorkflowService,
    private programResources: ProgramResources,
    private formService: FormsService,
    private fileService: FileService,
    private staticResourceService: StaticResourceService,
    private statusService: StatusService,
    private arrayHelper: ArrayHelpersService,
    private translationService: TranslationService,
    private notifier: NotifierService,
    private TableRepository: TableRepositoryFactory,
    private inKindService: InKindService,
    private formFieldService: FormFieldService,
    private cyclesService: CyclesService,
    private timeZoneService: TimeZoneService,
    private emailService: EmailService,
    private policyService: PolicyService,
    private formLogicService: FormLogicService,
    private dateService: DateService,
    private fileTypeService: FileTypeService,
    private confirmAndTakeAction: ConfirmAndTakeActionService
  ) {
    super();

    this.sub.add(this.translationService.changesTo$('viewTranslations').subscribe(() => {
      this.resetProgramTranslations();
    }));
  }

  get configureProgramMap () {
    return this.get('configureProgramMap');
  }

  get programMap () {
    return this.get('programMap');
  }

  get applicantProgramMap () {
    return this.get('applicantProgramMap');
  }

  get activeProgramId () {
    return this.get('activeProgramId');
  }

  get program () {
    return this.configureProgramMap[this.activeProgramId];
  }

  get allActiveManagerPrograms () {
    return this.get('allActiveManagerPrograms');
  }

  get allActiveManagerProgramOptions () {
    return this.get('allActiveManagerProgramOptions');
  }

  get allManagerPrograms () {
    return this.get('allManagerPrograms');
  }

  get allPublishedPrograms () {
    return this.get('allPublishedPrograms');
  }

  get allPublishedActivePrograms () {
    return this.get('allPublishedActivePrograms');
  }

  get allPrograms () {
    return this.get('allPrograms');
  }

  get dashboardPrograms () {
    return this.get('dashboardPrograms');
  }

  get nonMaskedDashboardPrograms () {
    return this.get('nonMaskedDashboardPrograms');
  }

  get ycProcessingPrograms () {
    return this.get('ycProcessingPrograms');
  }

  get clientProcessingPrograms () {
    return this.get('clientProcessingPrograms');
  }

  get cycleBudgetsMap () {
    return this.get('cycleBudgetsMap');
  }

  get programCycleBudgetFundingSource () {
    return this.get('programCycleBudgetFundingSource');
  }

  get offlineProgramMap () {
    return this.get('offlineProgramMap');
  }

  get programDashboardMap () {
    return this.get('programDashboardMap');
  }

  get passMessage () {
    return this.i18n.translate(
      'PROGRAM:textDefaultEligibilitySuccess',
      {},
      'Congratulations! You have passed eligibility and can now proceed with your application.'
    );
  }

  get failMessage () {
    return this.i18n.translate(
      'APPLY:textEligibilityNonSuccessDefaultMessage',
      {},
      'Unfortunately you have not met the eligibility qualifications. If you have any questions, feel free to reach out to your Grant Manager.'
    );
  }

  get programTranslationMap () {
    return this.translationService.viewTranslations.Grant_Program;
  }

  get currentProgramDashCycleIds () {
    return this.get('currentProgramDashCycleIds') || [];
  }

  get programChanges () {
    return this.get('programChanges');
  }

  private isLocalhost () {
    return environment.isLocalhost;
  }

  getProgramsTableKey (isNomination: boolean) {
    return isNomination ? 'NOMINATION_PROGRAMS' : 'GRANT_PROGRAMS';
  }

  setProgramChanges (changes: ProgramChange[]) {
    this.set('programChanges', changes);
  }

  async getProgramCycleBudgets (
    programId: number
  ): Promise<GrantProgramCycleBudgetFundingSource[]> {
    const budgetMap = this.get('programCycleBudgetFundingSource');

    if (budgetMap[programId]) {
      return budgetMap[programId];
    }

    const result = await this.programResources.getProgramCycleBudgetsAndFundingSources(programId);

    this.set('programCycleBudgetFundingSource', {
      ...this.programCycleBudgetFundingSource,
      [programId]: result
    });

    return result;
  }

  resetProgramCycleBudgetMap () {
    this.set('programCycleBudgetFundingSource', {});
  }

  setProgramDashCycleIdsMap (cycleIds: number[]) {
    this.set('currentProgramDashCycleIds', cycleIds);
  }

  setConfigureProgramMap (map: ConfigureProgramMap) {
    this.set('configureProgramMap', map);
  }

  setProgramDashboardMap (map: ProgramDashboardMap) {
    this.set('programDashboardMap', map);
  }

  setAllActiveManagerPrograms (active: Program[]) {
    const programs = this.arrayHelper.sort(active, 'grantProgramName');
    this.set('allActiveManagerPrograms', programs);
    const options = programs.map((prog) => {
      return {
        label: prog.grantProgramName,
        value: prog.grantProgramId
      };
    });
    this.set('allActiveManagerProgramOptions', options);
  }

  setAllManagerPrograms (progs: Program[]) {
    this.set(
      'allManagerPrograms',
      this.arrayHelper.sort(progs, 'grantProgramName')
    );
  }

  setActiveProgramId (id: string) {
    this.set('activeProgramId', id);
  }


  setOfflineProgramMap (map: {
    [p: string]: OfflineProgramDetail;
  }) {
    this.set('offlineProgramMap', map);
  }

  setMapProperty<K extends keyof ConfigureProgram> (
    id: keyof ConfigureProgramMap,
    prop: K,
    value: ConfigureProgram[K],
    skipProgramChange = false
  ) {
    const current = this.configureProgramMap[id];
    const oldVal = current[prop];
    if (!isEqual(oldVal, value)) {
      this.set('configureProgramMap', {
        ...this.configureProgramMap,
        [id]: {
          ...current,
          [prop]: value
        }
      });
      if (!skipProgramChange) {
        this.emitProgramChange(prop);
      }
    }
  }

  /**
   * When the configure program map changes, we emit the program changes
   *
   * @param prop: prop we are changing
   */
  emitProgramChange<K extends keyof ConfigureProgram> (
    prop: K
  ) {
    const changeType = this.getProgramChangeType(prop);
    // Workflow Form Changes are handled separately
    if (changeType !== ProgramChangeTypes.WorkflowForm) {
      const change: ProgramChange = {
        changeType,
        prop
      };
      const changes = [
        ...(this.programChanges || []),
        change
      ];
      this.setProgramChanges(changes);
    }
  }

  /**
   * Get the program change type by prop
   *
   * @param prop: prop that is changing
   * @returns the program change type
   */
  getProgramChangeType (
    prop: keyof ConfigureProgram
  ): ProgramChangeTypes {
    if (this.isCycleChange(prop)) {
      return ProgramChangeTypes.Cycle;
    } else if (this.isFormChange(prop)) {
      return ProgramChangeTypes.ProgramForm;
    } else if (this.isWorkflowFormChange(prop)) {
      return ProgramChangeTypes.WorkflowForm;
    } else if (this.isAllowedOrgLocationsChange(prop)) {
      return ProgramChangeTypes.AllowedOrgLocations;
    }

    return ProgramChangeTypes.Standard;
  }

  /**
   * Is the change an Allowed Org Locations change?
   *
   * @param prop: prop that is changing
   * @returns if the change is an allowed org locations change
   */
  isAllowedOrgLocationsChange (prop: keyof ConfigureProgram) {
    return prop === 'allowedOrgLocations';
  }


  /**
   * Is the change a Workflow Form Change?
   *
   * @param prop: prop that is changing
   * @returns if the change is a workflow form change
   */
  isWorkflowFormChange (prop: keyof ConfigureProgram) {
    return prop === 'workflowFormMap';
  }

  /**
   * Is the change a Cycle Change?
   *
   * @param prop: prop that is changing
   * @returns whether the change is a cycle change
   */
  isCycleChange (prop: keyof ConfigureProgram) {
    return prop === 'cycles';
  }

  /**
   * Is the change a Form Change?
   *
   * @param prop: prop that is changing
   * @returns whether the change is a form change
   */
  isFormChange (prop: keyof ConfigureProgram) {
    return [
      'masterResponse',
      'eligibilityForm',
      'successMessage',
      'failMessage'
    ].includes(prop);
  }

  /**
   * Saves program changes from autosave
   *
   * @param changes: changes to save
   * @param isNomination: is this a nomination?
   * @param saveAsDraft: are we saving as draft?
   * @returns true if passed
   */
  async saveProgramChanges (
    changes: ProgramChange[],
    isNomination: boolean,
    saveAsDraft: boolean
  ): Promise<boolean> {
    try {
      const filtered = uniqBy(changes, 'prop');
      for (const change of filtered) {
        await this.handleProgramSaveByType(
          change,
          isNomination,
          saveAsDraft
        );
      }
      const filteredChanges = this.programChanges.filter((change) => {
        return !changes.includes(change);
      });
      this.setProgramChanges(filteredChanges);

      return true;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorSavingChanges',
        {},
        'There was an error saving the changes'
      ));

      return false;
    }
  }

  /**
   * Handles the program autosave by type
   *
   * @param change: change to save
   * @param isNomination: is this a nomination?
   * @param saveAsDraft: are we saving as draft?
   */
  async handleProgramSaveByType (
    change: ProgramChange,
    isNomination: boolean,
    saveAsDraft: boolean
  ) {
    const program = this.configureProgramMap[this.activeProgramId];
    switch (change.changeType) {
      case ProgramChangeTypes.Cycle:
        const programTimeZone = this.timeZoneService.returnTimeZoneFromID(program.timezoneId);
        const cyclePayload = this.getSaveProgramCyclePayload(isNomination, programTimeZone);
        const updatedCycles = await this.programResources.saveProgramCycles(cyclePayload);
        const isNewCycle = program.cycles.some((cycle) => !cycle.id);
        if (isNewCycle) {
          this.addIdToNewCycles(updatedCycles);
        }
        break;
      case ProgramChangeTypes.Standard:
        await this.programResources.saveProgram(
          await this.getSaveProgramStandardPayload(isNomination, saveAsDraft)
        );
        if (change.prop === 'defaultForm' || change.prop === 'workflow') {
          // Changes to the default form or worflow could result in the Workflow level forms changing
          const workflowForms = await this.workflowService.getWorkflowForms(+this.activeProgramId);
          const workflowFormMap = this.constructFormsForEdit(
            program.workflow,
            workflowForms,
            program.defaultForm
          );
          this.setMapProperty(
            this.activeProgramId,
            'workflowFormMap',
            workflowFormMap,
            true
          );
        }
        break;
      case ProgramChangeTypes.ProgramForm:
        await this.programResources.saveProgramFormSettings(
          this.getSaveProgramFormSettingsPayload()
        );
        break;
      case ProgramChangeTypes.WorkflowForm:
        if (!!change.workflowFormAddOrUpdate) {
          const isNew = !change.workflowFormAddOrUpdate.id;
          const id = await this.programResources.saveProgramWorkflowLevelForm(
            this.getSaveProgramWorkflowLevelFormPayload(isNomination, change.workflowFormAddOrUpdate)
          );
          if (isNew && !!id) {
            const levelId = change.workflowFormAddOrUpdate.workflowLevelId;
            this.addIdToNewWorkflowLevelForm(levelId, id);
          }
        } else if (!!change.workflowLevelFormToRemove) {
          await this.programResources.deleteProgramWorkflowLevelForm(
            this.getSaveProgramWorkflowLevelFormPayload(isNomination, change.workflowLevelFormToRemove)
          );
        }
        break;
      case ProgramChangeTypes.AllowedOrgLocations:
        await this.programResources.saveProgramOrgLocations(+this.activeProgramId, program.allowedOrgLocations);
        break;
    }
  }

  addIdToNewCycles (updatedCycles: SimpleProgramCycle[]) {
    const program = this.configureProgramMap[this.activeProgramId];
    const cycleUpdates = updatedCycles.filter((cycle) => {
      const foundExistingCycle = program.cycles.find((existingCycle) => {
        return existingCycle.id === cycle.id;
      });

      return !foundExistingCycle;
    });
    // This will only ever be one cycle
    const cycleUpdate = cycleUpdates[0];

    const foundExistingIndex = program.cycles.findIndex((cycle) => !cycle.id);
    if (foundExistingIndex > -1) {
      const adaptedCycles = [
        ...program.cycles.slice(0, foundExistingIndex),
        {
          ...program.cycles[foundExistingIndex],
          id: cycleUpdate.id
        },
        ...program.cycles.slice(foundExistingIndex + 1)
      ];
      this.setMapProperty(
        this.activeProgramId,
        'cycles',
        adaptedCycles,
        true
      );
    }
  }

  /**
   * Finds the ID of the newly created workflow level form and gets it on the state
   *
   * @param levelId: Workflow Level ID the new form belongs to
   * @param newWorkflowLevelFormId: the ID of the new workflow level form
   */
  addIdToNewWorkflowLevelForm (
    levelId: number,
    newWorkflowLevelFormId: number
  ) {
    const program = this.configureProgramMap[this.activeProgramId];
    // Find the workflow form with the missing ID and update it
    const workflowForms = program.workflowFormMap[levelId];
    const foundIndex = workflowForms?.findIndex((form) => {
      return !form.id;
    });
    const current = program.workflowFormMap[levelId] || [];
    const workflowMap = {
      ...program.workflowFormMap,
      [levelId]: [
        ...current.slice(0, foundIndex),
        {
          ...current[foundIndex],
          id: newWorkflowLevelFormId
        },
        ...current.slice(foundIndex + 1)
      ]
    };
    this.setMapProperty(
      this.activeProgramId,
      'workflowFormMap',
      workflowMap,
      true
    );
  }

  /**
   * Gets the standard payload for program save
   *
   * @param isNomination: is this a nomination?
   * @param saveAsDraft: are we saving as draft?
   * @param program: program details
   * @returns the standard program save payload
   */
  getSaveProgramStandardPayload (
    isNomination: boolean,
    saveAsDraft: boolean,
    program = this.configureProgramMap[this.activeProgramId]
  ): Promise<CreateEditProgramApi> {
    return this.adaptForApi(
      program,
      isNomination,
      false,
      saveAsDraft,
      true
    );
  }

  /**
   * Gets the program cycle payload for save
   *
   * @param isNomination: is this a nomination?
   * @param program: program details
   * @returns the cycle save payload
   */
  getSaveProgramCyclePayload (
    isNomination: boolean,
    programTimeZone: TimeZone,
    program = this.configureProgramMap[this.activeProgramId]
  ): ProgramCyclesPayload {
    const programType = isNomination ? ProgramTypes.NOMINATION : ProgramTypes.GRANT;
    const grantProgramCycles = this.adaptCyclesForApi(
      program.cycles,
      isNomination,
      false,
      programTimeZone.offset,
      program.applicantType
    );

    return {
      grantProgramCycles,
      grantProgramId: program.id,
      grantProgramDefaultLanguageId: program.defaultLanguage,
      programType
    };
  }

  /**
   * Gets the program form settings payload
   *
   * @param program: program info
   * @returns the program form settings payload
   */
  getSaveProgramFormSettingsPayload (
    program = this.configureProgramMap[this.activeProgramId]
  ): ProgramFormPayload {

    return {
      additionalForms: program.eligibilityForm ?
        [program.eligibilityForm] : [],
      defaultFormId: program.defaultForm,
      grantProgramId: program.id,
      masterFormResponses: program.eligibilityForm && program.masterResponse ?
        [{
          formRevisionId: program.masterResponse.formRevisionId,
          masterResponse: program.masterResponse.masterResponse
        }] :
        [],
      eligibilityPassMessage: program.successMessage !== this.passMessage ?
        program.successMessage :
        '',
      eligibilityFailMessage: program.failMessage !== this.failMessage ?
        program.failMessage :
        '',
      defaultLanguageId: program.defaultLanguage
    };
  }

  /**
   * Gets the program workflow level form payload
   *
   * @param isNomination: is this a nomination?
   * @param change: change that was made
   * @param program: program info
   * @returns the program workflow level form payload
   */
  getSaveProgramWorkflowLevelFormPayload (
    isNomination: boolean,
    change: WorkflowLevelFormApi,
    program = this.configureProgramMap[this.activeProgramId]
  ): ProgramWorkflowLevelFormPayload {
    const grantProgramType = isNomination ? ProgramTypes.NOMINATION : ProgramTypes.GRANT;

    return {
      workflowId: program.workflow,
      grantProgramId: program.id,
      grantProgramType,
      grantProgramWorkflowLevelForm: change,
      defaultFormId: program.defaultForm
    };
  }

  /**
   * Handles Deleting the given cycle
   *
   * @param info: cycle info for delete
   */
  async handleDeleteCycle (info: CycleEmitDetail) {
    const { passed } = await this.cyclesService.handleDeleteCycle(info.id);

    if (passed) {
      const cycles = [
        ...this.program.cycles.slice(0, info.index),
        ...this.program.cycles.slice(info.index + 1)
      ];
      this.setMapProperty(
        this.activeProgramId,
        'cycles',
        cycles,
        true
      );
    }
  }

  /**
   * Handles archiving the given cycle
   *
   * @param info: Cycle info for archive
   */
  async handleArchiveCycle (info: CycleEmitDetail) {
    const { passed } = await this.cyclesService.handleArchiveCycle(info.id, info.comment);

    if (passed) {
      const cycles = [
        ...this.program.cycles.slice(0, info.index),
        {
          ...this.program.cycles[info.index],
          isArchived: true
        },
        ...this.program.cycles.slice(info.index + 1)
      ];
      this.setMapProperty(
        this.activeProgramId,
        'cycles',
        cycles,
        true
      );
    }
  }

  /**
   * Handles unarchiving the given cycle
   *
   * @param info: cycle info for unarchive
   */
  async handleUnarchiveCycle (info: CycleEmitDetail) {
    const { passed } = await this.cyclesService.handleUnarchiveCycle(info.id);

    if (passed) {
      const cycles = [
        ...this.program.cycles.slice(0, info.index),
        {
          ...this.program.cycles[info.index],
          isArchived: false
        },
        ...this.program.cycles.slice(info.index + 1)
      ];
      this.setMapProperty(
        this.activeProgramId,
        'cycles',
        cycles,
        true
      );
    }
  }

  async resetProgramAttrs () {
    await Promise.all([
      this.resetAllPrograms(),
      this.resetAllActiveManagerPrograms()
    ]);
  }

  resetProgramTranslations () {
    const resetAttrs = [
      'dashboardPrograms',
      'nonMaskedDashboardPrograms',
      'allActiveManagerPrograms',
      'allPublishedActivePrograms',
      'allPrograms',
      'allPublishedPrograms',
      'clientProcessingPrograms',
      'ycProcessingPrograms'
    ];
    resetAttrs.forEach((attr: any) => {
      const programs: Program[] = this.get(attr);
      if (programs) {
        this.updateProgramNamesWithTranslations(programs);
        this.set(attr, programs);
      }
    });
  }

  resetConfigureProgramMap () {
    this.setConfigureProgramMap({});
  }

  programExportAllowed (): boolean {
    return this.userService.user.isRootUser && (
      this.isLocalhost() ||
      environment.locationBase.includes('uat') ||
      environment.locationBase.includes('qa')
    );
  }

  programImportAllowed (): boolean {
    return this.userService.user.isRootUser && (
      this.isLocalhost() ||
      environment.locationBase.includes('qa') ||
      !environment.locationBase.includes('uat')
    );
  }

  async getProgram (
    grantProgramId: string|number
  ): Promise<ProgramDetailFromApi> {
    if (!this.programMap[grantProgramId]) {
      const program = await this.programResources.getProgramFromApi(
        grantProgramId
      );
      this.set('programMap', {
        ...this.programMap,
        [grantProgramId]: program
      });
    }

    return this.programMap[grantProgramId];
  }

  resetProgramMap (id: number) {
    this.set('programMap', {
      ...this.programMap,
      [id]: undefined
    });
  }

  async getOfflineProgramDetail (
    programId: number
  ): Promise<OfflineProgramDetail> {
    const programFromState = this.offlineProgramMap[programId];
    if (!programFromState) {
      const [
        program,
        detail,
        allowedOrgLocations
      ] = await Promise.all([
        this.programResources.getProgramWithForm(programId),
        this.getProgram('' + programId),
        this.programResources.getAllowedOrgLocations(programId)
      ])
      const adaptedDetail: OfflineProgramDetail = {
        ...detail,
        clientId: program.clientId,
        allowedOrgLocations
      };
      this.setOfflineProgramMap({
        ...this.offlineProgramMap,
        [programId]: adaptedDetail
      });

      return adaptedDetail;
    }

    return programFromState;
  }

  async getCycleFromProgram (programId: number, cycleId: number) {
    const program = await this.getProgram('' + programId);

    return program.cycles.find((c) => {
      return c.id === cycleId;
    });
  }

  async resetAllActiveManagerPrograms () {
    this.set('allActiveManagerPrograms', undefined);
    await this.getAllActiveManagerPrograms();
  }

  async getAllActiveManagerPrograms () {
    if (!this.allActiveManagerPrograms) {
      let managerPrograms = await this.programResources.getProgramsForManager(true);
      managerPrograms = managerPrograms.filter(prog => !prog.isDraft);
      this.updateProgramNamesWithTranslations(managerPrograms);
      const active = managerPrograms.filter((item) => !item.isArchived);
      this.setAllActiveManagerPrograms(active);
      this.setAllManagerPrograms(managerPrograms);
      this.setMyCycleAttrs(active);
    }

    return this.allActiveManagerPrograms;
  }

  setMyCycleAttrs (programs: Program[]) {
    const myCycles: CyclesAPI.BaseProgramCycle[] = [];
    programs.forEach((program) => {
      program.grantProgramCycles.forEach((cycle) => {
        myCycles.push(cycle);
      });
    });
    this.cyclesService.setMyCycleAttrs(myCycles);
  }

  async getProgramForDashboard (grantProgramId: number, programCycleIds: number[]) {
    const [
      detail,
      programStats,
      paymentStats,
      stats
    ] = await Promise.all([
      this.getProgram('' + grantProgramId),
      this.programResources.getProgramStats(grantProgramId, programCycleIds),
      this.programResources.getPaymentStats(grantProgramId, programCycleIds),
      this.programResources.getProgramInsightStats([grantProgramId])
    ]);
    const translationMap = this.programTranslationMap[grantProgramId];
    detail.name = translationMap ? translationMap.Name : detail.name;
    this.adaptPaymentStats(paymentStats);
    this.setProgramDashboardMap({
      ...this.get('programDashboardMap'),
      [grantProgramId]: {
        detail,
        programStats,
        paymentStats,
        stats
      }
    });
  }

  adaptPaymentStats (paymentStats: PaymentStats) {
    Object.keys(paymentStats).map(key => key as keyof PaymentStats).forEach((key: keyof PaymentStats) => {
      paymentStats[key] = paymentStats[key] || 0;
    });
  }

  async resetProgramStats (grantProgramId: number, programCycleIds: number[]) {
    const [
      programStats,
      paymentStats
    ] = await Promise.all([
      this.programResources.getProgramStats(grantProgramId, programCycleIds),
      this.programResources.getPaymentStats(grantProgramId, programCycleIds)
    ]);
    this.adaptPaymentStats(paymentStats);
    this.setProgramDashboardMap({
      ...this.programDashboardMap,
      [grantProgramId]: {
        ...this.programDashboardMap[grantProgramId].detail,
        programStats,
        paymentStats
      }
    });
  }

  async getProgramOptionsForApproveNom (programId: number): Promise<TypeaheadSelectOption<number>[]> {
    const [
      prog
    ] = await Promise.all([
      this.getProgram('' + programId),
      this.setAllPrograms()
    ]);

    return prog.grantProgramsForNominationProgram.filter((id) => {
      const activeIds = this.allPublishedPrograms.filter((program) => {
        return (program.grantProgramCycles || []).some((cycle) => {
          return this.dateService.isBetween(new Date(), new Date(cycle.startDate), new Date(cycle.endDate));
        });
      }).map((p) => +p.grantProgramId);

      return activeIds.includes(+id);
    }).map((id) => {
      const found = this.allPublishedPrograms.find((program) => {
        return +program.grantProgramId === +id;
      });
      const map = this.programTranslationMap[id];

      return {
        label: map && map.Name ? map.Name : found.grantProgramName,
        value: id
      };
    });
  }

  async getProgramForEdit (
    id: number,
    processor: ProcessingTypes,
    budgets: Budget[]
  ): Promise<ConfigureProgram> {
    const foundPublished = this.allPublishedPrograms.find((prog) => {
      return +prog.grantProgramId === +id;
    });
    const [
      program,
      masterResponse,
      workflowForms,
      emailSettings,
      allowedOrgLocations
    ] = await Promise.all([
      this.getProgram('' + id),
      this.programResources.getMasterFormResponse(id),
      this.workflowService.getWorkflowForms(id),
      this.programResources.getProgramEmailSettings(id),
      this.getAllowedOrgLocations(id)
    ]);
    await this.workflowService.getAndSetWorkflowMap(program.workflowId);
    const programTimeZone = this.timeZoneService.returnTimeZoneFromID(
      program.timezoneId
    );
    const adaptedCycles = this.adaptCyclesForEdit(
      program.cycles || [],
      processor,
      budgets,
      programTimeZone
    );

    return {
      id,
      name: program.name,
      lastValidProgramName: program.name,
      description: program.description || '',
      guidelines: program.guidelines || '',
      charityBucketId: program.charityBucketId,
      charityBucketDescriptions: program.charityBucketDescription || '',
      image: program.imageUrl,
      logo: program.logoUrl,
      imageUrlOrBase64: program.imageUrl,
      logoUrlOrBase64: program.logoUrl,
      logoName: program.logoName,
      imageName: program.imageName,
      timezoneId: program.timezoneId ||
        this.clientSettingsService.clientSettings.defaultTimezone,
      defaultForm: program.defaultFormId,
      workflow: program.workflowId,
      defaultLevel: program.defaultWorkflowLevelId,
      applicantType: program.programApplicantType || ProgramApplicantType.ORGS,
      allowCollaboration: program.allowCollaboration,
      deadlines: program.deadlines || [],
      successMessage: program.eligibilityPassMessage || this.passMessage,
      failMessage: program.eligibilityFailMessage || this.failMessage,
      masterResponse: await this.getMasterResponse(
        program.additionalForms[0], masterResponse
      ),
      eligibilityForm: program.additionalForms[0] || null,
      workflowFormMap: this.constructFormsForEdit(
        program.workflowId,
        workflowForms,
        program.defaultFormId
      ),
      hasApplications: program.hasApplications,
      grantPrograms: program.grantProgramsForNominationProgram,
      notifyNominators: program.notifyNominators,
      allowAddOrg: program.allowAddOrg,
      canChangeDefaultForm: program.canChangeDefaultForm,
      maskApplicantInfo: program.maskApplicantInfo,
      clientTemplates: [],
      useProgramLogo: program.useProgramLogo,
      senderDisplayName: emailSettings.senderDisplayName ||
        this.clientSettingsService.clientBranding.name,
      defaultLanguage: program.defaultLanguageId ||
        this.clientSettingsService.defaultLanguage,
      emailNotificationTypesPreferences: (emailSettings.disabledEmails || [])
        .map((email) => {
          return {
            id: email.id,
            isExcluded: true
          };
        }),
      daysBeforeEndDateReminders: program.daysBeforeEndDateReminders || 7,
      hideCycleDatesInApplicantPortal: !program.showCycles,
      cycles: adaptedCycles,
      originalCycles: [
        ...adaptedCycles.map((cycle) => {
          return {
            ...cycle
          };
        })
      ],
      isArchived: program.isArchived,
      inviteOnly: program.inviteOnly,
      isPublished: !!foundPublished,
      formDueReminderFrequencyForApplicant: program.formDueReminderFrequencyForApplicant,
      formDueReminderFrequencyForReviewer: program.formDueReminderFrequencyForReviewer,
      formOverDueReminderFrequencyForApplicant: program.formOverDueReminderFrequencyForApplicant,
      formOverDueReminderFrequencyForReviewer: program.formOverDueReminderFrequencyForReviewer,
      reserveFunds: program.reserveFunds,
      autoDeclineBudgetAssignment: program.autoDeclineBudgetAssignment,
      autoDeclineBudgetAssignmentSendEmail: program.autoDeclineBudgetAssignmentSendEmail,
      autoDeclineBudgetAssignmentDeclinationComment: program.autoDeclineBudgetAssignmentDeclinationComment,
      hidePaymentStatus: program.hidePaymentStatus,
      alternatePaymentStatusText: program.alternatePaymentStatusText,
      multipleApplicationsLimitType: program.multipleApplicationsLimitType || CanOrgSubmitMultipleAppsOptions.Yes,
      multipleApplicationsLimitMessage: program.multipleApplicationsLimitMessage,
      allowApplicationCopyForApplicant: program.allowApplicationCopyForApplicant,
      allowedOrgLocations: allowedOrgLocations || []
    };
  }

  adaptCyclesForEdit (
    cycles: CyclesUI.ProgramCycle[] = [],
    processor: ProcessingTypes,
    budgets: Budget[],
    timeZone: TimeZone
  ) {
    return cycles.map<CyclesAPI.ConfigureProgramCycle>((cycle) => {
      const startDate = this.dateService.adaptDateTimeForDisplay(
        cycle.startDate,
        timeZone.offset,
        TIMESTAMP_FORMAT
      );
      const endDate = this.dateService.adaptDateTimeForDisplay(
        cycle.endDate,
        timeZone.offset,
        TIMESTAMP_FORMAT
      );

      return {
        id: cycle.id,
        name: cycle.name,
        startDate,
        endDate,
        budgets: cycle.budgetIds,
        budgetNames: this.adaptCycleBudgetNames(cycle.budgetIds, budgets),
        defaultCashBudgetId: cycle.defaultCashBudgetId,
        defaultCashFundingSourceId: cycle.defaultCashFundingSourceId,
        defaultInKindBudgetId: cycle.defaultInKindBudgetId,
        defaultInKindFundingSourceId: cycle.defaultInKindFundingSourceId,
        clientOrganizationsProcessingTypeId: this.getProcessorTypeForProgram(
          cycle.clientOrganizationsProcessingTypeId,
          cycle.budgetIds,
          processor,
          budgets
        ).processor,
        createdBy: cycle.createdBy,
        createdDate: cycle.createdDate,
        updatedBy: cycle.updatedBy,
        updatedDate: cycle.updatedDate,
        hasApplications: cycle.hasApplications,
        isArchived: cycle.isArchived,
        createImpersonatedBy: cycle.createImpersonatedBy,
        impersonatedBy: cycle.impersonatedBy,
        status: this.cyclesService.getCycleStatus(cycle.startDate, cycle.endDate),
        isClientProcessing: cycle.isClientProcessing,
        alwaysOpen: this.dateService.isMinDate(cycle.startDate) &&
          this.dateService.isMaxDate(cycle.endDate)
      };
    });
  }

  adaptCycleBudgetNames (
    budgetIds: number[],
    budgets: Budget[]
  ): string[] {
    const clientProcessedText = this.i18n.translate(
      'common:labelClient',
      {},
      'Client'
    );
    const ycText = 'YourCause';

    return budgetIds.map((budgetId) => {
      const foundBudget = budgets.find((budget) => budget.id === budgetId);
      const isYcProcessed = foundBudget.hasYcProcessingType && !foundBudget.hasClientProcessingType;
      const isClientProcessed = foundBudget.hasClientProcessingType && !foundBudget.hasYcProcessingType;
      let processorText = '';;
      if (isYcProcessed) {
        processorText = ycText;
      } else if (isClientProcessed) {
        processorText = clientProcessedText;
      } else {
        processorText = `${clientProcessedText} & ${ycText}`;
      }

      return `${foundBudget.name} - ${processorText}`;
    });
  }

  getProcessorTypeForProgram (
    programProcessingType: ProcessingTypes,
    programBudgets: number[],
    processor: ProcessingTypes,
    allBudgets: Budget[]
  ) {
    let hasClient = false;
    let hasYourCause = false;
    allBudgets.forEach((budget) => {
      if (programBudgets.includes(budget.id)) {
        if (budget.hasClientProcessingType) {
          hasClient = true;
        }
        if (budget.hasYcProcessingType) {
          hasYourCause = true;
        }
      }
    });
    if (programProcessingType) {
      return {
        processor: programProcessingType,
        hasOptions: (processor === ProcessingTypes.Both) &&
          hasClient &&
          hasYourCause
      };
    }
    if (processor !== ProcessingTypes.Both) {
      return {
        processor,
        hasOptions: false
      };
    }
    if (hasClient && hasYourCause) {
      return {
        processor: ProcessingTypes.Client,
        hasOptions: true
      };
    } else if (hasClient) {
      return {
        processor: ProcessingTypes.Client,
        hasOptions: false
      };
    } else {
      return {
        processor: ProcessingTypes.YourCause,
        hasOptions: false
      };
    }
  }

  constructFormsForEdit (
    workflowId: number,
    workflowLevelForms: WorkflowLevelFormApi[],
    programDefaultForm: number
  ) {
    const map: {
      [x: string]: WorkflowLevelFormApi[];
    } = {};
    (workflowLevelForms || []).forEach((form) => {
      const adapted: WorkflowLevelFormApi = {
        ...form,
        formType: this.formService.getFormTypeFromFormId(form.formId),
        isDefaultForm: form.formId === programDefaultForm
      };
      const left = '' + form.workflowLevelId;
      if (map[left]) {
        map[left].push(adapted);
      } else {
        map[left] = [adapted];
      }
    });
    const workflowDetail = this.workflowService.get('workflowMap')[workflowId];
    workflowDetail.levels.forEach((level) => {
      if (!map[level.id]) {
        map[level.id] = [];
      } else {
        map[level.id] = this.arrayHelper.sort(map[level.id], 'sortOrder');
      }
      level.subLevels.forEach((sub) => {
        if (!map[sub.id]) {
          map[sub.id] = [];
        } else {
          map[sub.id] = this.arrayHelper.sort(map[sub.id], 'sortOrder');
        }
      });
    });

    return map;
  }

  setAllPublishedPrograms (programs: Program[]) {
    programs = this.arrayHelper.sort(programs, 'grantProgramName');
    this.set('allPublishedPrograms', programs);
    const publishedActivePrograms = programs.filter((prog) => !prog.isArchived);
    this.set('allPublishedActivePrograms', publishedActivePrograms);
  }

  async resetAllPrograms () {
    this.set('allPrograms', undefined);
    this.set('allPublishedPrograms', undefined);
    this.set('allPublishedActivePrograms', undefined);
    await this.setAllPrograms();
  }

  async setAllPrograms () {
    if (!this.allPrograms) {
      const programs = await this.programResources.getAllProgramsDraftAndPublished();
      const publishedPrograms = programs.filter((program) => {
        return !program.isDraft;
      });
      this.setAllPublishedPrograms(publishedPrograms);
      this.updateProgramNamesWithTranslations(programs);
      this.set('allPrograms', programs);
    }
  }

  updateProgramNamesWithTranslations (programs: (Program|ProgramForDashboard)[]) {
    programs.forEach((program) => {
      const map = this.programTranslationMap[program.grantProgramId];
      program.grantProgramName = map && map.Name ? map.Name : program.grantProgramName;
      ((program as Program).grantProgramCycles || []).forEach((cycle) => {
        const cycleMap = this.translationService.viewTranslations.Grant_Program_Cycle[
          cycle.id
        ];
        cycle.name = cycleMap && cycleMap.Name ?
          cycleMap.Name :
          cycle.name;
      });
    });
  }

  async setCycleBudgetsMap () {
    try {
      const map = await this.programResources.getCycleBudgetsMap();
      this.set('cycleBudgetsMap', map);
    } catch (e) {
      this.logger.error(e);
      this.set('cycleBudgetsMap', {});
    }
  }

  clearPaymentProcessingPrograms () {
    this.set('ycProcessingPrograms', undefined);
    this.set('clientProcessingPrograms', undefined);
  }

  async getPaymentProcessingPrograms (
    procType: ProcessingTypes,
    fSType: FundingSourceTypes
  ) {
    const attr = procType === ProcessingTypes.YourCause ?
      'ycProcessingPrograms' :
      'clientProcessingPrograms';
    let programs = this[attr];
    if (!!programs) {
      return programs;
    } else {
      programs = await this.programResources.getPaymentProcessingPrograms(procType, fSType);
      this.updateProgramNamesWithTranslations(programs);
      this.set(attr, programs);

      return programs;
    }
  }

  async getMasterResponse (formId: number, masterResponse: MasterResponse[]) {
    await this.formService.getAndSetForms();
    const found = this.formService.published.find((form) => {
      return +form.formId === +formId;
    });
    if (found) {
      const revisionId = found.revisionId;
      const foundResponse = masterResponse.find((res) => {
        return +res.formRevisionId === +revisionId;
      });

      return foundResponse ? foundResponse : null;
    }

    return null;
  }

  getProgramCycleOptionsForInsights (
    filterValue: ProgramDropdownOptionsForInsights,
    isOrgInsights = false,
    cycleStatus = CyclesAPI.CycleStatuses.Open,
    isApplicantInsights = false
  ): SelectOption[] {
    const programCycleMap = this.cyclesService.programCycleMap;
    const filtered = this.filterByProgramStatus(filterValue, isOrgInsights, isApplicantInsights);

    return this.arrayHelper.sort(
      filtered.map((program) => {
        return {
          label: program.grantProgramName,
          value: program.grantProgramId,
          dependentOptions: (programCycleMap[program.grantProgramId] || [])
            .filter((cycle) => {
              if (cycleStatus === CyclesAPI.CycleStatuses.All) {
                return [
                  CyclesAPI.CycleStatuses.Open,
                  CyclesAPI.CycleStatuses.Past
                ].includes(cycle.status);
              } else {
                return cycle.status === cycleStatus;
              }
            }).map((cycle) => {
              let label = cycle.name;
              const archivedText = this.i18n.translate(
                'GLOBAL:textArchivedLowercase',
                {},
                'archived'
              );
              if (cycleStatus === CyclesAPI.CycleStatuses.All) {
                const status = this.cyclesService.getTranslatedCycleStatus(
                  cycle.status
                ).toLowerCase();
                label = label + ` (${status}${
                  cycle.isArchived ? `/${archivedText})` : ')'
                }`;
              } else if (cycle.isArchived) {
                label = label + ` (${archivedText})`;
              }

              return {
                label,
                value: cycle.id
              };
            })
        };
      }).filter((program) => program.dependentOptions.length > 0),
      'label'
    );
  }

  filterByProgramStatus (
    filterValue: ProgramDropdownOptionsForInsights,
    isOrgInsights = false,
    isApplicantInsights = false
  ) {
    let records = this.dashboardPrograms;
    if  (isOrgInsights || isApplicantInsights) {
      if (!this.policyService.grantApplication.canSeeMaskedApplicants) {
        records = this.nonMaskedDashboardPrograms;
      }
    }
    const programs = records.filter((prog) => {
      const orgFilter = isOrgInsights ?
        prog.programApplicantType !== ProgramApplicantType.INDIVIDUAL :
        true;

      return !prog.isDraft && orgFilter;
    });

    return programs.filter((program) => {
      switch (filterValue) {
        case ProgramDropdownOptionsForInsights.ALL:
        default:
          return program;
        case ProgramDropdownOptionsForInsights.ACTIVE:
          return !program.isArchived;
        case ProgramDropdownOptionsForInsights.ARCHIVED:
          return program.isArchived;
      }
    });
  }

  async setProgramsForDashboard () {
    const [
      dashboardPrograms,
      nonMaskedDashboardPrograms
    ] = await Promise.all([
      this.programResources.getProgramsForDashboard(false),
      !this.policyService.grantApplication.canSeeMaskedApplicants() ?
        this.programResources.getProgramsForDashboard(true) :
        []
    ]);
    // we have to sanitize due to bad data in QA,
    // some programs don't have cycles and this will cause issues downstream
    const filteredDashPrograms = dashboardPrograms.filter((program) => program.numberOfGrantProgramCycles > 0);
    const filteredNonMaskedDashPrograms = nonMaskedDashboardPrograms.filter((program) => program.numberOfGrantProgramCycles > 0);
    this.updateProgramNamesWithTranslations(filteredDashPrograms);
    this.updateProgramNamesWithTranslations(filteredNonMaskedDashPrograms);
    this.set('dashboardPrograms', filteredDashPrograms);
    this.set('nonMaskedDashboardPrograms', filteredNonMaskedDashPrograms);
  }

  downloadProgramExport (programExport: ProgramExport) {
    const b64Input = Base64.encode(JSON.stringify(programExport.exportModel));
    const fileName = `program_export_${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.bin`;
    if (this.fileService.testCanDownload(b64Input)) {
      this.fileService.downloadRaw(b64Input, fileName);
    } else {
      this.fileService.downloadUrlAs(programExport.fileUrl, fileName);
    }
  }

  async doPublishProgram (programId: number) {
    await this.programResources.activateProgram(programId);
    await this.resetProgramAttrs();
  }

  async handlePublishProgram (programId: number) {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doPublishProgram(programId),
      this.i18n.translate(
        'PROGRAM:textSuccessPublishProgram',
        {},
        'Successfully published the program'
      ),
      this.i18n.translate(
        'PROGRAM:textErrorPublishingProgram',
        {},
        'There was an error publishing the program'
      )
    );
  }

  async doCopyProgram (
    program: Program,
    isNomination = false,
    processor: ProcessingTypes,
    budgets: Budget[]
  ) {
    const detail = await this.getProgramForEdit(
      +program.grantProgramId,
      processor,
      budgets
    );
    const adapted = await this.adaptForApi(
      {
        ...detail,
        id: null
      },
      isNomination,
      true,
      true,
      false
    );

    const id = await this.programResources.saveProgram(adapted);
    // After creating copy, we need to save the cycles and the eligibility info
    await this.getProgramForEdit(id, processor, budgets);
    const detailsForPayload = {
      ...detail,
      id
    };
    await this.programResources.saveProgramFormSettings(
      this.getSaveProgramFormSettingsPayload(detailsForPayload)
    );
    const programTimeZone = this.timeZoneService.returnTimeZoneFromID(
      program.timezoneId
    );
    await this.programResources.saveProgramCycles(
      this.getSaveProgramCyclePayload(isNomination, programTimeZone, detailsForPayload)
    );
    await this.resetAllPrograms();
  }

  async handleCopyProgram (
    program: Program,
    isNomination = false,
    processor: ProcessingTypes,
    budgets: Budget[]
  ) {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doCopyProgram(
        program,
        isNomination,
        processor,
        budgets
      ),
      this.i18n.translate(
        'PROGRAM:textSuccessCopyProgram',
        {},
        'Successfully copied the program'
      ),
      this.i18n.translate(
        'PROGRAM:textErrorCopyingProgram',
        {},
        'There was an error copying the program'
      ),
      true
    );
  }

  getArchivePaymentStatsForPrograms (programIds: number[]) {
    return this.programResources.getArchivePaymentStatsForPrograms(programIds);
  }

  async handleCreateProgram (
    response: CreateProgramModalResponse,
    isNomination = false
  ) {
    await this.workflowService.getAndSetWorkflowMap(response.workflowId);
    const adapted: ConfigureProgram = {
      name: response.name,
      description: '',
      guidelines: '',
      imageName: '',
      logoName: '',
      image: null,
      logo: null,
      imageUrlOrBase64: null,
      logoUrlOrBase64: null,
      timezoneId: this.clientSettingsService.clientSettings.defaultTimezone,
      charityBucketId: null,
      charityBucketDescriptions: null,
      eligibilityForm: null,
      masterResponse: null,
      defaultForm: response.defaultFormId,
      workflow: response.workflowId,
      defaultLevel: null,
      workflowFormMap: {},
      applicantType: ProgramApplicantType.ORGS,
      allowCollaboration: false,
      deadlines: [],
      successMessage: null,
      failMessage: null,
      hasApplications: false,
      canChangeDefaultForm: true,
      maskApplicantInfo: false,
      emailNotificationTypesPreferences: [],
      useProgramLogo: false,
      senderDisplayName: null,
      defaultLanguage: response.defaultLang,
      daysBeforeEndDateReminders: 7,
      isArchived: false,
      hideCycleDatesInApplicantPortal: false,
      originalCycles: [],
      cycles: [],
      inviteOnly: false,
      isPublished: false,
      grantPrograms: [],
      notifyNominators: false,
      allowAddOrg: false,
      reserveFunds: false,
      autoDeclineBudgetAssignment: false,
      autoDeclineBudgetAssignmentSendEmail: false,
      autoDeclineBudgetAssignmentDeclinationComment: '',
      hidePaymentStatus: true,
      alternatePaymentStatusText: '',
      multipleApplicationsLimitType: null,
      multipleApplicationsLimitMessage: '',
      allowApplicationCopyForApplicant: true,
      allowedOrgLocations: []
    };

    return this.handleSaveProgram(
      adapted,
      false,
      isNomination,
      true
    );
  }

  saveProgramSuccess (isNew = false) {
    this.notifier.success(this.i18n.translate(
      isNew ?
        'PROGRAM:textSuccessfullyCreatedProgram' :
        'PROGRAM:textSuccessfullyUpdatedProgram',
      {},
      isNew ?
        'Successfully created the program' :
        'Successfully updated the program'
    ));
  }

  saveProgramError (isNew = false) {
    this.notifier.error(this.i18n.translate(
      isNew ?
        'PROGRAM:textErrorCreatingProgram' :
        'PROGRAM:textErrorUpdatingProgram',
      {},
      isNew ?
        'There was an error creating the program' :
        'There was an error updating the program'
    ));
  }

  async handleSaveProgram (
    program: ConfigureProgram,
    refetchTranslations: boolean,
    isNomination = false,
    saveAsDraft = true
  ) {
    let id: number;
    try {
      id = await this.createOrEditProgram(
        program,
        isNomination,
        refetchTranslations,
        saveAsDraft
      );
      await this.resetAllActiveManagerPrograms();
      this.cyclesService.resetCycleMap();
      this.setOfflineProgramMap({});
      this.saveProgramSuccess();

      return id;
    } catch (err) {
      const e = err as HttpErrorResponse;
      this.logger.error(e);
      if (e?.error?.message === 'Cycles contain overlapping dates.') {
        this.notifier.error(this.i18n.translate(
          'PROGRAM:textErrorOverlappingCycles',
          {},
          'Cycles contain overlapping dates.'
        ));
      } else {
        this.saveProgramError(!program.id);
      }

      return null;
    }
  }

  async createOrEditProgram (
    program: ConfigureProgram,
    isNomination = false,
    refetchTranslations = false,
    saveAsDraft = false
  ) {
    const adapted = await this.adaptForApi(
      program,
      isNomination,
      false,
      saveAsDraft,
      false
    );

    const id = await this.programResources.saveProgram(adapted);
    await Promise.all([
      this.formService.refreshForms(),
      this.resetProgramAttrs()
    ]);
    if (refetchTranslations) {
      await this.translationService.resetViewTranslations();
    }
    if (program.id) {
      this.emailService.emptyTemplateMap();
    }

    return id;
  }

  async adaptForApi (
    program: ConfigureProgram,
    isNomination = false,
    isCopy = false,
    saveAsDraft = false,
    isAutosave = true
  ): Promise<CreateEditProgramApi> {
    const imageChanged = isAutosave && program.image instanceof Blob;
    const logoChanged = isAutosave && program.logo instanceof Blob;
    const programApplicantType = (
      program.applicantType === ProgramApplicantType.ORGS_WITH_BUCKET ?
        ProgramApplicantType.ORGS :
        program.applicantType
    );
    let name = program.name;
    const copyText = this.i18n.translate('common:textCopy', {}, 'Copy');
    if (isCopy) {
      name = `${program.name} ${copyText}`;
      if (name.length > 50) {
        name = program.name;
      }
    }
    if (!name) {
      name = program.lastValidProgramName;
    }
    const noOrgEligibility = program.multipleApplicationsLimitType === CanOrgSubmitMultipleAppsOptions.Yes ||
      programApplicantType === ProgramApplicantType.INDIVIDUAL;
    const adapted: CreateEditProgramApi = {
      saveAsDraft,
      id: program.id,
      name,
      charityBucketId: program.charityBucketId,
      charityBucketDescription: program.charityBucketDescriptions || '',
      description: program.description,
      guidelines: program.guidelines,
      imageName: imageChanged ? '' : program.imageName,
      logoName: logoChanged ? '' : program.logoName,
      workflow: program.workflow,
      defaultWorkflowLevel: program.defaultLevel,
      defaultFormId: program.defaultForm,
      timezone: program.timezoneId,
      useProgramLogo: program.useProgramLogo || false,
      deadlines: (program.deadlines || []).map((deadline) => {
        return {
          id: deadline.id,
          title: deadline.name,
          date: deadline.date,
          notes: deadline.description
        };
      }),
      programApplicantType,
      allowCollaboration: program.allowCollaboration || false,
      programType: isNomination ? ProgramTypes.NOMINATION : ProgramTypes.GRANT,
      allowAddOrg: program.allowAddOrg || false,
      maskApplicantInfo: program.applicantType === ProgramApplicantType.INDIVIDUAL ?
        program.maskApplicantInfo || false :
        false,
      clientEmailTemplates: program.clientTemplates,
      emailNotificationTypesPreferences: program.emailNotificationTypesPreferences,
      defaultLanguageId: program.defaultLanguage ||
        this.clientSettingsService.defaultLanguage,
      daysBeforeEndDateReminders: program.daysBeforeEndDateReminders || 7,
      senderDisplayName: (
        !program.senderDisplayName ||
        (program.senderDisplayName === this.clientSettingsService.clientBranding.name)
       ) ?
        null :
        program.senderDisplayName,
      hideCycleDatesInApplicantPortal: program.hideCycleDatesInApplicantPortal,
      inviteOnly: program.inviteOnly,
      formDueReminderFrequencyForApplicant: program.formDueReminderFrequencyForApplicant,
      formDueReminderFrequencyForReviewer: program.formDueReminderFrequencyForReviewer,
      formOverDueReminderFrequencyForApplicant: program.formOverDueReminderFrequencyForApplicant,
      formOverDueReminderFrequencyForReviewer: program.formOverDueReminderFrequencyForReviewer,
      reserveFunds: program.reserveFunds,
      autoDeclineBudgetAssignment: program.autoDeclineBudgetAssignment,
      autoDeclineBudgetAssignmentSendEmail: program.autoDeclineBudgetAssignmentSendEmail,
      autoDeclineBudgetAssignmentDeclinationComment: program.autoDeclineBudgetAssignmentDeclinationComment,
      hidePaymentStatus: program.hidePaymentStatus,
      alternatePaymentStatusText: program.alternatePaymentStatusText,
      multipleApplicationsLimitType: noOrgEligibility ? null : program.multipleApplicationsLimitType,
      multipleApplicationsLimitMessage: noOrgEligibility ? '' : program.multipleApplicationsLimitMessage,
      allowApplicationCopyForApplicant: program.allowApplicationCopyForApplicant,
      allowedOrgLocations: program.allowedOrgLocations
    };
    if (imageChanged && program.image) {
      adapted.imageName = await this.handleProgramImage(program.image);
      this.setMapProperty(program.id, 'imageName', adapted.imageName, true);
    }
    if (logoChanged && program.logo) {
      adapted.logoName = await this.handleProgramImage(program.logo);
      this.setMapProperty(program.id, 'logoName', adapted.logoName, true);
    }
    if (isNomination) {
      adapted.grantProgramsForNominationProgram = program.grantPrograms;
      adapted.nofityNominators = program.notifyNominators || false;
    }

    return adapted;
  }

  adaptCyclesForApi (
    cycles: CyclesAPI.ConfigureProgramCycle[] = [],
    isNomination = false,
    isCopy = false,
    timeZoneOffset: number,
    programApplicantType: ProgramApplicantType
  ) {
    return cycles.map((cycle) => {
      // Individuals can never be processed through YC, so assume processor is client
      const clientOrganizationsProcessingTypeId = programApplicantType === ProgramApplicantType.INDIVIDUAL ?
        ProcessingTypes.Client :
        cycle.clientOrganizationsProcessingTypeId;

      const adapted = {
        id: isCopy ? null : cycle.id,
        name: cycle.name,
        startDate: this.dateService.adaptDateTimeForSave(
          cycle.startDate,
          timeZoneOffset
        ),
        endDate: this.dateService.adaptDateTimeForSave(
          cycle.endDate,
          timeZoneOffset
        ),
        budgets: cycle.budgets,
        defaultCashBudgetId: cycle.defaultCashBudgetId,
        defaultCashFundingSourceId: cycle.defaultCashFundingSourceId,
        defaultInKindBudgetId: cycle.defaultInKindBudgetId,
        defaultInKindFundingSourceId: cycle.defaultInKindFundingSourceId,
        isClientProcessing: cycle.isClientProcessing,
        clientOrganizationsProcessingTypeId
      };
      if (isNomination) {
        delete adapted.budgets;
        delete adapted.defaultCashBudgetId;
        delete adapted.defaultCashFundingSourceId;
        delete adapted.defaultInKindBudgetId;
        delete adapted.defaultInKindFundingSourceId;
      }

      return adapted;
    });
  }

  async handleProgramImage (file: Blob): Promise<string> {
    try {
      const response = await this.staticResourceService.uploadManagerImage(
        file as File,
        ''
      );

      return response.fileName;
    } catch (err) {
      const e = err as HttpErrorResponse;
      this.fileTypeService.displayInvalidFileUploadErrorMessage(e?.error?.message, e);

      return null;
    }
  }

  async getProgramStatusStats (grantProgramId: number, programCycleIds: number[]): Promise<ProgramStatusStat[]> {
    const response = await this.programResources.getProgramStatusStats(grantProgramId, programCycleIds);
    const appStatusMap = this.statusService.applicationStatusMap;
    const  mapped = response.filter((record) => record.status !== ApplicationStatuses.Canceled)
      .map((item: ProgramStatusStatApi) => {
        return {
          statusName: appStatusMap[item.status]?.translated,
          requestAmount: item.statusInfo.requestAmount,
          numberOfApplications: item.statusInfo.numberOfApplications
        };
      });

    return this.arrayHelper.sortByAttributes(
      mapped,
      'requestAmount',
      'numberOfApplications',
      true
    );
  }

  async getPaymentStatusStatsByCycle (
    cycleIds: number[]
  ): Promise<PaymentStatusStat[]> {
    const response = await this.programResources.getPaymentStatusStatsByCycle(
      cycleIds
    );
    const paymentStatusMap = this.statusService.paymentStatusMap;
    const mapped = response.filter((item) => {
      return item.status !== PaymentStatus.Voided;
    }).map((item) => {
      return {
        statusId: item.status,
        statusName: paymentStatusMap[item.status]?.translated,
        paymentsTotal: item.statusInfo.paymentsTotal,
        numberOfPayments: item.statusInfo.numberOfPayments
      };
    });

    return this.arrayHelper.sortByAttributes(
      mapped,
      'paymentsTotal',
      'numberOfPayments',
      true
    );
  }

  getMostCommonDefaultLangFromArray (
    ids: number[]
  ) {
    const programs = this.allPrograms || [];

    return this.translationService.getMostCommonDefaultLangFromArray(
      programs.map((prog) => {
        return {
          defaultLanguageId: prog.defaultLanguageId,
          id: +prog.grantProgramId
        };
      }),
      ids
    );
  }

  async doDeleteProgram (programId: number) {
    await this.programResources.deleteProgram(programId);
    await this.resetProgramAttrs();
  }

  async handleDeleteProgram (programId: number) {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doDeleteProgram(programId),
      this.i18n.translate(
        'PROGRAM:textSuccessfullyDeletedProgram',
        {},
        'Successfully deleted program'
      ),
      this.i18n.translate(
        'PROGRAM:textErrorDeletingPRogram',
        {},
        'There was an error deleting this program'
      )
    );
  }

  async doArchiveProgram (payload: ArchiveProgramPayload) {
    await this.programResources.archiveProgram(payload);
    await this.resetProgramAttrs();
  }

  async handleArchiveProgram (payload: ArchiveProgramPayload) {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doArchiveProgram(payload),
      this.i18n.translate(
        payload.archive ?
          'PROGRAM:textSuccessArchiveProgram' :
          'PROGRAM:textSuccessUnarchiveProgram',
        {},
        payload.archive ?
          'Successfully archived the program' :
          'Successfully unarchived the program'
      ),
      this.i18n.translate(
        payload.archive ?
          'PROGRAM:textErrorArchivingProgram' :
          'PROGRAM:textErrorUnarchivingProgram',
        {},
        payload.archive ?
          'There was an error archiving the program' :
          'There was an error unarchiving the program'
      )
    );
  }

  async doProgramExport (programs: Program[]) {
    const programExport = await this.programResources.exportPrograms(
      programs.map(p => +p.grantProgramId)
    );
    const formNamesWithJavascript = this.formLogicService.getFormNamesWithCustomJavascriptForProgramExport(
      programExport
    );

    return {
      programExport,
      formNamesWithJavascript
    };
  }

  async handleProgramExport (programs: Program[]) {
    const response = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doProgramExport(programs),
      null,
      this.i18n.translate(
        'PROGRAM:textErrorExportingPrograms',
        {},
        'There was an error exporting the program(s)'
      )
    );

    if (response.passed) {
      return response.endpointResponse;
    }

    return null;
  }

  checkExistingDataForImport (programImport: ProgramImport) {
    return this.programResources.checkExistingDataForImport(programImport);
  }

  async handleCheckExistingDataForImport (programImport: ProgramImport) {
    const response = await this.confirmAndTakeAction.genericTakeAction(
      () => this.checkExistingDataForImport(programImport),
      null,
      this.i18n.translate(
        'PROGRAM:textErrorCheckingExistingDataForImport',
        {},
        'There was an error checking existing data before import'
      )
    );
    if (response.passed) {
      return response.endpointResponse;
    }

    return null;
  }

  async doProgramImport (
    programImport: ProgramImport,
    isNomination = false
  ) {
    await this.programResources.importPrograms(programImport);
    await Promise.all([
      this.formService.refreshForms(),
      this.clientSettingsService.setBranding(),
      this.resetProgramAttrs(),
      this.formFieldService.resetFieldsAndCategories()
    ]);
    this.inKindService.resetInKindState();
    const key = isNomination ? 'NOMINATION_PROGRAMS' : 'GRANT_PROGRAMS';
    const repo = this.TableRepository.getRepository(key);
    if (repo) {
      repo.reset();
    }
  }

  async handleProgramImport (
    programImport: ProgramImport,
    isNomination = false
  ): Promise<boolean> {
    const response = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doProgramImport(programImport, isNomination),
      this.i18n.translate(
        'PROGRAM:notificationSuccessProgramImport',
        {},
        'Successfully imported the program'
      ),
      this.i18n.translate(
        'PROGRAM:notificationErrorWithImport',
        {},
        'There was an error importing your programs'
      )
    );
    
    return response.passed;
  }

  async setCycleMap () {
    if (Object.keys(this.cyclesService.programCycleMap).length === 0) {
      const cycles = await this.cyclesService.getAllCycles();
      const map: SimpleStringMap<CyclesAPI.SimpleCycle[]> = {};
      const translateMap = this.translationService.viewTranslations.Grant_Program_Cycle;
      cycles.forEach((cycle) => {
        cycle.status = this.cyclesService.getCycleStatus(
          cycle.startDate,
          cycle.endDate
        );
        cycle.name = translateMap[cycle.id].Name;
        const existing = map[cycle.grantProgramId];
        if (existing) {
          map[cycle.grantProgramId] = [
            ...existing,
            cycle
          ];
        } else {
          map[cycle.grantProgramId] = [cycle];
        }
      });
      this.cyclesService.setCycleMap(map);
    }
  }

  async canApplicantApplyToProgram (
    programGuid: string
  ): Promise<InvitationApplicantHelpers> {
    return this.programResources.canApplicantApplyToProgram(
      this.userService.currentUser.id,
      programGuid
    );
  }

  async getAllowedOrgLocations (
    programGuid: string|number
  ) {
    const locations = await this.programResources.getAllowedOrgLocations(programGuid);

    return locations.map((location) => {
      return {
        ...location,
        regionIds: location.regionIds.filter((id) => !!id)
      };
    });
  }

  async getAndAdaptProgramForApplicant (
    programGuid: string|number
  ): Promise<ProgramDetail> {
    let program = this.applicantProgramMap[programGuid]; 
    if (!program) {
      const [
        programResponse,
        allowedOrgLocations
      ] = await Promise.all([
        this.programResources.getProgramForApplicant(programGuid),
        this.getAllowedOrgLocations(programGuid)
      ]);
      const adaptedResponse = this.formLogicService.adaptFormDefinitionForTabs(
        programResponse.form.formDefinition,
        FormTypes.REQUEST
      );
      const adapted: ProgramDetail = {
        ...programResponse,
        allowedOrgLocations,
        form: {
          ...programResponse.form,
          formDefinition: adaptedResponse.formDefinition,
          customJavascriptComps: adaptedResponse.customJavascriptComps,
          isOverdue: false,
          dueDate: null
        }
      };

      this.set('applicantProgramMap', {
        ...this.applicantProgramMap,
        [programGuid]: adapted
      });
    }

    return this.applicantProgramMap[programGuid];
  }

  async handleGetProgramForApplicant (
    programGuid: string|number,
    skipSpinner = false
  ) {
    const response = await this.confirmAndTakeAction.genericTakeAction(
      () =>  this.getAndAdaptProgramForApplicant(programGuid),
      null,
      this.i18n.translate(
        'PROGRAM:textErrorLoadingProgram',
        {},
        'There was an error loading the program'
      ),
      skipSpinner
    );
    if (response.passed) {
      return response.endpointResponse;
    }

    return null;
  }

  async getProgramLogo (programId: number) {
    const detail = await this.getProgram('' + programId);

    return detail.logoUrl;
  }

  async getDefaultWflId (programId: number) {
    await this.getAllActiveManagerPrograms();

    return this.allActiveManagerPrograms.find((prog) => {
      return +prog.grantProgramId === +programId;
    }).defaultWorkflowLevelId;
  }

  /**
   * Gets published active program options filtered by applicant type
   *
   * @param programApplicantType: the type of applicant - org or individual
   * @returns Typeahead select options for published and active programs
   */
  getPublishedActiveProgramOptions (
    programApplicantType?: ProgramApplicantType
  ): TypeaheadSelectOption[] {
    const translations = this.translationService.viewTranslations.Grant_Program;

    return this.arrayHelper.sort(
      this.allPublishedActivePrograms.filter((prog) => {
        if (programApplicantType) {
          return prog.programApplicantType === programApplicantType;
        }

        return prog;
      }).map((prog) => {
        return {
          label: translations[prog.grantProgramId]?.Name ?? prog.grantProgramName,
          value: prog.grantProgramId
        };
      }),
      'label'
    );
  }

  /**
   *
   * @param existingList the current list of forms
   * @param itemIndex the index of the item being moved
   * @param isUp whether the move is higher in the list
   * @returns updated list of forms, and an array of the forms that changed
   */
  updateFormsListSortOrder (
    id: number,
    existingList: WorkflowLevelFormApi[],
    itemIndex: number,
    isUp: boolean
  ): WorkflowFormSortUpdate {
    // when an item is being moved down, we are essentially moving the next item up
    if (!isUp) {
      ++itemIndex;
      isUp = true;
    }
    const updatedIndex = itemIndex - 1;
    const sliceIndexEnd = itemIndex + 1;

    const temp1 = existingList[itemIndex];
    const temp2 = existingList[updatedIndex];
    const updatedFormsArray = [
      ...existingList.slice(0, updatedIndex),
      temp1,
      temp2,
      ...existingList.slice(sliceIndexEnd)
    ];
    const adaptedFormsArray = updatedFormsArray.map((item, index) => {
      return {
        ...item,
        sortOrder: index + 1
      };
    });

    return {
      id,
      adaptedFormsArray,
      movedForm1: adaptedFormsArray.find((item) => {
        return item.id === temp1.id;
      }),
      movedForm2: adaptedFormsArray.find((item) => {
        return item.id === temp2.id;
      })
    };
  }
}
