import { BudgetImportModel } from '@core/typings/budget.typing';
import { createValidator } from '@yourcause/common';
import { BudgetService } from './budget.service';


export const BudgetNameIsUnique = createValidator<BudgetImportModel>(() => (name, {
  injector
}) => {
  const service = injector.get(BudgetService);
  const hasBudgetNameError = service.budgets.some((budget) => budget.name === name);

  if (hasBudgetNameError) {
    return {
      i18nKey: 'BUDGET:textBudgetImportNameError',
      defaultValue: 'Budget name must be unique. Budget with name __name__ already exists.',
      context: {
        name
      }
    };
  }

  return [];
});
