import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AggregationService } from '@core/services/aggregation.service';
import { CountriesService } from '@core/services/countries.service';
import { CurrencyService } from '@core/services/currency.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { StaticResourceService } from '@core/services/static-resource.service';
import sdgs from '@core/static-assets/sdgs';
import { AddOrgAPI } from '@core/typings/api/add-org.typing';
import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { ApplicantForOrg } from '@core/typings/applicant.typing';
import { ApplicationForUi } from '@core/typings/application.typing';
import { AdaptedNppSearchResult, ApplicantOrganizationForUi, BucketSearchAPIResponse, BucketSearchResultObj, CompanyOrgDetail, CsrConnectStats, NonprofitDataApi, NonprofitDetail, NonprofitProfileData, NonprofitProfileSummary, OrgForDash, OrgUnion, SearchResultNormalized, VettingRejectionReason, VettingRequestStatusId } from '@core/typings/organization.typing';
import { ProcessingTypes } from '@core/typings/payment.typing';
import { AllowedLocation } from '@core/typings/program.typing';
import { PaymentStatus } from '@core/typings/status.typing';
import { AwardForOrg } from '@features/awards/typings/award.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { APIResult, AddressFormatterService, AddressFromApi, ClassificationMap, InflectService, OrganizationEligibleForGivingStatus, OrganizationSearchResponse, PaginationOptions, SDGMetadata, SearchFilter, SearchResult, SearchResultObj, SubsectionCodeMap } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { DateService } from '@yourcause/common/date';
import { FileService, FileTypeService, YcFile } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { NonprofitResources } from './nonprofit.resources';
import { NonprofitState } from './nonprofit.state';

export const NonYcEligibleRegAuthNames: string[] = ['Monte de Piedad'];

@AttachYCState(NonprofitState)
@Injectable({ providedIn: 'root' })
export class NonprofitService extends BaseYCService<NonprofitState> {

  constructor (
    private nonprofitResources: NonprofitResources,
    private aggregationService: AggregationService,
    private i18nService: I18nService,
    private staticResourceService: StaticResourceService,
    private arrayHelper: ArrayHelpersService,
    private inflect: InflectService,
    private currencyService: CurrencyService,
    private fileService: FileService,
    private portal: PortalDeterminationService,
    private addressFormatter: AddressFormatterService,
    private clientSettingsService: ClientSettingsService,
    private i18n: I18nService,
    private dateService: DateService,
    private fileTypeService: FileTypeService,
    private countriesService: CountriesService
  ) {
    super();
  }

  get nonprofitMap () {
    return this.get('nonprofitMap');
  }

  get sdgs () {
    return this.get('sdgs');
  }

  /**
   *
   * @returns Sdg Options
   */
  getSdgOptions (): TypeaheadSelectOption[] {
    const options = (this.sdgs || []).map((sdg) => {
      return {
        label: this.translateSdg(sdg.goal),
        value: sdg.id
      };
    });

    return options;
  }

  /**
   *
   * @param name: SDG name
   * @returns translated SDG name
   */
  translateSdg (name: string) {
    return this.i18nService.translate(
      `sdgs:text${this.inflect.pascalize(name)}`,
      {},
      name
    );
  }

  /**
   * Sets SDG Metadata
   */
  setSdgMetadata () {
    if (!this.sdgs) {
      this.set('sdgs', sdgs as SDGMetadata[]);
    }
  }

  /**
   *
   * @param guid: nonprofit guid
   * @returns Nonprofit ID
   */
  getIdByNonprofitGuid (guid: string) {
    return this.nonprofitResources.getIdByNonprofitGuid(guid);
  }

  /**
   *
   * @param guid: nonprofit guid
   * @returns Nonprofit data
   */
  getNonprofitAdditionalDataByGuid (guid: string) {
    return this.nonprofitResources.getNonprofitAdditionalDataByGuid(guid);
  }

  /**
   *
   * @param id: Org ID
   * @returns Company org details
   */
  getCompanyOrgDetail (id: number) {
    return this.nonprofitResources.getCompanyOrgDetail(id);
  }

  /**
   *
   * @param guid: nonprofit guid
   */
  async setNonprofit (guid: string) {
    const [
      nonprofit,
      additional,
      gcId,
      nonprofitAdmins
    ] = await Promise.all([
      this.nonprofitResources.getNonprofitByGuid(guid),
      this.nonprofitResources.getNonprofitAdditionalDataByGuid(guid),
      this.nonprofitResources.getIdByNonprofitGuid(guid),
      this.nonprofitResources.getNonprofitAdminsByGuid(guid)
    ]);
    const summary = await this.nonprofitResources.getOrgSummary(
      +gcId
    );
    let csrStats: CsrConnectStats;
    if (nonprofit.ycCharityId) {
      csrStats = await this.aggregationService.getCharityDonationsByYear(
        nonprofit.ycCharityId
      );
    }
    this.set('nonprofitMap', {
      ...this.get('nonprofitMap'),
      [guid]: {
        ...additional,
        nonprofit,
        dueDiligence: nonprofit.dueDiligence,
        csrStats,
        gcId,
        nonprofitAdmins,
        summary: summary || {} as NonprofitProfileSummary
      }
    });
  }

  /**
   *
   * @param nonprofitId: nonprofit ID
   * @param appId: application ID
   * @param insightsPage: is insights page?
   */
  setNonprofitRouterLinkMap (
    nonprofitId: number|string,
    appId?: number,
    insightsPage: 'budgets'|'funding-sources'|'program-manager' = 'program-manager'
  ) {
    const isNomination = location.pathname.includes('nomination');
    const fromInsights = `/management/insights/${insightsPage}/nonprofit/${nonprofitId}/profile`;
    const fromAppManager = `/management/manage-${
      isNomination ? 'nominations' : 'applications'
    }/nonprofit/${nonprofitId}/profile`;
    const fromAppView = appId ?
      `/management/${
        isNomination ? 'nomination' : 'application'
      }-view/${appId}/nonprofit/${nonprofitId}/profile` :
      '';
    const fromProcessing = `/management/payment-processing/nonprofit/${nonprofitId}/profile`;

    this.set('nonprofitProfileRouterLink', {
      ...this.get('nonprofitProfileRouterLink'),
      [nonprofitId]: {
        fromInsights,
        fromAppManager,
        fromAppView,
        fromProcessing
      }
    });
  }

  /**
   *
   * @param id: Org ID
   */
  async setCompanyOrgDetail (id: number) {
    const [
      detail,
      summary
    ] = await Promise.all([
      this.nonprofitResources.getCompanyOrgDetail(id),
      this.nonprofitResources.getOrgSummary(id)
    ]);
    this.set('nonprofitMap', {
      ...this.get('nonprofitMap'),
      [id]: {
        ...this.adaptOrgDetail(detail, summary)
      }
    });
  }

  /**
   *
   * @param orgId: Org ID
   * @returns applicant organization model
   */
  async getApplicantOrganizationFromOrgId (
    orgId: number
  ): Promise<ApplicantOrganizationForUi> {
    const detail = await this.nonprofitResources.getCompanyOrgDetail(orgId);

    return {
      id: orgId,
      name: detail.name,
      address: detail.address,
      address2: detail.address2,
      country: detail.country,
      state: detail.state,
      city: detail.city,
      postalCode: detail.postalCode,
      phoneNumber: detail.phoneNumber,
      charityId: detail.charityId,
      orgIdentification: detail.orgIdentification,
      imageUrl: detail.imageUrl,
      isPrivateOrg: !detail.nonprofitGuid,
      nonprofitGuid: detail.nonprofitGuid,
      eligibleForGivingStatusId: null,
      eligibleForGivingStatus: null,
      registrationAuthorityName: '',
      clientId: null,
      latestVettingRequestStatusForOrg: null
    };
  }

  /**
   *
   * @param detail: Company org detail
   * @param summary: nonprofit profile summary
   * @returns adapted org detail
   */
  adaptOrgDetail (
    detail: CompanyOrgDetail,
    summary: NonprofitProfileSummary
  ): NonprofitProfileData {
    return {
      nonprofitDetail: {
        id: detail.id,
        registrationId: detail.orgIdentification,
        name: detail.name,
        registrationAuthorityId: null,
        isPrivateOrg: detail.isPrivateOrg,
        address: {
          address1: detail.address,
          address2: detail.address2,
          city: detail.city,
          country: detail.country,
          stateProvRegCode: detail.state,
          stateProvRegName: detail.state,
          countryCode: detail.country,
          postalCode: detail.postalCode
        },
        allowDonations: false,
        allowVolunteering: false,
        displayName: detail.name,
        displayPitch: null,
        displayNumber: null,
        displayEmail: null,
        displayAddress: null,
        displayUrl: null,
        tags: null,
        nonprofitIconImageUrl: detail.imageUrl,
        supportsSDGGive: false,
        supportsSDGVolunteer: false,
        sdGs: [],
        eligibleForGivingStatusId: OrganizationEligibleForGivingStatus.UNKNOWN,
        parentNonprofitName: '',
        parentNonprofitGuid: ''
      },
      dueDiligence: {
        registrationAuthority: '',
        foundationType: '',
        ycCompliance: {
          federalUSCompliance: false,
          nonDiscriminationCompliance: false,
          nonReligiousCompliance: false
        },
        companyCompliance: [],
        sdg: []
      },
      nonprofit: {
        nonprofitGuid: detail.nonprofitGuid
      } as NonprofitDataApi,
      gcId: '' + detail.id,
      csrStats: null,
      summary,
      nonprofitAdmins: []
    };
  }

  /**
   *
   * @param sdg: SDG info
   * @returns the SDG icon url
   */
  getSdgIconUrl (sdg: SDGMetadata) {
    const number = sdg.id > 9 ? '' + sdg.id : `0${sdg.id}`;

    return `./assets/img/sdg/icons/${this.i18nService.simpleLanguage}/sdg-rgb-${number}.png`;
  }

  /**
   *
   * @param newOrg: New Organization
   * @param file: File to upload
   * @returns the updated org
   */
  async uploadIcon (
    newOrg: AddOrgAPI.AddNonprofitModel,
    file: YcFile
  ) {
    try {
      if (file) {
        const res = await this.staticResourceService.uploadPortalImage(
          file.file as File,
          file.fileName
        );
        newOrg.imageName = res.fileName;
        newOrg.imageUrl = res.fileUrl;
      }

      return newOrg;
    } catch (err) {
      const e = err as HttpErrorResponse;
      this.fileTypeService.displayInvalidFileUploadErrorMessage(e?.error?.message, e);

      return null;
    }
  }

  /**
   *
   * @param csrStats: CSR stats
   * @returns CSR stats with data
   */
  getCsrStatsWithData (csrStats: CsrConnectStats) {
    if (csrStats) {
      const companyDonations = csrStats.clientCharityDonationsByYear;
      const networkDonations = csrStats.networkCharityDonationsByYear;
      if (companyDonations.length) {
        const total = companyDonations.reduce((acc, value) => {
          return +acc + +value.totalProcessedEmployeesDonations;
        }, 0);
        if (total) {
          return csrStats;
        }
      } else if (networkDonations.length) {
        const total = networkDonations.reduce((acc, value) => {
          return +acc + +value.totalProcessedEmployeesDonations;
        }, 0);
        if (total) {
          return csrStats;
        }
      } else {
        return null;
      }
    }

    return null;
  }

  /**
   *
   * @param id: Org ID
   * @param options: pagination options
   * @returns the applicants that have applied to this org
   */
  getOrgApplicants (
    id: number,
    options: PaginationOptions<ApplicantForOrg>
  ) {
    return this.nonprofitResources.getApplicantsForOrg(
      id,
      options
    );
  }

  /**
   *
   * @param id: Org ID
   * @param options: pagination options
   * @returns the awards tied to this org
   */
  async getOrgAwards (
    id: number,
    options: PaginationOptions<AwardForOrg>
  ) {
    return this.nonprofitResources.getAwardsForOrg(
      id,
      options
    );
  }

  /**
   *
   * @param nonprofitDetail: nonprofit detail info
   * @param defaultToRemittance: default to remittance address?
   * @returns nonprofit address fields for the org
   */
  getNonprofitAddressFields (
    nonprofitDetail: NonprofitDetail,
    defaultToRemittance = this.portal.isManager
  ): {
    address1: string;
    address2: string;
    city: string;
    stateProvRegCode: string;
    country: string;
    postalCode: string;
  } {
    const displayAddress = nonprofitDetail.displayAddress || {} as AddressFromApi;
    const address = nonprofitDetail.address || {} as AddressFromApi;
    const remittanceAddress = nonprofitDetail.remittanceInfo.address || {} as AddressFromApi;
    if (defaultToRemittance) {
      return {
        address1: remittanceAddress.address1 ||
          displayAddress.address1 ||
          address.address1,
        address2: remittanceAddress.address2 ||
          displayAddress.address2 ||
          address.address2,
        city: remittanceAddress.city ||
          displayAddress.city ||
          address.city,
        stateProvRegCode: remittanceAddress.stateProvRegCode ||
          displayAddress.stateProvRegCode ||
          address.stateProvRegCode,
        country: remittanceAddress.countryCode ||
          displayAddress.countryCode ||
          address.countryCode,
        postalCode: remittanceAddress.postalCode ||
          displayAddress.postalCode ||
          address.postalCode
      };
    } else {
      return {
        address1: displayAddress.address1 ||
          address.address1 ||
          remittanceAddress.address1,
        address2: displayAddress.address2 ||
          address.address2 ||
          remittanceAddress.address2,
        city: displayAddress.city ||
          address.city ||
          remittanceAddress.city,
        stateProvRegCode: displayAddress.stateProvRegCode ||
          address.stateProvRegCode ||
          remittanceAddress.stateProvRegCode,
        country: displayAddress.countryCode ||
          address.countryCode ||
          remittanceAddress.countryCode,
        postalCode: displayAddress.postalCode ||
          address.postalCode ||
          remittanceAddress.postalCode
      };
    }
  }

  /**
   *
   * @param programs: program IDs
   * @returns orgs tied to the programs passed in
   */
  async getOrgsForDashboard (
    programs: number[]
  ) {
    const nonprofits = await this.nonprofitResources.getNonprofits(programs);
    nonprofits.forEach((nonprofit) => {
      const sum = this.currencyService.formatMoney(
        nonprofit.awardsTotal
      );
      nonprofit.awardNumberAndTotal = `${nonprofit.numberOfAwards} (${sum})`;
    });
    const other = nonprofits.find((nonprofit) => {
      if (nonprofit.organizationId === 0) {
        nonprofit.name = this.i18nService.translate(
          'nonprofit:textAdditionalOrganizations',
          {},
          'Additional organizations'
        );

        return true;
      }

      return false;
    });
    const sorted = this.arrayHelper.sortByAttributes(
      nonprofits.filter((nonprofit) => nonprofit.organizationId !== 0),
      'awardsTotal',
      'numberOfAwards',
      true
    );
    if (other) {
      return [
        ...sorted,
        other
      ];
    }

    return sorted;
  }

  /**
   *
   * @param cycleIds: Cycle IDs
   * @returns the classifications tied to the cycle IDs
   */
  async getClassificationsForDashboard (cycleIds: number[]) {
    const classifications = await this.nonprofitResources.getClassifications(
      cycleIds
    );
    classifications.forEach((classification) => {
      const classificationName = ClassificationMap[
        classification.classificationId
      ];
      classification.name = this.i18nService.translate(
        `classification:text${this.inflect.pascalize(classificationName)}`,
        {},
        classification.name
      );
      const awardSum = this.currencyService.formatMoney(
        classification.awardsTotal
      );
      classification.awardNumberAndTotal = `${classification.numberOfAwards} (${
        awardSum
      })`;
      const paymentSum = this.currencyService.formatMoney(
        classification.paymentsTotal
      );
      classification.paymentsNumberAndTotal = `${classification.numberOfPayments} (${
        paymentSum
      })`;
    });

    return this.arrayHelper.sort(
      classifications,
      'name'
    );
  }

  /**
   *
   * @param options: pagination options
   * @param cycleIds: selected cycle IDs
   * @returns org list for insights
   */
  async getOrgListForInsights (
    options: PaginationOptions<OrgForDash>,
    cycleIds: number[]
  ) {
    const result = await this.nonprofitResources.getOrgsForInsights(
      options,
      cycleIds
    );
    const apiResult: APIResult<OrgForDash> = {
      success: true,
      data: {
        recordCount: result.recordCount,
        records: result.records
      }
    };

    return apiResult;
  }

  /**
   *
   * @param records: org records for dashboard
   * @returns returns csv for records
   */
  processCsvForOrgList (records: OrgForDash[]) {
    const adapted = records.map((record) => {
      const applicantInfo = record.applicantInfos;
      const orgInfo = record.organizationInfo;

      return {
        [this.i18n.translate('common:labelOrganizationName', {}, 'Organization name')]: orgInfo.name,
        [this.i18n.translate('GLOBAL:IDENTIFICATION', {}, 'Identification')]: orgInfo.identification,
        [this.i18n.translate('GLOBAL:ADDRESS_ONE', {}, 'Address 1')]: orgInfo.address1,
        [this.i18n.translate('GLOBAL:ADDRESS_2', {}, 'Address 2')]: orgInfo.address2,
        [this.i18n.translate('GLOBAL:CITY', {}, 'City')]: orgInfo.city,
        [this.i18n.translate('GLOBAL:STATE', {}, 'State')]: orgInfo.state,
        [this.i18n.translate('GLOBAL:lblPostalCode', {}, 'Postal code')]: orgInfo.postalCode,
        [this.i18n.translate('GLOBAL:COUNTRY', {}, 'Country')]: orgInfo.country,
        [this.i18n.translate('common:textIsPrivateOrganization', {}, 'Is private organization')]: orgInfo.isPrivateOrg,
        [this.i18n.translate('common:lblApplicants', {}, 'Applicants')]: applicantInfo.map((applicant) => {
          return applicant.fullName;
        }).join(', '),
        [this.i18n.translate('common:textNumberOfApplications', {}, 'Number of applications')]: record.numberOfApplications,
        [this.i18n.translate('GLOBAL:hdrTotalRequestAmount', {}, 'Total Request Amount')]: record.amountRequested,
        [this.i18n.translate('GLOBAL:textNumberOfAwards', {}, 'Number of awards')]: record.numberOfAwards,
        [this.i18n.translate('GLOBAL:textTotalAwardsAmount', {}, 'Total awards amount')]: record.amountAwardedTotal,
        [this.i18n.translate('GLOBAL:textNumberOfPayments', {}, 'Number of payments')]: record.numberOfPayments,
        [this.i18n.translate('GLOBAL:textTotalPaymentsAmount', {}, 'Total payments amount')]: record.paymentsTotal,
        [this.i18n.translate('common:lblLastApplicationDate', {}, 'Last Application Date')]: this.dateService.formatDate(record.lastApplicationDate)
      };
    });

    return this.fileService.convertObjectArrayToCSVString(adapted);
  }

  /**
   *
   * @param searchText: search term
   * @param searchFilters: search filters
   * @param pageNumber: page number
   * @param rowsPerPage: rows per page
   * @returns nonprofit search results
   */
  searchPublicNonprofits (
    searchText: string,
    searchFilters: SearchFilter[] = [],
    pageNumber: number,
    rowsPerPage: number,
    allowedOrgLocations: AllowedLocation[]
  ): Promise<{ results: SearchResult[]; count: number }> {
    const sanitizedSearchTerm = this.sanitizeRegId(searchText);

    return this.nonprofitResources.searchPublicNonprofits(
      sanitizedSearchTerm,
      searchFilters,
      pageNumber,
      rowsPerPage,
      allowedOrgLocations?.length > 0 ?
        false :
        true
    );
  }

  /**
   *
   * @param term: search term
   * @param page: page number
   * @param pageSize: page size
   * @param clientId: client ID
   * @param bucketId: bucket ID
   * @param filters: filters
   * @returns nonprofit bucket search results
   */
  searchNonprofitsWithinBucket (
    term: string,
    page: number,
    pageSize: number,
    clientId: number,
    bucketId: string,
    filters?: {
      state: string;
      country: string;
    }
  ) {
    const sanitizedTerm = this.sanitizeRegId(term);

    return this.nonprofitResources.searchNonprofitsWithinBucket(
      sanitizedTerm,
      page,
      pageSize,
      clientId,
      bucketId,
      filters
    );
  }

  /**
   *
   * @param clientId: client ID
   * @param paginationOptions: pagination options
   * @returns nonprofit private search results
   */
  searchPrivateOrgs (
    clientId: number,
    paginationOptions: PaginationOptions<SearchResult>
  ) {
    return this.nonprofitResources.searchPrivateOrgs(clientId, paginationOptions);
  }

  /**
   *
   * @param nonprofitGuid: nonprofit guid
   * @returns the vetting status for nonprofit
   */
  getVettingStatus (nonprofitGuid: string) {
    return this.nonprofitResources.getVettingStatus(nonprofitGuid);
  }

  /**
   *
   * @param payload: payload to add or vet org
   * @returns org ID
   */
  vetOrganization (payload: AddOrgAPI.VetOrgPayload): Promise<number> {
    return this.nonprofitResources.vetOrganization(payload);
  }

  createPrivateOrg (payload: AddOrgAPI.AddNonprofitModel): Promise<number> {
     return this.nonprofitResources.createPrivateOrg(payload);
  }

  /**
   *
   * @param regId: the search term
   * @returns the sanitized search term
   */
  sanitizeRegId (
    regId: string,
    allowDashes = false
  ) {
    const regex = allowDashes ? /[^a-zA-Z0-9- ]/g : /[^a-zA-Z0-9 ]/g;

    return regId.replace(regex, '');
  }

  /**
   *
   * @param org: org info
   * @returns charity ID and nonprofit detail
   */
  async getOrgId (org: OrgUnion): Promise<{
    charityId: string;
    details: NonprofitDetail;
  }> {
    let guid;
    let id;
    if ('document' in org) {
      guid = org.document.nonprofitGuid;
      id = org.document.id;
    } else if ('nonprofitGuid' in org) {
      guid = org.nonprofitGuid;
      id = org.id;
    } else if ('uniqueGuid' in org) {
      guid = org.uniqueGuid;
      id = org.id;
    }
    if (guid) {
      const charityId = await this.getIdByNonprofitGuid(
        guid
      );
      const details = await this.getNonprofitAdditionalDataByGuid(
        guid
      );

      return {
        charityId,
        details: details.nonprofitDetail
      };
    }

    return {
      charityId: id as string,
      details: null
    };
  }

  /**
   *
   * @param org: the org
   * @param isPrivate: is it private?
   * @param useBucketSearch: is it from bucket search?
   * @returns the adapted org
   */
  async getAdaptedOrg (
    org: OrgUnion,
    isPrivate: boolean,
    useBucketSearch: boolean
  ): Promise<{
    adaptedOrg: AdaptedNppSearchResult;
    nonprofitDetail: NonprofitDetail;
  }> {
    let id: string = null;
    let nonprofitDetail: NonprofitDetail;
    let adaptedOrg: SearchResult;
    let addressString = '';
    let address1; let address2; let city; let stateProvRegCode; let country; let postalCode; let isInternational;
    if (!isPrivate) {
      const response = await this.getOrgId(org);
      id = response.charityId;
      if (response.details) {
        nonprofitDetail = response.details;
        isInternational = nonprofitDetail?.registrationAuthorityId !== 1;
        const address = this.getNonprofitAddressFields(response.details);
        address1 = address.address1;
        address2 = address.address2;
        city = address.city;
        stateProvRegCode = address.stateProvRegCode;
        country = address.country;
        postalCode = address.postalCode;
        addressString = this.addressFormatter.format(address, true);
      }
    }
    if (useBucketSearch && 'location' in org) {
        adaptedOrg = SearchResult.construct<SearchResult>({
          text: org.name,
          document: {
            id,
            registrationId: org.registrationNumber,
            name: org.name,
            classification: null,
            addressString: addressString || org.location.address,
            iconURL: org.imageUrl,
            displayName: org.name,
            address1,
            address2,
            city,
            postalCode,
            country,
            stateProvRegCode,
            nonprofitGuid: org.uniqueGuid,
            eligibleForGivingStatusId: nonprofitDetail?.eligibleForGivingStatusId ||
              OrganizationEligibleForGivingStatus.INELIGBLE,
            isInternational
          }
        });
    } else if ('document' in org) {
      // npp int search
      id = id || org.document.id;
      adaptedOrg = SearchResult.construct<SearchResult>({
        text: org.document.name,
        document: {
          ...org.document,
          id,
          name: org.document.name,
          classification: org.document.classification,
          addressString: addressString ||
            this.addressFormatter.format(org.document),
          iconURL: org.document.iconURL,
          displayName: org.document.name,
          address1: address1 || org.document.address1,
          address2: address2 || org.document.address2,
          city: city || org.document.city,
          postalCode: postalCode || org.document.postalCode,
          country: country || org.document.country,
          stateProvRegCode: stateProvRegCode || org.document.stateProvRegCode,
          phone: org.document.phone,
          icon: org.document.icon,
          registrationId: org.document.registrationId,
          website: org.document.website,
          eligibleForGivingStatusId: org.document.eligibleForGivingStatusId ||
            OrganizationEligibleForGivingStatus.INELIGBLE,
          isInternational,
          nonprofitGuid: org.document.nonprofitGuid,
          parentName: org.document.parentName,
          parentGuid: org.document.parentGuid
        }
      });
    } else if ('address' in org) {
      adaptedOrg = SearchResult.construct<SearchResult>({
        text: org.name,
        document: {
          id: id || '' + org.id,
          addressString: addressString ||
            this.addressFormatter.format({
              address1: org.address,
              address2: org.address2,
              city: org.city,
              stateProvRegCode: org.state,
              country: org.country,
              postalCode: org.postalCode
            }, true),
          iconURL: org.imageUrl,
          name: org.name,
          displayName: org.name,
          address1: address1 || org.address,
          address2: address2 || org.address2,
          city: city || org.city,
          postalCode: postalCode || org.postalCode,
          country: country || org.country,
          stateProvRegCode: stateProvRegCode || org.state,
          registrationId: org.orgIdentification,
          eligibleForGivingStatusId: org.eligibleForGivingStatusId ||
            OrganizationEligibleForGivingStatus.INELIGBLE,
          isInternational,
          nonprofitGuid: org.nonprofitGuid
        }
      });
    }

    return {
      adaptedOrg: this.adaptNonprofitSearchResult(adaptedOrg),
      nonprofitDetail
    };
  }

  /**
   *
   * @param stateProvRegCode: state prov reg code
   * @param countryCode: country code
   * @param processorType: processor type
   * @param allowedOrgLocations: Locations to filter search by
   * @returns the adapted search filters
   */
  prepSearchFilters (
    stateProvRegCode: string,
    countryCode: string,
    processorType: ProcessingTypes,
    allowedOrgLocations: AllowedLocation[]
  ) {
    const searchFilters: SearchFilter[] = [];
    if (allowedOrgLocations?.length > 0) {
      allowedOrgLocations.forEach((location) => {
        const _countryCode = this.countriesService.countryIdToCountryMap[location.countryId].countryCode;
        if (location.regionIds.length > 0) {
          location.regionIds.forEach((regionId) => {
            searchFilters.push({
              filterName: 'CountryCode',
              filterValue: _countryCode,
              filterAnd: true,
              searchFilters: [{
                filterName: 'StateProvRegCode',
                filterValue: this.countriesService.regionIdToRegionMap[
                  regionId
                ].regionCode
              }]
            });
          });
        } else {
          searchFilters.push({
            filterName: 'CountryCode',
            filterValue: _countryCode
          });
        }
      });
    } else {
      // If not using the allowed org locations, use the singular country/state filter
      if (stateProvRegCode && stateProvRegCode !== '') {
        searchFilters.push({
          filterName: 'StateProvRegCode',
          filterValue: stateProvRegCode
        });
      }

      if (!!countryCode) {
        searchFilters.push({
          filterName: 'Country',
          filterValue: this.countriesService.countryCodeToNameMap[countryCode].toUpperCase()
        });
      }
    }

    const isYcProcessed = processorType === ProcessingTypes.YourCause;
    const hasInternational = this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.HasInternational);
    // if international, searchFilters stay as is, if not, add filter for domestic
    if (
      !hasInternational &&
      this.clientSettingsService.domesticRegAuthFilters
    ) {
      this.clientSettingsService.domesticRegAuthFilters.forEach((regAuthFilter) => {
        const isEligible = !isYcProcessed ||
          !this.isRegAuthInvalidForYcProcessing(regAuthFilter.filterValue as string);
        if (isEligible) {
          searchFilters.push(regAuthFilter);
        }
      });
    }

    // if YC Processing, only return ELIGIBLE or INFO_REQUIRED for organizationEligibleForGivingStatus
    if (isYcProcessed) {
      searchFilters.push(
        {
          filterName: 'EligibleForGivingStatusId',
          filterValue: OrganizationEligibleForGivingStatus.ELIGIBLE + ''
        },
        {
          filterName: 'EligibleForGivingStatusId',
          filterValue: OrganizationEligibleForGivingStatus.INFO_REQUIRED + ''
        }
      );
      this.appendInvalidRegAuthorityFilters(searchFilters);
    }

    return searchFilters;
  }

  /**
   * Appends the Invalid Registration Authorities as Filters
   *
   * @param searchFilters: Search Filters
   */
  appendInvalidRegAuthorityFilters (
    searchFilters: SearchFilter[]
  ) {
    NonYcEligibleRegAuthNames.forEach((regAuthName) => {
      searchFilters.push({
        filterName: 'RegistrationAuthority_ne',
        filterValue: regAuthName
      });
    });
  }

  /**
   * Is the org eligible for disbursement by YourCause?
   *
   * @param organizationEligibleForGivingStatus: Org Eligible for Giving Status
   * @param registrationAuthorityName: Registration Authority Name
   * @returns if the Org is eligible for disbusrement by YourCause
   */
  isEligibleForGivingByYc (
    organizationEligibleForGivingStatus: OrganizationEligibleForGivingStatus,
    registrationAuthorityName: string
  ) {
    return organizationEligibleForGivingStatus === OrganizationEligibleForGivingStatus.ELIGIBLE &&
      !this.isRegAuthInvalidForYcProcessing(registrationAuthorityName);
  }

  /**
   * Is the given Registration Authority valid for disbursement by YourCause?
   *
   * @param registrationAuthorityName: Registration Authority Name
   * @returns if the Registration Authority is valid for disbursement by YourCause
   */
  isRegAuthInvalidForYcProcessing (registrationAuthorityName: string) {
    return NonYcEligibleRegAuthNames.includes(registrationAuthorityName);
  }

  /**
   * Is the organization eligible to apply for an application with a YC budget?
   *
   * @param eligibleForGivingStatusId: Org's Eligible For Giving Status
   * @param registrationAuthorityName: Org's Registration Authority Name
   * @param latestVettingStatus: Latest Vetting Request Status for this Org
   * @returns if the org can create an application towards a YC processed cycle
   */
  isOrgEligibleToApplyForYcProcessing (
    eligibleForGivingStatusId: OrganizationEligibleForGivingStatus,
    registrationAuthorityName: string,
    latestVettingStatus: VettingRequestStatusId
  ) {
    const isEligibleForGiving = [
      OrganizationEligibleForGivingStatus.INFO_REQUIRED,
      OrganizationEligibleForGivingStatus.ELIGIBLE
    ].includes(eligibleForGivingStatusId);
    const isRegAuthEligible = !this.isRegAuthInvalidForYcProcessing(registrationAuthorityName);
    const isVettingDeclined = !!latestVettingStatus && [
      VettingRequestStatusId.DECLINED_ED,
      VettingRequestStatusId.DECLINED_STANDARD
    ].includes(latestVettingStatus);

    return isRegAuthEligible && isEligibleForGiving && !isVettingDeclined;
  }

  /**
   *
   * @param pageNumber: page number
   * @param searchTerm: search term
   * @param rowsPerPage: rows per page
   * @param stateProvRegCode: state prov reg code
   * @param countryCode: country code
   * @param processorType: processor
   * @param allowedOrgLocations: Locations to filter by
   * @returns the public search results
   */
  async doSearchPublic (
    pageNumber: number,
    searchTerm: string,
    rowsPerPage: number,
    stateProvRegCode: string,
    countryCode: string,
    processorType: ProcessingTypes,
    allowedOrgLocations: AllowedLocation[]
  ): Promise<APIResult<AdaptedNppSearchResult>> {
    const searchFilters = this.prepSearchFilters(
      stateProvRegCode,
      countryCode,
      processorType,
      allowedOrgLocations
    );
    if (searchTerm) {
      const response = await this.searchPublicNonprofits(
        searchTerm,
        searchFilters,
        pageNumber,
        rowsPerPage,
        allowedOrgLocations
      );

      return {
        success: true,
        data: {
          records: response.results.map((org) => {
            return this.adaptNonprofitSearchResult(org);
          }),
          recordCount: response.count
        }
      };
    }

    return {
      success: true,
      data: {
        records: [],
        recordCount: 0
      }
    };
  }

  adaptNonprofitSearchResult (org: SearchResult): AdaptedNppSearchResult {
    let eligibleForGivingStatusId = org.document.eligibleForGivingStatusId;
    const isEligibleForGiving = this.isEligibleForGivingByYc(
      eligibleForGivingStatusId,
      org.document.registrationAuthority
    );
    if (eligibleForGivingStatusId === OrganizationEligibleForGivingStatus.ELIGIBLE && !isEligibleForGiving) {
      // Account for Reg Auth not valid for giving
      eligibleForGivingStatusId = OrganizationEligibleForGivingStatus.INELIGBLE;
    }

    return {
      ...org,
      name: org.document.displayName || org.document.name,
      id: org.document.id ? +org.document.id : null,
      parentName: org.document.parentName,
      parentId: org.document.parentId ? + org.document.parentId : null,
      parentGuid: org.document.parentGuid,
      address: this.addressFormatter.formatSimpleGrantsAddressToSingleLine({
        address: org.document.address1,
        address2: org.document.address2,
        city: org.document.city,
        state: org.document.stateProvRegCode,
        country: org.document.country,
        postalCode: org.document.postalCode
      }),
      registrationId: org.document.registrationId,
      iconURL: org.document.iconURL,
      classification: org.document.classification,
      eligibleForGivingStatusId,
      isPrivateOrg: org.document.isPrivateOrg,
      isBucketOrg: false,
      addressObj: null
    };
  }

  /**
   *
   * @param pageNumber: page number
   * @param searchTerm: search term
   * @param stateProvRegCode: state prov reg code
   * @param countryCode: country code
   * @param rowsPerPage: rows per apge
   * @param clientId: client ID
   * @param charityBucketId: charity bucket ID
   * @returns the bucket search results
   */
  async doBucketSearch (
    pageNumber: number,
    searchTerm: string,
    stateProvRegCode: string,
    countryCode: string,
    rowsPerPage: number,
    clientId: number,
    charityBucketId: string
  ): Promise<APIResult<BucketSearchResultObj&SearchResultNormalized>> {
    const filters = {
      state: stateProvRegCode,
      country: countryCode
    };
    if (countryCode === 'UK') {
      filters.country = 'GB';
    }
    let bucketOrgsResponse: BucketSearchAPIResponse;
    if (!!searchTerm) {
      bucketOrgsResponse = await this.searchNonprofitsWithinBucket(
        searchTerm,
        pageNumber,
        rowsPerPage,
        clientId,
        charityBucketId,
        filters
      );
    } else {
      bucketOrgsResponse = {
        totalCount: 0,
        pageCount: 1,
        page: 1,
        pageSize: rowsPerPage,
        message: '',
        data: []
      };
    }

    return {
      success: true,
      data: {
        records: bucketOrgsResponse.data.map((org) => {
          return {
            ...org,
            parentName: '',
            parentId: null,
            parentGuid: '',
            address: org.location.address,
            registrationId: org.registrationNumber,
            iconURL: org.imageUrl,
            classification: '',
            eligibleForGivingStatusId: null,
            isPrivateOrg: false,
            isBucketOrg: true
          };
        }),
        recordCount: bucketOrgsResponse.totalCount
      }
    };
  }

  /**
   *
   * @param pageNumber: page number
   * @param searchTerm: search term
   * @param rowsPerPage: rows per page
   * @param processorType: processor
   * @param stateProvRegCode: state prov reg code
   * @param country: country
   * @param clientId: client ID
   * @returns the private search results
   */
  async doSearchPrivate (
    pageNumber: number,
    searchTerm: string,
    rowsPerPage: number,
    processorType: ProcessingTypes,
    stateProvRegCode: string,
    country: string,
    clientId: number,
    programId: number
  ): Promise<APIResult<OrganizationSearchResponse&SearchResultNormalized>> {
    let privateOrgsApi: APIResult<OrganizationSearchResponse&SearchResultNormalized>;
    if (searchTerm) {
      const paginationOptions = this.getPaginationOptionsForPrivateSearch(
        searchTerm,
        pageNumber,
        rowsPerPage
      );
      const data = await this.nonprofitResources.searchPrivateOrgsWithFilters(
        paginationOptions,
        programId,
        country,
        stateProvRegCode,
        processorType,
        clientId
      );
      privateOrgsApi = {
        success: true,
        data: {
          records: data.records.map((org) => {
            return {
              ...org,
              parentName: '',
              parentId: null,
              parentGuid: '',
              address: this.addressFormatter.formatSimpleGrantsAddressToSingleLine(org),
              registrationId: org.orgIdentification,
              iconURL: org.imageUrl,
              classification: '',
              eligibleForGivingStatusId: org.eligibleForGivingStatusId,
              isPrivateOrg: org.isPrivateOrg,
              isBucketOrg: false
            };
          }),
          recordCount: data.recordCount
        }
      };
    } else  {
      privateOrgsApi = {
        success: true,
        data: {
          records: [],
          recordCount: 0
        }
      };
    }

    return privateOrgsApi;
  }

  /**
   * Get Reg ID - OR filters
   *
   * @param regId: Reg ID
   * @returns the OR filters for the ID with a dash and without
   */
  getOrgRegIdOrFilters (regId: string) {
    return {
      columnName: 'orgIdentification',
      // To try to find all variations of the reg ID, search with and without the dash "-"
      filters: [{
        filterType: 'cn',
        filterValue: this.sanitizeRegId(regId, true)
      }, {
        filterType: 'cn',
        filterValue: this.sanitizeRegId(regId, false)
      }]
    };
  }

  /**
   *
   * @param searchTerm: search term
   * @param pageNumber: page number
   * @param rowsPerPage: rows per page
   */
  getPaginationOptionsForPrivateSearch (
    searchTerm: string,
    pageNumber: number,
    rowsPerPage: number
  ) {
    const paginationOptions: PaginationOptions<OrganizationSearchResponse&SearchResultNormalized> = {
      retrieveTotalRecordCount: true,
      filterColumns: [],
      orFilterColumns: [{
        columnName: 'name',
        filters: [{
          filterType: 'cn',
          filterValue: searchTerm
        }]
      }, {
        ...this.getOrgRegIdOrFilters(searchTerm)
      }],
      sortColumns: [],
      returnAll: false,
      pageNumber,
      rowsPerPage
    };

    return paginationOptions;
  }

  async constructSearchResult (app: ApplicationForUi) {
    if (app.organizationId) {
      let address1; let address2; let city; let stateProvRegCode; let country; let postalCode; let addressString; let parentName; let parentGuid; let eligibleForGivingStatusId;
      if (app.nonprofitGuid) {
        const response = await this.getNonprofitAdditionalDataByGuid(
          app.nonprofitGuid
        );

        if (response.nonprofitDetail) {
          eligibleForGivingStatusId = response.nonprofitDetail.eligibleForGivingStatusId;
          const address = this.getNonprofitAddressFields(
            response.nonprofitDetail
          );
          address1 = address.address1;
          address2 = address.address2;
          city = address.city;
          stateProvRegCode = address.stateProvRegCode;
          country = address.country;
          postalCode = address.postalCode;
          addressString = this.addressFormatter.format(
            address,
            true
          );
          parentName = response.nonprofitDetail.parentNonprofitName;
          parentGuid = response.nonprofitDetail.parentNonprofitGuid;
        }
      } else {
        const response = await this.getCompanyOrgDetail(app.organizationId);
        address1 = response.address;
        address2 = response.address2;
        city = response.city;
        stateProvRegCode = response.state;
        postalCode = response.postalCode;
        country = response.country;
      }

      return SearchResult.construct<SearchResult>({
        text: app.organizationName,
        document: {
          id: '' + app.organizationId,
          addressString: addressString || app.organizationAddress,
          iconURL: app.orgnizationImageUrl,
          name: app.organizationName,
          displayName: app.organizationName,
          address1,
          address2,
          city,
          stateProvRegCode,
          country,
          postalCode,
          registrationId: '' + app.organizationIdentification,
          isInternational: app.orgIsInternational,
          parentName,
          parentGuid,
          eligibleForGivingStatusId
        } as SearchResultObj
      });
    }

    return null;
  }

  getSubsectionCodeOptions () {
    const options: TypeaheadSelectOption[] = [];
    const subsectionCodeMap = SubsectionCodeMap as Record<string, string>;
    Object.keys(subsectionCodeMap).forEach((key) => {
      if (!!subsectionCodeMap[key]) {
        options.push({
          label: subsectionCodeMap[key],
          value: key
        });
      }
    });

    return this.arrayHelper.sort(options, 'label');
  }

  /**
   * Get the Display text for a Vetting Status ID
   *
   * @param vettingStatusId: Vetting Status ID
   * @returns the vetting status display text
   */
  getVettingStatusText (vettingStatusId: VettingRequestStatusId) {
    const statuses = this.getVettingStatusOptions();
    const foundStatus = statuses.find((status) => status.value === vettingStatusId);

    return foundStatus.label;
  }

  /**
   * Get display text for vetting rejection reason
   *
   * @param rejectionReasonId: Rejection Reason ID
   * @returns the translated text for the given reason
   */
  getVettingRejectionReasonText (rejectionReasonId: VettingRejectionReason) {
    switch (rejectionReasonId) {
      case VettingRejectionReason.Cancelled:
        return this.i18n.translate(
          'nonprofit:textVettingRequestCanceled',
          {},
          'The vetting request was canceled.'
        );
      case VettingRejectionReason.CancelledBecauseNPOWasDeactivated:
        return this.i18n.translate(
          'nonprofit:textVettingRequestCanceledBecauseOrgDeactivated',
          {},
          'The vetting request was canceled because the organization was deactivated.'
        );
      case VettingRejectionReason.NotAValidOrganization:
        return this.i18n.translate(
          'nonprofit:textOrgNotValid',
          {},
          'The organization is not valid.'
        );
      case VettingRejectionReason.DoesNotQualify:
        return this.i18n.translate(
          'nonprofit:textOrgDoesNotQualify',
          {},
          'The organization does not qualify.'
        );
      case VettingRejectionReason.NotInterestedInCompletingVettingApplication:
        return this.i18n.translate(
          'nonprofit:textOrgNotInterestedInCompletingVettingApp',
          {},
          'The organization was not interested in completing the vetting application.'
        );
      case VettingRejectionReason.CancelledBecauseGrantsConnectApplicationWasDeclined:
        return this.i18n.translate(
          'nonprofit:textVettingRequestCanceledAppDeclined',
          {},
          `The vetting request was canceled because the organization's application was declined.`
        );
      case VettingRejectionReason.Other:
        return this.i18n.translate(
          'common:textOther',
          {},
          'Other'
        );
      case VettingRejectionReason.NoResponseFromNonprofit:
        return this.i18n.translate(
          'nonprofit:textVettingRequestCanceledNoResponse',
          {},
          'The vetting request was canceled because the organization did not respond.'
        );
      case VettingRejectionReason.InvalidNonprofitContactDetailsProvided:
        return this.i18n.translate(
          'nonprofit:textVettingRequestCanceledInvalidContactDetails',
          {},
          'The vetting request was canceled because invalid organization contact details were provided.'
        );
      case VettingRejectionReason.VettingRequestNoLongerRelevantDueToEligibilityChange:
        return this.i18n.translate(
          'nonprofit:textVettingRequestCanceledEligibility',
          {},
          'The vetting request was canceled because it is no longer relavant due to eligility changes.'
        );
      case VettingRejectionReason.ClientDeclinedToPayForVetting:
        return this.i18n.translate(
          'nonprofit:textVettingRequestCanceledClientDeclinedToPay',
          {},
          'The vetting request was canceled because the client declined to pay for vetting.'
        );
      case VettingRejectionReason.CancelledOptOutOfVetting:
        return this.i18n.translate(
          'nonprofit:textOrgOptedOutOfVetting',
          {},
          'Nonprofit organization has opted out of being vetted.'
        );
      case VettingRejectionReason.CancelledBecauseVettingAppWasInDraftorHoldTooLong:
        return this.i18n.translate(
          'nonprofit:textInDraftOrOnHoldTooLong',
          {},
          'Cancelled because vetting application was in Draft or OnHold status for too long.'
        );
      case VettingRejectionReason.EmailToNonprofitContactHardBounced:
        return this.i18n.translate(
          'nonprofit:textEmailToNonprofitContactHardBounced',
          {},
          'Email to nonprofit contact hard bounced.'
        );
      case VettingRejectionReason.NonprofitContactOptedOut:
        return this.i18n.translate(
          'nonprofit:textNonprofitContactOptedOutOfOutreach',
          {},
          'Associated nonprofit contact opted out of outreach.'
        );
    }
  }

  /**
   * Should we show the vetting alert?
   *
   * @param eligibleforGivingStatus: Eligible for Giving Status
   * @param registrationAuthorityName: Reg Auth Name
   * @param isNomination: Is this for a nomination?
   * @param isClientProcessing: Is the client processing?
   * @param applicantApplying: Is this for an individual applicant applying? (no org)
   * @param paymentStatus: Payment status if this is in relation to a payment
   * @param isForApplicantPortal: Are we displaying this in the applicant portal?
   * @returns whether or not the alert should show
   */
  shouldShowVettingAlert (
    eligibleforGivingStatus: OrganizationEligibleForGivingStatus,
    registrationAuthorityName: string,
    isNomination: boolean,
    isClientProcessing: boolean,
    applicantApplying: boolean,
    paymentStatus: PaymentStatus, // passed only if this alert relates to a payment
    isForApplicantPortal: boolean
  ) {
    if (isForApplicantPortal) {
      return false;
    }
    if (paymentStatus) {
      const paymentPending = [PaymentStatus.Scheduled, PaymentStatus.Pending].includes(
        paymentStatus
      );
      // If the payment is already processed this message does not apply
      if (!paymentPending) {
        return false;
      }
    }
    if (applicantApplying && !isClientProcessing) {
      return true;
    } else {
      if (isClientProcessing || isNomination) {
        return false;
      } else {
        const notEligibleForGiving = eligibleforGivingStatus !== OrganizationEligibleForGivingStatus.ELIGIBLE;
        const regAuthInvalid = this.isRegAuthInvalidForYcProcessing(registrationAuthorityName);

        return notEligibleForGiving || regAuthInvalid;
      }
    }
  }

  /**
   * Get the Vetting Request Text to Display
   *
   * @param latestVettingRequestStatusForOrg: Vetting Request Status
   * @param rejectionReasonId: Rejection Reason ID
   * @param vettingRequestComment: Vetting Request Comment
   * @param eligibleforGivingStatus: Org's Eligible for Giving Status
   * @param registrationAuthorityName: Org's Registration Authority Name
   * @param applicantApplying: Is this for an individual applicant applying? (no org)
   * @param includeExtraDetails: Include Extra Details such as vetting status, rejection reason, and comment
   * @returns the vetting request text to display
   */
  getVettingAlertText (
    latestVettingRequestStatusForOrg: VettingRequestStatusId,
    rejectionReasonId: VettingRejectionReason,
    vettingRequestComment: string,
    eligibleforGivingStatus: OrganizationEligibleForGivingStatus,
    registrationAuthorityName: string,
    applicantApplying = false,
    includeExtraDetails = true
  ) {
    let returnText = '';
    const vettingPending = !!latestVettingRequestStatusForOrg && [
      VettingRequestStatusId.IN_PROGRESS,
      VettingRequestStatusId.ON_HOLD_PENDING_BILLING,
      VettingRequestStatusId.REQUEST_SUBMITTED,
      VettingRequestStatusId.ROUTED_TO_VETTING_AUTHORITY
    ].includes(latestVettingRequestStatusForOrg);
    if (applicantApplying) {
      returnText = this.i18n.translate(
        'common:textBBGFVettingIndividual',
        {},
        'Programs benefitting individuals are ineligible for disbursement by YourCause. Payments can only be created and processed using client processed budgets and funding sources.'
      );
    } else {
      const ineligibleForDisbursementText = this.i18n.translate(
        'GLOBAL:textBBGFVettingRequestDeclined2',
        {},
        'The organization associated with this application is ineligible for disbursement by YourCause. Payments can only be created and processed using client processed budgets and funding sources.'
      );
      if (this.isRegAuthInvalidForYcProcessing(registrationAuthorityName)) {
        returnText = ineligibleForDisbursementText;
      } else {
        switch (eligibleforGivingStatus) {
          case OrganizationEligibleForGivingStatus.UNKNOWN:
          case OrganizationEligibleForGivingStatus.INELIGBLE:
          default:
            returnText = ineligibleForDisbursementText;
            break;
          case OrganizationEligibleForGivingStatus.INFO_REQUIRED:
            if (vettingPending) {
              returnText = this.i18n.translate(
                'GLOBAL:textBBGFVettingRequested2',
                {},
                'An outstanding vetting request has been created for the organization associated with this application. Payments can only be created and processed using client processed budgets and funding sources.'
              );
            } else {
              returnText = ineligibleForDisbursementText;
            }
            break;
          case OrganizationEligibleForGivingStatus.ELIGIBLE:
            return '';
          }
      }
    }
    if (includeExtraDetails) {
      if (!!latestVettingRequestStatusForOrg) {
        const statusText = this.i18n.translate('nonprofit:textVettingStatus', {}, 'Vetting status');
        const vettingStatusText = this.getVettingStatusText(latestVettingRequestStatusForOrg);
        returnText = `
          ${returnText}
          <br>
          <strong>${statusText}</strong>: ${vettingStatusText}
        `;
      }
      if (!!rejectionReasonId) {
        const reasonText = this.i18n.translate('common:textReason', {}, 'Reason');
        const rejectionReasonText = this.getVettingRejectionReasonText(rejectionReasonId);
        returnText = `
          ${returnText}
          <br>
          <strong>${reasonText}</strong>: ${rejectionReasonText}
        `;
      }
      if (!!vettingRequestComment) {
        const commentText = this.i18n.translate('common:labelComment', {}, 'Comment');
        returnText = `
          ${returnText}
          <br>
          <strong>${commentText}</strong>: ${vettingRequestComment}
        `;
      }
    }

    return returnText;
  }

  /**
   *
   * @returns Vetting Status Options
   */
  getVettingStatusOptions (): TypeaheadSelectOption[] {
    return [{
      label: this.i18n.translate('GLOBAL:textApproved', {}, 'Approved'),
      value: VettingRequestStatusId.APPROVED_ED
    }, {
      label: this.i18n.translate('GLOBAL:textApproved', {}, 'Approved'),
      value: VettingRequestStatusId.APPROVED_STANDARD
    }, {
      label: this.i18n.translate('GLOBAL:textDeclined', {}, 'Declined'),
      value: VettingRequestStatusId.DECLINED_ED
    }, {
      label: this.i18n.translate('GLOBAL:textDeclined', {}, 'Declined'),
      value: VettingRequestStatusId.DECLINED_STANDARD
    }, {
      label: this.i18n.translate('common:textRequestSubmitted', {}, 'Request submitted'),
      value: VettingRequestStatusId.REQUEST_SUBMITTED
    }, {
      label: this.i18n.translate('GLOBAL:lblInProgress', {}, 'In progress'),
      value: VettingRequestStatusId.IN_PROGRESS
    }, {
      label: this.i18n.translate('GLOBAL:textCanceled', {}, 'Canceled'),
      value: VettingRequestStatusId.CANCELLED
    }, {
      label: this.i18n.translate('common:textRoutedToVettingAuthority', {}, 'Routed to vetting authority'),
      value: VettingRequestStatusId.ROUTED_TO_VETTING_AUTHORITY
    }, {
      label: this.i18n.translate('common:textOnHoldPendingBilling', {}, 'On hold - pending billing'),
      value: VettingRequestStatusId.ON_HOLD_PENDING_BILLING
    }];
  }

  /**
   * Check if a client has private orgs
   *
   * @param clientId: Client ID
   * @returns if the client has any private orgs
   */
  async checkIfClientHasPrivateOrgs (clientId: number) {
    const result = await this.searchPrivateOrgs(clientId, {
      returnAll: false,
      retrieveTotalRecordCount: true,
      filterColumns: [],
      sortColumns: [],
      rowsPerPage: 1,
      pageNumber: 1
    });

    return result.recordCount > 0;
  }
}
