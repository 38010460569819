import { FormAudience, FormDefinitionComponent, FormDefinitionForUi } from '@features/configure-forms/form.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { ComponentToViewOrEdit } from './form-builder.typing';

@RegisterYCState()
export class FormBuilderState extends BaseYcState {
  readonly copiedComponent: FormDefinitionComponent;
  readonly inFormBuilder: boolean;
  readonly currentFormBuilderFormId: number;
  readonly currentFormBuilderFormAudience: FormAudience;
  readonly currentFormBuilderDefinition: FormDefinitionForUi[];
  readonly currentFormBuilderIndex: number;
  readonly openQuickAddModal: boolean;
  readonly openFormFieldImportModal: boolean;
  readonly formTabWasUpdatedForGoToComp: boolean;

  // Populated when a component on the form is selected from the toolbox
  // This will:
  // 1. take the user to the selected component
  // 2. Open the component config if isEdit is true
  readonly componentToViewOrEdit: ComponentToViewOrEdit;
}
