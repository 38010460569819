import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from '@yourcause/common/logging';


@Injectable()
export class ErrorHandlerService extends ErrorHandler {
   constructor (
    private logger: LogService
  ) {
    super();
  }
  handleError (error: any) {
    if (error.message.startsWith('ExpressionChangedAfterItHasBeenCheckedError')) {
      return;
    }
    this.logger.error(error);

    super.handleError(error);
  }
}
