import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { FormFromApi, SaveForm, SaveNewFormResponse } from '@features/configure-forms/form.typing';


@Injectable({ providedIn: 'root' })
export class FormLogicResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getForm (formId: number, revisionId: number): Promise<FormFromApi> {
    return this.httpRestService.get(
      'api/manager/forms/' + formId + '/revisions/' + revisionId
    );
  }

  saveForm (data: SaveForm): Promise<SaveNewFormResponse> {
    return this.httpRestService.post('api/manager/forms', data);
  }

  updateRevision (
    formId: number,
    revisionId: number,
    data: SaveForm
  ) {
    const endpoint = `api/manager/forms/${formId}/revisions/${revisionId}`;

    return this.httpRestService.post(endpoint, data);
  }
}

