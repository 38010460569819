<div *ngFor="let email of emails"
  class="email-wrapper">
  <!-- Left Side -->
  <div class="left-side">
    <!-- Email ID -->
    <div>
      <yc-i18n
        key="common:textEmailId"
        defaultValue="Email ID">
      </yc-i18n>
    </div>
    <div class="email-info">
      {{ email.emailNotificationName }}
    </div>

    <!-- Title -->
    <div>
      <yc-i18n
        key="common:labelSubject"
        defaultValue="Subject">
      </yc-i18n>
    </div>
    <div class="email-info">
      {{ email.subject }}
    </div>

    <!-- Recipient -->
    <div *ngIf="(email | ycAs: $applicationEmailPdfType).toName">
      <yc-i18n
        key="PROGRAM:textRecipient"
        defaultValue="Recipient">
      </yc-i18n>
    </div>
    <div class="email-info">
      <div>
        {{ (email | ycAs: $applicationEmailPdfType).toName }}
      </div>
      <div>
        {{ (email | ycAs: $applicationEmailPdfType).toAddress }}
      </div>
    </div>

    <!-- Date Sent -->
    <div *ngIf="(email | ycAs: $applicationEmailPdfType).sentTime">
      <yc-i18n
        key="common:textDateSent"
        defaultValue="Date sent">
      </yc-i18n>
    </div>
    <div class="email-info">
      {{ (email | ycAs: $applicationEmailPdfType).sentTime }}
    </div>

    <!-- Audience -->
    <div>
      <yc-i18n
        key="common:textAudience"
        defaultValue="Audience">
      </yc-i18n>
    </div>
    <div class="email-info">
      {{ email.audienceText }}
    </div>

    <!-- Description -->
    <div>
      <yc-i18n
        key="common:textDescription"
        defaultValue="Description">
      </yc-i18n>
    </div>
    <div class="email-info">
      {{ email.description }}
    </div>
  </div>


  <!-- Right Side -->
  <div class="right-side">
    <div>
      <!-- Email Header -->
      <gc-email-header-block *ngIf="emailPdfType === EmailPdfTypes.Program"
        [title]="email.subject">
      </gc-email-header-block>

      <!-- Email Body -->
      <div [innerHTML]="email.body | escapeHtml"
        [class.program-email-container]="emailPdfType === EmailPdfTypes.Program"
        [class.rich-email-content]="emailPdfType === EmailPdfTypes.Application">
      </div>
    </div>
  </div>
</div>
