import { BatchItem, InKindPaymentStats, PaymentForProcess, SimplePaymentStats } from '@core/typings/payment.typing';
import { PaginationOptions } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class PaymentProcessingState extends BaseYcState {
  readonly batchMap: {
    [x: string]: BatchItem;
  };
  readonly availablePayments: BatchItem[];
  readonly currentAvailableStats: SimplePaymentStats;
  readonly currentProgramOptions: TypeaheadSelectOption[] = [];
  readonly currentIncludedOptions: PaginationOptions<PaymentForProcess>;
  readonly inKindPaymentStats: InKindPaymentStats;
}
