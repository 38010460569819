import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { SpinnerService } from '@core/services/spinner.service';
import { TranslationService } from '@core/services/translation.service';
import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { Payment } from '@core/typings/payment.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { UserService } from '@features/users/user.service';
import { SimpleStringMap, TableRepositoryFactory } from '@yourcause/common';
import { FileService } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { uniqBy } from 'lodash';
import * as parse from 'papaparse';
import { InKindResources } from './in-kind.resources';
import { InKindState } from './in-kind.state';
import { AddEditItemModalResponse, ImportCategoryItem, InKindAwardedItemApi, InKindCategories, InKindCategoryItemStat, InKindRequestedItem } from './in-kind.typing';


@AttachYCState(InKindState)
@Injectable({ providedIn: 'root' })
export class InKindService extends BaseYCService<InKindState> {

   constructor (
    private logger: LogService,
    private inKindResources: InKindResources,
    private notifier: NotifierService,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private fileService: FileService,
    private spinnerService: SpinnerService,
    private userService: UserService,
    private tableFactory: TableRepositoryFactory,
    private translationService: TranslationService,
    private portal: PortalDeterminationService,
    private clientSettings: ClientSettingsService
  ) {
    super();
  }

  get categories () {
    return this.get('categories');
  }

  get categoryOptions () {
    return this.get('categoryOptions');
  }

  get categoriesByClientMap () {
    return this.get('categoriesByClientMap');
  }

  get categoriesForApplicant () {
    return this.get('categoriesForApplicant');
  }

  get allItemsMap () {
    return this.get('allItemsMap');
  }

  get categoryItemMap () {
    return this.get('categoryItemMap');
  }

  get allItems () {
    return this.get('allItems');
  }

  get categoriesForForm () {
    return this.get('categoriesForForm');
  }

  get applicantCategoryItemsByProgramId () {
    return this.get('applicantCategoryItemsByProgramId');
  }

  async resolveInKind () {
    await this.setCategories();
    await this.setInKindHelperInfo();
  }

  getCategoryTypeMap (): Record<InKindCategories, string> {
    return {
      [InKindCategories.Goods]: this.i18n.translate('GLOBAL:textGoods'),
      [InKindCategories.Services]: this.i18n.translate('common:textServices'),
      [InKindCategories.Space]: this.i18n.translate('GLOBAL:textSpace'),
      [InKindCategories.Other]: this.i18n.translate('common:textOther')
    };
  }

  getTypeOptions () {
    const categoryTypeMap = this.getCategoryTypeMap();

    return [{
      label: categoryTypeMap[InKindCategories.Goods],
      value: InKindCategories.Goods
    }, {
      label: categoryTypeMap[InKindCategories.Services],
      value: InKindCategories.Services
    }, {
      label: categoryTypeMap[InKindCategories.Space],
      value: InKindCategories.Space
    }, {
      label: categoryTypeMap[InKindCategories.Other],
      value: InKindCategories.Other
    }];
  }

  async setCategories () {
    if (!this.categories) {
      try {
        const hasInternational = this.clientSettings.doesClientHaveClientFeature(
          APIAdminClient.ClientFeatureTypes.HasInternational
        );
        const language = this.userService.getCurrentUserCulture();
        const fetchTranslatedCategories = hasInternational &&
          language !== this.clientSettings.defaultLanguage;

        const [
          categories,
          translatedFormCategories
        ] = await Promise.all([
          await this.inKindResources.getInKindCategories(),
          fetchTranslatedCategories ? await this.inKindResources.getInKindCategories(
            language
          ) : null
        ]);
        this.set('categories', this.arrayHelper.sort(categories.records, 'name'));
        const options = this.categories.map((category) => {
          return {
            label: category.name,
            value: category.id
          };
        });
        this.set('categoryOptions', options);
        const categoriesForForm = translatedFormCategories?.records ?? categories.records;
        this.set('categoriesForForm', this.arrayHelper.sort(categoriesForForm, 'name'));
      } catch (e) {
        this.logger.error(e);
        this.notifier.error(this.i18n.translate(
          'CONFIG:textErrorFetchingCategories',
          {},
          'There was an error fetching in-kind categories'
        ));
      }
    }
  }

  async getCategoriesForApplicant (clientId: number) {
    const result = await this.getCategoriesByClient(clientId);

    this.set('categoriesForApplicant', result);
  }

  async getCategoriesByClient (clientId: number) {
    if (!this.categoriesByClientMap[clientId]) {
      try {
        const result = await this.inKindResources.getInKindCategoriesByClient(clientId);
        this.set(
          'categoriesByClientMap',
          {
            ...this.categoriesByClientMap,
            [clientId]: result.records
          }
        );

        return this.categoriesByClientMap[clientId];
      } catch (e) {
        this.logger.error(e);
        this.notifier.error(
          this.i18n.translate(
            'CONFIG:textErrorFetchingCategories',
            {},
            'There was an error fetching in-kind categories'
          )
        );

        return null;
      }
    } else {
      return this.categoriesByClientMap[clientId];
    }
  }

  resetCategoryItemsRepo (categoryId: number) {
    const repo = this.tableFactory.getRepository(
      `CATEGORY_${categoryId}_ITEMS`
    );
    if (repo) {
      repo.reset();
    }
  }

  async resetCategories () {
    this.set('categories', undefined);
    await this.setCategories();
  }

  resetInKindState () {
    this.set('categories', undefined);
    this.set('categoryItemMap', undefined);
    this.set('allItemsMap', undefined);
    this.set('allItems', undefined);
  }

  setAllItemsMapOnState (map: SimpleStringMap<InKindCategoryItemStat>) {
    this.set('allItemsMap', map);
  }

  async resetInKindHelperInfo () {
    this.resetInKindState();
    await this.resolveInKind();
  }

  async addOrEditCategoryItem (
    categoryId: number,
    modalResponse: AddEditItemModalResponse,
    isUpdate = false
  ) {
    this.spinnerService.startSpinner();
    try {
      const id = await this.inKindResources.addOrEditCategoryItem(
        categoryId,
        modalResponse,
        isUpdate
      );
      this.notifier.success(this.i18n.translate(
        isUpdate ?
          'CONFIG:textSuccessfullyUpdatedItem' :
          'CONFIG:textSuccessfullyCreatedItem',
        {},
        isUpdate ?
          'Successfully updated the item' :
          'Successfully created the item'
      ));
      await this.resetInKindHelperInfo();
      this.resetCategoryItemsRepo(categoryId);
      const existingItem = this.allItemsMap &&
        this.allItemsMap[modalResponse.identification];
      if (isUpdate && existingItem) {
        this.setAllItemsMapOnState({
          ...this.allItemsMap,
          [modalResponse.identification]: {
            ...existingItem,
            name: modalResponse.name,
            units: modalResponse.units,
            value: modalResponse.value
          }
        });
      } else if (!isUpdate) {
        this.setAllItemsMapOnState({
          ...this.allItemsMap,
          [modalResponse.identification]: {
            inUse: true,
            identification: modalResponse.identification,
            name: modalResponse.name,
            units: modalResponse.units,
            value: modalResponse.value,
            unitsPaid: 0,
            unitsRemaining: modalResponse.units || 0,
            categoryId
          }
        });
      }
      this.spinnerService.stopSpinner();

      return id;
    } catch (err) {
      const e = err as HttpErrorResponse;
      this.logger.error(e);
      if (
        e.error &&
        e.error.message === 'An item for this client already exists with this identification.'
      ) {
        this.notifier.error(this.i18n.translate(
          'CONFIG:textDuplicateIdentificationError',
          {},
          'The identification entered already exists'
        ));
      } else {
        this.notifier.error(this.i18n.translate(
          isUpdate ?
            'CONFIG:textErrorUpdatingItem' :
            'CONFIG:textErrorCreatingItem',
          {},
          `There was an error ${
            isUpdate ? 'updating' : 'creating'
          } the item`
        ));
      }
      this.spinnerService.stopSpinner();

      return null;
    }
  }

  async createCategory (
    name: string,
    type: InKindCategories,
    defaultLanguageId: string,
    skipSuccessNotifier = false
  ) {
    this.spinnerService.startSpinner();
    try {
      const id = await this.inKindResources.addCategory(
        name,
        type,
        defaultLanguageId
      );
      await this.resetCategories();
      if (!skipSuccessNotifier) {
        this.notifier.success(this.i18n.translate(
          'CONFIG:textSuccessfullyCreatedCategory',
          {},
          'Successfully created the category'
        ));
      }
      this.spinnerService.stopSpinner();

      return id;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'CONFIG:textErrorCreatingCategory',
        {},
        'There was an error creating the category'
      ));
    }
    this.spinnerService.stopSpinner();

    return null;
  }

  getTemplateForDownload () {
    const input = 'name,identification,units,value';

    return this.fileService.downloadString(
      input,
      'text/csv',
      'template.csv'
    );
  }

  isFileValid (parsed: ImportCategoryItem[]) {
    parsed = this.removeEmptyRows(parsed);
    if (parsed && parsed.length > 0) {
      const uniq = uniqBy(parsed, 'identification');
      const duplicates = uniq.length !== parsed.length;
      const errors = parsed.filter((result) => {
        if (!result.identification) {
          return true;
        } else if (!result.name) {
          return true;
        } else if (!result.value) {
          return true;
        } else if (isNaN(result.value)) {
          return true;
        }

        return null;
      });

      return errors.length === 0 && !duplicates;
    }

    return false;
  }

  removeEmptyRows (parsed: ImportCategoryItem[]) {
    return parsed.filter((item) => {
      return !!item.name || !!item.value || !!item.identification || !!item.units;
    });
  }

  async createAndImportData (
    name: string,
    type: InKindCategories,
    file: File,
    defaultLanguageId: string
  ) {
    const id = await this.createCategory(
      name,
      type,
      defaultLanguageId,
      true
    );
    if (id) {
      this.spinnerService.startSpinner();
      await this.importData(
        id,
        file
      );
      this.spinnerService.stopSpinner();

      return id;
    } else {
      return null;
    }
  }

  async importData (id: number, file: File, importOnly = false) {
    this.spinnerService.startSpinner();
    try {
      await this.inKindResources.uploadCsvList(
        id,
        file
      );
      if (importOnly) {
        this.notifier.success(this.i18n.translate(
          'common:textSuccessfullyImportedSelectedFile2',
          {},
          'Successfully imported the selected file'
        ));
      } else {
        this.notifier.success(this.i18n.translate(
          'BUDGET:textSuccessfullyCreatedCategoryAndImported',
          {},
          'Successfully created the category and imported the selected file'
        ));
      }
      await this.resetInKindHelperInfo();

      this.resetCategoryItemsRepo(id);
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorImportingSelectedFile',
        {},
        'There was an error importing the selected file'
      ));
    }
    this.spinnerService.stopSpinner();
  }

  async exportData (
    id: number,
    languageId: string
  ) {
    this.spinnerService.startSpinner();
    const allItems = await this.inKindResources.getAllItemsByCategory(
      [id],
      languageId
    );
    const itemsToExport = allItems.filter((opt) => {
      return opt.inUse;
    }).map((item) => {
      return {
        identification: item.identification,
        name: item.name,
        value: item.value,
        units: item.units
      };
    });
    const csv = parse.unparse(itemsToExport);
    this.fileService.downloadCSV(csv);
    this.spinnerService.stopSpinner();
  }

  async toggleItemIsActive (
    categoryId: number,
    identification: string,
    toInactive = false
  ) {
    this.spinnerService.startSpinner();
    try {
      await this.inKindResources.toggleItemIsActive(
        identification,
        toInactive
      );
      this.notifier.success(this.i18n.translate(
        toInactive ?
          'CONFIG:textSuccessfullyMarkedItemAsInactive' :
          'CONFIG:textSuccessfullyMarkedItemAsActive',
        {},
        `Successfully marked item as ${toInactive ? 'inactive' : 'active'}`
      ));
      if (this.allItemsMap && this.allItemsMap[identification]) {
        this.setAllItemsMapOnState({
          ...this.allItemsMap,
          [identification]: {
            ...this.allItemsMap[identification],
            inUse: !toInactive
          }
        });
      }
      this.resetCategoryItemsRepo(categoryId);
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        toInactive ?
          'CONFIG:textErrorMarkingItemAsInactive' :
          'CONFIG:textErrorMarkingItemAsActive',
        {},
        `There was an error marking the item as ${toInactive ? 'inactive' : 'active'}`
      ));
    }
    this.spinnerService.stopSpinner();
  }

  async getItemsById (
    ids: string[],
    programId: number,
    language: string
  ) {
    if (!ids || !ids.length) {
      return [];
    }

    if (this.portal.isManager) {
      await this.resolveInKind();

      return this.allItems.filter((item) => {
        return ids.includes(item.identification);
      });
    } else if (!!programId) {
      if (!this.applicantCategoryItemsByProgramId[programId]) {
        const records = await this.inKindResources.getApplicantCategoryItems(
          programId,
          ids,
          language
        );

        const adapted = records.map<InKindCategoryItemStat>(record => {
          return {
            identification: record.itemIdentification,
            inUse: record.inUse,
            name: record.name,
            units: record.count,
            unitsPaid: null,
            unitsRemaining: null,
            unitsEntered: null,
            value: record.value,
            categoryId: record.categoryId
          };
        });
        this.set('applicantCategoryItemsByProgramId', {
          ...this.applicantCategoryItemsByProgramId,
          [programId]: adapted
        });
      }

      return this.applicantCategoryItemsByProgramId[programId];
    }

    return [];
  }

  /**
   * Sets up allItems, allItemsMap, categoryNameMap, and categoryItemMap
   */
  async setInKindHelperInfo () {
    if (!this.allItems) {
      const map: SimpleStringMap<InKindCategoryItemStat> = {};
      const allItems = await this.getAllItemsByCategoryAndSetMaps();
      this.set('allItems', allItems);
      allItems.forEach((item) => {
        map[item.identification] = item;
      });
      this.setAllItemsMapOnState(map);
    }
  }

  async getAllItemsByCategoryAndSetMaps () {
    const categoryIds = this.categories.map((cat) => {
      return cat.id;
    });
    if (categoryIds.length > 0) {
      const items = await this.inKindResources.getAllItemsByCategory(
        categoryIds,
        this.userService.getCurrentUserCulture()
      );
      categoryIds.forEach(id => {
        this.set('categoryItemMap', {
          ...this.categoryItemMap,
          [id]: []
        });
      });
      items.forEach((item) => {
        const oldMap = this.categoryItemMap;
        this.set('categoryItemMap', {
          ...oldMap,
          [item.categoryId]: [
            ...oldMap[item.categoryId],
            item
          ]
        });
      });
    }

    return categoryIds.reduce<InKindCategoryItemStat[]>((acc, id) => {
      return [
        ...acc,
        ...this.categoryItemMap[id]
      ];
    }, []);
  }

  clearAllItemsByCategory (
    categoryIds: number[]
  ) {
    categoryIds.forEach(id => {
      this.set('categoryItemMap', {
        ...this.categoryItemMap,
        [id]: undefined
      });
    });
  }

  getMostCommonDefaultLangFromArray (ids: number[]) {
    return this.translationService.getMostCommonDefaultLangFromArray(
      this.categories.map((cat) => {
        return {
          defaultLanguageId: cat.language,
          id: cat.id
        };
      }),
      ids
    );
  }

  getAlreadyPaidItems (
    originalPayments: Payment[]
  ) {
    let alreadyPaidItems: InKindAwardedItemApi[] = [];
    if (originalPayments) {
      originalPayments.forEach((payment) => {
        payment.inKindItems.forEach((payItem) => {
          let found = false;
          alreadyPaidItems = alreadyPaidItems.map((i) => {
            if (i.itemIdentification === payItem.itemIdentification) {
              found = true;

              return {
                ...i,
                count: i.count + payItem.count
              };
            }

            return i;
          });
          if (!found) {
            alreadyPaidItems = [
              ...alreadyPaidItems,
              payItem
            ];
          }
        });
      });
    }

    return alreadyPaidItems;
  }

  getInactiveRequestedItemsString (inKindItems: InKindRequestedItem[]) {
    let inactiveRequestedItems = '';
    inKindItems.forEach((item) => {
      const detail = this.allItemsMap[item.itemIdentification];
      if (!detail.inUse) {
        const display = `${item.count} ${detail.name}`;
        if (inactiveRequestedItems) {
          inactiveRequestedItems = inactiveRequestedItems + ', ' + display;
        } else {
          inactiveRequestedItems = display;
        }
      }
    });

    return inactiveRequestedItems;
  }

  async editCategory (payload: { categoryId: number; name: string }) {
    this.spinnerService.startSpinner();
    try {
      await this.inKindResources.editCategory(payload);
      await this.resetCategories();
      this.spinnerService.stopSpinner();
      this.notifier.success(
        this.i18n.translate(
          'CONFIG:textSuccessfullyUpdatedCategory',
          {},
          'Successfully updated category'
        )
      );
    } catch (e) {
      this.logger.error(e);
      this.spinnerService.stopSpinner();
      this.notifier.error(
        this.i18n.translate(
          'CONFIG:textErrorUpdatingCategory',
          {},
          'There was an error updating the selected category'
        )
      );
    }
  }

  getValueMap (fetchedItems: InKindCategoryItemStat[]) {
    const map: Record<string, number> = {};
    fetchedItems.forEach((item) => {
      map[item.identification] = item.value;
    });

    return map;
  }

  getInKindItemsForSave (inKindItems: InKindRequestedItem[]) {
    return (inKindItems || []).filter((item) => {
      return !!item.itemIdentification && +item.count > 0;
    });
  }
}
