import { Component, Input } from '@angular/core';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { SPECIAL_HANDLING_REQUIRED_DESC } from '@features/forms/form-renderer-components/standard-form-components/form-special-handling/special-handling.constants';
import { CurrencyRadioOptions } from '@yourcause/common/currency';
import { I18nService } from '@yourcause/common/i18n';
import { Form, FormComponentsByTab } from '../form.typing';

@Component({
  selector: 'gc-download-form-pdf',
  templateUrl: './download-form-pdf.component.html'
})
export class DownloadFormPdfComponent {
  @Input() form: Form;
  @Input() revisionVersion: string;
  @Input() formComponentsByTab: FormComponentsByTab[];

  FieldTypes = ReferenceFieldsUI.ReferenceFieldTypes;
  attentionOptionLabel = this.i18n.translate(
    'FORMS:textThisAppRequiresAttentionLine',
    {},
    'This application requires attention line'
  );
  SPECIAL_HANDLING_REQUIRED_DESC = SPECIAL_HANDLING_REQUIRED_DESC;
  CurrencyRadioOptions = CurrencyRadioOptions;
  defaultCurrency = this.clientSettingsService.defaultCurrency;

  constructor (
    private i18n: I18nService,
    private clientSettingsService: ClientSettingsService
  ) { }
}
