import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { FooterStates } from '@yourcause/common';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { FooterState } from '../states/footer.state';
import { AppInsightsSessionService } from './app-insights-session.service';

@AttachYCState(FooterState)
@Injectable({ providedIn: 'root' })
export class FooterService extends BaseYCService<FooterState> {

  constructor (
    private appInsightsSessionService: AppInsightsSessionService
  ) {
    super();
  }

  get footerState () {
    return this.get('footerState');
  }

  get footerActionLabel () {
    return this.get('footerActionLabel');
  }

  get footerCancelLabel () {
    return this.get('footerCancelLabel');
  }

  get footerSecondaryLabel () {
    return this.get('footerSecondaryLabel');
  }

  get footerTertiaryLabel () {
    return this.get('footerTertiaryLabel');
  }

  get footerQuaternaryLabel () {
    return this.get('footerQuaternaryLabel');
  }

  get footerInfoText () {
    return this.get('footerInfoText');
  }

  get footerInfoTooltip () {
    return this.get('footerInfoTooltip');
  }

  get footerInfoIcon () {
    return this.get('footerInfoIcon');
  }

  get footerAction () {
    return this.get('footerAction');
  }

  get footerCancelAction () {
    return this.get('footerCancelAction');
  }

  get footerSecondaryAction () {
    return this.get('footerSecondaryAction');
  }

  get footerTertiaryAction () {
    return this.get('footerTertiaryAction');
  }

  get footerQuaternaryAction () {
    return this.get('footerQuaternaryAction');
  }

  get footerActionDisabled () {
    return this.get('footerActionDisabled');
  }

  get footerSecondaryDisabled () {
    return this.get('footerSecondaryDisabled');
  }

  get footerTertiaryDisabled () {
    return this.get('footerTertiaryDisabled');
  }

  get footerQuaternaryDisabled () {
    return this.get('footerQuaternaryDisabled');
  }

  setFooterState (state: FooterStates) {
    this.set('footerState', state);
  }

  setActionLabel (label: string) {
    this.set('footerActionLabel', label);
  }

  setCancelLabel (label: string) {
    this.set('footerCancelLabel', label);
  }

  setSecondaryLabel (label: string) {
    this.set('footerSecondaryLabel', label);
  }

  setTertiaryLabel (label: string) {
    this.set('footerTertiaryLabel', label);
  }

  setQuaternaryLabel (label: string) {
    this.set('footerQuaternaryLabel', label);
  }

  setFooterInfoText (text: string) {
    this.set('footerInfoText', text);
  }

  setFooterInfoIcon (icon: string) {
    this.set('footerInfoIcon', icon);
  }

  setFooterInfoTooltip (tooltip: string) {
    this.set('footerInfoTooltip', tooltip);
  }

  setPrimaryAction (action: () => any) {
    this.set('footerAction', action);
  }

  setCancelAction (action: () => any) {
    this.set('footerCancelAction', action);
  }

  setSecondaryAction (action: () => any) {
    this.set('footerSecondaryAction', action);
  }

  setTertiaryAction (action: () => any) {
    this.set('footerTertiaryAction', action);
  }

  setQuaternaryAction (action: () => any) {
    this.set('footerQuaternaryAction', action);
  }

  setPrimaryDisabled (disabled: boolean) {
    this.set('footerActionDisabled', disabled);
  }

  setSecondaryDisabled (disabled: boolean) {
    this.set('footerSecondaryDisabled', disabled);
  }

  setTertiaryDisabled (disabled: boolean) {
    this.set('footerTertiaryDisabled', disabled);
  }

  setQuaternaryDisabled (disabled: boolean) {
    this.set('footerQuaternaryDisabled', disabled);
  }

  setFooterInfo (
    infoText: string,
    infoIcon?: string,
    infoTooltip?: string
  ) {
    this.setFooterInfoText(infoText);
    this.setFooterInfoIcon(infoIcon);
    this.setFooterInfoTooltip(infoTooltip);
  }

  clearAll () {
    this.setActionLabel(null);
    this.setCancelLabel(null);
    this.setSecondaryLabel(null);
    this.setTertiaryLabel(null);
    this.setQuaternaryLabel(null);
    this.setFooterInfo(null, null, null);
    this.setPrimaryAction(null);
    this.setCancelAction(null);
    this.setSecondaryAction(null);
    this.setTertiaryAction(null);
    this.setQuaternaryAction(null);
    this.setPrimaryDisabled(false);
    this.setSecondaryDisabled(false);
    this.setTertiaryDisabled(false);
    this.setQuaternaryDisabled(false);
    this.setFooterState(FooterStates.STANDARD);
  }

  getCopyrightText () {
    const year = new Date().getFullYear();
    const version = environment.version;

    return `©${year} YourCause - GrantsConnect (${version}) (${this.appInsightsSessionService.sessionId})`;
  }
}
