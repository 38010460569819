import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { CyclesAPI } from '@core/typings/api/cycles.typing';
import { AppliedApplicant, AppliedNominee } from '@core/typings/applicant.typing';
import { ApplicationFromPaginated, BulkApplicationDetails, GetPaymentStatsPayload, Nominee, PaymentStats, SearchPaginatedResult } from '@core/typings/application.typing';
import { AppliedOrganization } from '@core/typings/organization.typing';
import { ProgramTypes } from '@core/typings/program.typing';
import { FilterColumn, PaginatedResponse, PaginationOptions } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class ApplicationManagerResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getNomineeInfo (id: number): Promise<Nominee> {
    const endpoint = `api/manager/applications/${id}/NomineeInfo`;

    return this.httpRestService.get(endpoint);
  }

  searchApplicationsPaginated (
    paginationOptions: PaginationOptions<ApplicationFromPaginated>,
    applicationId: number,
    organizationName: string,
    applicantName: string,
    applicantEmail: string,
    applicantId: number,
    organizationIdentification: string,
    startDate: string,
    endDate: string,
    cycleStatusFilter: CyclesAPI.CycleStatusFilterType,
    programType = ProgramTypes.GRANT,
    formFilterModels: FilterColumn<any>[] = []
  ): Promise<SearchPaginatedResult> {
    const endpoint = 'api/manager/applications/SearchPaginated';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      applicationId,
      organizationName,
      applicantName,
      applicantEmail,
      applicantId,
      organizationIdentification,
      cycleStatusFilter,
      programType,
      startDate,
      endDate,
      formFilterModels
    });
  }

  getOrganizations (
    options: PaginationOptions<AppliedOrganization>,
    isNomination = false
  ) {
    const endpoint = 'api/manager/applications/GetAppliedOrganizations';

    return this.httpRestService.post(endpoint, {
      paginationOptions: options,
      isNomination
    });
  }

  getApplicants (
    options: PaginationOptions<AppliedApplicant>,
    isNomination = false
  ) {
    const endpoint = 'api/manager/applications/GetAppliedApplicants';

    return this.httpRestService.post(endpoint, {
      paginationOptions: options,
      isNomination
    });
  }

  getNominees (options: PaginationOptions<AppliedNominee>) {
    const endpoint = 'api/manager/applications/GetAppliedNominees';

    const response = this.httpRestService.post<PaginatedResponse<AppliedNominee>>(endpoint, {
      paginationOptions: options
    });

    return response;
  }

  getPaymentStats (payload: GetPaymentStatsPayload) {
    const endpoint = 'api/manager/applications/PaymentStats';

    return this.httpRestService.post<PaymentStats>(endpoint, payload);
  }

  getAppCanBeDeleted (applicationId: number) {
    const endpoint = `api/manager/applications/${applicationId}/CanBeDeleted`;

    return this.httpRestService.get<boolean>(endpoint);
  }

  getValidateApplicationsForBulkDelete (applicationIdsToDelete: number[]) {
    return this.httpRestService.post(`api/manager/applications/ValidateApplicationsForBulkDelete`, {applicationIdsToDelete});
  }

  getUpdateCycleInfo (
    payload: { applicationIds: number[]; programId: number }
  ): Promise<CyclesAPI.UpdateCycleInfoResponse> {
    const endpoint = 'api/manager/applications/GrantProgramCycleUpdateInfo';

    return this.httpRestService.post(endpoint, payload);
  }

  getBulkApplicationDetails (
    applicationIds: number[]
  ): Promise<BulkApplicationDetails> {
    const endpoint = '/api/manager/applications/BulkActionIndicators';

    return this.httpRestService.post(endpoint, {
      applicationIds
    });
  }
}
