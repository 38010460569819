import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';

@Injectable({ providedIn: 'root' })
export class FormFieldCdtResources {

  constructor (
    private http: HttpRestService
  ) { }

  canUpdateCdtOnField (referenceFieldId: number): Promise<boolean> {
    const endpoint = `api/manager/forms/CanUpdateCustomDataTable/${referenceFieldId}`;

    return this.http.get(endpoint);
  }
}
