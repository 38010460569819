import { ProcessingTypes } from '@core/typings/payment.typing';
import { BudgetNameIsUnique } from '@features/budgets/budget-name-is-unique.validator';
import { CSVBoolean, IsNumber, IsOneOf, IsString, Required, Transform, Unique } from '@yourcause/common';

export enum FundingSourceTypes {
  DOLLARS = 1,
  UNITS = 2
}

export interface BudgetDetailMap {
  [budgetId: number|string]: BudgetDetail;
}

export interface Budget {
  id: number;
  name: string;
  description: string;
  fundingSourceType: FundingSourceTypes;
  numberOfPayments: number;
  numberOfFundingSources: number;
  totalAmount: number;
  totalUnits: number;
  totalAmountAvailable: number;
  totalUnitsAvailable: number;
  totalSpent: number;
  totalSpentUnits: number;
  totalSpentOverage: number;
  totalRemainingOverage: number;
  totalUnitsAvailableAmount: number;
  totalSpentUnitsAmount: number;
  totalRemainingUnitsAmount: number;
  totalUnavailableAmount: number;
  isUsedByYCProcessingType?: boolean;
  isUsedByClientProcessingType?: boolean;
  hasYcProcessingType?: boolean;
  hasClientProcessingType?: boolean;
  isClosed: boolean;
  reservedAmount: number;
  reservedApplications: number;
  budgetTags: number[];
}

export interface BudgetDetail {
  id?: number;
  name: string;
  description: string;
  active?: boolean;
  budgetFundingSources: BudgetFundingSource[];
  isUsedByYCProcessingType?: boolean;
  isUsedByClientProcessingType?: boolean;
  hasYcProcessingType?: boolean;
  hasClientProcessingType?: boolean;
  isClosed: boolean;
  accountNumber?: string;
  budgetTags: number[];
}


export interface SimpleBudgetFundingSource {
  clientId: number;
  fundingSourceId: number;
  fundingSourceType: number;
  fundingSourceName: string;
  totalAmountAvailable: number;
  totalUnitsAvailable: number;
  totalUnitsPayments?: number;
  totalAmountPayments?: number;
  isOverAllocationSource?: boolean;
  reservedAmount?: number;
}

export interface BudgetFundingSource {
  budgetId: number;
  fundingSourceId: number;
  fundingSourceType: number;
  totalAmount: number;
  totalUnits?: number;
  active: boolean;
  fundingSourceName: string;
  numberOfPayments?: number;
  totalAmountPayments: number;
  totalUnitsPayments?: number;
  totalPendingPayments?: number;
  isOverAllocationSource?: boolean;
  processingTypeId: ProcessingTypes;
  amountUnavailable: number;
  amountRemaining: number;
  isBudgetClosed: boolean;
  isFundingSourceClosed: boolean;
  reservedAmount: number;
}

export interface FundingSource {
  id: number;
  name: string;
  type: FundingSourceTypes;
  totalAmount: number;
  totalUnits?: number;
  unitCost?: number;
  unitValue?: number;
  active?: boolean;
  processingTypeId?: ProcessingTypes;
  amountRemaining?: number;
  unitsRemaining?: number;
  amountUnallocated?: number;
  amountAllocated?: number;
  unitsUnallocated?: number;
  numberOfPayments?: number;
  numberOfBudgets?: number;
  unavailableAmount?: number;
  isClosed?: boolean;
  isEligibleForRemove?: boolean;
  totalPaymentAmount?: number;
  reservedAmount?: number;
  reservedApplications?: number;
}

export interface FundingSourceForApi {
  id: number;
  name: string;
  type: FundingSourceTypes;
  totalAmount: number;
  processingTypeId: ProcessingTypes;
}

export interface SimpleFundingsource {
  fundingSourceId: number;
  fundingSourceName: string;
  isArchived: boolean;
  isClosed: boolean;
}

export interface FundingSourceInsights {
  fundingSourceId: number;
  fundingSourceName: string;
  isArchived: boolean;
  fundingSourceType: FundingSourceTypes;
  numberOfPayments: number;
  total: number;
  allocatedTotal: number;
  unallocatedTotal: number;
  totalSpent: number;
  totalRemaining: number;
  allocatedTotalRemaining: number;
  numberOfBudgets: number;
  isClosed: boolean;
  totalUnavailableAmount: number;
  reservedAmount: number;
  reservedApplications: number;
}

export interface SimpleDataHubBudget {
  id: number;
  name: string;
}

export interface SimpleDataHubBatch {
  id: number;
  name: string;
}

export interface BudgetTopLevelStats {
  totalAllocated: number;
  numberOfFundingSources: number;
  totalSpent: number;
  numberOfPayments: number;
  totalRemaining: number;
  totalAllocatedUnits: number;
  totalSpentUnits: number;
  totalRemainingUnits: number;
  totalAllocatedOverage: number;
  totalSpentOverage: number;
  totalRemainingOverage: number;
  totalUnavailableAmount: number;
  reservedAmount: number;
}

export interface TopLevelStats {
  total?: number;
  totalSpent: number;
  totalRemaining: number;
  totalAllocated?: number;
  totalUnavailableAmount?: number;
  totalReserved?: number;
}

export interface FundingSourceTopLevelStats {
  total: number;
  allocatedTotal: number;
  unallocatedTotal: number;
  totalSpent: number;
  allocatedTotalRemaining: number;
  totalRemaining: number;
  totalUnavailableAmount: number;
  numberOfBudgets: number;
  reservedAmount: number;
}


export interface BudgetDashboardMap {
  [x: string]: BudgetDashboard;
}

export interface FundingSourceDashboardMap {
  [x: string]: FundingSourceDashboardInfo;
}

export interface FundingSourceDashboardInfo {
  stats: FundingSourceTopLevelStats;
  budgets: FundingSourceBudgetInfo[];
}

export interface FundingSourceBudgetInfo {
  budgetId: number;
  budgetName: string;
  totalAllocated: number;
  totalRemaining: number;
  totalSpent: number;
  totalUnavailableAmount: number;
  isClosed: boolean;
  reservedAmount?: number;
}

export interface BudgetDashboard {
  detail: BudgetDetail;
  stats: BudgetTopLevelStats;
  programs: BudgetDashboardProgram[];
  sources: BudgetDashboardFundingSource[];
}

export interface FundingSourceDashboard {
  detail: FundingSource;
  stats: TopLevelStats;
  budgets: BudgetDetail[];
}

export interface BudgetDashboardProgram {
  programId: number;
  programName: string;
  numberOfPayments: number;
  paymentsAmount: number;
  paymentsAmountUnit: number;
}

export interface BudgetDashboardFundingSource {
  fundingSourceId: number;
  fundingSourceName: string;
  totalAllocated: number;
  totalSpent: number;
  totalRemaining: number;
  totalAllocatedUnits: number;
  totalSpentUnits: number;
  totalRemainingUnits: number;
  isOverage: boolean;
  isClosed: boolean;
  totalUnavailableAmount: number;
  reservedAmount: number;
}

export interface FundingSourceDashboardBudget {
  budgetId: number;
  totalAllocated: number;
  totalSpent: number;
  totalRemaining: number;
  totalUnavailableAmount: number;
}

export enum PaymentTableType {
  BUDGETS,
  FUNDING_SOURCES,
  PROGRAM
}

export interface RemainingAmountBudgetMap {
  [i: string]: {
    value: number;
    label: string;
    helpDisplay?: string;
    canMoveFunds?: boolean;
  };
}

export interface BudgetFundingSourceCombo {
  budget: BudgetDetail;
  fundingSource: BudgetFundingSource;
  comboId: string;
  isClosed: boolean;
}

export interface BudgetDrilldownInfo {
  budgetFundingSourceDetailModel: BudgetFundingSourceDetail[];
  grantProgramInfos: BudgetProgram[];
}

export interface BudgetProgram {
  grantProgramId: number;
  grantProgramName: string;
  applicationsCount: number;
  paymentsTotal: number;
}

export interface BudgetFundingSourceDetail {
  fundingSourceId: number;
  fundingSourceName: string;
  allocation: number;
  isClosed: boolean;
  budgetFundingSourceAvailableAmount: number;
  budgetsUsingThisFs: Budget[];
}

export interface BudgetDrilldownMap {
  [id: number]: BudgetDrilldownInfo;
}

export interface FundingSourceDrilldownMap {
  [id: number]: FundingSourceDrilldownInfo;
}

export interface FundingSourceDrilldownInfo {
  fundingSourceBudgetDetailModels: FundingSourceBudgetDetailModels[];
  programNames: string[];
  fundingSourceReservedAmount?: number;
}

export interface FundingSourceBudgetDetailModels {
  budgetId: number;
  budgetName: string;
  isClosed: boolean;
  allocation: number;
  unusedFundsFromSource: number;
  remainingFundsAfterClosingSource: number;
  remainingFundsAfterOpeningSource: number;
  reservedAmount?: number;
}

export interface BudgetFundingSourceModalResponse {
  source: SimpleBudgetFundingSource;
  amount: number;
}

export interface BudgetFundingSourceAudit {
  budgetId: number;
  createdDate: string;
  createdByUserName: string;
  impactAmount: number;
  actionType: BudgetFundingSourceAuditTypes;
  fundingSourceId?: number;
  fundingSourceName?: string;
  infoBudgetId?: number;
  infoBudgetName?: string;
  actionMessage?: string;
  impactDisplay?: string;
  impactColor?: string;
  budgetName?: string;
  infoBudgetAccountNumber?: string;
}

export interface ExportBudgetFundingSourceAudit {
  date: string;
  detail: string;
  user: string;
  impact: string;
}

export enum BudgetFundingSourceAuditTypes {
  FundingSourceCreated = 1,
  BudgetCreated = 2,
  FundingSourceAdded = 3,
  FundingSourceAmountUpdated = 4,
  BudgetAllocationUpdated = 5,
  OverageUsed = 6,
  FundingSourceClosed = 7,
  FundingSourceOpened = 8,
  BudgetClosed = 9,
  BudgetOpened = 10,
  FundsReallocated = 11,
  FundsReturnedToSource = 12,
  BudgetDeleted = 13,
  FundingSourceDeleted = 14,
  BudgetAllocationUpdatedFromClosedBudget = 15,
  FundingSourceReallocationToBudgetViaClosingBudget = 16,
  BudgetAccountNumberChanged = 17,
  FundingSourceNoReallocationToBudgetViaClosingBudget = 18
}

export interface BudgetSave {
  id: number;
  name: string;
  description: string;
  fundingSources: BudgetFundingSourceSave[];
  accountNumber: string;
}

export interface BudgetFundingSourceSave {
  fundingSourceId: number;
  fundingSourceType: FundingSourceTypes;
  totalAmount: number;
}

export interface BudgetImport {
  budgets: BudgetForImport[];
}

export interface BudgetImportNoSources {
  budgets: BudgetForImportNoSources[];
}

export interface BudgetForImportNoSources {
  budgetName: string;
  budgetDescription: string;
  totalAmount: number;
  fundingSourceType: FundingSourceTypes;
  processor: ProcessingTypes;
}

export interface FundingSourceAllocationsImport {
  fundingSourceId: number;
  fundingSourceType: FundingSourceTypes;
  allocatedAmount: number;
}
export interface BudgetForImport {
  budgetName: string;
  budgetDescription: string;
  fundingSourceAllocations: FundingSourceAllocationsImport[];
}
export interface BudgetImportExernalContext {
  fundingSourceIds: number[];
}

export class BudgetImportModel {
  @BudgetNameIsUnique()
  @Unique()
  @IsString({
    maxLength: 50
  })
  @Required()
  'Budget Name': string;
  @IsString()
  @Required()
  'Budget Description': string;
}

export class BudgetImportNoSourcesModel extends BudgetImportModel {
  @Required()
  @IsOneOf([
    'cash',
    'in-kind'
  ])
  @Transform((val: string) => {
    if (!!val) {
      return val.toLowerCase().trim();
    }

    return val;
  })
  'Type (cash or in-kind)': string;

  @Required()
  @IsNumber({ min: .01 })
  'Total Amount': number;
}

export class BudgetImportNoSourcesWithProcesserModel extends BudgetImportNoSourcesModel {
  @Required()
  @CSVBoolean()
  'Processed by Client (true or false)': boolean;
}

export interface ApplicantsApplicationsPaymentsFundingSource {
  fundingSourceName: string;
  applicationsCount: number;
  paymentsCount: number;
  paymentsTotal: number;
}

export interface BulkUpdateBudgetsPayload {
  budgetFundingSources: BulkUpdateBudget[];
}

export interface BulkUpdateBudget {
  budgetId: number;
  budgetFundingSourceAmount: number;
}
