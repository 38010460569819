import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { GeneratePdfPayload } from '@core/typings/pdf.typing';
import { EmailPdfType } from '@features/system-emails/email.typing';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PDFResources {
  constructor (
    private httpRestService: HttpRestService,
    private http: HttpClient,
    private portal: PortalDeterminationService
  ) { }

  getPdfBlobFromDownloadUrl (downloadUrl: string) {
    return lastValueFrom(this.http.get(downloadUrl, {
      responseType: 'blob'
    }));
  }

  applicationHtmlToPdf (
    payload: GeneratePdfPayload,
    applicationId: number
  ): Promise<{ url: string }> {
    const endpoint = `api/${this.portal.isManager ? 'manager' : 'portal'}/applications/DownloadApplicationPdf/${applicationId}`;

    return this.httpRestService.post(endpoint, payload);
  }

  formHtmlToPdf (
    payload: GeneratePdfPayload,
    formId: number
  ): Promise<{ url: string }> {
    const endpoint = `api/manager/forms/DownloadFormPdf/${formId}`;

    return this.httpRestService.post(endpoint, payload);
  }

  emailHtmlToPdf (
    payload: GeneratePdfPayload,
    emailPdfType: EmailPdfType,
    recordId: number
  ): Promise<{ url: string }> {
    let endpoint: string;
    switch (emailPdfType) {
      case EmailPdfType.Program:
        endpoint = `/api/manager/programs/DownloadProgramEmailsPdf/${recordId}`;
        break;
      case EmailPdfType.Application:
        endpoint = `api/manager/applications/${recordId}/DownloadApplicationSentEmailsPdf`;
        break;
    }

    return this.httpRestService.post(endpoint, payload);
  }
}
