import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { InAppAlertFromApi, ReleaseNoteFromApi } from './scheduled-announcements.typing';


@Injectable({ providedIn: 'root' })
export class ScheduledAnnouncementsResources {

  constructor (
    private http: HttpRestService,
    private portal: PortalDeterminationService
  ) { }

  getReleaseNotesForPlatform (): Promise<ReleaseNoteFromApi[]> {
    const endpoint = 'api/admin/Configuration/ReleaseNotesAnnouncements';

    return this.http.get(endpoint);
  }

  getReleaseNotesForUser (): Promise<ReleaseNoteFromApi[]> {
    const endpoint = 'api/manager/ClientResource/ReleaseNotesAnnouncements';

    return this.http.get(endpoint);
  }

  dismissReleaseNote (id: number) {
    const endpoint = `/api/manager/ClientResource/DismissReleaseNotesAnnouncement/${id}`;

    return this.http.post(endpoint, {});
  }

  getInAppAlertsForPlatform (): Promise<InAppAlertFromApi[]> {
    const endpoint = 'api/admin/Configuration/InAppAlertsAnnouncements';

    return this.http.get(endpoint);
  }

  getInAppAlertsForUser (): Promise<InAppAlertFromApi[]> {
    const endpoint = 'api/manager/ClientResource/InAppAlertsAnnouncements';

    return this.http.get(endpoint);
  }

  getInAppAlertsForApplicant (): Promise<InAppAlertFromApi[]> {
    const endpoint = 'api/portal/ApplicantResource/InAppAlertsAnnouncements';

    return this.http.get(endpoint);
  }

  dismissInAppAlert (id: number) {
    const endpoint = this.portal.isManager ?
      `/api/manager/ClientResource/DismissInAppAlertsAnnouncement/${id}` :
      `/api/portal/ApplicantResource/DismissInAppAlertsAnnouncement/${id}`;

    return this.http.post(endpoint, {});
  }
}

