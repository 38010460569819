
<ng-template #confirmChangePopoverTemplate
let-confirmChangePopover="confirmChangePopover">
  <strong class="mb-3 d-block">
    {{ popoverHeader }}
  </strong>
  <span>
    {{ popoverText }}
  </span>
  <yc-button
    class="d-flex justify-content-end"
    buttonType="submit"
    [disabled]="saving ||
    (
      isTableConfigPage ?
        !isTableConfigValid :
        !isValid
    )"
    [primary]="true"
    (onClick)="saveOrNavigateToTableConfig()">
    {{ primaryButtonText }}
  </yc-button>
</ng-template>

<yc-modal *ngIf="modalHeader"
  [ycHidden]="hideModal"
  [modalHeader]="modalHeader">

  <!-- Standard Config -->
  <div [ycHidden]="isTableConfigPage || isDataSetConfigPage">
    <gc-create-edit-form-field
      [isViewOnly]="isViewOnly"
      [existingKeys]="existingKeys"
      [allowedKeys]="allowedKeys"
      [doNotAllowKeyChanges]="doNotAllowKeyChanges"
      [addingFieldToForm]="addingFieldToForm"
      [editingFieldOnForm]="editingFieldOnForm"
      [forceIsTableField]="forceIsTableField"
      [forceIsDataPoint]="forceIsDataPoint"
      [pendingKeyToBeCreated]="pendingKeyToBeCreated"
      [defaultAudienceSelection]="defaultAudienceSelection"
      [existingReferenceField]="existingReferenceField"
      [tableImportNotAllowed]="tableImportNotAllowed"
      (onAddingCategoryChange)="addingCategory = $event"
      (onFieldChange)="onFieldChange($event)"
      (onValidityChange)="isValid = $event">
    </gc-create-edit-form-field>
  </div>

  <!-- Table Config -->
  <div *ngIf="isTableConfigPage">
    <gc-table-field-configuration
      [isViewOnly]="isViewOnly"
      [referenceFieldId]="existingReferenceField?.referenceFieldId"
      [field]="field"
      [pendingKeyToBeCreated]="!existingReferenceField?.referenceFieldId ?
        field?.key :
        null"
      (onTableFieldsChange)="tableFields = $event"
      (onValidityChange)="isTableConfigValid = $event"
      (onHideParentModal)="hideModal = $event"
      (onNoImportAllowedChange)="tableImportNotAllowed = $event">
    </gc-table-field-configuration>
  </div>
  <!-- Data Set Config -->
  <div *ngIf="isDataSetConfigPage">
    <gc-data-set-field-configuration
      [field]="field"
      [pendingKeyToBeCreated]="!existingReferenceField?.referenceFieldId ?
        field?.key :
        null"
      [disabled]="isViewOnly"
      [referenceFieldId]="existingReferenceField?.referenceFieldId"
      (onValidityChange)="isTableConfigValid = $event"
      (onSubsetFieldsChange)="tableFields = $event"
      (onHideParentModal)="hideModal = $event">
    </gc-data-set-field-configuration>
  </div>

  <!-- Footer -->
  <div *ycModalFooter 
    class="modal-footer">
    <div class="d-flex justify-content-between align-items-center flex-grow-1">
      <div>
        <yc-button 
          class="me-3 justify-content-start"
          [langKey]="'common:btnCancel'"
          [link]="true"
          [borderOnHover]="true"
          [defaultText]="'Cancel'"
          (onClick)="closeModal.emit()">
        </yc-button>
      </div>
      <div class="d-flex align-items-center">
        <yc-button *ngIf="isTableConfigPage || isDataSetConfigPage"
          class="me-3 justify-content-end"
          [langKey]="'common:btnBack'"
          [defaultText]="'Back'"
          [link]="true"
          (onClick)="onBack()">
        </yc-button>

        <yc-button *ngIf="!!secondarySaveText && onFinalStep"
          class="me-3 justify-content-end"
          [translatedText]="secondarySaveText"
          [secondary]="true"
          [disabled]="isSaveDisabled"
          (onClick)="onSecondaryClick()">
        </yc-button>

        <button #confirmChangePopover="bs-popover"
          [popover]="showPopover ?
            confirmChangePopoverTemplate :
            null"
          containerClass="custom-popover-width"
          placement="top"
          [outsideClick]="true"
          [popoverContext]="{
            confirmChangePopover: confirmChangePopover
          }"
          class="d-flex justify-content-end btn btn-primary"
          buttonType="submit"
          [disabled]="isSaveDisabled"
          (click)="onPrimaryClick(confirmChangePopover)">
          {{ primaryButtonText }}
        </button>
      </div>
    </div>
  </div>
</yc-modal>

