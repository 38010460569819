import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { ApproveApplication, ApproveDeclinePayload, ArchiveApplicationsPayload, BulkRouteApplicationsPayload, ChangeStatusPayload, NotifyOfStatusForApi, RouteApplicationPayload } from '@core/typings/application.typing';
import { CancelApplicationPayload } from '@core/typings/ui/cancel-application.typing';
import { UpdateProgramModalResponse } from '../update-program-modal/update-program-modal.component';

@Injectable({ providedIn: 'root' })
export class ApplicationActionResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  approveApplicationByAppManager (data: ApproveDeclinePayload): Promise<{
    automaticallyRouted: boolean;
  }> {
    return this.httpRestService.post('api/manager/applications/approve', data);
  }

  approveApplication (applicationId: number, data: ApproveApplication): Promise<{
    automaticallyRouted: boolean;
  }> {
    const endpoint = 'api/manager/applications/' + applicationId + '/approve';

    return this.httpRestService.post(endpoint, data);
  }

  declineApplicationByAppManager (data: ApproveDeclinePayload): Promise<{
    automaticallyRouted: boolean;
  }> {
    return this.httpRestService.post('api/manager/applications/decline', data);
  }

  declineApplication<T> (applicationId: number, data: T): Promise<{
    automaticallyRouted: boolean;
  }> {
    const endpoint = 'api/manager/applications/' + applicationId + '/decline';

    return this.httpRestService.post(endpoint, data);
  }

  bulkRouteApplications (data: BulkRouteApplicationsPayload) {
    const endpoint = 'api/manager/applications/bulkRouteApplications';

    return this.httpRestService.post(endpoint, data);
  }

  routeApplicationByAppManager<T> (applicationId: number, data: T) {
    const endpoint = 'api/manager/applications/' + applicationId + '/appmanager/route';

    return this.httpRestService.post(endpoint, data);
  }

  routeApplication (applicationId: number, data: RouteApplicationPayload) {
    const endpoint = 'api/manager/applications/' + applicationId + '/route';

    return this.httpRestService.post(endpoint, data);
  }

  archiveApplication (payload: ArchiveApplicationsPayload) {
    const endpoint = 'api/manager/applications/archive';

    return this.httpRestService.post(endpoint, payload);
  }

  unarchiveApplication (applicationIds: number[]) {
    return this.httpRestService.post('api/manager/applications/unarchive', {applicationIds});
  }

  changeApplicationStatus (data: ChangeStatusPayload) {
    const endpoint = 'api/manager/applications/ChangeApplicationStatus';

    return this.httpRestService.post(endpoint, data);
  }

  sendApplicationStatusEmail (data: NotifyOfStatusForApi) {
    return this.httpRestService.post('api/manager/applications/sendapplicationstatus', data);
  }

  deleteApplication (id: number) {
    const endpoint = `api/manager/applications/${id}/DeleteApplication`;

    return this.httpRestService.delete(endpoint);
  }

  bulkDeleteApplications (ApplicationIdsToDelete: number[]) {
    return this.httpRestService.post('api/manager/applications/BulkDeleteApplications', {ApplicationIdsToDelete});
  }

  setRecommendedFundingAmount (
    applicationId: number,
    recommendedFundingAmount: number
  ): Promise<{ automaticallyRouted: boolean }> {
    const endpoint = `api/manager/applications/${applicationId}/SetRecommendedFundingAmount`;

    return this.httpRestService.post(endpoint, {
      recommendedFundingAmount
    });
  }

  updateProgram (
    modalResponse: UpdateProgramModalResponse,
    applicationId: number
  ) {
    const endpoint = 'api/manager/applications/MoveApplicationToProgram';

    return this.httpRestService.post(endpoint, {
      applicationId,
      programId: modalResponse.programId,
      cycleId: modalResponse.cycleId,
      workflowLevelId: modalResponse.workflowLevelId,
      clientEmailTemplateId: modalResponse.clientEmailTemplateId,
      emailOptions: {
        ccEmails: [],
        bccEmails: [],
        attachments: []
      }
    });
  }

  cancelApplication (
    payload: CancelApplicationPayload
  ) {
    return this.httpRestService.post('api/manager/applications/CancelApplication', payload);
  }
}
