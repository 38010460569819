import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginBehaviors } from '@core/typings/login-behaviors.typing';
import { ProgramTypes } from '@core/typings/program.typing';
import { environment } from '@environment';
import { RootObjectNames } from '@features/reporting/services/ad-hoc-reporting-definitions.service';
import { get } from 'lodash';

export interface RouteSnapshot {
  children: RouteSnapshot[];
  data: {
    breadcrumbLabel?: string;
    pageName?: string;
  };
}

@Injectable({ providedIn: 'root' })
export class LocationService {

  constructor (
    private activatedRoute: ActivatedRoute
  ) { }

  getRelativeUrl (
    path: string,
    search?: string,
    hash?: string
  ) {
    const port = !location.port ||
      (location.port === '80' ||
      location.port === '443') ?
      '' :
      ':' + location.port;
    const searchString = search ? '?' + search : '';
    const hashString = hash ? '#' + hash : '';

    return `${location.protocol}//${location.hostname}${port}${path}${searchString}${hashString}`;
  }

  openExternalUrl (url: string) {
    if (url) {
      const http = 'http://';
      const https = 'https://';
      if (url.startsWith(http)) {
        url = url.replace(http, https);
      } else if (!url.startsWith(https)) {
        url = `${https}${url}`;
      }

      window.open(url, '_blank');
    }
  }

  getGenericRoute (pathname: string = location.pathname) {
    return pathname.replace(/\/(([a-fA-F0-9]{8}-([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12})|(\d+))(\/?)/g, '/:id$5');
  }

  getCurrentDefaultTitle () {
    return this.recurseGetTitle(this.activatedRoute.snapshot.root);
  }

  recurseGetTitle (
    route: RouteSnapshot
  ): string {
    let currentRoute = route;
    let pageName;
    while (currentRoute) {
      const potentialName = currentRoute.data?.pageName || currentRoute.data?.breadcrumbLabel;
      if (potentialName) {
        pageName = potentialName;
      }

      currentRoute = currentRoute.children[0];
    }

    return pageName;
  }

  generatePortalUrl (base: string) {
    return `${location.protocol}//${base}${+location.port > 443 ? `:${location.port}` : ''}`;
  }

  generateUrlBase (
    loginBehavior: LoginBehaviors,
    customSubdomain = ''
  ) {
    const { locationBase } = environment;
    const hostname = location.hostname;
    const base = (!environment.supportsSubdomains ? '' : customSubdomain ? `${customSubdomain}.` : 'apply.') + locationBase;
    const externalPortalBase = this.generatePortalUrl(base);
    let internalPortalBase = '';
    if (
      !customSubdomain &&
      (locationBase !== hostname) &&
      (loginBehavior !== LoginBehaviors.PlainLogin)
    ) {
      internalPortalBase = this.generatePortalUrl(hostname);
    }

    return {
      externalPortalBase,
      internalPortalBase
    };
  }

  navigateToApp (
    row: Record<string, Record<string, number|string|boolean>>,
    object: RootObjectNames
  ) {
    const url = this.getNavigateToAppUrl(row, object);
    window.open(url);
  }

  getNavigateToAppUrl (
    row: Record<string, Record<string, number|string|boolean>>,
    object: RootObjectNames
  ) {
    const appId = get(row, ['application', 'id']);
    const programType = get(row, ['grantProgram', 'programType']);
    const isNomination = programType === ProgramTypes.NOMINATION;
    const base = isNomination ?
      '/management/nomination-view' :
      '/management/application-view';
    let route = '';
    switch (object) {
      case 'application':
      case 'applicationInKind':
      case 'customForm':
      default:
        route = 'form/no-form';
        break;
      case 'award':
      case 'awardInKind':
      case 'payment':
      case 'paymentInKind':
        route = 'awards';
    }

    return this.getRelativeUrl(`${base}/${appId}/${route}`);
  }

  extractQueryParamsFromUrl<T> (
    url: string
  ): T {
    const splitArray = url.split('?');
    if (splitArray[1]) {
      return splitArray[1].split('&').map(c => {
        return c.split('=');
      }).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: decodeURIComponent(value)
        };
      }, {} as T);
    }

    return null;
  }
}
