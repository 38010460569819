import { SimpleStringMap } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { StatusMap, StatusOptions } from '../typings/status.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';

@RegisterYCState()
export class StatusState extends BaseYcState {
  readonly application: StatusOptions[];
  readonly payment: StatusOptions[];
  readonly form: StatusOptions[];
  readonly batch: StatusOptions[];
  readonly dataDecommissioning: StatusOptions[];
  readonly applicationStatusMap: StatusMap;
  readonly paymentStatusMap: StatusMap;
  readonly paymentSubStatusMap: StatusMap;
  readonly formStatusMap: StatusMap;
  readonly batchStatusMap: StatusMap;
  readonly dataDecommissioningStatusMap: StatusMap;
  readonly paymentStatusOptions: TypeaheadSelectOption[] = [];
  readonly paymentSubStatusOptions: TypeaheadSelectOption[] = [];
  readonly appStatusOptions: TypeaheadSelectOption[] = [];
  readonly formStatusOptions: TypeaheadSelectOption[] = [];
  readonly dataDecommissioningStatusOptions: TypeaheadSelectOption[] = [];
  readonly adHocAppStatusOptions: TypeaheadSelectOption<string>[] = [];
  readonly archiveReasonCodes: TypeaheadSelectOption[];
  readonly archiveReasonCodeMap: SimpleStringMap<string> = {};
}
