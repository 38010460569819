import { NgModule } from '@angular/core';
import { GCCoreModule } from '@core/_core.module';
import { GetValidationStateValuePipe } from '@features/forms/pipes/get-validation-state-value.pipe';
import { IsInvalidCompPipe } from '@features/forms/pipes/is-invalid-comp.pipe';
import { FormAmountRequestedComponent } from './form-amount-requested/form-amount-requested.component';
import { FormAttentionComponent } from './form-attention/form-attention.component';
import { FormDecisionComponent } from './form-decision/form-decision.component';
import { FormDesignationComponent } from './form-designation/form-designation.component';
import { FormEmployeeSSOFieldComponent } from './form-employee-sso-field/form-employee-sso-field.component';
import { FormInKindAmountRequestedComponent } from './form-in-kind-amount-requested/form-in-kind-amount-requested.component';
import { FormRecommendedFundingAmountComponent } from './form-recommended-funding-amount/form-recommended-funding-amount.component';
import { FormSpecialHandlingComponent } from './form-special-handling/form-special-handling.component';

@NgModule({
  imports: [
    GCCoreModule
  ],
  declarations: [
    FormAmountRequestedComponent,
    FormAttentionComponent,
    FormDecisionComponent,
    FormDesignationComponent,
    FormEmployeeSSOFieldComponent,
    FormInKindAmountRequestedComponent,
    FormRecommendedFundingAmountComponent,
    FormSpecialHandlingComponent,
    GetValidationStateValuePipe,
    IsInvalidCompPipe
  ],
  exports: [
    FormAmountRequestedComponent,
    FormAttentionComponent,
    FormDecisionComponent,
    FormDesignationComponent,
    FormEmployeeSSOFieldComponent,
    FormInKindAmountRequestedComponent,
    FormRecommendedFundingAmountComponent,
    FormSpecialHandlingComponent,
    GetValidationStateValuePipe,
    IsInvalidCompPipe
  ]
})
export class StandardFormComponentsModule { }
