import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ApplicationFileService } from '@core/services/application-file.service';
import { SpecialHandlingService } from '@core/services/special-handling.service';
import { SpinnerService } from '@core/services/spinner.service';
import { PayeeOverride, UpdatePaymentSpecialHandling } from '@core/typings/payment.typing';
import { SpecialHandling } from '@features/forms/form-renderer-components/standard-form-components/form-special-handling/form-special-handling.component';
import { AddressRequestsService } from '@features/platform-admin/address-requests/address-requests.service';
import { AddressRequestFile } from '@features/platform-admin/address-requests/address-requests.typing';

import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { OnFileDownloadInfo, YcFile } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { AddressFormGroup } from '../address-block/address-block.component';

export interface SpecialHandlingFormGroup extends AddressFormGroup {
  notes: string;
  reason: string;
  file: YcFile<File>[];
  updateAddress: boolean;
}

@Component({
  selector: 'gc-special-handling-modal',
  templateUrl: './special-handling-modal.component.html',
  styleUrls: ['./special-handling-modal.component.scss']
})
export class SpecialHandlingModalComponent extends YCModalComponent<UpdatePaymentSpecialHandling> implements OnInit {
  @Input() isViewOnly = false;
  @Input() paymentId: number;
  @Input() payeeOverride: PayeeOverride;
  @Input() name: string;
  @Input() defaultAddress: SpecialHandling;
  @Input() applicationId: number;
  @Input() isClientProcessed: boolean;

  formGroup: TypeSafeFormGroup<SpecialHandlingFormGroup>;
  saving: boolean;
  fileChanged = false;

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private applicationFileService: ApplicationFileService,
    private spinnerService: SpinnerService,
    private addressRequestService: AddressRequestsService,
    private notifier: NotifierService,
    private specialHandlingService: SpecialHandlingService,
    private i18n: I18nService
  ) {
    super();
  }

  ngOnInit () {
    const file = this.applicationFileService.breakDownloadUrlDownToObject(
      this.payeeOverride?.fileUrl
    );
    let formControlFile: YcFile<File>[] = [];
    if (file) {
      formControlFile  = [new YcFile<File>(
        file.fileName,
        null,
        this.payeeOverride.fileUrl,
        +file.fileId
      )];
    }
    const address: PayeeOverride|SpecialHandling = this.payeeOverride ||
      this.defaultAddress;
    this.formGroup = this.formBuilder.group<SpecialHandlingFormGroup>({
      name: [this.name || '', Validators.required],
      address1: [address?.address1 || '', Validators.required],
      address2: address?.address2 || '',
      city: [address?.city || '', Validators.required],
      stateProvRegCode: [address?.state || '', Validators.required],
      countryCode: [address?.country || 'US', Validators.required],
      postalCode: [address?.postalCode || '', Validators.required],
      notes: this.payeeOverride?.notes ?? '',
      reason: this.payeeOverride?.reason ?? '',
      file: [formControlFile],
      updateAddress: this.payeeOverride ?
        !!this.payeeOverride.address1 :
        false
    });
  }

  downloadFile = async (info: OnFileDownloadInfo) => {
    this.spinnerService.startSpinner();
    await this.addressRequestService.downloadAddressRequestFile(
      info.file.fileUrl,
      this.applicationId
    );
    this.spinnerService.stopSpinner();
    
  }

  onFileChange () {
    this.fileChanged = true;
  }

  async save () {
    this.saving = true;
    if (this.isViewOnly) {
      this.closeModal.emit();
    } else {
      const formVal = this.formGroup.value;
      const updateAddress = this.formGroup.value.updateAddress;
      if (updateAddress) {
        const isSameAsDefault = this.specialHandlingService.isSameAsDefault(
          {
            address1: formVal.address1,
            address2: formVal.address2,
            city: formVal.city,
            state: formVal.stateProvRegCode,
            postalCode: formVal.postalCode,
            country: formVal.countryCode,
            notes: formVal.notes,
            reason: formVal.reason,
            fileUrl: ''
          },
          this.defaultAddress,
          this.isClientProcessed
        );
        if (isSameAsDefault) {
          this.saving = false;

          return this.notifier.error(this.i18n.translate(
            'GLOBAL:textAddressMustBeDifferent',
            {},
            'Address must be different than default to save'
          ));
        }
        let fileObj: AddressRequestFile = {
          url: null,
          fileUploadId: null
        };
        const file = formVal.file[0];
        
        if (!!file?.file) {
          if (this.fileChanged) {
            this.spinnerService.startSpinner();
            fileObj = await this.addressRequestService.uploadAddressRequestFile(
              file.file,
              file.fileName,
              this.applicationId
            );
            this.spinnerService.stopSpinner();
          } else {
            fileObj = {
              url: file.fileUrl,
              fileUploadId: file.fileUploadId as number
            };
          }
        }
        this.closeModal.emit({
          paymentId: this.paymentId,
          specialHandlingName: formVal.name,
          specialHandlingAddress1: formVal.address1,
          specialHandlingAddress2: formVal.address2,
          specialHandlingCountry: formVal.countryCode,
          specialHandlingCity: formVal.city,
          specialHandlingStateProvinceRegion: formVal.stateProvRegCode,
          specialHandlingPostalCode: formVal.postalCode,
          specialHandlingNotes: formVal.notes,
          specialHandlingReason: formVal.reason,
          specialHandlingFileUrl: fileObj.url,
          fileUploadId: +fileObj.fileUploadId
        });
      } else {
        this.closeModal.emit({
          paymentId: this.paymentId,
          specialHandlingName: '',
          specialHandlingAddress1: '',
          specialHandlingAddress2: '',
          specialHandlingCountry: '',
          specialHandlingCity: '',
          specialHandlingStateProvinceRegion: '',
          specialHandlingPostalCode: '',
          specialHandlingNotes: '',
          specialHandlingReason: '',
          specialHandlingFileUrl: '',
          fileUploadId: null
        });
      }
    }
    this.saving = false;
  }
}
