export const SPECIAL_HANDLING_DESC = 'Select this option if this application requires payment to a special address. If a change of address is required, and YourCause is processing the payment, the address must be approved by Blackbaud Compliance. If an organization is receiving funds and has ACH setup, then payments will be sent by check and not ACH.';
export const SPECIAL_HANDLING_REQUIRED_DESC = 'This application requires change of address for payments';
export const COUNTRY_LABEL = 'Country';
export const POSTAL_LABEL = 'Postal code';
export const NOTES_LABEL = 'Notes';
export const FILE_LABEL = 'Upload supporting document for alternative address request';
export const REASON_LABEL = 'Reason for alternative address request';
export const STATE_LABEL = 'State, province or region';
export const CITY_LABEL = 'City';
export const ADDRESS_2_LABEL = 'Address line 2';
export const ADDRESS_1_LABEL = 'Address line 1';
export const NAME_LABEL = 'Name';
export const SPECIAL_HANDLING_FIELDS = [
  NAME_LABEL,
  ADDRESS_1_LABEL,
  ADDRESS_2_LABEL,
  CITY_LABEL,
  STATE_LABEL,
  COUNTRY_LABEL,
  POSTAL_LABEL,
  NOTES_LABEL,
  FILE_LABEL,
  REASON_LABEL
];
export const SPECIAL_HANDLING_FIELDS_MAP = {
  [NAME_LABEL]: NAME_LABEL,
  [ADDRESS_1_LABEL]: ADDRESS_1_LABEL,
  [ADDRESS_2_LABEL]: ADDRESS_2_LABEL,
  [CITY_LABEL]: CITY_LABEL,
  [STATE_LABEL]: STATE_LABEL,
  [COUNTRY_LABEL]: COUNTRY_LABEL,
  [POSTAL_LABEL]: POSTAL_LABEL,
  [NOTES_LABEL]: NOTES_LABEL,
  [FILE_LABEL]: FILE_LABEL,
  [REASON_LABEL]: REASON_LABEL
};
export const REQUIRED_MESSAGE = 'Please ensure all required fields are completed';
