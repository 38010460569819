export namespace AutomationAPI {
  export enum AutomationRuleSetStatus {
    Enabled = 1,
    Disabled = 2
  }
  export interface AutomationRuleSetExpressionBaseModel {
    /** This should be provided if this is an existing rule being modified. Otherwise, 0 should be provided. */
    automationRuleSetExpressionId: number;

    /** The period-separated column name of the field that is to be used in this comparison */
    columnName: string;

    /** The type of operator that will be applied on this column and the comparison value */
    comparisonType: AutomationRuleComparisonTypes;

    /** The value as a string that the column provided will be compared against */
    comparisonValue: string;

    /** Pass if type reference field */
    referenceFieldId: number;
  }
  export interface SaveAutomationRuleSetExpressionModel extends AutomationRuleSetExpressionBaseModel {
    isRemoved: boolean;
  }

  export enum AutomationRuleComparisonTypes {
    Equals = 1,
    NotEquals = 2,
    GreaterThan = 3,
    GreaterThanOrEquals = 4,
    LessThan = 5,
    LessThanOrEquals = 6,
    HasAValue = 7,
    DoesNotHaveAValue = 8,
    Yesterday = 9,
    Today = 10,
    Tomorrow = 11,
    LastWeek = 12,
    ThisWeek = 13,
    ThisMonth = 14,
    LastMonth = 15,
    ThisYear = 16,
    LastYear = 17,
    Last365Days = 18,
    // DynamicDate = 19, // This exists in backend but not used so commented out so no confusion
    InCdt = 20,
    NotInCdt = 21
  }
}
