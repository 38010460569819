import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { AddOrgAPI } from '@core/typings/api/add-org.typing';
import { ApplicantForOrg } from '@core/typings/applicant.typing';
import { BucketSearchAPIResponse, ClassificationForOrgs, CompanyOrgDetail, NonprofitAdditionalDataApi, NonprofitAdminApi, NonprofitDataApi, NonprofitProfileSummary, NonprofitTop10, NonprofitTopLevelStats, OrgForDash } from '@core/typings/organization.typing';
import { ProcessingTypes } from '@core/typings/payment.typing';
import { AwardForOrg } from '@features/awards/typings/award.typing';
import { APIResultData, OrganizationSearchResponse, PaginatedResponse, PaginationOptions, SearchFilter, SearchResult } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class NonprofitResources {

  constructor (
    private httpRestService: HttpRestService,
    private portal: PortalDeterminationService
  ) { }

  getNonprofitByGuid (guid: string): Promise<NonprofitDataApi> {
    const endpoint = `/api/lookup/GetNppOrganization?nonprofitGuid=${guid}`;

    return this.httpRestService.get(endpoint);
  }

  getNonprofitAdminsByGuid (guid: string): Promise<NonprofitAdminApi[]>{
    const endpoint = `/api/manager/organizations/GetNonprofitAdmins?nonprofitGuid=${guid}`;

    return this.httpRestService.get(endpoint);
  }

  getNonprofitAdditionalDataByGuid (guid: string): Promise<NonprofitAdditionalDataApi> {
    const endpoint = `/api/lookup/GetNonprofitAdditionalData?nonprofitGuid=${guid}`;

    return this.httpRestService.get(endpoint);
  }

  getIdByNonprofitGuid (guid: string) {
    const endpoint = `api/portal/applicant/organization/GetIdByNonprofitGuid?nonprofitGuid=${guid}`;

    return this.httpRestService.get<string>(endpoint);
  }

  getCompanyOrgDetail (id: number): Promise<CompanyOrgDetail> {
    const endpoint = `api/manager/organizations/${id}`;

    return this.httpRestService.get(endpoint);
  }

  getOrgSummary (id: number): Promise<NonprofitProfileSummary> {
    const endpoint = `api/manager/applicationsdashboard/GetOrgSummary/${id}`;

    return this.httpRestService.get(endpoint);
  }

  getApplicantsForOrg (
    id: number,
    paginationOptions: PaginationOptions<ApplicantForOrg>
  ): Promise<PaginatedResponse> {
    const endpoint = `api/manager/applicationsdashboard/GetApplicantsForOrg/${id}`;

    return this.httpRestService.post(endpoint, {
      paginationOptions
    });
  }

  getAwardsForOrg (
    id: number,
    paginationOptions: PaginationOptions<AwardForOrg>
  ): Promise<PaginatedResponse> {
    const endpoint = `api/manager/applicationsdashboard/GetAwardsForOrg/${id}`;

    return this.httpRestService.post(endpoint, {
      paginationOptions
    });
  }

  async getNonprofitStats (
    programCycleIds: number[]
  ): Promise<NonprofitTopLevelStats> {
    const endpoint = 'api/manager/insights/organization/stats';

    return this.httpRestService.post(endpoint, {
      programCycleIds
    });
  }

  async getNonprofits (
    programCycleIds: number[]
  ): Promise<NonprofitTop10[]> {
    const endpoint = 'api/manager/insights/organization/topten';

    return this.httpRestService.post(endpoint, {
      programCycleIds
    });
  }

  async getClassifications (
    programCycleIds: number[]
  ): Promise<ClassificationForOrgs[]> {
    const endpoint = 'api/manager/insights/organization/classifications';

    return this.httpRestService.post(endpoint, {
      programCycleIds
    });
  }

  async getOrgsForInsights (
    paginationOptions: PaginationOptions<OrgForDash>,
    programCycleIds: number[]
  ) {
    const endpoint = 'api/manager/insights/organizations';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      programCycleIds
    });
  }

  searchPublicNonprofits (
    searchText: string,
    searchFilters: SearchFilter[] = [],
    pageNumber: number,
    rowsPerPage: number,
    andSimpleSearchFilters: boolean
  ) {
    const endpoint = 'api/lookup/SearchNpp';

    return this.httpRestService.post(endpoint, {
      searchText,
      searchFilters,
      pageNumber,
      rowsPerPage,
      andSimpleSearchFilters
    });
  }

  searchNonprofitsWithinBucket (
    terms: string,
    page: number,
    pageSize: number,
    clientId: number,
    bucketId: string,
    filters?: {
      state: string;
      country: string;
    }
  ): Promise<BucketSearchAPIResponse> {
    const endpoint = '/api/Charity/search/' + clientId + '/buckets/' + bucketId;

    return this.httpRestService.post(endpoint, {
      terms,
      page,
      pageSize,
      filters
    });
  }

  searchPrivateOrgs (
    clientId: number,
    paginationOptions: PaginationOptions<SearchResult>
  ): Promise<APIResultData<OrganizationSearchResponse>> {
    const endpoint = this.portal.isApply ?
      '/api/portal/applicant/ClientOrganizations' :
      '/api/manager/applications/ClientOrganizations';

    return this.httpRestService.post(endpoint, {
      clientId,
      paginationOptions
    });
  }

  searchPrivateOrgsWithFilters (
    paginationOptions: PaginationOptions<any>,
    grantProgramId: number,
    country: string,
    state: string,
    processorType: ProcessingTypes,
    clientId: number
  ): Promise<APIResultData<OrganizationSearchResponse>> {
    const endpoint = this.portal.isApply ?
      '/api/portal/applicant/ClientOrganizationsWithFilters' :
      '/api/manager/applications/ClientOrganizationsWithFilters';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      grantProgramId,
      country,
      state,
      processorType,
      clientId
    });
  }

  getVettingStatus (nonprofitGuid: string) {
    const endpoint = `api/VettingController/GetNppNonprofitVettingStatus?nonprofitGuid=${nonprofitGuid}`;

    return this.httpRestService.get(endpoint);
  }

  vetOrganization (payload: AddOrgAPI.VetOrgPayload) {
    const endpoint = `api/VettingController/AddOrVetOrganization`;

    return this.httpRestService.post(endpoint, payload);
  }

  createPrivateOrg (payload: AddOrgAPI.AddNonprofitModel) {
    const endpoint = this.portal.isApply ?
      'api/portal/applications/CreatePrivateOrg' :
      'api/manager/organizations/CreatePrivateOrg';

    return this.httpRestService.post(endpoint, payload);
  }

}

