import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { BaseHelpContent, HelpContent } from './help-content.typing';

@Injectable({ providedIn: 'root' })
export class HelpContentResources {
  constructor (
    private http: HttpRestService
  ) { }

  getContents (): Promise<HelpContent[]> {
    return this.http.get('/api/helpcontents');
  }

  saveHelpContent (content: BaseHelpContent) {
    return this.http.post('/api/admin/Configuration/HelpContent', content);
  }

  deleteHelpContent (id: number) {
    return this.http.delete('/api/admin/Configuration/HelpContent/' + id);
  }
}
