import { Component, Input, OnInit } from '@angular/core';
import { BaseApplication } from '@core/typings/application.typing';
import { FormDefinitionComponent, FormDefinitionForUi, FormValueChange } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { Tab } from '@yourcause/common';
import { YCModalComponent } from '@yourcause/common/modals';
import { cloneDeep } from 'lodash';
import { ComponentConfigurationService } from '../component-configuration.service';
import { ComponentConfigTabType, ComponentTabOptionsWithValue } from '../component-configuration.typing';

@Component({
  selector: 'gc-component-configuration-modal',
  templateUrl: './component-configuration-modal.component.html',
  styleUrls: ['./component-configuration-modal.component.scss']
})
export class ComponentConfigurationModalComponent extends YCModalComponent<FormDefinitionComponent> implements OnInit {
  @Input() component: FormDefinitionComponent;
  @Input() formId: number;
  @Input() isManagerForm = false;
  @Input() formDefinition: FormDefinitionForUi[];
  @Input() isViewOnly = false;

  componentForEdit: FormDefinitionComponent;
  modalOpen: boolean;
  parentFields: Partial<BaseApplication> = {
    amountRequested: null,
    currencyRequestedAmountEquivalent: null,
    reviewerRecommendedFundingAmount: null,
    referenceFields: {}
  };
  modalSubHeader: string;
  tabs: Tab<ComponentConfigTabType>[] = [];
  ConfigTypes = ComponentConfigTabType;
  activeTabType: ComponentConfigTabType;
  allTabsValid: boolean;
  tabValidityMap: Record<ComponentConfigTabType, boolean> = {
    [ComponentConfigTabType.Display]: false,
    [ComponentConfigTabType.Data]: false,
    [ComponentConfigTabType.Api]: false,
    [ComponentConfigTabType.Calculated_Value]: false,
    [ComponentConfigTabType.Conditional_Visibility]: false,
    [ComponentConfigTabType.Set_Value]: false,
    [ComponentConfigTabType.Validation]: false,
    [ComponentConfigTabType.Columns]: false
  };
  config: Record<ComponentConfigTabType, ComponentTabOptionsWithValue>;

  constructor (
    private componentConfigService: ComponentConfigurationService,
    private formBuilderService: FormBuilderService,
    private formHelperService: FormHelperService
  ) {
    super();
  }

  ngOnInit () {
    this.componentForEdit = cloneDeep(this.component);
    const {
      config,
      modalSubHeader
    } = this.componentConfigService.getComponentConfig(
      this.component,
      this.formBuilderService.currentFormBuilderFormAudience
    );
    this.config = config;
    this.modalSubHeader = modalSubHeader;
    this.setTabs();
  }

  setTabs () {
    this.tabs = this.componentConfigService.getTabsForComponentConfiguration(
      this.componentForEdit,
      this.formBuilderService.currentFormBuilderFormAudience
    );
    this.activeTabType = this.tabs[0].context;
  }

  activeTabChanged (index: number) {
    this.activeTabType = this.tabs[index].context;
  }

  tabValidityChange (isValid: boolean, tabType: ComponentConfigTabType) {
    this.tabValidityMap[tabType] = isValid;
    this.allTabsValid = Object.keys(this.tabValidityMap).every((key) => {
      const type = +key as ComponentConfigTabType;
      const tabIsVisible = this.tabs.some((tab) => {
        return tab.context === type;
      });
      if (tabIsVisible) {
        return this.tabValidityMap[type];
      }

      return true;
    });
  }

  onComponentPreviewChange (change: FormValueChange) {
    // The only attribute that can get set from the preview component
    // is the content component's HTML
    if (this.component.type === 'content') {
      this.componentForEdit.html = change.value as string;
    }
  }

  onCurrencySettingChange () {
    this.componentForEdit.value = this.formHelperService.getValueForCurrency(
      null,
      null,
      null,
      this.isManagerForm,
      this.componentForEdit.useCustomCurrency,
      this.componentForEdit.customCurrency,
      this.componentForEdit.defaultVal,
      null
    );
  }

  componentChange (component: FormDefinitionComponent) {
    this.componentForEdit = component;
  }

  saveChanges () {
    this.closeModal.emit(this.componentForEdit);
  }
}
