import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { Automation } from '@core/typings/ui/automation.typing';
import { Column } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { EmployeeSSOField, EmployeeSSOFieldsData } from './employee-sso-fields.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';

@RegisterYCState()
export class EmployeeSSOFieldsState extends BaseYcState {
  readonly employeeSSOFields: EmployeeSSOField[];
  readonly employeeSSOFieldData: EmployeeSSOFieldsData[];
  readonly employeeColumnsForAutomation: Automation.ObjectColumnConfig[];
  readonly applicantColumnsForReporting: AdHocReportingUI.DefaultColumnDefinition[];
  readonly dependentSsoFilteringOptions: TypeaheadSelectOption<Column>[];
}
