
<div *ngIf="formGroup" 
  [formGroup]="formGroup">
  <yc-input
    [name]="comp.key"
    [disabled]="true"
    [addRequiredAsterisk]="isRequired"
    [label]="label"
    [description]="description">
  </yc-input>
  <small>
    {{ translations[description] || description }}
  </small>
</div>
