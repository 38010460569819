import { AbstractControl } from '@angular/forms';
import { IsValidTypes, ValidationTypes } from '@features/forms/component-configuration/component-configuration.typing';
import { InKindRequestedItem } from '@features/in-kind/in-kind.typing';

export function TotalValueOfValidator (
  validationType: ValidationTypes,
  validationAmount: number,
  isValidType: IsValidTypes,
  customErrorMessage: string,
  defaultErrorMessage: string
) {
  return (control: AbstractControl) => {
    const value = control.value as InKindRequestedItem[] ?? [];
    const totalValue = value.reduce((acc, selectedItem) => {
      return acc + (+selectedItem.count || 0);
    }, 0);
    const willBeInvalid = isValidType === IsValidTypes.Invalid;
    let isValid = false;

    if (validationType === ValidationTypes.QuantityEqualTo) {
      if (willBeInvalid) {
        isValid = validationAmount !== totalValue;
      } else {
        isValid = validationAmount === totalValue;
      }
    } else if (validationType === ValidationTypes.QuantityLessThan) {
      if (willBeInvalid) {
        isValid = totalValue >= validationAmount;
      } else {
        isValid = totalValue < validationAmount;
      }
    } else if (validationType === ValidationTypes.QuantityGreaterThan) {
      if (willBeInvalid) {
        isValid = totalValue <= validationAmount;
      } else {
        isValid = totalValue > validationAmount;
      }
    }

    if (!isValid) {
      return {
        totalValueError: {
          i18nKey: '',
          defaultValue: '',
          errorMessage: customErrorMessage || defaultErrorMessage
        }
      };
    }

    return null;
  };

};
