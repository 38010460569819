import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { SimpleStringMap } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class FormFieldCategoryState extends BaseYcState {
  readonly categories: ReferenceFieldAPI.Category[];
  readonly categoryNameMap: SimpleStringMap<string> = {};
  readonly categoryOptions: TypeaheadSelectOption[];
  readonly categoryOptionsWithOther: TypeaheadSelectOption[];
}
