<div *ngIf="formGroup && isContentEditMode"
  [formGroup]="formGroup">
  <yc-rich-text-editor
    [name]="comp.key"
    [height]="600"
    [disabled]="disabled"
    (onChange)="onChange($event)">
  </yc-rich-text-editor>
</div>

<div *ngIf="!isContentEditMode"
  class="add-min-height"
  [class.add-border]="isFormBuilderView"
  [innerHTML]="(html || '') | escapeHtml">
</div>