export enum EmployeeSSOFields {
  Region = 1,
  EmployeeTypeCode = 2,
  ReportGroupName = 3, // business unit
  Division = 4,
  DepartmentName = 5,
  CustomChar1 = 6,
  CustomChar2 = 7,
  CustomChar3 = 8,
  CustomChar4 = 9,
  CustomChar5 = 10,
  CustomChar6 = 11,
  CustomChar7 = 12,
  CustomChar8 = 13,
  CustomChar9 = 14,
  CustomChar10 = 15,
  CustomChar11 = 22,
  CustomChar12 = 23,
  CustomChar13 = 24,
  ExternalEmployeeId2	= 16,
  FullTimeCodeId = 17,
  HireDate = 18,
  JobCodeId	= 19,
  SiteCodeId = 20,
  ExternalEmployeeId = 21,
  PositionTitle = 25,
  Market = 26,
  ManagerEmployeeId = 27,
  MemberId = 28,
  AffiliateCorpName = 29,
  PayCurrencyCode = 30,
  PayPeriods = 31,
  PayrollCenter = 32,
  PayrollId = 33,
  MailStop = 34,
  HomeAddress = 35,
  HomeAddress2 = 36,
  HomeCity = 37,
  HomeState = 38,
  HomeZipCode = 39,
  HomeCountryCode = 40,
  ShiftCode = 41,
  EmployeeType = 42,
  EmployeeStatus = 43,
  SubCompanyCode = 44,
  PayrollType = 45,
  PayrollFilter = 46,
  MatchType = 47,
  CustomSegment = 48,
  IsEmployeeActive = 49,
}

export const DATE_SSO_FIELDS = [
  'hireDate'
];

export interface EmployeeSSOField {
  name?: string;
  inUse: boolean;
  column: EmployeeSSOFields;
  columnName?: string;
  attr?: string;
  type?: 'text'|'date'|'number';
  order?: number;
}

export interface EmployeeSSOFieldFromApi {
  name: string;
  inUse: boolean;
  column: EmployeeSSOFields;
  order?: number;
}

export interface EmployeeSSOFieldsData {
  region: string;
  employeeTypeCode: string;
  reportGroupName: string;
  division: string;
  departmentName: string;
  customChar1: string;
  customChar2: string;
  customChar3: string;
  customChar4: string;
  customChar5: string;
  customChar6: string;
  customChar7: string;
  customChar8: string;
  customChar9: string;
  customChar10: string;
  customChar11: string;
  customChar12: string;
  customChar13: string;
  externalEmployeeId2: string;
  fullTimeCodeId: string;
  hireDate: string;
  jobCodeId: string;
  siteCodeId: string;
  externalEmployeeId: string;
  positionTItle: string;
  market: string;
  managerEmployeeId: string;
  memberId: string;
  affiliateCorpName: string;
  payCurrencyCode: string;
  payPeriods: string;
  payrollCenter: string;
  payrollId: string;
  mailStop: string;
  homeAddress: string;
  homeAddress2: string;
  homeCity: string;
  homeState: string;
  homeZipCode: string;
  homeCountryCode: string;
  shiftCode: string;
  employeeType: string;
  employeeStatus: string;
  subCompanyCode: string;
  payrollType: string;
  payrollFilter: string;
  matchType: string;
  customSegment: string;
  isEmployeeActive: string;
}

export interface EmployeeSSOFieldBulkFromApi extends EmployeeSSOFieldsData {
  applicationId: number;
}
