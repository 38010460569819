import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { LocationService } from './location.service';

@Injectable({ providedIn: 'root' })
export class DeepLinkingService {
  static AttemptedRoutePlatformKey = `_yc_attemptedRoutePlatform`;
  static AttemptedRouteManagerKey =  `_yc_attemptedRouteManager`;
  static AttemptedRouteApplicantKey = `_yc_attemptedRouteApplicant`;

  get attemptedRouteApplicant (): string {
    return localStorage.getItem(DeepLinkingService.AttemptedRouteApplicantKey);
  }
  set attemptedRouteApplicant (value: string) {
    localStorage.setItem(DeepLinkingService.AttemptedRouteApplicantKey, value);
  }

  get attemptedRouteManager (): string {
    return localStorage.getItem(DeepLinkingService.AttemptedRouteManagerKey);
  }
  set attemptedRouteManager (value: string) {
    localStorage.setItem(DeepLinkingService.AttemptedRouteManagerKey, value);
  }

  get attemptedRoutePlatform (): string {
    return localStorage.getItem(DeepLinkingService.AttemptedRoutePlatformKey);
  }
  set attemptedRoutePlatform (value: string) {
    localStorage.setItem(DeepLinkingService.AttemptedRoutePlatformKey, value);
  }

  constructor (
    private portal: PortalDeterminationService,
    private router: Router,
    private locationService: LocationService
  ) {
    setTimeout(() => {
      this.init();
    });
  }

  private init () {
    const handedOff = localStorage.getItem('handedOff');
    if (handedOff === 'true') {
      this.attemptDeepLink(location.pathname);

      localStorage.removeItem('handedOff');
    }
  }

  setAttemptedRoute (route: string) {
    if (this.portal.isApply) {
      return this.setAttemptedRouteApplicant(route);
    } else if (this.portal.isManager) {
      return this.setAttemptedRouteManager(route);
    } else if (this.portal.isPlatform) {
      return this.setAttemptedRoutePlatform(route);
    }
  }


  getAttemptedRoute () {
    if (this.portal.isApply) {
      return this.attemptedRouteApplicant;
    } else if (this.portal.isManager) {
      return this.attemptedRouteManager;
    }

    return this.attemptedRoutePlatform;
  }

  attemptDeepLink (route: string, queryParams?: Params) {
    const existingRoute = this.getAttemptedRoute();
    let routeToUse = existingRoute || route;
    let extras = this.getExtraRouteParams(queryParams);
    const routeParams = this.locationService.extractQueryParamsFromUrl<Record<string, string>>(routeToUse);
    if (!!routeParams) {
      const routeHasParams = Object.keys(routeParams).length > 0;
      if (routeHasParams) {
        const splitArray = routeToUse.split('?');
        routeToUse = splitArray[0];
        if (!!extras) {
          extras = {
            queryParams: {
              ...extras.queryParams,
              ...routeParams
            }
          };
        } else {
          extras = {
            queryParams: {
              ...routeParams
            }
          };
        }
      }
    }

    if (!!extras) {
      this.router.navigate([routeToUse], extras);
    } else {
      this.router.navigateByUrl(routeToUse);
    }
    this.setAttemptedRoute('');
  }

  getExtraRouteParams (queryParams: Params) {
    if (!!queryParams) {
      return {
        queryParams
      };
    }

    return null;
  }

  setAttemptedRouteApplicant (route: string) {
    this.attemptedRouteApplicant = route;
  }

  setAttemptedRouteManager (route: string) {
    this.attemptedRouteManager = route;
  }

  setAttemptedRoutePlatform (route: string) {
    this.attemptedRoutePlatform = route;
  }
}
