import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { ComponentHelperService, Total_Columns } from '@features/forms/services/component-helper/component-helper.service';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';

@Component({
  selector: 'gc-column-properties',
  templateUrl: './column-properties.component.html',
  styleUrls: ['./column-properties.component.scss']
})
export class ColumnPropertiesComponent extends BaseComponentConfigSettingsComponent {
  @Input() columns: FormDefinitionComponent[];
  @Output() isValidChange = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<FormDefinitionComponent[]>();

  isValid = true;

  constructor (
    private componentHelper: ComponentHelperService
  ) {
    super();
  }

  addColumn () {
    this.columns = [
      ...this.columns,
      this.componentHelper.getBlankColumnComponent(this.columns)
    ];
    this.emitChanges();
  }

  removeRow (index: number) {
    this.columns = [
      ...this.columns.slice(0, index),
      ...this.columns.slice(index + 1)
    ];
    this.emitChanges();
  }

  handleChange (
    changeAttr: 'width'|'push'|'pull'|'offset',
    changeValue: number,
    index: number
  ) {
    this.columns = [
      ...this.columns.slice(0, index),
      {
        ...this.columns[index],
        [changeAttr]: changeValue
      },
      ...this.columns.slice(index + 1)
    ];
    this.emitChanges();
  }

  emitChanges () {
    const totalWidth = this.componentHelper.getTotalColumnWidth(this.columns);
    this.isValid = totalWidth <= Total_Columns;
    this.isValidChange.emit(this.isValid);
    this.onChange.emit(this.columns);
  }

  drop (columns: FormDefinitionComponent[]) {
    this.columns = columns;
    this.emitChanges();
  }
}
