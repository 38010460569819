<yc-modal [ycHidden]="hideModal"
  [modalHeader]="'common:hdrRestoreDeletedComponents' | ycI18n: 'Restore Deleted Components'"
  [primaryButtonText]="'common:textRestore' | ycI18n: 'Restore'"
  [primaryDisabled]="selectedComps.length === 0"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  (onPrimaryClick)="closeModal.emit(selectedComps)"
  (onCancel)="closeModal.emit()">

  <div class="mb-3">
    <yc-i18n
      key="common:textSelectComponentsToRestore"
      defaultValue="Select the components to restore. The selected components will be added back to the bottom of the current page.">
    </yc-i18n>
  </div>

  <yc-table
    [rows]="compsForDisplay"
    [topLevelFilters]="topLevelFilters"
    [bulkOptions]="bulkOptions"
    [hideTableOptions]="true"
    [hideTableFilters]="true"
    [bulkSelected]="selectedComps"
    [hidePagination]="true"
    [maxHeightBeforeScroll]="'60vh'"
    (bulkSelectedChanged)="selectedComps = $event">

    <!-- Name -->
    <ng-container *ycTableColumn="'common:lblName';
      let row;">
      {{ row.name }}
    </ng-container>
  
    <!-- Label -->
    <ng-container *ycTableColumn="'common:hdrLabel';
      let row;">
      {{ row.label }}
    </ng-container>

    <!-- Description -->
    <ng-container *ycTableColumn="'common:textDescription';
      visible: showDescription,
      let row;">
      {{ row.description }}
    </ng-container>

    <!-- Required  -->
    <ng-container *ycTableColumn="'common:textRequired';
      let row;">
      <yc-i18n
        [key]="row.validate?.required ?
          'common:textYes' :
          'common:textNo'"
        [defaultValue]="row.validate?.required ?
          'Yes' :
          'No'">
      </yc-i18n>
    </ng-container>

    <!-- Actions -->
    <ng-container *ycTableColumn="''
      let row;">
      <yc-button
        [link]="true"
        [fontAwesomeIcon]="'eye'"
        [tooltipText]="'common:textViewConfiguration' | ycI18n: 'View configuration'"
        (onClick)="viewConfig(row)">
      </yc-button>
    </ng-container>
  </yc-table>

</yc-modal>