<yc-modal
  [modalHeader]="modalHeader"
  [modalSubHeader]="modalSubHeader"
  [primaryButtonText]="'common:textClose' | ycI18n: 'Close'"
  (onPrimaryClick)="closeModal.emit()">

  <gc-manage-audience-table
    [selectedUsers]="members"
    [isEditMode]="false"
    [tableKey]="tableKey">
  </gc-manage-audience-table>
</yc-modal>