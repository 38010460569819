import { Component, Input, OnInit } from '@angular/core';
import { FormAudience, FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { BulkOptions, TopLevelFilter } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
import { BucketComp } from '../form-builder.typing';
import { FormBuilderService } from '../services/form-builder/form-builder.service';
interface FormDefinitionComponentForRestore extends FormDefinitionComponent {
  name: string;
}

@Component({
  selector: 'gc-restore-deleted-components-modal',
  templateUrl: './restore-deleted-components-modal.component.html',
  styleUrls: ['./restore-deleted-components-modal.component.scss']
})
export class RestoreDeletedComponentsModalComponent extends YCModalComponent<FormDefinitionComponent[]> implements OnInit {
  @Input() deletedComponents: FormDefinitionComponent[];
  @Input() bucketComps: BucketComp[];

  topLevelFilters: TopLevelFilter[] = [
    new TopLevelFilter(
      'text',
      'nameOrKey',
      '',
      this.i18n.translate(
        'common:textSearchByNameLabel',
        {},
        'Search by name or label'
      ),
      undefined,
      undefined,
      [{
        column: 'name',
        filterType: 'cn'
      }, {
        column: 'label',
        filterType: 'cn'
      }]
    )
  ];
  compsForDisplay: FormDefinitionComponentForRestore[] = [];
  selectedComps: FormDefinitionComponentForRestore[] = [];
  bulkOptions: BulkOptions<FormDefinitionComponentForRestore> = {
    recordIdentifierAttribute: 'type',
    onlyForTrackingRows: true,
    retainBulkSelectedItems: true,
    bulkActions: []
  };
  showDescription = false;
  hideModal = false;
  
  constructor (
    private formBuilderService: FormBuilderService,
    private i18n: I18nService
  ) {
    super();
  }

  ngOnInit () {
    this.compsForDisplay = this.deletedComponents.map<FormDefinitionComponentForRestore>((comp) => {
      const foundBucketComp = this.bucketComps.find((bucketComp) => {
        return comp.type === bucketComp.type;
      });

      return {
        ...comp,
        name: foundBucketComp?.name ?? comp.label
      };
    });
    this.showDescription = this.compsForDisplay.some((comp) => !!comp.description);
  }

  async viewConfig (component: FormDefinitionComponentForRestore) {
    this.hideModal = true;
    await this.formBuilderService.editComponentModal(
      component,
      this.formBuilderService.currentFormBuilderFormId,
      this.formBuilderService.currentFormBuilderDefinition,
      this.formBuilderService.currentFormBuilderFormAudience === FormAudience.MANAGER,
      true
    );
    this.hideModal = false;
  }
}
