
<div *ngIf="ready &&
  parentFormGroup &&
  !hideField" 
  [formGroup]="parentFormGroup">
  <ng-container [ngSwitch]="inputType">
    <!-- Typeahead Select -->
    <div *ngSwitchCase="InputTypes.CustomDataTable">

      <yc-typeahead-select
        [name]="compKey"
        [items]="options"
        [multi]="supportsMultiple"
        [showClear]="!defaultVal"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [tabIndex]="tabIndex"
        [tooltipText]="tooltipText"
        [showQuestionMarkForTooltip]="true"
        [addRequiredAsterisk]="requiredOverride || isRequired"
        [label]="label"
        [description]="description"
        [srOnlyLabel]="hideLabel"
        [customLabelIcon]="customLabelIcon"
        [customLabelIconTooltip]="customLabelIconTooltip"
        [customLabelIconClass]="customLabelIconClass"
        (onChange)="dataChanged($event)">
      </yc-typeahead-select>

    </div>

    <!-- Radio Buttons -->
    <div *ngSwitchCase="InputTypes.Radio">

      <yc-radio-buttons *ngIf="options?.length > 0"
        [label]="label"
        [description]="description"
        [srOnlyLabel]="hideLabel"
        [tooltipText]="tooltipText"
        [showQuestionMarkForTooltip]="true"
        [customLabelIcon]="customLabelIcon"
        [customLabelIconTooltip]="customLabelIconTooltip"
        [customLabelIconClass]="customLabelIconClass"
        [addRequiredAsterisk]="requiredOverride || isRequired"
        [name]="compKey"
        [disabled]="disabled"
        [options]="options"
        [controlled]="true"
        [tabIndex]="tabIndex"
        [sorted]="false"
        [horizontal]="inline"
        [minWidthForOptions]="inline ? '200px' : ''"
        (onChange)="dataChanged($event)">
      </yc-radio-buttons>

    </div>

    <!-- Select Boxes -->
    <div *ngSwitchCase="InputTypes.SelectBoxes">
  
      <yc-checkbox-group
        [label]="label"
        [description]="description"
        [srOnlyLabel]="hideLabel"
        [tooltipText]="tooltipText"
        [showQuestionMarkForTooltip]="true"
        [customLabelIcon]="customLabelIcon"
        [customLabelIconTooltip]="customLabelIconTooltip"
        [customLabelIconClass]="customLabelIconClass"
        [addRequiredAsterisk]="requiredOverride || isRequired"
        [name]="compKey"
        [disabled]="disabled"
        [options]="options"
        [grouped]="inline"
        [maxPerColumn]="options.length / 3"
        [tabIndex]="tabIndex"
        (onChange)="dataChanged($event)">
      </yc-checkbox-group>

    </div>
  </ng-container>
</div>

