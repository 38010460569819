import { APIExternalAPI } from '@core/typings/api/external-api.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { Subject } from 'rxjs';



@RegisterYCState()
export class ExternalAPIState extends BaseYcState {
  readonly requests: Record<string, Subject<APIExternalAPI.ExternalAPIResponse>>;
  readonly integrations: APIExternalAPI.SimpleExternalAPIConfiguration[] = [];
}
