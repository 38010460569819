import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageService {
  private readonly clientbrandingKey = '_clientBranding';

  getClientBranding () {
    return this.getObject(this.clientbrandingKey);
  }

  setClientBranding<T> (branding: T) {
    this.setItem(this.clientbrandingKey, branding);
  }

  clearClientBranding () {
    this.clearItem(this.clientbrandingKey);
  }

  setItem (key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getObject (key: string) {
    let value = null;
    const raw = localStorage.getItem(key);
    if (raw) {
      value = JSON.parse(raw);
    }

    return value;
  }

  clearItem (key: string) {
    localStorage.removeItem(key);
  }

}

