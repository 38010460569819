<div *ngIf="countrySelects.length > 0"
  class="container-fluid p-0">
  <div [formGroup]="addressFormGroup" 
    class="form" 
    role="form">
    <div class="row">
      <div class="col-md-6">
        <!-- Address 1 -->
        <yc-input
          name="address1"
          [disabled]="disableInputs"
          [label]="'GLOBAL:ADDRESS_LINE_ONE' | ycI18n: 'Address line 1'">
        </yc-input>
      </div>
      <div class="col-md-6">
        <!-- Address 2 -->
        <yc-input
          name="address2"
          [disabled]="disableInputs"
          [label]="'common:textAddressLine2' | ycI18n: 'Address line 2'">
        </yc-input>
      </div>
      <div [class.col-md-6]="regionsExist"
        [class.col-md-4]="!regionsExist">
        <!-- Country -->
        <yc-typeahead-select
          name="countryCode"
          [label]="'GLOBAL:COUNTRY' | ycI18n: 'Country'"
          [items]="countrySelects"
          [disabled]="countryDisabled || disableInputs"
          (onChange)="setRegionsOnCountryChange()">
        </yc-typeahead-select>
      </div>
      <div [class.col-md-6]="regionsExist"
        [class.col-md-4]="!regionsExist">
        <!-- City -->
        <yc-input
          name="city"
          [disabled]="disableInputs"
          [label]="'GLOBAL:CITY' | ycI18n: 'City'">
        </yc-input>
      </div>
      
      <div [ycHidden]="!regionsExist"
        class="col-md-6">
        <!-- State -->
        <yc-typeahead-select
          name="stateProvRegCode"
          [label]="'GLOBAL:lblState' | ycI18n: 'State, province or region'"
          [disabled]="disableInputs"
          [items]="regionSelects"
          [placeholder]="'GLOBAL:textSelectStateProvRegion' | ycI18n: 'Select state, province, or region'">
        </yc-typeahead-select>
      </div>
      <div [class.col-md-6]="regionsExist"
        [class.col-md-4]="!regionsExist">
        <!-- Postal Code -->
        <yc-input
          name="postalCode"
          [disabled]="disableInputs"
          [label]="'GLOBAL:lblPostalCode' | ycI18n: 'Postal code'">
        </yc-input>
      </div>
    </div>
  </div>
</div>