import { Component, OnDestroy } from '@angular/core';
import { GcFlyoutService } from '@core/services/gc-flyout.service';
import { PolicyService } from '@core/services/policy.service';
import { Budget, BudgetDetail, BudgetFundingSourceAudit, BudgetProgram, FundingSourceTypes } from '@core/typings/budget.typing';
import { SystemTags } from '@features/system-tags/typings/system-tags.typing';
import { TrackingEventNames } from '@yourcause/common/heap';
import { I18nService } from '@yourcause/common/i18n';
import { Subscription } from 'rxjs';
import { BudgetService } from '../budget.service';
import { SupportService } from '@features/support/support.service';
import { AccordionStyle } from '@yourcause/common/accordion';

@Component({
  selector: 'gc-budget-detail-flyout',
  templateUrl: './budget-detail-flyout.component.html',
  styleUrls: ['./budget-detail-flyout.component.scss']
})
export class  BudgetDetailFlyoutComponent implements OnDestroy {
  FundingSourceTypes = FundingSourceTypes;
  AccordionStyle = AccordionStyle;
  Buckets = SystemTags.Buckets;
  budgetHelpLink = 'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-faq-what-do-budget-attributes-mean.html';
  hasInsightsPermission = this.policyService.insights.canViewBudgets();
  budget: Budget;
  budgetDetail: BudgetDetail;
  auditTrail: BudgetFundingSourceAudit[] = [];
  programs: BudgetProgram[] = [];
  programSubHeader: string;
  sub = new Subscription();

  constructor (
    private budgetService: BudgetService,
    private policyService: PolicyService,
    private gcFlyoutService: GcFlyoutService,
    private i18n: I18nService,
    private supportService: SupportService
  ) {
    this.sub.add(this.budgetService.changesTo$('budgetDrilldownMap').subscribe(() => {
      this.setHelpers();
    }));
    this.sub.add(this.gcFlyoutService.changesTo$('currentIdForFlyout').subscribe(() => {
      this.setHelpers();
    }));
    this.sub.add(this.budgetService.changesTo$('budgetDetailMap').subscribe(() => {
      this.setHelpers();
    }));
    this.sub.add(this.budgetService.changesTo$('budgetAuditTrailMap').subscribe(() => {
      this.setHelpers();
    }));
  }

  get budgetId () {
    return +this.gcFlyoutService.currentIdForFlyout;
  }

  setHelpers () {
    this.budget = this.budgetService.simpleBudgetMap[this.budgetId];
    const detail = this.budgetService.budgetDetailMap[this.budgetId];
    if (!!detail) {
      this.budgetDetail = detail;
    }
    this.auditTrail = this.budgetService.budgetAuditTrailMap[this.budgetId] ?? [];
    const drilldownInfo = this.budgetService.budgetDrilldownMap[this.budgetId];
    if (!!drilldownInfo) {
      this.programs = drilldownInfo.grantProgramInfos ?? [];
      this.programSubHeader = this.i18n.translate(
        this.programs.length === 1 ? 'BUDGET:textOneProgramUsingThisBudget' : 'BUDGET:textNumberProgramsUsingBudget',
        {
          number: this.programs.length
        },
        this.programs.length === 1 ?
          '__number__ program using this budget' :
          '__number__ programs using budget'
      );
    }
  }

  editBudget () {
    this.budgetService.navigateToBudgetDetail(this.budgetId);
  }

  viewInsights () {
    this.budgetService.navigateToBudgetInsights(this.budgetId);
  }

  trackClick (link: string) {
    this.supportService.trackClick(TrackingEventNames.HelpLink, link)
  }
  
  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
