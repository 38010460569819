import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { BulkClientEmailTranslationsPayload, ClientEmailTemplateFromAPI, ClientEmailTranslatedTemplate, CommunicationPrefs, Email, EmailNotificationType, EmailTemplateCopyForAPI, EmailToken, ProgramEmailTemplateFromAPI, SendTestEmailPayload, UpdateCommPrefsPayload } from '@features/system-emails/email.typing';
import { UserService } from '@features/users/user.service';
import { PaginationOptions } from '@yourcause/common';
import { ImageUploadPayload } from '@yourcause/common/rich-text-editor';

@Injectable({ providedIn: 'root' })
export class EmailResources {

  constructor (
    private httpRestService: HttpRestService,
    private userService: UserService
  ) { }

  get languageId () {
    return this.userService.getCurrentUserCulture();
  }

  getProgramActiveCount (id: number): Promise<{
    type: EmailNotificationType;
    count: number;
  }[]> {
    const endpoint = `api/manager/clientemailtemplates/Copies/${id}/Count`;

    return this.httpRestService.get(endpoint);
  }

  getClientTranslatedTemplate (
    langId: string,
    templateId: number
  ): Promise<ClientEmailTranslatedTemplate> {
    const endpoint = `api/manager/clientemailtemplates/Template/${templateId}?languageId=${langId}`;

    return this.httpRestService.get(endpoint);
  }

  getEmailTemplates (
    paginationOptions: PaginationOptions<any>
  ): Promise<{
    records: Email[];
    recordCount: number;
  }> {
    const endpoint = 'api/manager/emails/Templates';

    return this.httpRestService.post(
      endpoint,
      { paginationOptions }
    );
  }

  async getClientTemplatesForEmail (
    type: EmailNotificationType,
    languageId = this.languageId
  ): Promise<ClientEmailTemplateFromAPI[]> {
    const endpoint = `api/manager/clientemailtemplates/Copies/${type}?languageId=${languageId}`;
    const templates = await this.httpRestService.get(endpoint);

    return templates.filter((template: any) => !!template.id);
  }

  getProgramTemplatesByEmailType (
    programId: number,
    type: EmailNotificationType,
    languageId = this.languageId
  ): Promise<ProgramEmailTemplateFromAPI[]> {
    const endpoint = `api/manager/clientemailtemplates/Copies/${type}/${programId}?languageId=${languageId}`;

    return this.httpRestService.get(endpoint);
  }

  getTokens (type: EmailNotificationType): Promise<EmailToken[]> {
    const endpoint = `api/manager/emailtemplates/Tokens/${type}`;

    return this.httpRestService.get(endpoint);
  }

  getEmailTemplate (type: number): Promise<{ template: string }> {
    const endpoint = `api/manager/emails/Templates/${type}`;

    return this.httpRestService.get(endpoint);
  }

  toggleActivateEmail (type: EmailNotificationType) {
    const endpoint = `api/manager/emails/Templates/${type}/ToggleDisable`;

    return this.httpRestService.post(endpoint, {});
  }

  activateClientTemplate (id: number) {
    const endpoint = `api/manager/clientemailtemplates/Template/${id}/Activate`;

    return this.httpRestService.get(endpoint);
  }

  deactivateClientTemplate (id: number) {
    const endpoint = `api/manager/clientemailtemplates/Template/${id}`;

    return this.httpRestService.delete(endpoint, {});
  }

  sendYouHaveBeenNominatedEmail (
    applicationId: number,
    emailQueueId: number
  ) {
    const endpoint = 'api/manager/applications/SendApprovedNomineeInviteEmail';

    return this.httpRestService.post(endpoint, {
      applicationId,
      emailQueueId
    });
  }

  createCopy (copy: EmailTemplateCopyForAPI): Promise<number> {
    return this.httpRestService.post('/api/manager/clientemailtemplates/Template', copy);
  }

  sendTestEmail (data: SendTestEmailPayload) {
    const endpoint = 'api/manager/clientemailtemplates/SendTestEmail';

    return this.httpRestService.post(endpoint, data);
  }

  getCommunicationPrefs (): Promise<CommunicationPrefs[]> {
    const endpoint = 'api/manager/communications/GetUserCommunicationPreferences';

    return this.httpRestService.get(endpoint);
  }

  updateCommunicationPrefs (payload: UpdateCommPrefsPayload) {
    const endpoint = 'api/manager/communications/UpdateUserEmailFrequency';

    return this.httpRestService.post(endpoint, payload);
  }

  bulkAddTranslationToClientEmail (payload: BulkClientEmailTranslationsPayload) {
    const endpoint = 'api/manager/clientemailtemplates/BulkAddTranslationsToClientEmailTemplate';

    return this.httpRestService.post(endpoint, payload);
  }

  addAttachmentToEmailTemplate (
    file: File,
    applicationId?: number,
    clientEmailTemplateId?: number,
    filesCombinedSize?: number
  ): Promise<number> {
    const endpoint = `api/manager/emailTemplates/attachment?applicationId=${applicationId || ''}&clientemailtemplateId=${clientEmailTemplateId || ''}&filesCombinedSize=${filesCombinedSize || ''}`;

    return this.httpRestService.postFile(endpoint, file);
  }

  async uploadEmailImage (
    uploadRequest: ImageUploadPayload
  ): Promise<string> {
    const endpoint = 'api/manager/clientemailtemplates/UploadInlineImage';
    const response = await this.httpRestService.postFile<{ file: string }>(
      endpoint,
      uploadRequest.blob,
      undefined,
      uploadRequest.fileName
    );

    return response.file;
  }

}
