import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';
interface DepPicklistOptionsFormGroup {
  parentPicklistName: string;
  hideWithoutParentVal: boolean;
}

@Component({
  selector: 'gc-dependent-picklist-options-settings',
  templateUrl: './dependent-picklist-options-settings.component.html',
  styleUrls: ['./dependent-picklist-options-settings.component.scss']
})
export class DependentPicklistOptionsSettingsComponent extends BaseComponentConfigSettingsComponent implements OnInit {
  @Input() hideWithoutParentVal: boolean;
  @Input() compType: string;
  @Output() onChange = new EventEmitter<boolean>();

  formGroup: TypeSafeFormGroup<DepPicklistOptionsFormGroup>;

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private formFieldHelperService: FormFieldHelperService
  ) {
    super();
  }

  ngOnInit () {
    const field = this.formFieldHelperService.getReferenceFieldFromCompType(
      this.compType
    );
    const parentPicklistName = this.formFieldHelperService.referenceFieldMapById[
      field.parentReferenceFieldId
    ]?.name ?? '';

    this.formGroup = this.formBuilder.group<DepPicklistOptionsFormGroup>({
      parentPicklistName,
      hideWithoutParentVal: this.hideWithoutParentVal
    });
  }
}
