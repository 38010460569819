<!-- Loop over column components, to get to "components" array for each column -->
<ng-container *ngIf="formComponent">
  <div *ngFor="let columnComponent of formComponent.columns; let last = last;"
    [class.pe-3]="!last"
    [ngClass]="columnComponent | gcGetColumnClass"
    [class.editable]="editable"
    cdkDropList
    [cdkDropListSortingDisabled]="false"
    [cdkDropListDisabled]="!editable"
    [cdkDropListData]="columnComponent.components"
    (cdkDropListDropped)="handleDropEvent($event)">

    <!-- No components -->
    <gc-form-builder-empty-container *ngIf="(editable || inConfigModal) &&
      !columnComponent.components?.length">
    </gc-form-builder-empty-container>

    <div *ngFor="let component of columnComponent.components; trackBy: trackBy"
      cdkDrag
      [cdkDragData]="component"
      [class.mb-4]="!component.isHidden && !component.hiddenFromParent">
      <gc-form-component-display
        [depth]="depth"
        [editable]="editable"
        [formId]="parentFields.formId"
        [translations]="translations"
        [notAutoSave]="notAutoSave"
        [masked]="masked"
        [hidden]="component.hidden"
        [disabledOverride]="disabledOverride"
        [showErrorSummary]="showErrorSummary"
        [errorMessages]="errorMessages"
        [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
        [isManagerForm]="isManagerForm"
        [validityState]="validityState"
        [component]="component | ycAs: $baseComponent"
        [value]="component.value"
        [isFormBuilderView]="isFormBuilderView"
        [parentFields]="parentFields"
        [refIdsChanged]="refIdsChanged"
        [standardFieldsChanged]="standardFieldsChanged"
        (onValueChange)="valueChanged($event, component)"
        (componentDropped)="handleDropEvent($event)"
        (componentActionClick)="componentActionClick.emit($event)"
        (onValidChange)="onValidChange.emit($event)">
      </gc-form-component-display>
    </div>
  </div>
</ng-container>
