import { Pipe, PipeTransform } from '@angular/core';
import { LogicBuilderService } from '../logic-builder.service';
import { EvaluationType } from '../logic-builder.typing';

@Pipe({
  name: 'ycShowConditionalLogicBuilder'
})
export class ShowConditionalLogicBuilderPipe implements PipeTransform {

  constructor (
    private logicBuilderService: LogicBuilderService
  ) { }

  transform (evaluationType: EvaluationType) {
    return this.logicBuilderService.getHasConditionalLogic(evaluationType);
  }
}
