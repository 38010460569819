<ng-container *ngIf="formDefinition">

  <!-- Nomination Header -->
  <ng-container *ngIf="isNominationForm && !!response && !!nominationForm">
    <gc-nomination-form-header
      [forApplicant]="true"
      [forOrg]="!!application.organizationId"
      [nomination]="nominationForm"
      [readOnly]="!editing ||
        application.applicationStatus === ApplicationStatuses.Approved ||
        application.applicationStatus === ApplicationStatuses.Declined"
      [markForValidity]="markForValidity"
      (onValidityChange)="onNomineeValidityChange($event)"
      (formChanged)="nominationFormChanged($event)">
    </gc-nomination-form-header>
  </ng-container>

  <gc-form-renderer *ngIf="!hideForm"
    #formRenderer
    [masked]="masked"
    [applicationFormId]="response.applicationFormId"
    [applicationId]="application.applicationId"
    [formId]="formId"
    [formRevisionId]="form.formRevisionId"
    [formType]="currentFormType"
    [form]="formDefinition"
    [triggerSubmit]="formSubmit"
    [externalFields]="application"
    [requireSignature]="requireSignature"
    [signatureDescription]="signatureDescription"
    [refIdsChanged]="refIdsChanged"
    [standardFieldsChanged]="standardFieldsChanged"
    [supportsBypassSignature]="true"
    [readOnly]="readOnly"
    [scrollBoxClass]="scrollBoxClass"
    [orgId]="!!application?.organizationId ? +application.organizationId : null"
    [hideSubmitButton]="true"
    [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
    (onChange)="onResponseChange($event)"
    (onConditionalVisibilityStateChanged)="conditionalVisibilityState = $event"
    (onTranslationsReady)="setTranslations($event)">
  </gc-form-renderer>


  <yc-status-floater *ngIf="!readOnly && statusText"
    [statusText]="statusText"
    [statusIcon]="statusIcon"
    [statusColor]="'primary'"
    [position]="position">
  </yc-status-floater>

</ng-container>

