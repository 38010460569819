import { Injectable } from '@angular/core';
import { TokenResponse } from '@core/typings/token.typing';
import { LogService } from '@yourcause/common/logging';
import { GuidService } from '@yourcause/common/utils';
import { AuthBehaviors } from './token-behaviors';

@Injectable({ providedIn: 'root' })
export class TokenStorageService {
   constructor (
    private logger: LogService,
    private behavior: AuthBehaviors,
    private guidService: GuidService
  ) { }

  set jwt (jwt: TokenResponse) {
    localStorage.setItem(this.behavior.current.userTokenKey, JSON.stringify(jwt));
  }

  get jwt () {
    const token = localStorage.getItem(this.behavior.current.userTokenKey);
    if (!token || token === 'undefined') {
      return null;
    }

    try {
      return JSON.parse(token) as TokenResponse;
    } catch (e) {
      this.logger.error(e, {
        message: 'Error parsing JWT got: ' + token
      });
    }

    return null;
  }

  revoke () {
    localStorage.removeItem(this.behavior.current.userTokenKey);
  }

  overrideClientIdentifier (id: string) {
    localStorage.setItem(this.behavior.current.clientIdentifierKey, id);
  }

  get clientIdentifier () {
    let identifier = localStorage.getItem(this.behavior.current.clientIdentifierKey);

    if (!identifier) {
      identifier = this.guidService.nonce();
      localStorage.setItem(this.behavior.current.clientIdentifierKey, identifier);
    }

    return identifier;
  }
}
