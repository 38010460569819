import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { TypeSafeFormBuilder, TypeSafeFormGroup, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
export enum DownloadFormVisibility {
  SHOW_ALL = 1,
  SHOW_CONDITIONAL = 2
}
export interface DownloadFormModalResponse {
  visibility: DownloadFormVisibility;
  includeFormVersion: boolean;
}

@Component({
  selector: 'gc-download-form-modal',
  templateUrl: './download-form-modal.component.html',
  styleUrls: ['./download-form-modal.component.scss']
})
export class DownloadFormModalComponent extends YCModalComponent<
  DownloadFormModalResponse
> implements OnInit {
  @Input() formName: string;

  formGroup: TypeSafeFormGroup<DownloadFormModalResponse>;
  visibilityOptions: TypeaheadSelectOption[] = [{
    label: this.i18n.translate(
      'GLOBAL:textDownloadFormVisibilityOptAll',
      {},
      'All sections, fields, etc. in the order they were placed on the form. Conditional logic does not apply'
    ),
    value: DownloadFormVisibility.SHOW_ALL
  }, {
    label: this.i18n.translate(
      'GLOBAL:textDownloadFormVisibilityOptConditional',
      {},
      `Only what's visible in the preview. Conditional logic does apply.`
    ),
    value: DownloadFormVisibility.SHOW_CONDITIONAL
  }];

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private i18n: I18nService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit () {
    this.formGroup = this.formBuilder.group<DownloadFormModalResponse>({
      visibility: [DownloadFormVisibility.SHOW_ALL, Validators.required],
      includeFormVersion: false
    });
  }

  onDownload () {
    this.closeModal.emit(this.formGroup.value);
    this.analyticsService.emitEvent({
      eventName: 'Download',
      eventType: EventType.Click,
      extras: null
    });
  }
}
