import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { AddEditDataTableOptionModalResponse } from './add-edit-custom-option-modal/add-edit-custom-option-modal.component';
import { CustomDataTable, CustomDataTableOption, KeyValue, KeyValueFromAPI, MergeOptionsPayload, MergePicklistsPayload, PicklistConflictInfo, PicklistDataType, UpdatePicklistPayload, UpdateSortOrderPayload } from './custom-data-tables.typing';


@Injectable({ providedIn: 'root' })
export class CustomDataTablesResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getCustomTableDataList (): Promise<CustomDataTable[]> {
    const endpoint = 'api/client/picklist/GetPicklists';

    return this.httpRestService.get(endpoint);
  }

  addCustomTable (
    name: string,
    defaultLanguageId: string,
    parentPicklistId: number,
    dataType: PicklistDataType
  ): Promise<number> {
    const endpoint = 'api/client/picklist/AddPicklist';

    return this.httpRestService.post(endpoint, {
      name,
      defaultLanguageId,
      parentPicklistId,
      dataType
    });
  }

  getCustomDataTableOptions (id: number): Promise<CustomDataTableOption[]> {
    const endpoint = `api/client/picklist/GetPicklistOptions/${id}`;

    return this.httpRestService.get(endpoint);
  }

  getKeyValuesByGuid (
    guid: string,
    languageId: string
  ): Promise<KeyValue[]> {
    const returnInactive = true;
    const endpoint = `api/client/picklist/GetPicklistWithOptions/${guid}/${returnInactive}/${languageId}`;

    return this.httpRestService.get(endpoint);
  }

  /**
   *
   * @param formIds this is needed to fetch the cdt on all forms provided
   * @param languageId this is used to translate cdt option labels
   * @param clientId if null, clientID is infered (management portal) if provided, allows backend to fetch options (applicant portal)
   */
  getBulkCustomDataTables (
    formIds: number[],
    languageId: string,
    clientId?: number
  ): Promise<KeyValueFromAPI[]> {
    const endpoint = 'api/client/picklist/PicklistInformation';

    return this.httpRestService.post(endpoint, {
      formIds,
      languageId,
      returnInactive: true,
      clientId
    });
  }

  updateCustomTable (
    id: number,
    name: string,
    defaultLanguageId: string,
    parentPicklistId: number
  ) {
    const endpoint = 'api/client/picklist/UpdatePicklist';

    return this.httpRestService.post(endpoint, {
      id,
      name,
      defaultLanguageId,
      parentPicklistId: parentPicklistId || null
    });
  }

  deleteCustomTable (id: number) {
    const endpoint = `api/client/picklist/${id}`;

    return this.httpRestService.delete(endpoint);
  }

  uploadCsvList (id: number, file: Blob) {
    const endpoint = `api/client/picklist/UploadCsvList/${id}`;

    return this.httpRestService.postFile(endpoint, file);
  }

  updateOptionValue (
    picklistId: number,
    picklistOptionId: number,
    value: string,
    languageId: string,
    sortOrder: number,
    parentKeys: string[]
  ) {
    const endpoint = `api/client/picklist/UpdatePicklistOption`;

    return this.httpRestService.post(endpoint, {
      picklistId,
      picklistOptionId,
      value,
      languageId,
      sortOrder,
      parentKeys
    });
  }

  getFormsFromPicklistGuid (guid: string): Promise<number[]> {
    const endpoint = `api/client/picklist/GetFormsUsingPicklist/${guid}`;

    return this.httpRestService.get(endpoint);
  }

  updatePicklist (payload: UpdatePicklistPayload) {
    const endpoint = 'api/client/picklist/UpdatePicklist';

    return this.httpRestService.post(endpoint, payload);
  }

  handleDeleteDataTable (picklistId: number) {
    return this.httpRestService.delete(`api/client/picklist/${picklistId}`);
  }

  addDataTableOption (
    customDataTableId: number,
    modalResponse: AddEditDataTableOptionModalResponse
  ): Promise<number> {
    const endpoint = 'api/client/picklist/AddPicklistOption';

    return this.httpRestService.post(endpoint, {
      picklistId: customDataTableId,
      key: modalResponse.key,
      value: modalResponse.value,
      sortOrder: modalResponse.sortOrder,
      parentKeys: modalResponse.parentKeys
    });
  }

  deactivatePicklistOption (optionId: number) {
    return this.httpRestService.post(`api/client/picklist/${optionId}/DeactivatePicklistOption`, {});
  }

  activatePicklistOption (optionId: number) {
    return this.httpRestService.post(`api/client/picklist/${optionId}/ReactivatePicklistOption`, {});
  }

  copyCustomDataTable (
    picklistToCopyId: number,
    picklistName: string
  ): Promise<number> {
    const endpoint = 'api/client/picklist/CopyPicklistAndOptions';

    return this.httpRestService.post(endpoint, {
      picklistName,
      picklistToCopyId
    });
  }

  getMergeConflictsForPicklists (
    picklistToKeepId: number,
    picklistToMergeId: number,
    defaultLanguageId: string
  ): Promise<PicklistConflictInfo> {
    const endpoint = 'api/client/picklist/GetMergeConflictsForPicklists';

    return this.httpRestService.post(endpoint, {
      picklistToKeepId,
      picklistToMergeId,
      defaultLanguageId
    });
  }

  mergePicklists (
    payload: MergePicklistsPayload
  ) {
    const endpoint = 'api/client/picklist/MergePicklists';

    return this.httpRestService.post(endpoint, payload);
  }

  mergeOptions (
    payload: MergeOptionsPayload
  ) {
    const endpoint = 'api/client/picklist/MergePicklistOptions';

    return this.httpRestService.post(endpoint, payload);
  }

  updateSortOrder (
    payload: UpdateSortOrderPayload
  ) {
    const endpoint = 'api/client/picklist/UpdatePicklistSortOrder';

    return this.httpRestService.post(endpoint, payload);
  }
}

