import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { ProgramTypes, SimpleDataHubProgram } from '@core/typings/program.typing';
import { PaginatedResponse, PaginationOptions } from '@yourcause/common';
import { DataHubItem, ExportModalResponse } from './data-hub.typing';

@Injectable({ providedIn: 'root' })
export class DataHubResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getPrograms (
    formId: number = null,
    type: ProgramTypes = null
  ): Promise<SimpleDataHubProgram[]> {
    const params = {
      formId,
      type
    };
    const extraParams = (formId || type) ?
      `?${
        Object.keys(params)
          .map(p => p as keyof typeof params)
          .filter((paramName: keyof typeof params) => !!params[paramName])
          .map((paramName: keyof typeof params) => `${paramName}=${params[paramName]}`)
          .join('&')}` :
      '';

    return this.httpRestService.get('api/datahub/program/list' + extraParams);
  }


  getBudgets () {
    return this.httpRestService.get('api/datahub/budget/list');
  }

  getBatches () {
    return this.httpRestService.get('api/datahub/PaymentBatches');
  }

  getRequestForms (
    nominationFormId: number
  ) {
    const payload = {
      nominationFormId,
      formType: 1
    };

    return this.httpRestService.get('api/datahub/form/list?formType=' + payload.formType + '&nominationFormId=' + payload.nominationFormId);
  }

  getForms () {
    return this.httpRestService.get('api/datahub/form/list');
  }

  getApplicationList (
    paginationOptions: PaginationOptions<DataHubItem>
  ): Promise<PaginatedResponse<DataHubItem>> {
    const endpoint = 'api/datahub/applicationdetails/paginated';

    return this.httpRestService.post(endpoint, { paginationOptions });
  }

  getProgramSummaryList (
    paginationOptions: PaginationOptions<DataHubItem>
  ): Promise<PaginatedResponse<DataHubItem>> {
    const endpoint = 'api/datahub/programsummary/paginated';

    return this.httpRestService.post(endpoint, { paginationOptions });
  }

  getPaymentsList (
    paginationOptions: PaginationOptions<DataHubItem>
  ): Promise<PaginatedResponse<DataHubItem>> {
    const endpoint = 'api/datahub/payments/paginated';

    return this.httpRestService.post(endpoint, { paginationOptions });
  }

  getBudgetsList (
    paginationOptions: PaginationOptions<DataHubItem>
  ): Promise<PaginatedResponse<DataHubItem>> {
    const endpoint = 'api/datahub/budget/paginated';

    return this.httpRestService.post(endpoint, { paginationOptions });
  }

  getAwardsList (
    paginationOptions: PaginationOptions<DataHubItem>
  ): Promise<PaginatedResponse<DataHubItem>> {
    const endpoint = 'api/datahub/award/paginated';

    return this.httpRestService.post(endpoint, { paginationOptions });
  }

  getFormsList (
    paginationOptions: PaginationOptions<DataHubItem>
  ): Promise<PaginatedResponse<DataHubItem>> {
    const endpoint = 'api/datahub/form/paginated';

    return this.httpRestService.post(endpoint, { paginationOptions });
  }

  addProgramSummaryExportJob (data: ExportModalResponse) {
    return this.httpRestService.post('api/datahub/programsummary', data);
  }

  addApplicationDetailsExportJob (data: ExportModalResponse) {
    return this.httpRestService.post('api/datahub/applicationdetails', data);
  }

  addPaymentsExportJob (data: ExportModalResponse) {
    return this.httpRestService.post('api/datahub/payments', data);
  }

  addBudgetExportJob (data: ExportModalResponse) {
    return this.httpRestService.post('api/datahub/budget', data);
  }

  addAwardExportJob (data: ExportModalResponse) {
    return this.httpRestService.post('api/datahub/award', data);
  }

  addFormExportJob (data: ExportModalResponse) {
    return this.httpRestService.post('api/datahub/form', data);
  }

  downloadDocumentFromAws (fileName: string) {
    return this.httpRestService.get('api/datahub/url?fileName=' + fileName);
  }
}

