import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { ApplicationFromApi, AuditTrailItem, RecommendedFundingInfoFromApi } from '@core/typings/application.typing';

@Injectable({ providedIn: 'root' })
export class ApplicationViewResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getApplication (applicationId: number): Promise<ApplicationFromApi> {
    const endpoint = 'api/manager/applications/' + applicationId;

    return this.httpRestService.get(endpoint);
  }

  canViewApplication (applicationId: number): Promise<boolean> {
    const endpoint = `api/manager/applications/CanViewApplication?applicationId=${applicationId}`;

    return this.httpRestService.get(endpoint);
  }

  getReviewerRecommendedFundingInfo (
    applicationId: number
  ): Promise<RecommendedFundingInfoFromApi> {
    const endpoint = `api/manager/applications/${applicationId}/RecommendedFundingDetails`;

    return this.httpRestService.get(endpoint);
  }

  getApplicationAuditTrail (
    applicationId: number
  ): Promise<AuditTrailItem[]> {
    const endpoint = 'api/manager/applications/' + applicationId + '/audittrail';

    return this.httpRestService.get(endpoint);
  }
}
