import { SimpleStringMap } from '@yourcause/common';
import { CurrencyFormattingMap, ExchangeRate } from '@yourcause/common/currency';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class CurrencyState extends BaseYcState {
  readonly formattingData: CurrencyFormattingMap;
  readonly exchangeRateMap: {
    [x: string]: ExchangeRate[];
  } = {};
  readonly precisionMap: SimpleStringMap<number> = {};
}
