import { Injectable } from '@angular/core';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { UserService } from '@features/users/user.service';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { FormFieldCdtResources } from '../resources/form-field-cdt.resources';
import { FormFieldHelperService } from './form-field-helper.service';

@Injectable({ providedIn: 'root' })
export class FormFieldCdtService {

  constructor (
    private customDataTableService: CustomDataTablesService,
    private userService: UserService,
    private formFieldHelperService: FormFieldHelperService,
    private arrayHelper: ArrayHelpersService,
    private formFieldCdtResources: FormFieldCdtResources
  ) { }

  get customDataTableOptionsMap () {
    return this.customDataTableService.customDataTableOptionsMap;
  }

  async getCdtOptionsFromRefField (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel
  ): Promise<TypeaheadSelectOption[]> {
    let options: TypeaheadSelectOption[] = [];
    if (!!field.customDataTableGuid && this.formFieldHelperService.doesTypeHaveOptions(field.type)) {
      if (!this.customDataTableOptionsMap[field.customDataTableGuid]) {
        await this.customDataTableService.setCustomDataTableOptionsFromGuid(
          field.customDataTableGuid,
          this.userService.getCurrentUserCulture()
        );
      }

      options = this.customDataTableOptionsMap[field.customDataTableGuid].map((option) => {
        return {
          label: option.value,
          value: option.key,
          hidden: !option.inUse
        };
      });
    }

    return options;
  }

  getFilterOptions (
    guid: string
  ) {
    return (this.customDataTableOptionsMap[guid] || []).map((opt) => {
      return {
        label: opt.value,
        value: opt.key,
        hidden: !opt.inUse
      };
    });
  }

  getParentRefFieldOptionsFromDependentCDTGuid (
    guid: string,
    refFieldId: number = null
  ): TypeaheadSelectOption<number>[] {
    // refFieldID is optionally passed in to check a very specific scenario
    // if you are editing a parent picklist and update the CDT to a child of the same CDT
    // you were previously using, this refFieldID will allow us to filter the results

    // default options to empty array for cdt without parentPicklist
    const cdt = this.customDataTableService.getCDTFromGuid(guid);
    let options: TypeaheadSelectOption<number>[] = [];

    if (cdt?.parentPicklistId) {
      // take incoming CDT and get parent
      const parentCDT = this.customDataTableService.getCDTFromId(cdt.parentPicklistId);
      // filter ref fields down to those that have matching guid to parent
      const filteredRefFields = this.formFieldHelperService.allReferenceFields.filter((refField) => {
        return refField.customDataTableGuid === parentCDT.guid && refField.referenceFieldId !== refFieldId;
      });
      options = filteredRefFields.map((refField) => {
        return {
          label: refField.name,
          value: refField.referenceFieldId
        };
      });
    }
    const sortedOptions = this.arrayHelper.sort(options, 'label');

    return sortedOptions;
  }

  canUpdateCdtOnField (
    referenceFieldId: number,
    type: ReferenceFieldsUI.ReferenceFieldTypes
  ) {
    if (this.formFieldHelperService.doesTypeHaveOptions(type)) {
      return this.formFieldCdtResources.canUpdateCdtOnField(
        referenceFieldId
      );
    }

    return false;
  }

  checkForParentPicklistOnForm (parentPicklistId: number): {
    parentIsOnForm: boolean;
    parentPicklist: ReferenceFieldAPI.ReferenceFieldDisplayModel;
  } {
    const parentIsOnForm = this.formFieldHelperService.currentFormRefFields.some((refField) => {
      return refField.referenceFieldId === parentPicklistId;
    });
    const parentPicklist = this.formFieldHelperService.allReferenceFields.find((refField) => {
      return refField.referenceFieldId === parentPicklistId;
    });

    return {
      parentIsOnForm,
      parentPicklist
    };
  }
}
