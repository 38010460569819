import { Component, Optional } from '@angular/core';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormInstanceComponent } from '@features/forms/form-renderer/form-instance/form-instance.component';
import { FormLogicService } from '@features/forms/services/form-logic/form-logic.service';
import { BaseLayoutComponent } from '../base-layout-component/base-layout.component';

@Component({
  selector: 'gc-form-columns',
  templateUrl: './form-columns.component.html',
  styleUrls: ['./form-columns.component.scss']
})
export class FormColumnsComponent extends BaseLayoutComponent {
  constructor (
    @Optional() parent: FormInstanceComponent,
    public formBuilderService: FormBuilderService,
    public formLogicService: FormLogicService
  ) {
    super(formBuilderService, formLogicService);
    this.parent = parent;
  }
}
