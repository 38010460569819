export enum ServiceType {
  Applicants = 1,
  Applications = 2,
  Audiences = 3,
  Awards = 4,
  Batches = 5,
  Budgets = 6,
  Cycles = 7,
  FundingSources = 8,
  GrantManagerUsers = 9,
  InKind = 10,
  Invitations = 11,
  Organizations = 12,
  Payments = 13,
  Programs = 14,
  Roles = 15,
  Tags = 16,
  Translations = 17,
  WorkflowLevelAutomation = 18,
  Workflows = 19,
  Picklists = 20,
  Forms = 21
}

export enum ExpirationType {
  OneYear = 1,
  Never = 2
}

export interface OutboundApiService {
  clientAPIServiceTypeId: ServiceType;
  canRead: boolean;
  canWrite: boolean;
}

export interface OutboundApiRecordFromApi {
  id: number;
  name: string;
  isEnabled: boolean;
  clientAPIExpirationType: ExpirationType;
  expirationDate: string;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  services: OutboundApiService[];
  callsToday: number;
}

export interface ServiceDetails {
  label: string;
  supportsWrite: boolean;
  type: ServiceType;
}

export interface CreateOutboundApiRecord {
  name: string;
  isEnabled: boolean;
  clientAPIExpirationType: ExpirationType;
  services: OutboundApiService[];
}

export interface UpdateOutboundApiRecord {
  name: string;
  isEnabled: boolean;
  services: OutboundApiService[];
}

export interface ReadWriteTracker {
  read: boolean;
  write: boolean;
}

export interface CreateOutboundApiResponse {
  id: number;
  token: string;
}

export interface ClientOutboundMetricsFromApi {
  callsToday: number;
  rateLimitPerDay: number;
}
