<yc-modal *ngIf="componentForEdit"
  [ycHidden]="modalOpen"
  [modalHeader]="'common:hdrConfiguration' | ycI18n: 'Configuration'"
  [modalSubHeader]="modalSubHeader"
  [cancelButtonText]="isViewOnly ? '' : 'common:btnCancel' | ycI18n: 'Cancel'"
  [primaryButtonText]="isViewOnly ?
    ('common:textClose' | ycI18n: 'Close') :
    ('common:btnSave' | ycI18n: 'Save')"
  [primaryDisabled]="!allTabsValid"
  (onPrimaryClick)="saveChanges()"
  (onCancel)="closeModal.emit()">

  <div class="d-flex">
    <!-- Left Side - Tabs / Config -->
    <div class="width-50 me-3">
      <yc-nav-tabs-responsive
        [tabs]="tabs"
        [useRoutes]="false"
        [hasTabOverflow]="true"
        (activeTabChanged)="activeTabChanged($event)">
      </yc-nav-tabs-responsive>

      <div class="yc-layout-table">
        <div *ngFor="let tab of tabs">
          <gc-component-configuration-tab
            [ycHidden]="tab.context !== activeTabType"
            [component]="componentForEdit"
            [type]="tab.context"
            [config]="config"
            [isViewOnly]="isViewOnly"
            [formDefinition]="formDefinition"
            (onValidChange)="tabValidityChange($event, tab.context)"
            (onModalOpenOrClose)="modalOpen = $event"
            (onCurrencySettingChange)="onCurrencySettingChange()"
            (onChange)="componentChange($event)">
          </gc-component-configuration-tab>
        </div>
      </div>
    </div>

    <!-- Right Side - Preview -->
    <div class="width-50">
      <div class="preview-header">
        <yc-i18n
          key="common:lblPreview"
          defaultValue="Preview">
        </yc-i18n>
      </div>
      <gc-form-component-display
        [formId]="formId"
        [editable]="false"
        [inConfigModal]="true"
        [notAutoSave]="true"
        [value]="componentForEdit.value"
        [isFormBuilderView]="true"
        [parentFields]="parentFields"
        [component]="componentForEdit"
        (onValueChange)="onComponentPreviewChange($event)">
      </gc-form-component-display>
    </div>
  </div>

</yc-modal>