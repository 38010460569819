import { Component, Input } from '@angular/core';
import { YCModalComponent } from '@yourcause/common/modals';
import { AudienceUsage } from '../audience.typing';

@Component({
  selector: 'gc-audience-usage-modal',
  templateUrl: './audience-usage-modal.component.html',
  styleUrls: ['./audience-usage-modal.component.scss']
})
export class AudienceUsageModalComponent extends YCModalComponent<void> {
  @Input() audienceName: string;
  @Input() audienceId: number;
  @Input() audienceUsage: AudienceUsage;
}
