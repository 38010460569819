import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormGroupDirective, ValidatorFn, Validators } from '@angular/forms';
import { MaxDesignationLength, ValidatorsService } from '@core/services/validators.service';
import { BaseApplication } from '@core/typings/application.typing';
import { FormFieldChangeIndicatorClass, FormFieldChangeIndicatorIcon } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { TypeSafeFormBuilder } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { BaseFormComponent } from '../../base/base.component';

@Component({
  selector: 'gc-form-designation',
  templateUrl: './form-designation.component.html',
  styleUrls: ['form-designation.component.scss']
})
export class FormDesignationComponent extends BaseFormComponent<string> implements OnInit {
  @Input() disabled: boolean;

  customLabelIcon = FormFieldChangeIndicatorIcon;
  customLabelIconTooltip = this.i18n.translate(
    'common:textThisFieldWasUpdatedByTheApplicant',
    {},
    'This field was updated by the applicant'
  );
  customLabelIconClass = FormFieldChangeIndicatorClass;

  constructor (
    public formBuilder: TypeSafeFormBuilder,
    private validatorService: ValidatorsService,
    public formBuilderService: FormBuilderService,
    public componentHelper: ComponentHelperService,
    private i18n: I18nService,
    @Optional() formGroupDir: FormGroupDirective,
    @Optional() renderer: FormRendererComponent<BaseApplication>
  ) {
    super(renderer, formGroupDir, formBuilder, formBuilderService, componentHelper);
  }

  ngOnInit () {
    super.ngOnInit();
    if (this.comp) {
      let validators: ValidatorFn[] = [];
      if (!this.inFormBuilder) {
        validators = [
          ...this.validatorService.getValidatorsForSimpleComponent(
            this.comp,
            false,
            false,
            false,
            this.translations
          ),
          Validators.maxLength(MaxDesignationLength)
        ];
      }
      this.setFormGroup(this.data || '', validators);
    }

  }

  patchValue () {
    if (this.formGroup && this.formGroup.get(this.compKey)) {
      this.formGroup.get(this.compKey).setValue(this.data || '');
    }
  }

  dataChanged (value: string) {
    this.onValueChange.emit({
      value,
      updateFormGroup: false
    });
  }
}
