import { Injectable } from '@angular/core';
import { CachedAttr, CACHE_TYPES } from '@yourcause/common/cache';
import { AppInsightsService, GuidService } from '@yourcause/common/utils';

@Injectable({ providedIn: 'root' })
export class AppInsightsSessionService {
  constructor (
    private appInsightsService: AppInsightsService
  ) { }

  @CachedAttr(CACHE_TYPES.SESSION, new GuidService().short())
  private _sessionId: string;

  get sessionId () {
    return this._sessionId;
  }
  setup () {
    this.registerSessionId();
  }

  private registerSessionId () {
    if (!this.appInsightsService.context?.session?.id) {
      setTimeout(() => {
        this.registerSessionId();
      }, 50);
    } else {
      this.appInsightsService.context.session.id = this.sessionId;
    }
  }
}
