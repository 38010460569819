import { ConfigureProgram } from './program.typing';

export interface BaseTranslation {
  defaultTranslationText: string;
  translations: Translation[];
  defaultLanguage: string;
  languageKeyId: number;
  isRichText: boolean;
  languageKeyName: string;
  picklistGuid?: string;
  formId?: number;
  grantProgramId?: number;
  categoryId?: number;
  richLinkText?: string;
}

export interface Translation {
  language: string;
  translation: string;
}

export interface BulkTranslationsUpdate {
  languageKeyId: number;
  translations: Translation[];
}

export interface TranslationsByLang {
  defaultTranslation: string;
  translations: Translation[];
  isRichText?: boolean;
}

export interface ExportTranslation {
  languageKeyId: string;
  default: string;
  [lang: string]: string;
}

export interface ProgramTranslationMap {
  name: string;
  description: string;
  guidelines: string;
}

export interface TranslationKeyValue {
  key: string;
  value: string;
}

export interface ProgramTranslation {
  Name: string;
  Description: string;
  Guidelines: string;
  Eligibility_Pass_Message: string;
  Eligibility_Fail_Message: string;
  Charity_Bucket_Description: string;
}

export interface FormTranslations {
  Name: string;
  Description: string;
}

export interface FormTranslationsMap {
  [x: string]: FormTranslations;
}

export interface ViewTranslations {
  Grant_Program: {
    [x: string]: ProgramTranslation;
  };
  FormTranslation: FormTranslationsMap;
  Grant_Program_Cycle: {
    [id: number]: { Name: string };
  };
}

export const ViewTranslationsBlank = {
  Grant_Program: {},
  FormTranslation: {},
  Grant_Program_Cycle: {}
};

export interface ProgramTranslationKeys {
  key: keyof ConfigureProgram;
  translated: string;
}

export const ValidRichTextTypes = [
  'Description',
  'Guidelines',
  'Eligibility_Pass_Message',
  'Eligibility_Fail_Message'
];

export interface TranslatableItems {
  defaultLanguageId: string;
  id: number|string;
}

export type TranslatedRichTextTypes = 'Eligibility_Pass_Message'|'Eligibility_Fail_Message'|'Description'|'Guidelines';

