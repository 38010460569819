import { Injectable } from '@angular/core';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { Default_Font_Color, Default_Font_Family, Default_Font_Size, Default_Line_Height } from '@yourcause/common/rich-text-editor';

@Injectable({ providedIn: 'root' })
export class RichTextEditorService {

  constructor (
    private clientSettingsService: ClientSettingsService
  ) { }

  getRichTextEditorDefaults () {
    const branding = this.clientSettingsService.clientBranding;
  
    return {
      fontColor: branding?.richTextFontColor || Default_Font_Color,
      fontFamily: branding?.richTextFontFamily || Default_Font_Family,
      fontSize: branding?.richTextFontSize || Default_Font_Size,
      lineHeight: branding?.richTextLineHeight || Default_Line_Height
    };
  }
}
