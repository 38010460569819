import { UserInfo } from '@core/typings/client-user.typing';
import { TemplateMarginsForUI } from '@yourcause/common/rich-text-editor';

export enum DistributeOptions {
  SEND_EMAIL = 1,
  DOWNLOAD = 2,
  PRINT = 3
}

export interface DocumentTemplateFromApi {
  id: number;
  name: string;
  createdDate: string;
  createdBy: UserInfo;
}

export interface DocumentTemplateForUi extends DocumentTemplateFromApi {
  statusText: string;
}
export interface BaseDocumentTemplateDetail {
  id: number;
  name: string;
  templateHtml: string;
}

export interface DocumentTemplateDetailFromAPI extends BaseDocumentTemplateDetail {
  margins: TemplateMarginsFromAPI;
}
export interface DocumentTemplateDetailForUI extends BaseDocumentTemplateDetail {
  margins: TemplateMarginsForUI;
}
export interface TemplateMarginsFromAPI {
  right: string;
  left: string;
  top: string;
  bottom: string;
}

export interface ExportDocumentTemplate {
  documentTemplateId: number;
  name: string;
  templateHtml: string;
}

export interface  FetchTemplateFileTokenPayload {
  url: string;
  templateId: number;
};

export interface  BulkFetchTemplateFileTokenPayload {
  urls: string[];
  templateId: number;
};

export interface URLSasTokenObj {
  urlsAndSasTokens: {
    [x: string]: string;
  };
};

