import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PaginatedResponse, PaginationOptions } from '@yourcause/common';
import { AddressRequestApproveApi, AddressRequestFileFromApi, AddressRequestFileUpload, AddressRequestFromApi, AddressRequestRejectApi, AddressRequestStatus } from './address-requests.typing';

@Injectable({ providedIn: 'root' })
export class AddressRequestsResources {

  constructor (
    private http: HttpRestService
  ) { }

  getAddressRequests (
    paginationOptions: PaginationOptions<AddressRequestFromApi>
  ): Promise<PaginatedResponse<AddressRequestFromApi>> {
    const endpoint = 'api/admin/AddressRequest/Paginated';

    return this.http.post(endpoint, {
      paginationOptions
    });
  }

  updateStatus (
    addressRequestId: number,
    newStatus: AddressRequestStatus,
    comment: string
  ) {
    const endpoint = 'api/admin/AddressRequest/UpdateStatus';

    return this.http.post(endpoint, {
      addressRequestId,
      comment,
      newStatus
    });
  }

  getFilesForAddressRequest (
    addressRequestId: number,
    applicationId: number
  ): Promise<AddressRequestFileFromApi[]> {
    const endpoint = `api/admin/AddressRequest/${addressRequestId}/AddressRequestFiles/${applicationId}`;

    return this.http.get(endpoint);
  }

  approveRequest (payload: AddressRequestApproveApi) {
    const endpoint = 'api/admin/AddressRequest/Approve';

    return this.http.post(endpoint, payload);
  }

  rejectRequest (payload: AddressRequestRejectApi) {
    const endpoint = 'api/admin/AddressRequest/Decline';

    return this.http.post(endpoint, payload);
  }

  uploadApprovalFile (
    file: File,
    applicationId: number
  ): Promise<number> {
    const endpoint = `api/admin/AddressRequest/${applicationId}/ApprovalFiles`;

    return this.http.postFile(endpoint, file);
  }

  getPlatformUrlForAddressRequestFile (
    applicationId: number,
    fileUploadId: number
  ): Promise<AddressRequestFileUpload> {
    const endpoint = `api/admin/AddressRequest/${applicationId}/AddressRequestAccessUrl/${fileUploadId}`;

    return this.http.get(endpoint);
  }

  getManagerUrlForAddressRequestFile (
    applicationId: number,
    fileUploadId: number
  ): Promise<AddressRequestFileUpload> {
    const endpoint = `api/manager/paymentbatch/${applicationId}/GetAddressRequestFileAccessUrl/${fileUploadId}`;

    return this.http.get(endpoint);
  }

  uploadAddressRequestFile (
    file: File,
    applicationId: number
  ): Promise<number> {
    const endpoint = `api/manager/paymentbatch/${applicationId}/AddressRequestFileUpload`;

    return this.http.postFile(endpoint, file);
  }
}
