
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHttpRestService } from '@yourcause/common/http';
import { filter, lastValueFrom, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpRestService implements IHttpRestService {

  constructor (
    private http: HttpClient
  ) { }

  get <T = any> (url: string, headers?: any): Promise <T> {
    return this.makeRequest(url, 'get', null, headers);
  }

  post <T = any, B = any> (url: string, data: B, headers?: any): Promise<T> {
    return this.makeRequest(url, 'post', data, headers);
  }

  async postFile<T = any> (
    url: string,
    fileToUpload: File|Blob|string,
    paramName = 'file',
    fileName?: string
  ) {
    const { request } = this.setupFormData(fileToUpload, fileName, paramName, url);

    return lastValueFrom(this.http.request(request).pipe(
      filter(event => event instanceof HttpResponse),
      map(event => (<HttpResponse<T>>event).body)));
  }

  private setupFormData (
    file: string | File | Blob,
    fileName: string,
    paramName: string,
    url: string,
    responseType: 'text'|'arraybuffer'|'blob'|'json' = 'json'
  ) {
    const formData = new FormData();
    if (typeof file === 'string') {
      file = new File([new Blob([file])], fileName || 'upload');
    }
    if (!(file instanceof File) && fileName) {
      formData.append(paramName, file, fileName);
    } else {
      formData.append(paramName, file);
    }
    const request = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      headers: new HttpHeaders({ 'ngsw-bypass': 'true' }),
      responseType
    });

    return { formData, request, file };
  }

  put <T = any> (url: string, data: any, headers?: any): Promise<T> {
    return this.makeRequest(url, 'put', data, headers);
  }

  delete <T = any> (url: string, headers?: any): Promise<T> {
    return this.makeRequest(url, 'delete', null, headers);
  }

  protected makeRequest <T, B> (
    url: string,
    method: 'get'|'post'|'put'|'delete',
    data: B,
    inputHeaders?: any
  ): Promise<T> {
    let observable: Observable<T>;
    switch (method) {
      case 'get':
        observable = this.http.get<T>(url, {
          headers: inputHeaders
        });
        break;
      case 'post':
        observable = this.http.post<T>(url, data, {
          headers: inputHeaders
        });
        break;
      case 'put':
        observable = this.http.put<T>(url, data, {
          headers: inputHeaders
        });
        break;
      case 'delete':
        observable = this.http.delete<T>(url, {
          headers: inputHeaders
        });
        break;
    }

    return lastValueFrom(observable);
  }
}
