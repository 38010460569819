import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { OpenCloseBudgetAPI } from '@core/typings/api/open-close-budget.typing';
import { Budget, BudgetDashboardFundingSource, BudgetDashboardProgram, BudgetDetail, BudgetDrilldownInfo, BudgetFundingSourceAudit, BudgetImport, BudgetImportNoSources, BudgetSave, BudgetTopLevelStats, BulkUpdateBudgetsPayload, FundingSource, FundingSourceBudgetInfo, FundingSourceForApi, FundingSourceInsights, FundingSourceTopLevelStats } from '@core/typings/budget.typing';
import { PaymentForProcess } from '@core/typings/payment.typing';
import { PaginatedResponse, PaginationOptions } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class BudgetResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getBudget (id: number): Promise<BudgetDetail> {
    return this.httpRestService.get('api/manager/budgets/' + id);
  }

  getBudgets (): Promise<Budget[]> {
    return this.httpRestService.get('api/manager/budgets');
  }

  getBudgetsSegmented (): Promise<number[]> {
    const endpoint = 'api/manager/budgets/dataSegmented';

    return this.httpRestService.get(endpoint);
  }

  getFundingSourcesSegmented (): Promise<number[]> {
    const endpoint = 'api/manager/fundingSources/dataSegmented';

    return this.httpRestService.get(endpoint);
  }

  getBudgetsForDashboard (): Promise<Budget[]> {
    const endpoint = 'api/manager/insights/budgets';

    return this.httpRestService.get(endpoint);
  }

  saveBudget (adapted: BudgetSave) {
    return this.httpRestService.post<number>('api/manager/budgets', adapted);
  }

  deleteBudget (budgetId: number) {
    return this.httpRestService.delete('api/manager/budgets/' + budgetId);
  }

  getFundingSourcesForDashboard (): Promise<FundingSourceInsights[]> {
    return this.httpRestService.get('api/manager/insights/fundingsources');
  }

  getFundingSourceStats (payload: number[]): Promise<FundingSourceTopLevelStats> {
    const endpoint = 'api/manager/insights/fundingsource/stats';

    return this.httpRestService.post(endpoint, {
      fundingSourceIds: payload,
      allocatedTotals: true
    });
  }

  getBudgetFundingSources () {
    return this.httpRestService.get('api/manager/budgets/fundingsources');
  }

  getBudgetDrilldownInfo (budgetId: number) {
    return this.httpRestService.get<BudgetDrilldownInfo>(`api/manager/budgets/BudgetDetails?budgetId=${budgetId}`);
  }

  getFundingSourceDrilldownInfo (id: number) {
    return this.httpRestService.get(`api/manager/fundingsources/FundingSourceDetails?fundingSourceId=${id}`);
  }

  saveFundingSource (data: FundingSourceForApi) {
    return this.httpRestService.post<number>('api/manager/fundingsources', data);
  }

  getFundingSources (): Promise<FundingSource[]> {
    const endpoint = 'api/manager/fundingsources';

    return this.httpRestService.get(endpoint);
  }

  getArchivedSources (): Promise<FundingSource[]> {
    const archivedEndpoint = 'api/manager/fundingsources?actives=false';

    return this.httpRestService.get(archivedEndpoint);
  }

  archiveFundingSource (fundingSourceId: number) {
    return this.httpRestService.delete(
      'api/manager/fundingsources/' + fundingSourceId
    );
  }

  restoreFundingSource (fundingSourceId: number) {
    return this.httpRestService.post(
      'api/manager/fundingsources/' + fundingSourceId + '/restore',
      {}
    );
  }

  getBudgetStats (budgetIds: number[]): Promise<BudgetTopLevelStats> {
    const endpoint = 'api/manager/insights/budget/stats';

    return this.httpRestService.post(endpoint, {
      budgetIds
    });
  }

  getProgramsForBudgetDashboard (budgetId: number): Promise<BudgetDashboardProgram[]> {
    const endpoint = 'api/manager/insights/budget/programs';

    return this.httpRestService.post(endpoint, {
      budgetId
    });
  }

  getFundingSourcesForBudgetDashboard (
    budgetId: number
  ): Promise<BudgetDashboardFundingSource[]> {
    const endpoint = 'api/manager/insights/budget/fundingsources';

    return this.httpRestService.post(endpoint, {
      budgetId
    });
  }

  getBudgetDashboardPayments (
    budgetId: number,
    paginationOptions: PaginationOptions<PaymentForProcess>,
    includeProgramFacet = true
  ) {
    const endpoint = 'api/manager/insights/budget/payments';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      budgetId,
      includeProgramFacet
    });
  }

  getFundingSourceDashboardPayments (
    fundingSourceId: number,
    paginationOptions: PaginationOptions<PaymentForProcess>,
    includeProgramFacet = true
  ) {
    const endpoint = 'api/manager/insights/fundingsource/payments';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      fundingSourceId,
      includeProgramFacet
    });
  }

  getBudgetsByFundingSourceID (
    fundingSourceId: number
  ): Promise<FundingSourceBudgetInfo[]> {
    const endpoint = 'api/manager/insights/fundingsource/budget';

    return this.httpRestService.post(endpoint, {
      fundingSourceId
    });
  }

  closeBudget (payload: OpenCloseBudgetAPI.CloseBudgetPayload) {
    const endpoint = 'api/manager/budgets/closeBudget';

    return this.httpRestService.post(endpoint, payload);
  }

  openBudget (budgetId: number) {
    const endpoint = `api/manager/budgets/openBudget?budgetId=${budgetId}`;

    return this.httpRestService.get(endpoint);
  }

  closeFundingSource (payload: number[]) {
    const endpoint = 'api/manager/fundingsources/closeFundingSource';

    return this.httpRestService.post(endpoint, payload);
  }

  openFundingSource (payload: number) {
    const endpoint = `api/manager/fundingsources/openfundingsource?fundingsourceid=${payload}`;

    return this.httpRestService.get(endpoint);
  }

  deleteFundingSource (fundingSourceId: number) {
    const endpoint = `api/manager/fundingsources/${fundingSourceId}`;

    return this.httpRestService.delete(endpoint);
  }

  getBudgetAuditTrail (
    budgetId: number,
    paginationOptions: PaginationOptions<BudgetFundingSourceAudit>
  ): Promise<PaginatedResponse<BudgetFundingSourceAudit>> {
    const endpoint = 'api/manager/budgets/GetBudgetAudit';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      budgetId
    });
  }

  getFundingSourceAuditTrail (
    fundingSourceId: number,
    paginationOptions: PaginationOptions<BudgetFundingSourceAudit>
  ): Promise<PaginatedResponse<BudgetFundingSourceAudit>> {
    const endpoint = 'api/manager/fundingsources/GetFundingSourceAudit';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      fundingSourceId
    });
  }

  importBudgets (payload: BudgetImport) {
    const endpoint = 'api/manager/budgets/ImportBudgets';

    return this.httpRestService.post(
      endpoint,
      payload
    );
  }

  importBudgetsNoSources (payload: BudgetImportNoSources) {
    const endpoint = 'api/manager/budgets/ImportBudgetsWithFundingSourcesHidden';

    return this.httpRestService.post(
      endpoint,
      payload
    );
  }

  bulkUpdateBudgets (payload: BulkUpdateBudgetsPayload) {
    const endpoint = 'api/manager/budgets/BulkUpdateBudgetAndFundingSources';

    return this.httpRestService.post(endpoint, payload);
  }
}

