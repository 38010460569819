import { ProcessingTypes } from '../payment.typing';

export namespace CyclesAPI {
  export interface BaseProgramCycle {
    id: number;
    isArchived?: boolean;
    name?: string;
    startDate: string;
    endDate: string;
    isClientProcessing: boolean;
    clientOrganizationsProcessingTypeId?: ProcessingTypes;
  }
  export interface ProgramCycleForApi extends BaseProgramCycle {
    name: string;
    budgets?: number[];
    defaultCashBudgetId?: number;
    defaultCashFundingSourceId?: number;
    defaultInKindBudgetId?: number;
    defaultInKindFundingSourceId?: number;
    clientOrganizationsProcessingTypeId?: ProcessingTypes;
  }
  export interface ConfigureProgramCycle extends ProgramCycleForApi {
    hasApplications: boolean;
    createdBy: string;
    createdDate: string;
    updatedBy: string;
    updatedDate: string;
    archiveComment?: string;
    isArchived: boolean;
    status: CycleStatuses;
    createImpersonatedBy: string;
    impersonatedBy: string;
    alwaysOpen: boolean;
    budgetNames: string[];
  }
  export enum CycleStatuses {
    All = 0,
    Open = 1,
    Upcoming = 2,
    Past = 3
  }
  export interface ArchiveCycleStats {
    totalApplications: number;
    totalAwards: number;
    totalPayments: number;
    paymentsAvailableForProcessing: number;
  }
  export interface BulkCycleUpdatePayload {
    applicationIds: number[];
    cycleId: number;
    comment?: string;
  }
  export interface SimpleCycle {
    id: number;
    name: string;
    startDate: string;
    endDate: string;
    grantProgramId: number;
    isArchived: boolean;
    status?: CycleStatuses;
  }
  export enum CycleStatusFilterType {
    All = 1,
    Open = 2,
    Closed = 3,
    Future = 4,
    OpenOrClosed = 5
  }

  export interface CycleHelpers {
    currentCycle: BaseProgramCycle;
    nextCycle: BaseProgramCycle;
    lastCycle: BaseProgramCycle;
  }
  export interface UpdateCycleInfoResponse {
    totalPayments: number;
    budgetCount: number;
    eligibleCycleIds: number[];
  }
  export interface CycleTop10 {
    cycleId: number;
    cycleName?: string;
    numberOfAwards: number;
    awardAmount: number;
    paymentAmount: number;
  }
}

