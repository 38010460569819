import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { CyclesAPI } from '@core/typings/api/cycles.typing';
import { ApplicationAwards } from '@core/typings/application.typing';
import { AdjustPayment, CreatePaymentResponse, PaymentForApi, PaymentTopLevelStats } from '@core/typings/payment.typing';
import { PaginatedResponse, PaginationOptions } from '@yourcause/common';
import { ApproveAwardPayPayload, AwardForApi, AwardForDash, AwardTopLevelStats, BulkApproveAwardPayPayload, MyAwardsFromApi } from './typings/award.typing';

@Injectable({ providedIn: 'root' })
export class AwardResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getAwards (applicationId: number): Promise<ApplicationAwards> {
    const endpoint = 'api/manager/applications/' + applicationId + '/awards';

    return this.httpRestService.get(endpoint);
  }

  getAwardsForInsights (
    programCycleIds: number[],
    paginationOptions: PaginationOptions<AwardForDash>,
    includeProgramFacet = true
  ): Promise<PaginatedResponse<AwardForDash>> {
    const endpoint = 'api/manager/insights/awards';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      programCycleIds,
      includeProgramFacet
    });
  }

  getTopCycles (
    programCycleIds: number[] = []
  ): Promise<CyclesAPI.CycleTop10[]> {
    const endpoint = 'api/manager/insights/award/programs';

    return this.httpRestService.post(endpoint, {
      programCycleIds
    });
  }

  getAwardStats (
    programCycleIds: number[]
  ): Promise<AwardTopLevelStats> {
    const endpoint = 'api/manager/insights/award/stats';

    return this.httpRestService.post(endpoint, {
      programCycleIds
    });
  }

  getAwardsForApplicant (
    page: number,
    pageSize: number
  ): Promise<MyAwardsFromApi> {
    const endpoint = 'api/portal/awards?page=' + page +
      '&pageSize=' + pageSize;

    return this.httpRestService.get(endpoint);
  }

  saveAward (
    applicationId: number,
    data: AwardForApi
  ): Promise<{
    awardId: number;
    automaticallyRouted: boolean;
  }> {
    const endpoint = 'api/manager/applications/' + applicationId + '/awards';

    return this.httpRestService.post(endpoint, data);
  }

  createPayment (
    applicationId: number,
    awardId: number,
    data: PaymentForApi
  ): Promise<CreatePaymentResponse> {
    const endpoint = 'api/manager/applications/' + applicationId + '/awards/' + awardId + '/payments';

    return this.httpRestService.post(endpoint, data);
  }

  removePayment (
    applicationId: number,
    awardId: number,
    paymentId: number
  ) {
    const endpoint = 'api/manager/applications/' + applicationId + '/awards/' + awardId + '/payments/' + paymentId;

    return this.httpRestService.delete(endpoint);
  }

  adjustPayment (
    applicationId: number,
    awardId: number,
    paymentId: number,
    data: AdjustPayment
  ): Promise<{
    automaticallyRouted: boolean;
  }> {
    const endpoint = 'api/manager/applications/' + applicationId + '/awards/' + awardId + '/payments/' + paymentId;

    return this.httpRestService.post(endpoint, data);
  }

  markPaymentAsPaid (
    applicationId: number,
    awardId: number,
    paymentId: number
  ) {
    const endpoint = 'api/manager/applications/' + applicationId + '/awards/' + awardId + '/payments/' + paymentId + '/paid';

    return this.httpRestService.post(endpoint, {});
  }

  deleteAward (
    applicationId: number,
    awardId: number
  ) {
    const endpoint = `api/manager/applications/${applicationId}/awards/${awardId}`;

    return this.httpRestService.delete(endpoint);
  }

  getPaymentStats (
    grantProgramId: number,
    programCycleIds: number[]
  ): Promise<PaymentTopLevelStats> {
    const endpoint = 'api/manager/insights/program/payments';

    return this.httpRestService.post(endpoint, {
      grantProgramId,
      programCycleIds
    });
  }

  approveAwardAndPayApplication (
    payload: ApproveAwardPayPayload,
    isAppManager = false
  ) {
    const endpoint = isAppManager ?
      'api/manager/applications/ApproveAndAwardApplicationByAppManager' :
      'api/manager/applications/ApproveAndAwardApplication';

    return this.httpRestService.post(endpoint, payload);
  }

  bulkApproveAwardPay (payload: BulkApproveAwardPayPayload) {
    const endpoint = 'api/manager/applications/BulkApproveAndAwardApplications';

    return this.httpRestService.post(endpoint, payload);
  }

}
