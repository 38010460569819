import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InKindService } from '@features/in-kind/in-kind.service';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { IsValidTypes, ValidationTypes } from '../component-configuration.typing';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';
import { ArrayHelpersService } from '@yourcause/common/utils';
export interface InKindValidationChange {
  willBeValid: IsValidTypes;
  validationType: ValidationTypes;
  validationAmount: number;
  validationItem: string;
  validationErrorMessage: string;
}

@Component({
  selector: 'gc-in-kind-validation-settings',
  templateUrl: './in-kind-validation-settings.component.html',
  styleUrls: ['./in-kind-validation-settings.component.scss']
})
export class InKindValidationSettingsComponent extends BaseComponentConfigSettingsComponent {
  @Input() willBeValid: IsValidTypes;
  @Input() validationType: ValidationTypes;
  @Input() validationAmount: number;
  @Input() validationItem: string;
  @Input() validationErrorMessage: string;
  @Output() onChange = new EventEmitter<InKindValidationChange>();

  ValidationTypes = ValidationTypes;
  willBeValidOptions: TypeaheadSelectOption[] = this.arrayHelper.sort([{
    label: this.i18n.translate(
      'common:textInvalid',
      {},
      'Invalid'
    ).toLowerCase(),
    value: IsValidTypes.Invalid
  }, {
    label: this.i18n.translate(
      'common:textValid',
      {},
      'Valid'
    ).toLowerCase(),
    value: IsValidTypes.Valid
  }], 'label');

  validationTypeOptions: TypeaheadSelectOption[] = this.arrayHelper.sort([{
    label: this.i18n.translate(
      'common:textAtLeastOneOf',
      {},
      'At least one of'
    ).toLowerCase(),
    value: ValidationTypes.HasSelectedItem
  }, {
    label: this.i18n.translate(
      'common:textTotalUnitsGreaterThan',
      {},
      'Total units greater than'
    ).toLowerCase(),
    value: ValidationTypes.QuantityGreaterThan
  }, {
    label: this.i18n.translate(
      'common:textTotalUnitsLessThan',
      {},
      'Total units less than'
    ).toLowerCase(),
    value: ValidationTypes.QuantityLessThan
  }, {
    label: this.i18n.translate(
      'common:textTotalUnitsEqualTo',
      {},
      'Total units equal to'
    ).toLowerCase(),
    value: ValidationTypes.QuantityEqualTo
  }, {
    label: this.i18n.translate(
      'common:textThisManyItems',
      {},
      'This many items'
    ).toLowerCase(),
    value: ValidationTypes.HasSelectedQuantity
  }], 'label');
  validationItemOptions: TypeaheadSelectOption[] = Object.keys(this.categoryitemMap)
    .map(key => this.categoryitemMap[key])
    .reduce<{ label: string; value: string }[]>((acc, catList) => {
      return [
        ...acc,
        ...catList.map(item => ({
          label: item.name,
          value: item.identification
        }))
      ];
    }, []);

  constructor (
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private inKindService: InKindService
  ) {
    super();
  }

  get categoryitemMap () {
    return this.inKindService.categoryItemMap;
  }

  onValidationChange () {
    this.onChange.emit({
      willBeValid: this.willBeValid,
      validationType: this.validationType,
      validationAmount: this.validationAmount,
      validationErrorMessage: this.validationErrorMessage,
      validationItem: this.validationItem
    });
  }
}
