import { Pipe, PipeTransform } from '@angular/core';
import { FormDefinitionComponent } from '@features/configure-forms/form.typing';

@Pipe({
  name: 'gcGetColumnClass'
})
export class GetColumnClassPipe implements PipeTransform {

  transform (comp: FormDefinitionComponent) {
    if (comp) {
      const width  = ` col-sm-${comp.width  ? comp.width  : 6}`;
      const offset = ` col-sm-offset-${comp.offset ? comp.offset : 0}`;
      const push   = ` col-sm-push-${comp.push   ? comp.push   : 0}`;
      const pull   = ` col-sm-pull-${comp.pull   ? comp.pull   : 0}`;

      return `col${width}${offset}${push}${pull}`;
    }

    return '';
  }
}
