import { AbstractControl } from '@angular/forms';
import { ValidatorMessageObj } from '@yourcause/common';

export function DataSetMinMaxValidator (
  comparisonValue: number,
  customErrorMessage: string,
  defaultErrorMessage: string,
  checkMin: boolean
) {
  return (control: AbstractControl) => {
    const val = control.value;
    const errorMessage = customErrorMessage || defaultErrorMessage;
    const errorObj: ValidatorMessageObj = {
      dataSetMinMaxError: {
        i18nKey: '',
        defaultValue: '',
        errorMessage
      }
    };

    const countOfValues = val && val[0] ?
      val[0].columns.filter((col: any) => {
        return !!col.value;
      }).length :
      null;

    const passesMin = countOfValues >= comparisonValue;
    const passesMax = countOfValues <= comparisonValue;

    const valid = checkMin ? passesMin : passesMax;

    return valid ? null : errorObj;
  };

}
