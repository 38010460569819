import { NgModule } from '@angular/core';
import { GCCoreModule } from '@core/_core.module';
import { FormBuilderComponentActionsComponent } from '@features/forms/form-builder/form-builder-component-actions/form-builder-component-actions.component';
import { FormBuilderDragPreviewComponent } from '@features/forms/form-builder/form-builder-drag-preview/form-builder-drag-preview.component';
import { FormBuilderEmptyContainerComponent } from '@features/forms/form-builder/form-builder-empty-container/form-builder-empty-container.component';
import { CollapsibleSectionModule } from '@yourcause/common/collapsible-section';
import { GetFilterTypeFromTableColumn } from '../../pipes/get-filter-type-from-table-column.pipe';
import { GetFormattedTableResponsePipe } from '../../pipes/get-formatted-table-response.pipe';
import { FormComponentDisplayComponent } from '../form-component-display/form-component-display.component';
import { BaseLayoutComponent } from '../layout-form-components/base-layout-component/base-layout.component';
import { FormColumnsComponent } from '../layout-form-components/form-columns/form-columns.component';
import { FormContentComponent } from '../layout-form-components/form-content/form-content.component';
import { FormFieldSetComponent } from '../layout-form-components/form-field-set/form-field-set.component';
import { FormGridComponent } from '../layout-form-components/form-grid/form-grid.component';
import { FormPanelComponent } from '../layout-form-components/form-panel/form-panel.component';
import { FormWellComponent } from '../layout-form-components/form-well/form-well.component';
import { FormReportFieldComponent } from '../standard-form-components/form-report-field/form-report-field.component';
import { StandardFormComponentsModule } from '../standard-form-components/standard-form-components.module';
import { AddressFormFieldComponent } from './address-form-field/address-form-field.component';
import { FormCustomDataTableComponent } from './form-custom-data-table/form-custom-data-table.component';
import { FormDataSetComponent } from './form-data-set-field/form-data-set-field.component';
import { FormExternalAPIFieldComponent } from './form-external-api-field/form-external-api-field.component';
import { FormReferenceFieldComponent } from './form-reference-field/form-reference-field.component';
import { FormTableFieldComponent } from './tables/form-table-field/form-table-field.component';
import { ImportTableRowsModalComponent } from './tables/import-table-rows-modal/import-table-rows-modal.component';
import { TableFieldCrudModalComponent } from './tables/table-field-crud-modal/table-field-crud-modal.component';

@NgModule({
  imports: [
    GCCoreModule,
    StandardFormComponentsModule,
    CollapsibleSectionModule
  ],
  declarations: [
    FormBuilderComponentActionsComponent,
    FormBuilderEmptyContainerComponent,
    FormCustomDataTableComponent,
    FormExternalAPIFieldComponent,
    FormReferenceFieldComponent,
    FormReportFieldComponent,
    FormTableFieldComponent,
    ImportTableRowsModalComponent,
    TableFieldCrudModalComponent,
    FormDataSetComponent,
    GetFilterTypeFromTableColumn,
    GetFormattedTableResponsePipe,
    FormComponentDisplayComponent,
    FormContentComponent,
    BaseLayoutComponent,
    FormWellComponent,
    FormColumnsComponent,
    FormPanelComponent,
    FormGridComponent,
    FormFieldSetComponent,
    FormBuilderDragPreviewComponent,
    AddressFormFieldComponent
  ],
  exports: [
    FormBuilderComponentActionsComponent,
    FormBuilderEmptyContainerComponent,
    FormCustomDataTableComponent,
    FormExternalAPIFieldComponent,
    FormReferenceFieldComponent,
    FormReportFieldComponent,
    FormTableFieldComponent,
    ImportTableRowsModalComponent,
    TableFieldCrudModalComponent,
    FormDataSetComponent,
    GetFilterTypeFromTableColumn,
    GetFormattedTableResponsePipe,
    FormComponentDisplayComponent,
    FormContentComponent,
    FormWellComponent,
    BaseLayoutComponent,
    FormColumnsComponent,
    FormPanelComponent,
    FormGridComponent,
    FormFieldSetComponent,
    FormBuilderDragPreviewComponent
  ]
})
export class FormReferenceFieldsModule { }
