import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { CurrencyRadioOptions } from '@yourcause/common/currency';
import { I18nService } from '@yourcause/common/i18n';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';
export interface CurrencyChange {
  useCustomCurrency: CurrencyRadioOptions;
  customCurrency: string;
}

@Component({
  selector: 'gc-currency-settings',
  templateUrl: './currency-settings.component.html',
  styleUrls: ['./currency-settings.component.scss']
})
export class CurrencySettingsComponent extends BaseComponentConfigSettingsComponent implements OnInit {
  @Input() useCustomCurrency: CurrencyRadioOptions;
  @Input() customCurrency: string;
  @Output() onChange = new EventEmitter<CurrencyChange>();

  currencies = this.clientSettingsService.selectedCurrencies;
  defaultCurrency = this.clientSettingsService.defaultCurrency;
  CurrencyRadioOptions = CurrencyRadioOptions;
  options: TypeaheadSelectOption[] = [{
    label: this.i18n.translate(
      'common:textAllowApplicantToChangeCurrency',
      {},
      'Allow applicant to change to their desired currency'
    ),
    value: CurrencyRadioOptions.USE_ANY_CURRENCY
  }, {
    label: this.i18n.translate(
      'common:textUseTheFollowingCurrency',
      {},
      'Use the following currency'
    ),
    value: CurrencyRadioOptions.USE_ONE_CURRENCY
  }];
  currencyOptions: TypeaheadSelectOption[] = this.currencies.map((currency) => {
    return {
      label: currency.displayName,
      value: currency.code
    };
  });

  constructor (
    private i18n: I18nService,
    private clientSettingsService: ClientSettingsService
  ) {
    super();
  }

  ngOnInit () {
    if (!this.useCustomCurrency) {
      this.useCustomCurrency = CurrencyRadioOptions.USE_ONE_CURRENCY;
      this.customCurrency = this.defaultCurrency;
      this.emitChange();
    }
  }

  emitChange () {
    this.onChange.emit({
      useCustomCurrency: this.useCustomCurrency,
      customCurrency: this.customCurrency
    });
  }

}
