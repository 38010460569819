import { Component, Input } from '@angular/core';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { YCModalComponent } from '@yourcause/common/modals';
import { LogicBuilderService } from '../logic-builder.service';
import { GlobalLogicGroup as GlobalConditionalLogicGroup, LogicColumnDisplay, LogicEvaluationTypeDisplayOptionsConditional, LogicEvaluationTypeDisplayOptionsValidity } from '../logic-builder.typing';

@Component({
  selector: 'gc-conditional-logic-builder-modal',
  templateUrl: './conditional-logic-builder-modal.component.html',
  styleUrls: ['./conditional-logic-builder-modal.component.scss']
})
export class ConditionalLogicBuilderModalComponent<T> extends YCModalComponent<
  GlobalConditionalLogicGroup<T>
> {
  @Input() builderName: string;
  @Input() evaluationTypeOptions: LogicEvaluationTypeDisplayOptionsConditional|LogicEvaluationTypeDisplayOptionsValidity;
  @Input() currentColumnName: string;
  @Input() availableColumns: LogicColumnDisplay<T>[];
  @Input() logic: GlobalConditionalLogicGroup<T>;
  @Input() isViewOnly: boolean;

  currentLogic: GlobalConditionalLogicGroup<T>;
  valid = true;

  constructor (
    private logicBuilderService: LogicBuilderService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  handleLogicChange (
    logic: GlobalConditionalLogicGroup<T>
  ) {
    this.currentLogic = logic;
  }

  handleValidChange (
    valid: boolean
  ) {
    this.valid = valid;
  }

  handleSubmit () {
    if (this.valid) {
      this.closeModal.emit(this.currentLogic);
    }
    this.analyticsService.emitEvent({
      eventName: 'Save conditional logic',
      eventType: EventType.Click,
      extras: null
    });
  }

  handleClear () {
    this.logic = this.logicBuilderService.getDefaultConditionalLogic();
    this.currentLogic = this.logic;
    this.valid = true;
    this.handleSubmit();
    this.analyticsService.emitEvent({
      eventName: 'Clear conditional logic',
      eventType: EventType.Click,
      extras: null
    });
  }

  handleCancel () {
    this.closeModal.emit();
  }
}
