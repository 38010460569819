import { SimpleStringMap } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { DocumentTemplateDetailForUI, DocumentTemplateForUi } from './document-template.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';

@RegisterYCState()
export class DocumentTemplateState extends BaseYcState {
  readonly detailMap: SimpleStringMap<DocumentTemplateDetailForUI> = {};
  readonly documentTemplates: DocumentTemplateForUi[];
  readonly documentTemplateOptions: TypeaheadSelectOption[];
}
