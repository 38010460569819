import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseApplicationForLogic, FormDefinitionComponent, ValueLogicResult } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { FormLogicService } from '@features/forms/services/form-logic/form-logic.service';
import { LogicBuilderService } from '@features/logic-builder/logic-builder.service';
import { EvaluationType, GlobalValueLogicGroup, LogicColumnDisplay, LogicValueFormatType } from '@features/logic-builder/logic-builder.typing';
import { ValueLogicBuilderModalComponent } from '@features/logic-builder/value-logic-builder-modal/value-logic-builder-modal.component';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { SelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory } from '@yourcause/common/modals';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';

@Component({
  selector: 'gc-conditional-value-form-builder-settings',
  templateUrl: './conditional-value-form-builder-settings.component.html',
  styleUrls: ['./conditional-value-form-builder-settings.component.scss']
})
export class ConditionalValueFormBuilderSettingsComponent extends BaseComponentConfigSettingsComponent implements OnInit {
  @Input() logicGroups: GlobalValueLogicGroup<BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>[];
  @Input() component: FormDefinitionComponent;
  @Output() onModalOpenOrClose = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<GlobalValueLogicGroup<
    BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>[]
  >();

  availableColumns: LogicColumnDisplay<BaseApplicationForLogic>[];
  noRulesMessage = this.i18n.translate(
    'common:textNoValueLogicRulesHelp',
    {},
    `Click "Add new rule" below to create conditional logic to assign this component a value.`
  );
  options: (TypeaheadSelectOption|SelectOption)[] = [];
  logicValueFormatType: LogicValueFormatType;
  ready = false;
  logicEnabled: boolean;
  EvaluationType = EvaluationType;
  sourceColumn: LogicColumnDisplay<BaseApplicationForLogic>;

  constructor (
    private i18n: I18nService,
    private modalFactory: ModalFactory,
    private formLogicService: FormLogicService,
    private logicBuilderService: LogicBuilderService,
    private formHelperService: FormHelperService,
    private formBuilderService: FormBuilderService
  ) {
    super();
  }

  get rules () {
    return this.logicGroups || [];
  }

  async ngOnInit () {
    const hasSetValueRules = (this.component.conditionalValue?.length ?? 0) > 0;
    const hasCalculatedValue = !!this.component?.formula?.step;
    this.logicEnabled = !hasCalculatedValue ||
      // this is an edge case, but if both are somehow set (bug 1872663), allow them to change this one
      (hasSetValueRules && hasCalculatedValue);
    if (!this.logicGroups) {
      this.logicGroups = [];
      this.onChange.emit(this.logicGroups);
    }
    const {
      availableColumns,
      sourceColumn
    } = this.formLogicService.getAvailableLogicColumnsForComponent(
      this.component,
      true,
      this.formBuilderService.currentFormBuilderDefinition,
      this.formBuilderService.currentFormBuilderIndex,
      this.formBuilderService.currentFormBuilderFormAudience
    );
    this.sourceColumn = sourceColumn;
    this.availableColumns = availableColumns;
    await this.getOptionsAndFormatValueType();
    this.ready = true;
  }

  async getOptionsAndFormatValueType () {
    const {
      options,
      logicValueFormatType
    } = await this.formHelperService.getOptionsAndFormatValueType(
      this.component
    );

    this.options = options;
    this.logicValueFormatType = logicValueFormatType;
  }

  moveUpOrDown (
    index: number,
    isUp: boolean
  ) {
    const otherIndex = isUp ? index - 1 : index + 1;
    const temp1 = this.rules[index];
    const temp2 = this.rules[otherIndex];
    this.logicGroups[index] = temp2;
    this.logicGroups[otherIndex] = temp1;
    this.emitValueLogic();
  }

  async openLogicBuilderModal (
    rule?: GlobalValueLogicGroup<BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>,
    index?: number
  ) {
    this.onModalOpenOrClose.emit(true);
    const isNewLogic = !rule;
    if (isNewLogic) {
      rule = this.logicBuilderService.getDefaultConditionalValueLogic<
        BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>
      >(this.logicValueFormatType);
    }
    const alwaysTrueAvailable = this.logicGroups.every((condition) => {
      return condition.evaluationType !== EvaluationType.AlwaysTrue;
    }) || rule.evaluationType === EvaluationType.AlwaysTrue;
    const response = await this.modalFactory.open<
      ValueLogicBuilderModalComponent<BaseApplicationForLogic>
    >(
      ValueLogicBuilderModalComponent,
      {
        builderName: this.i18n.translate(
          'FORMS:hdrManageConditionalLogic',
          {},
          'Manage Conditional Logic'
        ),
        formId: this.formBuilderService.currentFormBuilderFormId,
        component: this.component,
        options: this.options,
        isViewOnly: this.isViewOnly,
        logicValueFormatType: this.logicValueFormatType,
        currentColumnName: this.component.label,
        availableColumns: this.availableColumns,
        logic: rule,
        alwaysTrueAvailable,
        sourceColumn: this.sourceColumn
      }
    );
    this.onModalOpenOrClose.emit(false);
    if (isNewLogic && response?.delete) {
      return; // this is essentially cancel
    }
    if (response) {
      if (response.delete) {
        this.handleDeleteRule(index);
      } else {
        let newData: GlobalValueLogicGroup<BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>[] = [];
        if (isNewLogic) {
          // if new we can add to the bottom of the list
          newData = [
            ...this.logicGroups,
            { ...response }
          ];
        } else {
          // otherwise, update the list with new data
          newData = [
            ...this.logicGroups.slice(0, index),
            { ...response },
            ...this.logicGroups.slice(index + 1)
          ];
        }
        const alwaysTrueRuleArray: GlobalValueLogicGroup<BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>[] = [];
        // remove the always true rule and push it to it's own array
        const conditionalRules = newData.filter((r) => {
          if (r.evaluationType === EvaluationType.AlwaysTrue) {
            alwaysTrueRuleArray.push(r);

            return false;
          } else {
            return true;
          }
        });
        // spread arays so always true rules are on the bottom (evaluated last)
        this.logicGroups = [
          ...conditionalRules,
          ...alwaysTrueRuleArray
        ];
      }
      this.emitValueLogic();
    }
  }

  handleDeleteRule (index: number) {
    this.logicGroups = [
      ...this.logicGroups.slice(0, index),
      ...this.logicGroups.slice(index + 1)
    ];
  }

  emitValueLogic () {
    this.onChange.emit(this.logicGroups);
  }
}

