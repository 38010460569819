import { NgModule } from '@angular/core';
import { GCCoreModule } from '@core/_core.module';
import { FormReferenceFieldsModule } from '@features/forms/form-renderer-components/form-fields/form-reference-fields.module';
import { SwitchModule } from '@yourcause/common/switch';
import { ConditionalLogicBuilderConditionComponent } from './conditional-logic-builder-condition/conditional-logic-builder-condition.component';
import { ConditionalLogicBuilderGroupComponent } from './conditional-logic-builder-group/conditional-logic-builder-group.component';
import { ConditionalLogicBuilderModalComponent } from './conditional-logic-builder-modal/conditional-logic-builder-modal.component';
import { ConditionalLogicBuilderComponent } from './conditional-logic-builder/conditional-logic-builder.component';
import { EvaluationTypeSelectorComponent } from './evaluation-type-selector/evaluation-type-selector.component';
import { LogicSummarySentenceComponent } from './logic-summary-sentence/logic-summary-sentence.component';
import { IsLogicGroupPipe } from './pipes/is-logic-group.pipe';
import { ShowConditionalLogicBuilderPipe } from './pipes/show-conditional-logic-builder.pipe';
import { ShowOtherColumnSelectorPipe } from './pipes/show-other-colmn-selector.pipe';
import { RelativeDateCalculatorComponent } from './relative-date-calculator/relative-date-calculator.component';
import { ValueLogicBuilderModalComponent } from './value-logic-builder-modal/value-logic-builder-modal.component';
import { ValueLogicBuilderComponent } from './value-logic-builder/value-logic-builder.component';

@NgModule({
  imports: [
    GCCoreModule,
    FormReferenceFieldsModule,
    SwitchModule
  ],
  declarations: [
    ShowConditionalLogicBuilderPipe,
    ConditionalLogicBuilderModalComponent,
    ConditionalLogicBuilderComponent,
    ConditionalLogicBuilderGroupComponent,
    ConditionalLogicBuilderConditionComponent,
    IsLogicGroupPipe,
    EvaluationTypeSelectorComponent,
    LogicSummarySentenceComponent,
    ValueLogicBuilderModalComponent,
    ValueLogicBuilderComponent,
    ShowOtherColumnSelectorPipe,
    RelativeDateCalculatorComponent
  ],
  exports: [
    ConditionalLogicBuilderModalComponent,
    ConditionalLogicBuilderComponent,
    EvaluationTypeSelectorComponent,
    LogicSummarySentenceComponent,
    ValueLogicBuilderModalComponent,
    ValueLogicBuilderComponent
  ]
})
export class LogicBuilderModule { }
