import { Component, Input } from '@angular/core';
import { FormDefinitionComponent, ValueLogicResult } from '@features/configure-forms/form.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { YCModalComponent } from '@yourcause/common/modals';
import { LogicBuilderService } from '../logic-builder.service';
import { GlobalValueLogicGroup, LogicColumnDisplay, LogicValueFormatType } from '../logic-builder.typing';
import { SelectOption } from '@yourcause/common/core-forms';
export interface ValueLogicBuilderModalResponse<T> extends GlobalValueLogicGroup<T, ValueLogicResult<T>> {
  delete: boolean;
}

@Component({
  selector: 'gc-value-logic-builder-modal',
  templateUrl: './value-logic-builder-modal.component.html',
  styleUrls: ['./value-logic-builder-modal.component.scss']
})
export class ValueLogicBuilderModalComponent<T> extends YCModalComponent<
  ValueLogicBuilderModalResponse<T>
> {
  @Input() formId: number;
  @Input() builderName: string;
  @Input() component: FormDefinitionComponent;
  @Input() options: (TypeaheadSelectOption|SelectOption)[] = [];
  @Input() logicValueFormatType: LogicValueFormatType;
  @Input() currentColumnName: string;
  @Input() availableColumns: LogicColumnDisplay<T>[];
  @Input() sourceColumn: LogicColumnDisplay<T>;
  @Input() logic: GlobalValueLogicGroup<T, ValueLogicResult<T>>;
  @Input() alwaysTrueAvailable: boolean;
  @Input() isViewOnly = false;

  currentLogic: GlobalValueLogicGroup<T, ValueLogicResult<T>>;
  valid = true;

  constructor (
    private logicBuilderService: LogicBuilderService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  handleLogicChange (logic: GlobalValueLogicGroup<T, ValueLogicResult<T>>) {
    this.currentLogic = logic;
  }

  handleValidChange (valid: boolean) {
    this.valid = valid;
  }

  handleSubmit () {
    if (this.valid) {
      this.closeModal.emit({
        ...(this.currentLogic || this.logic),
        delete: false
      });
    }
    this.analyticsService.emitEvent({
      eventName: 'Submit logic',
      eventType: EventType.Click,
      extras: null
    });
  }

  handleClear () {
    this.logic = this.logicBuilderService.getDefaultConditionalValueLogic();
    this.currentLogic = this.logic;
    // Conditions are required for value logic
    this.handleValidChange(false);
    this.analyticsService.emitEvent({
      eventName: 'Clear logic',
      eventType: EventType.Click,
      extras: null
    });
  }

  deleteRule () {
    this.closeModal.emit({
      ...this.currentLogic,
      delete: true
    });
    this.analyticsService.emitEvent({
      eventName: 'Delete logic',
      eventType: EventType.Click,
      extras: null
    });
  }

  handleCancel () {
    this.closeModal.emit();
  }
}
