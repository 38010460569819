import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeToken } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { ConstructiveFormulaStep, DestructiveFormulaStep, FormulaEvaluationType, FormulaStep, FormulaStepValue, FormulaStepValueType } from '../formula-builder.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';

@Component({
  selector: 'gc-formula-builder-step',
  templateUrl: './formula-builder-step.component.html',
  styleUrls: ['./formula-builder-step.component.scss']
})
export class FormulaBuilderStepComponent<T> implements OnInit {
  @Input() step: FormulaStep<T>;
  @Input() columns: {
    label: string;
    value: string;
  }[];
  @Input() currentDepth: number;
  @Input() maxDepth: number;
  @Input() isViewOnly = false;
  @Output() stepChange = new EventEmitter<FormulaStep<T>>();
  @Output() removeStep = new EventEmitter<void>();
  @Output() validityChanged = new EventEmitter<boolean>();

  FormulaStepValueType = FormulaStepValueType;
  FormulaEvaluationType = FormulaEvaluationType;
  nestedEvaluationOptions: TypeaheadSelectOption<FormulaEvaluationType>[] = [{
    label: '+',
    value: FormulaEvaluationType.Add
  }, {
    label: '-',
    value: FormulaEvaluationType.Subtract
  }, {
    label: '÷',
    value: FormulaEvaluationType.Divide
  }, {
    label: '•',
    value: FormulaEvaluationType.Multiply
  }];
  evaluationOptions: TypeaheadSelectOption<FormulaEvaluationType>[] = [{
    label: this.i18n.translate('common:textAdded', {}, 'Added'),
    value: FormulaEvaluationType.Add
  }, {
    label: this.i18n.translate('common:textSubtracted', {}, 'Subtracted'),
    value: FormulaEvaluationType.Subtract
  }, {
    label: this.i18n.translate('common:textDivided', {}, 'Divided'),
    value: FormulaEvaluationType.Divide
  }, {
    label: this.i18n.translate('common:textMultiplied', {}, 'Multiplied'),
    value: FormulaEvaluationType.Multiply
  }, {
    label: this.i18n.translate('common:textAveraged', {}, 'Averaged'),
    value: FormulaEvaluationType.Average
  }];
  valueTypeOptions: TypeaheadSelectOption<FormulaStepValueType>[] = [{
    label: this.i18n.translate('common:textValueOfComponent', {}, 'Value of component'),
    value: FormulaStepValueType.ParentValue
  }, {
    label: this.i18n.translate('common:textNumericValue', {}, 'Numeric value'),
    value: FormulaStepValueType.Fixed
  }];
  shouldShowRemoveButton: boolean;
  shouldShowAddButton: boolean;
  isDestructive: boolean;
  nestedStepValidity: Record<number, boolean> = {};
  $formulaStepType = new TypeToken<FormulaStep<T>>();
  $number = new TypeToken<number>();

  constructor (
    private i18n: I18nService
  ) { }

  ngOnInit () {
    this.determineButtonVisibility();
    this.determineIsDestructive(this.step.type);
    this.emitValidity();
  }

  determineButtonVisibility () {
    this.shouldShowRemoveButton = this.step.values.length > 2;
    this.shouldShowAddButton = !this.isDestructive;
  }

  updateEvaluationType (type: FormulaEvaluationType) {
    this.step.type = type;
    const wasDestructive = this.isDestructive;
    this.determineIsDestructive(type);

    // if going to an operation that only supports 2 values, and we had more than 2 values, we need to reset
    if (!wasDestructive && this.isDestructive && (this.step.values.length > 2)) {
      this.step.values = [{
        type: FormulaStepValueType.ParentValue,
        value: null
      }, {
        type: FormulaStepValueType.ParentValue,
        value: null
      }];
    }

    this.determineButtonVisibility();
    this.handleStepChange();
  }

  private determineIsDestructive (type: FormulaEvaluationType) {
    this.isDestructive = (
      type === FormulaEvaluationType.Subtract ||
      type === FormulaEvaluationType.Divide
    );
  }

  private addValue (value: FormulaStepValue<T>) {
    const constructiveStep = this.step as ConstructiveFormulaStep<T>;

    this.step = {
      ...constructiveStep,
      values: [
        ...constructiveStep.values,
        value
      ]
    };

    this.determineButtonVisibility();
    this.handleStepChange();
  }

  addFixedValue () {
    this.addValue({
      type: FormulaStepValueType.ParentValue,
      value: null
    });
  }

  addNestedOperation () {
    const nestedOperation = {
      type: FormulaEvaluationType.Add,
      values: [
        {
          type: FormulaStepValueType.ParentValue,
          value: null
        },
        {
          type: FormulaStepValueType.ParentValue,
          value: null
        }
      ]
    } as FormulaStep<T>;

    this.addValue({
      type: FormulaStepValueType.NestedStep,
      value: nestedOperation
    });
  }

  updateValueType (newType: FormulaStepValueType, index: number) {
    const value = this.step.values[index];
    value.type = newType;
    switch (newType) {
      case FormulaStepValueType.Fixed:
        value.value = 0;
        break;
      case FormulaStepValueType.ParentValue:
        value.value = null;
        break;
    }

    this.determineButtonVisibility();
    this.handleStepChange();
  }

  swapOrder () {
    this.step = {
      ...this.step,
      values: [...this.step.values].reverse()
    } as DestructiveFormulaStep<T>;

    this.handleStepChange();
  }

  updateValue (newValue: number|string|FormulaStep<T>, index: number) {
    this.step.values[index].value = newValue as number;

    this.determineButtonVisibility();
    this.handleStepChange();
  }

  removeValue (index: number) {
    this.step.values = [
      ...this.step.values.slice(0, index),
      ...this.step.values.slice(index + 1)
    ];

    this.determineButtonVisibility();
    this.handleStepChange();
  }

  nestedValidityChange (valid: boolean, index: number) {
    this.nestedStepValidity[index] = valid;
    this.emitValidity();
  }

  handleStepChange () {
    this.stepChange.emit(this.step);
    this.emitValidity();
  }

  emitValidity () {
    const values: FormulaStepValue<T>[] = this.step.values;
    const allValuesValid = values.reduce<boolean>((valid: boolean, value: FormulaStepValue<T>, index: number) => {
      return valid && this.checkValueValidity(value as any, index as any);
    }, true);
    const isValid = allValuesValid && values.length > 0;
    this.validityChanged.emit(isValid);

  }

  private checkValueValidity (value: FormulaStepValue<T>, index: number): boolean {
    const val = value.value;
    switch (value.type) {
      case FormulaStepValueType.Fixed:
        return typeof (val) === 'number';
      case FormulaStepValueType.ParentValue:
        return this.columns.some(column => (column.value as string) === (val as string));
      case FormulaStepValueType.NestedStep:
        return !!this.nestedStepValidity[index];
    }
  }
}
