import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InsertTokenService } from '@core/services/insert-token.service';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { AdaptedExternalCommRecord, Communication } from '@features/communications/communications.typing';
import { FormFieldCategoryService } from '@features/form-fields/services/form-field-category.service';
import { BucketComp } from '@features/forms/form-builder/form-builder.typing';
import { ProgramResources } from '@features/programs/program.resources';
import { EmailState } from '@features/system-emails/email.state';
import { BaseEmailOptionsModel, ClientEmailTemplateForUI, ClientEmailTranslationPayload, CommunicationPrefs, Email, EmailDetail, EmailEditableChunk, EmailForUI, EmailMergeModel, EmailNotificationType, EmailOptionsModelForSave, EmailSetupPrefs, EmailTemplateCopyForAPI, EmailTemplateCopyForUI, EmailToken, SendTestEmailPayload, SimpleEmail, UpdateCommPrefsPayload } from '@features/system-emails/email.typing';
import { DragAndDropItem, SimpleStringMap, TableRepositoryFactory } from '@yourcause/common';
import { ExistingGenericFile, FileService, FileTypeService, GenericFile, NewGenericFile } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { PanelSection } from '@yourcause/common/panel';
import { ImageUploadPayload } from '@yourcause/common/rich-text-editor';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import EmailReplyParser from 'email-reply-parser';
import { EmailResources } from './email.resources';
export const CUSTOM_MESSAGE_TOKEN = 'CUSTOM_MESSAGE';
export const REVISION_NOTES_TOKEN = 'REVISION_COMMENT';

@AttachYCState(EmailState)
@Injectable({ providedIn: 'root' })
export class EmailService extends BaseYCService<EmailState> {
  private placeHolder = '<!-- EDITABLE CHUNK PLACEHOLDER -->';
  emailParser = new EmailReplyParser();

   constructor (
    private logger: LogService,
    private emailResources: EmailResources,
    private arrayHelper: ArrayHelpersService,
    private autoTableFactory: TableRepositoryFactory,
    private i18n: I18nService,
    private notifier: NotifierService,
    private programResources: ProgramResources,
    private clientSettingsService: ClientSettingsService,
    private insertTokenService: InsertTokenService,
    private formFieldCategoryService: FormFieldCategoryService,
    private fileTypeService: FileTypeService,
    private fileService: FileService
  ) {
    super();
  }

  get emails () {
    return this.get('emails');
  }

  get communicationPrefs () {
    return this.get('communicationPrefs') || [];
  }

  get mergeModelMap () {
    return this.get('mergeModelMap');
  }

  get templateMap () {
    return this.get('templateMap');
  }

  get emailTemplateTranslationMap () {
    return this.get('emailTemplateTranslationMap');
  }

  get emailSubjectMap () {
    return this.get('emailSubjectMap');
  }

  setEmailsOnState (emails: Email[]) {
    this.set('emails', emails);
  }

  emptyTemplateMap () {
    this.set('templateMap', {});
  }

  setEmailMergeModelOnState (
    emailId: number,
    mergeModel: EmailMergeModel
  ) {
    this.set('mergeModelMap', {
      ...(this.mergeModelMap || {}),
      [emailId]: mergeModel
    });
  }

  setTemplateMapOnState (detail: EmailDetail, type: number) {
    this.set('templateMap', {
      ...this.templateMap,
      [type]: detail
    });
  }

  setCommunicationPrefs (prefs: CommunicationPrefs[]) {
    this.set('communicationPrefs', prefs);
  }

  setEmailMergeModel (
    email: Email
  ) {
    this.setEmailMergeModelOnState(email.emailNotificationType, email.mergeModelType);
  }

  async setEmails () {
    let emails = this.emails;
    if (!emails) {
      const res = await this.emailResources.getEmailTemplates({
        rowsPerPage: 1000,
        pageNumber: 1,
        sortColumns: [],
        filterColumns: [],
        retrieveTotalRecordCount: false,
        returnAll: true
      });
      emails = res.records;
      this.setEmailsOnState(emails);
      this.setEmailSubjectMap(emails);
      emails.forEach(email => {
        this.setEmailMergeModel(email);
      });
    }
  }

  setEmailSubjectMap (emails: Email[]) {
    const map: SimpleStringMap<string> = {};
    emails.forEach((email) => {
      map[email.emailNotificationType] = email.subject;
    });
    this.set('emailSubjectMap', map);
  }

  getEmailMergeModel (notificationType: number) {
    return this.mergeModelMap[notificationType];
  }

  async resetAll (
    type: EmailNotificationType,
    isCopy: boolean,
    programId?: number
  ) {
    if (isCopy) {
      const map = this.templateMap;
      this.setTemplateMapOnState({
        ...map,
        [type]: undefined
      }, type);
      try {
        if (programId) {
          await this.setProgramTemplatesByType(programId, type);
        }
        await this.setTemplateMap(type);
      } catch (e) {
        this.logger.error(e);
        this.notifier.error(this.i18n.translate(
          'GLOBAL:textErrorLoadingEmail',
          {},
          'There was an error loading the copies'
        ));
      }
    }
    this.setEmailsOnState(undefined);
    await this.setEmails();
    const nomTable = this.autoTableFactory.getRepository('NOMINATION_EMAILS');
    const grantTable = this.autoTableFactory.getRepository('PROGRAM_EMAILS');
    const allTable = this.autoTableFactory.getRepository('SYSTEM_EMAILS');
    if (nomTable) {
      nomTable.reset(nomTable.pageNumber);
    }
    if (grantTable) {
      grantTable.reset(grantTable.pageNumber);
    }
    if (allTable) {
      allTable.reset(allTable.pageNumber);
    }
  }

  async getProgramDefault (programId: number, type: EmailNotificationType) {
    await this.setTemplateMap(type);
    await this.setProgramTemplatesByType(programId, type);
    const map = this.templateMap[type];
    const templates = map.programTemplates[programId];
    const found = templates.find((temp) => {
      return temp.isProgramDefault;
    });

    return found ? found.clientEmailTemplateId : 0;
  }

  /**
   * Gets Email for Editing Purposes
   * Finds the Editable and Non-Editable Parts of the email
   * Non editable is the button, which we capture in a separate input field
   *
   * @param body: email template html
   * @returns the editable and not editable chunks of the email
   */
  getEmailAsEditable (body: string): EmailEditableChunk {
    const tempEl = document.createElement('table');
    tempEl.innerHTML = body;
    body = tempEl.innerHTML.replace(/\n/g, '\r\n');
    const editableElement = tempEl.getElementsByTagName('td')[0];

    if (editableElement) {
      /* replace unix line endings with windows, replace html non breaking space with stupid string */
      let editableChunk = editableElement.innerHTML.replace(/\n/g, '\r\n');
      editableChunk = editableChunk.replace(/\&nbsp\;/g, String.fromCharCode(160));
      body = body.replace(/\&nbsp\;/g, String.fromCharCode(160));
      const nonEditableChunk = body.replace(editableChunk, this.placeHolder);

      return {
        nonEditableChunk,
        editableChunk
      };
    }

    return {
      nonEditableChunk: body,
      editableChunk: body
    };
  }

  /**
   * Given an email template's HTML, find the button text
   *
   * @param html: HTML to find button
   * @returns the button text
   */
  getButtonTextFromEmailContent (html: string) {
    const tableEl = document.createElement('table');
    tableEl.innerHTML = html;
    html = tableEl.innerHTML.replace(/\n/g, '\r\n');
    const buttons = tableEl.getElementsByTagName('a');
    const lastButton = buttons[buttons.length - 1];
    if (lastButton) {
      return lastButton.textContent.trim();
    }

    return '';
  }

  /**
   * Finds the button text for a given email
   *
   * @param email: Email
   * @param body: Email content (HTML)
   * @returns the button text from the given email
   */
  getButtonText (
    email: EmailForUI|ClientEmailTemplateForUI|Email,
    body: string
  ) {
    const defaultButtonText = this.i18n.translate(
      'common:textGoToGrantsConnect',
      {},
      'Go to GrantsConnect'
    );

    if ('buttonText' in email && !!email.buttonText) {
      return email.buttonText;
    } else if (!!body) {
      return this.getButtonTextFromEmailContent(body) || defaultButtonText;
    }

    return defaultButtonText;
  }

  /**
   * Adapts the Email Content for Save
   *
   * @param body: Email body
   * @param buttonText: Button text for email
   * @param nonEditableContent: Email content that is non editable (button)
   * @returns the reassembled body with the button text replaced
   */
  adaptEmailContentForSave (
    body: string,
    buttonText: string,
    nonEditableContent: string
  ) {
    const parts: EmailEditableChunk = {
      nonEditableChunk: nonEditableContent,
      editableChunk: body
    };

    return this.reassembleEmailTemplateForSave(parts, buttonText);
  }

  /**
   * Reassamelbe the Email Template for Save
   *
   * @param chunks: Email parts to put back together
   * @param buttonText: The Button text to insert
   * @returns the reassembled email
   */
  reassembleEmailTemplateForSave (
    chunks: EmailEditableChunk,
    buttonText: string
  ): string {
    if (chunks.editableChunk.includes('<html>')) {
      const tempEl = document.createElement('html');
      tempEl.innerHTML = chunks.editableChunk;

      chunks.editableChunk = tempEl.getElementsByTagName('body')[0]
        .innerHTML.replace(/\n/g, '\r\n');
    }

    let adapted = chunks.nonEditableChunk.replace(this.placeHolder, chunks.editableChunk);
    if (!!buttonText) {
      adapted = this.reinsertButtonText(adapted, buttonText);
    }

    return adapted;
  }

  /**
   * Re-insert the Button Text into the Email Content
   *
   * @param content: Email Content HTML
   * @param buttonText: Button text to Insert
   * @returns the adapted HTML after button text is updated
   */
  reinsertButtonText (
    content: string,
    buttonText: string
  ): string {
    const tempEl = document.createElement('table');
    tempEl.innerHTML = content;
    const buttons = tempEl.getElementsByTagName('a');
    const lastButton = buttons[buttons.length - 1];
    if (lastButton) {
      const innerSpan = lastButton.getElementsByTagName('span')[0];
      if (innerSpan) {
        innerSpan.textContent = buttonText;
      }
    }

    return tempEl.innerHTML;
  }

  async getEmailTokens (type: EmailNotificationType) {
    const found = this.emails.find((email) => {
      return email.emailNotificationType === type;
    });
    if (found && found.customizable) {
      const tokens = await this.emailResources.getTokens(type);

      return this.arrayHelper.sort(tokens.filter((token) => {
        const name = token.name.toLowerCase();

        return !name.includes('link');
      }), 'name');
    }

    return [];
  }

  adaptEmailTokensToPanelSections (
    tokens: EmailToken[],
    emailNotificationType: EmailNotificationType
  ): PanelSection<never, BucketComp[]>[] {
    const standardTokenGroup = this.getStandardTokenGroup(tokens);
    const mergeModelType = this.getEmailMergeModel(emailNotificationType);
    const formFieldModels = [
      EmailMergeModel.Application,
      EmailMergeModel.ApplicationForm,
      EmailMergeModel.Award,
      EmailMergeModel.Payment
    ];
    let formFieldTokenGroup: PanelSection<never, BucketComp[]>;
    if (formFieldModels.includes(mergeModelType)) {
      const formFieldMap = this.formFieldCategoryService.getFormFieldCategoryMapByUsage(
        AdHocReportingUI.Usage.TOKENS
      );
      formFieldTokenGroup = this.formFieldCategoryService.formFieldMapToDragAndDropItem(
        formFieldMap,
        false,
        true
      );
    }

    const allSections = [
      standardTokenGroup,
      formFieldTokenGroup
    ].filter((item) => !!item);

    return allSections;
  }

  getStandardTokenGroup (
    tokens: EmailToken[]
  ): PanelSection<never, BucketComp[]> {
    return  {
      name: this.i18n.translate('common:textStandardFields', {}, 'Standard fields'),
      open: true,
      panels: [{
        name: '',
        description: '',
        icon: '',
        iconClass: '',
        context: this.arrayHelper.sort(tokens.map((token) => {
          return {
            key: token.token.replace('{{', '').replace('}}', ''),
            label: token.name,
            name: token.name,
            type: null,
            icon: '',
            tooltip: '',
            fieldAudience: null,
            isReferenceField: false,
            notDraggable: true,
            markAsRequired: false,
            categoryId: null,
            actions: [{
              icon: 'plus',
              tooltip: this.i18n.translate(
                'common:textInsert',
                {},
                'Insert'
              ),
              onClick: (item: DragAndDropItem) => {
                this.insertTokenService.setTokenToInsert(item.key);
              }
            }]
          };
        }), 'name'),
        uniqueId: 'standard'
      }]
    };
  }

  async getClientTemplatesForEmail (
    type: EmailNotificationType,
    langId = this.clientSettingsService.defaultLanguage
  ): Promise<ClientEmailTemplateForUI[]> {
    const clientTemplates = await this.emailResources.getClientTemplatesForEmail(type, langId);
    const email = this.getEmailByType(type);

    return (clientTemplates || []).map((temp) => {
      return {
        ...temp,
        title: temp.title || email.title,
        emailNotificationType: temp.emailNotificationTypeId,
        programClientEmailTemplateId: null,
        isProgramDefault: false,
        clientEmailTemplateId: temp.id,
        isClientTemplateActive: temp.active,
        isProgramTemplateActive: false,
        buttonText: this.getButtonTextFromEmailContent(temp.body)
      };
    });
  }

  async setTemplateMap (
    type: EmailNotificationType,
    force = false,
    langId = this.clientSettingsService.defaultLanguage
  ): Promise<EmailDetail> {
    await this.setEmails();
    let detail: EmailDetail = this.templateMap[type];
    if (
      force ||
      (
        !detail ||
        !detail.template ||
        !detail.tokens ||
        !detail.clientTemplates
      )
    ) {
      const [
        template,
        tokens,
        clientTemplates
      ] = await Promise.all([
        this.emailResources.getEmailTemplate(type),
        this.getEmailTokens(type),
        this.getClientTemplatesForEmail(type, langId)
      ]);
      const email = this.emails.find((item) => {
        return item.emailNotificationType === type;
      });

      detail = {
        template: template.template,
        tokens,
        email,
        clientTemplates
      };

      this.setTemplateMapOnState(detail, type);
    }

    return detail;
  }

  async setProgramTemplatesByType (
    programId: number,
    type: EmailNotificationType
  ) {
    const detail: EmailDetail = this.templateMap[type];
    if (
      !detail ||
      !detail.programTemplates ||
      !detail.programTemplates[programId]
    ) {
      const adaptedProgramTemplates = await this.getAdaptedProgramTempsByEmailType(
        programId,
        type
      );
      this.setTemplateMapOnState({
        ...detail,
        programTemplates: {
          ...detail.programTemplates || {},
          [programId]: this.arrayHelper.sort(adaptedProgramTemplates || [], 'clientEmailTemplateId')
        }
      }, type);
    }
  }

  async getAdaptedProgramTempsByEmailType (
    programId: number,
    type: EmailNotificationType
  ): Promise<ClientEmailTemplateForUI[]> {
    const programTemplates = await this.emailResources.getProgramTemplatesByEmailType(
      programId,
      type
    );

    return programTemplates.map((template) => {
      const clientEmailTemplate = template.clientEmailTemplate;

      return {
        ...clientEmailTemplate,
        clientEmailTemplateId: clientEmailTemplate.id,
        programClientEmailTemplateId: template.id,
        isProgramDefault: template.default,
        isProgramTemplateActive: template.active,
        isClientTemplateActive: clientEmailTemplate.active,
        emailNotificationType: type,
        buttonText: this.getButtonTextFromEmailContent(clientEmailTemplate.body)
      };
    });
  }

  async toggleActivateClientEmail (id: number, activate = true) {
    if (activate) {
      await this.emailResources.activateClientTemplate(id);
    } else {
      await this.emailResources.deactivateClientTemplate(id);
    }
  }

  isEmailActive (type: EmailNotificationType) {
    const found = this.getEmailByType(type);
    if (found) {
      return !found.isDisabled;
    }

    return true;
  }

  async isProgramEmailActive (type: EmailNotificationType, programId: number) {
    await this.setEmails();
    const response = await this.programResources.getProgramEmailSettings(programId);
    const found = response.disabledEmails.find((item) => {
      return item.id === type;
    });

    return found ? false : this.isEmailActive(type);
  }

  getEmailByType (type: EmailNotificationType) {
    return  (this.emails || []).find((email) => {
      return +email.emailNotificationType === +type;
    });
  }

  async getCommunicationPrefs () {
    const prefs = await this.emailResources.getCommunicationPrefs();
    this.setCommunicationPrefs(prefs || []);
  }

  async updateCommunicationsPrefs (
    payload: UpdateCommPrefsPayload,
    email: EmailSetupPrefs
  ) {
    try {
      await this.emailResources.updateCommunicationPrefs(payload);
      this.notifier.success(this.i18n.translate(
        'ACCOUNT:textSuccessfullyUpdatedCommunicationPrefs',
        {},
        'Successfully updated communication preferences'
      ));
      const index = this.communicationPrefs.findIndex((pref) => {
        return pref.emailNotificationType === email.type;
      });
      let updatedPrefs;
      if (index > -1) {
        updatedPrefs = [
          ...this.communicationPrefs.slice(0, index),
          payload,
          ...this.communicationPrefs.slice(index + 1)
        ];
      } else {
        updatedPrefs = [
          ...this.communicationPrefs,
          payload
        ];
      }
      this.setCommunicationPrefs(updatedPrefs);
    } catch (e) {
      this.notifier.error(this.i18n.translate(
        'ACCOUNT:textErrorUpdatedCommunicationPrefs',
        {},
        'There was an error updating communication preferences'
      ));
    }
  }

  /**
   * Handle attaching file to email
   *
   * @param file: File to Attaach
   * @param applicationId: Application ID
   * @param clientEmailTemplateId: Client Email Template ID
   * @param filesCombinedSize: Combined File Size
   * @returns the new attachment ID
   */
  async handleAttachmentToEmail (
    file: NewGenericFile,
    applicationId?: number,
    clientEmailTemplateId?: number,
    filesCombinedSize?: number
  ): Promise<{
    error: HttpErrorResponse;
    fileId: number;
  }> {
    try {
      const fileId = await this.emailResources.addAttachmentToEmailTemplate(
        file.file,
        applicationId,
        clientEmailTemplateId,
        filesCombinedSize
      );

      return {
        fileId,
        error: null
      };
    } catch (err) {
      const e = err as HttpErrorResponse;
      this.fileTypeService.displayInvalidFileUploadErrorMessage(e?.error?.message, e);

      return {
        fileId: null,
        error: e
      };
    }
  }

  /**
   * Gets the payload to save an email template copy
   *
   * @param template: Template for save
   * @param attachments: Attachments to save
   * @param emailId: Email ID
   * @param isEdit: Is this for edit?
   * @returns the payload to save the copy
   */
  async getEmailCopyPayload (
    template: EmailTemplateCopyForUI,
    attachments: GenericFile[],
    emailId: number,
    isEdit: boolean
  ) {
    const attachmentArray = await this.getAttachmentIds(
      attachments,
      null,
      emailId
    );
    if (!!attachmentArray) {
      const copyPayload: EmailTemplateCopyForAPI = {
        ...template,
        id: isEdit ? emailId : null,
        emailAttachments: attachmentArray
      };

      return copyPayload;
    }

    return null;
  }

  /**
   * Adapts the email options model for save
   *
   * @param emailOptions: Email Options
   * @param applicationId: Application ID
   * @returns the email options model for save
   */
  async getEmailOptionsModelForSave (
    emailOptions: BaseEmailOptionsModel,
    applicationId?: number // pass for one off email sends for an application
  ): Promise<EmailOptionsModelForSave> {
    const attachments = await this.getAttachmentIds(
      emailOptions.attachments,
      applicationId
    );
    if (!!attachments) {
      return {
        ...emailOptions,
        attachments
      };
    }

    return null;
  }

  /**
   * Get attachment IDs for save
   *
   * @param attachments: Attachments to save
   * @param applicationId: Application ID
   * @param clientEmailTemplateId: Client Email Temlate ID
   * @returns the uploaded attachment IDs
   */
  async getAttachmentIds (
    attachments: GenericFile[],
    applicationId?: number, // pass for one off email sends for an application
    clientEmailTemplateId?: number // pass for system email attachment changes
  ): Promise<number[]> {
    let hasError = false;
    try {
      attachments = attachments || [];
      const filesCombinedSize = this.fileService.getFilesCombinedSize(
        0,
        attachments
      );
      const attachmentArray: number[] = attachments.filter((att) => {
        return ('fileUploadId' in att);
      }).map((att) => (att as ExistingGenericFile).fileUploadId);
      // Grab the new attachments for uploading
      await Promise.all(attachments.map(async (gFile: GenericFile) => {
        if (!('fileUploadId' in gFile)) {
          // Upload new attachments and return ID
          const {
            fileId,
            error
          } = await this.handleAttachmentToEmail(
            gFile,
            applicationId,
            clientEmailTemplateId,
            filesCombinedSize
          );
          if (!!fileId) {
            attachmentArray.push(fileId);
          } else if (!!error) {
            hasError = true;
          }
        }
      }));

      return !hasError ? attachmentArray : null;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorSavingAttachments',
        {},
        'There was an error saving attachments'
      ));

      return null;
    }
  }

  async handleBulkAddTranslationToClientEmail (
    email: SimpleEmail,
    templates: EmailTemplateCopyForUI[]
  ) {
    try {
      const payloadArray: ClientEmailTranslationPayload[] = [];
      templates.forEach((translation) => {
        const payload = {
          subjectText: translation.subject,
          titleText: translation.title,
          bodyText: translation.body,
          id: email.id,
          languageId: translation.languageId,
          buttonText: translation.buttonText
        };
        payloadArray.push(payload);
      });
      const bulkTranslationPayload = {
        clientEmailTemplateId: email.id,
        translations: payloadArray
      };
      await this.emailResources.bulkAddTranslationToClientEmail(bulkTranslationPayload);
      this.notifier.success(this.i18n.translate(
        'common: textSuccessfullySavedTranslations',
        {},
        'Successfully saved the translations'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'GLOBAL:textErrorTranslatingEmailCopy',
        {},
        'There was an error translating the email copy'
      ));
    }
  }

  async handleCreateOrUpdateCopy (
    copyPayload: EmailTemplateCopyForAPI
  ) {
    const isEdit = !!copyPayload.id;
    try {
      const id =  this.emailResources.createCopy(copyPayload);
      this.notifier.success(this.i18n.translate(
        'ACCOUNT:textSuccessfullyUpdatedEmailCopy',
        {},
        'Successfully updated email copy'
      ));

      return id;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        isEdit ?
          'GLOBAL:textErrorUpdatingEmailCopy' :
          'GLOBAL:textErrorCretingEmailCopy',
        {},
        isEdit ?
          'There was an error updating the email copy' :
          'There was an error creating the email copy'
      ));

      return null;
    }
  }

  async getUpdatedAttachments (
    type: EmailNotificationType,
    clientEmailTemplateId: number,
    langId = this.clientSettingsService.defaultLanguage
  ): Promise<ExistingGenericFile[]> {
    const templates = await this.emailResources.getClientTemplatesForEmail(type, langId);
    const found = templates.find((temp) => {
      return temp.id === clientEmailTemplateId;
    });

    return found.emailAttachments;
  }

  getShowCheckboxForCcEdit (type: EmailNotificationType) {
    // these don't have user input, so no need to allow CC/BCC edits
    return ![
      EmailNotificationType.ApplicationSubmittedConfirmation,
      EmailNotificationType.NominationSubmittedConfirmation,
      EmailNotificationType.AppAdditionalFormSubmittedApplicant,
      EmailNotificationType.AppAdditionalFormSubmittedManager,
      EmailNotificationType.NomAdditionalFormSubmittedApplicant,
      EmailNotificationType.NomAdditionalFormSubmittedManager,
      EmailNotificationType.VettingDeclinedApplicant,
      EmailNotificationType.VettingApprovedApplicant,
      EmailNotificationType.VettingApprovedManager,
      EmailNotificationType.VettingDeclinedManager,
      EmailNotificationType.PaymentFulfilled,
      EmailNotificationType.ProgramClosingReminderForApplicant,
      EmailNotificationType.ProgramClosingReminderForNominator
    ].includes(type);
  }

  async getClientTranslatedTemplate (
    languageId: string,
    emailId: number
  ): Promise<{
    translatedTemplate: EmailTemplateCopyForUI;
    emailParts: EmailEditableChunk;
  }> {
    const detail = await this.emailResources.getClientTranslatedTemplate(languageId, emailId);
    const emailParts = this.getEmailAsEditable(detail.body);

    return {
      translatedTemplate: {
        id: emailId,
        name: detail.name,
        emailNotificationTypeId: detail.emailNotificationTypeId,
        title: detail.title,
        body: emailParts.editableChunk,
        subject: detail.subject,
        default: false,
        description: detail.description,
        languageId,
        defaultLanguageId: languageId,
        ccEmails: detail.ccEmails,
        bccEmails: detail.bccEmails,
        allowCarbonCopyUpdates: detail.allowCarbonCopyUpdates,
        documentTemplates: detail.documentTemplates,
        buttonText: this.getButtonTextFromEmailContent(detail.body)
      },
      emailParts
    };
  }

  /**
   * Uplaod an Email Image for Rich Text Editor
   *
   * @param payload: Image Upload Payload
   * @returns the url or error message if it fails
   */
  async uploadEmailImage (payload: ImageUploadPayload): Promise<{
    errorMessage: string;
    url: string;
  }> {
    try {
      const url = await this.emailResources.uploadEmailImage(payload);
      this.notifier.success(this.i18n.translate(
        'common:textSuccessfullyUploadedImage',
        {},
        'Successfully uploaded image'
      ));

      return {
        url,
        errorMessage: ''
      };
    } catch (err) {
      const e = err as HttpErrorResponse;
      this.logger.error(err);
      const  {
        message
      } = this.fileTypeService.getInvalidFileErrorMessage(e?.error?.message);

      return {
        errorMessage: message,
        url: ''
      };
    }
  }

  getEmailFragmentsFromString (contentString: string) {
    return this.emailParser.read(contentString);
  }

  getAdaptedCommRecord (
    commRecord: Communication
  ): AdaptedExternalCommRecord {
    const parsedEmail = this.getEmailFragmentsFromString(commRecord.content);
    const emailFragments = parsedEmail.fragments;
    const latestComm = emailFragments[0];
    const previousCommsThread = emailFragments.filter(
      (
        fragment: EmailReplyParser.Fragment,
        index: number
      ) => {
      return index!== 0 &&
        !!fragment.content &&
        fragment.content !== '>' &&
        fragment.content.length > 1;
      }
    );

    return {
      ...commRecord,
      content: emailFragments,
      latestComm,
      previousCommsThread
    };
  }

  toggleActivateEmail (emailNotificationType: EmailNotificationType) {
    return this.emailResources.toggleActivateEmail(emailNotificationType);
  }

  sendTestEmail (payload: SendTestEmailPayload) {
    return this.emailResources.sendTestEmail(payload);
  }

  getProgramActiveCount (programId: number) {
    return this.emailResources.getProgramActiveCount(programId);
  }

  sendYouHaveBeenNominatedEmail (
    appId: number,
    emailQueueId: number
  ) {
    return this.emailResources.sendYouHaveBeenNominatedEmail(appId, emailQueueId);
  }

  /**
   * Check whether the given template contains the custom message token
   *
   * @param body: Body of template
   * @param emailType: Email Type
   * @returns if the template has the custom message token
   */
  doesTemplateHaveCustomMessageToken (body: string, emailType: EmailNotificationType) {
    if (
      emailType === EmailNotificationType.RequestRevision ||
      emailType === EmailNotificationType.RequestRevisionNom
    ) {
      // In Revision Request email, Revision notes is the same as custom message
      return !!(body?.includes(CUSTOM_MESSAGE_TOKEN) ||
        body?.includes(REVISION_NOTES_TOKEN));
    }

    return !!(body?.includes(CUSTOM_MESSAGE_TOKEN));
  }
}
