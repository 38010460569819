
export enum WorkflowLevelOptions {
  ALLOW_APPROVAL = 1,
  ALLOW_DECLINE = 2,
  ALLOW_REQUEST_REVISION = 3,
  ALLOW_ARCHIVE_APPLICATIONS = 4,
  ALLOW_AWARD_ACTIONS = 5,
  ALLOW_RECOMMEND_FUNDING = 6,
  ALLOW_BUDGET_ASSIGNMENT = 7
}

export enum WorkflowLevelPermissions {
  LEVEL_ONLY = 1,
  PASSTHROUGH = 2,
  MANAGER = 3
}

export enum WorkflowManagerActions {
  Approve = 1,
  Route = 2,
  UpdateCycle = 3,
  Decline = 4,
  ArchiveUnarchive = 5,
  NotifyOfStatus = 6,
  AwardPay = 7,
  UpdateStatus = 8,
  DeleteApplications = 9,
  ViewCommunications = 10,
  ManageCollabs = 11,
  SendReminder = 12,
  MailMerge = 13,
  AddTags = 14,
  UpdateProgram = 15,
  Cancel = 16,
  BudgetAssignment = 17
}

export interface Workflow {
  levels: WorkflowLevel[];
  id: number;
  name: string;
  description: string;
  active: boolean;
  hasApplications: boolean;
  hasAutomationRules: boolean;
  isWorkflowOnProgram?: boolean;
  workflowLevelManagers: WorkflowManager[];
}

export interface SimpleWorkflowUser {
  id: number;
  name: string;
  permissions: WorkflowLevelPermissions[];
  workflowLevelNames: string[];
}

export interface WorkflowDetailFromApi {
  levels: WorkflowLevel[];
  id: number;
  name: string;
  description: string;
  active: boolean;
  hasApplications: boolean;
  workflowLevelManagers: WorkflowManager[];
}

export interface WorkflowDetailForUi extends WorkflowDetailFromApi{
  grantPrograms: WorkflowGrantProgram[];
}

export interface WorkflowManager {
  clientUserId: number;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  workflowActions: WorkflowManagerActions[];
}

export interface WorkflowLevel {
  id: number;
  name: string;
  description: string;
  allowDeclination: boolean;
  allowApproval: boolean;
  allowAward: boolean;
  allowUserToRequestRevision: boolean;
  allowUserToArchiveAndUnarchive: boolean;
  allowBudgetAssignment: boolean;
  showMaskedApplicantInfo: boolean;
  showBudgetSummaryInfo: boolean;
  hasAutomationRules: boolean;
  allowUserToViewAwardsAndPayments: boolean;
  workflowLevelAudiences: WorkflowLevelAudience[];
  workflowLevelUsers: WorkflowLevelUser[];
  individualWorkflowLevelUsers: WorkflowLevelUser[]
  routes: WorkflowRoute[];
  sortOrder: number;
  disabled: boolean;
  grantPrograms: WorkflowGrantProgram[];
  isDefaultLevelOnProgram: boolean;
  allowFormDueDateExtension: boolean;
  allowRecommendedFunding: boolean;
  subLevels: WorkflowLevel[];
  parentName?: string;
}

export interface WorkflowGrantProgram {
  id: number;
  name: string;
}

export interface AutomationSubLevel extends WorkflowLevel {
  parent?: WorkflowLevel;
}

export interface WorkflowLevelUser {
  clientUserId: number;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  workflowLevelAccessType: WorkflowLevelPermissions;
}

export interface WorkflowRoute {
  workflowId: number;
  canRouteToWorkflowLevelId: number;
  canRouteToWorkflowLevelName: string;
  canRouteToWorkflowLevelDescription: string;
  disabled: boolean;
}

export interface WorkflowLevelUsersWithoutReviews {
  clientUserId: number;
  email: string;
  firstName: string;
  lastName: string;
}

export interface WorkflowLevelRoute {
  workflowLevelId: number;
  name: string;
  description: string;
}

export interface UsersAndRoutesLevel extends WorkflowLevel {
  parentName?: string;
}

export interface AddEditWorkflow {
  id: number;
  name: string;
  description: string;
  levels: AddEditWorkflowLevel[];
}

export interface AddEditWorkflowLevel {
  id: number;
  name: string;
  description: string;
  reviewRuleType?: number;
  allowDeclination: boolean;
  allowApproval: boolean;
  allowAward: boolean;
  allowUserToArchiveAndUnarchive: boolean;
  allowUserToRequestRevision: boolean;
  allowFormDueDateExtension: boolean;
  showMaskedApplicantInfo: boolean;
  subLevels?: AddEditWorkflowLevel[];
}

export interface AddEditLevelResponse {
  name: string;
  description: string;
  levelActions: WorkflowLevelOptions[];
  allowUserToViewAwardsAndPayments: boolean;
  showMaskedApplicantInfo: boolean;
  allowFormDueDateExtension: boolean;
  showBudgetSummaryInfo: boolean;
}

export interface SimpleWorkflowLevelUser {
  clientUserId: number;
  workflowLevelUserAccessType: WorkflowLevelPermissions;
}

export interface ManageWFLUsersModalResponse {
  audiences: WorkflowLevelAudience[];
  oneOffUsers: SimpleWorkflowLevelUser[];
  goToRouteModal: boolean;
}

export interface WorkflowLevelAudience {
  audienceId: number;
  workflowId: number;
  workflowLevelId: number;
  workflowLevelAccessType: WorkflowLevelPermissions;
}

export interface ToggleWorkflowLevelResponse {
  workflowLevelId: number;
  disable: boolean;
}

export interface AddEditWorkflowManagerPayload {
  workflowId: number;
  clientUserId: number;
  workflowLevelActions: WorkflowManagerActions[];
}
