import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { ExternalAPIService } from '@features/external-api/external-api.service';
import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { DependentSelectOption } from '@yourcause/common/double-picklist';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';

export interface ExternalAPISelection {
  integrationId: string;
  responseField: string;
}

@Component({
  selector: 'gc-external-api-selector-settings',
  templateUrl: './external-api-selector-settings.component.html',
  styleUrls: ['./external-api-selector-settings.component.scss']
})
export class ExternalAPISelectorSettingsComponent extends BaseComponentConfigSettingsComponent implements OnInit {
  @Input() apiConfig: ExternalAPISelection;
  @Output() onChange = new EventEmitter<ExternalAPISelection>();

  formGroup: TypeSafeFormGroup<{
    value: ExternalAPISelection;
  }>;
  apiOptions = this.externalApiService.integrations
    .map<DependentSelectOption<ExternalAPISelection>>(integration => {
      return {
        display: integration.name,
        value: integration.externalApiRequestGuid,
        dependentOptions: integration.responseFields.map(field => {
          return {
            display: field.displayName,
            value: {
              responseField: field.fieldName,
              integrationId: integration.externalApiRequestGuid
            }
          };
        })
      };
    });
  selectedOption: DependentSelectOption<ExternalAPISelection>;

  constructor (
    private externalApiService: ExternalAPIService,
    private formBuilder: TypeSafeFormBuilder
  ) {
    super();
  }

  ngOnInit () {
    this.setUpFormGroup();
  }

  setUpFormGroup () {
    const existingField = this.getExistingField(this.apiConfig);

    this.formGroup = this.formBuilder.group({
      value: [existingField ? existingField.value : null, Validators.required]
    });
  }

  private getExistingField (data: ExternalAPISelection) {
    const existingIntegration = this.apiOptions
      .find(option => {
        return option.value === data?.integrationId;
      });

    this.selectedOption = existingIntegration;
    const existingField = existingIntegration ? existingIntegration
      .dependentOptions.find(option => {
        return option.value.responseField === this.apiConfig?.responseField;
      }) : null;

    return existingField;
  }
}
