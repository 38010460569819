<!-- Edit Component -->
<yc-button 
  [icon]="true"
  (onClick)="actionClick.emit(FormBuilderActions.Edit_Component)"
  [tooltip]="'CONFIG:btnEditComponent' | ycI18n: 'Edit component'">
  <yc-fa icon="pencil"></yc-fa>
</yc-button>

<!-- Edit Form Field -->
<yc-button *ngIf="isTypeFormField && allowEditFormField"
  [icon]="true"
  (onClick)="actionClick.emit(FormBuilderActions.Edit_Field)"
  [tooltip]="'FORMS:textEditFormField' | ycI18n: 'Edit form field'">
  <yc-fa icon="wrench"></yc-fa>
</yc-button>

<!-- Cut -->
<yc-button *ngIf="!clipboardFull"
  [icon]="true"
  (onClick)="actionClick.emit(FormBuilderActions.Cut)"
  [tooltip]="'CONFIG:btnCutComponent' | ycI18n: 'Cut component'">
  <yc-fa icon="cut"></yc-fa>
</yc-button>

<!-- Copy -->
<yc-button *ngIf="allowCopy && !clipboardFull && !isInvalidType"
  [icon]="true"
  (onClick)="actionClick.emit(FormBuilderActions.Copy)"
  [tooltip]="'CONFIG:btnCopyComponent' | ycI18n: 'Copy component'">
  <yc-fa icon="copy"></yc-fa>
</yc-button>

<!-- Paste Above-->
<yc-button *ngIf="clipboardFull"
  [icon]="true"
  (onClick)="actionClick.emit(FormBuilderActions.Paste_Above)"
  [tooltip]="'CONFIG:btnPasteAbove' | ycI18n: 'Paste above'"
  [value]="'CONFIG:btnPasteAboveComponentKey' | ycI18n: 'Paste above component __key__': { key: componentKey }">
  <yc-fa icon="arrow-from-bottom"></yc-fa>
</yc-button>

<!-- Paste Below -->
<yc-button *ngIf="clipboardFull"
  [icon]="true"
  (onClick)="actionClick.emit(FormBuilderActions.Paste_Below)"
  [tooltip]="'CONFIG:btnPasteBelow' | ycI18n: 'Paste below'">
  <yc-fa icon="arrow-from-top"></yc-fa>
</yc-button>

<!-- Paste Into Container -->
<yc-button *ngIf="clipboardFull && allowPasteInto"
  [icon]="true"
  (onClick)="actionClick.emit(FormBuilderActions.Paste_Into_Container)"
  [tooltip]="'CONFIG:btnPasteIntoContainer' | ycI18n: 'Paste into container'">
  <yc-fa icon="paste"></yc-fa>
</yc-button>

<!-- Remove -->
<yc-button 
  [icon]="true"
  (onClick)="actionClick.emit(FormBuilderActions.Remove)"
  [tooltip]="'CONFIG:btnDeleteComponent' | ycI18n: 'Delete component'">
  <yc-fa icon="trash"></yc-fa>
</yc-button>
