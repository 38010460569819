import { EventEmitter, Injectable } from '@angular/core';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { AddOrgAPI } from '@core/typings/api/add-org.typing';
import { AdaptedNppSearchResult, OrgUnion, VettingRequestStatusId } from '@core/typings/organization.typing';
import { ProcessingTypes } from '@core/typings/payment.typing';
import { AllowedLocation } from '@core/typings/program.typing';
import { AddOrgUI } from '@core/typings/ui/add-org.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { NonprofitService } from '@features/nonprofit/nonprofit.service';
import { AddressFormatterService, OrganizationEligibleForGivingStatus, OrganizationSearchResponse, PaginationOptions, SearchResult } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory } from '@yourcause/common/modals';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { AddOrgModalComponent } from './add-org-modal/add-org-modal.component';
import { AddOrganizationState } from './add-organization.state';

@Injectable({ providedIn: 'root' })
@AttachYCState(AddOrganizationState)
export class AddOrganizationService extends BaseYCService<AddOrganizationState> {
  closeModal: EventEmitter<{
    selectionType: AddOrgUI.SelectionType;
    searchResult: AdaptedNppSearchResult;
    isInvalidOrg: boolean;
  }>;
  addOrgModalHeader = this.i18n.translate(
    'common:textAddOrganization',
    {},
    'Add Organization'
  );
  vettingRequiredHeader = this.i18n.translate(
    'common:textVettingRequired',
    {},
    'Vetting Required'
  );
  vettingRequiredForNewOrg = this.i18n.translate(
    'addOrg:textVettingRequiredForNewOrg',
    {},
    `Review your organization's information below. You can click "Back" to return to the previous screen to make any edits. If this information is correct, click "Submit and continue" to add your organization and return to your application. Once submitted, we will contact the representative you provided to begin the vetting process. For more information about vetting, see the Help Center.`
  );
  vettingRequiredForExistingOrgText = this.i18n.translate(
    'addOrg:textVettingRequireExistingOrg',
    {},
    `The organization you've selected has not yet been vetted by Blackbaud to determine if it's eligible to receive funds. Provide contact details for a representative of your organization that can be contacted about applying for vetting. To continue to your application, click "Submit and continue". Once submitted, an email will be sent to them to begin the vetting process. For more information about vetting, see the Help Center.`
  )
  clientProcessingNewOrgText = this.i18n.translate(
    'addOrg:textClientProcessingNewOrg4',
    {},
    `To proceed with your application using this nonprofit, click \'Next.\' If you wish to return to search click \'Cancel\'`
  );
  orgAlreadyExistsDuringAddConfirmationText = this.i18n.translate(
    'addOrg:textContinueWithVettedOrg2',
    {},
    'The identification number you entered matches the nonprofit below. To proceed with your application using this nonprofit click \'Next\'. If you wish to return to search click \'Cancel\'.'
  );
  isGrantManager = this.portalDeterminationService.isManager;
  addingOrg = false;
  vettingOrg = false;

  constructor (
    private clientSettingsService: ClientSettingsService,
    private i18n: I18nService,
    private addressFormatter: AddressFormatterService,
    private portalDeterminationService: PortalDeterminationService,
    private nonprofitService: NonprofitService,
    private modalFactory: ModalFactory
  ) {
    super();
  }

  get isApplicant (): boolean {
    return this.portalDeterminationService.isApply;
  }

  get addOrgPayload (): AddOrgUI.AddOrgPayload {
    return this.get('addOrgPayload');
  }

  get showContact () {
    return this.get('showContact');
  }

  get vettingContactInfo () {
    return this.get('vettingContactInfo');
  }

  get selectionType () {
    return this.get('selectionType');
  }

  get primaryDisabled () {
    return this.get('primaryDisabled');
  }

  get originalSearchResponse () {
    return this.get('originalSearchResponse');
  }

  get currentPage () {
    return this.get('currentPage');
  }

  get clientId () {
    return this.get('clientId');
  }

  get processorType () {
    return this.get('processorType');
  }

  get searchResult () {
    return this.get('searchResult');
  }

  get confirmationText () {
    return this.get('confirmationText');
  }

  get helpCenterConfirmationLink () {
    return this.get('helpCenterConfirmationLink');
  }

  setOriginalSearchResponse (payload: OrgUnion) {
    return this.set('originalSearchResponse', payload);
  }

  setCurrentPage (page: AddOrgUI.AddOrgModalPages) {
    this.set('currentPage', page);
  }

  setShowContactForm (showContact: boolean) {
    this.set('showContact', showContact);
  }

  setClientId (clientId: number) {
    this.set('clientId', clientId);
  }

  setAddOrgAttr<K extends keyof AddOrgUI.AddOrgPayload> (
    attr: K,
    data: AddOrgUI.AddOrgPayload[K]
  ) {
    this.set('addOrgPayload', {
      ...this.addOrgPayload,
      [attr]: data
    });
  }

  setVettingContactInfo<K extends keyof AddOrgUI.VettingContactInfo> (
    attr: K,
    data: AddOrgUI.VettingContactInfo[K]
  ) {
    this.set('vettingContactInfo', {
      ...this.vettingContactInfo,
      [attr]: data
    });
  }

  setValidation (valid: boolean) {
    return this.set('primaryDisabled', !valid);
  }

  setProcessorType (processorType: ProcessingTypes) {
    this.set('processorType', processorType);
  }

  setSearchResult (searchResult: AdaptedNppSearchResult) {
    this.set('searchResult', searchResult);
  }

  setSelectionType (selectionType: AddOrgUI.SelectionType) {
    this.set('selectionType', selectionType);
  }

  setConfirmForVettingNewOrg () {
    this.set('confirmationText', this.vettingRequiredForNewOrg);
    this.setVettingHelpLinks();
  }

  setConfirmForVettingExistingOrg () {
    this.set('confirmationText', this.vettingRequiredForExistingOrgText);
    this.setVettingHelpLinks();
  }

  setConfirmExistingOrgNoVettingNeeded () {
    this.set('confirmationText', this.orgAlreadyExistsDuringAddConfirmationText);
    this.setSelectionType(AddOrgUI.SelectionType.VETTED_ORG);
  }

  setVettingHelpLinks () {
    const vetOrganizationsHelpLinkApplicant = 'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-applicants-faq-why-is-vetting-required-for-organizations.html';
    const vetOrganizationsHelpLinkManager = 'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-applications-vetting-organizations.html';
    const link = this.portalDeterminationService.isManager ?
      vetOrganizationsHelpLinkManager :
      vetOrganizationsHelpLinkApplicant;
    this.set('helpCenterConfirmationLink', link);
  }

  setModalHeaderForAddOrg () {
    this.set('modalHeader', this.addOrgModalHeader);
  }

  setModalHeaderToVettingRequired () {
    this.set('modalHeader', this.vettingRequiredHeader);
    this.addingOrg = false;
    this.vettingOrg = true;
  }

  setHelpersBasedOnCurrentPage () {
    switch (this.currentPage) {
      case AddOrgUI.AddOrgModalPages.ID_LOOKUP:
      case AddOrgUI.AddOrgModalPages.DETAILS:
      case AddOrgUI.AddOrgModalPages.ADDRESS:
        this.addingOrg = true;
        this.vettingOrg = false;
        break;
      case AddOrgUI.AddOrgModalPages.CONFIRMATION:
        this.addingOrg = false;
        this.vettingOrg = false;
        break;
      default:
        this.addingOrg = true;
        this.vettingOrg = false;
        break;
    }
  }

  handlePrimaryButtonClick () {
    switch (this.currentPage) {
      case AddOrgUI.AddOrgModalPages.ID_LOOKUP:
        return this.handleIDLookupForAddOrg();
      case AddOrgUI.AddOrgModalPages.DETAILS:
        return this.handleDetails();
      case AddOrgUI.AddOrgModalPages.ADDRESS:
        return this.handleAddress();
      case AddOrgUI.AddOrgModalPages.CONFIRMATION:
        return this.handleConfirmation();
      default:
        return this.handleIDLookupForAddOrg();
    }
  }

  handleTertiaryButtonClick () {
    switch (this.currentPage) {
      case AddOrgUI.AddOrgModalPages.ID_LOOKUP:
        return this.handleCancelClick();
      case AddOrgUI.AddOrgModalPages.DETAILS:
        return this.handleBackButton(AddOrgUI.AddOrgModalPages.ID_LOOKUP);
      case AddOrgUI.AddOrgModalPages.ADDRESS:
        return this.handleBackButton(AddOrgUI.AddOrgModalPages.DETAILS);
      case AddOrgUI.AddOrgModalPages.CONFIRMATION:
        if (this.selectionType !== AddOrgUI.SelectionType.NEW_ORG) {
          return this.handleCancelClick();
        } else {
          return this.handleBackButton(AddOrgUI.AddOrgModalPages.ADDRESS);
        }
      default:
        return this.handleCancelClick();
    }
  }

  handleCancelClick () {
    this.closeModal.emit();
  }

  cleanUserRegIDInput (
    regId: string,
    allowDashes = false
  ) {
    const regex = allowDashes ? /[^a-zA-Z0-9- ]/g : /[^a-zA-Z0-9 ]/g;

    const adapted = regId.replace(regex, '').replace(/\s/g, '');

    return (adapted || '').toLowerCase();
  }

  async handleIDLookupForAddOrg () {
    this.setShowContactForm(false);
    let searchResult: AdaptedNppSearchResult;
    const regId = this.addOrgPayload.id;
    const regAuthorities = await this.clientSettingsService.getRegAuthoritiesPerCountry(this.addOrgPayload.country);
    const regAuthFilters = ([
      ...regAuthorities.domesticRegistrationAuthorities
    ]).map((regAuth) => {
      return {
        filterName: 'RegistrationAuthority',
        filterValue: regAuth.name
      };
    });
    const nppSearchResponse = await this.nonprofitService.searchPublicNonprofits(
      regId,
      regAuthFilters,
      1,
      20,
      []
    );
    const nppSearchResults = nppSearchResponse?.results?.map((result) => {
      return SearchResult.construct(result);
    });
    // check to see if any results match the registrationId
    // (false hits dues to fuzzy search)
    const nppResult = nppSearchResults.find((result) => {
      const cleanedNppRegId = this.cleanUserRegIDInput(result.document.registrationId, false);
      const cleanedRegId = this.cleanUserRegIDInput(regId, false);

      return cleanedNppRegId === cleanedRegId;
    });
    if (!nppResult) {
      // No NPOc results found, so check our private GC
      const gcSearchResponse = await this.nonprofitService.searchPrivateOrgs(
        this.clientId,
        this.getGcOrgLookupPagination(regId)
      );
      // No results found, so proceed with Add Org
      if (!gcSearchResponse.records[0]) {
        if (this.processorType !== ProcessingTypes.YourCause) {
          this.set('confirmationText', this.clientProcessingNewOrgText);
        } else {
          this.setHelpersBasedOnCurrentPage();
          this.setConfirmForVettingNewOrg();
        }
        this.setSelectionType(AddOrgUI.SelectionType.NEW_ORG);

        return this.setCurrentPage(AddOrgUI.AddOrgModalPages.DETAILS);
      } else {
        // Private orgs found that match the reg id entered
        if (this.processorType !== ProcessingTypes.YourCause) {
          this.setConfirmExistingOrgNoVettingNeeded();
        } else {
          this.handleFoundAddedOrgThatNeedsVetting();
        }
        this.setSelectionType(AddOrgUI.SelectionType.PRIVATE_ORG); 
        searchResult = this.adaptClientOrgSearchResult(gcSearchResponse.records[0]);
      }
    } else {
      searchResult = this.nonprofitService.adaptNonprofitSearchResult(nppResult);
      this.set('originalSearchResponse', searchResult);
      const eligibleForGivingStatus = searchResult.eligibleForGivingStatusId;
      if (
        eligibleForGivingStatus === OrganizationEligibleForGivingStatus.ELIGIBLE ||
        this.processorType !== ProcessingTypes.YourCause
      ) {
        // We found a matching org in NPP and it does not need vetted
        this.setConfirmExistingOrgNoVettingNeeded();
      } else {
        // We found a matching org in NPP, check if we need to create a vetting request
        const vettingStatus = await this.nonprofitService.getVettingStatus(searchResult.document.nonprofitGuid);
        // If allowCreateVettingRequest is false,
        // this means vetting has been declined,
        // so they should not be able to select this org
        if (!vettingStatus?.allowCreateVettingRequest) {
          return this.closeModal.emit({
            selectionType: null,
            searchResult,
            isInvalidOrg: true
          });
        } else {
          this.handleFoundAddedOrgThatNeedsVetting();
          this.setSelectionType(AddOrgUI.SelectionType.UNVETTED_ORG);
        }
      }
    }
    this.setCurrentPage(AddOrgUI.AddOrgModalPages.CONFIRMATION);
    this.setSearchResult(searchResult);

    return searchResult;
  }

  handleFoundAddedOrgThatNeedsVetting () {
    this.setShowContactForm(true);
    this.setModalHeaderToVettingRequired();
    this.setConfirmForVettingExistingOrg();
  }

  getGcOrgLookupPagination (regId: string): PaginationOptions<OrganizationSearchResponse> {
    return {
      retrieveTotalRecordCount: true,
      filterColumns: [],
      orFilterColumns: [
        {
          columnName: 'orgIdentification',
          filters: [
            {
              filterType: 'eq',
              filterValue: this.cleanUserRegIDInput(regId, false)
            },
            {
              filterType: 'eq',
              filterValue: this.cleanUserRegIDInput(regId, true)
            }
          ]
        }
      ],
      sortColumns: [],
      returnAll: false,
      pageNumber: 1,
      rowsPerPage: 15
    };
  }

  handleDetails () {
    this.setCurrentPage(AddOrgUI.AddOrgModalPages.ADDRESS);
    this.setHelpersBasedOnCurrentPage();
  }

  handleAddress () {
    this.setSearchResult(this.adaptAddNonprofitResult(this.addOrgPayload));
    this.setCurrentPage(AddOrgUI.AddOrgModalPages.CONFIRMATION);
    this.setHelpersBasedOnCurrentPage();
  }

  removeQueryParams (url: string) {
    return url.split('?')[0];
  }

  getUnionInfo (searchResult: OrgUnion) {
    if ('document' in searchResult) {
      return { id: searchResult.document.id, nonprofitGuid: searchResult.document.nonprofitGuid, country: searchResult.document.country };
    } else if ('clientId' in searchResult) {
      return { id: searchResult.id, nonprofitGuid: searchResult.nonprofitGuid, country: searchResult.country };
    } else if ('uniqueGuid' in searchResult) {
      return { id: searchResult.id, nonprofitGuid: searchResult.uniqueGuid, country: searchResult.location.countryCode };
    } else {
      return { id: searchResult.id, nonprofitGuid: searchResult.nonprofitGuid, country: searchResult.country };
    }
  }

  async handleVettingAfterUpdateProgram (
    organizationId: number,
    nonprofitContactName: string,
    nonprofitContactEmail: string,
    nonprofitContactWebsite: string,
    cycleId: number,
    applicationId: number
  ) {
    await this.nonprofitService.vetOrganization({
      organizationId,
      nonprofitContactName,
      nonprofitContactEmail,
      nonprofitContactWebsite,
      grantProgramCycleId: cycleId,
      isApplicant: false,
      applicationId,
      orgEligibleForGivingStatus: OrganizationEligibleForGivingStatus.INFO_REQUIRED,
      updateNonprofitClassificationModel: {
        classificationOneId: null,
        classificationTwoId: null,
        classificationThreeId: null
      }
    });
  }

  async handleOrgForApplication (
    cycleId: number,
    cycleClientOrgProcType: ProcessingTypes,
    organizationId: number,
    applicationId: number,
    organizationEligibleForGivingStatus: OrganizationEligibleForGivingStatus,
    latestVettingRequestStatusForOrg: VettingRequestStatusId,
    clientId: number,
    nonprofitContactName?: string,
    nonprofitContactEmail?: string,
    nonprofitContactWebsite?: string,
    isUpdateOrg = false
  ) {
    const vettingContactInfo = this.vettingContactInfo;
    nonprofitContactName = nonprofitContactName || vettingContactInfo?.contactFullName;
    nonprofitContactEmail = nonprofitContactEmail || vettingContactInfo?.contactEmail;
    nonprofitContactWebsite = nonprofitContactWebsite || vettingContactInfo?.contactWebsite;

    // first we check the processor and the type of org they have chosen
    const eligible = this.selectionType === AddOrgUI.SelectionType.VETTED_ORG ||
      organizationEligibleForGivingStatus === OrganizationEligibleForGivingStatus.ELIGIBLE;


    // if we already have a vetting request for the org, it shouldn't need vetted,
    // Unless we are updating the org then the existing vetting status doesn't matter
    let doesNotNeedVetting = !!latestVettingRequestStatusForOrg;
    if (isUpdateOrg) {
      doesNotNeedVetting = false;
    }

    if (
      !eligible &&
      cycleClientOrgProcType !== ProcessingTypes.Client &&
      !doesNotNeedVetting &&
      nonprofitContactName &&
      nonprofitContactEmail
    ) {
      if (organizationId) {
        if (applicationId) {
          await this.nonprofitService.vetOrganization({
            organizationId,
            nonprofitContactName,
            nonprofitContactEmail,
            nonprofitContactWebsite,
            grantProgramCycleId: cycleId,
            clientId,
            isApplicant: this.isApplicant,
            applicationId,
            orgEligibleForGivingStatus: organizationEligibleForGivingStatus,
            updateNonprofitClassificationModel: {
              classificationOneId: null,
              classificationTwoId: null,
              classificationThreeId: null
            }
          });
          this.set('vettingContactInfo', null);
        }
      }
    }

    return organizationId;
  }

  async createPrivateOrg (
    applicantId: number,
    clientId?: number
  ) {
    const nonprofitToAdd: AddOrgAPI.AddNonprofitModel = {
      applicantId,
      name: this.addOrgPayload.name,
      website: this.addOrgPayload.website,
      address: this.addOrgPayload.address,
      registrationId: this.addOrgPayload.id?.replace(/\s/g, ''), // removes extra spaces
      imageName: '',
      imageUrl: '',
      allowDonations: true,
      allowVolunteering: true,
      clientId
    };
    const addIconResponse = await this.nonprofitService.uploadIcon(nonprofitToAdd, this.addOrgPayload.image);
    nonprofitToAdd.imageName = addIconResponse.imageName;
    nonprofitToAdd.imageUrl = addIconResponse.imageUrl;
    const organizationId = await this.nonprofitService.createPrivateOrg(nonprofitToAdd);
    this.set('addOrgPayload', null);

    return organizationId;
  }

  async handleConfirmation () {
    const selectionType = this.selectionType;
    this.closeModal.emit({
      selectionType,
      searchResult: this.searchResult,
      isInvalidOrg: false
    });
  }

  handleBackButton (destination: AddOrgUI.AddOrgModalPages) {
    this.setCurrentPage(destination);
    this.setHelpersBasedOnCurrentPage();
  }

  registerModal (closeModal: EventEmitter<{
    selectionType: AddOrgUI.SelectionType;
    searchResult: AdaptedNppSearchResult;
    isInvalidOrg: boolean;
  }>) {
    this.closeModal = closeModal;
  }

  adaptClientOrgSearchResult (org: OrganizationSearchResponse): AdaptedNppSearchResult {
    const searchResult = SearchResult.construct<SearchResult>({
      text: org.name,
      document: {
        id: '' + org.id,
        addressString: this.addressFormatter.format({
          address1: org.address,
          address2: org.address2,
          city: org.city,
          stateProvRegCode: org.state,
          country: org.country,
          postalCode: org.postalCode
        }, true),
        iconURL: '',
        name: org.name,
        displayName: org.name,
        address1: org.address,
        address2: org.address2,
        city: org.city,
        postalCode: org.postalCode,
        country: org.country,
        stateProvRegCode: org.state,
        registrationId: org.orgIdentification
      }
    });

    return this.nonprofitService.adaptNonprofitSearchResult(searchResult);
  }

  adaptAddNonprofitResult (org: AddOrgUI.AddOrgPayload) {
    const searchResult = SearchResult.construct<SearchResult>({
      text: org.name,
      document: {
        id: null,
        addressString: this.addressFormatter.format({
          address1: org.address.address1,
          address2: org.address.address2,
          city: org.address.city,
          stateProvRegCode: org.address.stateProvRegCode,
          country: org.address.countryCode,
          postalCode: org.address.postalCode
        }, true),
        iconURL: org.imageUrl,
        name: org.name,
        website: org.website,
        displayName: org.name,
        address1: org.address.address1,
        address2: org.address.address2,
        city: org.address.city,
        postalCode: org.address.postalCode,
        country: org.address.countryCode,
        stateProvRegCode: org.address.stateProvRegCode,
        stateProvRegId: org.address.stateProvRegId,
        registrationId: '' + org.id,
        icon: org.image
      }
    });

    return this.nonprofitService.adaptNonprofitSearchResult(searchResult);
  }

  async openConfirmVettingModal (
    adaptedOrg: AdaptedNppSearchResult,
    clientId: number,
    processorType: ProcessingTypes,
    allowedOrgLocations: AllowedLocation[]
  ) {
    this.setSearchResult(adaptedOrg);
    this.setCurrentPage(AddOrgUI.AddOrgModalPages.CONFIRMATION);
    this.setConfirmForVettingExistingOrg();
    this.setModalHeaderToVettingRequired();
    this.setClientId(clientId);
    this.setProcessorType(processorType);
    this.setShowContactForm(true);
    this.setValidation(true);

    return this.openAddOrgModal(processorType, allowedOrgLocations);
  }

  async openAddOrgModal (
    processorType: ProcessingTypes,
    allowedOrgLocations: AllowedLocation[]
  ) {
    const response = await this.modalFactory.open(
      AddOrgModalComponent,
      {
        processorType,
        addingOrg: this.addingOrg,
        vettingOrg: this.vettingOrg,
        isManager: this.isGrantManager,
        allowedOrgLocations
      }
    );

    return response;
  }

}
