import { UserInfo } from '@core/typings/client-user.typing';
import { CommunicationVisibility } from '@features/communications/communications.typing';
import { DistributeOptions } from '@features/document-templates/document-template.typing';
import { EmailNotificationType, EmailOptionsModelForSave } from '@features/system-emails/email.typing';
import { YcFile } from '@yourcause/common/files';

export enum AttachmentType {
  EMAIL = 1,
  FORM = 2,
  EXTERNAL = 3,
  MERGE = 4,
  COMMUNICATION = 5
}

export type ApplicationAttachmentRecord = CommunicationFileInfo|EmailFileInfo|FormFileInfo|ExternalOrMergeFileInfo;

export interface CommunicationFileInfo {
  documentVisibility: CommunicationVisibility;
  attachmentId: number;
  fileInfo: CommFileInfo[];
  fileName: string;
  attachmentType: AttachmentType;
  uploadedDate: string;
  uploadedBy: UserInfo;
  fileUploadId: number;
  communicationId?: number;
}
export interface CommFileInfo {
  fileUploadId: number;
  fileName: string;
  uploadedDate: string;
  uploadedBy: UserInfo;
}

export interface EmailFileInfo {
  emailNotificationType: EmailNotificationType;
  emailSentDate: string;
  attachmentId: number;
  fileInfo: CommFileInfo[];
  attachmentType: AttachmentType;
  uploadedDate: string;
  uploadedBy: UserInfo;
}

export interface BaseFormFileInfo {
  fileName: string;
  uploadedDate: string;
  uploadedBy: UserInfo;
  fileUploadId: number;
}

export interface FormFileInfo extends BaseFormFileInfo {
  attachmentType: AttachmentType;
  formId: number;
  formName: string;
  fileInfo: CommFileInfo[];
  attachmentId: number;
  disableDownload: boolean;
  multiValueFileInfo: BaseFormFileInfo[];
}

export interface ExternalOrMergeFileInfo {
  documentVisibility: CommunicationVisibility;
  applicantCanView: boolean;
  canRemoveAttachment: boolean;
  attachmentId: number;
  fileInfo: CommFileInfo[];
  attachmentType: AttachmentType;
  uploadedDate: string;
  uploadedBy: UserInfo;
  documentTemplateId?: number;
  documentTemplateName?: string;
}

export interface ApplicationAttachmentFromApi {
  communicationFileInfo: CommunicationFileInfo[];
  emailFileInfo: EmailFileInfo[];
  formFileInfo: FormFileInfo[];
  externalFileInfo: ExternalOrMergeFileInfo[];
  mergeDocumentFileInfo: ExternalOrMergeFileInfo[];
}

export interface ApplicationAttachmentForUI {
  attachmentId: number;
  attachmentType: AttachmentType;
  emailNotificationType: EmailNotificationType;
  emailSentDate: string;
  formId: number;
  formName: string;
  documentVisibility: CommunicationVisibility;
  applicantCanView: boolean;
  uploadedDate: string;
  uploadedBy: UserInfo;
  canRemoveAttachment: boolean;
  statusText: string;
  statusTooltip: string;
  statusDate: string;
  disableDownload: boolean;
  fileInfo: CommFileInfo[];
}

export interface ExternalFileFormGroup  {
  documentVisibility: CommunicationVisibility;
  applicantCanView: boolean;
}

export interface ExternalFileModalResponse extends ExternalFileFormGroup {
  selectedFile: File;
  addAnother: boolean;
}

export interface MergeDocumentFormGroup  {
  documentTemplateId: number;
  documentVisibility: CommunicationVisibility;
  applicantCanView: boolean;
}

export interface MergeDocumenteModalResponse extends MergeDocumentFormGroup {
  addAnother: boolean;
}

export interface MailMergeModalResponse {
  documentTemplateId: number;
  distributeOptionId: DistributeOptions;
  documentVisibility: CommunicationVisibility;
  applicantCanView: boolean;
  emailOptions: EmailOptionsModelForSave;
  applicationIds: number[];
}

export interface CommunicationFileUpload {
  fileName: string;
  fileUploadId: number;
}

export interface OpenCommunicationFileParams {
  file: CommunicationFileUpload|YcFile;
  isForNonprofit: boolean;
  joinCommunicationId?: number;
  applicationId?: number;
}
