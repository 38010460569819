import { Component, Input, OnChanges } from '@angular/core';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { SelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { LogicBuilderService } from '../logic-builder.service';
import { GlobalLogicGroup, GlobalValueLogicGroup, LogicColumnDisplay, LogicValueFormatType } from '../logic-builder.typing';

@Component({
  selector: 'gc-logic-summary-sentence',
  templateUrl: './logic-summary-sentence.component.html',
  styleUrls: ['./logic-summary-sentence.component.scss']
})
export class LogicSummarySentenceComponent<T, V> implements OnChanges {
  @Input() logic: GlobalLogicGroup<T>|GlobalValueLogicGroup<T, V>;
  @Input() options: (TypeaheadSelectOption|SelectOption)[] = [];
  @Input() logicValueFormatType: LogicValueFormatType;
  @Input() componentType: string;
  @Input() availableColumns: LogicColumnDisplay<T>[];
  @Input() noLogicMessage = this.i18n.translate(
    'common:textAddNewRuleOrConditionAlert',
    {},
    'Click "Add new rule" to create a complex set of rules or "Add condition" to create a simple condition'
  );
  @Input() fallbackToNoLogicMessage = false;

  logicAsSentence: string;

  constructor (
    private logicBuilderService: LogicBuilderService,
    private i18n: I18nService
  ) { }

  ngOnChanges () {
    this.logicAsSentence = this.logicBuilderService.getLogicAsSentence(
      this.logic,
      this.availableColumns,
      this.options,
      this.logicValueFormatType,
      this.noLogicMessage,
      this.fallbackToNoLogicMessage
    );
  }
}
