import { BaseApplicationForLogic, Form, FormAudience } from '@features/configure-forms/form.typing';
import { LogicColumnDisplay } from '@features/logic-builder/logic-builder.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class FormLogicState extends BaseYcState {
  readonly reportFieldLogicColumns: LogicColumnDisplay<BaseApplicationForLogic>[];
  readonly formDetails: {
    [id: number]: Form;
  } = {};
  readonly pageOptionsMap: Record<string, TypeaheadSelectOption<string>[]> = {};
  readonly typeAudienceMap: Record<number, FormAudience> = {};
}
