import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class FormFieldTableAndSubsetState extends BaseYcState {
  readonly tableColumnsMap: Record<number, ReferenceFieldsUI.TableFieldForUi[]> = {};
  readonly dataPointsMap: Record<number, ReferenceFieldsUI.DataPointForUI[]> = {};

  /* This tracks the most current rows in a table on a particular application form so we can do change tracking on save */
  /* Left side of map is ApplicationFormId_TableId */
  readonly applicationFormTableRowsMap: Record<string, ReferenceFieldsUI.TableResponseRowForUi[]> = {};

  readonly tableFieldModalOpen: boolean;
}
