import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { GCDashboards } from '@features/dashboards/dashboards.typing';
import { ManageReportFormData } from '@features/reporting/pages/manage-ad-hoc-report-modal/manage-ad-hoc-report-modal.component';
import { RootObjectNames } from '@features/reporting/services/ad-hoc-reporting-definitions.service';
import { SelectOption, TypeSafeFormGroup, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { ArrayHelpersService } from '@yourcause/common/utils';

@Component({
  selector: 'gc-ad-hoc-form-selector',
  templateUrl: './ad-hoc-form-selector.component.html',
  styleUrls: ['./ad-hoc-form-selector.component.scss']
})
export class AdHocFormSelectorComponent implements OnChanges {
  @Input() object: RootObjectNames = 'application';
  @Input() formGroup: TypeSafeFormGroup<ManageReportFormData>|TypeSafeFormGroup<GCDashboards.WidgetConfig>;
  @Input() formsControlName = 'forms';
  @Input() primaryFormControlName = 'primaryFormId';
  @Input() primaryFormDisabled = false;
  @Input() gmFormsWithSingleResponseFields: number[];
  @Input() requireFormSelection = false;
  @Output() onFormsChange = new EventEmitter();

  allFormOptions: TypeaheadSelectOption<number>[] = [];
  singleResponseFormOptions: SelectOption<number>[] = [];

  constructor (
    private formService: FormsService,
    private arrayHelper: ArrayHelpersService
  ) { }

  ngOnChanges () {
    this.setFormOptions();
  }

  formChange () {
    this.setFormOptions();
    this.onFormsChange.emit();
  }

  setFormOptions () {
    let allFormOptions = this.formService.myFormOptions;
    const gmOptions = this.gmFormsWithSingleResponseFields.map<SelectOption>((formId) => {
      const form = this.formService.getFormFromId(formId);

      return {
        display: form.name,
        value: formId
      };
    });
    let singleResponseFormOptions = this.formService.myApplicantFormOptions
      .map<SelectOption>((form) => {
        return {
          display: form.label,
          value: form.value
        };
      }).concat(gmOptions);
    if (this.object === 'customForm') {
      const formVal = this.formGroup.value;
      const primaryFormId = formVal.primaryFormId;
      singleResponseFormOptions = singleResponseFormOptions.filter((form) => {
        return form.value !== primaryFormId;
      });
      const applicantForms = (formVal as any)[this.formsControlName];
      allFormOptions = allFormOptions.filter((form) => {
        return !applicantForms?.includes(form.value);
      });
    }
    this.allFormOptions = this.arrayHelper.sort(
      allFormOptions,
      'label'
    );
    this.singleResponseFormOptions = this.arrayHelper.sort(
      singleResponseFormOptions,
      'display'
    );
  }

}
