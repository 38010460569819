import { Injectable } from '@angular/core';
import { InsertTokenService } from '@core/services/insert-token.service';
import { AdHocReportingAPI } from '@core/typings/api/ad-hoc-reporting.typing';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ReferenceFieldsUI, STANDARD_FIELDS_CATEGORY_ID } from '@core/typings/ui/reference-fields.typing';
import { FormAudience } from '@features/configure-forms/form.typing';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { BucketComp } from '@features/forms/form-builder/form-builder.typing';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { DragAndDropItem, TopLevelFilter } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { PanelSection } from '@yourcause/common/panel';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { uniq } from 'lodash';
import { FormFieldCategoryResources } from '../resources/form-field-category.resources';
import { FormFieldCategoryState } from '../states/form-field-category.state';
import { FormFieldHelperService } from './form-field-helper.service';

@Injectable({ providedIn: 'root' })
@AttachYCState(FormFieldCategoryState)
export class FormFieldCategoryService extends BaseYCService<FormFieldCategoryState> {

  constructor (
    private formFieldCategoryResources: FormFieldCategoryResources,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private componentHelper: ComponentHelperService,
    private confirmAndTakeActionService: ConfirmAndTakeActionService,
    private formFieldHelperService: FormFieldHelperService,
    private insertTokenService: InsertTokenService,
    private customDataTableService: CustomDataTablesService
  ) {
    super();
  }

  get categories () {
    return this.get('categories');
  }

  get categoryNameMap () {
    return this.get('categoryNameMap');
  }

  get categoryOptions () {
    return this.get('categoryOptions');
  }

  get categoryOptionsWithOther () {
    return this.get('categoryOptionsWithOther');
  }

  get allReferenceFields () {
    return this.formFieldHelperService.allReferenceFields;
  }

  get referenceFieldMap () {
    return this.formFieldHelperService.referenceFieldMap;
  }

  /**
   * Fetches all categories and sets them on the state
   */
  async fetchAllCategories () {
    if (!this.categories) {
      const categories = await this.formFieldCategoryResources.getCategories();
      const otherText = this.i18n.translate('common:textOther', {}, 'Other');
      const map: Record<string, string> = {
        0: otherText
      };
      const categoryOptions: TypeaheadSelectOption[] = [];
      const categoryOptionsWithOther: TypeaheadSelectOption[] = [{
        label: otherText,
        value: 0
      }];
      categories.forEach((category) => {
        map[category.id] = category.name;
        categoryOptions.push({
          label: category.name,
          value: category.id
        });
        categoryOptionsWithOther.push({
          label: category.name,
          value: category.id
        });
      });
      this.set('categories', this.arrayHelper.sort(categories, 'name'));
      this.set('categoryNameMap', map);
      this.set('categoryOptions', this.arrayHelper.sort(categoryOptions, 'label'));
      this.set('categoryOptionsWithOther', this.arrayHelper.sort(categoryOptionsWithOther, 'label'));
    }
  }

  /**
   * Resets all Categories
   */
  async resetCategories () {
    this.set('categories', undefined);
    await this.fetchAllCategories();
  }

  /**
   * Bulk updates the category on a set of form fields
   *
   * @param fieldIds: Field IDs to update to new category
   * @param categoryId: Category ID
   */
  async bulkUpdateCategory (
    fieldIds: number[],
    categoryId: number
  ) {
    const response = await this.confirmAndTakeActionService.genericTakeAction(
      () => this.formFieldCategoryResources.bulkUpdateCategory(
        fieldIds,
        categoryId
      ),
      this.i18n.translate(
        'GLOBAL:textSuccessUpdateTheCategory',
        {},
        'Successfully updated the category'
      ),
      this.i18n.translate(
        'GLOBAL:textErrorUpdatingTheCategory',
        {},
        'There was an error updating the category'
      )
    );
    if (response.passed) {
      await this.resetCategories();
    }
  }

  /**
   * Handles Creating or Editing a Category
   *
   * @param categoryId: Category ID
   * @param categoryName: Category Name
   * @returns the ID of the category
   */
  async handleCreateOrEditCategory (
    categoryId: number,
    categoryName: string
  ): Promise<number> {
    const response = await this.confirmAndTakeActionService.genericTakeAction(
      () => this.formFieldCategoryResources.createOrEditCategory(categoryId, categoryName),
      this.i18n.translate(
        categoryId ?
          'GLOBAL:textSuccessUpdateTheCategory' :
          'GLOBAL:textSuccessAddingTheCategory',
        {},
        categoryId ?
          'Successfully updated the category' :
          'Successfully added the category'
      ),
      this.i18n.translate(
        categoryId ?
          'GLOBAL:textErrorUpdatingTheCategory' :
          'common:textErrorAddingNewCategory',
        {},
        categoryId ?
          'There was an error updating the category' :
          'There was an error adding the new category'
      )
    );
    if (response.passed) {
      const id = response.endpointResponse;
      await this.resetCategories();

      return id;
    }

    return null;
  }

  /**
   * Handles Deleting a Category
   *
   * @param categoryId: Category ID
   */
  async handleDeleteCategory (categoryId: number) {
    const response = await this.confirmAndTakeActionService.genericTakeAction(
      () => this.formFieldCategoryResources.deleteCategory(categoryId),
      this.i18n.translate(
        'GLOBAL:textSuccessDeleteCategory',
        {},
        'Successfully deleted the category'
      ),
      this.i18n.translate(
        'common:textErrorDeletingCategory',
        {},
        'There was an error deleting the category'
      )
    );
    if (response.passed) {
      await this.resetCategories();
    }
  }

  getFormFieldCategoryMapByUsage (usage: AdHocReportingUI.Usage) {
    const map: Record<string, ReferenceFieldAPI.ReferenceFieldDisplayModel[]> = {};
    this.allReferenceFields.filter((field) => {
      const isSingleResponse = usage === AdHocReportingUI.Usage.TOKENS ?
        field.isSingleResponse :
        true;

      return isSingleResponse &&
        !field.isMasked &&
        !field.isTableField &&
        field.type !== ReferenceFieldsUI.ReferenceFieldTypes.Table &&
        field.type !== ReferenceFieldsUI.ReferenceFieldTypes.Subset &&
        (
          field.formCount > 0 ||
          !!field.aggregateType
        );
    }).forEach((field) => {
      if (!map[field.categoryId]) {
        map[field.categoryId] = [{...field}];
      } else {
        map[field.categoryId] = [
          ...map[field.categoryId],
          field
        ];
      }
    });

    return map;
  }

  formFieldMapToDragAndDropItem (
    formFieldMap: Record<number, ReferenceFieldAPI.ReferenceFieldDisplayModel[]>,
    formFieldMapIsOpen: boolean,
    notDraggable = false
  ): PanelSection<never, BucketComp[]> {
    return {
      name: this.i18n.translate(
        'common:textFormFieldsByCategory',
        {},
        'Form fields (by category)'
      ),
      open: formFieldMapIsOpen,
      panels: this.arrayHelper.sort(Object.keys(formFieldMap).map((categoryId) => {
        const fields = formFieldMap[+categoryId];

        return {
          name: this.categoryNameMap[+categoryId],
          description: '',
          icon: '',
          iconClass: '',
          context: this.arrayHelper.sort(fields.map((field) => {
            const adapted = this.formFieldHelperService.getReferenceFieldBucketComp(
              field,
              this.categoryNameMap,
              this.customDataTableService.customDataTables,
              [],
              notDraggable
            );

            return {
              ...adapted,
              icon: '',
              key: `ReferenceField.${field.key}`,
              notDraggable,
              actions: [{
                icon: 'plus',
                tooltip: this.i18n.translate(
                  'common:textInsert',
                  {},
                  'Insert'
                ),
                onClick: (item: DragAndDropItem) => {
                  this.insertTokenService.setTokenToInsert(item.key);
                }
              }]
            };
          }), 'name')
        };
      }), 'name')
    };
  }

   /**
    *
    * @param formIds This could be formIds or a tableIds. Used for getting components off the componentMap.
    * @param componentMap This is used to building columns based on related components.
    * @param usage Ad hoc, dashboards, or tokens.
    * @param rootObject Object the report is based on.
    */
  getCategoryMapFromFormIds (
    formIds: number[],
    componentMap: Record<number, AdHocReportingUI.FormComponentSummary[]>,
    tableColumnsMap:  Record<number, ReferenceFieldsUI.TableFieldForUi[]>,
    usage = AdHocReportingUI.Usage.AD_HOC_BUILDER,
    rootObject?: AdHocReportingUI.RootObject<string>
  ): Record<string, ReferenceFieldAPI.ReferenceFieldAdHocModel[]> {
    let skipAggregates = usage === AdHocReportingUI.Usage.TOKENS;
    if (rootObject) {
      skipAggregates = skipAggregates || !!rootObject.noFormLogic;
    }
    const categoryMap: Record<
      string,
      ReferenceFieldAPI.ReferenceFieldAdHocModel[]
    > = {};
    if (!skipAggregates) {
      // TODO: remove once we allow manager forms on other reports
      // for now, we will show all aggregate fields on ad hoc reports
      const aggregateFields = this.allReferenceFields.filter(field => {
        return field.type === ReferenceFieldsUI.ReferenceFieldTypes.Aggregate;
      });

      aggregateFields.forEach(field => {
        this.addFieldToCategoryMap(field, categoryMap, null);
      });
    }

    const isCustomFormReport = rootObject?.property === 'formData';
    const isTable = rootObject.type === AdHocReportingAPI.AdHocReportModelType.Table;
    const allowMultipleResponseFields = isCustomFormReport || isTable;
    const excludedTypes = [
      ReferenceFieldsUI.ReferenceFieldTypes.Subset,
      ReferenceFieldsUI.ReferenceFieldTypes.Table
    ];
    if (usage === AdHocReportingUI.Usage.REPORT_FIELD) {
      excludedTypes.push(ReferenceFieldsUI.ReferenceFieldTypes.DataPoint);
      excludedTypes.push(ReferenceFieldsUI.ReferenceFieldTypes.FileUpload);
    }
    const standardDataPointsAlreadyAdded: string[] = [];
    formIds.forEach((recordId) => {
      return componentMap[recordId]?.map((comp) => {
        const key = comp.referenceFieldKey ?? this.componentHelper.getRefFieldKeyFromCompType(comp.type);
        let field = this.referenceFieldMap[key];
        if (field?.type === ReferenceFieldsUI.ReferenceFieldTypes.DataPoint) {
          field = {
            ...field,
            referenceFieldTableId: comp.referenceFieldTableId
          };
          if (field.isStandardProductField) {
            standardDataPointsAlreadyAdded.push(field.key);
          }
        }
        const allowStandardFields = (usage === AdHocReportingUI.Usage.REPORT_FIELD) ||
          standardDataPointsAlreadyAdded.includes(field?.key);
        const shouldAddField = this.shouldAddFieldToCategoryMap(
          field,
          allowStandardFields,
          allowMultipleResponseFields,
          rootObject,
          excludedTypes
        );

        if (shouldAddField) {
          this.handleAddToMap(field, categoryMap, recordId);
        }
      });
    });

    // Add standard fields to map
    if (rootObject.type !== AdHocReportingAPI.AdHocReportModelType.Budgets) {
      const tableFieldIds: number[] = [];
      if (isTable) {
        formIds.forEach((tableId) => {
          tableColumnsMap[tableId]?.forEach((_field) => {
            tableFieldIds.push(_field.referenceFieldId);
          });
        });
      }
      const shouldAddStandardFields = ![
        AdHocReportingUI.Usage.AD_HOC_QUICK_ADD,
        AdHocReportingUI.Usage.TOKENS,
        AdHocReportingUI.Usage.REPORT_FIELD
      ].includes(usage);
      if (shouldAddStandardFields) {
        this.addStandardProductFieldTokens(
          isTable,
          tableFieldIds,
          allowMultipleResponseFields,
          rootObject,
          categoryMap,
          excludedTypes,
          standardDataPointsAlreadyAdded
        );
      }
    }

    return categoryMap;
  }

  addStandardProductFieldTokens (
    isTable: boolean,
    tableFieldIds: number[],
    allowMultipleResponseFields: boolean,
    rootObject: AdHocReportingUI.RootObject<string>,
    categoryMap: Record<string, ReferenceFieldAPI.ReferenceFieldAdHocModel[]>,
    excludedTypes: ReferenceFieldsUI.ReferenceFieldTypes[],
    standardDataPointsAlreadyAdded: string[] = []
  ) {
    const standardFields = this.getStandardFields();
    standardFields.filter((field) => {
      if (isTable) {
        return tableFieldIds.includes(field.referenceFieldId);
      }

      return field.type !== ReferenceFieldsUI.ReferenceFieldTypes.Table &&
        field.type !== ReferenceFieldsUI.ReferenceFieldTypes.Subset &&
        !standardDataPointsAlreadyAdded.includes(field.key);
    }).forEach((field) => {
      const shouldAddField = this.shouldAddFieldToCategoryMap(
        field,
        true,
        allowMultipleResponseFields,
        rootObject,
        excludedTypes
      );
      if (shouldAddField) {
        this.handleAddToMap(field, categoryMap, null);
      }
    });
  }

  getStandardFields () {
    return this.allReferenceFields.filter((field) => {
      return field.isStandardProductField;
    });
  }

  shouldAddFieldToCategoryMap (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    allowStandardFields: boolean,
    allowMultipleResponseFields: boolean,
    rootObject: AdHocReportingUI.RootObject<string>,
    excludedTypes: ReferenceFieldsUI.ReferenceFieldTypes[]
  ) {
    const isSingleResponse = field?.formAudience === FormAudience.APPLICANT ||
      field?.isSingleResponse;
    const isTableReport = rootObject.type === AdHocReportingAPI.AdHocReportModelType.Table;
    const isStandardField = field?.isStandardProductField;
    const isTableTypeField = field?.isTableField;

    return field &&
      !excludedTypes.includes(field.type) &&
      (!isStandardField || allowStandardFields) &&
      (isSingleResponse || allowMultipleResponseFields) &&
      (!isTableTypeField || isTableReport);
  }

  handleAddToMap (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    categoryMap: Record<string,  ReferenceFieldAPI.ReferenceFieldAdHocModel[]>,
    recordId: number
  ) {
    this.addFieldToCategoryMap(field, categoryMap, recordId);
    // search for a related field that has this field as a parent
    const childField = (this.allReferenceFields || []).find((_field) => {
      return _field.parentReferenceFieldId === field.referenceFieldId;
    });
    // and if there is a child and it is an aggregate field
    if (childField?.aggregateType) {
      // add the it to the bucket(s)
      this.addFieldToCategoryMap(childField, categoryMap, recordId);
    }
  }

  addFieldToCategoryMap (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    categoryMap: Record<string, ReferenceFieldAPI.ReferenceFieldAdHocModel[]>,
    recordId: number
  ) {
    const categoryId = field.isStandardProductField ?
      STANDARD_FIELDS_CATEGORY_ID :
      field.categoryId;
    if (categoryMap[categoryId]) {
      const index = categoryMap[categoryId].findIndex((_field) => {
        const sameField = _field.key === field.key;
        const isDataPoint = _field.type === ReferenceFieldsUI.ReferenceFieldTypes.DataPoint;
        if (sameField && isDataPoint) {
          return _field.referenceFieldTableId === field.referenceFieldTableId;
        }

        return sameField;
      });
      if (index > -1) {
        const categoryItem = categoryMap[categoryId][index];
        // If exists, update with additional formId
        categoryMap[categoryId] = [
          ...categoryMap[categoryId].slice(0, index),
          {
            ...categoryItem,
            formIds: recordId ? uniq([
              ...categoryItem.formIds,
              recordId
            ]) : categoryItem.formIds
          },
          ...categoryMap[categoryId].slice(index + 1)
        ];
      } else {
        // else, add new field with formId
        categoryMap[categoryId] = [
          ...categoryMap[categoryId],
          {
            ...field,
            formIds: recordId ? [recordId] : []
          }
        ];
      }
    } else {
      categoryMap[categoryId] = [{
        ...field,
        formIds: recordId ? [recordId] : []
      }];
    }
  }

  getCategoryDropdownFilter () {
    return new TopLevelFilter(
      'checkboxDropdown',
      'categoryId',
      [],
      this.i18n.translate(
        'GLOBAL:textSearchByCategory',
        {},
        'Search by category'
      ),
      {
        selectOptions: this.arrayHelper.sort([
          {
            label: this.i18n.translate(
              'common:textOther',
              {},
              'Other'
            ),
            value: 0,
            filterTypeOverride: 'ib'
          },
          ...this.categoryOptions
        ], 'label'),
        filterObjectName: this.i18n.translate(
          'GLOBAL:textCategory', {}
        ).toLowerCase(),
        filterObjectNamePlural: this.i18n.translate(
          'GLOBAL:textCategories', {}
        ).toLowerCase()
      },
      undefined,
      undefined,
      true
    );
  }
}
