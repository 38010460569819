import { AdaptedNppSearchResult, OrgUnion } from '@core/typings/organization.typing';
import { ProcessingTypes } from '@core/typings/payment.typing';
import { AddOrgUI } from '@core/typings/ui/add-org.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class AddOrganizationState extends BaseYcState {
  readonly currentPage: AddOrgUI.AddOrgModalPages;
  readonly vettingContactInfo: AddOrgUI.VettingContactInfo;
  readonly addOrgPayload: AddOrgUI.AddOrgPayload;
  readonly processorType: ProcessingTypes;
  readonly originalSearchResponse: OrgUnion;
  readonly modalHeader: string;
  readonly confirmationText: string;
  readonly helpCenterConfirmationLink: string;
  readonly primaryDisabled: boolean = true;
  readonly clientId: number;
  readonly searchResult: AdaptedNppSearchResult;
  readonly selectionType: AddOrgUI.SelectionType;
  readonly showContact: boolean = false;
}
