import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { ExchangeRate } from '@yourcause/common/currency';

@Injectable({ providedIn: 'root' })
export class CurrencyResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getExchangeRates (
    baseCurrency: string
  ): Promise<ExchangeRate[]> {
    const endpoint = `api/client/exchangerates?baseCurrency=${baseCurrency}`;

    return this.httpRestService.get(endpoint);
  }
}

