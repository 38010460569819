import { FormAudience, FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { DragAndDropItem } from '@yourcause/common';

export interface BucketComp extends DragAndDropItem<string> {
  fieldAudience: FormAudience;
  isReferenceField: boolean;
  markAsRequired: boolean;
  categoryId: number;
}

export enum FormBuilderDropEvent {
  NewComponent,
  ReorderComponent,
  InvalidDrop
}

export enum FormBuilderActions {
  Cut,
  Copy,
  Paste_Below,
  Edit_Component,
  Remove,
  Paste_Above,
  Paste_Into_Container,
  Edit_Field
}

export interface FormBuilderActionEvent {
  component: FormDefinitionComponent;
  action: FormBuilderActions;
}

export interface ComponentToViewOrEdit {
  isEdit: boolean;
  compType: string;
  compKey: string;
}

export interface ComponentToSpellCheck {
  label: string;
  description: string;
  tooltipText: string;
  errorLabel: string;
  type: string;
}

export interface PartialSpellCheckCorrection {
  component: FormDefinitionComponent,
  type: string;
  componentTypeTranslated: string,
  pageName: string; 
}

export interface SpellCheckCorrection extends PartialSpellCheckCorrection {
  attribute: 'label'|'description'|'tooltipText'|'errorLabel';
  attributeTranslated: string;
  oldVal: string;
  newVal: string;
}

