<yc-modal *ngIf="formGroup"
  [modalHeader]="modalHeader"
  [modalSubHeader]="showCalculator ?
    ('BUDGET:textCalculateTotalAmount' | ycI18n: 'Calculate total amount') :
    ''"
  [primaryButtonText]="!showCalculator ?
    ('common:btnSave' | ycI18n) :
    ('common:btnNext' | ycI18n)"
  [primaryDisabled]="!showCalculator && formGroup.invalid"
  [secondaryButtonText]="!isDollars && !showCalculator ?
    ('BUDGET:textCalculateTotalAmount' | ycI18n: 'Calculate total amount') :
    ''"
  [cancelButtonText]="isDollars || !showCalculator ?
    ('common:btnCancel' | ycI18n) :
    ('common:btnBack' | ycI18n)"
  (onSecondaryClick)="!isDollars && !showCalculator ?
    showCalculator = true :
    null;"
  (onCancel)="isDollars || !showCalculator ?
    closeModal.emit() :
    showCalculator = false;"
  (onPrimaryClick)="showCalculator ?
    useCalculatedValue() :
    onSubmit()">

  <ng-container *ngIf="!showCalculator" 
    [formGroup]="formGroup">

    <!-- Name -->
    <div class="row mb-2">
      <div class="col-md-12">
        <yc-input
          name="fundingSourceName"
          [label]="'common:lblName' | ycI18n: 'Name'">
        </yc-input>
      </div>      
    </div>
  
      <!-- Type -->
      <div class="row mb-2">
        <div class="col-md-12">
          <yc-typeahead-select
            name="type"
            [label]="'common:hdrType' | ycI18n"
            [items]="typeOptions"
            [showClear]="false"
            [disabled]="!!fundingSource"
            [help]="fundingSource ?
              ('BUDGET:textFundingSourceTypeCannotBeChanged' | ycI18n: 'Funding source type cannot be changed') :
              ''">
          </yc-typeahead-select>
        </div>
      </div>

    <div class="row mb-2">
  
      <!-- Total -->
      <div class="col-md-12">
        <yc-input
          name="total"
          [isNumber]="true"
          [currencyData]="formattingData[defaultCurrency]"              
          [leftIcon]="leftIconCurrency"
          [help]="'BUDGET:textTotalFundsAvailableForSource' | ycI18n: 'Total funds available for this funding source'"
          [label]="'common:textTotalAmount' | ycI18n: 'Total amount'">
        </yc-input>
      </div>
    </div>

    <!-- Processor -->
    <div *ngIf="showProcessorSelect">
      <yc-typeahead-select
        name="processor"
        [label]="'BUDGET:textPaymentProcessor' | ycI18n: 'Payment processor'"
        [items]="processorOptions"
        [help]="'BUDGET:textPaymentProcessorHelp' | ycI18n: 'This indicates who will be responsible for disbursing the funds from this funding source'">
      </yc-typeahead-select>
    </div>
  
  </ng-container>

  <div [ycHidden]="!showCalculator">
    <gc-funding-source-calculator
      (onTotalChange)="onTotalCalculatorChange($event)">
    </gc-funding-source-calculator>
  </div>
</yc-modal>