import { Pipe, PipeTransform } from '@angular/core';
import { LogicCondition, LogicGroup } from '../logic-builder.typing';

@Pipe({
  name: 'gcIsLogicGroup',
  pure: true
})
export class IsLogicGroupPipe<T> implements PipeTransform {
  transform (groupOrCondition: LogicGroup<T>|LogicCondition<T, any>): groupOrCondition is LogicGroup<T> {
    return 'conditions' in groupOrCondition;
  }
}
