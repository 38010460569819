import { Injectable } from '@angular/core';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { ApplicantState } from '@core/states/applicant.state';
import { ApplicantAdminUser, ApplicantExistsResponse, ApplicantForApi, ApplicantFromSearch, CreateApplicantResponse, LinkSSOApplicantPayload, SearchApplicantsPayload, UpdateApplicant, UpdateProfile } from '@core/typings/applicant.typing';
import { ApplicantOrganizationForUi } from '@core/typings/organization.typing';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { UserService } from '@features/users/user.service';
import { HeapService, TrackingPropertyNames } from '@yourcause/common/heap';
import { PaginationOptions } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ApplicantResources } from '../../resources/applicant.resources';
import { MixpanelService } from '../mixpanel.service';
import { StatusService } from '../status.service';
import { TranslationService } from '../translation.service';
import { ArrayHelpersService } from '@yourcause/common/utils';

const applicantTempLanguageKey = '_applicantTempLangKey';

@AttachYCState(ApplicantState)
@Injectable({ providedIn: 'root' })
export class ApplicantService extends BaseYCService<ApplicantState> {

   constructor (
    private logger: LogService,
    private notifier: NotifierService,
    private determinationService: PortalDeterminationService,
    private i18nService: I18nService,
    private userService: UserService,
    private customDataTableService: CustomDataTablesService,
    private translationService: TranslationService,
    private applicantPortalResources: ApplicantResources,
    private mixpanel: MixpanelService,
    private statusService: StatusService,
    private arrayHelper: ArrayHelpersService,
    private heapService: HeapService
  ) {
    super();
    i18nService.language$.subscribe(async (culture) => {
      document.getElementsByTagName('html')[0].lang = this.i18nService.simpleLanguage || 'en';
      if (!this.determinationService.isManager) {
        const applicant = this.userService.applicant;
        if (applicant && applicant.culture !== culture) {
          await this.updateProfile({
            firstName: (applicant.firstName || '').trim(),
            lastName: (applicant.lastName || '').trim(),
            culture,
            address: applicant.address,
            address2: applicant.address2,
            city: applicant.city,
            state: applicant.state,
            postalCode: applicant.postalCode,
            country: applicant.country,
            phoneNumber: applicant.phoneNumber
          });
          await this.translationService.resetViewTranslations();
          this.customDataTableService.resetCustomDataTableOptionsMap();
          this.statusService.reset();
          this.userService.setApplicant({
            ...userService.applicant,
            culture
          });

          this.heapService.removeEventPropertyAndAssignNewValue(TrackingPropertyNames.Language, culture);
        }
      }
    });
  }

  get cultureToSaveAfterLogin () {
    return this.get('cultureToSaveAfterLogin');
  }

  get browserLocaleToSaveAfterLogin () {
    return this.get('browserLocaleToSaveAfterLogin');
  }

  setBrowserLocaleToSaveAfterLogin (lang: string) {
    this.set('browserLocaleToSaveAfterLogin', lang);
  }

  setCultureToSaveAfterLogin (lang: string) {
    this.set('cultureToSaveAfterLogin', lang);
  }

  async getApplicant (force: boolean) {
    if (!force && this.userService.get('applicant')) {
      return this.userService.get('applicant');
    }
    const applicant = await this.applicantPortalResources.getApplicant();
    this.userService.setApplicant(applicant);
    this.mixpanel.setProfile({
      Email: applicant.email,
      'First Name': applicant.firstName,
      'Last Name': applicant.lastName,
      'Portal Type': 'GrantPortal'
    });
    let langToSet = applicant?.culture;
    if (
      applicant &&
      applicant.culture &&
      applicant.culture !== this.i18nService.language
    ) {
      const applicantSelectedLang = this.cultureToSaveAfterLogin;
      const browserSelectedLang = this.browserLocaleToSaveAfterLogin;
      // Only override to the browser locale if the user is still on the default language (en-US)
      // If the applicant has a custom language, we should respect that and ignore the browser lang
      const shouldUpdateLanguage = !!applicantSelectedLang ||
        (
          !!browserSelectedLang && (applicant.culture === this.i18nService.DEFAULT_LANG)
        );
      if (shouldUpdateLanguage) {
        langToSet = applicantSelectedLang || browserSelectedLang;
        this.updateProfile({
          firstName: applicant.firstName,
          lastName: applicant.lastName,
          culture: langToSet,
          address: applicant.address,
          address2: applicant.address2,
          city: applicant.city,
          state: applicant.state,
          postalCode: applicant.postalCode,
          country: applicant.country,
          phoneNumber: applicant.phoneNumber
        });
        this.userService.setApplicant({
          ...applicant,
          culture: langToSet
        });
        this.setCultureToSaveAfterLogin(undefined);
        this.setBrowserLocaleToSaveAfterLogin(undefined);
      }
    }

    this.i18nService.setLanguage(langToSet);

    return applicant;
  }

  createApplicant (applicant: ApplicantForApi): Promise<CreateApplicantResponse> {
    return this.applicantPortalResources.createApplicant(applicant);
  }

  searchApplicants (payload: SearchApplicantsPayload): Promise<ApplicantFromSearch[]> {
    return this.applicantPortalResources.searchApplicants(payload);
  }

  async exactMatchSearchApplicants (email: string): Promise<ApplicantFromSearch> {
    const searchPayload = {
      exactMatch: true,
      email: true,
      searchText: email
    };

    const results = await this.searchApplicants(searchPayload);

    return results[0];
  }

  searchApplicantsFromPortal (email = ''): Promise<ApplicantFromSearch> {
    return this.applicantPortalResources.searchApplicantsFromPortal(email);
  }

  updateProfile (data: UpdateProfile) {
    return this.applicantPortalResources.updateProfile(data);
  }

  async getApplicantOrganizations (): Promise<ApplicantOrganizationForUi[]> {
    const response = await this.applicantPortalResources.getApplicantOrganizations();
    const adapted = response.map((org) => {
      return {
        ...org,
        eligibleForGivingStatusId: org.eligibleForGivingStatus,
        isPrivateOrg: !org.nonprofitGuid
      };
    });

    return this.arrayHelper.sort(adapted, 'name');
  }

  setApplicantTempLanguage (culture: string) {
    localStorage.setItem(applicantTempLanguageKey, culture);
  }

  getApplicantTempLanguage () {
    return localStorage.getItem(applicantTempLanguageKey);
  }

  updateApplicant (applicant: UpdateApplicant) {
    return this.applicantPortalResources.updateApplicant(applicant);
  }

  async linkHRData (linkHRDataPayload: LinkSSOApplicantPayload) {
     try {
      const response = await this.applicantPortalResources.linkHRData(linkHRDataPayload);
      this.notifier.success(this.i18nService.translate(
        'GLOBAL:textSuccessfullyLinkedHRData',
        {},
        'Successfully linked HR data'
      ));

       return response;
     } catch (e) {
        this.logger.error(e);
       this.notifier.error(
         this.i18nService.translate(
           'GLOBAL:textErrorLinkingHRData',
           {},
           'There was an error linking HR data'
         )
       );
     }
  }

  deactivateAccount () {
    return this.applicantPortalResources.deactivateAccount();
  }

  async doesApplicantExist (
    email: string,
    grantProgramGuid: string
  ): Promise<ApplicantExistsResponse> {

    const response = await this.applicantPortalResources.doesApplicantExist(
      email,
      grantProgramGuid
    );

    return response;
  }

  lookupEmployee (externalEmployeeId: string, applicationId: number) {
    return this.applicantPortalResources.lookupEmployee(externalEmployeeId, applicationId);
  }

  adminApplicantsPaginated (
    paginationOptions: PaginationOptions<ApplicantAdminUser>,
    clientIds: number[] = []
  ) {
    return this.applicantPortalResources.adminApplicantsPaginated(paginationOptions, clientIds);
  }

  adaptApplicantSearchPayload (searchText: string) {
    const emailRegEx = new RegExp(/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
    const firstLastNameRegEx = new RegExp(/([a-zA-Z])+ +([a-zA-Z])/);

    const email = emailRegEx.test(searchText);
    const exactMatch = email || firstLastNameRegEx.test(searchText);
    const payload = {
      email,
      exactMatch,
      searchText
    };

    return payload;
  }
}

