import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { FundingSourceTypes } from '@core/typings/budget.typing';
import { PaymentForProcess, ProcessingTypes } from '@core/typings/payment.typing';
import { AllowedLocation, ArchiveProgramPayload, CreateEditProgramApi, DisabledProgramEmail, GrantProgramCycleBudgetFundingSource, MasterResponse, PaymentStats, PaymentStatusStatApi, Program, ProgramCyclesPayload, ProgramDetailApi, ProgramDetailFromApi, ProgramExport, ProgramForDashboard, ProgramFormPayload, ProgramImport, ProgramStats, ProgramStatusStatApi, ProgramTopLevelStats, ProgramWorkflowLevelFormPayload, SimpleProgramCycle } from '@core/typings/program.typing';
import { InvitationApplicantHelpers } from '@features/invitations/invitation.typing';
import { PaginatedResponse, PaginationOptions, SimpleStringMap } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class ProgramResources {

  constructor (
    private httpRestService: HttpRestService,
    private portal: PortalDeterminationService
  ) { }

  getProgramCycleBudgetsAndFundingSources (grantProgramId: number): Promise<GrantProgramCycleBudgetFundingSource[]> {
    return this.httpRestService.get('/api/manager/programs/GetGrantProgramCyclesBudgetFundingSources/' + grantProgramId);
  }

  getProgramFromApi (grantProgramId: string|number): Promise<ProgramDetailFromApi> {
    return this.httpRestService.get(
      'api/manager/programs/' + grantProgramId
    );
  }

  getAllProgramsDraftAndPublished (): Promise<Program[]> {
    const endpoint = 'api/manager/programs/getall';

    return this.httpRestService.get(endpoint);
  }

  getProgramForApplicant (
    grantProgramGuid: string|number
  ): Promise<ProgramDetailApi> {
    const endpoint = 'api/portal/programs/' + grantProgramGuid + '/apply?includeForm=true';

    return this.httpRestService.get(endpoint);
  }

  getAllowedOrgLocations (programGuid: string|number): Promise<AllowedLocation[]> {
    const endpoint = this.portal.isApply ?
      `api/portal/programs/GetGrantProgramFilterLocationsAsync/${programGuid}` :
      `api/manager/programs/GetGrantProgramFilterLocationsAsync/${programGuid}`;

    return this.httpRestService.get(endpoint);
  }

  canApplicantApplyToProgram (
    applicantId: number,
    grantProgramGuid: string
  ): Promise<InvitationApplicantHelpers> {
    const endpoint = 'api/portal/programs/CanApplicantApplyToGrantProgram';

    return this.httpRestService.post(endpoint, {
      applicantId,
      grantProgramGuid
    });
  }

  saveProgram (
    adapted: CreateEditProgramApi
  ): Promise<number> {
    return this.httpRestService.post('api/manager/programs', adapted);
  }

  saveProgramCycles (payload: ProgramCyclesPayload): Promise<SimpleProgramCycle[]> {
    const endpoint = 'api/manager/programs/Cycles';

    return this.httpRestService.post(endpoint, payload);
  }

  saveProgramFormSettings (payload: ProgramFormPayload) {
    const endpoint = 'api/manager/programs/Forms';

    return this.httpRestService.post(endpoint, payload);
  }

  saveProgramWorkflowLevelForm (payload: ProgramWorkflowLevelFormPayload): Promise<number> {
    const endpoint = 'api/manager/programs/WorkflowLevelForms';

    return this.httpRestService.post(endpoint, payload);
  }

  saveProgramOrgLocations (
    programId: number,
    allowedOrgLocations: AllowedLocation[]
  ) {
    const endpoint = `api/manager/programs/UpdateGrantProgramOrgSearchLocations/${programId}`;

    return this.httpRestService.post(endpoint, {
      allowedOrgLocations
    });
  }

  deleteProgramWorkflowLevelForm (payload: ProgramWorkflowLevelFormPayload) {
    const endpoint = `api/manager/programs/${payload.grantProgramId}/WorkflowLevelForms/${payload.grantProgramWorkflowLevelForm.id}`;

    return this.httpRestService.delete(endpoint);
  }

  getProgramStats (grantProgramId: number, programCycleIds: number[]): Promise<ProgramStats> {
    const endpoint = `api/manager/applications/GetApplicationsAggregationStats`;
    const payload = {
      programCycleIds,
      grantProgramId
    };

    return this.httpRestService.post(endpoint, payload);
  }

  getPaymentStats (grantProgramId: number, programCycleIds: number[]): Promise<PaymentStats> {
    const payload = {
      programCycleIds,
      grantProgramId
    };
    const endpoint = `api/manager/applications/GetPaymentStatsForApprovedApplications`;

    return this.httpRestService.post(endpoint, payload);
  }

  getProgramsFromApi (endpoint: string) {
    return this.httpRestService.get(endpoint);
  }

  getActiveProgramsFromApi (endpoint: string) {
    return this.httpRestService.get(endpoint);
  }

  getProgramEmailSettings (id: number): Promise<{
    disabledEmails: DisabledProgramEmail[];
    senderDisplayName: string;
  }> {
    const endpoint = `api/manager/programs/GetProgramEmailSettings?grantProgramId=${id}`;

    return this.httpRestService.get(endpoint);
  }

  getMasterFormResponse (programId: number): Promise<MasterResponse[]> {
    const endpoint = `api/manager/programs/${programId}/MasterFormResponse`;

    return this.httpRestService.get(endpoint);
  }

  getProgramsForManager (active = false): Promise<Program[]> {
    const endpoint = `api/manager/applications/programs?active=${active}`;

    return this.httpRestService.get(endpoint);
  }

  getForms () {
    return this.httpRestService.get('api/manager/programs/forms');
  }

  getBudgets () {
    return this.httpRestService.get('api/manager/programs/budgets');
  }

  getWorkflows () {
    return this.httpRestService.get('api/manager/programs/workflows');
  }

  exportPrograms (programIds: number[]) {
    return this.httpRestService.post<ProgramExport>('/api/manager/programs/GetFullProgramExportData', {
      programIds,
      generateDownloadUrl: true
    });
  }

  importPrograms (programImport: ProgramImport) {
    return this.httpRestService.post(
      '/api/manager/programs/ImportFullProjectData',
      programImport
    );
  }

  activateProgram (programId: number) {
    return this.httpRestService.post(
      'api/manager/programs/' + programId + '/activate',
      {}
    );
  }

  archiveProgram (payload: ArchiveProgramPayload) {
    return this.httpRestService.post('api/manager/programs/ArchiveProgramAndApplications', payload);
  }

  getProgramInsightStats (
    programCycleIds: number[],
    grantProgramId?: number,
    isArchived?: boolean
  ): Promise<ProgramTopLevelStats> {
    const endpoint = 'api/manager/insights/program/stats';

    return this.httpRestService.post(endpoint, {
      programCycleIds,
      grantProgramId,
      isArchived
    });
  }

  getProgramStatusStats (
    grantProgramId: number,
    programCycleIds: number[]
  ): Promise<ProgramStatusStatApi[]> {
    const endpoint = 'api/manager/insights/program/statuses';

    return this.httpRestService.post(endpoint, {
      programCycleIds,
      grantProgramId
    });
  }

  getProgramsForDashboard (
    nonMaskedGrantPrograms = false
  ): Promise<ProgramForDashboard[]> {
    const endpoint = `api/manager/insights/programs?nonMaskedGrantPrograms=${nonMaskedGrantPrograms}`;

    return this.httpRestService.get(endpoint);
  }

  getPaymentsForProgram (
    paginationOptions: PaginationOptions<PaymentForProcess>,
    grantProgramId: number,
    programCycleIds: number[]
  ): Promise<PaginatedResponse<PaymentForProcess>> {
    const endpoint = 'api/manager/insights/program/payments/detail';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      grantProgramId,
      programCycleIds
    });
  }

  getPaymentStatusStatsByCycle (
    programCycleIds: number[]
  ): Promise<PaymentStatusStatApi[]> {
    const endpoint = 'api/manager/insights/program/payments/statuses';

    return this.httpRestService.post(endpoint, {
      programCycleIds
    });
  }

  getProgramWithForm (id: number): Promise<ProgramDetailApi> {
    const endpoint = `api/manager/programs/${id}/apply?includeForm=true`;

    return this.httpRestService.get(endpoint);
  }

  getArchivePaymentStatsForPrograms (programIds: number[]) {
    const endpoint = `api/manager/applications/GetPaymentStatsForPrograms`;

    return this.httpRestService.post(endpoint, {programIds});
  }

  deleteProgram (programId: number) {
    const endpoint = `api/manager/programs/deleteProgram/${programId}`;

    return this.httpRestService.post(endpoint, {});
  }

  getCycleBudgetsMap (): Promise<SimpleStringMap<number[]>> {
    const endpoint = 'api/manager/applications/GetProgramCycleBudgetIdsForCycles';

    return this.httpRestService.get(endpoint);
  }

  checkExistingDataForImport (programImport: ProgramImport) {
    const endpoint = 'api/manager/programs/CheckExistingDataForImport';

    return this.httpRestService.post(endpoint, programImport);
  }

  getPaymentProcessingPrograms (procType: ProcessingTypes, fSType: FundingSourceTypes): Promise<Program[]> {
    const endpoint = `api/manager/paymentbatch/programs?processingType=${procType}&fundingSourceType=${fSType}`;

    return this.httpRestService.get(endpoint);
  }
}

