<yc-modal
  [modalHeader]="'common:hdrAudienceUsage' | ycI18n: 'Audience Usage'"
  [modalSubHeader]="audienceName"
  [primaryButtonText]="'common:textClose' | ycI18n: 'Close'"
  (onPrimaryClick)="closeModal.emit()">

  <gc-audience-usage-table
    [audienceId]="audienceId"
    [audienceUsage]="audienceUsage">
  </gc-audience-usage-table>
</yc-modal>