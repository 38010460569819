import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { FormulaBuilderService } from '../formula-builder.service';
import { FormulaStep, RootFormula } from '../formula-builder.typing';

@Component({
  selector: 'gc-formula-builder',
  templateUrl: './formula-builder.component.html',
  styleUrls: ['./formula-builder.component.scss']
})
export class FormulaBuilderComponent<T> implements OnInit {
  @Input() columns: TypeaheadSelectOption<string>[];
  @Input() formula: RootFormula<T>;
  @Input() otherFormulas: RootFormula<T>[] = [];
  @Input() isViewOnly = false;

  @Output() formulaChange = new EventEmitter<RootFormula<T>>();
  @Output() validityChanged = new EventEmitter<boolean>();

  infiniteLoops: string[] = [];
  formulaString: string;

  constructor (
    private formulaBuilderService: FormulaBuilderService
  ) { }

  ngOnInit () {
    this.formulaString = this.formulaBuilderService.generateFormulaString<T>(this.formula, this.columns);
  }

  onFormulaStepChange (newStep: FormulaStep<T>) {
    this.formula.step = newStep;
    const infiniteLoops = this.formulaBuilderService.detectInfiniteLoops<T>(
      this.formula,
      this.otherFormulas
    ) as string[][];

    this.formulaString = this.formulaBuilderService.generateFormulaString<T>(this.formula, this.columns);

    this.infiniteLoops = infiniteLoops.map(loop => loop.join(' => '));

    this.formulaChange.emit({
      ...this.formula,
      step: newStep
    });
  }

  checkValidity (stepValid: boolean) {
    const valid = stepValid && (this.infiniteLoops.length === 0);

    this.validityChanged.emit(valid);
  }
}
