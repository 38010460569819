export default [
  {
    "id": 1,
    "name": "Awaiting Review"
  },
  {
    "id": 2,
    "name": "In Progress"
  },
  {
    "id": 3,
    "name": "Approved"
  },
  {
    "id": 4,
    "name": "Declined"
  },
  {
    "id": 5,
    "name": "Hold"
  },
  {
    "id": 6,
    "name": "Cancelled"
  },
  {
    "id": 7,
    "name": "Draft"
  }
]