<div *ngIf="!!workflowDetail"
  class="d-flex m-3 flex-column">
  <!-- Workflow Name -->
  <div class="flyout-header">
    {{ workflowDetail.name }}
  </div>
  <div>
    {{ workflowDetail.description }}
  </div>
  
  <!-- Actions -->
  <div class="d-flex mt-4 mb-4">
    <!-- Edit -->
    <yc-button
      class="me-2"
      [primary]="true"
      [langKey]="'WORKFLOW:btnEditWorkflow'"
      [defaultText]="'Edit workflow'"
      (onClick)="editWorkflow()">
    </yc-button>
  </div>

  <!-- Programs -->
  <yc-expandable-container
    [header]="'GLOBAL:textPrograms' | ycI18n: 'Programs'"
    [subHeader]="programsSubHeader"
    [hasData]="programs.length > 0">
    <yc-table
      [key]="'WORKFLOW_PROGRAMS_' + workflowId"
      [rows]="programs"
      [simple]="true"
      [rowsPerPage]="5">
      <ng-container *ycTableColumn="'common:lblName';
        let row;">
        {{ row.name }}
      </ng-container>
    </yc-table>
  </yc-expandable-container>

  <!-- Workflow Managers -->
  <yc-expandable-container
    [header]="'GLOBAL:textWorkflowManagers' | ycI18n: 'Workflow Managers'"
    [subHeader]="workflowManagersSubHeader"
    [hasData]="workflowManagers.length > 0">
    <yc-table
      [key]="workflowDetail.id + '_WORKFLOW_MANAGERS'"
      [rows]="workflowManagers"
      [simple]="true"
      [rowsPerPage]="5">
      <ng-container *ycTableColumn="'common:lblName';
        let row;">
        {{ row.fullName }}
      </ng-container>
    </yc-table>
  </yc-expandable-container>

  <!-- Workflow Users -->
  <yc-expandable-container
    [header]="'GLOBAL:hdrWorkflowUsers' | ycI18n: 'Workflow Users'"
    [subHeader]="workflowUsersSubHeader"
    [hasData]="workflowUsers.length > 0">
    <yc-table *ngIf="!!workflowUsers"
      [key]="workflowDetail.id + '_WORKFLOW_USERS'"
      [rowsPerPage]="5"
      [customNoRecordsMessage]="'common:textThereAreNoUsersAssignedToThisWorkflow' | ycI18n: 'There are no users assigned to this workflow'"
      [hideTableFilters]="true"
      [simple]="true"
      [drilldownStyles]="true"
      [rows]="workflowUsers">
      <ng-container *ycTableColumn="'common:lblName';
        let row;">
        {{ row.name }}
      </ng-container>

      <ng-container *ycTableColumn="'common:lblPermissions';
        let row;">
        {{ row.permissions | gcPermissionsDisplay }}
      </ng-container>

      <ng-container *ycTableColumn="'GLOBAL:hdrWorkflowLevel';
        let row;">
        {{ row.workflowLevelNames | ycJoinArray: ', ' }}
      </ng-container>
    </yc-table>

  </yc-expandable-container>
  
</div>

