<yc-modal *ngIf="ready"
  [formGroup]="formGroup"
  [modalHeader]="
    (isNew ? 'reporting:textCreateReport' : 'reporting:textManageReport')
    | ycI18n: (isNew ? 'Create Report' : 'Manage Report')"
  [modalSubHeader]="'common:hdrConfiguration' | ycI18n: 'Configuration'"
  [primaryButtonText]="
    (isNew ? 'reporting:textCreateReport' : 'common:btnSave')
    | ycI18n: (isNew ? 'Create Report' : 'Save')"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  [primaryDisabled]="formGroup?.invalid || !!invalidColumnHeadersAlert"
  (onPrimaryClick)="submit()"
  (onCancel)="cancel()"
  [helpLink]="createAndEditAdHocReportHelpLink">

  <yc-alert *ngIf="invalidColumnHeadersAlert"
    alertClass="danger"
    [message]="invalidColumnHeadersAlert">
  </yc-alert>

  <div class="column-header">
    <yc-i18n
      key="GLOBAL:textDetails"
      defaultValue="Details">
    </yc-i18n>  
  </div>

  <!-- Report Name -->
  <yc-input
    name="reportName"
    [label]="'reporting:lblReportName' | ycI18n:'Report name'">
  </yc-input>

  <!-- Description -->
  <div class="mb-2">
    <yc-textarea
      name="reportDescription"
      [label]="'reporting:lblReportDescription' | ycI18n:'Report description'">
    </yc-textarea>
  </div>

  <div class="column-header mt-4">
    <yc-i18n
      key="common:hdrAdHocReportType"
      defaultValue="Report Type">
    </yc-i18n>  
    <div class="report-type-help">
      <yc-i18n
        key="common:textSpecifyTheAreaToReportOn"
        defaultValue="Specify the area you want to report on. This cannot be changed once saved. The area selected determines the columns and sub-areas available in the report.">  
      </yc-i18n>
      <a [href]="'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-ad-hoc-reports-available-report-types.html'"
        target="_blank"
        class="ms-2">
        <yc-i18n
          key="common:textLearnMore"
          defaultValue="Learn more">
        </yc-i18n>
      </a>
    </div>
  </div>

  <!-- Object -->
  <yc-typeahead-select
    name="object"
    [disabled]="!isNew"
    [items]="objectOptions"
    [showClear]="false"
    [label]="'reporting:lblReportArea' | ycI18n:'Report area'"
    (onChange)="objectChanged($event)">
  </yc-typeahead-select>

  <!-- Start from Template -->
  <div *ngIf="isNew && startFromTemplateOptions.length > 0"
    class="mb-2 mt-2">
    <yc-typeahead-select
      name="startFromTemplate"
      [items]="startFromTemplateOptions"
      [popoverTemplate]="startFromTemplatePopover"
      [label]="'common:textStartFromTemplate2' | ycI18n: 'Select an existing report to use as a template'"
      (onChange)="startFromTemplateChanged($event)">
    </yc-typeahead-select>
  </div>

  <!-- Form Selector -->
  <ng-container *ngIf="formGroup.value.object | gcDoesReportSupportForms">
    <hr>

    <div class="column-header">
      <yc-i18n
        key="common:lblCustomForms"
        defaultValue="Custom Forms">
      </yc-i18n>  
      <div class="report-type-help">
        <yc-i18n *ngIf="formGroup.value.object !== 'customForm'"
          key="common:textSelectFormsYouWantToInclude"
          defaultValue="Select the forms you want to include in your report in addition to your report area. This is optional.">  
        </yc-i18n>
        <yc-i18n *ngIf="formGroup.value.object === 'customForm'"
          key="common:textSelectFormsYouWantToIncludeForCustomFormReport"
          defaultValue="Specify the primary form to pull its application data into the report. This can be your program's Default Form or any other forms attached to the application. You can also include additional forms. Only applications with submissions for these forms will appear in the report.">  
        </yc-i18n>
      </div>
    </div>

    <!-- Custom Form Alert -->
    <yc-alert *ngIf="formGroup.value.object === 'customForm'"
      [message]="customFormAlertMessage"
      [hrefLink]="'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-ad-hoc-reports-create-custom-form-reports.html'"
      [hrefLinkText]="'common:textLearnMore' | ycI18n: 'Learn more'"
      alertClass="info">
    </yc-alert>

    <!-- Form selector -->
    <gc-ad-hoc-form-selector
      [formGroup]="formGroup"
      [object]="formGroup.value.object"
      [requireFormSelection]="requireFormSelection"
      [primaryFormDisabled]="!isNew"
      [gmFormsWithSingleResponseFields]="gmFormsWithSingleResponseFields">
    </gc-ad-hoc-form-selector>
  </ng-container>

  <!-- Table selector -->
  <yc-typeahead-select *ngIf="formGroup.value.object === 'table'"
    name="referenceFieldTableKey"
    [disabled]="!isNew"
    [showClear]="isNew"
    [items]="referenceFieldTableOptions"
    [label]="'reporting:lblIWantToReportOnApplicationsWithSubmissionsToThisTable' | ycI18n:'I want to report on applications with submissions to this table'"
    (onChange)="objectChanged($event)">
  </yc-typeahead-select>
</yc-modal>


<ng-template #startFromTemplatePopover>
  <yc-i18n
    key="common:existingReportPopover"
    defaultValue="All columns and filters in this report will be automatically pulled into your new report.">
  </yc-i18n>
</ng-template>
