export namespace ContactSupport {
  export interface ContactSupportModalReturn {
    contactReason: ContactReasonFullList;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    clientName: string;
    applicationId?: number;
    programName: string;
    organizationName: string;
    taxId: string;
    description: string;
  }
  export enum ContactReasonFullList {
    CHANGE_PASSWORD = 0,
    UPDATE_EMAIL = 1,
    OTHER = 2,
    PROFILE_INFO = 3,
    FORMS = 4,
    DATA_MISSING = 5,
    APPLICATION_NOMINATION = 6,
    UNABLE_TO_FIND_ORG = 7
  }
}
