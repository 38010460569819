import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';
import { BulkOptions, TopLevelFilter } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory } from '@yourcause/common/modals';
import { Subscription } from 'rxjs';
import { AudienceUsersDisplayModalComponent } from '../audience-users-display-modal/audience-users-display-modal.component';
import { AudienceService } from '../audience.service';
import { Audience, Audience_Table_Key } from '../audience.typing';
import { UserService } from '@features/users/user.service';

@Component({
  selector: 'gc-audiences-page',
  templateUrl: './audiences-page.component.html',
  styleUrls: ['./audiences-page.component.scss']
})
export class AudiencesPageComponent implements OnInit, OnDestroy {
  @Input() isModalView = false;
  @Output() onModalToggle = new EventEmitter<boolean>();
  @Output() onSelectedAudiencesChange = new EventEmitter<Audience[]>();

  tableKey = Audience_Table_Key;
  audiences: Audience[] = [];
  topLevelFilters = [
    new TopLevelFilter(
      'text',
      'nameOrKey',
      '',
      this.i18n.translate(
        'common:textSearchByNameOrDescription',
        {},
        'Search by name or description'
      ),
      undefined,
      undefined,
      [{
        column: 'name',
        filterType: 'cn'
      }, {
        column: 'description',
        filterType: 'cn'
      }]
    )
  ];
  bulkOptions: BulkOptions<Audience> = {
    recordIdentifierAttribute: 'id',
    onlyForTrackingRows: true,
    retainBulkSelectedItems: true,
    bulkActions: [],
    addBulkFilterToOnlyShowSelected: true
  };
  selectedAudiences: Audience[];
  sub = new Subscription();
  ready = false;

  constructor (
    private i18n: I18nService,
    private audienceService: AudienceService,
    private modalFactory: ModalFactory,
    private spinnerService: SpinnerService,
    private userService: UserService
  ) {
    this.sub.add(this.audienceService.changesTo$('allAudiences').subscribe(() => {
      this.setAudiences();
    }));
  }


  async ngOnInit () {
    await this.audienceService.setAllAudiences();
    this.ready = true;
  }

  setAudiences () {
    this.audiences = this.audienceService.allAudiences ?? [];
  }

  async audienceModal (audience?: Audience) {
    this.onModalToggle.emit(true);
    await this.audienceService.handleAudienceModal(audience);
    this.userService.resetUserAudienceMap();
    this.onModalToggle.emit(false);
  }

  async deleteAudience (row: Audience) {
    this.onModalToggle.emit(true);
    await this.audienceService.handleDelete(row);
    this.onModalToggle.emit(false);
  }

  openFlyout (audience: Audience) {
    this.audienceService.openAudienceFlyout(audience);
  }

  async viewUsers (row: Audience) {
    this.spinnerService.startSpinner();
    await this.audienceService.setAudienceDetail(row.id);
    this.spinnerService.stopSpinner();
    this.modalFactory.setHideComponentDisplayModal(true);
    this.onModalToggle.emit(true);
    await this.modalFactory.open(
      AudienceUsersDisplayModalComponent,
      {
        members: this.audienceService.audienceDetailMap[row.id].members,
        modalSubHeader: row.name,
        tableKey: `AUDIENCE_MEMBERS_${row.id}`
      }
    );
    this.modalFactory.setHideComponentDisplayModal(false);
    this.onModalToggle.emit(false);
  }

  ngOnDestroy (): void {
    this.sub.unsubscribe();
  }
}
