import { Injectable } from '@angular/core';
import { StatusService } from '@core/services/status.service';
import { TranslationService } from '@core/services/translation.service';
import { CyclesAPI } from '@core/typings/api/cycles.typing';
import { ApplicationDetail, ApplicationFromPaginated, ApplicationFromPaginatedForUi, ApplicationManagerMap, ApplicationViewPage, AppManagerTypes, BulkApplicationDetail } from '@core/typings/application.typing';
import { ProgramTypes } from '@core/typings/program.typing';
import { ApplicationStatuses } from '@core/typings/status.typing';
import { ApplicantManagerService } from '@features/applicant/applicant-manager.service';
import { ApplicationManagerState } from '@features/application-manager/application-manager.state';
import { ApplicationManagerResources } from '@features/application-manager/resources/application-manager.resources';
import { ApplicationViewService } from '@features/application-view/application-view.service';
import { BudgetService } from '@features/budgets/budget.service';
import { FormData, FormDefinitionForUi } from '@features/configure-forms/form.typing';
import { EmployeeSSOFieldsService } from '@features/employee-sso-fields/employee-sso-fields.service';
import { FormFieldService } from '@features/form-fields/services/form-field.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { InKindService } from '@features/in-kind/in-kind.service';
import { SearchByDropdownValue } from '@features/my-workspace/my-workspace.typing';
import { NonprofitService } from '@features/nonprofit/nonprofit.service';
import { ProgramService } from '@features/programs/services/program.service';
import { SystemTagsService } from '@features/system-tags/system-tags.service';
import { WorkflowService } from '@features/workflow/workflow.service';
import { AddressFormatterService, APISortColumn, FilterColumn, FilterHelpersService, FilterModalTypes, PaginationOptions, TopLevelFilter } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ApplicationActionService } from '../application-actions/application-actions.service';
const DEFAULT_SORT_COLUMN = 'submittedDate';

export const DefaultSearchByDropdownValue: SearchByDropdownValue = {
  filterColumn: 'applicationId',
  filterValue: '',
  filterType: 'eq'
};

@AttachYCState(ApplicationManagerState)
@Injectable({ providedIn: 'root' })
export class ApplicationManagerService extends BaseYCService<ApplicationManagerState> {

   constructor (
    private applicationManagerResources: ApplicationManagerResources,
    private i18n: I18nService,
    private formFieldService: FormFieldService,
    private applicationActionService: ApplicationActionService,
    private employeeSSOFieldsService: EmployeeSSOFieldsService,
    private formHelperService: FormHelperService,
    private applicationViewService: ApplicationViewService,
    private filterHelperService: FilterHelpersService,
    private statusService: StatusService,
    private addressFormatter: AddressFormatterService,
    private systemTagsService: SystemTagsService,
    private nonprofitService: NonprofitService,
    private applicantManagerService: ApplicantManagerService,
    private workflowService: WorkflowService,
    private programService: ProgramService,
    private budgetService: BudgetService,
    private inKindService: InKindService,
    private translationService: TranslationService
  ) {
    super();
  }

  get hasMaskedAppsOnPage () {
    return this.get('hasMaskedAppsOnPage');
  }

  get applicationManagerMap () {
    return this.get('applicationManagerMap');
  }

  /**
   * Sets the hasMaskedAppsOnPage attr
   *
   * @param hasMaskedApps: does the current page have masked apps?
   */
  setHasMaskedAppsOnPage (hasMaskedApps: boolean) {
    this.set('hasMaskedAppsOnPage', hasMaskedApps);
  }

  /**
   * Sets the permissions on the map for a particular app
   *
   * @param app: the application
   */
  setPermissionsForApplicationManagerMap (app: ApplicationFromPaginated) {
    const id = app.applicationId;
    const currentMap = this.applicationManagerMap[id] || {} as ApplicationDetail;
    const applicationActionFlags = this.applicationActionService.getApplicationActionFlags(app);
    const map: ApplicationDetail = {
      ...applicationActionFlags,
      forms: currentMap.forms,
      awards: currentMap.awards,
      nominee: currentMap.nominee
    };
    this.updateApplicationManagerMap(id, map);
  }

  /**
   * Updates the application manager map with the detail for the given app ID
   *
   * @param id: app ID
   * @param detail: app detail
   */
  updateApplicationManagerMap (id: number, detail: ApplicationDetail) {
    this.set('applicationManagerMap', {
      ...this.applicationManagerMap,
      [id]: detail
    });
  }

  /**
   * Gets the applicationf or the view form modal
   *
   * @param appId: application ID
   * @param applicationFormId: application form ID
   * @param formDefinition: the form definition
   * @returns ApplicationViewPage model
   */
  async getApplicationForViewFormModal (
    appId: number,
    applicationFormId: number,
    formDefinition: FormDefinitionForUi[],
    formData: FormData
  ): Promise<ApplicationViewPage> {
    const app = await this.applicationViewService.getApplicationDetail(appId);

    return {
      ...app,
      referenceFields: await this.formFieldService.getReferenceFieldResponses(
        appId,
        applicationFormId,
        formDefinition,
        this.formHelperService.getTableAndSubsetIdsFromFormDefinition(
          [formDefinition]
        ),
        null,
        true,
        false,
        undefined,
        formData
      ),
      employeeInfo: await this.employeeSSOFieldsService.getEmployeeSSOFieldsForApp(appId)
    };
  }

  /**
   * Gets nominee info for a give app ID
   *
   * @param applicationId: app ID
   * @returns nominee info
   */
  getNomineeInfo (applicationId: number) {
    return this.applicationManagerResources.getNomineeInfo(applicationId);
  }

  /**
   * Sets attr on app manager map
   *
   * @param id: application ID
   * @param attr: attr to set
   * @param value: value to set
   */
  setApplicationManagerMap<K extends keyof ApplicationDetail> (
    id: keyof ApplicationManagerMap,
    attr: K,
    value: ApplicationDetail[K]
  ) {
    this.set('applicationManagerMap', {
      ...this.applicationManagerMap,
      [id]: {
        ...(this.applicationManagerMap[id] || ({} as ApplicationDetail)),
        [attr]: value
      }
    });
  }

  /**
   * Fetches the results for app manager table
   *
   * @param paginationOptions: pagination options
   * @param applicationId: app ID
   * @param organizationName: org name
   * @param applicantName: applicant name
   * @param applicantEmail: applicant email
   * @param applicantId: applicant ID
   * @param organizationIdentification: org identification
   * @param startDate: start date
   * @param endDate: end date
   * @param cycleStatusFilter: cycle status filter
   * @param programType: program type
   * @param formFilterModels: form filter models array
   * @returns
   */
  searchApplicationsPaginated (
    paginationOptions: PaginationOptions<ApplicationFromPaginated>,
    applicationId: number,
    organizationName: string,
    applicantName: string,
    applicantEmail: string,
    applicantId: number,
    organizationIdentification: string,
    startDate: string,
    endDate: string,
    cycleStatusFilter: CyclesAPI.CycleStatusFilterType,
    programType = ProgramTypes.GRANT,
    formFilterModels: FilterColumn<any>[] = []
  ) {
    return this.applicationManagerResources.searchApplicationsPaginated(
      paginationOptions,
      applicationId,
      organizationName,
      applicantName,
      applicantEmail,
      applicantId,
      organizationIdentification,
      startDate,
      endDate,
      cycleStatusFilter,
      programType,
      formFilterModels
    );
  }

  /**
   * Get payment stats for a set of app IDs
   *
   * @param applicationIds: app IDs
   * @param returnArchived: return archived?
   * @returns payment stats
   */
  getPaymentStats (
    applicationIds: number[],
    returnArchived: boolean
  ) {
    return this.applicationManagerResources.getPaymentStats({
      applicationIds,
      returnArchived
    });
  }

  /**
   * Gets info regarding updating an applications cycle
   *
   * @param applicationIds: app IDs
   * @param programId: program ID
   * @returns info regarding updating the cycle of this app
   */
  getUpdateCycleInfo (
    applicationIds: number[],
    programId: number
  ) {
    return this.applicationManagerResources.getUpdateCycleInfo({
      programId,
      applicationIds
    });
  }

  /**
   * Get's the bulk app details map
   *
   * @param applicationIds: app IDs
   * @returns the bulk app details map
   */
  async getBulkApplicationDetailsMap (
    applicationIds: number[]
  ) {
    const apps = await this.getBulkApplicationDetails(applicationIds);
    const map: Record<number, BulkApplicationDetail> = {};
    apps.forEach((app) => {
      map[app.applicationId] = app;
    });

    return map;
  }

  /**
   * Gets bulk app details for given app IDs
   *
   * @param applicationIds: app IDs
   * @returns bulk application details
   */
  async getBulkApplicationDetails (
    applicationIds: number[]
  ) {
    const details = await this.applicationManagerResources.getBulkApplicationDetails(applicationIds);

    return details.applicationBulkActionIndicators;
  }

  /**
   * Given app IDs, returns the ones with awards
   *
   * @param applicationIds: app IDs
   * @returns app IDs with awards
   */
  async getAllAppsWithAwards (applicationIds: number[]) {
    const apps = await this.getBulkApplicationDetails(
      applicationIds
    );
    const appsWithAwards = apps.filter((app) => {
      return app.hasAwards;
    }).map((app) => app.applicationId);

    return appsWithAwards;
  }

  /**
   * Gets the top level filters for app manager table
   *
   * @param currentAppStatusFilter: current app status filter on app manager table
   * @param isNomination: is nomination?
   * @param currentAppTypeFilter: current app type filter
   * @param searchByDropdownValue: current search by dropdown value
   * @param dateRangeValue: current date range vaue
   * @returns top level filteres for app manager table
   */
  getTopLevelFiltersForApplicationManager (
    currentAppStatusFilter: (string|number)[] = [],
    isNomination = false,
    currentAppTypeFilter = false,
    searchByDropdownValue = DefaultSearchByDropdownValue,
    dateRangeValue = FilterModalTypes.Last6Months
  ) {
    const {
      statusOptions,
      activeStatusOptions
    } = this.statusService.getAppFilterStatusSelects(isNomination);

    return [
      new TopLevelFilter(
        'searchByDropdown',
        'applicationId',
        searchByDropdownValue,
        undefined,
        {
          selectOptions: [{
            label: this.i18n.translate(
              'common:textId',
              {},
              'ID'
            ),
            value: 'applicationId',
            isNumber: true,
            placeholder: this.i18n.translate(
              isNomination ?
                'APPLY:textSearchAllNominationsById' :
                'APPLY:textSearchAllApplicationsbyId',
              {},
              isNomination ?
                'Search all nominations by ID' :
                'Search all applications by ID'
            )
          }, {
            label: this.i18n.translate(
              isNomination ?
                'GLOBAL:textNominatorName' :
                'common:textApplicantName',
              {},
              isNomination ?
                'Nominator name' :
                'Applicant name'
            ),
            value: 'applicantFullName',
            isNumber: false,
            placeholder: this.i18n.translate(
              isNomination ?
                'common:textSearchAllNominatorsByName' :
                'common:textSearchAllApplicantsByName',
              {},
              isNomination ?
                'Search all nominators by name' :
                'Search all applicants by name'
            )
          }, {
            label: this.i18n.translate(
              isNomination ?
                'GLOBAL:textNominatorEmail' :
                'common:textApplicantEmail',
              {},
              isNomination ?
                'Nominator email' :
                'Applicant email'
            ),
            value: 'applicantEmail',
            isNumber: false,
            placeholder: this.i18n.translate(
              isNomination ?
                'GLOBAL:textSearchAllNominatorsByEmail' :
                'common:textSearchAllApplicantsByEmail',
              {},
              isNomination ?
                'Search all nominators by email' :
                'Search all applicants by email'
            )
          }, {
            label: this.i18n.translate(
              'common:labelOrganizationName',
              {},
              'Organization name'
            ),
            value: 'organizationName',
            isNumber: false,
            placeholder: this.i18n.translate(
              'common:textSearchAllOrgsByName',
              {},
              'Search all organizations by name'
            )
          }, {
            label: this.i18n.translate(
              'nonprofit:textRegistrationID',
              {},
              'Registration ID'
            ),
            value: 'organizationIdentification',
            isNumber: false,
            placeholder: this.i18n.translate(
              'common:textSearchAllOrgsByRegId',
              {},
              'Search all organizations by registration ID'
            )
          }]
        }
      ),
      new TopLevelFilter(
        'typeaheadSingleEquals',
        'isArchived',
        currentAppTypeFilter,
        this.i18n.translate(
          'GLOBAL:textSearchByStatus',
          {},
          'Search by status'
        ),
        activeStatusOptions,
        this.i18n.translate(
          'GLOBAL:textSearchByStatus',
          {},
          'Search by status'
        )
      ),
      new TopLevelFilter(
        'checkboxDropdown',
        'applicationStatus',
        currentAppStatusFilter,
        this.i18n.translate(
          'GLOBAL:textSearchByStatus',
          {},
          'Search by status'
        ),
        statusOptions
      ),
      this.getRelativeDateFilterForApplications(dateRangeValue)
    ];
  }

  /**
   * Gets the relative date top level filter for app manager table
   *
   * @param dateRangeValue: the current date range value
   * @returns the relative date filter for app manager table
   */
  getRelativeDateFilterForApplications (
    dateRangeValue = FilterModalTypes.Last6Months
  ) {
    return new TopLevelFilter(
      'relativeDate',
      'updatedDate',
      dateRangeValue,
      '',
      this.filterHelperService.getRelativeDateFilterConfig(),
      this.i18n.translate(
        'common:textFilterByDate',
        {},
        'Filter by date'
      ),
      undefined,
      true,
      false,
      (filters: TopLevelFilter[]) => {
        const searchByDropdownFilter = filters.find((filter) => {
          return filter.filterType === 'searchByDropdown';
        });
        /* Disable date filter when search by dropdown filter has a value */
        const disabled = !!searchByDropdownFilter?.value?.filterValue;

        return {
          disabled,
          disabledDisplayValue: disabled ?
            this.i18n.translate(
              'common:lblAllCap',
              {},
              'All'
            ) :
            ''
        };
      }
    );
  }

  /**
   * Action to trigger app manager table to reset
   */
  triggerResetAppManagerTable () {
    this.triggerChange('triggerResetAppManagerTable');
  }

  /**
   * Returns whether app manager options are visible
   *
   * @param map: app manager map
   * @param row: application row
   * @param canEditApp: can they edit the app?
   * @returns if app manager options are visible
   */
  areAppManagerOptionsVisible (
    map: ApplicationManagerMap,
    row: ApplicationFromPaginated,
    canEditApp = false
  ) {
    const obj = map[row.applicationId];
    if (
      obj.canApprove ||
      obj.canDecline ||
      obj.canUpdateStatus ||
      obj.canNotifyOfStatus ||
      obj.canViewComms ||
      obj.canManageCollabs ||
      obj.canRoute ||
      obj.canDelete ||
      (!row.isArchived && obj.canArchiveUnarchive) ||
      (canEditApp && row.applicationStatus !== ApplicationStatuses.Declined) ||
      ((row.isArchived && !row.isProgramArchived) && obj.canArchiveUnarchive)
    ) {
      return true;
    }

    return false;
  }

  /**
   * Adapts the pagination options for app manager table
   *
   * @param type: app manager table type
   * @param options: pagination options
   * @param cycleIds: cycle IDs
   * @param nonprofitId: nonprofit ID
   * @returns the pagination options
   */
  getPaginationOptions (
    type: AppManagerTypes,
    options: PaginationOptions<ApplicationFromPaginated>,
    cycleIds: number[],
    nonprofitId: number
  ) {
    if (type === AppManagerTypes.ProgramDashboard) {
      options = this.getProgramManagerOptions(options, cycleIds);
    } else if (type === AppManagerTypes.NonprofitProfile) {
      options = this.getNonprofitProfileOptions(options, nonprofitId);
    } else if (type === AppManagerTypes.ApplicantProfile) {
      options = this.getApplicantProfileOptions(options);
    }

    return this.systemTagsService.formatPaginationOptions(
      options
    );
  }

  /**
   * Adapts the pagination options for app manager table to pull out endpoint filters
   *
   * @param paginationOptions: pagination options
   * @returns the adpated pagination options after endpoint filters are applied
   */
  adaptPaginationOptionForEndpointFilters (
    paginationOptions: PaginationOptions<ApplicationFromPaginated>
  ) {
    let applicationId;
    let applicantName;
    let organizationName;
    let applicantEmail;
    let organizationIdentification;
    let startDate;
    let endDate;
    const formFilterModels: FilterColumn<any>[] = [];

    paginationOptions = {
      ...paginationOptions,
      sortColumns: this.adaptDefaultSort(paginationOptions.sortColumns),
      filterColumns: paginationOptions.filterColumns.filter((filter) => {
        if (filter) {
          let keepColumn = false;
          const filterVal = filter.filters[0].filterValue;
          switch (filter.columnName) {
            case 'applicationId':
              applicationId = filterVal as number;
              break;
            case 'applicantFullName':
              applicantName = filterVal as string;
              break;
            case 'applicantEmail':
              applicantEmail = filterVal as string;
              break;
            case 'organizationName':
              organizationName = filterVal as string;
              break;
            case 'organizationIdentification':
              // Org Identification is stored without the "-",
              // so remove if search term has it
              organizationIdentification = (filterVal as string)?.replace('-', '');
              break;
            case 'updatedDate':
              const filterType = filter.filters[0].filterType as FilterModalTypes;
              const dates = this.filterHelperService.getClientSideRelativeDates(
                filterType
              );
              if (dates) {
                startDate = dates[0];
                endDate = dates[1];
              }
              break;
            default:
              keepColumn = true;
              break;
          }

          const isSso = filter.columnName.includes('sso');
          const isForm = filter.columnName.includes('formResponse');
          if (isSso || isForm) {
            formFilterModels.push(filter);
            keepColumn = false;
          }

          return keepColumn;
        }

        return false;
      })
    };

    return {
      paginationOptions,
      applicationId,
      organizationName,
      applicantName,
      applicantEmail,
      organizationIdentification,
      startDate,
      endDate,
      formFilterModels
    };
  }

  /**
   * Gets the program manager options for app manager table when in program dash
   *
   * @param options: pagination options
   * @param cycleIds: cycle IDs
   * @returns the program manager option filters
   */
  getProgramManagerOptions (
    options: PaginationOptions<ApplicationFromPaginated>,
    cycleIds: number[]
  ) {
    const cycleFilter = {
      columnName: 'grantProgramCycle.id',
      filters: (cycleIds || []).map((cycleId) => {
        return {
          filterType: 'eq',
          filterValue: cycleId
        };
      })
    };
    options = {
      ...options,
      filterColumns: [
        ...options.filterColumns,
        cycleFilter
      ]
    };

    return options;
  }

  /**
   * Adapt the records for app manager table
   *
   * @param rows: rows to adapt
   * @param type: app manager table type
   * @param applicationId: app ID
   * @param setMaskedIconAndTooltip: function to set masked icon and tooltip
   * @returns the adapted records
   */
  setupRecords (
    rows: ApplicationFromPaginated[],
    type: AppManagerTypes,
    applicationId: number,
    setMaskedIconAndTooltip: () => void
  ): ApplicationFromPaginatedForUi[] {
    let hasMaskedApps = false;
    const translations = this.translationService.viewTranslations;
    const programTranslations = translations.Grant_Program;
    const cycleTranslations = translations.Grant_Program_Cycle;
    const statusMapApp = this.statusService.applicationStatusMap;
    const records = rows.map((row) => {
      if (row.organizationAddressInfo) {
        row.organizationAddress = this.addressFormatter.formatSimpleGrantsAddressToSingleLine(
          row.organizationAddressInfo
        );
      }
      this.setPermissionsForApplicationManagerMap(row);
      this.setNonprofitAndApplicantRouterLinkMap(row, applicationId);
      if (row.isMasked && row.canViewMaskedApplicantInfo) {
        hasMaskedApps = true;
      }
      const actionBools = this.applicationActionService.getApplicationActionFlags(row);

      return {
        ...row,
        cycleName: cycleTranslations[row.grantProgramCycle.id]?.Name || row.grantProgramCycle.name,
        cylceId: row.grantProgramCycle.id,
        programName: programTranslations[row.programId]?.Name || row.programName,
        status: {
          id: row.applicationStatus,
          translated: statusMapApp[row.applicationStatus]?.translated
        },
        applicantAndOrgInfo: {
          applicantFullName: row.applicantFullName,
          organizationName: row.organizationName
        },
        permissions: this.applicationActionService.getPermissionsForApp(row.workflowId),
        ...actionBools
      };
    });
    if (type !== AppManagerTypes.ApplicantProfile) {
      this.setHasMaskedAppsOnPage(hasMaskedApps);
      if (this.hasMaskedAppsOnPage) {
        setMaskedIconAndTooltip();
      }
    }

    return records;
  }

  /**
   * Gets the filters for nonprofit profile app manager table
   *
   * @param options: pagination options
   * @param nonprofitId: nonprofit ID
   * @returns the nonprofit profile filters
   */
  getNonprofitProfileOptions (
    options: PaginationOptions<ApplicationFromPaginated>,
    nonprofitId: number
  ) {
    const isGuid = isNaN(nonprofitId);
    const columnName = isGuid ?
      'nonprofitGuid' :
      'organizationId';
    options.filterColumns = [
      ...options.filterColumns,
      {
        columnName,
        filters: [{
          filterType: 'eq',
          filterValue: nonprofitId
        }]
      }
    ];

    return options;
  }

  /**
   * Gets the applicant filters for app manager table
   *
   * @param options: pagination options
   * @returns the applicant filters
   */
  getApplicantProfileOptions (options: PaginationOptions<ApplicationFromPaginated>) {
    options.filterColumns = [
      ...options.filterColumns,
      {
        columnName: 'canViewMaskedApplicantInfo',
        filters: [{
          filterType: 'eq',
          filterValue: true
        }]
      }
    ];

    return options;
  }

  /**
   * Adapts default sort on app manager table
   *
   * @param sortColumns: sort columns
   * @returns the adapted sort columns
   */
  adaptDefaultSort (sortColumns: APISortColumn<ApplicationFromPaginated>[]) {
    // Since submittedDate (default sort) is not always populated,
    // we need a fallback for Draft applications
    if (
      sortColumns.length === 1 &&
      sortColumns[0].columnName === DEFAULT_SORT_COLUMN
    ) {
      return [
        ...sortColumns,
        {
          columnName: 'updatedDate',
          sortAscending: false
        }
      ];
    }

    return [...sortColumns];
  }

  /**
   * Sets the nonprofit and applicant router link map for navigation
   *
   * @param row: row to set router links
   * @param applicationId: app ID
   */
  setNonprofitAndApplicantRouterLinkMap (
    row: ApplicationFromPaginated,
    applicationId: number
  ) {
    this.applicantManagerService.setApplicantRouterLinkMap(
      row.applicantId,
      applicationId
    );
    this.nonprofitService.setNonprofitRouterLinkMap(
      (row.nonprofitGuid as any) || row.organizationId,
      applicationId
    );
  }

  async resolve () {
    await Promise.all([
      this.formFieldService.resolve(),
      this.inKindService.resolveInKind(),
      this.programService.setCycleBudgetsMap(),
      this.budgetService.setBudgets(),
      this.programService.getAllActiveManagerPrograms(),
      this.workflowService.setMyWorkflowManagerRolesMap(),
      this.workflowService.getWorkflows()
    ]);
  }
}

