import { Injectable } from '@angular/core';
export const acceptedFileTypesLink = 'https://webfiles-sc1.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-guide-file-requirements.html';
export const privacyPolicyLink = 'https://solutions.yourcause.com/wp-content/uploads/2021/08/GCPrivacyPolicyFeb21.pdf';
export const termsOfServiceLink = 'https://solutions.yourcause.com/wp-content/uploads/2021/08/GC-Terms-of-Service22021.pdf';

@Injectable({ providedIn: 'root' })
export class LinkService {

  getAcceptedFileTypesLink () {
    return acceptedFileTypesLink;
  }

  getPrivacyPolicyLink () {
    return privacyPolicyLink;
  }

  getTermsOfServiceLink () {
    return termsOfServiceLink;
  }
}
