<div *ngIf="formGroup"
  [formGroup]="formGroup">
  <div class="mb-3">
    <yc-input 
      name="parentPicklistName"
      [label]="'common:textParentPicklist' | ycI18n: 'Parent picklist'"
      [disabled]="true">
    </yc-input>
  </div>
  <div>
    <yc-checkbox
      [optionLabel]="'FORMS:labelHideUntilParentHasResponse' | ycI18n: 'Hide until parent picklist has a response'"
      [disabled]="isViewOnly"
      name="hideWithoutParentVal"
      (onChange)="onChange.emit($event)">
    </yc-checkbox>
  </div>
</div>