import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { ALL_SKIP_FILTER, TopLevelFilter } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { isEqual, uniq } from 'lodash';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';

export interface TableColumnsConfigChange {
  hiddenTableColumnKeys: string[];
  labelOverrideMap: Record<string, string>;
  requiredOverrideKeys: string[];
}
interface TableColumnForForm {
  label: string;
  key: string;
  isVisible: boolean;
  isRequired: boolean;
  canTurnOffRequiredAndVisible: boolean;
  originalLabel: string;
}

@Component({
  selector: 'gc-table-component-visible-columns',
  templateUrl: './table-component-visible-columns.component.html',
  styleUrls: ['./table-component-visible-columns.component.scss']
})
export class TableComponentVisibleColumnsComponent extends BaseComponentConfigSettingsComponent implements OnInit {
  @Input() compType: string;
  @Input() hiddenTableColumnKeys: string[];
  @Input() labelOverrideMap: Record<string, string> = {};
  @Input() requiredOverrideKeys: string[];
  @Output() isValidChange = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<TableColumnsConfigChange>();

  tableColumns: ReferenceFieldsUI.TableFieldForUi[];
  originalTableColumnKeys: string[];
  rows: TableColumnForForm[] = [];
  topLevelFilters: TopLevelFilter[] = [
    new TopLevelFilter(
      'text',
      '',
      '',
      this.i18n.translate(
        'common:textSearchByLabel',
        {},
        'Search by label'
      ),
      undefined,
      '',
      [{
        column: 'label',
        filterType: 'cn'
      }, {
        column: 'originalLabel',
        filterType: 'cn'
      }]
    ),
    new TopLevelFilter(
      'typeaheadSingleEquals',
      'isVisible',
      ALL_SKIP_FILTER,
      '',
      {
        selectOptions: [{
          label: this.i18n.translate(
            'common:textAllAvailableColumns',
            {},
            'All available columns'
          ),
          value: ALL_SKIP_FILTER
        }, {
          label: this.i18n.translate(
            'common:textVisibleColumns',
            {},
            'Visible columns'
          ),
          value: true
        }, {
          label: this.i18n.translate(
            'common:textHiddenColumns',
            {},
            'Hidden columns'
          ),
          value: false
        }]
      },
      this.i18n.translate('common:textVisibility', {}, 'Visibility')
    )
  ];
  description = this.i18n.translate(
    'common:textSelectWhichColumnsWillBeAvailableForThisTable4',
    {},
    `Select the fields to make available when completing the form. Deselecting a field removes it from the table view and "Add new" modal. Required fields cannot be unchecked. To hide columns in the table view only, please update the field's configuration instead.`
  );
  hasAtLeastOneColumn = true;
  ready = false;

  constructor (
    private formFieldHelperService: FormFieldHelperService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService
  ) {
    super();
  }

  async ngOnInit () {
    const field = this.formFieldHelperService.getReferenceFieldFromCompType(this.compType);
    await this.formFieldTableAndSubsetService.setColumnsForTable(field.referenceFieldId);
    this.tableColumns = this.formFieldTableAndSubsetService.getColumnsForTable(
      field.referenceFieldId,
      [],
      {}
    );
    this.originalTableColumnKeys = uniq(this.arrayHelper.sort(
      this.tableColumns.map((col) => col.referenceField.key)
    ));
    this.handleInitialTableColumnSettings();
    this.setRows();
    this.emitValidity();
    this.ready = true;
  }

  handleInitialTableColumnSettings () {
    let hiddenTableColumnKeys: string[] = [];
    // If they haven't been set yet, emit the defaults
    let shouldEmit = !this.hiddenTableColumnKeys || !this.labelOverrideMap || !this.requiredOverrideKeys;
    if (!!this.hiddenTableColumnKeys) {
      hiddenTableColumnKeys = [...this.hiddenTableColumnKeys];
      // check if the a hidden column is required,
      // which is not allowed and means we need to update the hiddenTableColumnKeys value
      const requiredColumnKeys = this.tableColumns.filter((col) => {
        return col.isRequired;
      }).map((col) => col.referenceField.key);
      hiddenTableColumnKeys = hiddenTableColumnKeys.filter((key) => {
        if (requiredColumnKeys.includes(key)) {
          shouldEmit = true;

          return false;
        }

        return true;
      });
    }
    if (shouldEmit) {
      this.hiddenTableColumnKeys = hiddenTableColumnKeys;
      this.labelOverrideMap = this.labelOverrideMap || {};
      this.requiredOverrideKeys = this.requiredOverrideKeys || [];
      this.emitChanges();
    }
  }

  setRows () {
    this.rows = this.tableColumns.map((column) => {
      const isRequired = column.isRequired || this.requiredOverrideKeys.includes(column.referenceField.key);
      const isVisible = !this.hiddenTableColumnKeys.includes(column.referenceField.key);

      return {
        key: column.referenceField.key,
        label: this.labelOverrideMap[column.referenceField.key] || column.label,
        isRequired,
        isVisible,
        canTurnOffRequiredAndVisible: !column.isRequired,
        originalLabel: column.label
      };
    });
  }

  emitChanges () {
    this.onChange.emit({
      hiddenTableColumnKeys: this.hiddenTableColumnKeys,
      labelOverrideMap: this.labelOverrideMap,
      requiredOverrideKeys: this.requiredOverrideKeys
    });
    this.emitValidity();
  }

  emitValidity () {
    let labelsValid = true;
    Object.keys(this.labelOverrideMap).forEach((key) => {
      const isHidden = this.hiddenTableColumnKeys.some((_key) => _key === key);
      if (!this.labelOverrideMap[key] && !isHidden) {
        labelsValid = false;
      }
    });

    const isValid = labelsValid && this.hasAtLeastOneColumn;
    this.isValidChange.emit(isValid);
  }

  visibleChange (index: number) {
    const row = this.rows[index];
    this.rows = [
      ...this.rows.slice(0, index),
      row,
      ...this.rows.slice(index + 1)
    ];
    const isVisible = row.isVisible;
    this.handleVisibleChangeAndValidity(isVisible, row);
    this.emitChanges();
  }

  handleVisibleChangeAndValidity (isVisible: boolean, row: TableColumnForForm) {
    if (isVisible) {
      // Remove this column from the array
      this.hiddenTableColumnKeys = this.hiddenTableColumnKeys.filter((key) => {
        return key !== row.key;
      });
    } else {
      // Add this column to the array
      this.hiddenTableColumnKeys = [
        ...this.hiddenTableColumnKeys,
        row.key
      ];
      // Make sure hidden fields are not required
      row.isRequired = false;
      this.handleRequiredChange(row.isRequired, row);
    }
    const uniqHidden = uniq(this.arrayHelper.sort(this.hiddenTableColumnKeys));
    // Make sure every column is not hidden
    this.hasAtLeastOneColumn = !isEqual(uniqHidden, this.originalTableColumnKeys);
  }

  labelChange (index: number) {
    const row = this.rows[index];
    const label = row.label;
    this.labelOverrideMap = {
      ...this.labelOverrideMap,
      [row.key]: label
    };
    this.emitChanges();
  }

  requiredChange (index: number) {
    const row = this.rows[index];
    const isRequired = row.isRequired;
    this.handleRequiredChange(isRequired, row);
    this.emitChanges();
  }

  handleRequiredChange (isRequired: boolean, row: TableColumnForForm) {
    if (!isRequired) {
      // Remove this column from the array
      this.requiredOverrideKeys = this.requiredOverrideKeys.filter((key) => {
        return key !== row.key;
      });
    } else {
      // Add this column to the array
      this.requiredOverrideKeys = [
        ...this.requiredOverrideKeys,
        row.key
      ];
    }
  }

  toggleSelectAll (selectAll: boolean) {
    this.rows = this.rows.map((row) => {
      const isVisible = row.canTurnOffRequiredAndVisible && !selectAll ? selectAll : true;
      this.handleVisibleChangeAndValidity(isVisible, row);

      return {
        ...row,
        isVisible
      };
    });
    this.emitChanges();
  }
}
