import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NominationForm } from '@core/typings/application.typing';
import { EmailExtensionValidator } from '@yourcause/common';
import { TypeSafeFormGroup, TypeSafeFormBuilder } from '@yourcause/common/core-forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gc-nomination-form-header',
  templateUrl: './nomination-form-header.component.html',
  styleUrls: ['./nomination-form-header.component.scss']
})
export class NominationFormHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formName = '';
  @Input() formDescription = '';
  @Input() forOrg = true;
  @Input() forApplicant = false;
  @Input() nomination: NominationForm = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    position: ''
  };
  @Input() markForValidity = false;
  @Input() readOnly = false;
  @Output() onValidityChange = new EventEmitter<boolean>();
  @Output() formChanged = new EventEmitter<NominationForm>();

  formGroup: TypeSafeFormGroup<NominationForm>;
  sub = new Subscription();

  constructor (
    private formBuilder: TypeSafeFormBuilder
  ) { }

  ngOnInit () {
    if (!this.formGroup) {
      this.formGroup = this.formBuilder.group<NominationForm>({
        email: [this.nomination.email, [
          Validators.required,
          EmailExtensionValidator,
          Validators.maxLength(50)
        ]],
        firstName: [this.nomination.firstName, [
          Validators.required,
          Validators.maxLength(50)
        ]],
        lastName: [this.nomination.lastName, [
          Validators.required,
          Validators.maxLength(50)
        ]],
        phoneNumber: [this.nomination.phoneNumber, Validators.maxLength(50)],
        position: [this.nomination.position, Validators.maxLength(50)]
      });
      this.sub.add(this.formGroup.statusChanges.subscribe((val) => {
        this.onValidityChange.emit(val === 'VALID');
      }));
    }
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.markForValidity) {
      if (this.markForValidity) {
        if (!this.formGroup) {
          this.ngOnInit();
        }
        const requiredFields: (keyof NominationForm)[] = ['firstName', 'lastName', 'email'];
        requiredFields.forEach((field) => {
          if (
            !this.formGroup.value[field] ||
            (
              field === 'email' &&
              Validators.email(new UntypedFormControl(this.formGroup.value.email))
            )
          ) {
            this.formGroup.get(field).markAsDirty();
            this.formGroup.get(field).setValue(this.formGroup.value[field]);
          }
        });
      }
    } else if (changes.nomination && this.formGroup) {
      this.formGroup.setValue({
        email: this.nomination.email || '',
        firstName: this.nomination.firstName || '',
        lastName: this.nomination.lastName || '',
        phoneNumber: this.nomination.phoneNumber || '',
        position: this.nomination.position || ''
      });
    }
  }

  nominationFormChanged () {
    this.formChanged.emit(this.formGroup.value);
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
