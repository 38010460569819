import { Component, Input, OnInit, Type } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { FormFieldService } from '@features/form-fields/services/form-field.service';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { TableDataDownloadFormat } from '@yourcause/common/files';
import { YCModalComponent } from '@yourcause/common/modals';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
  selector: 'gc-import-table-rows-modal',
  templateUrl: './import-table-rows-modal.component.html',
  styleUrls: ['./import-table-rows-modal.component.scss']
})
export class ImportTableRowsModalComponent extends YCModalComponent<boolean> implements OnInit {
  @Input() applicationId: number;
  @Input() applicationFormId: number;
  @Input() tableLabel: string;
  @Input() tableId: number;
  @Input() formId: number;
  @Input() rowsForTable: ReferenceFieldsUI.TableResponseRowForUiMapped[];
  @Input() hiddenTableColumnKeys: string[];
  @Input() labelOverrideMap: Record<string, string> = {};
  @Input() requiredOverrideKeys: string[];
  @Input() translations: Record<string, string> = {};

  ValidationClass: Type<any>;
  cdtItemsMap: Record<number, TypeaheadSelectOption[]> = {};
  importValid: boolean;
  saving = false;
  csvRows: Record<string, any>[] = [];
  currentPopover: {
    instance?: PopoverDirective;
  } = {};

  constructor (
    private formFieldService: FormFieldService,
    private customDataTableService: CustomDataTablesService,
    private spinnerService: SpinnerService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService
  ) {
    super();
  }

  ngOnInit () {
    this.cdtItemsMap = this.customDataTableService.getCdtItemsMapForRow(
      this.formFieldTableAndSubsetService.getColumnsForTable(
        this.tableId,
        this.hiddenTableColumnKeys,
        this.labelOverrideMap
      ),
      undefined,
      false
    );
    this.ValidationClass = this.formFieldTableAndSubsetService.getValidationClassForTableRowImport(
      this.tableId,
      this.cdtItemsMap,
      this.hiddenTableColumnKeys,
      this.labelOverrideMap,
      this.requiredOverrideKeys,
      this.translations
    );
  }

  downloadTemplate () {
    this.formFieldTableAndSubsetService.returnTableRowImportData(
      this.tableId,
      this.rowsForTable,
      true,
      TableDataDownloadFormat.CSV,
      this.hiddenTableColumnKeys,
      this.labelOverrideMap,
      this.requiredOverrideKeys,
      this.translations
    );
  }

  onContentsChange (rows: Record<string, any>[]) {
    this.csvRows = rows;
  }

  handleUpdateValidation (valid: boolean) {
    this.importValid = valid;
  }

  setCurrentPopover (
    popover: PopoverDirective
  ) {
    if (this.currentPopover.instance) {
      this.currentPopover.instance.hide();
    }

    this.currentPopover.instance = popover;

    setTimeout(() => {
      popover.show();
    });
  }

  async onImport () {
    this.spinnerService.startSpinner();
    this.saving = true;
    const passed = await this.formFieldTableAndSubsetService.handleImportTableRows(
      this.applicationId,
      this.tableId,
      this.formId,
      this.csvRows,
      this.cdtItemsMap,
      this.translations,
      this.hiddenTableColumnKeys,
      this.labelOverrideMap
    );
    this.saving = false;
    if (passed) {
      await this.formFieldService.getReferenceFieldResponses(
        this.applicationId,
        this.applicationFormId,
        [],
        [this.tableId],
        null,
        false,
        true
      );
      this.spinnerService.stopSpinner();
      this.closeModal.emit(true);
    } else {
      this.spinnerService.stopSpinner();
    }
  }
}
