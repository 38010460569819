import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LogicBuilderService } from '../logic-builder.service';
import { EvaluationType, GlobalLogicGroup, LogicColumnDisplay, LogicEvaluationTypeDisplayOptionsConditional, LogicEvaluationTypeDisplayOptionsValidity, LogicGroupType } from '../logic-builder.typing';

@Component({
  selector: 'gc-conditional-logic-builder',
  templateUrl: './conditional-logic-builder.component.html',
  styleUrls: ['./conditional-logic-builder.component.scss']
})
export class ConditionalLogicBuilderComponent<T> implements OnInit {
  @Input() availableColumns: LogicColumnDisplay<T>[];
  @Input() logic: GlobalLogicGroup<T>;
  @Input() maxDepth: number;
  @Input() isViewOnly = false;
  @Input() evaluationTypeOptions: LogicEvaluationTypeDisplayOptionsConditional|LogicEvaluationTypeDisplayOptionsValidity;
  @Output() logicChanged = new EventEmitter<GlobalLogicGroup<T>>();
  @Output() validChange = new EventEmitter<boolean>();

  constructor (
    private logicBuilderService: LogicBuilderService
  ) { }

  ngOnInit () {
    if (!this.logic) {
      this.logic = this.logicBuilderService.getDefaultConditionalLogic();
      this.logicChanged.emit(this.logic);
    }

    if (this.availableColumns.length === 0) {
      this.handleValidityChange(true);
      this.logic = this.logicBuilderService.getDefaultConditionalLogic();
    }
  }

  handleValidityChange (
    valid: boolean
  ) {
    this.validChange.emit(valid);
  }

  handleGroupChange (group: LogicGroupType<T, null>) {
    this.logic = group as GlobalLogicGroup<T>;
    this.logicChanged.emit(this.logic);
  }

  handleEvaluationTypeChange (evaluationType: EvaluationType) {
    this.logic = this.logicBuilderService.handleEvaluationTypeChange(
      this.logic,
      evaluationType
    );

    this.logicChanged.emit(this.logic);

    switch (evaluationType) {
      case EvaluationType.AlwaysFalse:
      case EvaluationType.AlwaysTrue:
        this.handleValidityChange(true);
        break;
    }
  }
}
