import { SimpleStringMap } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { Audience, AudienceDetail } from './audience.typing';

@RegisterYCState()
export class AudienceState extends BaseYcState {
  readonly allAudiences: Audience[];
  readonly audienceDetailMap: SimpleStringMap<AudienceDetail> = {};
  readonly audienceSimpleMap: SimpleStringMap<Audience>;
}
