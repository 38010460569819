import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationFileUploadResponse } from '@core/typings/file.typing';
import { environment } from '@environment';
import { AddressRequestsResources } from '@features/platform-admin/address-requests/address-requests.resources';
import { FileService, FileTypeService } from '@yourcause/common/files';
import { HttpRestService } from './http-rest.service';
import { LocationService } from './location.service';
import { PortalDeterminationService } from './portal-determination.service';

export interface ApplicationFileUrlParams {
  applicationId: number;
  applicationFormId: number;
  fileId: number;
  fileName: string;
}

@Injectable({ providedIn: 'root' })
export class ApplicationFileService {

   constructor (
    private portal: PortalDeterminationService,
    private http: HttpRestService,
    private fileService: FileService,
    private addressRequestResources: AddressRequestsResources,
    private fileTypeService: FileTypeService,
    private locationService: LocationService
  ) { }

  extractQueryParamsFromFileUrl (url: string) {
    return this.locationService.extractQueryParamsFromUrl<ApplicationFileUrlParams>(url);
  }

  convertParamsToApplicationFileUrl (
    applicationId: number,
    applicationFormId: number,
    fileId: number,
    fileName: string
  ) {
    const forApplicant = this.portal.isApply;
    const locationBase = environment.isLocalhost ?
      'yourcausegrantsqa.com' :
      location.hostname;

    const queryParams = {
      applicationId,
      applicationFormId,
      fileId,
      fileName
    };
    const query = Object.keys(queryParams)
      .map(key => key as keyof typeof queryParams)
      .map((key: keyof typeof queryParams) => `${key}=${encodeURIComponent(queryParams[key])}`)
      .join('&');

    return `https://${locationBase}/${forApplicant ? 'apply' : 'management'}/download-file?${query}`;
  }

  async uploadFile (
    applicationId: number,
    applicationFormId: number,
    file: File,
    fileName: string,
    refFieldId: number
  ) {
    try {
      const forApplicant = this.portal.isApply;
      let endpoint = forApplicant ?
        `/api/portal/applications/${applicationId}/ApplicationForms/${applicationFormId}/Files` :
        `/api/manager/applications/${applicationId}/ApplicationForms/${applicationFormId}/Files`;
      if (!!refFieldId) {
        endpoint = `${endpoint}?referenceFieldId=${refFieldId}`;
      }

      const fileId = await this.http.postFile(endpoint, file, undefined, fileName);

      return this.convertParamsToApplicationFileUrl(
        applicationId,
        applicationFormId,
        fileId,
        fileName
      );
    } catch (err) {
      const e = err as HttpErrorResponse;
      this.fileTypeService.displayInvalidFileUploadErrorMessage(e?.error?.message, e);

      return null;
    }
  }

  breakDownloadUrlDownToObject (url: string): ApplicationFileUrlParams {
    if (url) {
      const applicableInfo = url.split('?')[1];

      if (applicableInfo) {
        const info = applicableInfo.split('&')
          .map(keyValueString => keyValueString.split('='))
          .reduce((acc, keyValue) => ({
            ...acc,
            [keyValue[0]]: keyValue[1]
          }), {
            applicationId: null,
            applicationFormId: null,
            fileId: null,
            fileName: ''
          });

        let fileName = info.fileName;
        try {
          const decoded = decodeURIComponent(info.fileName);
          fileName = decoded;
        } catch (e) { }

        return {
          ...info,
          fileName
        };
      }
    }

    return null;
  }

  async openFile (
    applicationId: number,
    fileId: number,
    applicationFormId?: number
  ) {
    const blob = await this.getFileFromFileInfo(applicationFormId, applicationId, fileId);
    const blobUrl = this.fileService.convertFileToUrl(blob);

    window.open(blobUrl, '_blank');
  }

  async downloadFile (
    applicationId: number,
    fileId: number,
    fileName: string,
    applicationFormId?: number
  ) {
    const blob = await this.getFileFromFileInfo(applicationFormId, applicationId, fileId);

    this.fileService.saveAs(blob, fileName);
  }

  async getFileFromFileInfo (
    applicationFormId: number,
    applicationId: number,
    fileId: number
  ) {
    let url: string;
    if (applicationFormId) {
      const { accessUrl } = await this.getFile(
        applicationId,
        applicationFormId,
        fileId
      );
      url = accessUrl;
    } else {
      // Only scenario is address request files
      const {
        accessUrl
      } = await this.addressRequestResources.getManagerUrlForAddressRequestFile(
        applicationId,
        fileId
      );
      url = accessUrl;
    }

    const blob = await this.fileService.getBlob(url) as File;

    return blob;
  }

  private getFileApplicant (
    applicationId: number,
    applicationFormId: number,
    applicationFormFileUploadId: number
  ) {
    return `/api/portal/applications/${applicationId}/ApplicationForms/${applicationFormId}/Files/${applicationFormFileUploadId}`;
  }

  private getFileManager (
    applicationId: number,
    applicationFormId: number,
    applicationFormFileUploadId: number
  ) {
    return `/api/manager/applications/${applicationId}/ApplicationForms/${applicationFormId}/Files/${applicationFormFileUploadId}`;
  }

  async getFile (
    applicationId: number,
    applicationFormId: number,
    applicationFormFileUploadId: number
  ) {
    return this.http.get<ApplicationFileUploadResponse>(
      this.getFileUrl(applicationId, applicationFormId, applicationFormFileUploadId)
    );
  }

  private getFileUrl (
    applicationId: number,
    applicationFormId: number,
    applicationFormFileUploadId: number
  ): string {
    const func = this.portal.isApply ?
      'getFileApplicant' :
      'getFileManager';

    return this[func](
      applicationId,
      applicationFormId,
      applicationFormFileUploadId
    );
  }
}
