import { Component, OnDestroy } from '@angular/core';
import { GcFlyoutService } from '@core/services/gc-flyout.service';
import { SimpleWorkflowUser, WorkflowDetailForUi, WorkflowGrantProgram, WorkflowManager } from '@core/typings/workflow.typing';
import { I18nService } from '@yourcause/common/i18n';
import { Subscription } from 'rxjs';
import { WorkflowService } from '../workflow.service';

@Component({
  selector: 'gc-workflow-detail-flyout',
  templateUrl: './workflow-detail-flyout.component.html',
  styleUrls: ['./workflow-detail-flyout.component.scss']
})
export class WorkflowDetailFlyoutComponent implements OnDestroy {
  workflowDetail: WorkflowDetailForUi;
  workflowUsers: SimpleWorkflowUser[] = [];
  workflowManagers: WorkflowManager[] = [];
  programs: WorkflowGrantProgram[] = [];
  workflowUsersSubHeader: string;
  workflowManagersSubHeader: string;
  programsSubHeader: string;
  sub = new Subscription();

  constructor (
    private workflowService: WorkflowService,
    private gcFlyoutService: GcFlyoutService,
    private i18n: I18nService
  ) {
    this.sub.add(this.gcFlyoutService.changesTo$('currentIdForFlyout').subscribe(() => {
      this.setHelpers();
    }));
    this.sub.add(this.workflowService.changesTo$('workflowUsersMap').subscribe(() => {
      this.setHelpers();
    }));
    this.sub.add(this.workflowService.changesTo$('workflowMap').subscribe(() => {
      this.setHelpers();
    }));
  }

  get workflowId () {
    return +this.gcFlyoutService.currentIdForFlyout;
  }

  setHelpers () {
    this.workflowDetail = this.workflowService.getWorkflowForEdit(this.workflowId);
    this.workflowUsers = this.workflowService.workflowUsersMap[this.workflowId] ?? [];
    this.workflowUsersSubHeader = this.i18n.translate(
      this.workflowUsers.length === 1 ? 'WORKFLOW:textOneWorkflowUser' : 'WORKFLOW:textNumberWorkflowUsers',
      {
        number: this.workflowUsers.length
      },
      this.workflowUsers.length === 1 ?
        'One workflow user' :
        '__number__ workflow users'
    );
    this.workflowManagers = this.workflowDetail?.workflowLevelManagers ?? [];
    this.workflowManagersSubHeader = this.i18n.translate(
      this.workflowManagers.length === 1 ? 'WORKFLOW:textOneWorkflowManager2' : 'WORKFLOW:textNumberWorkflowManagers',
      {
        number: this.workflowManagers.length
      },
      this.workflowManagers.length === 1 ?
        'One workflow manager' :
        '__number__ workflow managers'
    );
    this.programs = this.workflowDetail?.grantPrograms ?? [];
    this.programsSubHeader = this.i18n.translate(
      this.programs.length === 1 ? 'WORKFLOW:textOneProgramUsingThisWorkflow' : 'WORKFLOW:textNumberProgramsUsingWorkflow',
      {
        number: this.programs.length
      },
      this.programs.length === 1 ?
        '__number__ program using this workflow' :
        '__number__ programs using workflow'
    );
  }

  editWorkflow () {
    this.workflowService.navigateToWorkflowDetail(this.workflowId);
  }

  ngOnDestroy (): void {
    this.sub.unsubscribe();
  }
}
