import { Injectable } from '@angular/core';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { REF_COMPONENT_TYPE_PREFIX, ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormAudience, FormTypes } from '@features/configure-forms/form.typing';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { FormFieldCdtService } from '@features/form-fields/services/form-field-cdt.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { REPORT_FIELD_KEY } from '@features/forms/services/component-helper/component-helper.service';
import { ReportFieldService } from '@features/forms/services/report-field/report-field.service';
import { AdHocReportingService } from '@features/reporting/services/ad-hoc-reporting.service';
import { I18nService } from '@yourcause/common/i18n';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { FormFieldCategoryService } from '../../form-fields/services/form-field-category.service';
import { ExistingRefField } from '../quick-add-fields/quick-add-fields.component';

@Injectable({ providedIn: 'root' })
export class QuickAddService {

  constructor (
    private formBuilderService: FormBuilderService,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private reportFieldService: ReportFieldService,
    private adHocReportingService: AdHocReportingService,
    private formFieldHelperService: FormFieldHelperService,
    private formFieldCdtService: FormFieldCdtService,
    private formFieldCategoryService: FormFieldCategoryService,
    private customDataTableService: CustomDataTablesService,
    private formService: FormsService,
    private clientSettingsService: ClientSettingsService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService
  ) { }

  /**
   * Maps a Form Field to the Quick Add Model
   *
   * @param field: Field to Map
   * @returns the mapped object
   */
  mapFormFieldToQuickAdd (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel
  ): ReferenceFieldsUI.QuickAddField {
    const hideRequired = !this.formFieldHelperService.canFieldTypeBeRequired(field.type, field.supportsMultiple);
    const type = `${REF_COMPONENT_TYPE_PREFIX}${field.key}`;

    return {
      key: field.key,
      type,
      name: field.name,
      label: field.defaultLabel || field.name,
      categoryId: field.categoryId,
      isRequired: !hideRequired,
      bucketName: this.i18n.translate(
        field.formAudience === FormAudience.APPLICANT ?
          'common:hdrApplicantFields' :
          'common:hdrManagerFields',
        {},
        field.formAudience === FormAudience.APPLICANT ?
          'Applicant fields' :
          'Manager fields'
      ),
      hideRequired,
      tooltip: this.formFieldHelperService.getFieldTooltip(
        field,
        this.formFieldCategoryService.categoryNameMap,
        this.customDataTableService.customDataTables
      ),
      referenceField: field
    };
  }

  /**
   * Get the Accordion Groups to Display in Quick Add
   *
   * @param formId: Form ID
   * @param existingRefFields: Existing ref fields
   * @param componentsToAdd: Components we will be adding
   * @param quickAddType: Quick Add Scenario Type
   * @returns the accordion groups to display in quick add
   */
  getAccordionGroups (
    formId: number,
    existingRefFields: ExistingRefField[],
    componentsToAdd: ReferenceFieldsUI.QuickAddField[],
    quickAddType: ReferenceFieldsUI.QuickAddType,
    formType: FormTypes
  ): (ReferenceFieldsUI.QuickAddBucket[])[] {
    switch (quickAddType) {
      case ReferenceFieldsUI.QuickAddType.FORM_BUILDER:
      default:
        return this.getAccordionGroupsForFormBuilder(
          formId,
          existingRefFields,
          componentsToAdd,
          formType === FormTypes.NOMINATION
        );
      case ReferenceFieldsUI.QuickAddType.AD_HOC:
        return this.getAccordionGroupsForAdHoc(
          existingRefFields,
          componentsToAdd,
          formId
        );
    }
  }

  /**
   * Get the Accordion Groups for Quick Add Form Builder
   *
   * @param formId: Form ID
   * @param existingRefFields: Existing Ref Fields in Use
   * @param componentsToAdd: Components we plan to add
   * @returns the accordion groups for the form builder's quick add
   */
  getAccordionGroupsForFormBuilder (
    formId: number,
    existingRefFields: ExistingRefField[],
    componentsToAdd: ReferenceFieldsUI.QuickAddField[],
    isNominationForm: boolean
  ): (ReferenceFieldsUI.QuickAddBucket[])[] {
    const formType = this.formService.getFormTypeFromFormId(formId);
    const formAudience = this.formService.getAudienceFromFormType(formType);
    const applicantFields = this.prepFieldsForQuickAddModal(
      FormAudience.APPLICANT,
      formAudience,
      existingRefFields,
      componentsToAdd
    );
    const sortedApplicantFields = this.arrayHelper.sort(applicantFields, 'name');

    const managerFields = this.clientSettingsService.isBBGM ? [] :this.prepFieldsForQuickAddModal(
      FormAudience.MANAGER,
      formAudience,
      existingRefFields,
      componentsToAdd
    );
    const sortedManagerFields = this.arrayHelper.sort(managerFields, 'name');
    const applicantAndManagerFields = [
      ...sortedApplicantFields,
      ...sortedManagerFields
    ];

    const standardName = this.i18n.translate(
      'common:hdrStandardComponents',
      {},
      'Standard components'
    );
    const reportName = this.i18n.translate(
      'common:hdrReportFields',
      {},
      'Report fields'
    );
    const standardComponents: ReferenceFieldsUI.QuickAddField[] = this.formBuilderService.getStandardComponents(
      formType,
      formAudience,
      componentsToAdd.map((comp) => comp.type)
    ).filter((comp) => {
      return !comp.hidden;
    }).map((comp) => {
      return {
        key: comp.key,
        type: comp.type,
        label: comp.label,
        name: comp.name,
        categoryId: comp.categoryId,
        tooltip: '',
        isRequired: comp.markAsRequired,
        hideRequired: !comp.markAsRequired,
        bucketName: standardName
      };
    });

    const reportFieldBuckets = this.reportFieldService.getReportFieldBuckets(isNominationForm, false);
    const reportFields = reportFieldBuckets.reduce((acc, curr) => {
      const bucket = reportFieldBuckets.find((_bucket) => _bucket.property === curr.property);
      const options = bucket.columns.map<ReferenceFieldsUI.QuickAddField>((column) => {
        const columnName = column.definition.display;
        const label = columnName + ' - (' + column.definition.parentBucketName + ')';

        return {
          key: column.definition.column,
          name: label,
          label,
          categoryId: null,
          type: REPORT_FIELD_KEY,
          isReportField: true,
          isRequired: false,
          hideRequired: true,
          tooltip: '',
          reportFieldConfigType: {
            reportFieldObject: column.definition.parentBucket,
            reportFieldDisplay: column.definition.column,
            nominationFormId: null
          },
          bucketName: reportName
        };
      });

      return [
        ...acc,
        ...options
      ];
    }, [] as ReferenceFieldsUI.QuickAddField[]).filter((field) => {
      return !componentsToAdd.find((compToAdd) => compToAdd.key === field.key);
    });
    const applicantAndGMFieldsFlatList = applicantAndManagerFields.reduce((acc, curr) => {
      return [
        ...acc,
        ...curr.components
      ];
    }, [] as ReferenceFieldsUI.QuickAddField[]);
    const accordionGroupsWithComps: ReferenceFieldsUI.QuickAddBucket[][] = [
      [{
        name: standardName,
        type: ReferenceFieldsUI.QuickAddFieldBucket.STANDARD,
        componentGroups: [{
          name: standardName,
          components: standardComponents
        }]
      }],
      [{
        name: this.i18n.translate(
          'common:hdrApplicantFields',
          {},
          'Applicant fields'
        ),
        type: ReferenceFieldsUI.QuickAddFieldBucket.APPLICANT,
        componentGroups: sortedApplicantFields
      }],
      this.clientSettingsService.isBBGM ? undefined : [{
        name: this.i18n.translate(
          'common:hdrManagerFields',
          {},
          'Manager fields'
        ),
        type: ReferenceFieldsUI.QuickAddFieldBucket.MANAGER,
        componentGroups: sortedManagerFields
      }],
      [{
        name: reportName,
        type: ReferenceFieldsUI.QuickAddFieldBucket.REPORT,
        componentGroups: [{
          name: reportName,
          components: this.arrayHelper.sort(reportFields, 'label')
        }]
      }],
      this.getAllComponentsBucket([
        ...applicantAndGMFieldsFlatList,
        ...standardComponents,
        ...reportFields
      ])
    ].filter((item) => !!item);

    return accordionGroupsWithComps;
  }

  /**
   * Get Form Fields By Type / Audience
   *
   * @param fieldAudience: Field Audience
   * @param formAudience: Form Audience
   * @param existingRefFields: Existing Ref Fields in Use
   * @param componentsToAdd: Components we will be adding
   * @returns form fields by type / audience
   */
  prepFieldsForQuickAddModal (
    fieldAudience: FormAudience,
    formAudience: FormAudience,
    existingRefFields: ExistingRefField[],
    componentsToAdd: ReferenceFieldsUI.QuickAddField[]
  ) {
    return this.formFieldHelperService.getReferenceFieldTypesList().reduce((acc, type) => {
      const typesToFilterOut = [
        ReferenceFieldsUI.ReferenceFieldTypes.DataPoint,
        ReferenceFieldsUI.ReferenceFieldTypes.ExternalAPI
      ];
      if (!typesToFilterOut.includes(type)) {
        const fields = this.formFieldHelperService.getFormFieldsByTypeAndAudience(
          existingRefFields,
          type,
          fieldAudience,
          formAudience,
          false
        );

        const filteredFields = fields.filter((field) => {
          return !componentsToAdd.some((comp) => {
            if (!!comp.referenceField) {
              return comp.referenceField.key === field.key;
            } else {
              return false;
            }
          });
        }).map<ReferenceFieldsUI.QuickAddField>((field) => {
          return this.mapFormFieldToQuickAdd(field);
        });
        const translatedType = this.formFieldHelperService.getFieldTypeTranslatedWithIcon(type);

        return [
          ...acc,
          {
            name: translatedType.label,
            components: this.arrayHelper.sort(filteredFields, 'name')
          }
        ];
      } else {
        return [
          ...acc
        ];
      }
    }, [] as ReferenceFieldsUI.QuickAddGroup[]);
  }

  getAllComponentsBucket (
    components: ReferenceFieldsUI.QuickAddField[]
  ) {
    return [{
      name: 'all',
      type: ReferenceFieldsUI.QuickAddFieldBucket.ALL,
      componentGroups: [{
        name: this.i18n.translate('GLOBAL:lblAll', {}, 'All'),
        components
      }]
    }];
  }

  /**
   * Get the Fields Available for Ad Hoc Quick Add
   *
   * @param existingRefFields: Existing Ref Fields In Use
   * @param columnsToAdd: Columns we are planning to add
   * @param formId: Form ID
   * @returns the fields available for ad hoc quick add
   */
  getAccordionGroupsForAdHoc (
    existingRefFields: ExistingRefField[],
    columnsToAdd: ReferenceFieldsUI.QuickAddField[],
    formId: number
  ): (ReferenceFieldsUI.QuickAddBucket[])[] {
    let accordionGroupsWithComps: ReferenceFieldsUI.QuickAddBucket[][] = [];
    const allComponents: ReferenceFieldsUI.QuickAddField[] = [];
    const allCompKeys: string[] = [];
    const formComponents = this.adHocReportingService.formComponentMap[formId];
    const fieldBuckets = this.getBucketsForAdHocFormFields(formId);
    fieldBuckets.forEach((bucket) => {
      const components = bucket.columns.map((col) => {
        const columnKey = col.definition.column;
        const formFieldKey = columnKey.split('.')[0];
        const field = this.formFieldHelperService.referenceFieldMap[formFieldKey];
        const foundInAdd = this.checkIfCompAlreadyExistsInColumnsToAdd(
          columnsToAdd,
          columnKey,
          col.definition.referenceFieldTableId
        );
        const foundInExisting = this.checkIfCompAlreadyExistsInExistingFields(
          existingRefFields,
          columnKey,
          col.definition.referenceFieldTableId
        );
        const passes = !foundInAdd && !foundInExisting;
        if (passes) {
          const name = col.definition.display;
          const foundFormComponent = formComponents.find((comp) => {
          if (comp.type === ReferenceFieldsUI.ReferenceFieldTypes.DataPoint) {
            return columnKey === comp.referenceFieldKey &&
              col.definition.referenceFieldTableId === comp.referenceFieldTableId;
          } else {
            return comp.key === columnKey;
          }
        });
          let label = foundFormComponent?.label ?? name;
          if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.DataPoint) {
            const relatedFieldGroup = this.formFieldTableAndSubsetService.getRelatedFieldGroupOrTable(
              col.definition.referenceFieldTableId
            );
            if (!!relatedFieldGroup) {
              label = `${label} (${relatedFieldGroup.name})`;
            }
          }
          const adaptedField: ReferenceFieldsUI.QuickAddField = {
            key: columnKey,
            type: columnKey,
            name,
            label,
            bucketName: bucket.i18nKey ?
              this.i18n.translate(bucket.i18nKey, {}, bucket.display) :
              bucket.display,
            categoryId: field?.categoryId,
            tooltip: this.formFieldHelperService.getFieldTooltip(
              field,
              this.formFieldCategoryService.categoryNameMap,
              this.customDataTableService.customDataTables,
              col.definition.display
            ),
            referenceField: field,
            referenceFieldTableId: foundFormComponent?.referenceFieldTableId
          };

          if (!allCompKeys.includes(adaptedField.key)) {
            allComponents.push(adaptedField);
            allCompKeys.push(adaptedField.key);
          }

          return adaptedField;
        } else {
          return null;
        }
      }).filter((field) => !!field);
      if (components.length > 0) {
        const group = [{
          name: bucket.display,
          type: bucket.property,
          componentGroups: [{
            name: '',
            components
          }]
        }];
        accordionGroupsWithComps = [
          ...accordionGroupsWithComps,
          group
        ];
      }
    });

    return [
      ...accordionGroupsWithComps,
      this.getAllComponentsBucket(allComponents)
    ];
  }

  /**
   * Does the column already exist in columns to add?
   *
   * @param columnsToAdd: Columns we are adding
   * @param columnKeyToCheck: Column to check against
   * @param referenceFieldTableIdToCheck: Reference Field Table ID to check when it's a data point
   * @returns if the column already exists
   */
  checkIfCompAlreadyExistsInColumnsToAdd (
    columnsToAdd: ReferenceFieldsUI.QuickAddField[],
    columnKeyToCheck: string,
    referenceFieldTableIdToCheck: number
  ) {
    const found  = columnsToAdd.find((column) => {
      const sameKey = column.key === columnKeyToCheck;
      if (!!column.referenceField) {
        if (sameKey && column.referenceField.type === ReferenceFieldsUI.ReferenceFieldTypes.DataPoint) {
          return column.referenceFieldTableId === referenceFieldTableIdToCheck;
        }
      }

      return sameKey;
    });

    return !!found;
  }

  /**
   * Does the column already exist in existing fields?
   *
   * @param existingFields: Existing Fields to check
   * @param columnKeyToCheck: Column Key to Check Against
   * @param referenceFieldTableIdToCheck: Reference Field Table ID to check for Data Points
   * @returns if the column already exists
   */
  checkIfCompAlreadyExistsInExistingFields (
    existingFields: ExistingRefField[],
    columnKeyToCheck: string,
    referenceFieldTableIdToCheck: number
  ) {
    const found  = existingFields.find((existingField) => {
      const sameKey = existingField.key === columnKeyToCheck;
      if (sameKey && existingField.type === ReferenceFieldsUI.ReferenceFieldTypes.DataPoint) {
        return existingField.referenceFieldTableId === referenceFieldTableIdToCheck;
      } else {
        return sameKey;
      }
    });

    return !!found;
  }

  /**
   * Get Ad Hoc Buckets By Form
   *
   * @param formId: Form ID
   * @returns the buckets for ad hoc filtered by form
   */
  getBucketsForAdHocFormFields (formId: number) {
    const buckets = this.adHocReportingService.getBuckets(
      this.adHocReportingService.currentReportDetails.object,
      [formId],
      this.adHocReportingService.currentReportColumns,
      AdHocReportingUI.Usage.AD_HOC_QUICK_ADD
    );

    return buckets.filter((bucket) => {
      return bucket.columns.some((column) => {
        return column.definition.formIds?.includes(formId);
      });
    });
  }

  /**
   * Get a list of Invalid Dependent Picklists
   *
   * @param componentsToAdd: Components we plan to add
   * @param invalidDependentPicklists: Existing list of invalid picklists
   * @param fieldToAdd: Fields to Add
   * @param fieldToRemove: Fields to Remove
   * @returns an updated list of invalid dependent picklists
   */
  handleDependentPicklistValidityForQuickAdd (
    componentsToAdd: ReferenceFieldsUI.QuickAddField[],
    invalidDependentPicklists: ReferenceFieldsUI.InvalidDependentPicklist[],
    fieldToAdd?: ReferenceFieldsUI.QuickAddField,
    fieldToRemove?: ReferenceFieldsUI.QuickAddField
  ) {
    if (fieldToAdd && ('referenceField' in fieldToAdd)) {
      const field = fieldToAdd.referenceField;
      const isParentPicklist = this.formFieldHelperService.getIsParentRefField(
        field.referenceFieldId
      );
      const requiresParent = !!field.parentReferenceFieldId &&
        !field.aggregateType;
      if (requiresParent) {
        const {
          parentIsOnForm,
          parentPicklist
        } = this.formFieldCdtService.checkForParentPicklistOnForm(
          field.parentReferenceFieldId
        );
        if (!parentIsOnForm) {
          // If parent not on form, see if they are in the componentsToAdd array
          const foundParent = componentsToAdd.find((comp) => {
            return ('referenceField' in comp) &&
              comp.referenceField.referenceFieldId === parentPicklist.referenceFieldId;
          });
          if (!foundParent) {
            invalidDependentPicklists = [
              ...invalidDependentPicklists,
              {
                fieldWithoutParent: fieldToAdd.referenceField,
                parentPicklist
              }
            ];
          }
        }
      }
      if (isParentPicklist) {
        const children = this.formFieldHelperService.getChildrenOfParentRefField(
          fieldToAdd.referenceField.referenceFieldId
        );
        children.forEach((child) => {
          // If this child was found in the invalid array, remove it
          const childIndex = invalidDependentPicklists.findIndex((item) => {
            return item.fieldWithoutParent.referenceFieldId === child.referenceFieldId;
          });
          if (childIndex > -1) {
            invalidDependentPicklists = [
              ...invalidDependentPicklists.slice(0, childIndex),
              ...invalidDependentPicklists.slice(childIndex + 1)
            ];
          }
        });
      }
    } else if (fieldToRemove && ('referenceField' in fieldToRemove)) {
      const fieldForRemoval = fieldToRemove.referenceField;
      // Check to see if the field we are removing had an error.
      // If so error no longer relevant
      const fieldIndex = invalidDependentPicklists.findIndex((item) => {
        return item.fieldWithoutParent.referenceFieldId === fieldToRemove.referenceField.referenceFieldId;
      });
      if (fieldIndex !== -1) {
        invalidDependentPicklists = [
          ...invalidDependentPicklists.slice(0, fieldIndex),
          ...invalidDependentPicklists.slice(fieldIndex + 1)
        ];
      }
      // If we remove a parent, we need to add validation that the child is not being added
      const children = this.formFieldHelperService.getChildrenOfParentRefField(
        fieldForRemoval.referenceFieldId
      );
      children.forEach((child) => {
        const childIsBeingAdded = componentsToAdd.find((comp) => {
          return ('referenceField' in comp) &&
            comp.referenceField.referenceFieldId === child.referenceFieldId;
        });
        if (childIsBeingAdded) {
          invalidDependentPicklists = this.handleDependentPicklistValidityForQuickAdd(
            componentsToAdd,
            invalidDependentPicklists,
            this.mapFormFieldToQuickAdd(child)
          );
        }
      });
    }


    return invalidDependentPicklists;
  }
}
