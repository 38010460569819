<yc-collapsible-section
  [header]="component.i18nKey | ycI18n: component.i18nDefault">
  <ng-template #section>
    <yc-textarea
      [name]="component.key"
      [disabled]="isViewOnly"
      [(value)]="component.value"
      [label]="component.i18nKey | ycI18n: component.i18nDefault"
      [srOnlyLabel]="true"
      (valueChange)="onChange.emit($event)">
    </yc-textarea>
  </ng-template>
</yc-collapsible-section>