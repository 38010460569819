<yc-collapsible-section
  [header]="'common:hdrCalculatedValueBuilder' | ycI18n: 'Calculated Value Builder'">
  <ng-template #section>
    <yc-alert
      [alertClass]="'info'"
      [message]="alertMessage">
    </yc-alert>
    <!-- Edit -->
    <yc-button *ngIf="logicEnabled"
      [link]="true"
      [langKey]="'FORMS:btnManageCalculatedFormula'"
      [defaultText]="'Manage calculated value formula'"
      (onClick)="openLogicBuilderModal()">
    </yc-button>
  </ng-template>
</yc-collapsible-section>
