export default [
  {
    "code": "AED",
    "displayName": "UAE Dirham"
  },
  {
    "code": "AFN",
    "displayName": "Afghani"
  },
  {
    "code": "ALL",
    "displayName": "Lek"
  },
  {
    "code": "AMD",
    "displayName": "Armenian Dram"
  },
  {
    "code": "ANG",
    "displayName": "Netherlands Antillean Guilder"
  },
  {
    "code": "ARS",
    "displayName": "Argentine Peso"
  },
  {
    "code": "AUD",
    "displayName": "Australian Dollar"
  },
  {
    "code": "AZN",
    "displayName": "Azerbaijanian Manat"
  },
  {
    "code": "BAM",
    "displayName": "Convertible Mark"
  },
  {
    "code": "BDT",
    "displayName": "Taka"
  },
  {
    "code": "BGN",
    "displayName": "Bulgarian Lev"
  },
  {
    "code": "BHD",
    "displayName": "Bahraini Dinar"
  },
  {
    "code": "BIF",
    "displayName": "Burundi Franc"
  },
  {
    "code": "BND",
    "displayName": "Brunei Dollar"
  },
  {
    "code": "BOB",
    "displayName": "Boliviano"
  },
  {
    "code": "BRL",
    "displayName": "Brazilian Real"
  },
  {
    "code": "BWP",
    "displayName": "Pula"
  },
  {
    "code": "BZD",
    "displayName": "Belize Dollar"
  },
  {
    "code": "CAD",
    "displayName": "Canadian Dollar"
  },
  {
    "code": "CDF",
    "displayName": "Congolese Franc"
  },
  {
    "code": "CHF",
    "displayName": "Swiss Franc"
  },
  {
    "code": "CLP",
    "displayName": "Chilean Peso"
  },
  {
    "code": "CNH",
    "displayName": "Chinese Yuan"
  },
  {
    "code": "CNY",
    "displayName": "Yuan Renminbi"
  },
  {
    "code": "COP",
    "displayName": "Colombian Peso"
  },
  {
    "code": "CRC",
    "displayName": "Costa Rican Colon"
  },
  {
    "code": "CVE",
    "displayName": "Cabo Verde Escudo"
  },
  {
    "code": "CZK",
    "displayName": "Czech Koruna"
  },
  {
    "code": "DJF",
    "displayName": "Djibouti Franc"
  },
  {
    "code": "DKK",
    "displayName": "Danish Krone"
  },
  {
    "code": "DOP",
    "displayName": "Dominican Peso"
  },
  {
    "code": "DZD",
    "displayName": "Algerian Dinar"
  },
  {
    "code": "EGP",
    "displayName": "Egyptian Pound"
  },
  {
    "code": "ERN",
    "displayName": "Nakfa"
  },
  {
    "code": "ETB",
    "displayName": "Ethiopian Birr"
  },
  {
    "code": "EUR",
    "displayName": "Euro"
  },
  {
    "code": "GBP",
    "displayName": "Pound Sterling"
  },
  {
    "code": "GEL",
    "displayName": "Lari"
  },
  {
    "code": "GHS",
    "displayName": "Ghana Cedi"
  },
  {
    "code": "GNF",
    "displayName": "Guinea Franc"
  },
  {
    "code": "GTQ",
    "displayName": "Quetzal"
  },
  {
    "code": "HKD",
    "displayName": "Hong Kong Dollar"
  },
  {
    "code": "HNL",
    "displayName": "Lempira"
  },
  {
    "code": "HRK",
    "displayName": "Kuna"
  },
  {
    "code": "HUF",
    "displayName": "Forint"
  },
  {
    "code": "IDR",
    "displayName": "Rupiah"
  },
  {
    "code": "ILS",
    "displayName": "New Israeli Sheqel"
  },
  {
    "code": "INR",
    "displayName": "Indian Rupee"
  },
  {
    "code": "IQD",
    "displayName": "Iraqi Dinar"
  },
  {
    "code": "IRR",
    "displayName": "Iranian Rial"
  },
  {
    "code": "ISK",
    "displayName": "Iceland Krona"
  },
  {
    "code": "JMD",
    "displayName": "Jamaican Dollar"
  },
  {
    "code": "JOD",
    "displayName": "Jordanian Dinar"
  },
  {
    "code": "JPY",
    "displayName": "Yen"
  },
  {
    "code": "KES",
    "displayName": "Kenyan Shilling"
  },
  {
    "code": "KHR",
    "displayName": "Riel"
  },
  {
    "code": "KMF",
    "displayName": "Comoro Franc"
  },
  {
    "code": "KRW",
    "displayName": "Won"
  },
  {
    "code": "KWD",
    "displayName": "Kuwaiti Dinar"
  },
  {
    "code": "KZT",
    "displayName": "Tenge"
  },
  {
    "code": "LBP",
    "displayName": "Lebanese Pound"
  },
  {
    "code": "LKR",
    "displayName": "Sri Lanka Rupee"
  },
  {
    "code": "LYD",
    "displayName": "Libyan Dinar"
  },
  {
    "code": "MAD",
    "displayName": "Moroccan Dirham"
  },
  {
    "code": "MDL",
    "displayName": "Moldovan Leu"
  },
  {
    "code": "MGA",
    "displayName": "Malagasy Ariary"
  },
  {
    "code": "MKD",
    "displayName": "Denar"
  },
  {
    "code": "MMK",
    "displayName": "Kyat"
  },
  {
    "code": "MOP",
    "displayName": "Pataca"
  },
  {
    "code": "MRU",
    "displayName": "Mauritanian Ouguiya"
  },
  {
    "code": "MUR",
    "displayName": "Mauritius Rupee"
  },
  {
    "code": "MXN",
    "displayName": "Mexican Peso"
  },
  {
    "code": "MYR",
    "displayName": "Malaysian Ringgit"
  },
  {
    "code": "MZN",
    "displayName": "Mozambique Metical"
  },
  {
    "code": "NAD",
    "displayName": "Namibia Dollar"
  },
  {
    "code": "NGN",
    "displayName": "Naira"
  },
  {
    "code": "NIO",
    "displayName": "Cordoba Oro"
  },
  {
    "code": "NOK",
    "displayName": "Norwegian Krone"
  },
  {
    "code": "NPR",
    "displayName": "Nepalese Rupee"
  },
  {
    "code": "NZD",
    "displayName": "New Zealand Dollar"
  },
  {
    "code": "OMR",
    "displayName": "Rial Omani"
  },
  {
    "code": "PAB",
    "displayName": "Balboa"
  },
  {
    "code": "PEN",
    "displayName": "Nuevo Sol"
  },
  {
    "code": "PHP",
    "displayName": "Philippine Peso"
  },
  {
    "code": "PKR",
    "displayName": "Pakistan Rupee"
  },
  {
    "code": "PLN",
    "displayName": "Zloty"
  },
  {
    "code": "PYG",
    "displayName": "Guarani"
  },
  {
    "code": "QAR",
    "displayName": "Qatari Rial"
  },
  {
    "code": "RON",
    "displayName": "Romanian Leu"
  },
  {
    "code": "RSD",
    "displayName": "Serbian Dinar"
  },
  {
    "code": "RUB",
    "displayName": "Russian Ruble"
  },
  {
    "code": "RWF",
    "displayName": "Rwanda Franc"
  },
  {
    "code": "SAR",
    "displayName": "Saudi Riyal"
  },
  {
    "code": "SDG",
    "displayName": "Sudanese Pound"
  },
  {
    "code": "SEK",
    "displayName": "Swedish Krona"
  },
  {
    "code": "SGD",
    "displayName": "Singapore Dollar"
  },
  {
    "code": "SOS",
    "displayName": "Somali Shilling"
  },
  {
    "code": "SSP",
    "displayName": "South Sudanese Pound"
  },
  {
    "code": "STN",
    "displayName": "Sao Tomean Dobra"
  },
  {
    "code": "SYP",
    "displayName": "Syrian Pound"
  },
  {
    "code": "THB",
    "displayName": "Baht"
  },
  {
    "code": "TND",
    "displayName": "Tunisian Dinar"
  },
  {
    "code": "TOP",
    "displayName": "Pa’anga"
  },
  {
    "code": "TRY",
    "displayName": "Turkish Lira"
  },
  {
    "code": "TTD",
    "displayName": "Trinidad and Tobago Dollar"
  },
  {
    "code": "TWD",
    "displayName": "New Taiwan Dollar"
  },
  {
    "code": "TZS",
    "displayName": "Tanzanian Shilling"
  },
  {
    "code": "UAH",
    "displayName": "Hryvnia"
  },
  {
    "code": "UGX",
    "displayName": "Uganda Shilling"
  },
  {
    "code": "USD",
    "displayName": "US Dollar"
  },
  {
    "code": "UYU",
    "displayName": "Peso Uruguayo"
  },
  {
    "code": "UZS",
    "displayName": "Uzbekistan Sum"
  },
  {
    "code": "VEF",
    "displayName": "Bolivar"
  },
  {
    "code": "VES",
    "displayName": "Sovereign Bolivar"
  },
  {
    "code": "VND",
    "displayName": "Dong"
  },
  {
    "code": "XAF",
    "displayName": "CFA Franc BEAC"
  },
  {
    "code": "XOF",
    "displayName": "CFA Franc BCEAO"
  },
  {
    "code": "YER",
    "displayName": "Yemeni Rial"
  },
  {
    "code": "ZAR",
    "displayName": "Rand"
  }
]