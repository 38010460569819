import { Injectable } from '@angular/core';
import { TranslationService } from '@core/services/translation.service';
import { CyclesAPI } from '@core/typings/api/cycles.typing';
import { SimpleStringMap } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { isAfter, isBefore } from 'date-fns';
import { CyclesResources } from './cycles.resources';
import { CyclesState } from './cycles.state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { DateService } from '@yourcause/common/date';

@AttachYCState(CyclesState)
@Injectable({ providedIn: 'root' })
export class CyclesService extends BaseYCService<CyclesState> {

   constructor (
    private logger: LogService,
    private cyclesResources: CyclesResources,
    private notifier: NotifierService,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private translationService: TranslationService,
    private confirmAndTakeActionService: ConfirmAndTakeActionService,
    private dateService: DateService
  ) {
    super();
  }

  get programCycleMap () {
    return this.get('programCycleMap');
  }

  get allMyCycleSelectOptions () {
    return this.get('allMyCycleSelectOptions');
  }

  get allMyNonFutureCycleSelectOptions () {
    return this.get('allMyNonFutureCycleSelectOptions');
  }

  resetAllMyCycleSelectOptions () {
    this.set('allMyCycleSelectOptions', undefined);
  }

  setMyCycleAttrs (myCycles: CyclesAPI.BaseProgramCycle[]) {
    this.set(
      'allMyCycleSelectOptions',
      this.arrayHelper.sort(
        myCycles.map((cycle) => {
          return this.getCycleSelectObj(cycle);
        }),
        'label'
      )
    );
    this.set(
      'allMyNonFutureCycleSelectOptions',
      this.arrayHelper.sort(
        myCycles.filter((cycle) => {
          return isBefore(new Date(cycle.startDate), new Date());
        }).map((cycle) => {
          return this.getCycleSelectObj(cycle);
        }),
        'label'
      )
    );
  }

  getCycleSelectObj (cycle: CyclesAPI.SimpleCycle|CyclesAPI.BaseProgramCycle) {
    const translateMap = this.translationService.viewTranslations.Grant_Program_Cycle;
    const record = translateMap[cycle.id];

    return {
      label: record && record.Name ?
        record.Name :
        (cycle as CyclesAPI.SimpleCycle).name || '',
      value: cycle.id
    };
  }

  getCycleStatus (startDate: string, endDate: string): CyclesAPI.CycleStatuses {
    if (isBefore(new Date(), new Date(startDate))) {
      return CyclesAPI.CycleStatuses.Upcoming;
    } else if (isAfter(new Date(), new Date(endDate))) {
      return CyclesAPI.CycleStatuses.Past;
    }

    return CyclesAPI.CycleStatuses.Open;
  }

  getArchiveCycleStats (cycleId: number) {
    return this.cyclesResources.getArchiveCycleStats(cycleId);
  }

  handleDeleteCycle (cycleId: number) {
    return this.confirmAndTakeActionService.genericTakeAction(
      () => this.cyclesResources.deleteCycle(cycleId),
      this.i18n.translate('common:textSuccessDeleteCycle', {}, 'Successfully deleted the cycle'),
      this.i18n.translate('common:textErrorDeletingCycle', {}, 'There was an error deleting the cycle')
    );
  }

  handleArchiveCycle (cycleId: number, archiveComment: string) {
    return this.confirmAndTakeActionService.genericTakeAction(
      () => this.cyclesResources.archiveCycle(cycleId, archiveComment),
      this.i18n.translate('common:textSuccessArchiveCycle', {}, 'Successfully archived the cycle'),
      this.i18n.translate('common:textErrorArchivingCycle', {}, 'There was an error archiving the cycle')
    );
  }

  handleUnarchiveCycle (cycleId: number) {
    return this.confirmAndTakeActionService.genericTakeAction(
      () => this.cyclesResources.unarchiveCycle(cycleId),
      this.i18n.translate('common:textSuccessUnarchiveCycle', {}, 'Successfully unarchived the cycle'),
      this.i18n.translate('common:textErrorUnarchivingCycle', {}, 'There was an error unarchiving the cycle')
    );
  }

  resetCycleMap () {
    this.set('programCycleMap', {});
  }

  setCycleMap (cycleMap: SimpleStringMap<CyclesAPI.SimpleCycle[]>) {
    this.set('programCycleMap', cycleMap);
  }

  getAllCycles () {
    return this.cyclesResources.getAllCycles();
  }

  getTranslatedCycleStatus (status: CyclesAPI.CycleStatuses) {
    switch (status) {
      case CyclesAPI.CycleStatuses.Open:
        return this.i18n.translate(
          'GLOBAL:textOpen',
          {},
          'Open'
        );
      case CyclesAPI.CycleStatuses.Upcoming:
        return this.i18n.translate(
          'GLOBAL:textUpcoming',
          {},
          'Upcoming'
        );
      case CyclesAPI.CycleStatuses.Past:
        return this.i18n.translate(
          'GLOBAL:textClosed',
          {},
          'Closed'
        );
      default:
        return '';
    }
  }

  async handleBulkCycleUpdate (payload: CyclesAPI.BulkCycleUpdatePayload) {
    let errorMessage = '';
    try {
      await this.cyclesResources.bulkCycleUpdate(payload);
      this.notifier.success(
        this.i18n.translate(
          'PROGRAM:textSuccessfullyUpdatedCycle2',
          {},
          'Successfully updated cycle'
        )
      );
    } catch (e: any) {
      this.logger.error(e);
      this.notifier.error(
        this.i18n.translate(
          'PROGRAM:textErrorUpdatingCycle',
          {},
          'There was an error updating the cycle'
        )
      );
      if (
        e.error.message === 'The specified destination cycle is not valid or doesn\'t exist'
      ) {
        errorMessage = this.i18n.translate(
          'PROGRAM:textNewCycleMustContainTheSameBudgetUsed',
          {},
          'New cycle must contain the budget previously used'
        );
      }
    }

    return errorMessage;
  }

  getCycleDateHelpers (
    cycles: CyclesAPI.BaseProgramCycle[]
  ): CyclesAPI.CycleHelpers {
    let currentCycle: CyclesAPI.BaseProgramCycle;
    cycles.forEach((cycle) => {
      if (this.dateService.isBetween(new Date(), new Date(cycle.startDate), new Date(cycle.endDate))) {
        currentCycle = cycle;
      }
    });
    const upcomingCycles = cycles.filter((a) => {
      return isAfter(new Date(a.startDate), new Date());
    });
    const nextCycle = upcomingCycles.length ?
      upcomingCycles.reduce((next, curr) => {
        return isBefore(new Date(curr.startDate), new Date(next.startDate)) ? curr : next;
      }) :
      undefined;
    const previousCycles = cycles.filter((a) => {
      return isBefore(new Date(a.endDate), new Date());
    });
    const lastCycle = previousCycles.length ?
      previousCycles.reduce((next, curr) => {
        return isAfter(new Date(curr.endDate), new Date(next.endDate)) ? curr : next;
      }) :
      undefined;

    return { currentCycle, nextCycle, lastCycle };
  }

  returnCycleIdsForProgramList (programIds: number[]) {
    const cycleMap = this.get('programCycleMap');
    const cycles: CyclesAPI.SimpleCycle[] = [];
    programIds.forEach((programId) => {
      const cyclesForProg = cycleMap[programId];
      cycles.push(...cyclesForProg);
    });
    const cycleIds: number[] = [];
    cycles.forEach((cycle) => {
      cycleIds.push(cycle.id);
    });

    return cycleIds;
  }

}
