import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { LogicBuilderService } from '../logic-builder.service';
import { RelativeDateCalculationConfig } from '../logic-builder.typing';

@Component({
  selector: 'gc-relative-date-calculator',
  templateUrl: './relative-date-calculator.component.html',
  styleUrls: ['./relative-date-calculator.component.scss']
})
export class RelativeDateCalculatorComponent {
  @Input() calculationOptionsFormGroup: TypeSafeFormGroup<RelativeDateCalculationConfig>;
  @Input() isViewOnly = false;
  @Output() onCalculationChange = new EventEmitter();

  operatorOptions = this.logicBuilderService.operatorOptions;
  constantUnitOptions = this.logicBuilderService.getConstantUnitOptions();

  constructor (
    private logicBuilderService: LogicBuilderService
  ) { }
}
