
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SlotRoutingInterceptor implements HttpInterceptor {
  intercept (request: HttpRequest<any>, next: HttpHandler) {
    const slotName = sessionStorage.getItem('currentAppSlotName');
    if (slotName) {
      let url = request.urlWithParams;
      url += `${url.includes('?') ? '&' : '?'}x-ms-routing-name=${slotName}`;

      request = request.clone({
        url
      });
    }

    return next.handle(request);
  }
}
