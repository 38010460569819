<ng-container *ngIf="isEditMode && !!formGroup"
  [formGroup]="formGroup">
  <!-- Audience Selector -->
  <div *ngIf="supportsAudienceSelector"
    class="mb-3">
    <!-- Audience Selector without Permissions -->
    <yc-typeahead-select *ngIf="!supportsAudiencePermissionSelector"
      [name]="'audienceIds'"
      [(value)]="audienceIds"
      [label]="'common:textAudience' | ycI18n: 'Audience'"
      [items]="audienceOptions"
      [formatAsArray]="true"
      [multi]="supportsMultipleAudiences"
      [help]="audienceHelp"
      (valueChange)="handleAudienceChangeNoPermissions()">
    </yc-typeahead-select>


    <!-- Audience Selector with Permissions -->
    <div *ngIf="supportsAudiencePermissionSelector">
      <!-- Row -->
      <div *ngFor="let audience of audiences; let index = index;"
        class="d-flex align-items-center">
        <!-- Audience Select -->
        <yc-typeahead-select
          class="flex-basis-47 me-2"
          [name]="'audience_' + index"
          [showClear]="true"
          [placeholder]="'common:textSelectAnAudience' | ycI18n: 'Select an audience'"
          [(value)]="audience.audienceId"
          [items]="audienceOptionsMap[index]"
          [label]="('common:textAudience' | ycI18n: 'Audience') + ' ' + (index + 1)"
          (valueChange)="handleAudienceChangeWithPermissions()">
        </yc-typeahead-select>

        <!-- Audience Permission Select -->
        <yc-typeahead-select *ngIf="!!audience.audienceId"
          class="flex-basis-47 me-2"
          [name]="'permission_' + (index)"
          [(value)]="audience[permissionProp]"
          [showClear]="false"
          [label]="('common:textAudience' | ycI18n: 'Audience') + ' ' +
            (index + 1) + ' ' +
            ('common:textPermission' | ycI18n: 'Permission')"
          [items]="permissionOptions"
          (valueChange)="handleAudienceChangeWithPermissions()">
        </yc-typeahead-select>

        <!-- Delete -->
        <yc-button *ngIf="audiences.length > 1"
          class="flex-basis-5 mt-3"
          [icon]="true"
          [fontAwesomeIcon]="'times'"
          [textDanger]="true"
          [tooltipText]="'common:textRemove' | ycI18n: 'Remove'"
          (onClick)="removeAudience(index)">
        </yc-button>
      </div>
      <!-- Add Another -->
      <div>
        <yc-button *ngIf="(audiences.length !== allAudiences.length) && !hasEmptyAudienceSelect"
          [link]="true"
          [fontAwesomeIcon]="'plus'"
          [langKey]="'common:btnSelectAnother'"
          [defaultText]="'Select another'"
          (onClick)="addAudience()">
        </yc-button>
      </div>
    </div>
  </div>

  <!-- User Typeahead -->
  <div class="mb-3">
    <yc-typeahead-select
      name="userSelectEmail"
      [label]="userSelectLabel"
      [showClear]="false"
      [useEmptyArray]="true"
      [items]="userOptions"
      [help]="supportsAudienceSelector ?
        userSelectHelp :
        ('CONFIG:textAllGrantManagersForClient' | ycI18n: 'All grant managers for __companyName__': {
        companyName: companyName
      })"
      (onChange)="onUserChange($event)">
    </yc-typeahead-select>
  </div>

  <div>
    <!-- Add All Users -->
    <yc-button
      [link]="true"
      [langKey]="'CONFIG:textAddAllUsers'"
      [defaultText]="'Add all users'"
      [disabled]="allUsersSelected"
      [placement]="'right'"
      [popoverTemplate]="addAllUsersPopover"
      (onPopoverReady)="onPopoverReady($event)">
    </yc-button>

    <yc-button *ngIf="supportsAudienceSelector"
      [link]="true"
      [langKey]="'common:textManageAudiences'"
      [defaultText]="'Manage audiences'"
      (onClick)="manageAudiences()">
    </yc-button>
  </div>

  <hr>
</ng-container>

<!-- Users Table -->
<div class="pt-1">
  <yc-table
    [key]="tableKey"
    [hideTableOptions]="true"
    [hideTableFilters]="true"
    [rows]="selectedUsers"
    [hoverRows]="true"
    [rowsPerPage]="5"
    [skipAddToState]="true"
    [noTableScrollerClass]="true"
    [topLevelFilters]="topLevelFilters">
    
    <!-- Name -->
    <ng-container *ycTableColumn="'common:lblName';
      prop: 'name',
      filterType: 'text';
      let row;
      let index = index;">
      <ng-container *ngIf="!addingExternalUser ||
        index !== 0">
        {{ row.name }}

        <yc-fa *ngIf="row.isAudienceMember"
          icon="users"
          [light]="true"
          [tooltip]="'GLOBAL:textMemberOfAudienceDynamic' | ycI18n: 'Member of __audienceName__': {
            audienceName: row.audienceNames
          }"
          class="ms-2">
        </yc-fa>
      </ng-container>

      <ng-container *ngIf="isEditMode &&
        externalUserFormGroup &&
        addingExternalUser &&
        index === 0"
        [formGroup]="externalUserFormGroup">
        <yc-input
          name="externalName"
          [label]="'common:textFullName' | ycI18n: 'Full name'"
          [srOnlyLabel]="true"
          [placeholder]="'common:textFullName' | ycI18n: 'Full name'">
        </yc-input>
      </ng-container>
    </ng-container>

    <!-- Email -->
    <ng-container *ycTableColumn="'common:textEmail';
      prop: 'email',
      filterType: 'text';
      let row;
      let index = index;">

      <ng-container *ngIf="!addingExternalUser ||
        index !== 0">
        {{ row.email }}
      </ng-container>

      <ng-container *ngIf="isEditMode &&
        externalUserFormGroup &&
        addingExternalUser &&
        index === 0"
        [formGroup]="externalUserFormGroup">
        <yc-input
          name="externalEmail"
          [label]="'GLOBAL:textEmailAddress' | ycI18n: 'Email address'"
          [srOnlyLabel]="true"
          [placeholder]="'GLOBAL:textEmailAddress' | ycI18n: 'Email address'">
        </yc-input>
      </ng-container>
    </ng-container>

    <!-- User Position -->
    <ng-container *ycTableColumn="'common:textUserPosition';
      prop: 'external',
      visible: allowExternal,
      filterType: 'boolean';
      let row;">
      <yc-i18n *ngIf="!row.external"
        key="common:lblInternal"
        defaultValue="Internal">
      </yc-i18n>
      <yc-i18n *ngIf="row.external"
        key="common:textExternal"
        defaultValue="External">
      </yc-i18n>
    </ng-container>

    <!-- Permission Selector -->
    <ng-container *ycTableColumn="permissionColumnHeaderKey;
      prop: permissionProp,
      visible: includePermissionSelector,
      filterType: 'boolean';
      let row;">

      <ng-container *ngIf="userManageMap">
        <!-- Can select Manage or View -->
        <yc-typeahead-select *ngIf="userManageMap[row.id]"
          [(value)]="row[permissionProp]"
          [label]="permissionColumnHeaderKey | ycI18n"
          [srOnlyLabel]="true"
          [showClear]="false"
          [items]="permissionOptions"
          (valueChange)="setSelectedUsers(selectedUsers)">
        </yc-typeahead-select>

        <!-- No Manage Permission -->
        <yc-i18n *ngIf="!userManageMap[row.id]"
          key="GLOBAL:textViewOnly"
          defaultValue="View only">
        </yc-i18n>
      </ng-container>

      <ng-container *ngIf="!userManageMap">
        <yc-typeahead-select
          [(value)]="row[permissionProp]"
          [showClear]="false"
          [disabled]="supportsAudiencePermissionSelector && row.isAudienceMember"
          [label]="permissionColumnHeaderKey"
          [srOnlyLabel]="true"
          [items]="permissionOptions">
        </yc-typeahead-select>
        
      </ng-container>
    </ng-container>

    <!-- Actions -->
    <ng-container *ycTableColumn="''
      let row;
      let index = index;
      prop: '',
      visible: isEditMode,
      filterType: '',
      class: 'actions',
      locked: true">
      <div class="btn-group" dropdown>
        <!-- Save -->
        <yc-button *ngIf="addingExternalUser &&
          index === 0 &&
          !externalUserFormGroup.invalid"
          [icon]="true"
          [fontAwesomeIcon]="'check'"
          [textSuccess]="true"
          [tooltipText]="'common:btnSave' | ycI18n: 'Save'"
          (onClick)="saveExternalUser()">
        </yc-button>

        <!-- Delete -->
        <yc-button *ngIf="(!addingExternalUser || index === 0) &&
          !row.isAudienceMember"
          [icon]="true"
          [fontAwesomeIcon]="'times'"
          [textDanger]="true"
          [tooltipText]="'common:textRemove' | ycI18n: 'Remove'"
          (onClick)="removeUser(row)">
        </yc-button>
      </div>
    </ng-container>
  </yc-table>
</div>

<yc-button *ngIf="isEditMode && allowExternal && !addingExternalUser"
  [link]="true"
  [fontAwesomeIcon]="'plus'"
  [langKey]="'CONFIG:textAddExternalUser'"
  [defaultText]="'Add external user'"
  (onClick)="addExternalUser()">
</yc-button>


<!-- Add All Users Popover -->
<ng-template #addAllUsersPopover
  let-row="row"
  let-index="index">
  <strong class="mb-3 d-block">
    <yc-i18n
      key="CONFIG:textAddAllUsers"
      defaultValue="Add all users">
    </yc-i18n>
  </strong>
  <span>
    <yc-i18n
      key="common:textAreYouSureAddAllUsers"
      defaultValue="Are you sure you want to add all users?">
    </yc-i18n>
  </span>
  <div class="d-flex justify-content-end align-items-center">
    <!-- No -->
    <yc-button
      class="me-3"
      [link]="true"
      [langKey]="'common:textNo'"
      [defaultText]="'No'"
      (onClick)="allUsersPopover?.hide()">
    </yc-button>

    <!-- Yes -->
    <yc-button
      [primary]="true"
      [langKey]="'common:textYes'"
      [defaultText]="'Yes'"
      (onClick)="addAllUsers()">
    </yc-button>
  </div>
</ng-template>
