import { Injectable } from '@angular/core';
import { PolicyService } from '@core/services/policy.service';
import { AdHocReportingAPI } from '@core/typings/api/ad-hoc-reporting.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { StartFromFormTemplate } from '@features/configure-forms/create-edit-form-modal/create-edit-form-modal.component';
import { BasicForm, BasicFormForUi, FormAudience, FormStates, FormTypes } from '@features/configure-forms/form.typing';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { GCDashboards } from '@features/dashboards/dashboards.typing';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormFieldService } from '@features/form-fields/services/form-field.service';
import { StartFromReportTemplate } from '@features/reporting/pages/manage-ad-hoc-report-modal/manage-ad-hoc-report-modal.component';
import { RootObjectNames } from '@features/reporting/services/ad-hoc-reporting-definitions.service';
import { AdHocReportingMappingService } from '@features/reporting/services/ad-hoc-reporting-mapping.service';
import { AdHocReportingService } from '@features/reporting/services/ad-hoc-reporting.service';
import { UserService } from '@features/users/user.service';
import { PaginationOptions, TableRepositoryFactory } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { isUndefined, uniq } from 'lodash';
import { StandardProductConfigurationResources } from './standard-product-configuration.resources';
import { StandardProductConfigurationState } from './standard-product-configuration.state';
import { CopyStandardDashboardPayload, STANDARD_PRODUCT_FIELDS_KEY, STANDARD_PRODUCT_FORMS_KEY, STANDARD_PRODUCT_REPORTS_KEY, StandardFormTemplate, StandardProductDashboard, StandardProductForm, StandardProductFormField, StandardProductRecordType, StandardProductReport, StandardProductTypes, StandardReportTemplate } from './standard-product-configuration.typing';

@AttachYCState(StandardProductConfigurationState)
@Injectable({ providedIn: 'root' })
export class StandardProductConfigurationService extends BaseYCService<
  StandardProductConfigurationState
> {

  constructor (
    private standardProductResources: StandardProductConfigurationResources,
    private i18n: I18nService,
    private formFieldService: FormFieldService,
    private notifier: NotifierService,
    private logger: LogService,
    private formService: FormsService,
    private arrayHelper: ArrayHelpersService,
    private clientSettingsService: ClientSettingsService,
    private autoTableFactory: TableRepositoryFactory,
    private adHocService: AdHocReportingService,
    private customDataTableService: CustomDataTablesService,
    private adHocMappingService: AdHocReportingMappingService,
    private userService: UserService,
    private policyService: PolicyService,
    private formFieldHelperService: FormFieldHelperService
  ) {
    super();
  }

  get isRootZone () {
    return this.clientSettingsService.clientSettings.isRootClient;
  }

  get isBBGM () {
    return this.clientSettingsService.isBBGM;
  }

  get fileIsStaged () {
    return this.get('fileIsStaged');
  }

  get standardFormTemplates () {
    return this.get('standardFormTemplates');
  }

  get standardReportTemplates () {
    return this.get('standardReportTemplates');
  }

  get standardProductDashboards () {
    return this.get('standardProductDashboards');
  }

  get standardApplicantFormOptions () {
    return this.get('standardApplicantFormOptions');
  }

  get standardManagerFormOptions () {
    return this.get('standardManagerFormOptions');
  }

  get areRecordsAvailableToPush () {
    return this.get('areRecordsAvailableToPush');
  }

  async getFormsForPlatformTool (
    options: PaginationOptions<StandardProductForm>,
    fromPushModal: boolean
  ) {
    const {
      paginationOptions,
      returnOnlyRecordsToBeStaged
    } = this.adaptPaginationOptions(
      options,
      fromPushModal
    );
    const result = await this.standardProductResources.getFormsForPlatformTool(
      paginationOptions,
      returnOnlyRecordsToBeStaged
    );

    return {
      success: true,
      data: {
        recordCount: result.recordCount,
        records: result.records
      }
    };
  }

  async getFieldsForPlatformTool (
    options: PaginationOptions<StandardProductFormField>,
    fromPushModal: boolean
  ) {
    const {
      paginationOptions,
      returnOnlyRecordsToBeStaged
    } = this.adaptPaginationOptions(
      options,
      fromPushModal
    );
    const result = await this.standardProductResources.getFieldsForPlatformTool(
      paginationOptions,
      returnOnlyRecordsToBeStaged
    );

    return {
      success: true,
      data: {
        recordCount: result.recordCount,
        records: result.records
      }
    };
  }

  async getReportsForPlatformTool (
    options: PaginationOptions<StandardProductReport>,
    fromPushModal: boolean
  ) {
    const {
      paginationOptions,
      returnOnlyRecordsToBeStaged
    } = this.adaptPaginationOptions(
      options,
      fromPushModal
    );
    const result = await this.standardProductResources.getReportsForPlatformTool(
      paginationOptions,
      returnOnlyRecordsToBeStaged
    );

    return {
      success: true,
      data: {
        recordCount: result.recordCount,
        records: result.records
      }
    };
  }

  async getDashboardsForPlatformTool (
    options: PaginationOptions<StandardProductDashboard>,
    fromPushModal: boolean
  ) {
    const {
      paginationOptions,
      returnOnlyRecordsToBeStaged
    } = this.adaptPaginationOptions(
      options,
      fromPushModal
    );
    const result = await this.standardProductResources.getDashboardsForPlatformTool(
      paginationOptions,
      returnOnlyRecordsToBeStaged
    );

    return {
      success: true,
      data: {
        recordCount: result.recordCount,
        records: result.records
      }
    };
  }

  adaptPaginationOptions (
    paginationOptions: PaginationOptions<StandardProductRecordType>,
    fromPushModal: boolean
  ) {
    let returnOnlyRecordsToBeStaged = fromPushModal;

    return {
        paginationOptions: {
        ...paginationOptions,
        filterColumns: paginationOptions.filterColumns.filter((col) => {
          if (col.columnName === 'returnOnlyRecordsToBeStaged') {
            returnOnlyRecordsToBeStaged = true;

            return false;
          }

          return true;
        }).map((col) => {
          return {
            ...col
          };
        })
      },
      returnOnlyRecordsToBeStaged
    };
  }

  async handlePublishStandardComponents (
    componentIds: number[],
    type: StandardProductTypes,
    skipToastr = false
  ) {
    try {
      await this.standardProductResources.publishStandardProductComponents(
        componentIds,
        type
      );
      await this.resetAfterPublishUnpublish(type);
      if (!skipToastr) {
        this.notifier.success(
          this.getToastrForPublishUnpublish(true, true, type)
        );
      }
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(
        this.getToastrForPublishUnpublish(false, true, type)
      );
    }
  }

  async handleUnpublishStandardComponents (
    componentId: number,
    type: StandardProductTypes
  ) {
    try {
      await this.standardProductResources.unpublishStandardProductComponent(
        componentId,
        type
      );
      await this.resetAfterPublishUnpublish(type);
      this.notifier.success(
        this.getToastrForPublishUnpublish(true, false, type)
      );
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(
        this.getToastrForPublishUnpublish(false, false, type)
      );
    }
  }


  async resetAfterPublishUnpublish (type: StandardProductTypes) {
    switch (type) {
      case StandardProductTypes.FORMS:
        await this.formService.refreshForms();
        break;
      case StandardProductTypes.FORM_FIELDS:
        await this.formFieldService.resetFieldsAndCategories();
        break;
      case StandardProductTypes.REPORTS:
        await this.adHocService.fetchReports();
        break;
    }
  }

  getToastrForPublishUnpublish (
    success: boolean,
    isPublish: boolean,
    type: StandardProductTypes
  ) {
    switch (type) {
      case StandardProductTypes.FORMS:
        if (success) {
          return this.i18n.translate(
            isPublish?
              'common:textSuccessPublishForm' :
              'common:textSuccessUnpublishForm',
            {},
            isPublish ?
              'Successfully published the form(s)' :
              'Successfully unpublished the form'
          );

        } else {
          return this.i18n.translate(
            isPublish?
              'common:textErrorPublishForm' :
              'common:textErrorUnpublishForm',
            {},
            isPublish ?
              'There was an error publishing the form(s)' :
              'There was an error unpublishing the form'
          );
        }
      case StandardProductTypes.FORM_FIELDS:
        if (success) {
          return this.i18n.translate(
            isPublish?
              'common:textSuccessPublishField' :
              'common:textSuccessUnpublishField',
            {},
            isPublish ?
              'Successfully published the form field(s)' :
              'Successfully unpublished the form field'
          );

        } else {
          return this.i18n.translate(
            isPublish?
              'common:textErrorPublishField' :
              'common:textErrorUnpublishField',
            {},
            isPublish ?
              'There was an error publishing the field(s)' :
              'There was an error unpublishing the field'
          );
        }
      case StandardProductTypes.REPORTS:
        if (success) {
          return this.i18n.translate(
            isPublish?
              'common:textSuccessPublishReport' :
              'common:textSuccessUnpublishReport',
            {},
            isPublish ?
              'Successfully published the form report(s)' :
              'Successfully unpublished the form report'
          );

        } else {
          return this.i18n.translate(
            isPublish?
              'common:textErrorPublishReport' :
              'common:textErrorUnpublishReport',
            {},
            isPublish ?
              'There was an error publishing the report(s)' :
              'There was an error unpublishing the report'
          );
        }
      case StandardProductTypes.DASHBOARDS:
        if (success) {
          return this.i18n.translate(
            isPublish?
              'common:textSuccessPublishDashboard' :
              'common:textSuccessUnpublishDashboard',
            {},
            isPublish ?
              'Successfully published the dashboard' :
              'Successfully unpublished the dashboard'
          );

        } else {
          return this.i18n.translate(
            isPublish?
              'common:textErrorPublishDashboard' :
              'common:textErrorUnpublishDashboard',
            {},
            isPublish ?
              'There was an error publishing the dashboard' :
              'There was an error unpublishing the dashboard'
          );
        }
    }
  }

  adaptStandardProductDashboardWidgets (
    oldWidgets: GCDashboards.WidgetConfigFromApi[],
    dashboardId: number
  ): GCDashboards.CreateWidgetPayload[]  {
    const adaptedList = oldWidgets.map((widget) => {
      const adaptedWidget: AdHocReportingAPI.CreateChartPayload = {
        ...widget,
        reportType: AdHocReportingAPI.AdHocReportType.Chart,
        formIds: widget.forms.map((form => form.id)),
        reportUsers: [],
        referenceFieldTableId: null
      };

      return {
        widget: adaptedWidget,
        dashboardId
      };
    });

    return adaptedList;
  }

  adaptForCopyStandardDashboard (
    dashboardDetail: GCDashboards.DashboardDetail,
    dashboardId: number,
    order: number,
    dashboardName: string
  ): CopyStandardDashboardPayload {
    const adaptedWidgets = this.adaptStandardProductDashboardWidgets(
      dashboardDetail.widgets,
      dashboardId
    );
    const adaptedDashboard = {
      dashboard: {
        name: dashboardName,
        order
      },
      widgets: adaptedWidgets
    };

    return adaptedDashboard;
  }

  async setStandardFormTemplates () {
    if (!this.standardFormTemplates) {
      let templates: StandardFormTemplate[] = [];
      if (!this.clientSettingsService.clientSettings.isRootClient && !this.isBBGM) {
        templates = await this.standardProductResources.getStandardFormTemplates();
      }
      this.set('standardFormTemplates', templates);
      this.formService.setStandardFormTemplates(templates);
      this.setFormOptionsByAudience(FormAudience.MANAGER);
      this.setFormOptionsByAudience(FormAudience.APPLICANT);
    }
  }

  async setStandardProductDashboardTemplates () {
    const canManageDashboards = this.policyService.dataExport.canManageDashboards();

    let adaptedDashboards = null;
    if (canManageDashboards && !this.isBBGM) {
     const standardProductDBs = await this.standardProductResources.getStandardProductDashboards();
     adaptedDashboards = standardProductDBs.map((db) => {
       return {
         ...db,
         isStandardDashboardPublished: true
       };
     });
    };
    this.set('standardProductDashboards', adaptedDashboards || []);
  }

  async setStandardReportTemplates () {
    if (!this.standardReportTemplates) {
      let templates: StandardReportTemplate[] = [];
      if (!this.clientSettingsService.clientSettings.isRootClient && !this.isBBGM) {
        const canManageAdHoc = this.policyService.dataExport.canManageAdHoc();
        if (canManageAdHoc) {
          templates = await this.standardProductResources.getStandardReportTemplates();
          templates.forEach((template) => {
            // referenceFieldTableId will be the ID from the root zone
            // Use the referenceFieldTableKey to adapt to the correct ID
            const field = this.formFieldHelperService.referenceFieldMap[
              template.referenceFieldTableKey
            ];
            if (field) {
              template.referenceFieldTableId = field.referenceFieldTableId;
            }
            template.isStandardReportTemplate = true;
          });
        }
      }
      // filter out the non-adhoc report templates here
      const adHocReportTemplates = templates.filter((temp) => {
        return temp.reportType === AdHocReportingAPI.AdHocReportType.AdHoc;
      });
      this.set('standardReportTemplates', adHocReportTemplates);
    }
  }

  setFormOptionsByAudience (formAudience: FormAudience) {
    const formOptions = this.standardFormTemplates.filter((template) => {
      const audience = this.formService.getAudienceFromFormType(template.formTypeId);

      return audience === formAudience;
    }).map((template) => {
      return {
        label: template.name,
        value: template.formId
      };
    });

    if (formAudience === FormAudience.MANAGER) {
      this.set('standardManagerFormOptions', formOptions);
    } else {
      this.set('standardApplicantFormOptions', formOptions);
    }
  }

  getFormsAndStandardTemplates (): BasicFormForUi[] {
    const adaptedTemplates = this.standardFormTemplates.map<BasicFormForUi>((template) => {
      const basicForm: BasicForm = {
        ...template,
        formType: template.formTypeId,
        canBeRemoved: false,
        canModify: false,
        availableForTranslation: true,
        programIds: [],
        standardComponentIsPublished: false,
        revisions: [],
        isDraft: false
      };

      return {
        ...basicForm,
        state: FormStates.PRODUCT_TEMPLATE,
        isStandardFormTemplate: true,
        showRemoveRevision: false,
        showRemoveForm: false,
        canPublishFromRootZone: false,
        formAndRevisionId: ''
      };
    });

    return this.arrayHelper.sort([
      ...this.formService.forms,
      ...adaptedTemplates
    ], 'name');
  }

  getReportsAndStandardTemplates (): AdHocReportingAPI.UserSavedReportForUi[] {
    const reports = this.adHocService.reports;
    const templates = this.standardReportTemplates;

    return [
      ...reports.map((report) => {
        return {
          ...report,
          isStandardReportTemplate: false,
          isMyReport: report.isUserOwnedReport || report.isShared
        };
      }),
      ...templates.map((report) => {
        return {
          ...report,
          isStandardReportTemplate: true,
          isMyReport: false
        };
      })
    ];
  }

  getFormTemplateOptionsByAudience (
    formAudience: FormAudience,
    formType?: FormTypes
  ): TypeaheadSelectOption<StartFromFormTemplate>[] {
    return this.arrayHelper.sort(
      this.standardFormTemplates.filter((template) => {
        const audience = this.formService.getAudienceFromFormType(
          template.formTypeId
        );

        const passesAudience = audience === formAudience;
        let passesFormType = true;
        if (formType) {
          passesFormType = template.formTypeId === formType;
        }

        return passesAudience && passesFormType;
      }).map<TypeaheadSelectOption<StartFromFormTemplate>>((template) => {
        return {
          label: template.name,
          value: {
            name: template.name,
            formId: template.formId,
            revisionId: template.revisionId,
            defaultFormLang: template.defaultLanguageId,
            description: template.description
          }
        };
      }),
      'label'
    );
  }

  getReportTemplateOptionsByObject (
    object: RootObjectNames
  ): TypeaheadSelectOption<StartFromReportTemplate>[] {
    return this.arrayHelper.sort(
      this.standardReportTemplates.filter((template) => {
        const _object = this.adHocMappingService.getObjectByReportModelType(
          template.reportModelType
        );

        return object === _object;
      }).map<TypeaheadSelectOption<StartFromReportTemplate>>((template) => {
        return {
          label: template.name,
          value: template
        };
      }),
      'label'
    );
  }

  resetStandardProductTables () {
    const formsTable = this.autoTableFactory.getRepository(
      STANDARD_PRODUCT_FORMS_KEY
    );
    const formFieldsTable = this.autoTableFactory.getRepository(
      STANDARD_PRODUCT_FIELDS_KEY
    );
    const reportsTable = this.autoTableFactory.getRepository(
      STANDARD_PRODUCT_REPORTS_KEY
    );
    if (formsTable) {
      formsTable.reset();
    }
    if (formFieldsTable) {
      formFieldsTable.reset();
    }
    if (reportsTable) {
      reportsTable.reset();
    }
  }

  async handlePushToProduction () {
    try {
      await this.standardProductResources.pushToProduction();
      await this.resetAreRecordsAvailableToPush();
      this.resetStandardProductTables();
      this.notifier.success(this.i18n.translate(
        'common:textSuccessPushToProd',
        {},
        'Successfully pushed to production'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorPushToProd',
        {},
        'There was an error pushing to production'
      ));
    }
  }

  async handleImportFile () {
    try {
      await this.standardProductResources.processStagedFile();
      await this.resetIsFileStaged();
      this.resetStandardProductTables();
      this.notifier.success(this.i18n.translate(
        'common:textSuccessProcessFile',
        {},
        'Successfully processed the file'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorProcessingFile',
        {},
        'There was an error processing the file'
      ));
    }
  }

  getFormTypeFromFormId (formId: number) {
    const found = this.standardFormTemplates.find((form) => {
      return +form.formId === +formId;
    });

    return found ? found.formTypeId : null;
  }

  async resetAreRecordsAvailableToPush () {
    this.set('areRecordsAvailableToPush', undefined);
    await this.setRecordsAvailableToPush();
  }

  async resetIsFileStaged () {
    this.set('fileIsStaged', undefined);
    await this.retrieveStagedFileInfo();
  }

  async retrieveStagedFileInfo () {
    if (isUndefined(this.fileIsStaged)) {
      const result = await this.standardProductResources.retrieveStagedFileInfo();
      this.set('fileIsStaged', result?.fileIsStaged ?? false);
    }
  }

  async setRecordsAvailableToPush () {
    if (isUndefined(this.areRecordsAvailableToPush)) {
      const paginationOptions: PaginationOptions<StandardProductRecordType> = {
        rowsPerPage: 1,
        pageNumber: 1,
        sortColumns: [],
        filterColumns: [],
        retrieveTotalRecordCount: true,
        returnAll: false
      };
      let hasRecords = false;
      await Promise.all([
        StandardProductTypes.FORMS,
        StandardProductTypes.FORM_FIELDS,
        StandardProductTypes.REPORTS,
        StandardProductTypes.DASHBOARDS
      ].map(async (type) => {
        switch (type) {
          case StandardProductTypes.FORMS:
            const forms = await this.getFormsForPlatformTool(
              paginationOptions,
              true
            );
            if (forms.data.recordCount > 0) {
              hasRecords = true;
            }
            break;
          case StandardProductTypes.FORM_FIELDS:
            const fields = await this.getFieldsForPlatformTool(
              paginationOptions,
              true
            );
            if (fields.data.recordCount > 0) {
              hasRecords = true;
            }
            break;
          case StandardProductTypes.REPORTS:
            const reports = await this.getReportsForPlatformTool(
              paginationOptions,
              true
            );
            if (reports.data.recordCount > 0) {
              hasRecords = true;
            }
            break;
          case StandardProductTypes.DASHBOARDS:
            const dashboards = await this.getDashboardsForPlatformTool(
              paginationOptions,
              true
            );
            if (dashboards.data.recordCount > 0) {
              hasRecords = true;
            }
            break;
        }
      }));

      this.set('areRecordsAvailableToPush', hasRecords);
    }
  }

  getReportTemplateById (reportId: number) {
    return this.standardReportTemplates.find((template) => {
      return template.id === reportId;
    });
  }

  /**
   *
   * @param template the standard report template to fetch options for
   */
  async prepareCdtOptionsForReportTemplate (
    template: AdHocReportingAPI.UserSavedReportForUi
  ) {
    let guidsToFetch: string[] = [];
    template?.userSavedReportColumns.forEach((column) => {
      const refField = this.adHocMappingService.getRefFieldByColumnName(
        column.columnName
      );
      if (refField?.customDataTableGuid) {
        guidsToFetch.push(refField.customDataTableGuid);
      }
    });

    guidsToFetch = uniq(guidsToFetch);

    await this.customDataTableService.setCdtOptionsFromGuids(
      guidsToFetch,
      this.userService.getCurrentUserCulture()
    );
  }
}
