<!-- Regular Expression Pattern -->
<div>
  <yc-input *ngIf="!formatTypeName"
    name="pattern"
    [disabled]="isViewOnly"
    [label]="'common:textRegularExpressionPatternDefault' | ycI18n: 'Regular expression pattern'"
    [(value)]="pattern"
    (valueChange)="onChange.emit($event)">
  </yc-input>

  <!-- If field has formatType, disable and display the name of format type -->
  <yc-input *ngIf="formatTypeName"
    name="pattern"
    [disabled]="isViewOnly"
    [label]="'common:textRegularExpressionPatternDefault' | ycI18n: 'Regular expression pattern'"
    [value]="formatTypeName"
    [disabled]="true">
  </yc-input>
</div>