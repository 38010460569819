import { Injectable } from '@angular/core';
import { StaticResourceState } from '@core/states/static-resource.state';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { HttpRestService } from './http-rest.service';


interface StaticResourceResponse {
  originalFileName: string;
  extension: string;
  contentType: string;
  fileName: string;
  uploadUrl: string;
  fileUrl: string;
}

@Injectable({ providedIn: 'root' })
@AttachYCState(StaticResourceState)
export class StaticResourceService extends BaseYCService<StaticResourceState> {

  constructor (
    private httpRestService: HttpRestService
  ) {
    super();
  }

  get sas () {
    return this.get('sas');
  }

  async uploadManagerImage (
    file: Blob,
    fileName: string
  ): Promise<StaticResourceResponse> {
    const endpoint = 'api/staticresources/manager/uploads/image';

    const response = await this.httpRestService.postFile(
      endpoint,
      file,
      'file',
      fileName
    );

    return response;
  }

  async uploadPortalImage (
    file: Blob,
    fileName: string
  ): Promise<StaticResourceResponse> {
    const endpoint = 'api/staticresources/portal/uploads/image';

    const response = await this.httpRestService.postFile(
      endpoint,
      file,
      'file',
      fileName
    );

    return response;
  }

  async getSAS () {
    if (!this.sas) {
      const response = await this.httpRestService.get<{ token: string }>(
        'api/staticresources/GetStaticContentAccess'
      );

      this.set('sas', response.token);
    }

    return this.sas;
  }
}

