import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { ComponentToSpellCheck } from '../../form-builder.typing';

@Injectable({ providedIn: 'root' })
export class FormBuilderResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  doSpellCheckOfFormComponents (components: ComponentToSpellCheck[]): Promise<ComponentToSpellCheck[]>  {
    const endpoint = 'api/manager/forms/FormOpenAIProof';

    return this.httpRestService.post(endpoint, {
      requestModel: components
    });
  }

}

