import { Injectable } from '@angular/core';
import { GcFlyoutService } from '@core/services/gc-flyout.service';
import { ALL_SKIP_FILTER, PaginationOptions, SimpleStringMap, TopLevelFilter } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { FlyoutService } from '@yourcause/common/flyout';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmAndTakeActionService, ModalFactory } from '@yourcause/common/modals';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { AudienceDetailFlyoutComponent } from './audience-detail-flyout/audience-detail-flyout.component';
import { AudienceModalComponent } from './audience-modal/audience-modal.component';
import { AudienceResources } from './audience.resources';
import { AudienceState } from './audience.state';
import { Audience, AudienceModalResponse, AudiencePermissionAttrs, AudienceUsage, Audience_Table_Key, UsageType } from './audience.typing';

@AttachYCState(AudienceState)
@Injectable({ providedIn: 'root' })
export class AudienceService extends BaseYCService<AudienceState> {

   constructor (
    private audienceResources: AudienceResources,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private confirmAndTakeActionService: ConfirmAndTakeActionService,
    private gcFlyoutService: GcFlyoutService,
    private flyoutService: FlyoutService,
    private modalFactory: ModalFactory
  ) {
    super();
  }

  get allAudiences () {
    return this.get('allAudiences');
  }

  get audienceDetailMap () {
    return this.get('audienceDetailMap');
  }

  get audienceSimpleMap () {
    return this.get('audienceSimpleMap');
  }

  /**
   * Get Top Level Filters for Manage Audience Table
   *
   * @param forExternal: For External/Internal Filter
   * @param permissionProp: Permission Property
   * @param permissionOptions: Permission Options
   * @returns top level fitlers for the manage audience table
   */
  getTopLevelFiltersForUserAudienceTable (
    forExternal: boolean,
    permissionProp: keyof AudiencePermissionAttrs,
    permissionOptions: TypeaheadSelectOption[] = []
  ) {
    let options = permissionOptions ?? [];
    if (forExternal) {
      options = [{
        label: this.i18n.translate(
          forExternal ? 'common:lblInternal' : 'GLOBAL:textViewOnly',
          {},
          forExternal ? 'Internal' : 'View only'
        ),
        value: false
      }, {
        label: this.i18n.translate(
          forExternal ? 'common:textExternal' : 'APPLY:textManage',
          {},
          forExternal ? 'External' : 'Manage'
        ),
        value: true
      }];
    }

    return [
      new TopLevelFilter(
        'text',
        'nameOrEmail',
        '',
        this.i18n.translate(
          'GLOBAL:textSearchByNameOrEmail',
          {},
          'Search by name or email'
        ),
        undefined,
        undefined,
        [{
          column: 'name',
          filterType: 'cn'
        }, {
          column: 'email',
          filterType: 'cn'
        }]
      ),
      options.length > 0 ?
        new TopLevelFilter(
          'typeaheadSingleEquals',
          forExternal ? 'external' : permissionProp,
          ALL_SKIP_FILTER,
          '',
          {
            selectOptions: [{
              label: this.i18n.translate(
                'common:lblAllCap',
                {},
                'All'
              ),
              value: ALL_SKIP_FILTER
            },
              ...options
            ]
          },
          this.i18n.translate(
            forExternal ? 'common:textUserPosition' : 'common:textPermission',
            {},
            forExternal ? 'User position' : 'Permission'
          )
      ) :
      undefined
    ].filter((item) => !!item);
  }

  /**
   * Set All Audiences
   */
  async setAllAudiences () {
    if (!this.allAudiences) {
      const options: PaginationOptions<Audience> = {
        returnAll: true,
        retrieveTotalRecordCount: false,
        filterColumns: [],
        sortColumns: [],
        rowsPerPage: 1000000,
        pageNumber: 0
      };
      const response = await this.audienceResources.getAudiencesPaginated(options);
      this.set(
        'allAudiences',
        this.arrayHelper.sort(response.records, 'name')
      );
      const audienceSimpleMap: SimpleStringMap<Audience> = {};
      response.records.forEach((audience) => {
        audienceSimpleMap[audience.id] = audience;
      });
      this.set('audienceSimpleMap', audienceSimpleMap);
    }
  }

  /**
   * Reset All Audiences
   */
  async resetAllAudiences (audienceId?: number) {
    if (!!this.allAudiences) {
      this.set('allAudiences', undefined);
      await this.setAllAudiences();
    }
    if (!!audienceId) {
      await this.resetAudienceDetail(audienceId);
    }
  }

  /**
   * Set Audience Detail
   *
   * @param id: Audience ID
   */
  async setAudienceDetail (id: number) {
    const audience = this.audienceSimpleMap[id];
    if (!this.audienceDetailMap[id]) {
      const [
        members,
        usage
      ] = await Promise.all([
        this.audienceResources.getAudienceMembers(id),
        this.audienceResources.getAudienceUsage(id)
      ]);
      const detail = {
        ...audience,
        members,
        usage
      };
      this.set('audienceDetailMap', {
        ...this.audienceDetailMap,
        [id]: detail
      });
    }
  }

  /**
   * Reset Audience Detail
   *
   * @param audience: Audience
   */
  async resetAudienceDetail (audienceId: number) {
    const audience = this.audienceSimpleMap[audienceId];
    if (!!this.audienceDetailMap[audience.id]) {
      this.set('audienceDetailMap', {
        ...this.audienceDetailMap,
        [audience.id]: undefined
      });
      await this.setAudienceDetail(audienceId);
    }
  }

  /**
   * Reset the Audience Detail Map
   */
  resetAudienceDetailMap () {
    this.set('audienceDetailMap', {});
  }

  /**
   * Create or Edit an Audience
   *
   * @param response: Audience Modal Response
   */
  async createOrEditAudience (
    response: AudienceModalResponse
  ) {
    await this.audienceResources.createOrEditAudience(response);
    await this.resetAllAudiences(response.id);
  }

  /**
   * Handles the Audience Modal
   *
   * @param audience: Audience
   */
  async handleAudienceModal (audience?: Audience) {
    this.modalFactory.setHideComponentDisplayModal(true);
    const response = await this.modalFactory.open(
      AudienceModalComponent,
      {
        audience
      }
    );
    this.modalFactory.setHideComponentDisplayModal(false);
    if (!!response) {
      await this.confirmAndTakeActionService.genericTakeAction(
        () => this.createOrEditAudience(response),
        this.i18n.translate(
          !response.id ?
            'CONFIG:textSuccessfullyCreatedAudience' :
            'CONFIG:textSuccessfullyUpdatedAudience',
          {},
          !response.id ?
            'Successfully created the audience' :
            'Successfully updated the audience'
        ),
        this.i18n.translate(
          !response.id ?
            'CONFIG:textErrorCreatingTheAudience' :
            'CONFIG:textErrorUpdatingTheAudience',
          {},
          !response.id ?
            'There was an error creating the audience' :
            'There was an error updating the audience'
        )
      );
    }
  }

  /**
   * Deletes the audience
   *
   * @param id: Audience ID to Delete
   */
  async doDeleteAudience (id: number) {
    await this.audienceResources.deleteAudience(id);
    await this.resetAllAudiences();
  }

  /**
   * Handle Deleting an Audience
   * 
   * @param audience: Audience to Delete
   */
  async handleDelete (audience: Audience) {
    this.modalFactory.setHideComponentDisplayModal(true);
    await this.confirmAndTakeActionService.genericConfirmAndTakeAction(
      () => this.doDeleteAudience(audience.id),
      this.i18n.translate(
        'CONFIG:hdrDeleteAudience',
        {},
        'Delete Audience'
      ),
      audience.name,
      this.i18n.translate(
        'CONFIG:textDeleteAudienceDesc2',
        {},
        'Deleting the audience will prevent it from being used for scheduled or manually sent reports and assiging to workflow levels. Are you sure you want to delete this audience? This action can not be undone.'
      ),
      this.i18n.translate(
        'common:btnDelete',
        {},
        'Delete'
      ),
      this.i18n.translate(
        'CONFIG:textSuccessfullyDeletedTheAudience',
        {},
        'Successfully deleted the audience'
      ),
      this.i18n.translate(
        'CONFIG:textErrorDeletingTheAudience',
        {},
        'There was an error deleting the audience'
      )
    );
    this.modalFactory.setHideComponentDisplayModal(false);
  }

  /**
   * Is this audience in use?
   */
  hasUsage (usage: AudienceUsage) {
    return usage.accountsPayableSettings.length > 0 ||
      usage.workflowLevels.length > 0 ||
      usage.scheduledReports.length > 0;
  }

  /**
   * Removes a user from an audience
   *
   * @param userId: User ID
   * @param audienceId: Audience ID
   */
  async removeUserFromAudience (
    userId: number,
    audienceId: number
  ) {
    await this.audienceResources.removeUserFromAudience(userId, audienceId);
    await this.resetAllAudiences(audienceId)
  }

  /**
   * Handles Removing a User fron an Audience
   *
   * @param userId: User ID to remove from audience
   * @param audienceId: Audience ID
   * @returns if passed
   */
  async handleRemoveUserFromAudience (
    userId: number,
    audienceId: number
  ) {
    const result = await this.confirmAndTakeActionService.genericTakeAction(
      () => this.removeUserFromAudience(userId, audienceId),
      this.i18n.translate(
        'common:textSuccessRemoveUserFromAudience',
        {},
        'Successfully removed user from audience'
      ),
      this.i18n.translate(
        'common:textErrorRemovingUserFromAudience',
        {},
        'There was an error removing the user from the audience'
      )
    );

    return result.passed;
  }

  /**
   * Adapt Audience Usage for Display
   * 
   * @param audienceUsage: Audience Usage
   * @returns adapted usage for display
   */
  adaptAudienceUsage (audienceUsage: AudienceUsage) {
    return [
      ...this.arrayHelper.sort(audienceUsage.workflowLevels.map((level) => {
        return {
          usageName: level.workflowLevelName,
          usageSubName: level.workflowName,
          usageType: UsageType.Workflow,
          usageTypeTranslated: this.i18n.translate('common:lblWorkflowLevel', {}, 'Workflow level')
        };
      }), 'usageName'),
      ...this.arrayHelper.sort(audienceUsage.scheduledReports.map((report) => {
        return {
          usageName: report.reportName,
          usageType: UsageType.Reports,
          usageTypeTranslated: this.i18n.translate('common:textScheduledReport', {}, 'Scheduled report')
        };
      }), 'usageName'),
      audienceUsage.accountsPayableSettings.length > 0 ?
        {
          usageName: this.i18n.translate(
            'common:textReceivesApExportEmails',
            {},
            'Receives Accounts Payable export emails'
          ),
          usageType: UsageType.ApConfig,
          usageTypeTranslated: this.i18n.translate('common:textAPConfig', {}, 'Accounts payable configuration')
        } :
        undefined
    ].filter((item) => !!item);
  }

  /**
   * Opens the user flyout for the given record
   *
   * @param audience: Audience to open flyout for
   */
  async openAudienceFlyout (audience: Audience): Promise<void> {
    this.gcFlyoutService.setInfoForFlyout(audience, Audience_Table_Key, 'id');
    const only1Record = this.gcFlyoutService.idsForFlyout.length === 1;
    await this.flyoutService.openFlyout(
      AudienceDetailFlyoutComponent,
      {
        showIterator: !only1Record
      },
      this.gcFlyoutService.onNextFlyoutRecord,
      this.gcFlyoutService.onPreviousFlyoutRecord,
      this.prepareAudienceFlyout,
      this.gcFlyoutService.onInitialFlyoutRecord
    );
  }

  /**
   * Prepare the Audience Flyout
   */
  prepareAudienceFlyout = async (id: number|string) => {
    await this.setAudienceDetail(id as number);
  };
}


