import { AdHocReportingAPI } from '@core/typings/api/ad-hoc-reporting.typing';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { FormAudience, FormTypes } from '@features/configure-forms/form.typing';
import { GCDashboards } from '@features/dashboards/dashboards.typing';
export const STANDARD_PRODUCT_FORMS_KEY = 'STANDARD_PRODUCT_FORMS';
export const STANDARD_PRODUCT_FIELDS_KEY = 'STANDARD_PRODUCT_FIELDS';
export const STANDARD_PRODUCT_REPORTS_KEY = 'STANDARD_PRODUCT_REPORTS';
export const STANDARD_PRODUCT_DASHBOARDS_KEY = 'STANDARD_PRODUCT_DASHBOARDS_KEY';

export enum StandardProductTypes {
  FORMS = 1,
  FORM_FIELDS = 2,
  REPORTS = 3,
  DASHBOARDS = 4
}

export interface StandardProductRecord {
  id: number;
  name: string;
  description: string;
  formAudience: FormAudience;
  lastSyncDate: string;
  updatedDate: string;
}

export interface StandardProductFormField extends StandardProductRecord {
  referenceFieldType: ReferenceFieldsUI.ReferenceFieldTypes;
  aggregateType: ReferenceFieldAPI.ReferenceFieldAggregateType; // still need
  categoryId: number;
  categoryName: string;
}

export interface StandardProductFormFieldForUi extends StandardProductFormField {
  statusIcon: string;
  statusIconClass: string;
  statusText: string;
  statusDate: string;
}

export interface StandardProductReport extends StandardProductRecord {
  reportType: AdHocReportingAPI.AdHocReportType;
  modalType: AdHocReportingAPI.AdHocReportModelType;
  chartType: AdHocReportingAPI.ChartType;
}

export interface StandardProductForm extends StandardProductRecord {
  revisionVersion: number;
  formType: string;
}

export type StandardProductRecordType = StandardProductFormField|StandardProductForm|StandardProductReport|StandardProductDashboard;

export interface StandardFormTemplate {
  name: string;
  description: string;
  createdDate: string;
  updatedDate: string;
  revisionId: number;
  revisionVersion: number;
  formId: number;
  formTypeId: FormTypes;
  defaultLanguageId: string;
}

export type StandardReportTemplate = AdHocReportingAPI.UserSavedReportForUi;

export interface CopyStandardDashboardPayload {
  dashboard: {
    name: string;
    order: number;
  };
  widgets: GCDashboards.CreateWidgetPayload[];
}

export interface StandardProductDashboard {
  dashboardId: number;
  name: string;
  order: number;
  dashboardType: GCDashboards.DashboardTypes;
  createdDate: string;
  updatedDate: string;
  createdBy: string;
  updatedBy: string;
  isDashboardOwner: boolean;
  numberOfUsersSharedWith: number;
  isActive: boolean;
  createdByFirstName: string;
  createdByLastName: string;
  isHidden: boolean;
  isStandardDashboardPublished?: boolean;
}

