<!-- Currency Options -->
<div [class.mb-3]="useCustomCurrency === CurrencyRadioOptions.USE_ONE_CURRENCY">
  <yc-typeahead-select
    name="currencyType"
    [items]="options"
    [(value)]="useCustomCurrency"
    [disabled]="isViewOnly"
    [showClear]="false"
    [label]="'common:textCurrencyType' | ycI18n: 'Currency type'"
    (valueChange)="emitChange()">
  </yc-typeahead-select>
</div>

<div *ngIf="useCustomCurrency === CurrencyRadioOptions.USE_ONE_CURRENCY">
  <!-- Custom Currency -->
  <yc-typeahead-select
    name="currency"
    [items]="currencyOptions"
    [showClear]="false"
    [disabled]="isViewOnly"
    [(value)]="customCurrency"
    [label]="'common:textCurrency' | ycI18n: 'Currency'"
    (valueChange)="emitChange()">
  </yc-typeahead-select>
</div>