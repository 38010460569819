<div [formGroup]="formGroup" 
  class="condition-container">
  <div class="d-flex">
    <!-- Source Column -->
    <div class="source-column">
      <yc-typeahead-select
        name="sourceColumn"
        [disabled]="isViewOnly"
        [srOnlyLabel]="true"
        [showClear]="false"
        [placeholder]="'common:textSelectAField' | ycI18n: 'Select a field'"
        [label]="'common:textColumn' | ycI18n: 'Column'"
        [items]="columnOptions"
        (onChange)="onColumnChange()">
      </yc-typeahead-select>
    </div>

    <!-- Comparison Column -->
    <div class="comparison-column">
      <yc-typeahead-select *ngIf="sourceColumn"
        name="comparison"
        [srOnlyLabel]="true"
        [disabled]="isViewOnly"
        [showClear]="false"
        [placeholder]="'common:textSelectAComparison' | ycI18n: 'Select a comparison'"
        [label]="'common:labelComparison' | ycI18n: 'Comparison'"
        [items]="comparisonOptions"
        (onChange)="setFilterOption(false)">
      </yc-typeahead-select>
    </div>

    <!-- Value Column -->
    <div class="value-type-column">
      <ng-container *ngIf="sourceColumnDisplay && apiComparison">
        <!-- Type of value (static value or based on other comp) -->
        <yc-typeahead-select *ngIf="(otherComponentOptions | ycShowOtherColumnSelector: apiComparison) ||
          sourceColumnIsDate"
          name="resultType"
          [disabled]="isViewOnly"
          [showClear]="false"
          [label]="'common:lblTypeOfValue' | ycI18n: 'Type of value'"
          [srOnlyLabel]="true"
          [items]="resultTypeOptions"
          (onChange)="handleResultTypeChange($event)">
        </yc-typeahead-select>

        <ng-container *ngIf="!(otherComponentOptions | ycShowOtherColumnSelector: apiComparison) &&
          !sourceColumnIsDate">
          <ng-template 
            *ngTemplateOutlet="individualFilterValue">
          </ng-template>
        </ng-container>
      </ng-container>
    </div>

    <!-- Delete Condition -->
    <div class="d-flex align-items-center">
      <yc-button  
        [fontAwesomeIcon]="'times'"
        [textDanger]="true"
        [disabled]="isViewOnly"
        [tooltipText]="'common:textDeleteCondition' | ycI18n: 'Delete condition'"
        [icon]="true"
        (onClick)="handleDeleteClick()">
      </yc-button>
    </div>
  </div>

  <!-- Value of Condition -->
  <ng-container *ngIf="sourceColumnDisplay &&
    apiComparison &&
    (
      (otherComponentOptions | ycShowOtherColumnSelector: apiComparison) ||
      sourceColumnIsDate
    )">
    <div class="d-flex">
      <div *ngIf="resultType === ConditionalLogicResultType.STATIC_VALUE"
        class="flex-basis-30">
        <ng-template 
          *ngTemplateOutlet="individualFilterValue">
        </ng-template>
      </div>

      <yc-typeahead-select *ngIf="resultType === ConditionalLogicResultType.OTHER_COLUMN" 
        class="flex-basis-30"
        [disabled]="isViewOnly"
        name="relatedColumn"
        [label]="'common:textValueFromAnotherComponent' | ycI18n: 'Value from another component'"
        [srOnlyLabel]="true"
        [items]="otherComponentOptions"
        [showClear]="false">
      </yc-typeahead-select>

      <gc-relative-date-calculator *ngIf="sourceColumnIsDate &&
        enableDateCalculations"
        class="d-flex"
        [class.ms-2]="resultType !== ConditionalLogicResultType.RELATIVE_DATE"
        [isViewOnly]="isViewOnly"
        [calculationOptionsFormGroup]="calculationOptionsFormGroup"
        (onCalculationChange)="emitCondition()">
      </gc-relative-date-calculator>
    </div>

    <div *ngIf="sourceColumnIsDate">
      <yc-checkbox
        name="applyCalculation"
        [disabled]="isViewOnly"
        [srOnlyLabel]="true"
        [label]="'common:textCheckboxForCalculation' | ycI18n: 'Checkbox for whether a calculation should be applied'"
        [optionLabel]="'common:lblDateCalculationLabel' | ycI18n: 'Add or subtract time from the selected date'"
        (onCalculationChange)="emitCondition()">
      </yc-checkbox>
    </div>
  </ng-container>

  <ng-template #individualFilterValue>
    <yc-individual-filter-value
      [type]="sourceColumnDisplay.type | ycAs: $filterOptionsType"
      [api]="apiComparison"
      [filter]="filter"
      [disabled]="isViewOnly"
      [filterOptions]="filterOptions">
    </yc-individual-filter-value>
  </ng-template>
</div>

