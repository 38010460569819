import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { CurrencyService } from '@core/services/currency.service';
import { FundingSource, FundingSourceTypes } from '@core/typings/budget.typing';
import { ProcessingTypes } from '@core/typings/payment.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { PaymentProcessingService } from '@features/payment-processing/payment-processing.service';
import { TypeSafeFormBuilder, TypeSafeFormGroup, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
interface AddEditFsFormGroup {
  fundingSourceName: string;
  type: FundingSourceTypes;
  total: number;
  processor: ProcessingTypes;
}

@Component({
  selector: 'gc-add-edit-funding-source-modal',
  templateUrl: 'add-edit-funding-source-modal.component.html',
  styleUrls: ['./add-edit-funding-source-modal.component.scss']
})
export class AddEditFundingSourceModalComponent extends YCModalComponent<FundingSource> implements OnInit {
  @Input() fundingSource: FundingSource;
  modalHeader = this.i18n.translate(
    'BUDGET:hdrCreateFundingSource',
    {},
    'Create Funding Source'
  );
  formGroup: TypeSafeFormGroup<AddEditFsFormGroup>;
  typeOptions: TypeaheadSelectOption<FundingSourceTypes>[] = [{
    label: this.i18n.translate(
      'GLOBAL:textCash'
    ),
    value: FundingSourceTypes.DOLLARS
  }, {
    label: this.i18n.translate(
      'GLOBAL:textInKind',
      {},
      'In-Kind'
    ),
    value: FundingSourceTypes.UNITS
  }];
  processorOptions = this.paymentProcessingService.getProcessorOptions();
  FundingSourceTypes = FundingSourceTypes;
  ProcessingTypes = ProcessingTypes;
  formattingData = this.currencyService.formattingData;
  defaultCurrency = this.clientSettingsService.defaultCurrency;
  defaultSymbol = this.formattingData[
    this.defaultCurrency
  ].symbol;
  leftIconCurrency = {
    icon: this.defaultSymbol,
    isStaticText: true
  };
  showCalculator = false;
  totalFromCalculator = 0;

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private i18n: I18nService,
    private clientSettingsService: ClientSettingsService,
    private paymentProcessingService: PaymentProcessingService,
    private currencyService: CurrencyService
  ) {
    super();
  }

  get processorType () {
    return this.paymentProcessingService.processorType;
  }

  get showProcessorSelect () {
    return this.isDollars &&
      (this.processorType === ProcessingTypes.Both);
  }

  get branding () {
    return this.clientSettingsService.get('clientBranding');
  }

  get isDollars () {
    if (this.formGroup) {
      return this.formGroup.value.type === FundingSourceTypes.DOLLARS;
    }

    return this.fundingSource ? this.fundingSource.type === FundingSourceTypes.DOLLARS : true;
  }

  ngOnInit () {
    if (this.fundingSource) {
      this.modalHeader = this.i18n.translate(
        'BUDGET:hdrEditFundingSource',
        {},
        'Edit Funding Source'
      );
    }
    let total = 0;
    let name = '';
    let processor: ProcessingTypes = this.processorType === ProcessingTypes.Both ?
      ProcessingTypes.YourCause :
      this.processorType;
    if (this.fundingSource) {
      name = this.fundingSource.name;
      processor = this.fundingSource.processingTypeId;
      total = this.fundingSource.totalAmount;
    }
    this.formGroup = this.formBuilder.group<AddEditFsFormGroup>({
      fundingSourceName: [
        name,
        [
          Validators.required,
          Validators.maxLength(50)
        ]
      ],
      type: [
        this.fundingSource ?
          this.fundingSource.type :
          FundingSourceTypes.DOLLARS,
        Validators.required
      ],
      total: [total, Validators.required],
      processor: [processor, this.processorRequiredValidator()]
    }, {
      validator: [
        this.minAmountValidator()
      ]
    });
  }

  processorRequiredValidator () {
    return (control: AbstractControl) => {
      if (this.showProcessorSelect && !control.value) {
        return {
          required: {
            i18nKey: 'common:textThisInputIsRequired',
            defaultValue: 'This input is required'
          }
        };
      }

      return null;
    };
  }

  minAmountValidator () {
    return (group: AbstractControl) => {
      let returnVal = null;
      let used = .01;
      if (this.fundingSource) {
        used = this.fundingSource.totalAmount - this.fundingSource.amountUnallocated;
      }
      if (used === 0) {
        used = .01;
      }
      const total = group.value.total || 0;
      if (total < used) {
          returnVal = {
            total: {
              greaterOrEqual: {
                i18nKey: 'BUDGET:textAmountMustBeGreaterEqualDynamic',
                context: {
                  amount: this.currencyService.formatMoney(used)
                },
                defaultValue: 'Amount must be greater than or equal to __amount__'
              }
            }
          };
      }

      return returnVal;
    };
  }

  useCalculatedValue () {
    this.formGroup.get('total').setValue(
      this.currencyService.makeNumberPrecise(this.totalFromCalculator)
    );
    this.showCalculator = false;
  }

  onTotalCalculatorChange (total: number) {
    this.totalFromCalculator = total;
  }

  onSubmit () {
    const totalAmount = this.formGroup.value.total;

    const fundingSource: FundingSource = {
      id: this.fundingSource ? this.fundingSource.id : undefined,
      name: this.formGroup.value.fundingSourceName,
      type: this.formGroup.value.type,
      totalAmount,
      processingTypeId: this.showProcessorSelect ?
        this.formGroup.value.processor :
        this.processorOptions[0].value
    };
    this.closeModal.emit(fundingSource);
  }
}
