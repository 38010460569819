import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseApplicationForLogic, FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormLogicService } from '@features/forms/services/form-logic/form-logic.service';
import { ConditionalLogicBuilderModalComponent } from '@features/logic-builder/conditional-logic-builder-modal/conditional-logic-builder-modal.component';
import { LogicBuilderService } from '@features/logic-builder/logic-builder.service';
import { EvaluationType, GlobalLogicGroup, LogicColumnDisplay } from '@features/logic-builder/logic-builder.typing';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory } from '@yourcause/common/modals';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';

@Component({
  selector: 'gc-conditional-logic-form-builder-settings',
  templateUrl: './conditional-logic-form-builder-settings.component.html',
  styleUrls: ['./conditional-logic-form-builder-settings.component.scss']
})
export class ConditionalLogicFormBuilderSettingsComponent extends BaseComponentConfigSettingsComponent implements OnInit {
  @Input() logicGroups: GlobalLogicGroup<BaseApplicationForLogic>;
  @Input() component: FormDefinitionComponent;
  @Output() onModalOpenOrClose = new EventEmitter<boolean>();
  @Output() isValidChange = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<GlobalLogicGroup<BaseApplicationForLogic>>();

  availableColumns: LogicColumnDisplay<BaseApplicationForLogic>[];
  evaluationTypeOptions = this.logicBuilderService.getEvaluationTypeOptionsForConditional();
  instructions = this.i18n.translate(
    'common:textClickManageConditionalLogicAlert',
    {},
    'Click "Manage conditional logic" below to create conditional logic to define when this component should show or hide.'
  );
  showValidityError = false;

  constructor (
    private logicBuilderService: LogicBuilderService,
    private i18n: I18nService,
    private modalFactory: ModalFactory,
    private formLogicService: FormLogicService,
    private formBuilderService: FormBuilderService
  ) {
    super();
  }

  ngOnInit () {
    if (!this.logicGroups) {
      this.logicGroups = this.logicBuilderService.getDefaultConditionalLogic();
      this.emitConditionalLogic();
    }
    const {
      availableColumns
    } = this.formLogicService.getAvailableLogicColumnsForComponent(
      this.component,
      true,
      this.formBuilderService.currentFormBuilderDefinition,
      this.formBuilderService.currentFormBuilderIndex,
      this.formBuilderService.currentFormBuilderFormAudience
    );
    this.availableColumns = availableColumns;
    this.emitValidity();
  }

  getHasValidConditions () {
    return this.logicBuilderService.getHasValidConditions(this.logicGroups);
  }

  async openLogicBuilderModal () {
    this.onModalOpenOrClose.emit(true);
    const response = await this.modalFactory.open<
      ConditionalLogicBuilderModalComponent<BaseApplicationForLogic>
    >(
      ConditionalLogicBuilderModalComponent,
      {
        builderName: this.i18n.translate(
          'FORMS:hdrManageConditionalLogic',
          {},
          'Manage Conditional Logic'
        ),
        evaluationTypeOptions: this.logicBuilderService.getEvaluationTypeOptionsForConditional(),
        currentColumnName: this.component.label,
        availableColumns: this.availableColumns,
        logic: this.logicGroups,
        isViewOnly: this.isViewOnly
      }
    );
    this.onModalOpenOrClose.emit(false);
    if (response) {
      this.logicGroups = response;
      this.emitConditionalLogic();
    }
  }

  handleEvaluationTypeChange (evaluationType: EvaluationType) {
    this.logicGroups = this.logicBuilderService.handleEvaluationTypeChange(
      this.logicGroups,
      evaluationType
    );
    this.emitConditionalLogic();
  }

  emitConditionalLogic () {
    this.onChange.emit(this.logicGroups);
    this.emitValidity();
  }

  emitValidity () {
    const isValid = this.getHasValidConditions();
    this.isValidChange.emit(isValid);
    this.showValidityError = !isValid;
  }
}
