import { Injectable } from '@angular/core';
import { BaseLogicState, BaseLogicStateRecord } from './logic-state.typing';

@Injectable({ providedIn: 'root' })
export class LogicStateService {

  getRecordsFromState<L extends BaseLogicStateRecord<any>, K extends string = string> (
    column: K,
    logicState: BaseLogicState<L, K>
  ): {
    source: L;
    dependents: L[];
  } {
    return {
      source: logicState.sourceMap.get(column) ?? null,
      dependents: logicState.dependentMap.get(column) ?? []
    };
  }

  getCurrentLogicValueOfColumn<R, L extends BaseLogicStateRecord<R>, K extends string = string> (
    column: K,
    logicState: BaseLogicState<L>
  ) {
    const { source } = this.getRecordsFromState(column, logicState);

    return source?.result$?.value;
  }
}
