export default [
  {
    "code": 1,
    "name": "Completed",
    "description": "Archive an application as Completed"
  },
  {
    "code": 2,
    "name": "Terminated",
    "description": "Archive an application as Terminated"
  },
  {
    "code": 3,
    "name": "Declined",
    "description": "Archive an application as Declined"
  },
  {
    "code": 4,
    "name": "Duplicate",
    "description": "Archive an application as Duplicate"
  },
  {
    "code": 5,
    "name": "Other",
    "description": "Archive an application as Other"
  },
  {
    "code": 6,
    "name": "Program Archived",
    "description": "Archive an application as Program Archived"
  }
]