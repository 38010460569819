<ng-container *ngIf="component && formGroup">
  <div class="mb-3">
    <yc-i18n
      [key]="'common:textWhenConditionsAreMetSetValueTo'"
      [defaultValue]="'When the following conditions are met, this component will have the value below.'">
    </yc-i18n>
    <div
      [formGroup]="formGroup"
      class="d-flex">
      <!-- Type of value (static value or based on other comp) -->
      <yc-typeahead-select
        class="flex-basis-50 me-2"
        [ngClass]="{
          'flex-basis-33': formGroup?.value.resultType === ConditionalLogicResultType.RELATIVE_DATE
        }"
        name="resultType"
        [disabled]="isViewOnly"
        [label]="'common:lblTypeOfValue' | ycI18n: 'Type of value'"
        [showClear]="false"
        [items]="resultTypeOptions"
        (onChange)="handleResultTypeChange($event)">
      </yc-typeahead-select>
      <!-- Other field selected for value -->
      <ng-container *ngIf="formGroup?.value.resultType === ConditionalLogicResultType.OTHER_COLUMN">
        <yc-typeahead-select
          class="flex-basis-33"
          [disabled]="isViewOnly"
          [ngClass]="{'me-2': formGroup.value.applyCalculation }"
          name="setResult"
          [addRequiredAsterisk]="true"
          [label]="'common:textSelectAComponent' | ycI18n: 'Select a component'"
          [showClear]="false"
          [items]="setValueOfComponentOptions"
          (onChange)="onSetValueComponentChange($event)">
        </yc-typeahead-select>
      </ng-container>
      <!-- Static value input -->
      <ng-container *ngIf="formGroup?.value.resultType === ConditionalLogicResultType.STATIC_VALUE">
        <gc-form-component-display
          class="flex-basis-33"
          [formId]="formId"
          [component]="componentForView"
          [disabledOverride]="isViewOnly"
          [isForSetValue]="true"
          [inConfigModal]="true"
          [notAutoSave]="true"
          [value]="formGroup.value.staticResult | ycAs: $formAnswerType"
          (onValueChange)="onStaticValueChange($event)">
        </gc-form-component-display>      
      </ng-container>

      <!-- Relative date config -->
      <ng-container *ngIf="calculationOptionsFormGroup &&
        logicValueFormatType === 'date' &&
        formGroup.value.applyCalculation &&
        logic.resultType !== ConditionalLogicResultType.STATIC_VALUE">
        <gc-relative-date-calculator
          class="d-flex mt-4"
          [isViewOnly]="isViewOnly"
          [calculationOptionsFormGroup]="calculationOptionsFormGroup"
          (onCalculationChange)="handleCalculationUpdate()">
        </gc-relative-date-calculator>
      </ng-container>
    </div>
    <!-- Enable Date Calculations -->
    <div *ngIf="showDateCalculationCheckbox"
      [formGroup]="formGroup">
      <yc-checkbox
        name="applyCalculation"
        [srOnlyLabel]="true"
        [disabled]="isViewOnly"
        [label]="'common:textCheckboxForCalculation' | ycI18n: 'Checkbox for whether a calculation should be applied'"
        [optionLabel]="'common:lblDateCalculationLabel' | ycI18n: 'Add or subtract time from the selected date'"
        (onChange)="handleCalculationUpdate()">
      </yc-checkbox>
    </div>
  </div>

  <!-- Logic Summary Sentence -->
  <gc-logic-summary-sentence
    [logic]="logic"
    [logicValueFormatType]="logicValueFormatType"
    [options]="options"
    [availableColumns]="availableColumns">
  </gc-logic-summary-sentence>

  <gc-conditional-logic-builder-group
    [availableColumns]="availableColumns"
    [maxDepth]="maxDepth"
    [allowNoConditions]="false"
    [currentDepth]="1"
    [group]="logic"
    [isViewOnly]="isViewOnly"
    (groupChange)="handleLogicChange($event)"
    (validChange)="conditionsValidChange($event)">
  </gc-conditional-logic-builder-group>
</ng-container>

