
<yc-modal
  [primaryButtonText]="'common:btnSubmit' | ycI18n: 'Submit'"
  [modalHeader]="'common:hdrManageCalculatedValueBuilder' | ycI18n: 'Manage Calculated Value Builder'"
  [secondaryButtonText]="'common:linkClear' | ycI18n: 'Clear'"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  [primaryDisabled]="!valid || isViewOnly"
  (onCancel)="closeModal.emit()"
  (onPrimaryClick)="handlePrimaryClick()"
  (onSecondaryClick)="clear()">
  <gc-formula-builder
    [columns]="columns"
    [formula]="formula"
    [isViewOnly]="isViewOnly"
    [otherFormulas]="otherFormulas"
    (formulaChange)="formulaToOutput = $event"
    (validityChanged)="valid = $event">
  </gc-formula-builder>
</yc-modal>
