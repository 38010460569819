<div *ngIf="ready">
  <ng-container *ngIf="logicEnabled">

    <yc-alert *ngIf="(rules.length === 0)"
      alertClass="info"
      [message]="noRulesMessage">
    </yc-alert>

    <div *ngFor="let rule of rules; let index = index;"
      class="d-flex mb-3 align-items-center">

      <!-- Logic Summary -->
      <gc-logic-summary-sentence class="flex-grow-1"
        [logic]="rule"
        [options]="options"
        [logicValueFormatType]="logicValueFormatType"
        [availableColumns]="availableColumns"
        [fallbackToNoLogicMessage]="false">
      </gc-logic-summary-sentence>

      <div class="d-flex flex-column">
        <!-- Move Up  -->
        <yc-button *ngIf="rules.length > 1"
          class="ms-2"
          [fontAwesomeIcon]="'arrow-up'"
          [icon]="true"
          [disabled]="isViewOnly ||
            (index === 0) ||
            (rule.evaluationType === EvaluationType.AlwaysTrue)"
          (onClick)="moveUpOrDown(index, true)">
        </yc-button>

        <!-- Manage Conditional Logic -->
        <yc-button class="ms-2"
          [icon]="true"
          [fontAwesomeIcon]="'pencil'"
          [tooltipText]="'common:textEdit' | ycI18n: 'Edit'"
          (onClick)="openLogicBuilderModal(rule, index)">
        </yc-button>

        <!-- Move Down  -->
        <yc-button *ngIf="rules.length > 1"
          class="ms-2"
          [icon]="true"
          [disabled]="isViewOnly ||
            ((index + 1) === rules.length) ||
            (rules[(index + 1)].evaluationType === EvaluationType.AlwaysTrue)"
          (onClick)="moveUpOrDown(index, false)">
          <yc-fa [icon]="'arrow-down'">
          </yc-fa>
        </yc-button>
      </div>
    </div>

    <!-- Add Rule -->
    <div>
      <yc-button
        [link]="true"
        [langKey]="'common:btnAddNewRule'"
        [defaultText]="'Add new rule'"
        [disabled]="isViewOnly"
        [fontAwesomeIcon]="'plus'"
        (onClick)="openLogicBuilderModal()">
      </yc-button>
    </div>
  </ng-container>
  <yc-alert *ngIf="!logicEnabled"
    alertClass="info"
    [message]="'common:textFormulaBuilderUnavailable' | ycI18n:'&quot;Calculated Value&quot; and &quot;Set Value&quot; cannot be used together, please clear any logic from the &quot;Calculated Value&quot; tab to continue.'">
  </yc-alert>
</div>