
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { SystemTags } from './typings/system-tags.typing';

@RegisterYCState()
export class SystemTagsState extends BaseYcState {
  readonly buckets: SystemTags.TagBucket[];
  readonly allTags: SystemTags.TagDefinitionForUi[];
  readonly allTagsMap: Record<number, SystemTags.TagDefinitionForUi> = {};
  readonly tagMap: {
    [x: string]: {
      [x: string]: number[];
    };
  } = {};
}
