import { APP_BASE_HREF } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AppInsightsSessionService } from '@core/services/app-insights-session.service';
import { MixpanelService } from '@core/services/mixpanel.service';
import { GCCoreModule } from '@core/_core.module';
import { environment } from '@environment';
import { FormRendererModule } from '@features/forms/form-renderer-module';
import { AnalyticsService } from '@yourcause/common/analytics';
import { I18nService } from '@yourcause/common/i18n';
import { LoggingModule } from '@yourcause/common/logging';
import { NotifierModule } from '@yourcause/common/notifier';
import { AppInsightsService } from '@yourcause/common/utils';
import { HeapModule } from '@yourcause/common/heap';
import { AppComponent } from './app.component';
import { ErrorHandlerService } from './app/core/services/error-handler.service';
import './vendor/ng-flow';

const appBase = environment.isLocalhost ?
  [] : [{
    provide: APP_BASE_HREF,
    useValue: location.protocol + '//' + location.hostname + '/'
  }];
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    FormRendererModule,
    BrowserAnimationsModule,
    GCCoreModule,
    BrowserModule,
    LoggingModule,
    NotifierModule,
    HeapModule
  ],
  providers: [
    ...appBase,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    AppInsightsService
  ],
  exports: [
    AppComponent
  ]
})
export class AppSharedModule {
  constructor (
    appInsightsService: AppInsightsService,
    router: Router,
    i18n: I18nService,
    appInsightsSessionService: AppInsightsSessionService,
    mixpanel: MixpanelService,
    analyticsService: AnalyticsService
  ) {
    if (environment.production) {
      I18nService.CACHE_VERSION = require('./app/environments/version.json');
    }
    I18nService.nsLangOverride['en-US'] = 'en';
    if (environment.isLocalhost) {
      i18n.endpoint = 'https://localhost:51852/InsertMissing';
    } else {
      i18n.endpoint = '';
    }
    const shouldReuseRoute = router.routeReuseStrategy.shouldReuseRoute;
    router.routeReuseStrategy.shouldReuseRoute = function (future, current) {
      if (future.data.noReuse) {
        return false;
      }

      return shouldReuseRoute(future, current);
    };

    if (environment.appInsightsKey && environment.supportsSubdomains) {
      appInsightsService.config = {
        instrumentationKey: environment.appInsightsKey,
        isStorageUseDisabled: false,
        isCookieUseDisabled: true,
        url: './assets/ngai.js'
      };
      appInsightsService.init();
      appInsightsSessionService.setup();
    }

    if (environment.mixpanelProjectId) {
      mixpanel.init().then(() => {
        analyticsService.initRouterAnalytics();
        analyticsService.getEvents().subscribe((event) => {
          // Should we filter out events here?
          // John says we will figure this out with feedback as we go
          mixpanel.track(event.eventType, {
            ...event.extras,
            eventType: event.eventType,
            eventName: event.eventName
          });
        });
      });
    }
  }
}
