import { NgModule } from '@angular/core';
import { GCCoreModule } from '@core/_core.module';
import { FormulaBuilderModalComponent } from './formula-builder-modal/formula-builder-modal.component';
import { FormulaBuilderStepComponent } from './formula-builder-step/formula-builder-step.component';
import { FormulaBuilderComponent } from './formula-builder/formula-builder.component';

@NgModule({
  declarations: [
    FormulaBuilderComponent,
    FormulaBuilderStepComponent,
    FormulaBuilderModalComponent
  ],
  imports: [
    GCCoreModule
  ],
  exports: [
    FormulaBuilderComponent,
    FormulaBuilderStepComponent,
    FormulaBuilderModalComponent
  ]
})
export class FormulaBuilderModule {}
