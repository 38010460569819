  <div class="yc-layout-table mb-4">

    <!-- Name -->
    <div *ngIf="formName" 
      class="form-title">
      {{ formName }}
    </div>
    <small class="d-block">
      {{ formDescription }}
    </small>

    <div [formGroup]="formGroup"
      class="mt-3">
      <strong class="d-block">
        <yc-i18n
          [key]="forOrg ?
            'FORMS:hdrNonprofitNomineeInformation' :
            'FORMS:hdrNomineeInformation'"
          [defaultValue]="forOrg ?
            'Nonprofit Nominee Information' :
            'Nominee Information'">
        </yc-i18n>
      </strong>
      <small>
        <yc-i18n *ngIf="!forOrg"
          key="FORMS:textNomineeInfoForIndividualDesc3"
          defaultValue="This is the individual being nominated for the grant. If the nomination is accepted they will be invited to continue the application.">
        </yc-i18n>
        <yc-i18n *ngIf="forOrg"
          key="FORMS:textNomineeInfoForOrgDesc"
          defaultValue="This is the individual who will be the main point of contact for the nominated nonprofit. If the nomination is accepted they will be invited to continue the application.">
        </yc-i18n>
      </small>


      <!-- Nomination Form -->
      <div class="d-flex">
        <yc-input class="col-md-6 ps-0 mb-2 mt-2"
          name="email"
          [disabled]="readOnly"
          [addRequiredAsterisk]="true"
          [label]="forOrg ?
          ('FORMS:textNonprofitNomineeEmail' | ycI18n: 'Nonprofit nominee email') : 
          ('FORMS:textNomineeEmail' | ycI18n: 'Nominee email')"
          (onChange)="nominationFormChanged()">
        </yc-input>
      </div>

      <div class="row mb-2">
        <div class="col-md-6">
          <yc-input
            name="firstName"
            [label]="forOrg ?
              ('FORMS:textNonprofitNomineeFirstName' | ycI18n: 'Nonprofit nominee first name') :
              ('FORMS:textNomineeFirstName' | ycI18n: 'Nominee first name')"
            [disabled]="readOnly"
            [addRequiredAsterisk]="true"
            (onChange)="nominationFormChanged()">
          </yc-input>
        </div>
        <div class="col-md-6">
          <yc-input
            name="lastName"
            [label]="forOrg ?
              ('FORMS:textNonprofitNomineeLastName' | ycI18n: 'Nonprofit nominee last name') :
              ('FORMS:textNomineeLastName' | ycI18n: 'Nominee last name')"
            [disabled]="readOnly"
            [addRequiredAsterisk]="true"
            (onChange)="nominationFormChanged()">
          </yc-input>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-6">
          <yc-input
            name="phoneNumber"
            [label]="forOrg ?
              ('FORMS:textNonprofitNomineePhone' | ycI18n: 'Nonprofit nominee phone') :
              ('FORMS:textPhone' | ycI18n: 'Phone')"
            [disabled]="readOnly"
            (onChange)="nominationFormChanged()">
          </yc-input>
        </div>
        <div class="col-md-6">
          <yc-input
            name="position"
            [label]="forOrg ?
              ('FORMS:textNonprofitNomineePosition' | ycI18n: 'Nonprofit nominee position') :
              ('common:textPosition' | ycI18n: 'Position')"
            [disabled]="readOnly"
            (onChange)="nominationFormChanged()">
          </yc-input>
        </div>
      </div>
    </div>
  </div>

  <hr *ngIf="forApplicant">