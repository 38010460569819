<yc-currency
  [amount]="data.amountForControl"
  [equivalent]="equivalentAmount"
  [currencyOptions]="currencyOptions"
  [label]="label"
  [description]="description"
  [isRequired]="isRequired"
  [hideLabel]="hideLabel"
  [disabled]="isDisabled"
  [currency]="currency"
  [equivalentCurrency]="equivalentCurrency"
  [errors]="control.errors"
  [isDirtyOverride]="control.dirty"
  [canToggle]="canToggleCurrency"
  [currencyLocked]="forceDefaultCurrency ||
    (useCustomCurrency === CurrencyRadioOptions.USE_ONE_CURRENCY)"
  [formattingData]="formattingData"
  [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
  [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
  [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
  (amountChange)="onAmountChange($event)"
  (currencyChange)="onCurrencyChange($event)">
</yc-currency>