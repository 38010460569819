import { Inject } from '@angular/core';
import { AuthBehaviors } from '@core/services/token/token-behaviors';
import { Applicant } from '@core/typings/applicant.typing';
import { SimpleUser, User, UserForUi, UserStats } from '@core/typings/client-user.typing';
import { AudienceForUser } from '@core/typings/user.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class UserState extends BaseYcState {
  @Inject(AuthBehaviors) readonly behaviors: AuthBehaviors;
  readonly user: User;
  readonly applicant: Applicant;
  readonly admin: User;
  readonly userName: string;
  readonly userJobTitle: string;
  readonly lastSelectedCurrency: string;
  readonly adminPermissions: number[];

  readonly allUsers: SimpleUser[];
  readonly userStats: UserStats;
  readonly allUsersMap: Record<string, SimpleUser> = {};

  readonly allUsersDetailed: UserForUi[];
  readonly userAudienceMap: Record<number, AudienceForUser[]> = {};
}
