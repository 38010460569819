<div>
  <!-- Invalid Dependent Picklists -->
  <yc-alert *ngIf="invalidDependentPicklists.length > 0"
    [message]="invalidDependentPicklists | gcInvalidDependentPicklistsErrorMessage"
    alertClass="warning">
  </yc-alert>
  <div class="row">
    <div class="col-md-3 mt-2"
      [class.accordion-section]="!hasFiltered"
      [class.filtered-section]="hasFiltered">
      <!-- Search -->
      <div class="mb-3">
        <yc-top-level-filters
          [topLevelFilters]="topLevelFilters"
          [hideTableFilters]="true"
          [hideTableOptions]="true"
          [stackFilters]="true"
          [simple]="true"
          (onTopLevelFilterChange)="onTopLevelFilterChange()">
        </yc-top-level-filters>
      </div>
    
      <div>
        <div *ngIf="!hasFiltered">
          <yc-accordion *ngFor="let rows of accordionGroups"
            class="d-block overflow-scroll"
            [isDraggable]="false"
            [style]="AccordionStyle.Wrapper"
            [rows]="rows">
            <div *ycAccordionTab="let bucket;">
              <div class="font-875-rem font-550">
                {{ bucket.name }}
              </div>
            </div>
            <div *ycAccordionContent="let bucket;">
              <!-- Non Nested Buckets -->
              <ng-container *ngIf="bucket.componentGroups.length === 1">
                <ng-template *ngTemplateOutlet="components; context: {
                    components: bucket.componentGroups[0].components
                  }">
                </ng-template>
              </ng-container>
              <!-- Nested Buckets -->
              <yc-accordion *ngIf="bucket.componentGroups.length > 1"
                class="d-block overflow-scroll nested-accordion"
                [isDraggable]="false"
                [style]="AccordionStyle.Compact"
                [singleOpen]="true"
                [rows]="bucket.componentGroups"
                [openMap]="openMapByBucketType[bucket.type | ycAs: $bucketType]"
                (openMapChange)="isOpenChange($event, bucket.type)">
                <div *ycAccordionTab="let row;">
                  <div class="font-875-rem font-550">
                    {{ row.name }}
                  </div>
                </div>
                <div *ycAccordionContent="let innerGroup; let index = index;">
                  <ng-container *ngIf="!!openMapByBucketType[bucket.type | ycAs: $bucketType][index]">
                    <ng-template *ngTemplateOutlet="components; context: {
                        components: innerGroup.components
                      }">
                    </ng-template>
                  </ng-container>
                </div>
              </yc-accordion>
            </div>
          </yc-accordion>

          <div *ngIf="accordionGroups.length === 0"
            class="font-550 font-875-rem mt-3 text-center">
            {{ noFieldsAvailableMessage }}
          </div>
        </div>
        
        <!-- Search Results -->
        <div *ngIf="hasFiltered">
          <ng-template *ngTemplateOutlet="components; context: {
              components: filteredComponents
            }">
          </ng-template>
        </div>
      </div>
    </div>

    <div tabIndex="0"
      class="col-md-9">

      <yc-table
        [key]="tableKey"
        [simple]="true"
        [rows]="componentsToAdd"
        [hidePagination]="true"
        [maxHeightBeforeScroll]="'60vh'"
        [dragAndDropEnabled]="true"
        [customNoRecordsMessage]="accordionGroups.length === 0 ? noFieldsAvailableMessage : noRecordsTableMessage"
        [hideBorderBottom]="true"
        (onDrop)="componentsToAdd = $event">

        <!-- Label -->
        <ng-container *ycTableColumn="labelColumnHeader;
          let row;">
          <yc-textarea
            [rows]="2"
            [(value)]="row.label"
            [placeholder]="labelColumnHeader | ycI18n"
            [label]="labelColumnHeader | ycI18n"
            [srOnlyLabel]="true">
          </yc-textarea>
        </ng-container>

        <!-- Form Field -->
        <ng-container *ycTableColumn="'common:lblFormField';
          tdWidth: '200px';
          let row">
          <div>
            <yc-button *ngIf="!!row.referenceField"
              [link]="true"
              [outsideClick]="true"
              [popover]="row.tooltip ? tooltipPopover : null"
              [popoverContext]="{
                tooltipText: row.tooltip
              }">
              {{ refTypesMap[row.referenceField?.type | ycAs: $refType] }}
            </yc-button>

            <div *ngIf="!row.referenceField">
              {{ row.name }}
            </div>
          </div>
          <div *ngIf="!!row.referenceField"
            class="ms-2">
            <ng-template *ngTemplateOutlet="audience; context: {
              audience: row.referenceField.formAudience
            }"></ng-template>
          </div>
        </ng-container>

        <!-- Required -->
        <ng-container *ycTableColumn="'common:textRequired';
          visible: quickAddType === QuickAddType.FORM_BUILDER;
          let row">
          <yc-checkbox *ngIf="!row.hideRequired"
            [(value)]="row.isRequired"
            [optionLabel]="'common:textComponentRequired' | ycI18n: 'Component required'"
            [optionLabelSrOnly]="true">
          </yc-checkbox>
        </ng-container>

        <!-- Actions -->
        <ng-container *ycTableColumn="''; let row; let index = index;
          let last = last;
          let first = first;
          class: 'draggable-actions',
          headerVisible: false">
          <div class="d-flex justify-content-end">
            <yc-button
              [icon]="true"
              [fontAwesomeIcon]="'times'"
              [textDanger]="true"
              [tooltipText]="'common:textRemove' | ycI18n: 'Remove'"
              (onClick)="handleRemoveAction(row, index)">
            </yc-button>
          </div>
        </ng-container>
      </yc-table>
    </div>
  </div>
</div>

<ng-template #tooltipPopover
  let-tooltipText="tooltipText">
  <div [innerHtml]="tooltipText | escapeHtml"></div>
</ng-template>

<ng-template #audience
  let-audience="audience">
  <yc-i18n
    [key]="audience === FormAudience.APPLICANT ?
      'common:lblApplicant':
      'ADMIN:textGrantManager'"
    [defaultValue]="audience === FormAudience.APPLICANT ?
      'Applicant':
      'Grant Manager'">
  </yc-i18n>
</ng-template>

<!-- Components List -->
<ng-template #components
  let-components="components">
  <div *ngIf="components.length > 100">
    <cdk-virtual-scroll-viewport itemSize="50"
      class="cdk-scroll-viewport">
      <div *cdkVirtualFor="let component of components; let index = index;"
        [ngStyle]="{
          height: '50px'
        }">
        <ng-template *ngTemplateOutlet="componentRow; context: {
          component: component
        }"></ng-template>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <div *ngIf="components.length <= 100">
    <div *ngFor="let component of components; let index = index">
      <ng-template *ngTemplateOutlet="componentRow; context: {
          component: component
        }"></ng-template>
    </div>
  </div>

  <!-- No Components Available -->
  <div *ngIf="components.length === 0"
    class="no-fields-available">
    <yc-i18n
      class="no-fields-text"
      [key]="hasFiltered ? 'common:lblNoResultsFound' : 'common:textNoFieldsAvailable'"
      [defaultValue]="hasFiltered ? 'No results found' : 'No fields available'">
    </yc-i18n>
  </div>
</ng-template>

<ng-template #componentRow
  let-component="component">
  <div *ngIf="!!component.tooltip"
    class="bucket-component"
    [popover]="tooltipPopover"
    [popoverContext]="{
      tooltipText: component.tooltip
    }"
    [delay]="500"
    [container]="'body'"
    [placement]="'auto'"
    [boundariesElement]="'viewport'"
    triggers="mouseenter:mouseleave mouseenter:click">
    <ng-template *ngTemplateOutlet="componentContent; context {
      component: component
    }"></ng-template>
  </div>

  <div *ngIf="!component.tooltip"
    class="bucket-component">
    <ng-template *ngTemplateOutlet="componentContent; context {
      component: component
    }"></ng-template>
  </div>
</ng-template>

<ng-template #componentContent
  let-component="component">
  <div>
    <div [class.ms-2]="hasFiltered">
    {{ component.label | ycTruncateText: 100 }}
    </div>
    <div *ngIf="hasFiltered">
      <div *ngIf="!component.referenceField"
        class="ms-2">
        {{ component.bucketName }}
      </div>
    </div>
  </div>
  <yc-button
    [link]="true"
    [fontAwesomeIcon]="'plus'"
    [tooltipText]="'common:linkAdd' | ycI18n: 'Add'"
    (onClick)="handleAddComponent(component)">
  </yc-button>
</ng-template>
