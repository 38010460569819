<div *ngIf="formGroup"
  [formGroup]="formGroup">
  <yc-double-picklist
    name="value"
    [dependentOption]="selectedOption"
    [dependentOptions]="apiOptions"
    [stacked]="true"
    [addRequiredAsterisk]="true"
    [disabled]="isViewOnly"
    [controlled]="true"
    [label]="'CONFIG:lblWebService' | ycI18n: 'Web service'"
    [dependentLabel]="'CONFIG:lblParamName' | ycI18n: 'Param name'"
    (onChange)="onChange.emit($event)">
  </yc-double-picklist>
</div>
