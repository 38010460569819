import { ComponentFactoryResolver, Inject, Injectable } from '@angular/core';
import { CurrentModalPayload, CurrentModalToken } from '@yourcause/common/modals';
import { AppInsightsService } from '@yourcause/common/utils';
import { Observable, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModalTrackerService {
  private sub: Subscription;

  constructor (
    private appInsights: AppInsightsService,
    @Inject(CurrentModalToken) private currentModal: Observable<CurrentModalPayload>,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  startTracking () {
    this.stopTracking();
    this.sub = this.currentModal.subscribe(async (currentModal) => {
      const factory = this.componentFactoryResolver.resolveComponentFactory(currentModal.modal);

      // track the route with a hash and the modal component selector e.g. /management/home/my-workspace#gc-recent-activity-modal
      const ammendedRoute = `${location.pathname}#${factory.selector}`;

      // if we have a promise to track, we can learn how long they visited the modal
      if (currentModal.promise) {
        this.appInsights.startTrackPage(ammendedRoute);
        await currentModal.promise;
        this.appInsights.stopTrackPage(ammendedRoute);
      } else {
        // otherwise just track that they viewed the modal
        this.appInsights.trackPageView(ammendedRoute);
      }
    });
  }

  stopTracking () {
    this.sub?.unsubscribe();
  }
}
