<div *ngIf="!!budget"
  class="d-flex m-3 flex-column">
  <!-- Budget Name -->
  <div class="flyout-header mb-2">
    {{ budget.name }}
  </div>

  <!-- Type / Status -->
  <div class="d-flex mb-2">
    <div class="me-4">
      <yc-i18n
        key="common:hdrType"
        defaultValue="Type">
      </yc-i18n>:
      <yc-i18n
        [key]="budget.fundingSourceType === FundingSourceTypes.DOLLARS ?
          'GLOBAL:textCash' :
          'GLOBAL:hdrInKind'"
        [defaultValue]="budget.fundingSourceType === FundingSourceTypes.DOLLARS ?
          'Cash' :
          'In Kind'">
      </yc-i18n>
    </div>

    <div>
      <yc-i18n
        key="common:hdrStatus"
        defaultValue="Status">
      </yc-i18n>:
      <yc-i18n
        [key]="budget.isClosed ?
          'GLOBAL:textClosed' :
          'GLOBAL:textOpen'"
        [defaultValue]="budget.isClosed ?
          'Closed' :
          'Open'">
      </yc-i18n>
    </div>
  </div>

  <!-- Tags -->
  <gc-manage-tags
    [tagType]="Buckets.Budget"
    [entityId]="budgetId"
    [showManageLink]="false">
  </gc-manage-tags>

  <!-- Stats Row 1 -->
  <div class="d-flex justify-content-between mt-3">
    <!-- Total Budget-->
    <div class="budget-stat">
      <div class="font-550">
        {{ budget.totalAmount | gcMoney }}
      </div>
      <div>
        <yc-i18n
          key="GLOBAL:textTotalAmount"
          defaultValue="Total amount">
        </yc-i18n>
      </div>
    </div>

    <!-- Remaining -->
    <div class="budget-stat">
      <div class="font-550">
        {{ budget.totalAmountAvailable| gcMoney }}
      </div>
      <div>
        <yc-i18n
          key="GLOBAL:textRemaining"
          defaultValue="Remaining">
        </yc-i18n>
      </div>
    </div>

    <!-- Spacer -->
    <div class="budget-stat"></div>
  </div>

  <!-- Stats Row 2 -->
  <div class="d-flex justify-content-between mt-3 mb-3">
    <!-- Payments-->
    <div class="budget-stat">
      <div class="font-550">
        {{ budget.totalSpent| gcMoney }}
      </div>
      <div>
        <yc-i18n
          key="common:hdrPayments"
          defaultValue="Payments">
        </yc-i18n>
      </div>
    </div>

    <!-- Reserved For Payment -->
    <div class="budget-stat">
      <div class="font-550">
        {{ budget.reservedAmount| gcMoney }}
      </div>
      <div>
        <yc-i18n
          key="common:hdrReserved"
          defaultValue="Reserved">
        </yc-i18n>
        <yc-fa
          icon="question-circle"
          [popoverTemplate]="reservedPopoverTemplate"
          conatiner="body"
          class="ms-2 cursor-p">
        </yc-fa>
      </div>
    </div>
    <!-- Unavailable -->
    <div class="budget-stat">
      <div class="font-550">
        {{ budget.totalUnavailableAmount| gcMoney }}
      </div>
      <div>
        <yc-i18n
          key="GLOBAL:textUnavailable"
          defaultValue="Unavailable">
        </yc-i18n>
        <yc-fa
          icon="question-circle"
          [popoverTemplate]="unavailablePopoverTemplate"
          conatiner="body"
          class="ms-2 cursor-p">
        </yc-fa>
      </div>
    </div>
  </div>

  <!-- Actions -->
  <div class="d-flex mb-4">
    <!-- Edit / View -->
    <yc-button
      class="me-2"
      [primary]="true"
      [langKey]="budget.isClosed ?
        'common:textView' :
        'common:textEdit'"
      [defaultText]="budget.isClosed ?
        'View' :
        'Edit'"
      (onClick)="editBudget()">
    </yc-button>

    <!--Insights -->
    <yc-button *ngIf="hasInsightsPermission"
      class="me-2"
      [secondary]="true"
      [langKey]="'GLOBAL:textInsights'"
      [defaultText]="'Insights'"
      (onClick)="viewInsights()">
    </yc-button>
  </div>

  <!-- Programs -->
  <yc-expandable-container
    [header]="'GLOBAL:textPrograms' | ycI18n: 'Programs'"
    [subHeader]="programSubHeader"
    [hasData]="programs.length > 0">
    <yc-table
      [key]="budget.id + '_PROGRAMS'"
      [rowsPerPage]="5"
      [hideTableFilters]="true"
      [simple]="true"
      [drilldownStyles]="true"
      [rows]="programs">
      <ng-container *ycTableColumn="'common:lblProgram';
        let row;">
        {{ row.grantProgramName }}
      </ng-container>

      <ng-container *ycTableColumn="'common:hdrApplications';
        class: 'number';
        let row;">
        {{ row.applicationsCount }}
      </ng-container>

      <ng-container *ycTableColumn="'GLOBAL:textPaymentTotal';
        class: 'number';
        let row;">
        {{ row.paymentsTotal | gcMoney }}
      </ng-container>
    </yc-table>
  </yc-expandable-container>

  <!-- Audit Trail -->
  <yc-expandable-container
    [header]="'common:hdrRecentActivity' | ycI18n: 'Recent Activity'"
    [hasData]="true">
    <gc-budget-audit-trail-table
      [budgetId]="budgetId"
      [isSimple]="true">
    </gc-budget-audit-trail-table>
  </yc-expandable-container>
</div>

<ng-template #reservedPopoverTemplate>
  <yc-i18n
    key="BUDGET:textReservedFlyoutPopover"
    defaultValue="This amount is automatically reserved from applications using budget reservation. Funds are reserved until awards and payments are created.">
  </yc-i18n> 
  <a [href]="budgetHelpLink" 
    target="_blank"
    (click)="trackClick(budgetHelpLink)"
    class="ms-2">
    <yc-i18n
      key="common:textLearnMore"
      defaultValue="Learn more">
    </yc-i18n>
  </a>
</ng-template>

<ng-template #unavailablePopoverTemplate>
  <yc-i18n
    key="BUDGET:textUnavailableFlyoutPopover"
    defaultValue="This amount cannot be used for payments.">
  </yc-i18n>
  <a [href]="budgetHelpLink"
    target="_blank"
    (click)="trackClick(budgetHelpLink)"
    class="ms-2">
    <yc-i18n
      key="common:textLearnMore"
      defaultValue="Learn more">
    </yc-i18n>
  </a>
</ng-template>
