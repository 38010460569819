<div *ngIf="specialHandlingGroup"
  class="special-handling">
  <yc-form-label
    [label]="label"
    [description]="translations[specialHandlingInstructions] ||
      specialHandlingInstructions"
    [name]="compKey"
    [customIcon]="showFieldChange ? customLabelIcon : ''"
    [customIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
    [customIconClass]="showFieldChange ? customLabelIconClass : ''">
  </yc-form-label>
  <div [formGroup]="specialHandlingGroup">
    <!-- Handling On? -->
    <div class="mb-2">
      <yc-checkbox
        name="handlingOn"
        [tabIndex]="tabIndex"
        [disabled]="isDisabled"
        [optionLabel]="translations[SPECIAL_HANDLING_REQUIRED_DESC] ||
          SPECIAL_HANDLING_REQUIRED_DESC">
      </yc-checkbox>
    </div>
    <div [ycHidden]="!specialHandlingGroup.value.handlingOn">
      <!-- Name -->
      <div>
        <yc-input
          name="handlingName"
          [label]="translations[NAME_LABEL] || NAME_LABEL"
          [disabled]="true"
          [addRequiredAsterisk]="true">
        </yc-input>
      </div>

      <!-- Address Block -->
      <div>
        <gc-address-block
          [addressFormGroup]="specialHandlingGroup"
          [enforceCustomStateValidation]="false"
          [disableInputs]="isDisabled">
        </gc-address-block>
      </div>

      <!-- File Upload -->
      <div class="mt-3">
        <yc-multi-dropzone-input
          name="file"
          [label]="translations[FILE_LABEL] || FILE_LABEL"
          [disabled]="isDisabled"
          [addRequiredAsterisk]="requireFileUpload"
          [uploadFunc]="uploadFile"
          [fileDownloadFunc]="downloadFile">
        </yc-multi-dropzone-input>
      </div>

      <!-- Reason -->
      <div>
        <yc-textarea
          name="reason"
          [disabled]="isDisabled"
          [addRequiredAsterisk]="requireReason"
          [label]="translations[REASON_LABEL] || REASON_LABEL">
        </yc-textarea>
      </div>

      <!-- Notes -->
      <div>
        <yc-textarea
          name="notes"
          [disabled]="isDisabled"
          [label]="translations[NOTES_LABEL] || NOTES_LABEL">
        </yc-textarea>
      </div>
    </div>
  </div>

</div>

