import { SimpleStringMap } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { InKindCategory, InKindCategoryItemStat } from './in-kind.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';

@RegisterYCState()
export class InKindState extends BaseYcState {
  readonly categories: InKindCategory[];
  readonly categoriesForForm: InKindCategory[];
  readonly categoryOptions: TypeaheadSelectOption[];
  readonly categoriesByClientMap: {
    [clientId: number]: InKindCategory[];
  } = {};
  readonly categoriesForApplicant: InKindCategory[];
  readonly allItems: InKindCategoryItemStat[];
  readonly allItemsMap: SimpleStringMap<InKindCategoryItemStat> = {};
  readonly categoryItemMap: Record<string, InKindCategoryItemStat[]> = {};
  readonly applicantCategoryItemsByProgramId: Record<number, InKindCategoryItemStat[]> = {};
}
