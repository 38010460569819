import { Injectable } from '@angular/core';
import { PDFService } from '@core/services/pdf.service';
import { FormAudience } from '@features/configure-forms/form.typing';
import { DateService, DATE_TIME_FORMAT } from '@yourcause/common/date';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { ApplicationEmailPdf, Email, EmailByApplication, EmailByProgramResponse, EmailNotificationType, EmailPdfType, ProgramEmailInfo, ProgramEmailPdf } from '../email.typing';
import { ExportEmailModalResponse } from './export-emails-modal/export-emails-modal.component';
import { ExportEmailResources } from './export-emails.resources';
import { FileService } from '@yourcause/common/files';


@Injectable({ providedIn: 'root' })
export class ExportEmailService {
  readonly buttonReplacement = '<!-- BUTTON_PLACEHOLDER -->';

  constructor (
    private exportEmailResources: ExportEmailResources,
    private pdfService: PDFService,
    private logger: LogService,
    private i18n: I18nService,
    private notifier: NotifierService,
    private fileService: FileService,
    private dateService: DateService
  ) { }

  /**
   *
   * @param modalResponse: response from the export email modal
   * @param emails: Array of all system emails
   */
  async handleExportEmailsByProgramAndLang (
    modalResponse: ExportEmailModalResponse,
    emails: Email[]
  ) {
    try {
      const result = await this.exportEmailResources.getEmailsByProgramAndLang(
        modalResponse.programId,
        modalResponse.emailLanguage
      );
      const adapted = this.adaptProgramEmailsForUi(result, emails);
      const compInputs = {
        emails: adapted,
        emailPdfType: EmailPdfType.Program
      };
      const downloadUrl = await this.pdfService.generateEmailPdf(
        compInputs,
        modalResponse.programId
      );
      if (downloadUrl) {
        this.fileService.downloadUrlAs(downloadUrl, 'Program Emails.pdf');
      }

    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorExportingEmails',
        {},
        'There was an error exporting the emails'
      ));
    }
  }

  /**
   *
   * @param result: program emails result from API
   * @param emails: Array of all system emails
   * @returns the adapted emails
   */
  adaptProgramEmailsForUi (
    result: EmailByProgramResponse[],
    emails: Email[]
  ) {
    let adapted: ProgramEmailPdf[] = [];
    result.forEach((item) => {
      const detail = item.emailInfo[0];
      const found = emails.find((_email) => {
        return _email.emailNotificationType === detail.emailNotificationTypeId;
      });
      const copies = item.emailInfo.filter((info) => {
        return info.clientEmailTemplate.copy;
      }).map((info, index) => {
        return this.adaptProgramEmailForUi(info, found, index);
      }).filter((email) => !!email);
      const standard = item.emailInfo.filter((info) => {
        return !info.clientEmailTemplate.copy;
      }).map((info) => {
        return this.adaptProgramEmailForUi(info, found);
      }).filter((email) => !!email);

      adapted = [
        ...adapted,
        ...standard,
        ...copies
      ];
    });

    return adapted;
  }

  /**
   *
   * @param applicationId: application id
   * @param email: Array of all system emails
   * @returns the adapted application emails
   */
  async getApplicationEmails (
    applicationId: number,
    emails: Email[]
  ): Promise<ApplicationEmailPdf[]> {
    const results = await this.exportEmailResources.getApplicationEmails(applicationId);

    return results.map<ApplicationEmailPdf>((email) => {
      const found = emails.find((_email) => {
        return _email.emailNotificationType === email.emailNotificationTypeId;
      });

      return this.adaptApplicationEmailForUi(email, found);
    });
  }

  /**
   *
   * @param email: the email to adapt
   * @param foundEmail: the email from system emails array
   * @param clientTemplateIndex: the index of the client email template
   * @returns the adapted email
   */
  adaptProgramEmailForUi (
    email: ProgramEmailInfo,
    foundEmail: Email,
    clientTemplateIndex?: number
  ): ProgramEmailPdf {
    const isApplicant = foundEmail.audienceType === FormAudience.APPLICANT;
    const isActive = email.active;

    return isActive ? {
      ...email,
      ...email.clientEmailTemplate,
      body: this.getBodyWithoutButton(email.clientEmailTemplate.body).body,
      emailNotificationName: this.getEmailNotificationName(
        email.emailNotificationTypeId,
        clientTemplateIndex
      ),
      audienceText: this.getAudienceText(isApplicant)
    } : null;
  }

  /**
   *
   * @param email: the email to adapt
   * @param foundEmail: the email from system emails array
   * @param clientTemplateIndex: the index of the client email template
   * @returns the adapted email
   */
  adaptApplicationEmailForUi (
    email: EmailByApplication,
    foundEmail: Email,
    clientTemplateIndex?: number
  ): ApplicationEmailPdf {
    const isApplicant = foundEmail.audienceType === FormAudience.APPLICANT;

    return {
      ...email,
      name: foundEmail.name,
      title: foundEmail.title,
      body: this.getBodyWithoutButton(email.body).body,
      sentTime: this.dateService.formatDate(email.sentTime, DATE_TIME_FORMAT),
      emailNotificationName: this.getEmailNotificationName(
        email.emailNotificationTypeId,
        clientTemplateIndex
      ),
      description: foundEmail.description,
      audienceText: this.getAudienceText(isApplicant)
    };
  }

  /**
   *
   * @param body: body to adapt
   * @returns the body without the button and the buttom HTML
   */
  getBodyWithoutButton (body: string): {
    body: string;
    buttonHTML: string;
  } {
    const match = body.match(
      /<!-- Button : BEGIN -->(.|(\r)?\n)*<!-- Button : END -->/
    );
    const buttonHTML = match ? match[0] : '';
    if (buttonHTML) {
      body = body.replace(
        buttonHTML,
        this.buttonReplacement
      );
    }

    return {
      body,
      buttonHTML
    };
  }

  /**
   *
   * @param emailNotificationTypeId: email notification type id
   * @param clientTemplateIndex: the index of the client email template
   * @returns the email notification name
   */
  getEmailNotificationName (
    emailNotificationTypeId: EmailNotificationType,
    clientTemplateIndex?: number
  ) {
    const hasClientTemplateIndex = clientTemplateIndex || clientTemplateIndex === 0;

    return `GC-${emailNotificationTypeId}` +
      (hasClientTemplateIndex ? `.${clientTemplateIndex + 1}` : '');
  }

  /**
   *
   * @param isApplicant: is this an applicant email?
   * @returns audience text
   */
  getAudienceText (isApplicant: boolean) {
    return this.i18n.translate(
      isApplicant ? 'common:lblApplicant' : 'GLOBAL:textGrantManager',
      {},
      isApplicant ? 'Applicant' : 'Grant manager'
    );
  }
}
