import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResultData, PaginationOptions } from '@yourcause/common';
import { AddEditItemModalResponse, ApplicantInKindItem, InKindCategories, InKindCategory, InKindCategoryItemStat } from './in-kind.typing';

@Injectable({ providedIn: 'root' })
export class InKindResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getInKindCategories (
    language?: string
  ): Promise<APIResultData<InKindCategory>> {
    const endpoint = 'api/manager/inkind/paginated/categories';
    const payload = {
      paginationOptions: {
        returnAll: true,
        retrieveTotalRecordCount: false,
        filterColumns: [],
        orFilterColumns: [],
        sortColumns: [],
        rowsPerPage: 100000,
        pageNumber: 0
      } as PaginationOptions<InKindCategory>
    };
    if (!!language) {
      (payload as any).language = language;
    }

    return this.httpRestService.post(endpoint, payload);
  }

  getInKindCategoriesByClient (clientId: number): Promise<APIResultData<InKindCategory>> {
    const endpoint = 'api/portal/applications/paginated/categories';

    return this.httpRestService.post(
      endpoint,
      {
        clientId,
        CategoryPaginationRequest: {
          paginationOptions: {
            returnAll: true,
            retrieveTotalRecordCount: false,
            filterColumns: [],
            orFilterColumns: [],
            sortColumns: [],
            rowsPerPage: 100000,
            pageNumber: 0
          }
        }
      }
    );
  }

  getApplicantCategoryItems (
    programId: number,
    items: string[],
    language: string
  ): Promise<ApplicantInKindItem[]> {
    const endpoint = `api/portal/programs/${programId}/ClientItems`;

    return this.httpRestService.post(endpoint, {
      itemIdentifications: items,
      language
    });
  }

  getCategoryItems (
    categoryIds: number[],
    language: string,
    paginationOptions: PaginationOptions<InKindCategoryItemStat>
  ): Promise<APIResultData<InKindCategoryItemStat>> {
    const endpoint = 'api/manager/inkind/paginated/items';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      categoryIds,
      language
    });
  }

  uploadCsvList (
    categoryId: number,
    file: File
  ) {
    const endpoint = `api/manager/inkind/import/${categoryId}`;

    return this.httpRestService.postFile(endpoint, file);
  }

  addCategory (
    name: string,
    type: InKindCategories,
    language: string
  ): Promise<number> {
    const endpoint = 'api/manager/inkind/create/category';

    return this.httpRestService.post(endpoint, {
      name,
      type,
      language
    });
  }

  addOrEditCategoryItem (
    categoryId: number,
    modalResponse: AddEditItemModalResponse,
    isUpdate = false
  ) {
    const endpoint = isUpdate ?
      'api/manager/inkind/edit/item' :
      'api/manager/inkind/create/item';

    return this.httpRestService.post<number>(endpoint, {
      categoryId,
      identification: modalResponse.identification,
      name: modalResponse.name,
      units: modalResponse.units,
      value: modalResponse.value
    });
  }

  toggleItemIsActive (
    identification: string,
    toInactive = false
  ) {
    const endpoint = `api/manager/inkind/set/item/inuse`;

    return this.httpRestService.post(endpoint, {
      inUse: !toInactive,
      identification
    });
  }

  async getAllItemsByCategory (
    categoryIds: number[],
    language: string
  ): Promise<InKindCategoryItemStat[]> {
    // For Funding Source Calculator, return all
    const endpoint = 'api/manager/inkind/paginated/items';
    const items = await this.httpRestService.post<APIResultData<InKindCategoryItemStat>>(
      endpoint,
      {
        paginationOptions: {
          returnAll: true,
          retrieveTotalRecordCount: false,
          filterColumns: [],
          orFilterColumns: [],
          sortColumns: [],
          rowsPerPage: 1000000,
          pageNumber: 0
        },
        categoryIds,
        language
      }
    );

    return items.records;
  }

  editCategory (payload: { categoryId: number; name: string }) {
    return this.httpRestService.post('api/manager/inkind/edit/category', payload);
  }
}
