
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HelpContentService } from '@features/platform-admin/help-content/help-content.service';
import { ReleaseNoteService } from '@features/platform-admin/release-notes/services/release-notes.service';
import { SupportService } from '@features/support/support.service';
import { SidebarLink } from '@yourcause/common';
import { TrackingEventNames } from '@yourcause/common/heap';
import { ButtonAction } from '@yourcause/common/buttons';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { filter } from 'rxjs';
import { AppShellState } from '../states/app-shell.state';
import { LocationService } from './location.service';
import { PortalDeterminationService } from './portal-determination.service';

export const defaultApplicantLink = 'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-applicants.html';
export const defaultGMLink = 'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/home.html';

@Injectable({ providedIn: 'root' })
@AttachYCState(AppShellState)
export class AppShellService extends BaseYCService<AppShellState> {

  constructor (
    private router: Router,
    private releaseNoteService: ReleaseNoteService,
    private supportService: SupportService,
    private helpContentService: HelpContentService,
    private locationService: LocationService,
    private portal: PortalDeterminationService
  ) {
    super();
  }

  get sidebarClosed () {
    return this.get('sidebarClosed');
  }

  get sidebarLinks () {
    return this.get('sidebarLinks');
  }

  toggleSidebar () {
    this.set('sidebarClosed', !this.sidebarClosed);
  }

  setSidebarLinks (links: SidebarLink[]) {
    this.set('sidebarLinks', links);
  }

  registerSupportLinkListener () {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        this.setSupportLinks();
      });
  }

  setSupportLinks () {
    const currentGenericRoute = this.locationService.getGenericRoute();

    const applicableHelpContents = this.helpContentService
      .contents?.filter(content => {
        const contentGenericRoute = this.locationService.getGenericRoute(content.grantsConnectLink);

        return currentGenericRoute.startsWith(contentGenericRoute);
      });

    let helpContentLink = this.portal.isApply ?
      defaultApplicantLink :
      defaultGMLink;

    if (applicableHelpContents.length) {
      const applicableHelpContent = applicableHelpContents
        .reduce((val, next) => {
          // if val has a longer link, we assume it is more accurate and use that
          return val.grantsConnectLink.length > next.grantsConnectLink.length ?
            val : next;
        });

      helpContentLink = applicableHelpContent.helpContentLink;

      if (!helpContentLink.startsWith('https:')) {
        helpContentLink = `https://${helpContentLink}`;
      }
    }
    const releaseNotes = this.releaseNoteService.releaseNoteForManager?.releaseNotesUrl;

    const links: ButtonAction[] = [{
      i18nKey: 'common:btnOpenHelp',
      i18nDefault: 'Open help',
      icon: 'external-link',
      onClick: () => {
        this.supportService.trackClickAndOpenLink(TrackingEventNames.OpenHelp, helpContentLink);
      }
    }, !!releaseNotes ? {
      i18nKey: 'common:btnWhatsNew',
      i18nDefault: 'What\'s new',
      icon: 'circle-info',
      iconClass: 'text-success',
      onClick: () => {
        this.supportService.trackClickAndOpenLink(TrackingEventNames.WhatsNew, releaseNotes);
      }
    } : undefined, {
      i18nKey: 'common:btnContactSupport',
      i18nDefault: 'Contact support',
      icon: 'envelope',
      onClick: () => {
        this.supportService.openSupportEmailModal();
      }
    }, {
      i18nKey: this.supportService.liveChatAvailable ?
        'common:liveChat' :
        'common:textLiveChatIsUnavailable',
      i18nDefault: this.supportService.liveChatAvailable ?
        'Live chat' :
        'Live chat (currently unavailable)',
      icon: 'comment',
      iconClass: this.supportService.liveChatAvailable ? 'text-success' : 'text-warning',
      onClick: () => {
        if (this.supportService.liveChatAvailable) {
          this.supportService.openLiveChat();
        }
      }
    }].filter((item) => !!item);
    this.set('supportLinks', links);
  }

  setActiveHelpLink (link: string) {
    this.set('activeHelpLink', link);
  }

  setShowHeaderButtons (show: boolean) {
    this.set('showHeaderButtons', show);
  }
}
