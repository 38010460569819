<div [formGroup]="formGroup">
  <yc-typeahead-select
    [name]="compKey"
    [items]="options"
    [disabled]="isDisabled"
    [placeholder]="translations[placeholder] || placeholder"
    [tabIndex]="tabIndex"
    [addRequiredAsterisk]="isRequired"
    [label]="label"
    [description]="description"
    (onChange)="decisionChange($event)">
  </yc-typeahead-select>
</div>