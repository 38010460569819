import { UserInfo } from '@core/typings/client-user.typing';
import { GrantActivityTypes } from '@features/application-activity/activity.typing';
import { FormAudience } from '@features/configure-forms/form.typing';
import { EmailNotificationType, EmailStatus } from '@features/system-emails/email.typing';
import { YcFile } from '@yourcause/common/files';
import { Fragment } from 'email-reply-parser';

export enum CommunicationTypes {
  MEETING_AND_INTERACTION = 1,
  EXTERNAL_COMMUNICATION = 2,
  DOCUMENTATION = 3,
  NONPROFIT_DOC = 4,
  WORKFLOW_COMMENT = 5,
  SYSTEM_EMAIL = 6,
  CYCLE_COMMENT = 7,
  EXTERNAL_EMAIL = 8
}
export interface AdaptedExternalCommRecord extends Communication {
  latestComm: Fragment;
  previousCommsThread: Fragment[];
};

export enum CommunicationVisibility {
  ONLY_ME = 1,
  ALL_GRANT_MANAGERS = 2,
  MANAGERS_IN_LEVEL = 3
}

export interface Communication {
  createdBy?: UserInfo;
  updatedBy?: UserInfo;
  description?: string;
  isRich?: boolean;
  communicationId?: number;
  joinCommunicationId?: number;
  visibility?: CommunicationVisibility;
  publishToNonprofit?: boolean;
  type: CommunicationTypes;
  subject: string;
  date: string;
  updatedDate?: string;
  content: string;
  emailStatusType?: EmailStatus;
  isManual?: boolean;
  ccEmails?: string[];
  bccEmails?: string[];
  emailAttachments?: CommEmailAttachment[];
  emailMergeDocuments?: CommEmailAttachment[];
  fileAttachment?: YcFile;
  fileName?: string;
  fileAccessURL?: string;
  applicationId?: number;
  toFriendlyName?: string;
  emailNotificationType?: EmailNotificationType;
  emailNumber?: string;
  files: {
    fileName: string;
    fileUploadId: number;
  }[];
}

export interface BaseAPICommunication {
  content: string;
  communicationId?: number;
  subject: string;
  date: string;
  visibility: CommunicationVisibility;
  type: CommunicationTypes;
  fileAttachments?: string[];
  fileUploadIds?: number[];
}

export interface ApplicationAPICommunication extends BaseAPICommunication {
  applicationCommunicationId?: number;
  applicationId: number;
  publishToNonprofit: boolean;
}

export interface OrganizationAPICommunication extends BaseAPICommunication {
  organizationCommunicationsId?: number;
  organizationId: number;
}

export interface BaseSimpleCommunication {
  communicationId: number;
  subject: string;
  date: string;
  updatedDate: string;
  fileAttachments?: string[];
  visibility: CommunicationVisibility;
  type: CommunicationTypes;
  createdBy: UserInfo;
  updatedBy: UserInfo;
  fileUploadIds: number[];
  fileName: string;
  fileAccessURL: string;
  emailNotificationType: EmailNotificationType;
  files: {
    fileName: string;
    fileUploadId: number;
  }[];
}

export interface SimpleApplicationCommunication extends BaseSimpleCommunication {
  applicationCommunicationId: number;
}

export interface SimpleOrganizationCommunication extends BaseSimpleCommunication {
  organizationCommunicationId: number;
}

export type SimpleCommunication =
  SimpleOrganizationCommunication |
  SimpleApplicationCommunication;

export interface SimpleEmail {
  id: number;
  fromAddress: string;
  fromFriendlyName: string;
  toAddress: string;
  toFriendlyName: string;
  subject: string;
  sentDate: string;
  createdDate: string;
  updatedDate: string;
  emailNotificationType: EmailNotificationType;
  emailStatusType: EmailStatus;
  body?: string;
  workflowLevelName?: string;
  workflowName?: string;
  ccEmails?: string[];
  bccEmails?: string[];
  emailAttachments: CommEmailAttachment[];
  emailNumber: string;
}

export interface SimpleComment {
  id: number;
  clientId: number;
  clientUserId: number;
  workflowId: number;
  workflowLevelId: number;
  workflowName: string;
  workflowLevelName: string;
  applicationId: number;
  formId: number;
  formRevisionId: number;
  notes: string;
  createdDate: string;
  createdBy: UserInfo;
  updatedDate: string;
  updatedBy: UserInfo;
  active: boolean;
  formName: string;
}

export interface SimpleActivityComment {
  activityId: number;
  comments: string;
  grantActivityType: GrantActivityTypes;
  createdBy: UserInfo;
  createdDate: string;
  workflowName: string;
  workflowLevelName: string;
  grantProgramCycleName: string;
  previousGrantProgramCycleName: string;
  emailModel?: ApplicationEmailModel;
}

export interface ApplicationEmailModel {
  id: number;
  createdDate: string;
  emailNotificationType: EmailNotificationType;
  emailStatusType: EmailStatus;
  fromAddress: string;
  sentDate: string;
  subject: string;
  toFriendlyName: string;
  toAddress: string;
  fromFriendlyName: string;
  updatedDate: string;
  workflowLevelName: string;
  workflowName: string;
  audienceType: FormAudience;
  ccEmails: string[];
  bccEmails: string[];
}

export interface APIOrganizationCommunicationList {
  manualCommunicationRecords: SimpleCommunication[];
}

export interface APIApplicationCommunicationList extends APIOrganizationCommunicationList {
  emails: SimpleEmail[];
  comments: SimpleComment[];
}

export interface ApplicationCommunication extends SimpleApplicationCommunication {
  content: string;
  applicationId: number;
}

export interface FullEmail {
  body: string;
  ccEmails: string[];
  bccEmails: string[];
  applicationId: number;
  emailAttachments: CommEmailAttachment[];
  emailMergeDocuments: CommEmailAttachment[];
  id: number;
  fromAddress: string;
  fromFriendlyName: string;
  toAddress: string;
  toFriendlyName: string;
  subject: string;
  sentDate: string;
  createdDate: string;
  updatedDate: string;
  emailNotificationType: EmailNotificationType;
  emailStatusType: EmailStatus;
  workflowLevelName: string;
  workflowName: string;
  audienceType: FormAudience;
  clientId: number;
}

export interface CommEmailAttachment {
  fileUploadId: number;
  fileName: string;
  uniqueFileName: string;
  fileType: string;
  bucketType: number;
  bucketName: string;
  accessURL: string;
}

export interface CommunicationsByDate {
  date: string;
  communications: Communication[];
}
