import { Pipe, PipeTransform } from '@angular/core';
import { FormDefinition, FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { ComponentHelperService } from '../services/component-helper/component-helper.service';

@Pipe({
  name: 'gcFlattenFormDefinition',
  pure: false
})
export class FlattenFormDefinitionPipe implements PipeTransform {
  constructor (
    private componentHelper: ComponentHelperService
  ) { }

  /**
   * Flattens the form definition
   *
   * @param value The form definition to flatten
   * @param shouldFlatten Whether to flatten the form definition or not
   * @returns The flattened form definition
   */
  transform (value: FormDefinition, shouldFlatten: boolean): FormDefinitionComponent[] {
    if (!shouldFlatten) {
      return value.components;
    }

    return this.componentHelper.flattenFormDefinition(value);
  }
}
