<yc-modal 
  [modalHeader]="'breadcrumbs:EditForm' | ycI18n: 'Edit Form'"
  [modalSubHeader]="formName"
  [primaryButtonText]="'FORMS:textEditExisting' | ycI18n: 'Edit existing version'"
  [secondaryButtonText]="'FORMS:textEditLatest' | ycI18n: 'Edit latest version'"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  (onPrimaryClick)="handlePrimaryClick()"
  (onSecondaryClick)="handleSecondaryClick()"
  (onCancel)="closeModal.emit()">

  <div>
    <yc-i18n
      key="FORMS:textConfirmEditPublishedForm"
      [context]="{
          formNumber: formRevisionNumber
      }"
      defaultValue="Are you sure you want to edit v__formNumber__? There is a newer version of this form that has not been published yet.">
    </yc-i18n>
  </div>

</yc-modal>