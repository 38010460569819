import { TypeaheadSelectOption } from '@yourcause/common/core-forms';

export enum CustomDataTableModalPages {
  NAME = 1,
  IMPORT = 2
}

export enum AddCustomDataTableModalResponse {
  CREATE_TABLE_ONLY = 1,
  CREATE_AND_IMPORT = 2,
  IMPORT_ONLY = 3
}

export interface CustomDataTable {
  id: number;
  name: string;
  guid: string;
  createdDate: string;
  updatedDate: string;
  createdBy: string;
  updatedBy: string;
  isSystem: boolean;
  hasOptions: boolean;
  defaultLanguageId: string;
  parentPicklistId: number;
  dataType: PicklistDataType;
}

export interface CustomDataTableDetailViewOption {
  id: number;
  key: string;
  value: string;
  inUse: boolean;
  sortOrder: number;
  createdDate: string;
  updatedDate: string;
  parentKeys: string[];
}

export interface CustomDataTableOption {
  id: number;
  key: string;
  // key is constant and used to track
  values: DataTableOptionTranslations[];
  // values are what displays depending on languageID
  inUse: boolean;
  createdDate: string;
  updatedDate: string;
  sortOrder: number;
  picklistOptionDependentPicklists: PicklistOptionDependentPicklist[];
}
export interface PicklistOptionDependentPicklist {
  parentPicklistId: number;
  parentPicklistOptionId: number;
  parentPicklistOptionKey: string;
  dependentPicklistId: number;
  dependentPicklistOptionId: number;
  dependentPicklistOptionKey: string;
}

export interface DataTableOptionTranslations {
  languageId: string;
  text: string;
}

export interface KeyValue {
  key: string;
  value: string;
  inUse: boolean;
  parentKeysString: string;
  sortOrder: number;
  parentKeys: string[];
}
export interface KeyValueFromAPI extends KeyValue {
  picklistGuid: string;
}
export interface CustomDataTableExternalContext {
  dynamicType: 'string'|'number';
  requiresParentListValidation: boolean;
  parentListKeys: string[];
  picklistId: number;
}
export interface UpdatePicklistPayload {
  id: number;
  name: string;
  file: Blob;
  defaultLanguageId: string;
  parentPicklistId?: number;
}

export enum DeleteCDTModalScenario {
  IsUsedByFormFields,
  IsAParentFormField,
  IsAbleToDelete
}

export enum PicklistDataType {
  Text = 1,
  Numeric = 2
}

export interface PicklistConflictInfo {
  conflictInfo: Record<string, PicklistConflict>;
  conflictResolutionRequiredCount: number;
}

export interface PicklistConflict {
  picklistOption: PicklistOption;
  conflictResolutionRequired: boolean;
  picklistOptionWithSameKey: PicklistOption;
  picklistOptionWithSameValue: PicklistOption;
  picklistOptionWithSameKeyAndValue: PicklistOption;
}

export interface PicklistOption {
  picklistId: number;
  id: number;
  key: string;
  value: string;
}

export interface PicklistConflictForUi extends PicklistConflict {
  id: string;
}

export interface ConflictResolutionInfo {
  items: TypeaheadSelectOption[];
  picklistOptionId: number;
}

export interface MergePicklistsPayload {
  newPicklistName: string;
  picklistToKeepId: number;
  picklistToMergeId: number;
  conflictResolutions: Record<string, number>;
}

export interface MergeOptionsPayload {
  picklistId: number;
  picklistOptionToKeepId: number;
  picklistOptionToMergeId: number;
  picklistOptionValueToKeepId: number;
}

export interface UpdateSortOrderPayload {
  picklistId: number;
  picklistOptionsWithSortOrder: {
    picklistOptionId: number;
    sortOrder: number;
  }[];
}
