<ng-container *ngIf="infiniteLoops.length > 0">
  <yc-i18n
    key="common:infiniteLoopsDetected"
    defaultValue="There were invalid rules detected. Please correct your formula to continue">
  </yc-i18n>

  <div *ngFor="let loop of infiniteLoops">
    {{ loop }}
  </div>
</ng-container>

<yc-alert [alertClass]="'info'" [message]="formulaString"></yc-alert>

<gc-formula-builder-step
  [step]="formula.step"
  [isViewOnly]="isViewOnly"
  [columns]="columns"
  [currentDepth]="0"
  [maxDepth]="1"
  (stepChange)="onFormulaStepChange($event)"
  (validityChanged)="checkValidity($event)">
</gc-formula-builder-step>
