import { Pipe, PipeTransform } from '@angular/core';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';

@Pipe({name: 'gcCheckboxNumberValue'})
export class CheckboxNumberValuePipe implements PipeTransform {

  constructor (
    private formFieldHelperService: FormFieldHelperService
  ) { }

  transform (value: number): boolean {
    return this.formFieldHelperService.convertNumberToCheckboxValue(value);
  }
}
