import { AbstractControl } from '@angular/forms';
import { IsValidTypes } from '@features/forms/component-configuration/component-configuration.typing';
import { InKindRequestedItem } from '@features/in-kind/in-kind.typing';

export function SelectedOneOfValidator (
  validationItem: string,
  isValidType: IsValidTypes,
  customErrorMessage: string,
  defaultErrorMessage: string
) {
  return (control: AbstractControl) => {
    const value = control.value as InKindRequestedItem[] ?? [];
    let isValid: boolean;
    if (isValidType === IsValidTypes.Valid) {
      isValid = value?.some((item) => {
        return item.itemIdentification === validationItem;
      });
    } else {
      isValid = value?.every((item) => {
        return item.itemIdentification !== validationItem;
      });
    }

    if (!isValid) {
      return {
        selectedOneOfValidator: {
          i18nKey: '',
          defaultValue: '',
          errorMessage: customErrorMessage || defaultErrorMessage
        }
      };
    }

    return null;
  };

};
