import { Component, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormGroupDirective, ValidatorFn } from '@angular/forms';
import { BaseApplication } from '@core/typings/application.typing';
import { FormFieldChangeIndicatorClass, FormFieldChangeIndicatorIcon } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { TypeSafeFormBuilder } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { BaseFormComponent } from '../../base/base.component';


@Component({
  selector: 'gc-form-attention',
  templateUrl: './form-attention.component.html',
  styleUrls: ['form-attention.component.scss']
})
export class FormAttentionComponent extends BaseFormComponent<string> implements OnInit {
  attentionLabel = this.i18n.translate(
    'FORMS:textAttention',
    {},
    'Attention'
  );
  attentionDescription = this.i18n.translate(
    'FORMS:textAttentionDescription',
    {},
    'Attention allows payments to go to a specific person or department at the above address'
  );
  showAttentionFieldLabel = this.i18n.translate(
    'FORMS:textThisAppRequiresAttentionLine',
    {},
    'This application requires attention line'
  );
  customLabelIcon = FormFieldChangeIndicatorIcon;
  customLabelIconTooltip = this.i18n.translate(
    'common:textThisFieldWasUpdatedByTheApplicant',
    {},
    'This field was updated by the applicant'
  );
  customLabelIconClass = FormFieldChangeIndicatorClass;

  constructor (
    public formBuilder: TypeSafeFormBuilder,
    private i18n: I18nService,
    public formBuilderService: FormBuilderService,
    public componentHelper: ComponentHelperService,
    @Optional() formGroupDir: FormGroupDirective,
    @Optional() renderer: FormRendererComponent<BaseApplication>
  ) {
    super(renderer, formGroupDir, formBuilder, formBuilderService, componentHelper);
  }

  ngOnInit () {
    super.ngOnInit();
    if (this.comp) {
      this.setFormGroup(this.data, []);
      this.handlePrepopData();
    }
  }

  handlePrepopData () {
    if (!!this.data) {
      this.fieldIsRequiredChange(true);
    }
  }

  generateFormGroup () {
    let validators: ValidatorFn[] = [];
    if (
      this.comp.customValidation &&
      !this.inFormBuilder
    ) {
      validators = [
        ...validators,
        this.customValidator()
      ];
    }
    this.formGroup = this.formBuilder.group({
      [this.compKey]: [
        this.data || '',
        validators
      ],
      fieldIsRequired: !!this.data
    }, {
      validator: [
        this.isRequiredValidator()
      ]
    });
  }

  patchValue () {
    if (this.control) {
      this.control.setValue(this.data || '');
    }
  }

  fieldIsRequiredChange (isChecked: boolean) {
    if (isChecked) {
      this.comp.validate.required = true;
    } else {
      this.comp.validate.required = false;
      this.control.setValue('');
    }
  }

  dataChanged (value: string) {
    this.onValueChange.emit({
      value,
      updateFormGroup: false
    });
  }

  isRequiredValidator () {
    return (group: AbstractControl) => {
      let returnVal = null;
      const isRequired = group.value.fieldIsRequired;
      if (isRequired) {
        const hasAttention = group.value[this.compKey];
        if (!hasAttention) {
          returnVal = {
            [this.compKey]: {
              required: {
                i18nKey: 'common:textThisInputIsRequired',
                defaultValue: 'This input is required'
              }
            }
          };
        }
      }

      return returnVal;
    };
  }
}
