import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { AddEditUser, PaginatedUsersResponse, SimpleUser, UserFromApi } from '@core/typings/client-user.typing';
import { GrantManagerUser } from '@core/typings/grant-manager.typing';
import { AudienceForUser, UsersImport, UsersValidationPayload } from '@core/typings/user.typing';
import { PaginationOptions } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class UserResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getUsersPaginatedWithPolicies (
    paginationOptions: PaginationOptions<UserFromApi>
  ): Promise<PaginatedUsersResponse> {
    const endpoint = 'api/client/userspaginatedwithpolicies';

    return this.httpRestService.post(endpoint, {
      paginationOptions
    });
  }

  getAllUsers (): Promise<SimpleUser[]> {
    const endpoint = 'api/client/clientusers';

    return this.httpRestService.get(endpoint);
  }

  async getAllUsersDetailed (): Promise<UserFromApi[]> {
    const paginationOptions: PaginationOptions<UserFromApi> = {
      rowsPerPage: 12,
      pageNumber: 0,
      sortColumns: [{
        columnName: 'fullName',
        sortAscending: true
      }],
      filterColumns: [],
      retrieveTotalRecordCount: true,
      returnAll: true
    };
    const endpoint = 'api/client/userspaginated';

    const result = await this.httpRestService.post<Promise<PaginatedUsersResponse>>(endpoint, {
      paginationOptions
    });

    return result.users.records;
  }

  addEditUser (user: AddEditUser) {
    const endpoint = 'api/client/users';

    return this.httpRestService.post(endpoint, user);
  }

  activateUser (id: number) {
    const endpoint = `api/client/users/${id}/activate`;

    return this.httpRestService.post(endpoint, {});
  }

  deactivateUser (ids: number[]) {
    const endpoint = `api/client/users/deactivate`;

    return this.httpRestService.post(endpoint, {
      ids
    });
  }

  async getAdminPermissions () {
    const endpoint = `api/admin/clients/getuserpermissions`;

    return this.httpRestService.get(endpoint);
  }

  importUsers (users: UsersImport[]) {
    const payload = {
      clientUsersToImport: users
    };
    const endpoint = `api/client/import`;

    return this.httpRestService.post(endpoint, payload);
  }

  validateUsers (
    validationPayload: UsersValidationPayload
  ): Promise<UsersValidationPayload> {
    const endpoint = 'api/client/import/validate';

    return this.httpRestService.post(endpoint, validationPayload);
  }

  exportRoles () {
    const endpoint = 'api/client/export/roles';

    return this.httpRestService.get(endpoint);
  }

  exportWFLs () {
    const endpoint = 'api/manager/workflows/export/workflowlevels';

    return this.httpRestService.get(endpoint);
  }

  exportUsersGMPortal (getActiveUsers: boolean, getInActiveUsers: boolean) {
    const endpoint = `api/client/export/clientusers?getActiveUsers=${getActiveUsers}&getInActiveUsers=${getInActiveUsers}`;

    return this.httpRestService.get(endpoint);
  }

  exportGrantManagersAdminPortal (paginationOptions: PaginationOptions<GrantManagerUser>) {
    const endpoint = 'api/admin/GrantManager/paginated/export';

    return this.httpRestService.post(endpoint, {
      paginationOptions
    });
  }

  getAudiencesForUser (id: number): Promise<AudienceForUser[]> {
    const endpoint = `api/manager/audience/GetAudiencesForUser/${id}`;

    return this.httpRestService.get(endpoint);
  }
}


