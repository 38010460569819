import { ApplicantFromSearch } from '@core/typings/applicant.typing';
import { OfflineProgramDetail } from '@core/typings/program.typing';
import { CyclesUI } from '@core/typings/ui/cycles.typing';
import { VettingInfo } from '@features/offline-grants/offline-grants-create-edit-application/organization/organization.component';
import { SearchResult } from '@yourcause/common';

export enum OfflineCreateModalAction {
  SEND_TO_APPLICANT = 1,
  SAVE_AS_DRAFT = 2,
  SAVE_AND_EDIT = 3
}

export enum SubmitApplicationOptions {
  ENTER_DEFAULT_LEVEL = 1,
  SELECT_A_LEVEL = 2
}

export interface CreateEditApplicationForApi {
  applicationId: number;
  grantProgramId: number;
  grantProgramCycleId: number;
  applicantId: number;
  organizationId: number;
  applicantCanReceiveEmails: boolean;
  sendEmailToApplicant: boolean;
  amountRequested?: number;
}

export interface CreateEditApplicationModalResponse {
  program: OfflineProgramDetail;
  cycle: CyclesUI.ProgramCycle;
  grantProgramId: number;
  cycleId: number;
  selectedApplicant: ApplicantFromSearch;
  selectedOrg: SearchResult;
  applicantCanReceiveEmails: boolean;
  vettingInfo: VettingInfo;
  modalAction: OfflineCreateModalAction;
}
