<yc-modal *ngIf="builderName && component"
  [modalHeader]="builderName"
  [modalSubHeader]="currentColumnName"
  [primaryButtonText]="'common:textApply' | ycI18n: 'Apply'"
  [secondaryButtonText]="isViewOnly ?
    '' :
    'common:linkClearAllLogic' | ycI18n: 'Clear all logic'"
  [tertiaryButtonText]="isViewOnly ?
    '' :
    'common:textDeleteRule' | ycI18n: 'Delete rule'"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  [primaryDisabled]="!valid || isViewOnly"
  (onPrimaryClick)="handleSubmit()"
  (onSecondaryClick)="handleClear()"
  (onTertiaryClick)="deleteRule()"
  (onCancel)="handleCancel()">

  <gc-value-logic-builder
    [formId]="formId"
    [isViewOnly]="isViewOnly"
    [component]="component"
    [logicValueFormatType]="logicValueFormatType"
    [options]="options"
    [sourceColumn]="sourceColumn"
    [availableColumns]="availableColumns"
    [maxDepth]="3"
    [logic]="logic"
    [alwaysTrueAvailable]="alwaysTrueAvailable"
    (logicChanged)="handleLogicChange($event)"
    (validChange)="handleValidChange($event)">
  </gc-value-logic-builder>
</yc-modal>
