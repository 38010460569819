import { Component, Input } from '@angular/core';
import { TypeToken } from '@yourcause/common';
import { ApplicationEmailPdf, EmailPdfForUi, EmailPdfType } from '../email.typing';

@Component({
  selector: 'gc-email-pdf',
  templateUrl: './email-pdf.component.html'
})
export class EmailPdfComponent {
  @Input() emails: EmailPdfForUi[];
  @Input() emailPdfType: EmailPdfType;

  EmailPdfTypes = EmailPdfType;
  $applicationEmailPdfType = new TypeToken<ApplicationEmailPdf>();
}
