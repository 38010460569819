import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormGroupDirective, ValidatorFn } from '@angular/forms';
import { CurrencyService } from '@core/services/currency.service';
import { ValidatorsService } from '@core/services/validators.service';
import { BaseApplication } from '@core/typings/application.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormFieldChangeIndicatorClass, FormFieldChangeIndicatorIcon } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { TypeSafeFormBuilder, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { CurrencyRadioOptions, CurrencyValue } from '@yourcause/common/currency';
import { I18nService } from '@yourcause/common/i18n';
import { isEqual } from 'lodash';
import { BaseFormComponent } from '../../base/base.component';

@Component({
  selector: 'gc-form-amount-requested',
  templateUrl: './form-amount-requested.component.html',
  styleUrls: ['./form-amount-requested.component.scss']
})
export class FormAmountRequestedComponent extends BaseFormComponent<CurrencyValue> implements OnInit {
  @Input() useCustomCurrency: CurrencyRadioOptions;
  @Input() forceDefaultCurrency: boolean;
  @Input() currencyOptions: TypeaheadSelectOption[] = [];
  @Input() canToggleCurrency = false;

  formattingData = this.currencyService.formattingData;
  CurrencyRadioOptions = CurrencyRadioOptions;
  customLabelIcon = FormFieldChangeIndicatorIcon;
  customLabelIconTooltip = this.i18n.translate(
    'common:textThisFieldWasUpdatedByTheApplicant',
    {},
    'This field was updated by the applicant'
  );
  customLabelIconClass = FormFieldChangeIndicatorClass;
  afterInit = false;

  constructor (
    private clientSettingsService: ClientSettingsService,
    private i18n: I18nService,
    private currencyService: CurrencyService,
    public formBuilder: TypeSafeFormBuilder,
    private validatorService: ValidatorsService,
    public formBuilderService: FormBuilderService,
    public componentHelper: ComponentHelperService,
    @Optional() formGroupDir: FormGroupDirective,
    @Optional() renderer: FormRendererComponent<BaseApplication>
  ) {
    super(renderer, formGroupDir, formBuilder, formBuilderService, componentHelper);
  }

  get currency () {
    if (this.forceDefaultCurrency) {
      return this.defaultCurrency;
    }

    return this.data?.currency;
  }

  get equivalentCurrency () {
    if (this.forceDefaultCurrency) {
      return this.data?.currency;
    }

    return this.defaultCurrency;
  }

  get equivalentAmount () {
    if (this.forceDefaultCurrency) {
      return this.data?.amountEquivalent;
    }

    return this.data.amountInDefaultCurrency;
  }

  get defaultCurrency () {
    return this.clientSettingsService.defaultCurrency;
  }

  ngOnInit () {
    super.ngOnInit();
    let validators: ValidatorFn[] = [];
    if (!this.inFormBuilder) {
      validators = this.validatorService.getValidatorsForSimpleComponent(
        this.comp,
        true,
        false,
        false,
        this.translations
      );
    }
    this.setFormGroup(this.data, validators);
    this.afterInit = true;
  }

  patchValue () {
    if (
      this.afterInit &&
      !isEqual(this.data, this.control.value)
    ) {
      this.amountChanged();
    }
  }

  onAmountChange (amount = this.data.amountForControl) {
    this.control.markAsDirty();
    this.amountChanged(amount);
  }

  amountChanged (amount = this.data.amountForControl) {
    this.onValueChange.emit({
      value: {
        ...this.data,
        amountForControl: amount
      },
      updateFormGroup: true
    });
  }

  onCurrencyChange (currency: string) {
    if (!this.forceDefaultCurrency) {
      // The data setter will fire patch value, which will trigger onValueChange
      this.data = {
        ...this.data,
        currency
      };
    }
  }
}
