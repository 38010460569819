import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormMaskingService } from '@core/services/form-masking.service';
import { ApplicantFormForUI, ApplicationForUi } from '@core/typings/application.typing';
import { UserInfo } from '@core/typings/client-user.typing';
import { AwardService } from '@features/awards/award.service';
import { AwardFromApi } from '@features/awards/typings/award.typing';
import { ApplicationViewFormForUI, ApplicationViewFormFromApi, FormAudience, FormResponse, SubmissionMap, WorkflowLevelSubmissionForm } from '@features/configure-forms/form.typing';
import { WorkflowService } from '@features/workflow/workflow.service';
import { SimpleStringMap } from '@yourcause/common';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { DATE_TIME_FORMAT } from '@yourcause/common/date';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { uniqBy } from 'lodash';

export interface DownloadAppModalResponse {
  submissions: SubmissionMap[];
  isMasked: boolean;
  showActivityTrail: boolean;
  showEmails: boolean;
  showAwards: boolean;
  attachmentsType: DownloadAttachmentOptions;
}

export enum DownloadAttachmentOptions {
  All = 1,
  Form = 2,
  None = 3
}

@Component({
  selector: 'gc-download-application-pdf-modal',
  templateUrl: './download-application-pdf-modal.component.html',
  styleUrls: ['./download-application-pdf-modal.component.scss']
})
export class DownloadApplicationPDFModalComponent extends YCModalComponent<
  DownloadAppModalResponse
> implements OnInit, AfterViewInit {
  @Input() workflowId: number;
  @Input() applicationId: number;
  @Input() defaultForm: ApplicantFormForUI;
  @Input() forCurrentWfl: boolean;
  @Input() submissions: ApplicationViewFormForUI[];
  @Input() isNomination = false;
  @Input() applicationForUi: ApplicationForUi;
  @Input() applicationAwards: AwardFromApi[];
  @Input() applicantIsMaskedAndCantView: boolean;
  @Input() downloadImmediately = false; // Download the application without any user interaction

  submissionMaps: SubmissionMap[];
  FormAudience = FormAudience;
  valid = false;
  showMaskedDataToggle = false;
  notMasked = false;
  activityTrail = false;
  includeEmails = false;
  includeAwards = false;
  awardOptionNotVisible: boolean;
  DATE_TIME_FORMAT = DATE_TIME_FORMAT;
  attachmentsType: DownloadAttachmentOptions = DownloadAttachmentOptions.Form;
  attachmentOptions: TypeaheadSelectOption[] = [{
    label: this.i18n.translate('common:textIncludeAllAttachments', {}, 'Include all attachments'),
    value: DownloadAttachmentOptions.All
  }, {
    label: this.i18n.translate('common:textIncludeOnlyFormAttachments', {}, 'Only include form upload attachments'),
    value: DownloadAttachmentOptions.Form
  }, {
    label: this.i18n.translate('common:textDoNotIncludeAttachments', {}, 'Do not include attachments'),
    value: DownloadAttachmentOptions.None
  }];

  constructor (
    private arrayHelper: ArrayHelpersService,
    private i18n: I18nService,
    private workflowService: WorkflowService,
    private analyticsService: AnalyticsService,
    private awardService: AwardService,
    private formMaskingService: FormMaskingService
  ) {
    super();
  }

  ngOnInit () {
    this.awardOptionNotVisible = this.awardService.shouldHideAwardTab(
      this.applicationForUi,
      this.applicationAwards,
      this.isNomination
    );
    const workflowLevelMap: SimpleStringMap<WorkflowLevelSubmissionForm[]> = {};
    this.submissions.forEach((form) => {
      form.responses.forEach((response) => {
        // Add to map if not default and has workflow level
        if (
          response.workflowLevelId &&
          +form.formId !== +this.defaultForm.formId
        ) {
          this.setWorkflowLevelMap(
            form,
            response,
            workflowLevelMap
          );
        }
      });
    });
    const workflowLevelItems = Object.keys(workflowLevelMap).map((key) => {
      const forms = workflowLevelMap[key].map((form) => {
        form.submissions = uniqBy(form.submissions, 'applicationFormId');

        return form;
      });

      return {
        workflowLevelName: forms[0].workflowLevelName,
        parentWorkflowLevelName: forms[0].parentWorkflowLevelName,
        workflowLevelId: +key,
        forms
      };
    });
    let submittedBy: UserInfo;
    if (this.defaultForm.submittedBy || this.defaultForm.createdBy) {
      submittedBy = {
        firstName: '',
        lastName: '',
        fullName: this.defaultForm.submittedBy || this.defaultForm.createdBy,
        userType: null,
        impersonatedBy: '',
        email: ''
      };
    }

    this.submissionMaps = [
      {
        workflowLevelName: this.i18n.translate(
          'PROGRAM:textDefaultForm',
          {},
          'Default form'
        ),
        workflowLevelId: null,
        forms: [{
          formId: this.defaultForm.formId,
          formName: this.defaultForm.name,
          formAudience: FormAudience.APPLICANT,
          submittedOn: this.defaultForm.formSubmittedOn,
          submissions: [{
            applicationFormId: this.defaultForm.applicationFormId,
            isDraft: this.defaultForm.isDraft,
            createdDate: '',
            updatedDate: this.defaultForm.updatedDate,
            revisionNotes: '',
            formComments: '',
            submittedDate: this.defaultForm.formSubmittedOn,
            applicationFormStatusId: this.defaultForm.formStatus,
            formData: this.defaultForm.formData,
            formDefinition: this.defaultForm.formDefinition,
            formRevisionId: this.defaultForm.formRevisionId,
            workflowLevelName: '',
            revisionLastSentDate: '',
            decision: null,
            reviewerRecommendedFundingAmount: null,
            dueDate: '',
            submittedBy
          }],
          selected: true
        }]
      },
      ...(this.arrayHelper.sort(workflowLevelItems, 'workflowLevelName'))
    ];

    this.setShowMaskedDataToggle();
    this.handleCheckboxChange();
    if (this.downloadImmediately) {
      this.activityTrail = true;
      this.includeAwards = true;
      this.attachmentsType = DownloadAttachmentOptions.All;
      this.notMasked = true;
    }
  }

  ngAfterViewInit () {
    if (this.downloadImmediately) {
      setTimeout(() => {
        this.handleSubmit();
      }, 1000);
    }
  }

  setShowMaskedDataToggle () {
    this.submissionMaps.forEach((item) => {
      item.forms.forEach((form) => {
        form.submissions.forEach((response) => {
          const maskResult = response.formDefinition &&
          this.formMaskingService.checkShouldShowMaskToggleForFormView(
            response.formDefinition
          );
          if (maskResult?.shouldShow) {
            this.showMaskedDataToggle = true;
          }
        });
      });
    });
  }

  setWorkflowLevelMap (
    form: ApplicationViewFormFromApi,
    response: FormResponse,
    workflowLevelMap: SimpleStringMap<WorkflowLevelSubmissionForm[]>
  ) {
    const workflowLevelName = response.workflowLevelName;
    const workflowLevelId = response.workflowLevelId;
    let parentWorkflowLevelName = '';
    this.workflowService.workflows.forEach((flow) => {
      flow.levels.forEach((level) => {
        level.subLevels.forEach((sub) => {
          if (sub.id === workflowLevelId) {
            parentWorkflowLevelName = level.name;
          }
        });
      });
    });
    const workflowLevelForms = workflowLevelMap[response.workflowLevelId];
    if (workflowLevelForms) {
      const foundThisFormIndex = workflowLevelForms.findIndex((f) => {
        return f.formId === form.formId;
      });
      if (foundThisFormIndex > -1) {
        workflowLevelMap[workflowLevelId] = [
          ...workflowLevelForms.slice(0, foundThisFormIndex),
          {
            ...workflowLevelForms[foundThisFormIndex],
            submissions: [
              ...workflowLevelForms[foundThisFormIndex].submissions,
              response
            ]
          },
          ...workflowLevelForms.slice(foundThisFormIndex + 1)
        ];
      } else {
        workflowLevelMap[workflowLevelId] = [
          ...workflowLevelForms,
          {
            formId: form.formId,
            formName: form.name,
            formAudience: form.audience,
            submittedOn: response.submittedDate,
            submissions: [
              response
            ],
            workflowLevelName,
            parentWorkflowLevelName,
            selected: this.downloadImmediately
          }
        ];
      }
    } else {
      workflowLevelMap[workflowLevelId] = [{
        formId: form.formId,
        formName: form.name,
        formAudience: form.audience,
        submittedOn: response.submittedDate,
        submissions: [
          response
        ],
        workflowLevelName,
        parentWorkflowLevelName,
        selected: this.downloadImmediately
      }];
    }
  }

  getSelected () {
    const selected = this.submissionMaps.filter((item) => {
      item.forms = item.forms.filter((form) => {
        return form.selected;
      });

      return item.forms.length > 0;
    });

    return selected;
  }

  handleSubmit () {
    this.closeModal.emit({
      submissions: this.getSelected(),
      isMasked: this.showMaskedDataToggle ?
        !this.notMasked :
        true,
      showActivityTrail: this.activityTrail,
      showEmails: this.includeEmails,
      showAwards: this.includeAwards,
      attachmentsType: this.attachmentsType
    });
    this.analyticsService.emitEvent({
      eventName: 'Download single application',
      eventType: EventType.Click,
      extras: null
    });
  }

  handleCheckboxChange () {
    let hasSelected = false;
    this.submissionMaps.forEach((item) => {
      item.forms.forEach((form) => {
        if (form.selected) {
          hasSelected = true;
        }
      });
    });

    this.valid = hasSelected;
  }
}
