
<ng-container *ngIf="formGroup">
  <div [formGroup]="formGroup">
    <yc-input
      [name]="compKey"
      [label]="label"
      [description]="description"
      [srOnlyLabel]="hideLabel"
      [placeholder]="translations[placeholder] || placeholder"
      [disabled]="isDisabled"
      [addRequiredAsterisk]="isRequired"
      [tabIndex]="tabIndex"
      [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
      [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
      [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
      (onChange)="dataChanged($event)">
    </yc-input>
  </div>
</ng-container>