import { Component } from '@angular/core';
import { FooterService } from '@core/services/footer.service';
import { LinkService } from '@core/services/link.service';

@Component({
  selector: 'gc-copyright-block',
  templateUrl: './gc-copyright-block.component.html',
  styleUrls: ['./gc-copyright-block.component.scss']
})
export class GcCopyrightBlockComponent {
  copyrightText = this.footerService.getCopyrightText();
  termsOfServiceLink = this.linkService.getTermsOfServiceLink();
  privacyPolicyLink = this.linkService.getPrivacyPolicyLink();

  constructor (
    private linkService: LinkService,
    private footerService: FooterService
  ) { }
}
