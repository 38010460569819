export default [
  {
    "id": 1,
    "goal": "No Poverty",
    "targets": [
      {
        "id": 1,
        "target": "Eradicate Extreme Poverty"
      },
      {
        "id": 2,
        "target": "Reduce People Living in Poverty"
      },
      {
        "id": 3,
        "target": "Implement Social Protection for People in Poverty"
      },
      {
        "id": 4,
        "target": "Increase Resources for People in Poverty"
      },
      {
        "id": 5,
        "target": "Reduce Exposure to Climate for People in Poverty"
      },
      {
        "id": 6,
        "target": "Enhance Poverty Support in Developing Countries"
      },
      {
        "id": 7,
        "target": "Create Poverty Policy Development"
      }
    ]
  },
  {
    "id": 2,
    "goal": "Zero Hunger",
    "targets": [
      {
        "id": 8,
        "target": "Improve Access to Nutritious Food"
      },
      {
        "id": 9,
        "target": "Eradicate Malnutrition"
      },
      {
        "id": 10,
        "target": "Increase Small-Scale Agriculture"
      },
      {
        "id": 11,
        "target": "Support Food Production Systems"
      },
      {
        "id": 12,
        "target": "Maintain Genetic Diversity of Plants, Animals, and Other Resources"
      },
      {
        "id": 13,
        "target": "Expand Agriculture Production in Developing Countries"
      },
      {
        "id": 14,
        "target": "Foster World Agricultural Markets"
      },
      {
        "id": 15,
        "target": "Limit Food Price Volatility"
      }
    ]
  },
  {
    "id": 3,
    "goal": "Good Health and Well-Being",
    "targets": [
      {
        "id": 16,
        "target": "Reduce Maternity Mortality Rate"
      },
      {
        "id": 17,
        "target": "Lower Newborn and Children Mortality Rate"
      },
      {
        "id": 18,
        "target": "Eliminate Epidemics"
      },
      {
        "id": 19,
        "target": "Increase Prevention and Treatment for Mental Health"
      },
      {
        "id": 20,
        "target": "Strengthen Prevention and Treatment of Substance Abuse"
      },
      {
        "id": 21,
        "target": "Reduce Deaths by Road Accidents"
      },
      {
        "id": 22,
        "target": "Ensure Access to Reproductive Healthcare Services"
      },
      {
        "id": 23,
        "target": "Provide Health Coverage, Medicines, and Vaccines"
      },
      {
        "id": 24,
        "target": "Reduce Contamination and Hazardous Chemicals"
      },
      {
        "id": 25,
        "target": "Advance Tobacco Control"
      },
      {
        "id": 26,
        "target": "Expand Vaccine and Medical Research"
      },
      {
        "id": 27,
        "target": "Increase Health Workforce in Developing Countries"
      },
      {
        "id": 28,
        "target": "Boost Capacity for Risk Reduction and Disaster Management"
      }
    ]
  },
  {
    "id": 4,
    "goal": "Quality Education",
    "targets": [
      {
        "id": 29,
        "target": "Ensure Quality Education"
      },
      {
        "id": 30,
        "target": "Improve Quality of Early Childhood Development"
      },
      {
        "id": 31,
        "target": "Foster Equal Access to Education"
      },
      {
        "id": 32,
        "target": "Develop Employment Skills"
      },
      {
        "id": 33,
        "target": "Ensure Gender Equality in Education"
      },
      {
        "id": 34,
        "target": "Increase Literacy"
      },
      {
        "id": 35,
        "target": "Improve Development Skills"
      },
      {
        "id": 36,
        "target": "Advance Education Facilities"
      },
      {
        "id": 37,
        "target": "Expand Scholarships to Developing Countries"
      },
      {
        "id": 38,
        "target": "Increase Qualified Teachers"
      }
    ]
  },
  {
    "id": 5,
    "goal": "Gender Equality",
    "targets": [
      {
        "id": 39,
        "target": "End Discrimination"
      },
      {
        "id": 40,
        "target": "Eradicate Violence Against Women"
      },
      {
        "id": 41,
        "target": "End Forced Marriage and Female Genital Mutilation"
      },
      {
        "id": 42,
        "target": "Increase Public Services for Women"
      },
      {
        "id": 43,
        "target": "Promote Female Leadership"
      },
      {
        "id": 44,
        "target": "Guarantee Access to Reproductive Health and Reproductive Rights"
      },
      {
        "id": 45,
        "target": "Foster Equality in Ownership and Resources"
      },
      {
        "id": 46,
        "target": "Strengthen Equality Policy"
      },
      {
        "id": 170,
        "target": "Empower Women Through Technology"
      }
    ]
  },
  {
    "id": 6,
    "goal": "Clean Water and Sanitization",
    "targets": [
      {
        "id": 47,
        "target": "Increase Access to Safe Drinking Water"
      },
      {
        "id": 48,
        "target": "Improve Sanitation and Hygiene"
      },
      {
        "id": 49,
        "target": "Reduce Water Pollution"
      },
      {
        "id": 50,
        "target": "Increase Water-Use Efficiency"
      },
      {
        "id": 51,
        "target": "Implement Water Resource Management"
      },
      {
        "id": 52,
        "target": "Protect Water Ecosystems"
      },
      {
        "id": 53,
        "target": "Develop Water Support in Developing Countries"
      },
      {
        "id": 54,
        "target": "Strengthen Water Improvement in Local Communities"
      }
    ]
  },
  {
    "id": 7,
    "goal": "Affordable and Clean Energy",
    "targets": [
      {
        "id": 55,
        "target": "Ensure Affordable Energy Services"
      },
      {
        "id": 56,
        "target": "Increase Renewable Energy"
      },
      {
        "id": 57,
        "target": "Improve Energy Efficiency"
      },
      {
        "id": 58,
        "target": "Enhance Clean Energy Research and Technology"
      },
      {
        "id": 59,
        "target": "Expand Energy Technology in Developing Countries"
      }
    ]
  },
  {
    "id": 8,
    "goal": "Decent Work and Economic Growth",
    "targets": [
      {
        "id": 60,
        "target": "Sustain Economic Growth"
      },
      {
        "id": 61,
        "target": "Foster Economic Productivity"
      },
      {
        "id": 62,
        "target": "Promote Development Policies"
      },
      {
        "id": 63,
        "target": "Improve Economic Growth in Developing Countries"
      },
      {
        "id": 64,
        "target": "Increase Employment"
      },
      {
        "id": 65,
        "target": "Reduce Unemployed and Uneducated Youth"
      },
      {
        "id": 66,
        "target": "Eliminate Forced Labor, Slavery, and Human Trafficking"
      },
      {
        "id": 67,
        "target": "Promote Safe Work Environments"
      },
      {
        "id": 68,
        "target": "Create Sustainable Tourism"
      },
      {
        "id": 69,
        "target": "Strengthen Domestic Financial Institutions"
      },
      {
        "id": 70,
        "target": "Increase Trade Support for Developing Countries"
      },
      {
        "id": 71,
        "target": "Advance Youth Employment"
      }
    ]
  },
  {
    "id": 9,
    "goal": "Industry, Innovation, and Infrastructure",
    "targets": [
      {
        "id": 72,
        "target": "Development Infrastructure"
      },
      {
        "id": 73,
        "target": "Promote Sustainable Industrialization"
      },
      {
        "id": 74,
        "target": "Expand Industrial Enterprises in Developing Countries"
      },
      {
        "id": 75,
        "target": "Increase Resource-Use Efficiency"
      },
      {
        "id": 76,
        "target": "Enhance Research and Technology in Industrial Sector"
      },
      {
        "id": 77,
        "target": "Improve Infrastructure in Developing Countries"
      },
      {
        "id": 78,
        "target": "Advance Technology Innovation in Developing Countries"
      },
      {
        "id": 79,
        "target": "Provide Access to Internet"
      }
    ]
  },
  {
    "id": 10,
    "goal": "Reduced Inequalities",
    "targets": [
      {
        "id": 80,
        "target": "Sustain Income Growth"
      },
      {
        "id": 81,
        "target": "Empower Equality"
      },
      {
        "id": 82,
        "target": "Ensure Equal Opportunity"
      },
      {
        "id": 83,
        "target": "Promote Equality Policies"
      },
      {
        "id": 84,
        "target": "Improve Global Regulations"
      },
      {
        "id": 85,
        "target": "Increase Voice for Developing Countries"
      },
      {
        "id": 86,
        "target": "Facilitate Migration"
      },
      {
        "id": 87,
        "target": "Implement Trade in Developing Countries"
      },
      {
        "id": 88,
        "target": "Promote Investments in Developing Countries"
      },
      {
        "id": 89,
        "target": "Reduce Migration Cost"
      }
    ]
  },
  {
    "id": 11,
    "goal": "Sustainable Cities and Communities",
    "targets": [
      {
        "id": 90,
        "target": "Ensure Affordable Housing"
      },
      {
        "id": 91,
        "target": "Increase Public Transportation"
      },
      {
        "id": 92,
        "target": "Enhance Urbanization"
      },
      {
        "id": 93,
        "target": "Protect Cultural Heritage"
      },
      {
        "id": 94,
        "target": "Increase Disaster Relief"
      },
      {
        "id": 95,
        "target": "Reduce Environmental Impact of Cities"
      },
      {
        "id": 96,
        "target": "Increase Public Spaces"
      },
      {
        "id": 97,
        "target": "Promote Economic, Social, and Environmental Development Planning"
      },
      {
        "id": 98,
        "target": "Increase Disaster Risk Management"
      },
      {
        "id": 99,
        "target": "Support Infrastructure in Developing Countries"
      }
    ]
  },
  {
    "id": 12,
    "goal": "Responsible Consumption and Production",
    "targets": [
      {
        "id": 100,
        "target": "Promote Sustainable Consumption and Production"
      },
      {
        "id": 101,
        "target": "Increase Natural Resources"
      },
      {
        "id": 102,
        "target": "Reduce Food Waste"
      },
      {
        "id": 104,
        "target": "Increase Reuse and Recycling"
      },
      {
        "id": 105,
        "target": "Promote Corporate Sustainability Practices"
      },
      {
        "id": 106,
        "target": "Support Sustainability Policy"
      },
      {
        "id": 107,
        "target": "Improve Education"
      },
      {
        "id": 108,
        "target": "Support Sustainability in Developing Countries"
      },
      {
        "id": 109,
        "target": "Develop Tourism"
      },
      {
        "id": 110,
        "target": "Reduce Financial Barriers to Sustainability"
      },
      {
        "id": 111,
        "target": "Reduce Chemicals, Waste, and Pollution"
      }
    ]
  },
  {
    "id": 13,
    "goal": "Climate Action",
    "targets": [
      {
        "id": 112,
        "target": "Strengthen Natural Disaster Relief"
      },
      {
        "id": 113,
        "target": "Implement Climate Change Policy"
      },
      {
        "id": 114,
        "target": "Improve Education"
      },
      {
        "id": 115,
        "target": "Support Developing Countries"
      },
      {
        "id": 116,
        "target": "Develop Climate Change Management"
      }
    ]
  },
  {
    "id": 14,
    "goal": "Life Below Water",
    "targets": [
      {
        "id": 117,
        "target": "Reduce Marine Pollution"
      },
      {
        "id": 118,
        "target": "Protect Ecosystems"
      },
      {
        "id": 119,
        "target": "Reduce Acidification"
      },
      {
        "id": 120,
        "target": "Regulate Illegal and Destructive Fishing Practices"
      },
      {
        "id": 121,
        "target": "Increase Marine Conservation"
      },
      {
        "id": 122,
        "target": "Reduce Fishing Subsidies"
      },
      {
        "id": 123,
        "target": "Advance Tourism and Economic Benefits"
      },
      {
        "id": 124,
        "target": "Develop Education"
      },
      {
        "id": 125,
        "target": "Support Local Markets"
      },
      {
        "id": 126,
        "target": "Enhance Marine Law"
      }
    ]
  },
  {
    "id": 15,
    "goal": "Life on Land",
    "targets": [
      {
        "id": 127,
        "target": "Increase Ecosystem Conservation"
      },
      {
        "id": 128,
        "target": "Manage Forest Sustainability"
      },
      {
        "id": 129,
        "target": "Combat Desertification"
      },
      {
        "id": 130,
        "target": "Support Mountain Conservation"
      },
      {
        "id": 131,
        "target": "Reduce Degradation of Natural Habitats"
      },
      {
        "id": 132,
        "target": "Share Equitable Resource Access"
      },
      {
        "id": 133,
        "target": "End Poaching and Trafficking"
      },
      {
        "id": 134,
        "target": "Reduce Alien Species"
      },
      {
        "id": 135,
        "target": "Expand Biodiversity"
      },
      {
        "id": 136,
        "target": "Grow Financial Resources"
      },
      {
        "id": 137,
        "target": "Mobilize Resources"
      },
      {
        "id": 138,
        "target": "Develop Global Support"
      }
    ]
  },
  {
    "id": 16,
    "goal": "Peace and Justice Strong Institutions",
    "targets": [
      {
        "id": 139,
        "target": "Reduce Violence"
      },
      {
        "id": 140,
        "target": "End Abuse and Trafficking"
      },
      {
        "id": 141,
        "target": "Promote International Justice"
      },
      {
        "id": 142,
        "target": "Lower Organized Crime"
      },
      {
        "id": 143,
        "target": "Eradicate Corruption"
      },
      {
        "id": 144,
        "target": "Foster Institutions"
      },
      {
        "id": 145,
        "target": "Increase Inclusive Decision-Making"
      },
      {
        "id": 146,
        "target": "Strengthen Global Governance in Developing Countries"
      },
      {
        "id": 147,
        "target": "Provide Legal Identity"
      },
      {
        "id": 148,
        "target": "Protect Fundamental Freedoms"
      },
      {
        "id": 149,
        "target": "Combat Terrorism"
      },
      {
        "id": 150,
        "target": "Promote Non-Discriminatory Policy"
      }
    ]
  },
  {
    "id": 17,
    "goal": "Partnerships for the Goals",
    "targets": [
      {
        "id": 151,
        "target": "Improve Financial Capabilities for Developing Countries"
      },
      {
        "id": 152,
        "target": "Implement ODA Commitments"
      },
      {
        "id": 153,
        "target": "Mobilize Financial Resources"
      },
      {
        "id": 154,
        "target": "Assist Developing Countries"
      },
      {
        "id": 155,
        "target": "Implement Investment Promotion"
      },
      {
        "id": 156,
        "target": "Enhance Technology"
      },
      {
        "id": 157,
        "target": "Promote Sustainable Technology"
      },
      {
        "id": 158,
        "target": "Operationalize Technology Bank"
      },
      {
        "id": 159,
        "target": "Support Capacity Building in Developing Countries"
      },
      {
        "id": 160,
        "target": "Promote Equal Trade"
      },
      {
        "id": 161,
        "target": "Increase Exports in Developing Countries"
      },
      {
        "id": 162,
        "target": "Implement Free Markets"
      },
      {
        "id": 163,
        "target": "Enhance Global Macroeconomic Stability"
      },
      {
        "id": 164,
        "target": "Enhance Policy Coherence"
      },
      {
        "id": 165,
        "target": "Respect Sustainable Policy"
      },
      {
        "id": 166,
        "target": "Support Multi-Stakeholder Partnerships"
      },
      {
        "id": 167,
        "target": "Promote the Creation of Partnerships"
      },
      {
        "id": 168,
        "target": "Enhance Data Monitoring"
      },
      {
        "id": 169,
        "target": "Increase Accountability for Development"
      }
    ]
  }
]