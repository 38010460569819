<yc-alert
  [alertClass]="isValid ? 'info' : 'danger'"
  [message]="'common:textColumnWidthHelp' | ycI18n: 'Total column width cannot exceed 12.'">
</yc-alert>

<yc-table
  [rows]="columns"
  [dragAndDropEnabled]="columns.length > 1"
  [simple]="true"
  (onDrop)="drop($event)">

  <!-- Width -->
  <ng-container *ycTableColumn="'common:textWidth'; let index = index; let row;">
    <yc-input
      [name]="'width_' + index"
      [disabled]="isViewOnly"
      [isNumber]="true"
      [numberOfDecimals]="0"
      [(value)]="row.width"
      [srOnlyLabel]="true"
      [label]="'common:textWidthRowIndex' | ycI18n: 'Width for row __number__': {
        index
      }"
      (valueChange)="handleChange('width', row.width, index)">
    </yc-input>
  </ng-container>

  <!-- Offset -->
  <ng-container *ycTableColumn="'common:textOffset'; let index = index; let row;">
    <yc-input
      [name]="'offset_' + index"
      [isNumber]="true"
      [disabled]="isViewOnly"
      [numberOfDecimals]="0"
      [(value)]="row.offset"
      [srOnlyLabel]="true"
      [label]="'common:textOffsetRowIndex' | ycI18n: 'Offset for row __number__': {
        index
      }"
      (valueChange)="handleChange('offset', row.offset, index)">
    </yc-input>
  </ng-container>

  <!-- Push -->
  <ng-container *ycTableColumn="'common:textPush'; let index = index; let row;">
    <yc-input
      [name]="'push_' + index"
      [isNumber]="true"
      [numberOfDecimals]="0"
      [disabled]="isViewOnly"
      [(value)]="row.push"
      [srOnlyLabel]="true"
      [label]="'common:textPushRowIndex' | ycI18n: 'Push for row __number__': {
        index
      }"
      (valueChange)="handleChange('push', row.push, index)">
    </yc-input>
  </ng-container>

  <!-- Pull -->
  <ng-container *ycTableColumn="'common:textPull'; let index = index; let row;">
    <yc-input
      [name]="'pull_' + index"
      [disabled]="isViewOnly"
      [numberOfDecimals]="0"
      [isNumber]="true"
      [(value)]="row.pull"
      [srOnlyLabel]="true"
      [label]="'common:textPullRowIndex' | ycI18n: 'Pull for row __number__': {
        index
      }"
      (valueChange)="handleChange('pull', row.pull, index)">
    </yc-input>
  </ng-container>

  <ng-container *ycTableColumn="''
    let row;
    class: 'text',
    let index = index;
    locked: true">
    <yc-button *ngIf="columns.length > 1"
      [icon]="true"
      [fontAwesomeIcon]="'times'"
      [textDanger]="true"
      [tooltipText]="'common:textRemove' | ycI18n: 'Remove'"
      (onClick)="removeRow(index)">
    </yc-button>
  </ng-container>
    
</yc-table>

<!-- Add Row -->
<div class="d-flex justify-content-end">
  <yc-button
    [primary]="true"
    [disabled]="isViewOnly || !(columns | gcCanAddColumns)"
    [translatedText]="'common:textAddColumn' | ycI18n: 'Add column'"
    (onClick)="addColumn()">
  </yc-button>
</div>