import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { FormBuilderActions } from '../form-builder.typing';
import { FormBuilderService } from '../services/form-builder/form-builder.service';

@Component({
  selector: 'gc-form-builder-component-actions',
  templateUrl: './form-builder-component-actions.component.html',
  styleUrls: ['./form-builder-component-actions.component.scss']
})
export class FormBuilderComponentActionsComponent implements OnChanges {
  @Input() componentKey: string;
  @Input() allowCopy = true;
  @Input() allowEditFormField = true;
  @Input() componentType: string;
  @Output() actionClick = new EventEmitter<FormBuilderActions>();

  FormBuilderActions = FormBuilderActions;
  allowPasteInto: boolean;
  isInvalidType: boolean;
  isTypeFormField = false;

  get clipboardFull () {
    return !!this.formBuilderService.copiedComponent;
  }

  constructor (
    private formBuilderService: FormBuilderService,
    private componentHelper: ComponentHelperService,
    private formFieldHelperService: FormFieldHelperService
  ) { }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.componentType) {
      const isStandardComponent = this.componentHelper.isStandardComponent(
        this.componentType
      );
      const invalidTypes = [
        ReferenceFieldsUI.ReferenceFieldTypes.ExternalAPI,
        ReferenceFieldsUI.ReferenceFieldTypes.Aggregate
      ];
      const field = this.formFieldHelperService.getReferenceFieldFromCompType(this.componentType);
      this.isTypeFormField = !!field;
      const isValidType = !isStandardComponent && !invalidTypes.includes(field?.type);
      this.isInvalidType = !isValidType;
      this.allowPasteInto = this.componentHelper.allowPasteIntoContainer(
        this.componentType
      );
    }
  }
}
