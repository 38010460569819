export namespace SystemTags {
  export enum Buckets {
    Global = 1,
    Application = 2,
    Nomination = 3,
    NonprofitProfile = 4,
    Payment = 5,
    Applicant = 6,
    Budget = 7
  }

  export const BucketEnumMapping = {
    [SystemTags.Buckets.Applicant]: 'common:lblApplicant',
    [SystemTags.Buckets.Application]: 'common:lblApplication',
    [SystemTags.Buckets.Budget]: 'common:lblBudget',
    [SystemTags.Buckets.Global]: 'tags:lblGlobal',
    [SystemTags.Buckets.Nomination]: 'FORMS:textNomination',
    [SystemTags.Buckets.NonprofitProfile]: 'tags:lblNonprofitProfile',
    [SystemTags.Buckets.Payment]: 'common:lblPayment'
  };

  export interface BaseEntityTag {
    active: boolean;
    id: number;
    relatedEntityId: number;
  }

  export interface EntityTagForUi extends BaseEntityTag {
    tag: TagDefinitionForUi;
  }

  export interface EntityTagFromApi extends BaseEntityTag {
    tag: TagDefinitionForApi;
  }

  export interface BulkTagResponse extends EntityTagFromApi {
    applicationId: number;
  }

  export interface AddTagModalResult {
    tag: TagDefinitionForUi;
    reopenModal: boolean;
    wantsToConvert: boolean;
  }

  export interface InactiveModalResult {
    tag: TagDefinitionForUi;
    change: 'delete'|'Enable';
  }

  export enum TagColor {
    Blue = 1,
    Green = 2,
    Red = 3,
    Yellow = 4
  }

  // Database stores string but needed number enum above for import
  export enum TagColorText {
    Blue = 'Blue',
    Green = 'Green',
    Red = 'Red',
    Yellow = 'Yellow'
  }

  export const ColorEnumMapping = {
    [SystemTags.TagColor.Blue]: 'common:textBlue',
    [SystemTags.TagColor.Green]: 'common:textGreen',
    [SystemTags.TagColor.Red]: 'common:textRed',
    [SystemTags.TagColor.Yellow]: 'common:textYellow'
  };


  export interface AddTagRequest {
    name: string;
    description: string;
    color: TagColorText;
    tagBucketId: Buckets;
  }

  export interface UpdateTagRequest extends AddTagRequest {
    id: number;
  }

  export interface ImportTagRequest extends UpdateTagRequest {
    enabled: boolean;
  }

  export interface TagDefinitionBase {
    id?: number;
    name: string;
    description: string;
    bucketId: Buckets;
    enabled: boolean;
    active: boolean;
  }

  export interface TagDefinitionForApi extends TagDefinitionBase {
    color: TagColorText;
  }

  export interface TagDefinitionForUi extends TagDefinitionBase {
    color: TagColor;
  }

  export interface TagBucket {
    id: number;
    name: string;
  }

  export interface TagBucketWithTags extends TagBucket {
    activeTags: TagDefinitionForUi[];
    inactiveTags: TagDefinitionForUi[];
  }

  export interface SetTags {
    [x: string]: string | number | {
        tagId: number;
        active: boolean;
    }[];
    tags: {
        tagId: number;
        active: boolean;
    }[];
  }

  export interface TagsFormGroup {
    tags: number[];
    newTagName: string;
  }

  export interface TagImportForUi {
    id?: number;
    name: string;
    bucket: Buckets;
    inactive: boolean;
    color: TagColor;
  }

  export interface TagImportForApi {
    id?: number;
    name: string;
    description: string;
    color: TagColorText;
    tagBucketId: Buckets;
    enabled: boolean;
  }
}

