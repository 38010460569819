import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SignatureState } from '@core/states/signature.state';
import { ApplicationFormSignature, FormAudience, FormTypes } from '@features/configure-forms/form.typing';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { DATE_TIME_FORMAT, DateService } from '@yourcause/common/date';
import { FileService, FileTypeService, YcFile } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { SignatureModalResponse, SignatureTabType } from '@yourcause/common/signature';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { lastValueFrom } from 'rxjs';
import { SignatureResources } from './signature.resources';


@AttachYCState(SignatureState)
@Injectable({ providedIn: 'root' })
export class SignatureService extends BaseYCService<SignatureState> {

  constructor (
    private signatureResources: SignatureResources,
    private fileService: FileService,
    private logger: LogService,
    private i18n: I18nService,
    private notifier: NotifierService,
    private formService: FormsService,
    private sanitizer: DomSanitizer,
    private dateService: DateService,
    private fileTypeService: FileTypeService
  ) {
    super();
  }

  get userSignature () {
    return this.get('userSignature');
  }

  get savedSignatureFile () {
    return this.get('savedSignatureFile');
  }

  get formSignatureMap () {
    return this.get('formSignatureMap');
  }

  /**
   *
   * @param formType type of form
   * @returns the default signature description by form type
   */
  getDefaultFormSignatureDescription (formType: FormTypes) {
    const audience = this.formService.getAudienceFromFormType(formType);
    const isNomination = formType === FormTypes.NOMINATION;
    if (audience === FormAudience.APPLICANT) {
      return this.getDefaultFormDescriptionApplicant(isNomination);
    } else {
      return this.getDefaultFormDescriptionManager();
    }
  }

  /**
   *
   * @param isNomination is nomination
   * @returns the default form description for an applicant form
   */
  getDefaultFormDescriptionApplicant (isNomination: boolean) {
    return this.i18n.translate(
      isNomination ?
        'common:textDefaultSignatureDescNom' :
        'common:textDefaultSignatureDescApp',
      {},
      isNomination ?
        'In order to proceed, please use one of the options below to sign and submit your nomination. By clicking Submit I understand that this is a legal representation of my signature.' :
        'In order to proceed, please use one of the options below to sign and submit your application. By clicking Submit I understand that this is a legal representation of my signature.'
    );
  }

  /**
   *
   * @returns the default form description for a manager form
   */
  getDefaultFormDescriptionManager () {
    return this.i18n.translate(
      'common:textDefaultSignatureDescManager',
      {},
      'In order to proceed, please use one of the options below to sign and submit your form. By clicking Submit I understand that this is a legal representation of my signature.'
    );
  }

  /**
   *
   * @param formName name of the form
   * @param formAudience form audience
   * @param formSignatureDescription signature description stored on the form
   * @param isNomination is nomination
   * @returns the updated signature description and if bypass is supported
   */
  getSignatureFormDescriptionManagerPortal (
    formName: string,
    formAudience: FormAudience,
    formSignatureDescription: string,
    isNomination: boolean
  ) {
    const supportsBypass = formAudience === FormAudience.APPLICANT;
    let signatureDescription = formSignatureDescription;
    if (supportsBypass) {
      const bypassText = this.i18n.translate(
        isNomination ?
          'common:textBypassNomineeText' :
          'common:textBypassApplicantText',
        {
          formName
        },
        isNomination ?
          'A signature is required to submit __formName__. You can sign on behalf of the nominee or bypass the signature.' :
          'A signature is required to submit __formName__. You can sign on behalf of the applicant or bypass the signature.'
      );
      signatureDescription = `<div class="mb-3">${bypassText}</div>
        <div>${formSignatureDescription}</div>`;
    }

    return {
      supportsBypass,
      signatureDescription
    };
  }

  /**
   * Sets the users signature info if not already set
   */
  async setSignature () {
    if (!this.userSignature) {
      try {
        const userSignature = await this.signatureResources.getUserSignature();
        this.set('userSignature', userSignature);
        if (userSignature.userSignatureUrl) {
          const blob = await this.fileService.getBlob(userSignature.userSignatureUrl);
          const blobUrl = this.fileService.convertFileToUrl(blob as File);
          // This content is from a trusted source (our API)
          const cleanUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl) as string;
          const savedSignatureFile = new YcFile(
            'signature.png',
            blob,
            cleanUrl,
            userSignature.userSignatureId
          );
          this.set('savedSignatureFile', savedSignatureFile);
        }
      } catch (e) {
        this.logger.error(e);
      }
    }
  }

  /**
   * Updates to the latest users signature info
   */
  async resetUserSignature () {
    this.set('userSignature', undefined);
    this.set('savedSignatureFile', undefined);
    await this.setSignature();
  }

  /**
   *
   * @param signatureResponse Response from the signature modal
   * @returns Uploads the signature if necessary and returns the ID
   */
  async handleSignatureModalResponse (
    signatureResponse: SignatureModalResponse
  ): Promise<number> {
    if (signatureResponse.type !== SignatureTabType.Saved) {
      return this.uploadSignature(signatureResponse);
    } else {
      return this.userSignature.userSignatureId;
    }
  }

  /**
   *
   * @param signatureModalResponse Response from the signature modal
   * @returns Uploads the signature and returns the ID
   */
  async uploadSignature (signatureModalResponse: SignatureModalResponse) {
    try {
      const fileId = await this.signatureResources.uploadSignature(
        signatureModalResponse.file.file as File
      );
      await this.resetUserSignature();

      return fileId;
    } catch (err) {
      const e = err as HttpErrorResponse;
      this.fileTypeService.displayInvalidFileUploadErrorMessage(e?.error?.message, e);

      return null;
    }
  }

  /**
   *
   * @param signatureResponse Response from the signature modal
   * Updates the users signature
   */
  async handleUpdateSignature (
    signatureResponse: SignatureModalResponse
  ) {
    try {
      await this.handleSignatureModalResponse(signatureResponse);
      this.notifier.success(this.i18n.translate(
        'common:textSuccessfullyUpdatesSignature',
        {},
        'Successfully updated electronic signature'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorUpdatingSignature',
        {},
        'There was an error updating electronic signature'
      ));
    }
  }

  async getSignaturesBulk (
    applicationIds: number[],
    formIds: number[]
  ) {
    const signatures = await this.signatureResources.getSignaturesBulk(
      applicationIds,
      formIds
    );
    signatures.forEach((signature) => {
      this.setSignatureOnMap(
        signature,
        signature.applicationFormId
      );
    });

    return signatures;
  }

  /**
   *
   * @param applicationId: application id
   * @param applicationFormId: application form id
   * @returns signature info
   */
  async setFormSignature (
    applicationId: number,
    applicationFormId: number,
    forPDF: boolean
  ) {
    const signature = this.formSignatureMap[applicationFormId];
    if (!signature || forPDF) {
      const response = await this.getSignatureForApplicationFormResponse(
        applicationId,
        applicationFormId,
        forPDF
      );
      if (response) {
        if (forPDF) {
          return response;
        } else {
          this.setSignatureOnMap(response, applicationFormId);
        }
      }
    }

    return this.formSignatureMap[applicationFormId];
  }

  /**
   *
   * @param signature: signature to set
   * @param applicationFormId: application form id
   */
  setSignatureOnMap (
    signature: ApplicationFormSignature,
    applicationFormId: number
  ) {
    const updatedMap = {
      ...this.formSignatureMap,
      [applicationFormId]: {
        ...signature,
        signedDate: this.dateService.formatDate(signature.signedDate, DATE_TIME_FORMAT)
      }
    };
    this.set('formSignatureMap', updatedMap);
  }

  /**
   *
   * @param applicationId: Application ID
   * @param applicationFormId: Application Form ID
   * @returns Signature Response from API
   */
  async getSignatureForApplicationFormResponse (
    applicationId: number,
    applicationFormId: number,
    forPDF: boolean
  ) {
    try {
      const signatureResponse = await this.signatureResources.getSignatureForApplicationFormResponse(
        applicationId,
        applicationFormId
      );

      const signature = signatureResponse ? signatureResponse[0] : null;
      if (signature && signature.userSignatureUrl) {
        if (forPDF) {
          const blob = await this.fileService.getBlob(signature.userSignatureUrl);
          const blobUrl = await lastValueFrom(this.fileService.convertFileToB64(blob as File));

          return {
            ...signature,
            userSignatureUrl: blobUrl
          };
        } else {

          return signature;
        }
      } else {
        return null;
      }
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorRetrievingSignature',
        {},
        'There was an error retrieving the signature'
      ));

      return null;
    }
  }
}

