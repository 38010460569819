import { ApplicationStatuses } from '@core/typings/status.typing';
import { AttachmentType } from '@features/application-view/application-attachments/application-attachments.typing';
import { AwardFromApi } from '@features/awards/typings/award.typing';
import { CommunicationVisibility } from '@features/communications/communications.typing';
import { ApplicationViewFormForUI, AvailabilityOptions, FormData, FormDecisionTypes, FormDefinitionForUi, FormDefinitionWithLogic, FormResponse, FormStatuses, FormTypes, MyApplicationForm, ProgramFormForUi, ProgramFormFromApi, StandardApplicantRequiredFields, UserSignatureForBulk } from '@features/configure-forms/form.typing';
import { EmployeeSSOFieldBulkFromApi, EmployeeSSOFieldsData } from '@features/employee-sso-fields/employee-sso-fields.typing';
import { SpecialHandling, SpecialHandlingFromApi } from '@features/forms/form-renderer-components/standard-form-components/form-special-handling/form-special-handling.component';
import { InKindRequestedItem } from '@features/in-kind/in-kind.typing';
import { EmailOptionsModelForSave } from '@features/system-emails/email.typing';
import { APIResultData, OrganizationEligibleForGivingStatus, SimpleGrantsAddress, SimpleStringMap, Status } from '@yourcause/common';
import { GenericFile } from '@yourcause/common/files';
import { CyclesAPI } from './api/cycles.typing';
import { ReferenceFieldAPI } from './api/reference-fields.typing';
import { SimpleApplicant } from './applicant.typing';
import { ClientBrandingFromApi } from './branding.typing';
import { BudgetDetail, BudgetFundingSource } from './budget.typing';
import { UserInfo, UserTypes } from './client-user.typing';
import { BulkCollaboratorResponse, Collaborator } from './collaboration.typing';
import { ApplicantOrganizationForUi, CsrConnectStats, NonprofitDetail, OrganizationForInfoPanel, VettingRejectionReason, VettingRequestStatusId } from './organization.typing';
import { ProcessingTypes } from './payment.typing';
import { AllowedLocation, CanOrgSubmitMultipleAppsOptions, OfflineProgramDetail, ProgramApplicantType, ProgramDetailFromApi } from './program.typing';
import { AdHocReportingUI } from './ui/ad-hoc-reporting.typing';
import { CyclesUI } from './ui/cycles.typing';
import { ReferenceFieldsUI } from './ui/reference-fields.typing';
import { WorkflowLevelRoute, WorkflowLevelUsersWithoutReviews, WorkflowManagerActions } from './workflow.typing';
import { CommunicationForApplicant } from '@features/applicant-communications/typings/applicant-communications.typing';

export enum WorkflowTabOptions {
  SAVE_AS_DRAFT = 1,
  SUBMIT = 2,
  SUBMIT_AND_APPROVE = 3
}

export enum AppManagerTypes {
  Standard = 1,
  ProgramDashboard = 2,
  ApplicantProfile = 3,
  NonprofitProfile = 4,
  ColumnsOnly = 5
}

export interface ApplicationFromPaginated {
  applicationId: number;
  applicantId: number;
  applicantFirstName: string;
  applicantLastName: string;
  applicationLastName: string;
  applicantFullName: string;
  applicantEmail: string;
  applicantAddress: string;
  applicantAddressInfo: SimpleGrantsAddress;
  applicantPhoneNumber: string;
  organizationId: number;
  organizationName: string;
  nonprofitGuid: string;
  organizationIdentification: string;
  organizationImageUrl: string;
  organizationPhoneNumber?: number;
  organizationAddress?: string;
  organizationAddressInfo: SimpleGrantsAddress;
  orgPhoneNumber: string;
  isPrivateOrg?: boolean;
  programId: number;
  programName: string;
  submittedDate: string;
  applicationStatus: ApplicationStatuses;
  isApplicationArchived: boolean;
  workflowId: number;
  workflowName: string;
  currentWorkflowLevelId: number;
  currentWorkflowLevelName: string;
  createdBy: UserInfo;
  lastUpdatedBy: UserInfo;
  dateCreated: string;
  organizationEmail?: string;
  amountRequested: number;
  nominatedBy: UserInfo;
  nominationApplicationId: number;
  dateAddedToWorkflow: string;
  daysInWfl: number;
  paymentStats: ApplicationPaymentStats;
  canArchive: boolean;
  canViewMaskedApplicantInfo: boolean;
  isMasked: boolean;
  programAllowCollaboration: boolean;
  careOf: string;
  currencyRequested: string;
  currencyRequestedAmountEquivalent: number;
  referenceFields?: ReferenceFieldsUI.RefResponseMap;
  inKindItemTotalCount: number;
  inKindAmountRequested: number;
  isEdVetted: boolean;
  isArchived: boolean;
  isProgramArchived: boolean;
  clientOrganizationsProcessingTypeId: ProcessingTypes;
  grantProgramCycle: ApplicationCycle;
  reservedFunds: boolean;
  budgetId: number;
  fundingSourceId: number;
  organizationEligibleForGivingStatus: OrganizationEligibleForGivingStatus;
  recommendedFundingAmount: number;
  orgIsInternational: boolean;
  parentOrganizationName: string;
  parentOrganizationGuid: string;
  organizationNPPIsActive: boolean;
  updatedDate: string;
  grantProgramCycleId: number;
  grantProgramCycleName: string;
  designation: string;
  registrationAuthorityName: string;
  latestVettingRequestStatusForOrg: VettingRequestStatusId;
  rejectionReasonId: VettingRejectionReason;
  nonprofitVettingRequestStatusComment: string;
  declinedDate: string;
  previousApplicationStatusId: ApplicationStatuses;
}

export interface ApplicantAndOrgInfo {
  applicantFullName: string;
  organizationName: string;
}

export interface ApplicationPermissions {
  myActions: WorkflowManagerActions[];
  hasAllPermission: boolean;
}

export interface ApplicationFromPaginatedForUi extends ApplicationFromPaginated {
  cylceId: number;
  cycleName: string;
  status: Status;
  applicantAndOrgInfo: ApplicantAndOrgInfo;
  permissions: ApplicationPermissions;
  canApprove: boolean;
  canDecline: boolean;
  canArchiveUnarchive: boolean;
  canAwardPay: boolean;
  canDelete: boolean;
  canNotifyOfStatus: boolean;
  canRoute: boolean;
  canUpdateCycle: boolean;
  canUpdateStatus: boolean;
  canViewComms: boolean;
  canManageCollabs: boolean;
  canSendMailMerge: boolean;
  canAssignBudget: boolean;
  canCancel: boolean;
  canAddTags: boolean;
  canUpdateProgram: boolean;
}

export interface ApplicationRoute {
  workflowId: number;
  canRouteToWorkflowLevelId: number;
  canRouteToWorkflowLevelName: string;
  canRouteToWorkflowLevelDescription: string;
}

export interface SearchPaginatedResult extends APIResultData<ApplicationFromPaginated> {
  programFacets: SimpleStringMap<string>;
  cycleFacets: SimpleStringMap<string>;
}

export interface ApplicationPaymentStats {
  awarded: number;
  pending: number;
  paid: number;
}

export interface PaymentStats {
  awarded: number;
  pending: number;
  notPending: number;
  paymentsAmount: number;
  paymentsAvailableForProcessing: number;
  unarchivedApplicationCount: number;
}

export interface ApplicationFromApi {
  applicationId: number;
  applicationStatus: ApplicationStatuses;
  submittedDate: string;
  applicantId: number;
  applicantFirstName: string;
  applicantCanReceiveEmails: boolean;
  applicantLastName: string;
  applicantEmail: string;
  applicantPhoneNumber: string;
  applicantAddress: string;
  programName: string;
  programId: number;
  grantProgramTimezone: string;
  organizationId: number;
  charityId: number;
  organizationName: string;
  orgnizationImageUrl: string;
  organizationIdentification: string;
  organizationAddress: string;
  isPrivateOrg: boolean;
  daysInReview: number;
  isApplicationInClientUserWorkflowLevel: boolean;
  reviewRuleType: number;
  totalWorkflowLevelReviews: number;
  totalWorkflowLevelUsers: number;
  majorityReviewed: boolean;
  currentWorkflowName: string;
  currentWorkFlowLevelName: string;
  parentWorkFlowLevelName: string;
  parentWorkFlowLevelId: number;
  canRouteApplication: boolean;
  canArchiveAndUnarchiveApplication: boolean;
  canApproveApplication: boolean;
  canAwardApplication: boolean;
  canDeclineApplication: boolean;
  canExtendFormDueDate: boolean;
  canRecommendFunding: boolean;
  hasNonPaidPayments: boolean;
  workflowLevelUsersWithoutReviews: WorkflowLevelUsersWithoutReviews[];
  workflowLevelRoutes: WorkflowLevelRoute[];
  programAllowCollaboration: boolean;
  programSendEmailsToCollaborators: boolean;
  amountRequested: number;
  paymentDesignation: string;
  createdBy: UserInfo;
  lastUpdatedBy: UserInfo;
  updatedDate: string;
  createdDate: string;
  nonprofitGuid: string;
  currentWorkFlowLevelId: number;
  currentWorkFlowId: number;
  isArchived: boolean;
  nominee: Nominee;
  nominationApplicationId: number;
  canAccessNominationApplication: boolean;
  isMasked: boolean;
  canViewMaskedApplicantInfo: boolean;
  specialHandlingAddress1: string;
  specialHandlingAddress2: string;
  specialHandlingCity: string;
  specialHandlingCountry: string;
  specialHandlingName: string;
  specialHandlingPostalCode: string;
  specialHandlingStateProvinceRegion: string;
  specialHandlingNotes: string;
  specialHandlingFileUrl: string;
  specialHandlingReason: string;
  currencyRequestedAmountEquivalent: number;
  currencyRequested: string;
  latestVettingRequestStatusForOrg?: VettingRequestStatusId;
  inKindAmountRequested: number;
  inKindItems: InKindRequestedItem[];
  isProgramArchived: boolean;
  meetsVettingRequirement: boolean;
  grantProgramCycle: ApplicationCycle;
  preventOrgChange?: boolean;
  budgetId: number;
  fundingSourceId: number;
  reservedFunds: boolean;
  hasAwards: boolean;
  hasPayments: boolean;
  hasCashAward: boolean;
  careOf: string;
  organizationEligibleForGivingStatus: OrganizationEligibleForGivingStatus;
  recommendedFundingAmount: number;
  orgIsInternational: boolean;
  applicantIsSSO: boolean;
  applicationGuid: string;
  currentWorkflowLevelShowBudgetSummaryInfo: boolean;
  currentWorkflowLevelAllowUserToViewAwardsAndPayments: boolean;
  currentWorkflowLevelAllowAward: boolean;
  currentWorkflowLevelAllowBudgetAssignment: boolean;
  organizationAggregatedYearToDateAmountRequested: number;
  organizationAggregatedYearToDateAmountRequestedApproved: number;
  organizationAggregatedYearToDateAmountRequestedDeclined: number;
  organizationAggregatedYearToDateAmountRequestedCancelled: number;
  organizationAggregatedYearToDateAmountRequestedHold: number;
  organizationAggregatedYearToDateAmountRequestedInProgress: number;
  organizationAggregatedYearToDateAmountRequestedAwaitingReview: number;
  organizationAggregatedYearToDateAmountRequestedIsDraft: number;
  organizationAggregatedYearToDateAmountRequestedIsArchived: number;
  organizationAggregatedYearToDateAwarded: number;
  organizationAggregatedYearToDateAwardedApproved: number;
  organizationNPPIsActive: boolean;
  canViewAwardsAndPayments: boolean;
  isInWorkflowLevel: boolean;
  rejectionReasonId: VettingRejectionReason;
  nonprofitVettingRequestStatusComment: string;
  previousApplicationStatusId: ApplicationStatuses;
}

export interface BulkApplicationDetails {
  applicationBulkActionIndicators: BulkApplicationDetail[];
}

export interface BulkApplicationDetail extends SpecialHandlingFromApi {
  applicationId: number;
  inKindItems: InKindRequestedItem[];
  hasAwards: boolean;
  hasPayments: boolean;
  currentWorkflowLevelId: number;
  hasCashAward: boolean;
  hasInKindAward: boolean;
  programHasCashBudget: boolean;
  programHasInKindBudget: boolean;
  workflowLevelRoutes: {
    workflowId: number;
    workflowLevelId: number;
    canRouteToWorkflowLevelId: number;
    canRouteToWorkflowLevelName: string;
    canRouteToWorkflowLevelDescription: string;
  }[];
}

export interface ApplicationForUi extends ApplicationFromApi {
  amountRequestedForEdit: number;
  designation: string;
  specialHandling: SpecialHandling;
  defaultSpecialHandling?: SpecialHandling;
  orgAddressString?: string;
  decision?: FormDecisionTypes;
  reviewerRecommendedFundingAmount?: number;
  budgetAssignmentAutomationRuleSetId?: number;
}

export interface ApplicationViewPage extends ApplicationForUi {
  employeeInfo: EmployeeSSOFieldsData;
  referenceFields?: ReferenceFieldsUI.RefResponseMap;
}

export interface ApplicationDetail extends ApplicationActionFlags {
  forms: ApplicantFormForUI[];
  awards: ApplicationAwards;
  nominee?: Nominee;
  isInWorkflowLevel?: boolean;
  canAccessNominationApplication?: boolean;
  programTimezone?: string;
}

export interface ApplicationActionFlags {
  canApprove: boolean;
  canDecline: boolean;
  canArchiveUnarchive: boolean;
  canAwardPay: boolean;
  canDelete: boolean;
  canNotifyOfStatus: boolean;
  canRoute: boolean;
  canUpdateCycle: boolean;
  canUpdateStatus: boolean;
  canViewComms: boolean;
  canManageCollabs: boolean;
  canSendMailMerge: boolean;
  canCancel: boolean;
  canAddTags: boolean;
  canUpdateProgram: boolean;
  canAssignBudget: boolean;
}

export interface BaseApplicantForm {
  userType?: UserTypes;
  updatedBy: string;
  updatedDate: string;
  createdBy: string;
  impersonatedBy: string;
  createImpersonatedBy: string;
  formTypeId: FormTypes;
  applicationFormId: number;
  formId: number;
  formRevisionId: number;
  formName: string;
  formDescription: string;
  formStatus: FormStatuses;
  statusUpdatedDate: string;
  statusUpdatedBy?: number;
  formSubmittedOn: string;
  totalCommentsInCurrentWorkflow: number;
  canRequestRevision: boolean;
  canApproveApplicaitonForm: boolean;
  canDeclineApplicationForm: boolean;
  formData: FormData;
  statusUpdatedByClientUser: any;
  formComments: ApplicantFormComment[];
  myCommentInCurrentLevel: string;
  programApplicantType: ProgramApplicantType;
  isDefault: boolean;
  portalFormAvailabilityInfo: PortalFormAvailabilityInfo;
  dueDate: string;
  submittedBy: string;
  isDraft: boolean;
  requireSignature: boolean;
  signatureDescription: string;
}

export interface ApplicantFormFromApi extends BaseApplicantForm {
  formDefinition: FormDefinitionWithLogic|FormDefinitionWithLogic[];
}

export interface ApplicantFormForUI extends BaseApplicantForm {
  isOverdue: boolean;
  formDefinition: FormDefinitionForUi[];
  formResponse: FormResponse;
  name: string;
}

export interface ApplicantFormComment {
  workflowLevelId: number;
  workflowLevelName: string;
  firstName: string;
  lastName: string;
  notes: string;
  createdDate: string;
}

export interface PortalFormAvailabilityInfo {
  portalFormAvailabilityType: AvailabilityOptions;
  portalFormAvailabilityDateOption: number;
  portalFormAvailabilityDateOffset: string;
  portalFormAvailabilityDate: string;
  portalFormAvailabilityDynamicDateApplicationApproval: string;
  portalFormAvailabilityDynamicDateAwardCreation: string;
  portalFormAvailabilityDynamicDateApplicationSubmission: string;
  portalFormAvailabilityDynamicDateWorkflowLevelEntry: string;
}

export interface OfflineApplicationOrgInfo {
  program: OfflineProgramDetail;
  cycle: CyclesUI.ProgramCycle;
}

export type ApplicationManagerMap = Record<number, ApplicationDetail>;

export type ApplicationViewMap = Record<number, ApplicationViewDetail>;

export type ApplicationEditMap = Record<number, ApplicationEditDetail>;

export interface ApplicationViewDetail {
  application: ApplicationViewPage;
  formsForAppView: ApplicationViewFormForUI[];
  formsForEdit: ApplicantFormForUI[];
  nominee: Nominee;
  csrData: CsrConnectStats;
  program: ProgramDetailFromApi;
  budget: BudgetDetail;
  budgetFundingSource: BudgetFundingSource;
  awards: ApplicationAwards;
  primaryApplicant: Collaborator;
  organization: OrganizationForInfoPanel;
}

export interface ApplicationEditDetail {
  application: ApplicationViewPage;
  showSendToApplicant: boolean;
  showSaveAsDraft: boolean;
  defaultFormId: number;
  formsForEdit: ApplicantFormForUI[];
  nominee: Nominee;
  program: ProgramDetailFromApi;
  budget: BudgetDetail;
  budgetFundingSource: BudgetFundingSource;
  awards: ApplicationAwards;
  primaryApplicant: Collaborator;
  organization: OrganizationForInfoPanel;
}

export interface ApplicationAwards {
  awards: AwardFromApi[];
  totalAmount: number;
};

export interface ApplicationByFormFromApi extends SpecialHandlingFromApi {
  isApplicationDraft: boolean;
  organization: ApplicantOrganizationForUi;
  grantProgramId: number;
  grantProgramName: string;
  grantProgramDescription: string;
  programApplicantType: ProgramApplicantType;
  startDate: string;
  endDate: string;
  active: boolean;
  imageUrl: string;
  clientName: string;
  clientLogoUrl: string;
  clientId: number;
  form: ProgramFormFromApi;
  programAllowCollaboration: boolean;
  programSendEmailsToCollaborators: boolean;
  amountRequested: number;
  nominee: Nominee;
  applicationStatusId: ApplicationStatuses;
  nominationApplicationId: number;
  paymentDesignation: string;
  careOf: null;
  currencyRequestedAmountEquivalent: number;
  currencyRequested: string;
  clientOrganizationsProcessingTypeId: ProcessingTypes;
  inKindAmountRequested: number;
  isArchived: boolean;
  isProgramArchived: boolean;
  inKindItems: InKindRequestedItem[];
  cycle: ApplicationCycle;
  latestVettingRequestStatusForOrg: VettingRequestStatusId;
  invitationOrganizationId: number;
  previousApplicationStatusId: ApplicationStatuses;
}

export interface SaveApplication extends StandardApplicantRequiredFields {
  amountRequested: number;
  currencyRequested: string;
  applicationId: number;
  grantProgramId: number;
  organizationId?: number;
  isDraft: boolean;
  form: {
    formId: number;
    formRevisionId: number;
    formData: FormData;
    isDraft: boolean;
  };
  nominee?: Nominee;
  paymentDesignation?: string;
  careOf: string;
  specialHandlingName: string;
  specialHandlingAddress1: string;
  specialHandlingAddress2: string;
  specialHandlingCountry: string;
  specialHandlingCity: string;
  specialHandlingStateProvinceRegion: string;
  specialHandlingPostalCode: string;
  specialHandlingNotes: string;
  specialHandlingReason: string;
  specialHandlingFileUrl: string;
  inKindAmountRequested: InKindRequestedItem[];
  requiredReferenceFieldKeys: string[];
  userSignatureId: number;
  userSignatureBypassed: boolean;
}

export interface Nominee {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  position?: string;
  orgId?: number;
}

export interface ApproveDeclineModalResponse {
  comment: string;
  notifyApplicant: boolean;
  grantProgram?: number;
  emailOptionsModel: EmailOptionsModelForSave;
  isEmployeeOfClient?: boolean;
}

export interface StatusModalResponse {
  customMessage?: string;
  clientEmailTemplateId: number;
  cc?: string[];
  bcc?: string[];
  attachments: GenericFile[];
}

export interface StatusModalPayload {
  customMessage?: string;
  clientEmailTemplateId: number;
  emailOptionsRequest: EmailOptionsModelForSave;
}

export interface RouteModalResponse {
  comment: string;
  level: number;
}

export interface CreateApplicantResponse {
  applicantId: number;
  userId: number;
}

export interface MyApplicationFromApi {
  clientLogoUrl: string;
  grantProgramId: number;
  grantProgramGuid: string;
  grantProgramName: string;
  grantProgramGuidelines: string;
  grantProgramImageUrl: string;
  programAllowCollaboration: boolean;
  programSendEmailsToCollaborators: boolean;
  applicationId: number;
  organizationId: number;
  organizationName: string;
  isPrivateOrg?: boolean;
  submissionDate: string;
  createdDate: string;
  status: ApplicationStatuses;
  applicationForms: MyApplicationForm[];
  applicantCount: number;
  applicant: SimpleApplicant;
  amountRequested: number;
  nominee: Nominee;
  nominatedBy: UserInfo;
  nominationApplicationId: number;
  grantProgramLogoUrl: string;
  currencyRequested: string;
  currencyRequestedAmountEquivalent: number;
  clientId: number;
  inKindAmountRequested: number;
  inKindItems: InKindRequestedItem[];
  clientName: string;
  files: ApplicationApplicantFile[];
  mergeDocuments: ApplicantMergeDoc[];
  hasOpenCycle: boolean;
  isAwarded: boolean;
  isInvitationOnlyProgram: boolean;
  invitationId: number;
  isArchived: boolean;
  isProgramArchived: boolean;
  cycleId: number;
  previousApplicationStatusId: ApplicationStatuses;
  programAllowApplicantToCopy: boolean;
  partnerId: number;
}

export interface MyApplicationForUi extends MyApplicationFromApi {
  applicantDescription: string;
  nominationDescription: string;
  canManage: boolean;
  canManageApplicants: boolean;
  canDelete: boolean;
  canCancel: boolean;
  canCopy: boolean;
}

export interface ApplicantMergeDoc {
  applicantCanView: boolean;
  canRemoveAttachment: boolean;
  documentVisibility: CommunicationVisibility;
  documentTemplateId: number;
  documentTemplateName: string;
  attachmentId: number;
  fileName: string;
  fileUploadId: number;
  attachmentType: AttachmentType;
  uploadedDate: string;
  uploadedBy: UserInfo;
}

export interface ApplicationApplicantFile {
  fileName: string;
  uploadedDate: string;
  uploadedBy: UserInfo;
  attachmentId: number;
  fileUploadId: number;
  attachmentType: AttachmentType;
}

export interface MyApplicationsApi {
  totalCount: number;
  pageCount: number;
  page: number;
  pageSize: number;
  data: MyApplicationFromApi[];
}

export interface ApplyPageApplication extends BaseApplication {
  id: number;
  isApplicationDraft: boolean;
  organization: ApplicantOrganizationForUi;
  grantProgramName: string;
  fallbackProgramGuid: string;
  ruleSetFailureMessage: string;
  charityBucketId?: string;
  charityBucketDescription?: string;
  grantProgramDescription: string;
  grantProgramGuidelines: string;
  programApplicantType: ProgramApplicantType;
  isArchived?: boolean;
  isProgramArchived: boolean;
  clientName: string;
  clientLogoUrl: string;
  clientId: number;
  form: ProgramFormForUi;
  eligibilityForm: ProgramFormForUi;
  programAllowCollaboration: boolean;
  programSendEmailsToCollaborators: boolean;
  hasDraftApplication: boolean;
  passMessage: string;
  failMessage: string;
  allowAddOrg: boolean;
  nominee: Nominee;
  statusId: ApplicationStatuses;
  nominationApplicationId: number;
  employeeInfo: EmployeeSSOFieldsData;
  clientOrganizationsProcessingTypeId?: ProcessingTypes;
  inKindAmountRequested: number;
  inKindItems: InKindRequestedItem[];
  hideCycleDatesInApplicantPortal: boolean;
  cycles: CyclesAPI.BaseProgramCycle[];
  cycle: ApplicationCycle;
  defaultFormId: number;
  latestVettingRequestStatusForOrg: VettingRequestStatusId;
  invitationOrganizationId: number;
  clientBranding?: ClientBrandingFromApi;
  isClientProcessing: boolean;
  multipleApplicationsLimitType: CanOrgSubmitMultipleAppsOptions;
  multipleApplicationsLimitMessage: string;
  allowedOrgLocations: AllowedLocation[];
  communications: CommunicationForApplicant[];
}

export interface SubmitEligibility {
  id: number;
  organizationId: number|string;
  formRevisionId: number;
  grantProgramId: number;
  formData: FormData;
  isDraft: boolean;
}

export interface EligibilityResponse {
  id: number;
  matchesMasterResponse: boolean;
  matchedResponse: string;
  notMatchedResponse: string;
}

export interface AuditTrailItem {
  workflowLevelName: string;
  createdDate: string;
  createdBy: string;
  comment: string;
}

export interface ApproveDeclinePayload {
  applicationIds: number[];
  sendEmail: boolean;
  comments: string;
  nominationApprovalGrantProgramId?: number;
  nominationInviteCustomMessage?: string;
  clientEmailTemplateId: number;
  nominatorClientEmailTemplateId?: number;
  customMessage?: string;
  emailOptionsModel: EmailOptionsModelForSave;
  isEmployeeOfClient?: boolean; // For approving a nomination, current clientId gets assigned to new applicant created
}

export interface RouteApplicationPayload {
  workflowLevelId: number;
  comments: string;
}
export interface BulkRouteApplicationsPayload {
  applicationIds: number[];
  nominationIds: number[];
  newWorkflowLevelId: number;
  comment: string;
}
export interface ReminderResponse {
  users: number[];
  emailOptionsModel: EmailOptionsModelForSave;
}

export interface ApproveApplication {
  sendEmail: boolean;
  comments: string;
  nominationApprovalGrantProgramId?: number;
  nominationInviteCustomMessage?: string;
  clientEmailTemplateId?: number;
  nominatorClientEmailTemplateId?: number;
  customMessage?: string;
  emailOptionsModel: EmailOptionsModelForSave;
}

export interface BulkRevisionRequestPayload {
  formId: number;
  clientEmailTemplateId: number;
  message: string;
  grantProgramId: number;
  applicationIds: number[];
}

export interface BulkCancelRevisionRequestPayload {
  emailOptionsRequest: EmailOptionsModelForSave;
  cancelRevisionRequestModels: CancelRevisionRequestModel[];
}

export interface CancelRevisionRequestModel {
  applicationId: number;
  applicationFormId: number;
}

export interface AppInMyLevel {
  exists: boolean;
}

export interface NominationForm {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  position?: string;
}

export interface ApplicationForPDF {
  programName: string;
  applicationId: number;
  formSubmittedOn: string|Date;
  organizationName: string;
  organizationAddressInfo: SimpleGrantsAddress|string;
  organizationPhoneNumber?: string|number;
  organizationEmail?: string;
  applicantFullName: string;
  applicantAddressInfo: SimpleGrantsAddress|string;
  applicantEmail: string;
  applicantPhoneNumber: string|number;
  isMasked: boolean;
  isNomination: boolean;
  specialHandling: SpecialHandling;
}

export interface SubmittedInfoForPDF {
  name: string;
  isManager: boolean;
}

export interface SendRevisionReminderBody {
  formId: number;
  customMessage: string;
  clientEmailTemplateId: number;
  emailOptionsModel?: EmailOptionsModelForSave;
}

export interface BaseApplication {
  formType: FormTypes;
  applicationId: number;
  applicationFormId: number;
  formId: number;
  revisionId: number;
  amountRequested: number;
  currencyRequested: string;
  currencyRequestedAmountEquivalent: number;
  amountRequestedForEdit: number;
  designation: string;
  careOf: string;
  specialHandling: SpecialHandling;
  defaultSpecialHandling?: SpecialHandling;
  programId: number;
  reviewerRecommendedFundingAmount?: number;
  referenceFields: ReferenceFieldsUI.RefResponseMap;
  reportFieldResponse?: AdHocReportingUI.ReportFieldResponseRow;
  employeeInfo: EmployeeSSOFieldsData;
  decision?: FormDecisionTypes;
  inKindItems: InKindRequestedItem[];
}

export interface ChangeStatusPayload {
  applicationId?: number;
  statusId: ApplicationStatuses;
  workflowLevelId: number;
  comment: string;
  sendEmail: boolean;
  clientEmailTemplateId: number;
  includeCommentInEmail: boolean;
  disregardWorkflowAutomationRules: boolean;
  emailOptionsModel?: EmailOptionsModelForSave;
}

export interface AutomaticallyRouted {
  automaticallyRouted: boolean;
}

export interface SaveApplicationResponse {
  id: number;
  applicationFormId: number;
  amountRequested: number;
  currencyRequested: string;
  currencyRequestedAmountEquivalent: number;
  automaticallyRouted: boolean;
}

export interface ArchiveModalData {
  ids: number[];
  totalAwards: number;
  totalPayments: number;
  paymentsAvailableForProcessing: number;
  programName?: string;
  unarchivedApplicationCount?: number;
}

export interface ArchivePayload {
  ids: number[];
  code?: number;
  notes?: string;
}

export interface ArchiveApplicationsPayload {
  applicationIds: number[];
  code?: number;
  notes?: string;
}

export interface ArchiveModalResponse extends ArchivePayload {
  context: 'archive'|'unarchive';
}

export interface GetPaymentStatsPayload {
  applicationIds: number[];
  returnArchived: boolean;
}

export interface ApplicationCycle {
  id: number;
  name?: string;
  startDate: string;
  endDate: string;
  grantProgramId: number;
  isArchived: boolean;
  clientOrganizationsProcessingTypeId: number;
  isClientProcessing: boolean;
}

export interface NotifyOfStatusForApi {
  applicationIds: number[];
  customMessage: string;
  clientEmailTemplateId: number;
  emailOptionsRequest: EmailOptionsModelForSave;
}

export interface RecommendedFundingInfoFromApi {
  reviewerRecommendations: ReviewerRecommendation[];
  amountRequested: number;
  currencyRequestedAmountEquivalent: number;
  currencyRequested: string;
  recommendedFundingAmount: number;
}

export interface ReviewerRecommendation {
  reviewerRecommendedFundingAmount: number;
  decision: FormDecisionTypes;
  createdBy: string;
  createdDate: string;
  submittedDate: string;
  workflowLevelName: string;
}

export interface RecommendedFundingInfoForUI {
  recommendedFundingAmount: number;
  decision: FormDecisionTypes;
  createdBy: string;
  createdDate: string;
  workflowLevelName: string;
}

export interface ApplicableRequiredAppFields {
  amountRequested: string|number;
  designation: string;
  decision?: FormDecisionTypes;
  reviewerRecommendedFundingAmount?: number;
  careOf: string;
}

export interface SpecialHandlingResponse {
  defaultAddress: SpecialHandling;
  orgAddressString: string;
  nonprofitDetail: NonprofitDetail;
}

export interface AppDetailForDownload {
  forms: ApplicationViewFormForUI[];
  defaultFormId: number;
  collaborators: BulkCollaboratorResponse[];
  tags: string[];
  employeeInfo: EmployeeSSOFieldBulkFromApi;
  reportFieldResponse: AdHocReportingUI.ReportFieldResponseRow;
  signatures: UserSignatureForBulk[];
  appDetail: BulkApplicationDetail;
  application: ApplicationFromPaginated;
  refResponseMapByAppFormId: Record<number, ReferenceFieldAPI.ApplicationRefFieldResponse[]>;
}

export interface RoutingOrEligibilityChangesToSave {
  formId: number;
  formRevisionId: number;
  amountRequested: number;
  currencyRequested: string;
  referenceFields: ReferenceFieldsUI.RefResponseMap;
}
