<div *ngIf="rowsForTable && dataColumnHeader">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <div class="font-550">
        <yc-form-label
          [id]="component.key"
          [label]="label"
          [srOnly]="srOnlyLabel"
          [customIcon]="customLabelIcon"
          [customIconTooltip]="customLabelIconTooltip"
          [customIconClass]="customLabelIconClass"
          [tooltipText]="tooltipText"
          [showQuestionMarkForTooltip]="true">
        </yc-form-label>
      </div>
      <small class="d-block"
        [innerHTML]="description | escapeHtml">
      </small>
    </div>
  </div>
  <!-- TABLE OF OPTIONS -->
  <table
    role="table"
    [attr.aria-describedBy]="component.key"
    class="wrapper-styles"
    [tabIndex]="tabIndex"
    [ngStyle]="scrollSetting">
    <caption *ngIf="label"
      class="sr-only">
      {{ label }}
    </caption>
    <thead>
      <tr role="row">
        <th role="columnheader">
          {{ translations[field.name] || field.name }}
        </th>
        <th role="columnheader">
          {{ dataColumnHeader }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rowsForTable; let index = index;" role="row">
        <td role="rowheader">
          {{ translations[row.label] || row.label }}
        </td>
        <td role="cell">
          <ng-container [ngSwitch]="field.subsetCollectionType">
            <!-- YES or NO CHECKBOX -->
            <ng-container *ngSwitchCase="DataSetCollectionType.YesOrNo">
              <yc-checkbox
                [value]="(row.value | ycAs: $number) | gcCheckboxNumberValue"
                [disabled]="disabled"
                [label]="'common:textSelectIfApplicableForRow' | ycI18n: 'Select if applicable for the __label__ row' : { label: row.label }"
                [srOnlyLabel]="true"
                (onChange)="handleOnChange($event, row, index, true)">
              </yc-checkbox>
            </ng-container>
            <!-- NUMBER INPUTS -->
            <ng-container *ngSwitchCase="DataSetCollectionType.Number">
              <yc-input
                [value]="row.value | ycAs: $number"
                [disabled]="disabled"
                [isNumber]="true"
                [label]="'FORMS:textNumberValueForRowDynamic' | ycI18n: 'This is the number value for the __label__ row' : { label: row.label }"
                [srOnlyLabel]="true"
                (onChange)="handleOnChange($event, row, index, false)">
              </yc-input>
            </ng-container>
            <!-- PERCENT INPUTS -->
            <ng-container *ngSwitchCase="DataSetCollectionType.Percent">
              <yc-input
                [value]="row.value | ycAs: $string"
                [disabled]="disabled"
                [isNumber]="true"
                [label]="'FORMS:textNumberValueForRowDynamic' | ycI18n: 'This is the number value for the __label__ row' : { label: row.label }"
                [srOnlyLabel]="true"
                [rightIcons]="[{
                  icon: 'percent'
                }]"
                (onChange)="handleOnChange($event, row, index, false)">
              </yc-input>
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="d-flex justify-content-end me-4">
  <yc-i18n class="font-550 me-2"
    key="GLOBAL:textTotal"
    defaultValue="Total">
  </yc-i18n>
  {{ rows ? (($any(rows[0]?.columns || []) | ycPluck: 'value') | ycSum) : 0}}
</div>


<div *ngIf="showErrorSummary && errorMessages.length > 0"
  class="mb-4 text-danger">
  {{ errorMessages[0].errorMessage }}
</div>