import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { GCCoreModule } from '@core/_core.module';
import { InvalidDependentPicklistsErrorMessage } from '@features/configure-forms/pipes/invalid-dependent-picklists-error-message.pipe';
import { AdHocFormFieldsModalComponent } from '@features/quick-add/ad-hoc-form-fields-modal/ad-hoc-form-fields-modal.component';
import { QuickAddFieldsComponent } from './quick-add-fields/quick-add-fields.component';
import { QuickAddModalComponent } from './quick-add-modal/quick-add-modal.component';
import { AccordionsModule } from '@yourcause/common/accordion';

@NgModule({
  imports: [
    GCCoreModule,
    ScrollingModule,
    AccordionsModule
  ],
  declarations: [
    QuickAddModalComponent,
    QuickAddFieldsComponent,
    AdHocFormFieldsModalComponent,
    InvalidDependentPicklistsErrorMessage
  ],
  exports: [
    QuickAddModalComponent,
    QuickAddFieldsComponent,
    AdHocFormFieldsModalComponent,
    InvalidDependentPicklistsErrorMessage
  ]
})
export class QuickAddModule {}
