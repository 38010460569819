<yc-modal
  [modalHeader]="isNomination ?
    ('common:hdrSubmitNomination' | ycI18n: 'Submit Nomination') :
    ('common:hdrSubmitApplication' | ycI18n: 'Submit Application')"
  [primaryButtonText]="isNomination ?
    ('common:textSubmitNomination' | ycI18n: 'Submit nomination') :
    ('common:textSubmitApplication' | ycI18n: 'Submit application')"
  [secondaryButtonText]="'APPLY:textSubmitAndApproveApp' | ycI18n: 'Submit and approve'"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  (onCancel)="closeModal.emit()"
  (onPrimaryClick)="submit()"
  (onSecondaryClick)="submit(true)">

  <!-- Submit Alert -->
  <yc-alert
    alertClass="info"
    [message]="isNomination ?
      ('common:textSelectToProceedWithSubmitNom' | ycI18n: 'Please select an option below for how you want to proceed with the submitted nomination.') :
      ('common:textSelectToProceedWithSubmitApp' | ycI18n: 'Please select an option below for how you want to proceed with the submitted application.')">
  </yc-alert>

  <!-- Submit Option -->
  <yc-radio-buttons
    name="submitOption"
    [options]="submitOptions"
    [(value)]="submitOptionValue"
    [sorted]="false">
  </yc-radio-buttons>

  <!-- WFL Option -->
  <div *ngIf="submitOptionValue === SubmitApplicationOptions.SELECT_A_LEVEL"
    class="wfl-margin">
    <yc-typeahead-select
      name="workflowLevel"
      [showClear]="false"
      [srOnlyLabel]="true"
      [label]="'common:lblWorkflowLevel' | ycI18n: 'Workflow level'"
      [(value)]="workflowLevelId"
      [items]="levelOptions">
    </yc-typeahead-select>
  </div>

</yc-modal>