import { SimpleDataHubBatch, SimpleDataHubBudget } from '@core/typings/budget.typing';
import { SimpleDataHubProgram } from '@core/typings/program.typing';
import { SimpleDataHubForm } from '@features/configure-forms/form.typing';
import { DataHubItem } from '@features/reporting/data-hub/data-hub.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class DataHubState extends BaseYcState {
  readonly programList: SimpleDataHubProgram[];
  readonly formList: SimpleDataHubForm[];
  readonly budgetList: SimpleDataHubBudget[];
  readonly batchesList: SimpleDataHubBatch[];
  readonly applications: DataHubItem[];
  readonly awards: DataHubItem[];
  readonly budgets: DataHubItem[];
  readonly customForms: DataHubItem[];
  readonly payments: DataHubItem[];
  readonly programSummary: DataHubItem[];
}
