import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';

@Injectable({ providedIn: 'root' })
export class FormFieldTableAndSubsetResources {

  constructor (
    private http: HttpRestService,
    private portal: PortalDeterminationService
  ) { }

  importTableRows (
    applicationId: number,
    tableReferenceFieldId: number,
    file: Blob,
    formId: number
  ) {
    const portal = this.portal.isManager ? 'manager' : 'portal';
    const endpoint = `api/${portal}/applications/UploadDataToTableReferenceField?applicationId=${applicationId}&tableReferenceFieldId=${tableReferenceFieldId}&formId=${formId}`;

    return this.http.postFile(endpoint, file);
  }

  getTableFields (referenceFieldId: number): Promise<ReferenceFieldAPI.TableField[]> {
    const isManager = this.portal.isManager;
    const endpoint = isManager ?
      `api/manager/forms/ReferenceFields/${referenceFieldId}/TableColumns` :
      `api/portal/applications/ReferenceFields/${referenceFieldId}/TableColumns`;

    return this.http.get(endpoint);
  }

  getSubsetFields (referenceFieldId: number): Promise<ReferenceFieldAPI.TableField[]> {
    const isManager = this.portal.isManager;
    const endpoint = isManager ?
      `api/manager/forms/ReferenceFields/${referenceFieldId}/TableColumns` :
      `api/portal/applications/ReferenceFields/${referenceFieldId}/TableColumns`;

    return this.http.get(endpoint);
  }

  getTableResponses (
    applicationId: number,
    applicationFormId: number,
    tableReferenceFieldId: number,
    perPage = 50000,
    pageNumber = 1
  ): Promise<ReferenceFieldAPI.TableResponse[]> {
    const endpoint = this.portal.isManager ?
      `api/manager/applications/${applicationId}/ApplicationForms/${applicationFormId}/ReferenceFields/${tableReferenceFieldId}/TableData?perPage=${perPage}&pageNumber=${pageNumber}&sortReferenceFieldId=&sortAscending=false` :
      `api/portal/applications/${applicationId}/ApplicationForms/${applicationFormId}/ReferenceFields/${tableReferenceFieldId}/TableData?perPage=${perPage}&pageNumber=${pageNumber}&sortReferenceFieldId=&sortAscending=false`;

    return this.http.get(endpoint);
  }

}
