<yc-modal
  [modalHeader]="'common:hdrLatestUpdatesForGrantsConnect' | ycI18n: 'Latest Updates for GrantsConnect'"
  [primaryButtonText]="'common:textClose' | ycI18n: 'Close'"
  (onPrimaryClick)="closeModal.emit(doNotShowAgain)">

  <div class="mb-3">
    <yc-i18n
      key="common:textReleaseNoteModalContent"
      [context]="{
        number: releaseNote.releaseNumber,
        date: releaseNote.announcementDate | ycStartOfDayDateFormatter
      }"
      defaultValue="GrantsConnect release __number__ is scheduled for release on __date__. Explore the latest features and enhancements in the release notes.">
    </yc-i18n>
  </div>

  <div class="mb-3 text-center">
    <yc-button
      [primary]="true"
      [langKey]="'common:textViewReleaseNotes'"
      [defaultText]="'View release notes'"
      (onClick)="viewReleaseNotes()">
    </yc-button>
  </div>

  <div>
    <yc-checkbox  
      name="doNotShowAgain"
      [(value)]="doNotShowAgain"
      [optionLabel]="'common:textDoNotShowThisAnnouncementAgain' | ycI18n: 'Do not show announcement again'">
    </yc-checkbox>
  </div>


</yc-modal>