import { Injectable } from '@angular/core';
import { TranslationService } from '@core/services/translation.service';
import { SimpleDataHubBatch, SimpleDataHubBudget } from '@core/typings/budget.typing';
import { ProgramTypes, SimpleDataHubProgram } from '@core/typings/program.typing';
import { SimpleDataHubForm } from '@features/configure-forms/form.typing';
import { ProgramService } from '@features/programs/services/program.service';
import { DataHubItem, DataHubReportStatus } from '@features/reporting/data-hub/data-hub.typing';
import { PaginatedResponse, PaginationOptions } from '@yourcause/common';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { DataHubResources } from './data-hub.resources';
import { DataHubState } from './data-hub.state';

@AttachYCState(DataHubState)
@Injectable({ providedIn: 'root' })
export class DatahubService extends BaseYCService<DataHubState> {

  constructor (
    private dataHubResources: DataHubResources,
    private programService: ProgramService,
    private translationService: TranslationService
  ) {
    super();
  }

  setBatchesList (batchesList: SimpleDataHubBatch[]) {
    this.set('batchesList', batchesList);
  }

  clearProgramList () {
    this.set('programList', undefined);
  }

  async getPrograms (
    formId: number = null,
    type: ProgramTypes = null
  ): Promise<SimpleDataHubProgram[]> {
    const response = await this.dataHubResources.getPrograms(
      formId,
      type
    );
    response.forEach((program) => {
      const map = this.programService.programTranslationMap[program.id];
      program.name = map && map.Name ? map.Name : program.name;
    });
    this.set('programList', response);

    return this.get('programList');
  }

  async getBudgets (): Promise<SimpleDataHubBudget[]> {
    if (!this.get('budgetList')) {
      const response = await this.dataHubResources.getBudgets();
      this.set('budgetList', response);

      return response;
    }

    return this.get('budgetList');
  }

  async getBatches (): Promise<SimpleDataHubBatch[]> {
    if (!this.get('batchesList')) {
      const response = await this.dataHubResources.getBatches();
      this.set('batchesList', response);

      return response;
    }

    return this.get('batchesList');
  }

  async getRequestForms (nominationFormId: number): Promise<SimpleDataHubForm[]> {
    const response = await this.dataHubResources.getRequestForms(nominationFormId);

    return response;
  }

  async getForms (): Promise<SimpleDataHubForm[]> {
    if (!this.get('formList')) {
      const response: SimpleDataHubForm[] = await this.dataHubResources.getForms();
      const viewTranslations = this.translationService.viewTranslations;
      const formTranslationMap = viewTranslations.FormTranslation;
      response.forEach((form) => {
        const map = formTranslationMap[form.id];
        form.name = map && map.Name ? map.Name : form.name;
      });
      this.set('formList', response);

      return response;
    }

    return this.get('formList');
  }

  async getApplicationsList (
    paginationOptions: PaginationOptions<DataHubItem>
  ): Promise<PaginatedResponse<DataHubItem>> {
    const response = await this.dataHubResources.getApplicationList(
      paginationOptions
    );
    this.set('applications', response.records);

    return response;
  }

  async getProgramSummaryList (
    paginationOptions: PaginationOptions<DataHubItem>
  ): Promise<PaginatedResponse<DataHubItem>> {
    const response = await this.dataHubResources.getProgramSummaryList(
      paginationOptions
    );
    this.set('programSummary', response.records);

    return response;
  }

  async getPaymentsList (
    paginationOptions: PaginationOptions<DataHubItem>
  ): Promise<PaginatedResponse<DataHubItem>> {
      const response = await this.dataHubResources.getPaymentsList(
        paginationOptions
      );
      this.set('payments', response.records);

      return response;
  }

  async getBudgetsList (
    paginationOptions: PaginationOptions<DataHubItem>
  ): Promise<PaginatedResponse<DataHubItem>> {
    const response = await this.dataHubResources.getBudgetsList(
      paginationOptions
    );
    this.set('budgets', response.records);

    return response;
  }

  async getAwardsList (
    paginationOptions: PaginationOptions<DataHubItem>
  ): Promise<PaginatedResponse<DataHubItem>> {
    const response = await this.dataHubResources.getAwardsList(
      paginationOptions
    );
    this.set('awards', response.records);

    return response;
  }

  async getFormsList (
    paginationOptions: PaginationOptions<DataHubItem>
  ): Promise<PaginatedResponse<DataHubItem>> {
    const response = await this.dataHubResources.getFormsList(
      paginationOptions
    );
    this.set('customForms', response.records);

    return response;
  }

  async getTableData (
    table: 'applications'|'awards'|'budgets'|'customForms'|'payments'|'programSummary',
    reset = false,
    paginationOptions: PaginationOptions<DataHubItem>
  ) {
    if (reset) {
      this.set(table, null);
    }
    let data;
    switch (table) {
      case 'applications':
        data  = await this.getApplicationsList(paginationOptions);
        break;
      case 'awards':
        data  = await this.getAwardsList(paginationOptions);
        break;
      case 'budgets':
        data  = await this.getBudgetsList(paginationOptions);
        break;
      case 'payments':
        data  = await this.getPaymentsList(paginationOptions);
        break;
      case 'programSummary':
        data  = await this.getProgramSummaryList(paginationOptions);
        break;
      case 'customForms':
        data  = await this.getFormsList(paginationOptions);
        break;
      }

      return data;
  }

  async getNumberPendingReportsForActiveTable (
    table: 'applications'|'awards'|'budgets'|'customForms'|'payments'|'programSummary'
  ): Promise<number> {
    const paginationOptions: PaginationOptions<DataHubItem> = {
      returnAll: false,
      rowsPerPage: 1,
      pageNumber: 1,
      sortColumns: [{
        columnName: 'queuedDate',
        sortAscending: false
      }],
      filterColumns: [{
        columnName: 'status',
        filters: [{
          filterType: 'eq',
          filterValue: DataHubReportStatus.PENDING
        }, {
          filterType: 'eq',
          filterValue: DataHubReportStatus.PROCESSING
        }]
      }],
      retrieveTotalRecordCount: true
    };
    const response = await this.getTableData(table, false, paginationOptions);

    return response.recordCount;
  }
}
