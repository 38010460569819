<yc-modal *ngIf="formGroup" tabindex="0"
  [formGroup]="formGroup"
  [modalHeader]="'common:hdrContactSupport' | ycI18n"
  [primaryButtonText]="'common:btnSubmit' | ycI18n: 'Submit'"
  [cancelButtonText]="'common:btnCancel' | ycI18n"
  [primaryDisabled]="formGroup.invalid"
  (onCancel)="closeModal.emit()"
  (onPrimaryClick)="onSubmit()">

  <!-- Description of Form -->
  <div class="mb-2">
    <yc-i18n
      key="common:textDescriptionOfSupportForm"
      defaultValue="Please use the form below to ask any question you may have and/or to report issues you may encounter while using our service.">
    </yc-i18n>
  </div>

  <!-- Contact Reason -->
  <div class="mb-2">
    <yc-panel
      [type]="PanelTypes.FormSectionHeader"
      [isFirst]="true"
      [noMarginBottom]="true"
      [title]="'common:textContactReason' | ycI18n: 'Contact Reason'">
    </yc-panel>
    <yc-typeahead-select
      name="contactReason"
      [label]="'common:textReason' | ycI18n: 'Reason'"
      [items]="contactReasonPostAuthOptions"
      [showClear]="false">
    </yc-typeahead-select>
  </div>

  <!-- Contact Information Section -->
  <yc-panel
    [type]="PanelTypes.FormSectionHeader"
    [isFirst]="true"
    [noMarginBottom]="true"
    [title]="'common:hdrContactInformation' | ycI18n: 'Contact Information'">
  </yc-panel>

  <!-- First and Last Name -->
  <div class="class-mb2 d-flex">
    <div class="flex-basis-50 me-2">
      <yc-input
        name="firstName"
        [label]="'common:lblFirstName' | ycI18n: 'First name'">
      </yc-input>
    </div>
    <div class="flex-basis-50 ms-2">
      <yc-input
        name="lastName"
        [label]="'common:lblLastName' | ycI18n: 'Last name'">
      </yc-input>
    </div>
  </div>
  <!-- Email and Phone number -->
  <div class="class-mb2 d-flex">
    <div class="flex-basis-50 me-2">
      <yc-input
        name="email"
        [label]="'common:textEmail' | ycI18n: 'Email'">
      </yc-input>
    </div>
    <div class="flex-basis-50 ms-2">
      <yc-input
        name="phoneNumber"
        [label]="'common:textPhoneNumber' | ycI18n: 'Phone number'">
      </yc-input>
    </div>
  </div>
  <!-- Additional Information Section -->
  <yc-panel
    [type]="PanelTypes.FormSectionHeader"
    [isFirst]="true"
    [noMarginBottom]="true"
    [title]="'common:hdrAdditionalInformation' | ycI18n: 'Additional Information'"
    [description]="'common:textAdditionalInfoSupport' | ycI18n: 'If relevant, please provide additional application information to help get your questions answered and issues resolved as quickly as possible.'">
  </yc-panel>
  <div class="class-mb2 d-flex">
    <!-- Client Name, Application ID and Program Name -->
    <div class="flex-basis-33 me-2">
      <yc-input
        name="clientName"
        [label]="'common:lblCompanySeekingGrantFrom' | ycI18n: 'Company seeking grant from'">
      </yc-input>
    </div>
    <div class="flex-basis-33 ms-2 me-2">
      <yc-input
        name="appId"
        [label]="'common:textApplicationId' | ycI18n: 'Application ID'">
      </yc-input>
    </div>
    <div class="flex-basis-33 ms-2">
      <yc-input
        name="programName"
        [label]="'common:lblProgram' | ycI18n: 'Program'">
      </yc-input>
    </div>
  </div>
  <!-- Organization name and Tax ID -->
  <div class="class-mb2 d-flex">
    <div class="flex-basis-50 me-2">
      <yc-input
        name="organizationName"
        [label]="'common:lblOrganization' | ycI18n: 'Organization'">
      </yc-input>
    </div>
    <div class="flex-basis-50 ms-2">
      <yc-input
        name="taxId"
        [label]="'common:labelGovTaxIdentificationNumber' | ycI18n: 'Government tax identification number'"
        [help]="'common:textEinOrTaxIdHelp' | ycI18n: 'Examples include EIN, Tax ID VAT, BIN or PAN Number'">
      </yc-input>
    </div>
  </div>
  <!-- Description -->
  <div class="mb-2">
    <yc-panel
      [type]="PanelTypes.FormSectionHeader"
      [isFirst]="true"
      [title]="'common:textDescriptionOfIssueOrQuestion' | ycI18n: 'Description of Issue/Question'">
    </yc-panel>
    <yc-textarea
      name="description"
      [label]="'common:textDescription' | ycI18n: 'Description'">
    </yc-textarea>
  </div>
</yc-modal>
