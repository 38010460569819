<yc-modal
  [formGroup]="actionFormGroup"
  [primaryButtonText]="primaryButtonText"
  [modalHeader]="modalHeader"
  [modalSubHeader]="modalSubHeader"
  [primaryDisabled]="enforceFsFormGroup ? fsFormGroup.invalid : false"
  [cancelButtonText]="'common:btnCancel' | ycI18n"
  (onCancel)="closeModal.emit()"
  (onPrimaryClick)="onSubmit()">
  <form>
    <div class="row">
      <div class="col-lg 12 mb-2">
        {{ scenarioText }}
        <ul *ngIf="budgets.length > 1">
          <li *ngFor="let budget of budgets">
            {{ budget.name }}
          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="context === 'close' && hasMoneyLeftover && remainingFundsDecisionOptions.length > 1"
      class="row mt-3">
      <div class="col-lg 12">
        <yc-typeahead-select
          [items]="remainingFundsDecisionOptions"
          [showClear]="false"
          [label]="scenarioText"
          [srOnlyLabel]="true"
          name="actionType"
          (onChange)="setEnforceFsFormGroup()">
        </yc-typeahead-select>
      </div>
    </div>
  </form>
  
  <ng-container *ngIf="context === 'close' &&
    enforceFsFormGroup">
    <form
      [formGroup]="fsFormGroup"
      class="mt-3">
      
      <!-- Funding Source Table with Budget Selector -->
      <div class="funding-sources-wrapper">
        <div class="headers">
          <div class="header left">
            <yc-i18n key="BUDGET:lblFundingSource"
              defaultValue="Funding Source">
            </yc-i18n>
          </div>
          <div class="header right">
            <yc-i18n key="BUDGET:textNewBudget"
              defaultValue="New Budget">
            </yc-i18n>
          </div>
        </div>
  
        <ng-container *ngFor="let item of fsWithAvailableFunds;
          let index = index;
          let last = last;">
          <div class="item-row">
            <div class="funding-source">
              {{ item.fundingSourceName }}
            </div>
            <ng-template
              *ngTemplateOutlet="budgetSelector; context: {
                index: index
              }">
            </ng-template>
          </div>
        </ng-container>
      </div>

      <ng-template #budgetSelector
        let-index="index">
        <div *ngIf="fundingSourcesOptions[index].length > 0"
          class="select-box">
          <yc-typeahead-select
            [name]="'' + index"
            [showClear]="false"
            [label]="'common:textNewBudget' | ycI18n: 'New budget'"
            [srOnlyLabel]="true"
            [items]="fundingSourcesOptions[index]">
          </yc-typeahead-select>
        </div>
        <div *ngIf="fundingSourcesOptions[index].length === 0"
          class="select-box">
          <yc-i18n
            key="BUDGET:testNoBudgetAvailable"
            defaultValue="No budget available, unused funds will be added back to funding source">
          </yc-i18n>
        </div>
      </ng-template>
    </form>
  </ng-container>
</yc-modal>