import { ApplicantProfileData, ProfileRouterLink } from '@core/typings/applicant.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class ApplicantManagerState extends BaseYcState {
  readonly applicantMap: {
    [g: string]: ApplicantProfileData;
  } = {};
  readonly applicantProfileRouterLink: ProfileRouterLink = {};
}
