<yc-modal
  [primaryButtonText]="((currentPage$ | async) === AddOrgModalPages.CONFIRMATION) ?
    ('common:textSubmitAndContinue' | ycI18n: 'Submit and continue') :
    ('common:btnNext' | ycI18n: 'Next')"
  [primaryDisabled]="(primaryDisabled$ | async) || disableSave"
  [cancelButtonText]="cancelText"
  [tertiaryButtonText]="backText"
  [modalHeader]="modalHeader$ | async"
  (onCancel)="cancelClick()"
  (onPrimaryClick)="primaryClick()"
  (onTertiaryClick)="tertiaryClick()"
  [helpLink]="addingOrg && !isManager ? addOrganizationsHelpLinkApplicant :
    addingOrg && isManager ? addOrganizationsHelpLinkManager :
    vettingOrg && !isManager ? vetOrganizationsHelpLinkApplicant :
    vettingOrg && isManager ? vetOrganizationsHelpLinkManager :
    null">
  <ng-container [ngSwitch]="currentPage$ | async">
    <gc-add-org-id-lookup *ngSwitchCase="AddOrgModalPages.ID_LOOKUP"
      [processorType]="processorType"
      [lookupFormGroup]="lookupFormGroup"
      [disableCountrySelect]="disableCountrySelect"
      [allowedOrgLocations]="allowedOrgLocations">
    </gc-add-org-id-lookup>
    <gc-add-org-details *ngSwitchCase="AddOrgModalPages.DETAILS"
      [detailsFormGroup]="detailsFormGroup">
    </gc-add-org-details>
    <gc-add-org-address *ngSwitchCase="AddOrgModalPages.ADDRESS"
      [processorType]="processorType"
      [addressFormGroup]="addressFormGroup"
      [allowedOrgLocations]="allowedOrgLocations">
    </gc-add-org-address>
    <gc-add-org-confirmation *ngSwitchCase="AddOrgModalPages.CONFIRMATION"
      [nonprofitContactGroup]="nonprofitContactGroup">
    </gc-add-org-confirmation>
  </ng-container>
</yc-modal>