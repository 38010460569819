import { LoginBehaviors } from './login-behaviors.typing';

export enum ColorPaletteType {
  SHADES = 1,
  STANDARD = 2,
  ALT = 3
}

export enum ColorPurpose {
  ChartPrimary = 1,
  ChartSecondary = 2,
  ChartUtility = 3,
  Tags = 4
}

export interface BrandingColors {
  brandPrimary: string;
  brandSecondary: string;
  brandUtility: string;
}

export interface SaveBrandingForApi extends BrandingColors {
  logoName: string;
  richTextFontSize: string;
  richTextFontColor: string;
  richTextFontFamily: string;
  richTextLineHeight: number;
}

export interface ClientBrandingFromApi {
  logoUrl: string;
  brandPrimary: string;
  brandSecondary: string;
  brandUtility: string;
  name?: string;
  subDomain?: string;
  loginBehavior?: LoginBehaviors;
  hasImportedFromUAT?: boolean;
  logoName?: string;
  clientColorSchemes: ColorScheme[];
  siteId: number;
  richTextFontSize: string;
  richTextFontColor: string;
  richTextFontFamily: string;
  richTextLineHeight: number;
}

export interface ClientBrandingForUi extends ClientBrandingFromApi {
  chartPrimary: string;
  chartSecondary: string;
  chartUtility: string;
  colorPalette: ColorPaletteType;
}

export interface ColorSchemePayload {
  colorScheme: ColorScheme[];
}

export interface ColorScheme {
  color: string;
  colorPurpose: ColorPurpose;
  colorPalette: ColorPaletteType;
}

export interface BrandColors {
  primary: string;
  secondary: string;
  utility: string;
  chartPrimary: string;
  chartSecondary: string;
  chartUtility: string;
  colorPalette: ColorPaletteType;
}

export enum InternationalSettingsSections {
  LANGUAGES,
  CURRENCY
}

export interface InternationalSettings {
  languages: string[];
  currencies: string[];
}
export interface UpdateClientCurrencyPayload {
  codes: string[];
  default: string;
}

