import { Pipe, PipeTransform } from '@angular/core';
import { AdHocReportingDefinitions, RootObjectNames } from '../services/ad-hoc-reporting-definitions.service';

@Pipe({ name: 'gcDoesReportSupportForms' })
export class DoesReportSupportFormsPipe implements PipeTransform {

  constructor (
    private adHocDefinitions: AdHocReportingDefinitions
  ) { }

  transform (
    object: RootObjectNames
  ): boolean {
    return !this.adHocDefinitions[object]?.noFormLogic;
  }

}
