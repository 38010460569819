import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { UserTypes } from '../typings/client-user.typing';
export type PortalTypes = 'apply'|'platform'|'management';
export const BBGM_APPLICANT_SUBDOMAIN = 'bbgm-apply';

@Injectable({ providedIn: 'root' })
export class PortalDeterminationService {
  _routeBase: string;
  _prefix: string;
  private restrictedPrefixes = [
    'apply',
    'platform',
    'management',
    'www',
    'localhost'
  ];
  constructor () {
    this._prefix = location.hostname
      .replace(environment.locationBase, '')
      .replace('.', '');
  }

  get isManager () {
    return this.routeBase === 'management' || location.pathname.startsWith('/management');
  }

  get isApply () {
    return this.routeBase === 'apply' || location.pathname.startsWith('/apply');
  }

  get isPlatform () {
    return this.routeBase === 'platform' || location.pathname.startsWith('/platform');
  }

  get routeBase () {
    if (!this._routeBase) {
      this._routeBase = environment.routeBase;
    }

    return this._routeBase;
  }

  hasSubdomain () {
    const subdomain = this.getCurrentPrefix();

    return !!subdomain && subdomain !== BBGM_APPLICANT_SUBDOMAIN;
  }

  isBBGMApplicant () {
    const subdomain = this.getCurrentPrefix();

    return (subdomain === BBGM_APPLICANT_SUBDOMAIN) && this.isApply;
  }

  getRoute (route: string): string {
    return `${this.isManager ?
      '/management/' : this.isPlatform ?
      '/platform/' :
      '/apply/'
    }${route}`.replace('//', '/');
  }

  getCurrentPrefix () {
    if (this.restrictedPrefixes.includes(this._prefix)) {
      return '';
    }

    return this._prefix;
  }

  getUserType () {
    if (this.isManager) {
      return UserTypes.MANAGER;
    } else if (this.isApply) {
      return UserTypes.APPLICANT;
    } else if (this.isPlatform) {
      return UserTypes.ADMIN;
    }
    return null;
  }
}
