import { Component, Input } from '@angular/core';

@Component({
  selector: 'gc-base-component-config-settings',
  templateUrl: './base-component-config-settings.component.html',
  styleUrls: ['./base-component-config-settings.component.scss']
})
export class BaseComponentConfigSettingsComponent {
  @Input() isViewOnly: boolean;
}
