<div *ngIf="!!fundingSource"
  class="d-flex m-3 flex-column">
  <!-- Workflow Name -->
  <div class="flyout-header">
    {{ fundingSource.name }}
  </div>

  <div class="d-flex mb-2">
    <!-- Type -->
    <div class="me-4">
      <yc-i18n
        key="common:hdrType"
        defaultValue="Type">
      </yc-i18n>:
      <yc-i18n
        [key]="fundingSource.type === FundingSourceTypes.DOLLARS ?
          'GLOBAL:textCash' :
          'GLOBAL:hdrInKind'"
        [defaultValue]="fundingSource.type === FundingSourceTypes.DOLLARS ?
          'Cash' :
          'In Kind'">
      </yc-i18n>
    </div>

    <!-- Status -->
    <div class="me-4">
      <yc-i18n
        key="common:hdrStatus"
        defaultValue="Status">
      </yc-i18n>:
      <yc-i18n
        [key]="fundingSource.isClosed ?
          'GLOBAL:textClosed' :
          'GLOBAL:textOpen'"
        [defaultValue]="fundingSource.isClosed ?
          'Closed' :
          'Open'">
      </yc-i18n>
    </div>

    <!-- Processor -->
    <div *ngIf="showProcessor">
      <yc-i18n
        key="common:textProcessor"
        defaultValue="Processor">
      </yc-i18n>:
      {{ fundingSource.processingTypeId === ProcessingTypes.Client ? clientName : 'YourCause' }}
    </div>
  </div>


  <!-- Stats Row 1 -->
  <div class="d-flex justify-content-between mt-3">
    <!-- Total Budget-->
    <div class="funding-source-stat">
      <div class="font-550">
        {{ fundingSource.totalAmount | gcMoney }}
      </div>
      <div>
        <yc-i18n
          key="GLOBAL:textTotalAmount"
          defaultValue="Total amount">
        </yc-i18n>
      </div>
    </div>

    <!-- Allocated -->
    <div class="funding-source-stat">
      <div class="font-550">
        {{ fundingSource.amountAllocated | gcMoney }}
      </div>
      <div>
        <yc-i18n
          key="GLOBAL:textAllocated"
          defaultValue="Allocated">
        </yc-i18n>
      </div>
    </div>

    <!-- Reserved -->
    <div class="funding-source-stat">
      <ng-container *ngIf="canReserveFunds">
        <div class="font-550">
          {{ fundingSource.reservedAmount | gcMoney }}
        </div>
        <div>
          <yc-i18n
            key="common:hdrReserved"
            defaultValue="Reserved">
          </yc-i18n>
          <yc-fa
            icon="question-circle"
            [popoverTemplate]="reservedPopoverTemplate"
            conatiner="body"
            class="ms-2 cursor-p">
          </yc-fa>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Stats Row 2 -->
  <div class="d-flex justify-content-between mt-3 mb-3">
    <!-- Unavailable-->
    <div class="funding-source-stat">
      <div class="font-550">
        {{ fundingSource.unavailableAmount | gcMoney }}
      </div>
      <div>
        <yc-i18n
          key="GLOBAL:textUnavailable"
          defaultValue="Unavailable">
        </yc-i18n>
        <yc-fa
          icon="question-circle"
          [popoverTemplate]="unavailablePopoverTemplate"
          conatiner="body"
          class="ms-2 cursor-p">
        </yc-fa>
      </div>
    </div>

    <!-- Unallocated -->
    <div class="funding-source-stat">
      <div class="font-550">
        {{ fundingSource.amountUnallocated | gcMoney }}
      </div>
      <div>
        <yc-i18n
          key="BUDGET:hdrUnallocated"
          defaultValue="Unallocated">
        </yc-i18n>
      </div>
    </div>
    <!-- Spacer -->
    <div class="funding-source-stat"></div>
  </div>

  <!-- Actions -->
  <div class="d-flex mt-4 mb-4">
    <!-- Edit -->
    <yc-button
      class="me-2"
      [primary]="true"
      [langKey]="'BUDGET:textEditFundingSource'"
      [defaultText]="'Edit funding source'"
      (onClick)="editFundingSource()">
    </yc-button>
    <!-- Insights -->
    <yc-button *ngIf="hasInsightsPermission"
      class="me-2"
      [secondary]="true"
      [langKey]="'GLOBAL:textInsights'"
      [defaultText]="'Insights'"
      (onClick)="viewInsights()">
    </yc-button>
  </div>

  <!-- Budgets -->
  <yc-expandable-container
    [header]="'GLOBAL:textBudgets' | ycI18n: 'Budgets'"
    [subHeader]="budgetSubHeader"
    [hasData]="budgets.length > 0">
    <yc-table
      [hideTableFilters]="true"
      [rowsPerPage]="5"
      [simple]="true"
      [rows]="budgets">
      <ng-container *ycTableColumn="'common:lblBudget';
        prop: 'budgetName',
        class: 'text',
        filterType: 'text';
        let row;">
        {{ row.budgetName }}
      </ng-container>
      <ng-container *ycTableColumn="'common:hdrStatus';
        prop: 'isClosed',
        class: 'text',
        filterType: 'boolean';
        let row;">
        <yc-i18n *ngIf="row.isClosed"
          key="GLOBAL:textClosed"
          defaultValue="Closed">
        </yc-i18n>
        <yc-i18n *ngIf="!row.isClosed"
          key="GLOBAL:textOpen"
          defaultValue="Open">
        </yc-i18n>
      </ng-container>
      <ng-container *ycTableColumn="'BUDGET:hdrAllocation';
        prop: 'allocation',
        class: 'number',
        filterType: 'number';
        let row;">
        {{ row.allocation | gcMoney }}
        <br>
        <small *ngIf="canReserveFunds">
          <yc-i18n
            [key]="'GLOBAL:textAmountReserved'"
            [context]="{
              amount: row.reservedAmount | gcMoney
            }"
            defaultValue="__amount__ reserved">
          </yc-i18n>
        </small>
      </ng-container>
    </yc-table>
  </yc-expandable-container>

  <!-- Programs -->
  <yc-expandable-container
    [header]="'GLOBAL:textPrograms' | ycI18n: 'Programs'"
    [subHeader]="programSubHeader"
    [hasData]="programs.length > 0">
    <yc-table
      [rowsPerPage]="5"
      [hideTableFilters]="true"
      [simple]="true"
      [rows]="programs | ycArrayToObject : 'name'">
      <ng-container *ycTableColumn="'GLOBAL:textPrograms';
        filterType: 'text';
        prop: 'name'
        let row;">
        {{ row.name }}
      </ng-container>
    </yc-table>
  </yc-expandable-container>

</div>


<ng-template #unavailablePopoverTemplate>
  <yc-i18n
    key="BUDGET:textUnavailableFlyoutPopover"
    defaultValue="This amount cannot be used for payments.">
  </yc-i18n> 
  <a [href]="unavailableHelpLink"
    target="_blank"
    (click)="trackClick(unavailableHelpLink)"
    class="ms-2">
    <yc-i18n
      key="common:textLearnMore"
      defaultValue="Learn more">
    </yc-i18n>
  </a>
</ng-template>

<ng-template #reservedPopoverTemplate>
  <yc-i18n
    key="BUDGET:textReservedFlyoutPopover"
    defaultValue="This amount is automatically reserved from applications using budget reservation. Funds are reserved until awards and payments are created.">
  </yc-i18n>
  <a [href]="reservedHelpLink"
    target="_blank"
    (click)="trackClick(reservedHelpLink)"
    class="ms-2">
    <yc-i18n
      key="common:textLearnMore"
      defaultValue="Learn more">
    </yc-i18n>
  </a>
</ng-template>
