import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { BaseApplication } from '@core/typings/application.typing';
import { FormComponentValidChange, FormDefinitionComponent, FormValueChange } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { ErrorForDisplay } from '@features/forms/form-renderer/form-error-summary/form-error-summary.component';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { SimpleStringMap } from '@yourcause/common';
import { TypeSafeFormGroup, TypeSafeFormBuilder } from '@yourcause/common/core-forms';
import { isEqual, isUndefined } from 'lodash';
import { Subscription } from 'rxjs';
export interface FormRendererFormGroup {
  formGroup: UntypedFormGroup;
  compKey: string;
}

@Component({
  template: ''
})
export class BaseFormComponent<T> implements OnInit, OnChanges, OnDestroy {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() description = '';
  @Input() tooltipText = '';
  @Input() translations: SimpleStringMap<string>;
  @Input() defaultVal: string;
  @Input() hideLabel: boolean;
  @Input() tabIndex: number;
  @Input() showFieldChange: boolean;
  @Input() isFormBuilderView: boolean;

  protected _data: T;
  protected saveDisabled = false;
  protected _dirty = false;

  @Input()
  public get data (): T {
    return this._data;
  }
  public set data (value: T) {
    if (!isEqual(this._data, value)) {
      this._data = value;
      if (this.compKey) {
        this.patchValue();
      }
    }
  }

  @Input() formComponent: FormDefinitionComponent;
  @Input() parentFields: Partial<BaseApplication>;
  @Input() isForSetValue: boolean;
  @Input() hasCustomValidationError: boolean;
  @Input() emitInitialValidity: boolean;
  @Input() showErrorSummary: boolean;
  @Input() errorMessages: ErrorForDisplay[] = [];
  @Input() disabledOverride: boolean;
  @Input() disabled: boolean;
  @Output() dataChange = new EventEmitter<T>();

  @Output() onValueChange = new EventEmitter<FormValueChange>();
  @Output() onValidChange = new EventEmitter<FormComponentValidChange>();

  sub = new Subscription();

  get dirty (): boolean {
    return this._dirty;
  }
  set dirty (dirty: boolean) {
    this._dirty = dirty;
  }

  compKey: string;
  formGroup: TypeSafeFormGroup<any>;
  needToApplyStandardError: boolean;
  needToApplyCustomValidationError: boolean;

  constructor (
    public renderer: FormRendererComponent<BaseApplication>,
    public formGroupDir: FormGroupDirective,
    public formBuilder: TypeSafeFormBuilder,
    public formBuilderService: FormBuilderService,
    public componentHelper: ComponentHelperService
  ) { }

  get comp () {
    return this.formComponent;
  }

  get control () {
    return this.formGroup?.get(this.compKey);
  }

  get inFormBuilder () {
    return this.formBuilderService.inFormBuilder;
  }

  get isDisabled () {
    if (
      !isUndefined(this.disabledOverride) &&
      this.disabledOverride !== null
    ) {
      return this.disabledOverride;
    } else {
      return this.disabled ||
        this.componentHelper.isCompDisabled(this.comp);
    }
  }

  get isRequired () {
    if (this.comp) {
      return this.comp.validate.required;
    }

    return false;
  }


  ngOnInit () {
    this.setCompKey();
    this.setDefaultTranslations();
  }

  ngOnChanges (changes: SimpleChanges) {
    if (!this.inFormBuilder) {
      const hasCustomValidationError = !!changes.hasCustomValidationError;
      const hasStandardError = changes.showErrorSummary && this.showErrorSummary;
      if (hasCustomValidationError || hasStandardError) {
        this.updateValidityOfControl(hasCustomValidationError, hasStandardError);
      }
    }
  }

  updateValidityOfControl (
    hasCustomValidationError: boolean,
    hasStandardError: boolean
  ) {
    if (!this.control) {
      this.needToApplyCustomValidationError = hasCustomValidationError;
      this.needToApplyStandardError = hasStandardError;
    } else {
      if (hasCustomValidationError) {
        this.control.updateValueAndValidity();
        this.needToApplyCustomValidationError = false;
      }
      if (hasStandardError && (this.control.invalid || this.formGroup.invalid)) {
        this.control.markAsTouched();
        this.control.markAsDirty();
        this.control.updateValueAndValidity();
        this.needToApplyStandardError = false;
      }
    }
  }

  setDefaultTranslations () {
    if (Object.keys(this.translations || {}).length === 0) {
      this.translations = {
        [this.label]: this.label,
        [this.description]: this.description,
        [this.placeholder]: this.placeholder,
        [this.tooltipText]: this.tooltipText
      };
    }

    this.label = this.translations[this.label] || this.label;
    this.description = this.translations[this.description] || this.description;
    this.placeholder = this.translations[this.placeholder] || this.translations.placeholder || this.placeholder;
    this.tooltipText = this.translations[this.tooltipText] || this.tooltipText;
  }

  /**
   * Adapt the key for default value,
   * so the controls in form builder have different names / are not bound to eachother
   */
  getAdaptedKeyFromComponentKey (
    componentKey: string,
    isForSetValue: boolean
  ) {
    return isForSetValue ?
      `defaultValue_${componentKey}` :
      componentKey;
  }

  setCompKey () {
    this.compKey = this.getAdaptedKeyFromComponentKey(
      this.comp.key,
      this.isForSetValue
    );
  }

  setFormGroup (
    controlValue: any,
    validators: any[],
    isRichText = false
  ) {
    if (
      (
        !this.inFormBuilder &&
        (
          this.comp.customValidation ||
          !!this.comp.validate.custom
        )
      )
    ) {
      validators = [
        ...validators,
        this.customValidator()
      ];
    }
    const existingGroup = this.formGroupDir?.form.get(this.compKey);

    if (!existingGroup) {
      this.generateFormGroup(controlValue, validators, isRichText);
    } else {
      this.formGroup = existingGroup as UntypedFormGroup;

      this.formGroup.get(this.compKey).setValidators(validators);

      // if we've viewed the group and it's invalid
      if (existingGroup.dirty && existingGroup.invalid) {
        setTimeout(() => {
          // update the value and validity (to show errors)
          // after the template renders
          existingGroup.updateValueAndValidity();
        });
      }
    }
    this.handleFormGroupReady(!existingGroup);
  }

  generateFormGroup (
    controlValue: any,
    validators: any[],
    isRichText = false
  ) {
    // Our Rich Text Editor Plugin requires the disabled property to live on the control
    if (isRichText) {
      controlValue = { value: controlValue, disabled: this.isDisabled };
    }
    this.formGroup = this.formBuilder.group({
      [this.compKey]: [
        controlValue,
        validators
      ]
    });
  }

  handleFormGroupReady (shouldEmitFormGroupReady: boolean) {
    this.sub.add(this.control.statusChanges.subscribe(() => {
      setTimeout(() => {
        this.emitValidity();
      });
    }));
    if (shouldEmitFormGroupReady) {
      this.renderer?.onFormGroupReady({
        formGroup: this.formGroup,
        compKey: this.compKey
      });
    }
    if (this.emitInitialValidity) {
      this.emitValidity();
    }
    if (this.needToApplyCustomValidationError || this.needToApplyStandardError) {
      setTimeout(() => {
        this.updateValidityOfControl(this.needToApplyCustomValidationError, this.needToApplyStandardError);
      });
    }
  }

  emitValidity () {
    this.onValidChange.emit({
      isValid: this.control.valid,
      key: this.compKey
    });
  }

  patchValue () { }

  emitData (dataToEmit: T) {
    if (!isEqual(this.data, dataToEmit)) {
      this._data = dataToEmit;
      if (!this.saveDisabled) {
        this.dataChange.emit(dataToEmit);
      }
    }
  }

  getValueOnShow () {
    return this.data;
  }


  customValidator () {
    return () => {
      const customValidationResult = this.comp?.validate.validationResult;
      const customValidationMessage = this.comp?.validate.customMessage;
      if (this.hasCustomValidationError) {
        if (customValidationResult) {
          return {
            customValidationError: {
              errorMessage: customValidationMessage || customValidationResult
            }
          };
        } else {
          return {
            customValidationError: {
              errorMessage: this.comp?.customValidation?.result ??
                customValidationMessage
            }
          };
        }
      }

      return null;
    };
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
