<div>
  <!-- Validity Error -->
  <yc-alert *ngIf="showValidityError"
    alertClass="danger"
    [message]="'common:textClickManageConditionsToProceedAlert' | ycI18n: 'To proceed with using conditional logic, you must set up at least one condition.'">
  </yc-alert>

  <div class="mb-3">
    <!-- Evaluation Selector -->
    <gc-evaluation-type-selector
      [availableColumns]="availableColumns"
      [isViewOnly]="isViewOnly"
      [evaluationType]="logicGroups.evaluationType"
      [evaluationTypeOptions]="evaluationTypeOptions"
      (onEvaluationTypeChange)="handleEvaluationTypeChange($event)">
    </gc-evaluation-type-selector>
  </div>

  <div class="d-flex flex-column mb-3">
    <!-- Logic Summary -->
    <gc-logic-summary-sentence
      [logic]="logicGroups"
      [availableColumns]="availableColumns"
      [noLogicMessage]="instructions"
      [fallbackToNoLogicMessage]="true">
    </gc-logic-summary-sentence>

    <!-- Manage Conditional Logic -->
    <yc-button
      class="d-flex justify-content-end"
      [link]="true"
      [langKey]="'common:textManageConditionalLogic'"
      [defaultText]="'Manage conditional logic'"
      (onClick)="openLogicBuilderModal()">
    </yc-button>
  </div>

</div>
