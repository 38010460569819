<yc-modal *ngIf="builderName"
  [modalHeader]="builderName"
  [modalSubHeader]="currentColumnName"
  [primaryButtonText]="'common:textApply' | ycI18n: 'Apply'"
  [secondaryButtonText]="isViewOnly ?
    '' :
    'common:linkClearAllLogic' | ycI18n: 'Clear all logic'"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  [primaryDisabled]="!valid || isViewOnly"
  (onPrimaryClick)="handleSubmit()"
  (onSecondaryClick)="handleClear()"
  (onCancel)="handleCancel()">

  <gc-conditional-logic-builder
    [availableColumns]="availableColumns"
    [isViewOnly]="isViewOnly"
    [maxDepth]="3"
    [logic]="logic"
    [evaluationTypeOptions]="evaluationTypeOptions"
    (logicChanged)="handleLogicChange($event)"
    (validChange)="handleValidChange($event)">
  </gc-conditional-logic-builder>
</yc-modal>
