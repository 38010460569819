
import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { CountriesService } from '@core/services/countries.service';
import { AllowedLocation } from '@core/typings/program.typing';
import { TypeSafeFormGroup, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { SpecialHandlingFormGroup } from '../special-handling-modal/special-handling-modal.component';
export interface AddressFormGroup {
  name?: string;
  address1: string;
  address2: string;
  countryCode: string;
  city: string;
  stateProvRegCode: string;
  postalCode: string;
}

@Component({
  selector: 'gc-address-block',
  templateUrl: './address-block.component.html',
  styleUrls: ['./address-block.component.scss']
})
export class AddressBlockComponent implements OnInit {
  @Input() addressFormGroup: TypeSafeFormGroup<AddressFormGroup>|TypeSafeFormGroup<SpecialHandlingFormGroup>;
  @Input() countryDisabled = false;
  @Input() disableInputs = false;
  @Input() enforceCustomStateValidation = true;
  @Input() allowedOrgLocations: AllowedLocation[];

  countrySelects = this.countriesService.countrySelects;
  countryToRegionOptionsMap = this.countriesService.countryCodeToRegionOptionsMap;
  regionSelects: TypeaheadSelectOption[] = [];
  regionsExist: boolean;

  constructor (
    private countriesService: CountriesService
  ) { }

  ngOnInit () {
    this.setRegionsOnCountryChange();
  }

  setRegionsOnCountryChange () {
    const countryCode = this.addressFormGroup.value.countryCode;
    if (!!countryCode) {
      let regionSelects = this.countryToRegionOptionsMap[countryCode];
      if (this.allowedOrgLocations?.length > 0) {
        const foundCountry = this.allowedOrgLocations.find((location) => {
          const _countryCode = this.countriesService.countryIdToCountryMap[location.countryId].countryCode;
          
          return _countryCode === countryCode;
        });
        if (!!foundCountry) {
          if (foundCountry.regionIds.length > 0) {
            regionSelects = this.countriesService.countryToRegionsMap[countryCode].filter((region) => {
              return foundCountry.regionIds.includes(region.regionId);
            }).map((region) => {
              return {
                label: region.regionName,
                value: region.regionCode
              };
            });
          }
        }
      }
      this.setRegionSelects(regionSelects);
    } else {
      this.regionSelects = [];
      this.regionsExist = false;
    }

    if (this.enforceCustomStateValidation) {
      const control = (this.addressFormGroup as TypeSafeFormGroup<AddressFormGroup>).get('stateProvRegCode');
      if (this.regionSelects.length === 0) {
        control.clearValidators();
        control.updateValueAndValidity();
      } else if (!control.hasValidator(Validators.required)) {
        control.addValidators(Validators.required);
        control.updateValueAndValidity();
      }
    }
  }

  setRegionSelects (regionSelects: TypeaheadSelectOption[]) {
    this.regionSelects = regionSelects;
    this.regionsExist = this.regionSelects.length > 0;
  }
}
