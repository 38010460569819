export const BATCH_STATUS: StatusOptions[] = [{
  name: 'Open',
  id: 1
}, {
  name: 'Reviewed',
  id: 2
}, {
  name: 'Sent to processing - Pending',
  id: 3
}, {
  name: 'Funded',
  id: 4
}, {
  name: 'Disbursed',
  id: 5
}, {
  name: 'Complete',
  id: 6
}];

export enum ApplicationStatuses {
  AwaitingReview = 1,
  InProgress = 2,
  Approved = 3,
  Declined = 4,
  Hold = 5,
  Canceled = 6,
  Draft = 7
}

export enum BatchStatuses {
  Open = 1,
  Reviewed = 2,
  Processing = 3,
  Funded = 4,
  Disbursed = 5,
  Complete = 6
}

export enum PaymentStatus {
  Pending = 1,
  Scheduled = 2,
  Processing = 3,
  Outstanding = 4,
  Cleared = 5,
  Voided = 6,
  Fulfilled = 7,
  ProcessingHold = 8
}

export enum PaymentSubStatus {
  RETURNED = 1,
  CANCELLED = 2,
  REJECTED_ADDRES_REQUEST = 3,
  ALTERNATE_ADDRESS_REQUEST = 4,
  RECALLED = 5,
  VOIDED_FOR_STALE_CHECK = 6,
  VOIDED_FOR_USPS_CHECK_RETURN = 7,
  REISSUE_REQUESTED_BY_CHARITY = 8,
  INVALID_BANKING_DETAILS = 9,
  REJECTED_BY_RECEIVING_BANK = 10,
  FAILED_BLACKBAUD_COMPLIANCE_REVIEW = 11
}

export interface StatusMap {
  [x: string]: {
    name: string;
    translated: string;
    icon?: string;
    iconClass?: string;
    tooltipText?: string;
  };
}

export interface StatusOptions {
  id: number;
  name: string;
}

export interface ReasonCode {
  code: number;
  name: string;
  description: string;
}
