import { CyclesAPI } from '@core/typings/api/cycles.typing';
import { SimpleStringMap } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class CyclesState extends BaseYcState {
  readonly allMyCycleSelectOptions: TypeaheadSelectOption[];
  readonly allMyNonFutureCycleSelectOptions: TypeaheadSelectOption[];
  readonly programCycleMap: SimpleStringMap<CyclesAPI.SimpleCycle[]> = {};
}
