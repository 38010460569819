import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { ApplicantForDash, ApplicantSummaryApi, ApplicantTop10, ApplicantTopLevelStats } from '@core/typings/applicant.typing';
import { ApplicantsApplicationsPaymentsFundingSource } from '@core/typings/budget.typing';
import { OrgForApplicant } from '@core/typings/organization.typing';
import { AwardForApplicant } from '@features/awards/typings/award.typing';
import { PaginatedResponse, PaginationOptions, SummaryNumbers } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class ApplicantManagerResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getApplicantSummary (id: number): Promise<ApplicantSummaryApi> {
    const endpoint = `api/manager/applicationsdashboard/GetApplicantSummary/${id}`;

    return this.httpRestService.get(endpoint);
  }

  getApplicantCalculations (id: number): Promise<SummaryNumbers> {
    const endpoint = `api/manager/applicationsdashboard/GetApplicantSummaryCalculations/${id}`;

    return this.httpRestService.get(endpoint);
  }

  getOrgsForApplicant (
    id: number,
    paginationOptions: PaginationOptions<OrgForApplicant>
  ): Promise<PaginatedResponse> {
    const endpoint = `api/manager/applicationsdashboard/GetOrgsForApplicant/${id}`;

    return this.httpRestService.post(endpoint, {
      paginationOptions
    });
  }

  getAwardsForApplicant (
    id: number,
    paginationOptions: PaginationOptions<AwardForApplicant>
  ): Promise<PaginatedResponse> {
    const endpoint = `api/manager/applicationsdashboard/GetAwardsAndPaymentsForApplicant/${id}`;

    return this.httpRestService.post(endpoint, {
      paginationOptions
    });
  }

  async getApplicantStats (
    programCycleIds: number[]
  ): Promise<ApplicantTopLevelStats> {
    const endpoint = 'api/manager/insights/applicant/stats';

    return this.httpRestService.post(endpoint, {
      programCycleIds
    });
  }

  async getApplicants (
    programCycleIds: number[]
  ): Promise<ApplicantTop10[]> {
    const endpoint = 'api/manager/insights/applicant/topten';

    return this.httpRestService.post(endpoint, {
      programCycleIds
    });
  }

  getApplicantsForInsights (
    paginationOptions: PaginationOptions<ApplicantForDash>,
    programCycleIds: number[]
  ): Promise<PaginatedResponse<ApplicantForDash>> {
    const endpoint = 'api/manager/insights/applicants';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      programCycleIds
    });
  }

  getFundingSourcesForApplicant (
    id: number,
    paginationOptions: PaginationOptions<ApplicantsApplicationsPaymentsFundingSource>
  ): Promise<PaginatedResponse> {
    const endpoint = `api/manager/applications/ApplicantsApplicationsPaymentsFundingSources/${id}`;

    return this.httpRestService.post(endpoint, {
      paginationOptions
    });
  }
}

