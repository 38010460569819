import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResultData, PaginationOptions } from '@yourcause/common';
import { CopyStandardDashboardPayload, StandardFormTemplate, StandardProductDashboard, StandardProductForm, StandardProductFormField, StandardProductReport, StandardProductTypes, StandardReportTemplate } from './standard-product-configuration.typing';

@Injectable({ providedIn: 'root' })
export class StandardProductConfigurationResources {

  constructor (
    private http: HttpRestService
  ) { }

  getFormsForPlatformTool (
    paginationOptions: PaginationOptions<StandardProductForm>,
    returnOnlyRecordsToBeStaged: boolean
  ): Promise<APIResultData<StandardProductForm>> {
    const endpoint = 'api/admin/StandardProductComponent/forms';

    return this.http.post(endpoint, {
      paginationOptions,
      returnOnlyRecordsToBeStaged
    });
  }

  getFieldsForPlatformTool (
    paginationOptions: PaginationOptions<StandardProductFormField>,
    returnOnlyRecordsToBeStaged: boolean
  ): Promise<APIResultData<StandardProductFormField>> {
    const endpoint = 'api/admin/StandardProductComponent/fields';

    return this.http.post(endpoint, {
      paginationOptions,
      returnOnlyRecordsToBeStaged
    });
  }

  getReportsForPlatformTool (
    paginationOptions: PaginationOptions<StandardProductReport>,
    returnOnlyRecordsToBeStaged: boolean
  ): Promise<APIResultData<StandardProductReport>> {
    const endpoint = 'api/admin/StandardProductComponent/reports';

    return this.http.post(endpoint, {
      paginationOptions,
      returnOnlyRecordsToBeStaged
    });
  }

  getDashboardsForPlatformTool (
    paginationOptions: PaginationOptions<StandardProductDashboard>,
    returnOnlyRecordsToBeStaged: boolean
  ): Promise<APIResultData<StandardProductDashboard>> {
    const endpoint = 'api/admin/StandardProductComponent/dashboards';

    return this.http.post(endpoint, {
      paginationOptions,
      returnOnlyRecordsToBeStaged
    });
  }

  publishStandardProductComponents (
    componentIds: number[],
    type: StandardProductTypes
  ) {
    const endpoint = 'api/manager/StandardProductComponent/SetPublish';

    return this.http.post(endpoint, {
      type,
      componentIds,
      publish: true
    });
  }

  unpublishStandardProductComponent (
    componentId: number,
    type: StandardProductTypes
  ) {
    const endpoint = 'api/manager/StandardProductComponent/SetPublish';

    return this.http.post(endpoint, {
      type,
      componentIds: [componentId],
      publish: false
    });
  }

  async getStandardFormTemplates (): Promise<StandardFormTemplate[]> {
    const endpoint = 'api/manager/forms/StandardPublishedForms/Paginated';
    const paginationOptions: PaginationOptions<StandardFormTemplate> = {
      returnAll: true,
      retrieveTotalRecordCount: false,
      filterColumns: [],
      sortColumns: [{
        columnName: 'name',
        sortAscending: true
      }],
      rowsPerPage: 1,
      pageNumber: 0
    };

    const results = await this.http.post<APIResultData<StandardFormTemplate>>(endpoint, {
      paginationOptions
    });

    return results.records;
  }

  async getStandardReportTemplates (): Promise<StandardReportTemplate[]> {
    const endpoint = 'api/manager/reports/StandardPublishedTemplates/Paginated';
    const paginationOptions: PaginationOptions<StandardReportTemplate> = {
      returnAll: true,
      retrieveTotalRecordCount: false,
      filterColumns: [],
      sortColumns: [{
        columnName: 'name',
        sortAscending: true
      }],
      rowsPerPage: 1,
      pageNumber: 0
    };

    const results = await this.http.post<APIResultData<StandardReportTemplate>>(endpoint, {
      paginationOptions
    });

    return results.records;
  }

  pushToProduction () {
    const endpoint = 'api/admin/StandardProductComponent/StageNextVersion';

    return this.http.post(endpoint, {});
  }

  retrieveStagedFileInfo (): Promise<{
    fileIsStaged: boolean;
  }> {
    const endpoint = 'api/admin/StandardProductComponent/RetrieveStagedFileInfo';

    return this.http.get(endpoint);
  }

  processStagedFile () {
    const endpoint = 'api/admin/StandardProductComponent/ProcessStagedVersion';

    return this.http.post(endpoint, {});
  }

  copyStandardDashboard (payload: CopyStandardDashboardPayload) {
    const endpoint = 'api/manager/dashboard/CopyStandardDashboard';

    return this.http.post(endpoint, payload);
  }

  getStandardProductDashboards (): Promise<StandardProductDashboard[]> {
    const endpoint = 'api/manager/dashboard/StandardProductDashboards';

    return this.http.get(endpoint);
  }
}
