import { BaseEmailOptionsModel, EmailOptionsModelForSave } from '@features/system-emails/email.typing';

export interface CancelApplicationPayload {
  applicationId: number;
  reason: number;
  comment: string;
  sendEmailToApplicants: boolean;
  customMessage: string;
  emailOptions: EmailOptionsModelForSave;
}

export interface CancelApplicationModalResponse {
  applicationId: number;
  reason: number;
  comment: string;
  sendEmailToApplicants: boolean;
  emailOptions: EmailOptionsModelForSave;
}

export enum CancelReasons {
  FundingNoLongerRequired = 1,
  ProjectOrProgramCancelledOrDiscontinued = 2,
  DuplicateApplication = 3,
  ChangeInCircumstances = 4,
  NoLongerEligibleForFunding = 5,
  Other = 6
}

export interface CancelApplicationFormGroup extends BaseEmailOptionsModel {
  reason: CancelReasons;
  comment: string;
  notifyApplicant: boolean;
}
