import { Pipe, PipeTransform } from '@angular/core';
import { FilterModalTypes } from '@yourcause/common';
import { LogicBuilderService } from '../logic-builder.service';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';

@Pipe({
  name: 'ycShowOtherColumnSelector'
})
export class ShowOtherColumnSelectorPipe implements PipeTransform {

  constructor (
    private logicBuilderService: LogicBuilderService
  ) { }

  transform (
    otherColumnOptions: TypeaheadSelectOption[],
    comparison: FilterModalTypes
  ) {
    return this.logicBuilderService.shouldShowOtherColumnSelector(
      otherColumnOptions || [],
      comparison
    );
  }
}
