import { Injectable } from '@angular/core';
import { InsertTokenState } from '@core/states/insert-token.state';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';

@Injectable({ providedIn: 'root' })
@AttachYCState(InsertTokenState)
export class InsertTokenService extends BaseYCService<InsertTokenState> {

  get tokenToInsert () {
    return this.get('tokenToInsert');
  }

  setTokenToInsert (token: string) {
    this.set('tokenToInsert', token);
  }
}
