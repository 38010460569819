export default [
  {
    "id": 1,
    "code": "1",
    "name": "Arts, Culture, and Humanities",
    "defaultIconUrl": "https://npp-images-nonprofit-image-prod.imgix.net/defaultImages/default_npo_icon_cat1.gif"
  },
  {
    "parentCode": "1",
    "id": 2,
    "code": "A",
    "name": "Arts, Culture and Humanities"
  },
  {
    "parentCode": "A",
    "id": 3,
    "code": "A01",
    "name": "Alliance/Advocacy Organizations"
  },
  {
    "parentCode": "A",
    "id": 4,
    "code": "A02",
    "name": "Management & Technical Assistance"
  },
  {
    "parentCode": "A",
    "id": 5,
    "code": "A03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "A",
    "id": 6,
    "code": "A05",
    "name": "Research Institutes and/or Public Policy Analysis"
  },
  {
    "parentCode": "A",
    "id": 7,
    "code": "A11",
    "name": "Single Organization Support"
  },
  {
    "parentCode": "A",
    "id": 8,
    "code": "A12",
    "name": "Fund Raising and/or Fund Distribution"
  },
  {
    "parentCode": "A",
    "id": 9,
    "code": "A19",
    "name": "Nonmonetary Support N.E.C.*"
  },
  {
    "parentCode": "A",
    "id": 10,
    "code": "A20",
    "name": "Arts, Cultural Organizations - Multipurpose"
  },
  {
    "parentCode": "A",
    "id": 11,
    "code": "A23",
    "name": "Cultural, Ethnic Awareness"
  },
  {
    "parentCode": "A",
    "id": 12,
    "code": "A25",
    "name": "Arts Education"
  },
  {
    "parentCode": "A",
    "id": 13,
    "code": "A26",
    "name": "Arts Council/Agency"
  },
  {
    "parentCode": "A",
    "id": 14,
    "code": "A30",
    "name": "Media, Communications Organizations"
  },
  {
    "parentCode": "A",
    "id": 15,
    "code": "A31",
    "name": "Film, Video"
  },
  {
    "parentCode": "A",
    "id": 16,
    "code": "A32",
    "name": "Television"
  },
  {
    "parentCode": "A",
    "id": 17,
    "code": "A33",
    "name": "Printing, Publishing"
  },
  {
    "parentCode": "A",
    "id": 18,
    "code": "A34",
    "name": "Radio"
  },
  {
    "parentCode": "A",
    "id": 19,
    "code": "A40",
    "name": "Visual Arts Organizations"
  },
  {
    "parentCode": "A",
    "id": 20,
    "code": "A50",
    "name": "Museum, Museum Activities"
  },
  {
    "parentCode": "A",
    "id": 21,
    "code": "A51",
    "name": "Art Museums"
  },
  {
    "parentCode": "A",
    "id": 22,
    "code": "A52",
    "name": "Children's Museums"
  },
  {
    "parentCode": "A",
    "id": 23,
    "code": "A54",
    "name": "History Museums"
  },
  {
    "parentCode": "A",
    "id": 24,
    "code": "A56",
    "name": "Natural History, Natural Science Museums"
  },
  {
    "parentCode": "A",
    "id": 25,
    "code": "A57",
    "name": "Science and Technology Museums"
  },
  {
    "parentCode": "A",
    "id": 26,
    "code": "A60",
    "name": "Performing Arts Organizations"
  },
  {
    "parentCode": "A",
    "id": 27,
    "code": "A61",
    "name": "Performing Arts Centers"
  },
  {
    "parentCode": "A",
    "id": 28,
    "code": "A62",
    "name": "Dance"
  },
  {
    "parentCode": "A",
    "id": 29,
    "code": "A63",
    "name": "Ballet"
  },
  {
    "parentCode": "A",
    "id": 30,
    "code": "A65",
    "name": "Theater"
  },
  {
    "parentCode": "A",
    "id": 31,
    "code": "A68",
    "name": "Music"
  },
  {
    "parentCode": "A",
    "id": 32,
    "code": "A69",
    "name": "Symphony Orchestras"
  },
  {
    "parentCode": "A",
    "id": 33,
    "code": "A6A",
    "name": "Opera"
  },
  {
    "parentCode": "A",
    "id": 34,
    "code": "A6B",
    "name": "Singing, Choral"
  },
  {
    "parentCode": "A",
    "id": 35,
    "code": "A6C",
    "name": "Music Groups, Bands, Ensembles"
  },
  {
    "parentCode": "A",
    "id": 36,
    "code": "A6E",
    "name": "Performing Arts Schools"
  },
  {
    "parentCode": "A",
    "id": 37,
    "code": "A70",
    "name": "Humanities Organizations"
  },
  {
    "parentCode": "A",
    "id": 38,
    "code": "A80",
    "name": "Historical Societies, Related Historical Activities"
  },
  {
    "parentCode": "A",
    "id": 39,
    "code": "A84",
    "name": "Commemorative Events"
  },
  {
    "parentCode": "A",
    "id": 40,
    "code": "A90",
    "name": "Arts Service Organizations and Activities"
  },
  {
    "parentCode": "A",
    "id": 41,
    "code": "A99",
    "name": "Arts, Culture, and Humanities N.E.C."
  },
  {
    "id": 42,
    "code": "2",
    "name": "Education",
    "defaultIconUrl": "https://npp-images-nonprofit-image-prod.imgix.net/defaultImages/default_npo_icon_cat2.gif"
  },
  {
    "parentCode": "2",
    "id": 43,
    "code": "B",
    "name": "Education"
  },
  {
    "parentCode": "B",
    "id": 44,
    "code": "B01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "B",
    "id": 45,
    "code": "B02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "B",
    "id": 46,
    "code": "B03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "B",
    "id": 47,
    "code": "B05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "B",
    "id": 48,
    "code": "B11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "B",
    "id": 49,
    "code": "B12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "B",
    "id": 50,
    "code": "B19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "B",
    "id": 51,
    "code": "B20",
    "name": "Elementary, Secondary Education, K - 12"
  },
  {
    "parentCode": "B",
    "id": 52,
    "code": "B21",
    "name": "Kindergarten, Preschool, Nursery School, Early Admissions"
  },
  {
    "parentCode": "B",
    "id": 53,
    "code": "B24",
    "name": "Primary, Elementary Schools"
  },
  {
    "parentCode": "B",
    "id": 54,
    "code": "B25",
    "name": "Secondary, High School"
  },
  {
    "parentCode": "B",
    "id": 55,
    "code": "B28",
    "name": "Specialized Education Institutions"
  },
  {
    "parentCode": "B",
    "id": 56,
    "code": "B30",
    "name": "Vocational, Technical Schools"
  },
  {
    "parentCode": "B",
    "id": 57,
    "code": "B40",
    "name": "Higher Education Institutions"
  },
  {
    "parentCode": "B",
    "id": 58,
    "code": "B41",
    "name": "Community or Junior Colleges"
  },
  {
    "parentCode": "B",
    "id": 59,
    "code": "B42",
    "name": "Undergraduate College (4-year)"
  },
  {
    "parentCode": "B",
    "id": 60,
    "code": "B43",
    "name": "University or Technological Institute"
  },
  {
    "parentCode": "B",
    "id": 61,
    "code": "B50",
    "name": "Graduate, Professional Schools (Separate Entities)"
  },
  {
    "parentCode": "B",
    "id": 62,
    "code": "B60",
    "name": "Adult, Continuing Education"
  },
  {
    "parentCode": "B",
    "id": 63,
    "code": "B70",
    "name": "Libraries"
  },
  {
    "parentCode": "B",
    "id": 64,
    "code": "B80",
    "name": "Student Services, Organizations of Students"
  },
  {
    "parentCode": "B",
    "id": 65,
    "code": "B82",
    "name": "Scholarships, Student Financial Aid Services, Awards"
  },
  {
    "parentCode": "B",
    "id": 66,
    "code": "B83",
    "name": "Student Sororities, Fraternities"
  },
  {
    "parentCode": "B",
    "id": 67,
    "code": "B84",
    "name": "Alumni Associations"
  },
  {
    "parentCode": "B",
    "id": 68,
    "code": "B90",
    "name": "Educational Services and Schools - Other"
  },
  {
    "parentCode": "B",
    "id": 69,
    "code": "B92",
    "name": "Remedial Reading, Reading Encouragement"
  },
  {
    "parentCode": "B",
    "id": 70,
    "code": "B94",
    "name": "Parent/Teacher Group"
  },
  {
    "parentCode": "B",
    "id": 71,
    "code": "B99",
    "name": "Education N.E.C."
  },
  {
    "id": 72,
    "code": "3",
    "name": "Environment and Animals",
    "defaultIconUrl": "https://npp-images-nonprofit-image-prod.imgix.net/defaultImages/default_npo_icon_cat3.gif"
  },
  {
    "parentCode": "3",
    "id": 73,
    "code": "C",
    "name": "Environmental Quality, Protection and Beautification "
  },
  {
    "parentCode": "C",
    "id": 74,
    "code": "C01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "C",
    "id": 75,
    "code": "C02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "C",
    "id": 76,
    "code": "C03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "C",
    "id": 77,
    "code": "C05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "C",
    "id": 78,
    "code": "C11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "C",
    "id": 79,
    "code": "C12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "C",
    "id": 80,
    "code": "C19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "C",
    "id": 81,
    "code": "C20",
    "name": "Pollution Abatement and Control Services"
  },
  {
    "parentCode": "C",
    "id": 82,
    "code": "C27",
    "name": "Recycling Programs"
  },
  {
    "parentCode": "C",
    "id": 83,
    "code": "C30",
    "name": "Natural Resources Conservation and Protection"
  },
  {
    "parentCode": "C",
    "id": 84,
    "code": "C32",
    "name": "Water Resource, Wetlands Conservation and Management"
  },
  {
    "parentCode": "C",
    "id": 85,
    "code": "C34",
    "name": "Land Resources Conservation"
  },
  {
    "parentCode": "C",
    "id": 86,
    "code": "C35",
    "name": "Energy Resources Conservation and Development"
  },
  {
    "parentCode": "C",
    "id": 87,
    "code": "C36",
    "name": "Forest Conservation"
  },
  {
    "parentCode": "C",
    "id": 88,
    "code": "C40",
    "name": "Botanical, Horticultural, and Landscape Services"
  },
  {
    "parentCode": "C",
    "id": 89,
    "code": "C41",
    "name": "Botanical Gardens, Arboreta and Botanical Organizations"
  },
  {
    "parentCode": "C",
    "id": 90,
    "code": "C42",
    "name": "Garden Club, Horticultural Program"
  },
  {
    "parentCode": "C",
    "id": 91,
    "code": "C50",
    "name": "Environmental Beautification and Aesthetics"
  },
  {
    "parentCode": "C",
    "id": 92,
    "code": "C60",
    "name": "Environmental Education and Outdoor Survival Programs"
  },
  {
    "parentCode": "C",
    "id": 93,
    "code": "C99",
    "name": "Environmental Quality, Protection, and Beautification N.E.C."
  },
  {
    "parentCode": "3",
    "id": 94,
    "code": "D",
    "name": "Animal-Related "
  },
  {
    "parentCode": "D",
    "id": 95,
    "code": "D01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "D",
    "id": 96,
    "code": "D02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "D",
    "id": 97,
    "code": "D03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "D",
    "id": 98,
    "code": "D05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "D",
    "id": 99,
    "code": "D11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "D",
    "id": 100,
    "code": "D12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "D",
    "id": 101,
    "code": "D19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "D",
    "id": 102,
    "code": "D20",
    "name": "Animal Protection and Welfare"
  },
  {
    "parentCode": "D",
    "id": 103,
    "code": "D30",
    "name": "Wildlife Preservation, Protection"
  },
  {
    "parentCode": "D",
    "id": 104,
    "code": "D31",
    "name": "Protection of Endangered Species"
  },
  {
    "parentCode": "D",
    "id": 105,
    "code": "D32",
    "name": "Bird Sanctuary, Preserve"
  },
  {
    "parentCode": "D",
    "id": 106,
    "code": "D33",
    "name": "Fisheries Resources"
  },
  {
    "parentCode": "D",
    "id": 107,
    "code": "D34",
    "name": "Wildlife Sanctuary, Refuge"
  },
  {
    "parentCode": "D",
    "id": 108,
    "code": "D40",
    "name": "Veterinary Services"
  },
  {
    "parentCode": "D",
    "id": 109,
    "code": "D50",
    "name": "Zoo, Zoological Society"
  },
  {
    "parentCode": "D",
    "id": 110,
    "code": "D60",
    "name": "Other Services - Specialty Animals"
  },
  {
    "parentCode": "D",
    "id": 111,
    "code": "D61",
    "name": "Animal Training, Behavior"
  },
  {
    "parentCode": "D",
    "id": 112,
    "code": "D99",
    "name": "Animal-Related N.E.C."
  },
  {
    "id": 113,
    "code": "4",
    "name": "Health",
    "defaultIconUrl": "https://npp-images-nonprofit-image-prod.imgix.net/defaultImages/default_npo_icon_cat4.gif"
  },
  {
    "parentCode": "4",
    "id": 114,
    "code": "E",
    "name": "Health - General and Rehabilitative"
  },
  {
    "parentCode": "E",
    "id": 115,
    "code": "E01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "E",
    "id": 116,
    "code": "E02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "E",
    "id": 117,
    "code": "E03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "E",
    "id": 118,
    "code": "E05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "E",
    "id": 119,
    "code": "E11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "E",
    "id": 120,
    "code": "E12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "E",
    "id": 121,
    "code": "E19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "E",
    "id": 122,
    "code": "E20",
    "name": "Hospitals and Related Primary Medical Care Facilities"
  },
  {
    "parentCode": "E",
    "id": 123,
    "code": "E21",
    "name": "Community Health Systems"
  },
  {
    "parentCode": "E",
    "id": 124,
    "code": "E22",
    "name": "Hospital, General"
  },
  {
    "parentCode": "E",
    "id": 125,
    "code": "E24",
    "name": "Hospital, Specialty"
  },
  {
    "parentCode": "E",
    "id": 126,
    "code": "E30",
    "name": "Health Treatment Facilities, Primarily Outpatient"
  },
  {
    "parentCode": "E",
    "id": 127,
    "code": "E31",
    "name": "Group Health Practice (Health Maintenance Organizations)"
  },
  {
    "parentCode": "E",
    "id": 128,
    "code": "E32",
    "name": "Ambulatory Health Center, Community Clinic"
  },
  {
    "parentCode": "E",
    "id": 129,
    "code": "E40",
    "name": "Reproductive Health Care Facilities and Allied Services"
  },
  {
    "parentCode": "E",
    "id": 130,
    "code": "E42",
    "name": "Family Planning Centers"
  },
  {
    "parentCode": "E",
    "id": 131,
    "code": "E50",
    "name": "Rehabilitative Medical Services"
  },
  {
    "parentCode": "E",
    "id": 132,
    "code": "E60",
    "name": "Health Support Services"
  },
  {
    "parentCode": "E",
    "id": 133,
    "code": "E61",
    "name": "Blood Supply Related"
  },
  {
    "parentCode": "E",
    "id": 134,
    "code": "E62",
    "name": "Ambulance, Emergency Medical Transport Services"
  },
  {
    "parentCode": "E",
    "id": 135,
    "code": "E65",
    "name": "Organ and Tissue Banks"
  },
  {
    "parentCode": "E",
    "id": 136,
    "code": "E70",
    "name": "Public Health Program (Includes General Health and Wellness Promotion Services)"
  },
  {
    "parentCode": "E",
    "id": 137,
    "code": "E80",
    "name": "Health, General and Financing"
  },
  {
    "parentCode": "E",
    "id": 138,
    "code": "E86",
    "name": "Patient Services - Entertainment, Recreation"
  },
  {
    "parentCode": "E",
    "id": 139,
    "code": "E90",
    "name": "Nursing Services (General)"
  },
  {
    "parentCode": "E",
    "id": 140,
    "code": "E91",
    "name": "Nursing, Convalescent Facilities"
  },
  {
    "parentCode": "E",
    "id": 141,
    "code": "E92",
    "name": "Home Health Care"
  },
  {
    "parentCode": "E",
    "id": 142,
    "code": "E99",
    "name": "Health - General and Rehabilitative N.E.C."
  },
  {
    "parentCode": "4",
    "id": 143,
    "code": "F",
    "name": "Mental Health, Crisis Intervention"
  },
  {
    "parentCode": "F",
    "id": 144,
    "code": "F01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "F",
    "id": 145,
    "code": "F02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "F",
    "id": 146,
    "code": "F03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "F",
    "id": 147,
    "code": "F05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "F",
    "id": 148,
    "code": "F11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "F",
    "id": 149,
    "code": "F12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "F",
    "id": 150,
    "code": "F19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "F",
    "id": 151,
    "code": "F20",
    "name": "Alcohol, Drug and Substance Abuse, Dependency Prevention and Treatment"
  },
  {
    "parentCode": "F",
    "id": 152,
    "code": "F21",
    "name": "Alcohol, Drug Abuse, Prevention Only"
  },
  {
    "parentCode": "F",
    "id": 153,
    "code": "F22",
    "name": "Alcohol, Drug Abuse, Treatment Only"
  },
  {
    "parentCode": "F",
    "id": 154,
    "code": "F30",
    "name": "Mental Health Treatment - Multipurpose and N.E.C."
  },
  {
    "parentCode": "F",
    "id": 155,
    "code": "F31",
    "name": "Psychiatric, Mental Health Hospital"
  },
  {
    "parentCode": "F",
    "id": 156,
    "code": "F32",
    "name": "Community Mental Health Center"
  },
  {
    "parentCode": "F",
    "id": 157,
    "code": "F33",
    "name": "Group Home, Residential Treatment Facility - Mental Health Related"
  },
  {
    "parentCode": "F",
    "id": 158,
    "code": "F40",
    "name": "Hot Line, Crisis Intervention Services"
  },
  {
    "parentCode": "F",
    "id": 159,
    "code": "F42",
    "name": "Rape Victim Services"
  },
  {
    "parentCode": "F",
    "id": 160,
    "code": "F50",
    "name": "Addictive Disorders N.E.C."
  },
  {
    "parentCode": "F",
    "id": 161,
    "code": "F52",
    "name": "Smoking Addiction"
  },
  {
    "parentCode": "F",
    "id": 162,
    "code": "F53",
    "name": "Eating Disorder, Addiction"
  },
  {
    "parentCode": "F",
    "id": 163,
    "code": "F54",
    "name": "Gambling Addiction"
  },
  {
    "parentCode": "F",
    "id": 164,
    "code": "F60",
    "name": "Counseling, Support Groups"
  },
  {
    "parentCode": "F",
    "id": 165,
    "code": "F70",
    "name": "Mental Health Disorders"
  },
  {
    "parentCode": "F",
    "id": 166,
    "code": "F80",
    "name": "Mental Health Association, Multipurpose"
  },
  {
    "parentCode": "F",
    "id": 167,
    "code": "F99",
    "name": "Mental Health, Crisis Intervention N.E.C."
  },
  {
    "parentCode": "4",
    "id": 168,
    "code": "G",
    "name": "Diseases, Disorders, Medical Disciplines"
  },
  {
    "parentCode": "G",
    "id": 169,
    "code": "G01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "G",
    "id": 170,
    "code": "G02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "G",
    "id": 171,
    "code": "G03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "G",
    "id": 172,
    "code": "G05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "G",
    "id": 173,
    "code": "G11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "G",
    "id": 174,
    "code": "G12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "G",
    "id": 175,
    "code": "G19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "G",
    "id": 176,
    "code": "G20",
    "name": "Birth Defects and Genetic Diseases"
  },
  {
    "parentCode": "G",
    "id": 177,
    "code": "G25",
    "name": "Down Syndrome"
  },
  {
    "parentCode": "G",
    "id": 178,
    "code": "G30",
    "name": "Cancer"
  },
  {
    "parentCode": "G",
    "id": 179,
    "code": "G40",
    "name": "Diseases of Specific Organs"
  },
  {
    "parentCode": "G",
    "id": 180,
    "code": "G41",
    "name": "Eye Diseases, Blindness and Vision Impairments"
  },
  {
    "parentCode": "G",
    "id": 181,
    "code": "G42",
    "name": "Ear and Throat Diseases"
  },
  {
    "parentCode": "G",
    "id": 182,
    "code": "G43",
    "name": "Heart and Circulatory System Diseases, Disorders"
  },
  {
    "parentCode": "G",
    "id": 183,
    "code": "G44",
    "name": "Kidney Disease"
  },
  {
    "parentCode": "G",
    "id": 184,
    "code": "G45",
    "name": "Lung Disease"
  },
  {
    "parentCode": "G",
    "id": 185,
    "code": "G48",
    "name": "Brain Disorders"
  },
  {
    "parentCode": "G",
    "id": 186,
    "code": "G50",
    "name": "Nerve, Muscle and Bone Diseases"
  },
  {
    "parentCode": "G",
    "id": 187,
    "code": "G51",
    "name": "Arthritis"
  },
  {
    "parentCode": "G",
    "id": 188,
    "code": "G54",
    "name": "Epilepsy"
  },
  {
    "parentCode": "G",
    "id": 189,
    "code": "G60",
    "name": "Allergy Related Diseases"
  },
  {
    "parentCode": "G",
    "id": 190,
    "code": "G61",
    "name": "Asthma"
  },
  {
    "parentCode": "G",
    "id": 191,
    "code": "G70",
    "name": "Digestive Diseases, Disorders"
  },
  {
    "parentCode": "G",
    "id": 192,
    "code": "G80",
    "name": "Specifically Named Diseases"
  },
  {
    "parentCode": "G",
    "id": 193,
    "code": "G81",
    "name": "AIDS"
  },
  {
    "parentCode": "G",
    "id": 194,
    "code": "G83",
    "name": "Alzheimer's Disease"
  },
  {
    "parentCode": "G",
    "id": 195,
    "code": "G84",
    "name": "Autism"
  },
  {
    "parentCode": "G",
    "id": 196,
    "code": "G90",
    "name": "Medical Disciplines"
  },
  {
    "parentCode": "G",
    "id": 197,
    "code": "G92",
    "name": "Biomedicine, Bioengineering "
  },
  {
    "parentCode": "G",
    "id": 198,
    "code": "G94",
    "name": "Geriatrics"
  },
  {
    "parentCode": "G",
    "id": 199,
    "code": "G96",
    "name": "Neurology, Neuroscience"
  },
  {
    "parentCode": "G",
    "id": 200,
    "code": "G98",
    "name": "Pediatrics"
  },
  {
    "parentCode": "G",
    "id": 201,
    "code": "G99",
    "name": "Diseases, Disorders, Medical Disciplines N.E.C."
  },
  {
    "parentCode": "G",
    "id": 202,
    "code": "G9B",
    "name": "Surgery"
  },
  {
    "parentCode": "4",
    "id": 203,
    "code": "H",
    "name": "Medical Research"
  },
  {
    "parentCode": "H",
    "id": 204,
    "code": "H01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "H",
    "id": 205,
    "code": "H02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "H",
    "id": 206,
    "code": "H03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "H",
    "id": 207,
    "code": "H05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "H",
    "id": 208,
    "code": "H11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "H",
    "id": 209,
    "code": "H12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "H",
    "id": 210,
    "code": "H19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "H",
    "id": 211,
    "code": "H20",
    "name": "Birth Defects, Genetic Diseases Research"
  },
  {
    "parentCode": "H",
    "id": 212,
    "code": "H25",
    "name": "Down Syndrome Research"
  },
  {
    "parentCode": "H",
    "id": 213,
    "code": "H30",
    "name": "Cancer Research"
  },
  {
    "parentCode": "H",
    "id": 214,
    "code": "H40",
    "name": "Specific Organ Research"
  },
  {
    "parentCode": "H",
    "id": 215,
    "code": "H41",
    "name": "Eye Research"
  },
  {
    "parentCode": "H",
    "id": 216,
    "code": "H42",
    "name": "Ear and Throat Research"
  },
  {
    "parentCode": "H",
    "id": 217,
    "code": "H43",
    "name": "Heart, Circulatory Research"
  },
  {
    "parentCode": "H",
    "id": 218,
    "code": "H44",
    "name": "Kidney Research"
  },
  {
    "parentCode": "H",
    "id": 219,
    "code": "H45",
    "name": "Lung Research"
  },
  {
    "parentCode": "H",
    "id": 220,
    "code": "H48",
    "name": "Brain Disorders Research"
  },
  {
    "parentCode": "H",
    "id": 221,
    "code": "H50",
    "name": "Nerve, Muscle, Bone Research"
  },
  {
    "parentCode": "H",
    "id": 222,
    "code": "H51",
    "name": "Arthritis Research"
  },
  {
    "parentCode": "H",
    "id": 223,
    "code": "H54",
    "name": "Epilepsy Research"
  },
  {
    "parentCode": "H",
    "id": 224,
    "code": "H60",
    "name": "Allergy Related Disease Research"
  },
  {
    "parentCode": "H",
    "id": 225,
    "code": "H61",
    "name": "Asthma Research"
  },
  {
    "parentCode": "H",
    "id": 226,
    "code": "H70",
    "name": "Digestive Disease, Disorder Research"
  },
  {
    "parentCode": "H",
    "id": 227,
    "code": "H80",
    "name": "Specifically Named Diseases Research"
  },
  {
    "parentCode": "H",
    "id": 228,
    "code": "H81",
    "name": "AIDS Research"
  },
  {
    "parentCode": "H",
    "id": 229,
    "code": "H83",
    "name": "Alzheimer's Disease Research"
  },
  {
    "parentCode": "H",
    "id": 230,
    "code": "H84",
    "name": "Autism Research"
  },
  {
    "parentCode": "H",
    "id": 231,
    "code": "H90",
    "name": "Medical Specialty Research"
  },
  {
    "parentCode": "H",
    "id": 232,
    "code": "H92",
    "name": "Biomedicine, Bioengineering Research"
  },
  {
    "parentCode": "H",
    "id": 233,
    "code": "H94",
    "name": "Geriatrics Research"
  },
  {
    "parentCode": "H",
    "id": 234,
    "code": "H96",
    "name": "Neurology, Neuroscience Research"
  },
  {
    "parentCode": "H",
    "id": 235,
    "code": "H98",
    "name": "Pediatrics Research"
  },
  {
    "parentCode": "H",
    "id": 236,
    "code": "H99",
    "name": "Medical Research N.E.C."
  },
  {
    "parentCode": "H",
    "id": 237,
    "code": "H9B",
    "name": "Surgery Research"
  },
  {
    "id": 238,
    "code": "9",
    "name": "Mutual/Membership Benefit",
    "defaultIconUrl": "https://npp-images-nonprofit-image-prod.imgix.net/defaultImages/default_npo_icon_cat8.gif"
  },
  {
    "parentCode": "5",
    "id": 239,
    "code": "I",
    "name": "Crime, Legal-Related"
  },
  {
    "parentCode": "I",
    "id": 240,
    "code": "I01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "I",
    "id": 241,
    "code": "I02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "I",
    "id": 242,
    "code": "I03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "I",
    "id": 243,
    "code": "I05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "I",
    "id": 244,
    "code": "I11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "I",
    "id": 245,
    "code": "I12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "I",
    "id": 246,
    "code": "I19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "I",
    "id": 247,
    "code": "I20",
    "name": "Crime Prevention N.E.C."
  },
  {
    "parentCode": "I",
    "id": 248,
    "code": "I21",
    "name": "Delinquency Prevention"
  },
  {
    "parentCode": "I",
    "id": 249,
    "code": "I23",
    "name": "Drunk Driving Related"
  },
  {
    "parentCode": "I",
    "id": 250,
    "code": "I30",
    "name": "Correctional Facilities N.E.C."
  },
  {
    "parentCode": "I",
    "id": 251,
    "code": "I31",
    "name": "Transitional Care, Half-Way House for Offenders, Ex-Offenders"
  },
  {
    "parentCode": "I",
    "id": 252,
    "code": "I40",
    "name": "Rehabilitation Services for Offenders"
  },
  {
    "parentCode": "I",
    "id": 253,
    "code": "I43",
    "name": "Services to Prisoners and Families - Multipurpose"
  },
  {
    "parentCode": "I",
    "id": 254,
    "code": "I44",
    "name": "Prison Alternatives"
  },
  {
    "parentCode": "I",
    "id": 255,
    "code": "I50",
    "name": "Administration of Justice, Courts"
  },
  {
    "parentCode": "I",
    "id": 256,
    "code": "I51",
    "name": "Dispute Resolution, Mediation Services"
  },
  {
    "parentCode": "I",
    "id": 257,
    "code": "I60",
    "name": "Law Enforcement Agencies (Police Departments)"
  },
  {
    "parentCode": "I",
    "id": 258,
    "code": "I70",
    "name": "Protection Against, Prevention of Neglect, Abuse, Exploitation"
  },
  {
    "parentCode": "I",
    "id": 259,
    "code": "I71",
    "name": "Spouse Abuse, Prevention of"
  },
  {
    "parentCode": "I",
    "id": 260,
    "code": "I72",
    "name": "Child Abuse, Prevention of"
  },
  {
    "parentCode": "I",
    "id": 261,
    "code": "I73",
    "name": "Sexual Abuse, Prevention of"
  },
  {
    "parentCode": "I",
    "id": 262,
    "code": "I80",
    "name": "Legal Services"
  },
  {
    "parentCode": "I",
    "id": 263,
    "code": "I83",
    "name": "Public Interest Law, Litigation"
  },
  {
    "parentCode": "I",
    "id": 264,
    "code": "I99",
    "name": "Crime, Legal Related N.E.C."
  },
  {
    "parentCode": "5",
    "id": 265,
    "code": "J",
    "name": "Employment, Job-Related"
  },
  {
    "parentCode": "J",
    "id": 266,
    "code": "J01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "J",
    "id": 267,
    "code": "J02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "J",
    "id": 268,
    "code": "J03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "J",
    "id": 269,
    "code": "J05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "J",
    "id": 270,
    "code": "J11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "J",
    "id": 271,
    "code": "J12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "J",
    "id": 272,
    "code": "J19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "J",
    "id": 273,
    "code": "J20",
    "name": "Employment Procurement Assistance, Job Training"
  },
  {
    "parentCode": "J",
    "id": 274,
    "code": "J21",
    "name": "Vocational Counseling, Guidance and Testing"
  },
  {
    "parentCode": "J",
    "id": 275,
    "code": "J22",
    "name": "Vocational Training"
  },
  {
    "parentCode": "J",
    "id": 276,
    "code": "J30",
    "name": "Vocational Rehabilitation"
  },
  {
    "parentCode": "J",
    "id": 277,
    "code": "J32",
    "name": "Goodwill Industries"
  },
  {
    "parentCode": "J",
    "id": 278,
    "code": "J33",
    "name": "Sheltered Remunerative Employment, Work Activity Center N.E.C."
  },
  {
    "parentCode": "J",
    "id": 279,
    "code": "J40",
    "name": "Labor Unions, Organizations"
  },
  {
    "parentCode": "J",
    "id": 280,
    "code": "J99",
    "name": "Employment, Job Related N.E.C."
  },
  {
    "parentCode": "5",
    "id": 281,
    "code": "K",
    "name": "Food, Agriculture and Nutrition "
  },
  {
    "parentCode": "K",
    "id": 282,
    "code": "K01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "K",
    "id": 283,
    "code": "K02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "K",
    "id": 284,
    "code": "K03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "K",
    "id": 285,
    "code": "K05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "K",
    "id": 286,
    "code": "K11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "K",
    "id": 287,
    "code": "K12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "K",
    "id": 288,
    "code": "K19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "K",
    "id": 289,
    "code": "K20",
    "name": "Agricultural Programs"
  },
  {
    "parentCode": "K",
    "id": 290,
    "code": "K25",
    "name": "Farmland Preservation"
  },
  {
    "parentCode": "K",
    "id": 291,
    "code": "K26",
    "name": "Livestock Breeding, Development, Management"
  },
  {
    "parentCode": "K",
    "id": 292,
    "code": "K28",
    "name": "Farm Bureau, Grange"
  },
  {
    "parentCode": "K",
    "id": 293,
    "code": "K30",
    "name": "Food Service, Free Food Distribution Programs"
  },
  {
    "parentCode": "K",
    "id": 294,
    "code": "K31",
    "name": "Food Banks, Food Pantries"
  },
  {
    "parentCode": "K",
    "id": 295,
    "code": "K34",
    "name": "Congregate Meals"
  },
  {
    "parentCode": "K",
    "id": 296,
    "code": "K35",
    "name": "Eatery, Agency, Organization Sponsored"
  },
  {
    "parentCode": "K",
    "id": 297,
    "code": "K36",
    "name": "Meals on Wheels"
  },
  {
    "parentCode": "K",
    "id": 298,
    "code": "K40",
    "name": "Nutrition Programs"
  },
  {
    "parentCode": "K",
    "id": 299,
    "code": "K50",
    "name": "Home Economics"
  },
  {
    "parentCode": "K",
    "id": 300,
    "code": "K99",
    "name": "Food, Agriculture, and Nutrition N.E.C."
  },
  {
    "parentCode": "5",
    "id": 301,
    "code": "L",
    "name": "Housing, Shelter"
  },
  {
    "parentCode": "L",
    "id": 302,
    "code": "L01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "L",
    "id": 303,
    "code": "L02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "L",
    "id": 304,
    "code": "L03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "L",
    "id": 305,
    "code": "L05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "L",
    "id": 306,
    "code": "L11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "L",
    "id": 307,
    "code": "L12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "L",
    "id": 308,
    "code": "L19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "L",
    "id": 309,
    "code": "L20",
    "name": "Housing Development, Construction, Management"
  },
  {
    "parentCode": "L",
    "id": 310,
    "code": "L21",
    "name": "Public Housing Facilities"
  },
  {
    "parentCode": "L",
    "id": 311,
    "code": "L22",
    "name": "Senior Citizens' Housing/Retirement Communities"
  },
  {
    "parentCode": "L",
    "id": 312,
    "code": "L25",
    "name": "Housing Rehabilitation"
  },
  {
    "parentCode": "L",
    "id": 313,
    "code": "L30",
    "name": "Housing Search Assistance"
  },
  {
    "parentCode": "L",
    "id": 314,
    "code": "L40",
    "name": "Low-Cost Temporary Housing"
  },
  {
    "parentCode": "L",
    "id": 315,
    "code": "L41",
    "name": "Homeless, Temporary Shelter For"
  },
  {
    "parentCode": "L",
    "id": 316,
    "code": "L50",
    "name": "Housing Owners, Renters Organizations"
  },
  {
    "parentCode": "L",
    "id": 317,
    "code": "L80",
    "name": "Housing Support Services -- Other"
  },
  {
    "parentCode": "L",
    "id": 318,
    "code": "L81",
    "name": "Home Improvement and Repairs"
  },
  {
    "parentCode": "L",
    "id": 319,
    "code": "L82",
    "name": "Housing Expense Reduction Support"
  },
  {
    "parentCode": "L",
    "id": 320,
    "code": "L99",
    "name": "Housing, Shelter N.E.C."
  },
  {
    "parentCode": "5",
    "id": 321,
    "code": "M",
    "name": "Public Safety, Disaster Preparedness and Relief"
  },
  {
    "parentCode": "M",
    "id": 322,
    "code": "M01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "M",
    "id": 323,
    "code": "M02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "M",
    "id": 324,
    "code": "M03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "M",
    "id": 325,
    "code": "M05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "M",
    "id": 326,
    "code": "M11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "M",
    "id": 327,
    "code": "M12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "M",
    "id": 328,
    "code": "M19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "M",
    "id": 329,
    "code": "M20",
    "name": "Disaster Preparedness and Relief Services"
  },
  {
    "parentCode": "M",
    "id": 330,
    "code": "M23",
    "name": "Search and Rescue Squads, Services"
  },
  {
    "parentCode": "M",
    "id": 331,
    "code": "M24",
    "name": "Fire Prevention, Protection, Control"
  },
  {
    "parentCode": "M",
    "id": 332,
    "code": "M40",
    "name": "Safety Education"
  },
  {
    "parentCode": "M",
    "id": 333,
    "code": "M41",
    "name": "First Aid Training, Services"
  },
  {
    "parentCode": "M",
    "id": 334,
    "code": "M42",
    "name": "Automotive Safety"
  },
  {
    "parentCode": "M",
    "id": 335,
    "code": "M99",
    "name": "Public Safety, Disaster Preparedness, and Relief N.E.C."
  },
  {
    "id": 336,
    "code": "5",
    "name": "Human Services",
    "defaultIconUrl": "https://npp-images-nonprofit-image-prod.imgix.net/defaultImages/default_npo_icon_cat5.gif"
  },
  {
    "id": 337,
    "code": "6",
    "name": "International, Foreign Affairs",
    "defaultIconUrl": "https://npp-images-nonprofit-image-prod.imgix.net/defaultImages/default_npo_icon_cat7.gif"
  },
  {
    "id": 338,
    "code": "7",
    "name": "Public, Societal Benefit",
    "defaultIconUrl": "https://npp-images-nonprofit-image-prod.imgix.net/defaultImages/default_npo_icon_cat6.gif"
  },
  {
    "id": 339,
    "code": "8",
    "name": "Religion Related",
    "defaultIconUrl": "https://npp-images-nonprofit-image-prod.imgix.net/defaultImages/default_npo_icon_cat9.gif"
  },
  {
    "id": 340,
    "code": "10",
    "name": "Unknown, Unclassified",
    "defaultIconUrl": "https://npp-images-nonprofit-image-prod.imgix.net/defaultImages/default_npo_icon_cat10.gif"
  },
  {
    "parentCode": "5",
    "id": 341,
    "code": "N",
    "name": "Recreation, Sports, Leisure, Athletics "
  },
  {
    "parentCode": "N",
    "id": 342,
    "code": "N01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "N",
    "id": 343,
    "code": "N02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "N",
    "id": 344,
    "code": "N03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "N",
    "id": 345,
    "code": "N05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "N",
    "id": 346,
    "code": "N11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "N",
    "id": 347,
    "code": "N12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "N",
    "id": 348,
    "code": "N19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "N",
    "id": 349,
    "code": "N20",
    "name": "Recreational and Sporting Camps"
  },
  {
    "parentCode": "N",
    "id": 350,
    "code": "N30",
    "name": "Physical Fitness and Community Recreational Facilities"
  },
  {
    "parentCode": "N",
    "id": 351,
    "code": "N31",
    "name": "Community Recreational Centers"
  },
  {
    "parentCode": "N",
    "id": 352,
    "code": "N32",
    "name": "Parks and Playgrounds"
  },
  {
    "parentCode": "N",
    "id": 353,
    "code": "N40",
    "name": "Sports Training Facilities, Agencies"
  },
  {
    "parentCode": "N",
    "id": 354,
    "code": "N50",
    "name": "Recreational, Pleasure, or Social Club"
  },
  {
    "parentCode": "N",
    "id": 355,
    "code": "N52",
    "name": "Fairs, County and Other"
  },
  {
    "parentCode": "N",
    "id": 356,
    "code": "N60",
    "name": "Amateur Sports Clubs, Leagues, N.E.C."
  },
  {
    "parentCode": "N",
    "id": 357,
    "code": "N61",
    "name": "Fishing, Hunting Clubs"
  },
  {
    "parentCode": "N",
    "id": 358,
    "code": "N62",
    "name": "Basketball"
  },
  {
    "parentCode": "N",
    "id": 359,
    "code": "N63",
    "name": "Baseball, Softball"
  },
  {
    "parentCode": "N",
    "id": 360,
    "code": "N64",
    "name": "Soccer Clubs, Leagues"
  },
  {
    "parentCode": "N",
    "id": 361,
    "code": "N65",
    "name": "Football Clubs, Leagues"
  },
  {
    "parentCode": "N",
    "id": 362,
    "code": "N66",
    "name": "Tennis, Racquet Sports Clubs, Leagues"
  },
  {
    "parentCode": "N",
    "id": 363,
    "code": "N67",
    "name": "Swimming, Water Recreation"
  },
  {
    "parentCode": "N",
    "id": 364,
    "code": "N68",
    "name": "Winter Sports (Snow and Ice)"
  },
  {
    "parentCode": "N",
    "id": 365,
    "code": "N69",
    "name": "Equestrian, Riding"
  },
  {
    "parentCode": "N",
    "id": 366,
    "code": "N6A",
    "name": "Golf"
  },
  {
    "parentCode": "N",
    "id": 367,
    "code": "N70",
    "name": "Amateur Sports Competitions"
  },
  {
    "parentCode": "N",
    "id": 368,
    "code": "N71",
    "name": "Olympics Committees and Related International Competitions"
  },
  {
    "parentCode": "N",
    "id": 369,
    "code": "N72",
    "name": "Special Olympics"
  },
  {
    "parentCode": "N",
    "id": 370,
    "code": "N80",
    "name": "Professional Athletic Leagues"
  },
  {
    "parentCode": "N",
    "id": 371,
    "code": "N99",
    "name": "Recreation, Sports, Leisure, Athletics N.E.C."
  },
  {
    "parentCode": "5",
    "id": 372,
    "code": "O",
    "name": "Youth Development "
  },
  {
    "parentCode": "O",
    "id": 373,
    "code": "O01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "O",
    "id": 374,
    "code": "O02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "O",
    "id": 375,
    "code": "O03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "O",
    "id": 376,
    "code": "O05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "O",
    "id": 377,
    "code": "O11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "O",
    "id": 378,
    "code": "O12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "O",
    "id": 379,
    "code": "O19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "O",
    "id": 380,
    "code": "O20",
    "name": "Youth Centers, Clubs, Multipurpose"
  },
  {
    "parentCode": "O",
    "id": 381,
    "code": "O21",
    "name": "Boys Clubs"
  },
  {
    "parentCode": "O",
    "id": 382,
    "code": "O22",
    "name": "Girls Clubs"
  },
  {
    "parentCode": "O",
    "id": 383,
    "code": "O23",
    "name": "Boys and Girls Clubs (Combined)"
  },
  {
    "parentCode": "O",
    "id": 384,
    "code": "O30",
    "name": "Adult, Child Matching Programs"
  },
  {
    "parentCode": "O",
    "id": 385,
    "code": "O31",
    "name": "Big Brothers, Big Sisters"
  },
  {
    "parentCode": "O",
    "id": 386,
    "code": "O40",
    "name": "Scouting Organizations"
  },
  {
    "parentCode": "O",
    "id": 387,
    "code": "O41",
    "name": "Boy Scouts of America"
  },
  {
    "parentCode": "O",
    "id": 388,
    "code": "O42",
    "name": "Girl Scouts of the U.S.A."
  },
  {
    "parentCode": "O",
    "id": 389,
    "code": "O43",
    "name": "Camp Fire"
  },
  {
    "parentCode": "O",
    "id": 390,
    "code": "O50",
    "name": "Youth Development Programs, Other"
  },
  {
    "parentCode": "O",
    "id": 391,
    "code": "O51",
    "name": "Youth Community Service Clubs"
  },
  {
    "parentCode": "O",
    "id": 392,
    "code": "O52",
    "name": "Youth Development - Agricultural"
  },
  {
    "parentCode": "O",
    "id": 393,
    "code": "O53",
    "name": "Youth Development - Business"
  },
  {
    "parentCode": "O",
    "id": 394,
    "code": "O54",
    "name": "Youth Development - Citizenship Programs"
  },
  {
    "parentCode": "O",
    "id": 395,
    "code": "O55",
    "name": "Youth Development - Religious Leadership"
  },
  {
    "parentCode": "O",
    "id": 396,
    "code": "O99",
    "name": "Youth Development N.E.C."
  },
  {
    "parentCode": "5",
    "id": 397,
    "code": "P",
    "name": "Human Services - Multipurpose and Other "
  },
  {
    "parentCode": "P",
    "id": 398,
    "code": "P01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "P",
    "id": 399,
    "code": "P02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "P",
    "id": 400,
    "code": "P03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "P",
    "id": 401,
    "code": "P05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "P",
    "id": 402,
    "code": "P11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "P",
    "id": 403,
    "code": "P12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "P",
    "id": 404,
    "code": "P19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "P",
    "id": 405,
    "code": "P20",
    "name": "Human Service Organizations - Multipurpose"
  },
  {
    "parentCode": "P",
    "id": 406,
    "code": "P21",
    "name": "American Red Cross"
  },
  {
    "parentCode": "P",
    "id": 407,
    "code": "P22",
    "name": "Urban League"
  },
  {
    "parentCode": "P",
    "id": 408,
    "code": "P24",
    "name": "Salvation Army"
  },
  {
    "parentCode": "P",
    "id": 409,
    "code": "P26",
    "name": "Volunteers of America"
  },
  {
    "parentCode": "P",
    "id": 410,
    "code": "P27",
    "name": "Young Men's or Women's Associations (YMCA, YWCA, YWHA, YMHA)"
  },
  {
    "parentCode": "P",
    "id": 411,
    "code": "P28",
    "name": "Neighborhood Centers, Settlement Houses"
  },
  {
    "parentCode": "P",
    "id": 412,
    "code": "P29",
    "name": "Thrift Shops"
  },
  {
    "parentCode": "P",
    "id": 413,
    "code": "P30",
    "name": "Children's, Youth Services"
  },
  {
    "parentCode": "P",
    "id": 414,
    "code": "P31",
    "name": "Adoption"
  },
  {
    "parentCode": "P",
    "id": 415,
    "code": "P32",
    "name": "Foster Care"
  },
  {
    "parentCode": "P",
    "id": 416,
    "code": "P33",
    "name": "Child Day Care"
  },
  {
    "parentCode": "P",
    "id": 417,
    "code": "P40",
    "name": "Family Services"
  },
  {
    "parentCode": "P",
    "id": 418,
    "code": "P42",
    "name": "Single Parent Agencies, Services"
  },
  {
    "parentCode": "P",
    "id": 419,
    "code": "P43",
    "name": "Family Violence Shelters, Services"
  },
  {
    "parentCode": "P",
    "id": 420,
    "code": "P44",
    "name": "Homemaker, Home Health Aide"
  },
  {
    "parentCode": "P",
    "id": 421,
    "code": "P45",
    "name": "Family Services, Adolescent Parents"
  },
  {
    "parentCode": "P",
    "id": 422,
    "code": "P46",
    "name": "Family Counseling"
  },
  {
    "parentCode": "P",
    "id": 423,
    "code": "P50",
    "name": "Personal Social Services"
  },
  {
    "parentCode": "P",
    "id": 424,
    "code": "P51",
    "name": "Financial Counseling, Money Management"
  },
  {
    "parentCode": "P",
    "id": 425,
    "code": "P52",
    "name": "Transportation, Free or Subsidized"
  },
  {
    "parentCode": "P",
    "id": 426,
    "code": "P58",
    "name": "Gift Distribution"
  },
  {
    "parentCode": "P",
    "id": 427,
    "code": "P60",
    "name": "Emergency Assistance (Food, Clothing, Cash)"
  },
  {
    "parentCode": "P",
    "id": 428,
    "code": "P61",
    "name": "Travelers' Aid"
  },
  {
    "parentCode": "P",
    "id": 429,
    "code": "P62",
    "name": "Victims' Services"
  },
  {
    "parentCode": "P",
    "id": 430,
    "code": "P70",
    "name": "Residential, Custodial Care"
  },
  {
    "parentCode": "P",
    "id": 431,
    "code": "P72",
    "name": "Half-Way House (Short-Term Residential Care)"
  },
  {
    "parentCode": "P",
    "id": 432,
    "code": "P73",
    "name": "Group Home (Long Term)"
  },
  {
    "parentCode": "P",
    "id": 433,
    "code": "P74",
    "name": "Hospice"
  },
  {
    "parentCode": "P",
    "id": 434,
    "code": "P75",
    "name": "Senior Continuing Care Communities"
  },
  {
    "parentCode": "P",
    "id": 435,
    "code": "P80",
    "name": "Services to Promote the Independence of Specific Populations"
  },
  {
    "parentCode": "P",
    "id": 436,
    "code": "P81",
    "name": "Senior Centers, Services"
  },
  {
    "parentCode": "P",
    "id": 437,
    "code": "P82",
    "name": "Developmentally Disabled Centers, Services"
  },
  {
    "parentCode": "P",
    "id": 438,
    "code": "P84",
    "name": "Ethnic, Immigrant Centers, Services"
  },
  {
    "parentCode": "P",
    "id": 439,
    "code": "P85",
    "name": "Homeless Persons Centers, Services"
  },
  {
    "parentCode": "P",
    "id": 440,
    "code": "P86",
    "name": "Blind/Visually Impaired Centers, Services "
  },
  {
    "parentCode": "P",
    "id": 441,
    "code": "P87",
    "name": "Deaf/Hearing Impaired Centers, Services "
  },
  {
    "parentCode": "P",
    "id": 442,
    "code": "P99",
    "name": "Human Services - Multipurpose and Other N.E.C."
  },
  {
    "parentCode": "6",
    "id": 443,
    "code": "Q",
    "name": "International, Foreign Affairs and National Security "
  },
  {
    "parentCode": "Q",
    "id": 444,
    "code": "Q01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "Q",
    "id": 445,
    "code": "Q02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "Q",
    "id": 446,
    "code": "Q03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "Q",
    "id": 447,
    "code": "Q05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "Q",
    "id": 448,
    "code": "Q11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "Q",
    "id": 449,
    "code": "Q12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "Q",
    "id": 450,
    "code": "Q19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "Q",
    "id": 451,
    "code": "Q20",
    "name": "Promotion of International Understanding"
  },
  {
    "parentCode": "Q",
    "id": 452,
    "code": "Q21",
    "name": "International Cultural Exchange"
  },
  {
    "parentCode": "Q",
    "id": 453,
    "code": "Q22",
    "name": "International Student Exchange and Aid"
  },
  {
    "parentCode": "Q",
    "id": 454,
    "code": "Q23",
    "name": "International Exchanges, N.E.C."
  },
  {
    "parentCode": "Q",
    "id": 455,
    "code": "Q30",
    "name": "International Development, Relief Services"
  },
  {
    "parentCode": "Q",
    "id": 456,
    "code": "Q31",
    "name": "International Agricultural Development"
  },
  {
    "parentCode": "Q",
    "id": 457,
    "code": "Q32",
    "name": "International Economic Development"
  },
  {
    "parentCode": "Q",
    "id": 458,
    "code": "Q33",
    "name": "International Relief"
  },
  {
    "parentCode": "Q",
    "id": 459,
    "code": "Q40",
    "name": "International Peace and Security"
  },
  {
    "parentCode": "Q",
    "id": 460,
    "code": "Q41",
    "name": "Arms Control, Peace Organizations"
  },
  {
    "parentCode": "Q",
    "id": 461,
    "code": "Q42",
    "name": "United Nations Association"
  },
  {
    "parentCode": "Q",
    "id": 462,
    "code": "Q43",
    "name": "National Security, Domestic"
  },
  {
    "parentCode": "Q",
    "id": 463,
    "code": "Q70",
    "name": "International Human Rights"
  },
  {
    "parentCode": "Q",
    "id": 464,
    "code": "Q71",
    "name": "International Migration, Refugee Issues"
  },
  {
    "parentCode": "Q",
    "id": 465,
    "code": "Q99",
    "name": "International, Foreign Affairs, and National Security N.E.C."
  },
  {
    "parentCode": "7",
    "id": 466,
    "code": "R",
    "name": "Civil Rights, Social Action, Advocacy "
  },
  {
    "parentCode": "R",
    "id": 467,
    "code": "R01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "R",
    "id": 468,
    "code": "R02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "R",
    "id": 469,
    "code": "R03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "R",
    "id": 470,
    "code": "R05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "R",
    "id": 471,
    "code": "R11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "R",
    "id": 472,
    "code": "R12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "R",
    "id": 473,
    "code": "R19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "R",
    "id": 474,
    "code": "R20",
    "name": "Civil Rights, Advocacy for Specific Groups"
  },
  {
    "parentCode": "R",
    "id": 475,
    "code": "R22",
    "name": "Minority Rights"
  },
  {
    "parentCode": "R",
    "id": 476,
    "code": "R23",
    "name": "Disabled Persons' Rights"
  },
  {
    "parentCode": "R",
    "id": 477,
    "code": "R24",
    "name": "Women's Rights"
  },
  {
    "parentCode": "R",
    "id": 478,
    "code": "R25",
    "name": "Seniors' Rights"
  },
  {
    "parentCode": "R",
    "id": 479,
    "code": "R26",
    "name": "Lesbian, Gay Rights"
  },
  {
    "parentCode": "R",
    "id": 480,
    "code": "R30",
    "name": "Intergroup, Race Relations"
  },
  {
    "parentCode": "R",
    "id": 481,
    "code": "R40",
    "name": "Voter Education, Registration"
  },
  {
    "parentCode": "R",
    "id": 482,
    "code": "R60",
    "name": "Civil Liberties Advocacy"
  },
  {
    "parentCode": "R",
    "id": 483,
    "code": "R61",
    "name": "Reproductive Rights"
  },
  {
    "parentCode": "R",
    "id": 484,
    "code": "R62",
    "name": "Right to Life"
  },
  {
    "parentCode": "R",
    "id": 485,
    "code": "R63",
    "name": "Censorship, Freedom of Speech and Press Issues"
  },
  {
    "parentCode": "R",
    "id": 486,
    "code": "R67",
    "name": "Right to Die, Euthanasia Issues"
  },
  {
    "parentCode": "R",
    "id": 487,
    "code": "R99",
    "name": "Civil Rights, Social Action, Advocacy N.E.C."
  },
  {
    "parentCode": "7",
    "id": 488,
    "code": "S",
    "name": "Community Improvement, Capacity Building "
  },
  {
    "parentCode": "S",
    "id": 489,
    "code": "S01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "S",
    "id": 490,
    "code": "S02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "S",
    "id": 491,
    "code": "S03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "S",
    "id": 492,
    "code": "S05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "S",
    "id": 493,
    "code": "S11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "S",
    "id": 494,
    "code": "S12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "S",
    "id": 495,
    "code": "S19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "S",
    "id": 496,
    "code": "S20",
    "name": "Community, Neighborhood Development, Improvement (General)"
  },
  {
    "parentCode": "S",
    "id": 497,
    "code": "S21",
    "name": "Community Coalitions"
  },
  {
    "parentCode": "S",
    "id": 498,
    "code": "S22",
    "name": "Neighborhood, Block Associations"
  },
  {
    "parentCode": "S",
    "id": 499,
    "code": "S30",
    "name": "Economic Development"
  },
  {
    "parentCode": "S",
    "id": 500,
    "code": "S31",
    "name": "Urban, Community Economic Development"
  },
  {
    "parentCode": "S",
    "id": 501,
    "code": "S32",
    "name": "Rural Development"
  },
  {
    "parentCode": "S",
    "id": 502,
    "code": "S40",
    "name": "Business and Industry"
  },
  {
    "parentCode": "S",
    "id": 503,
    "code": "S41",
    "name": "Promotion of Business"
  },
  {
    "parentCode": "S",
    "id": 504,
    "code": "S43",
    "name": "Management Services for Small Business, Entrepreneurs"
  },
  {
    "parentCode": "S",
    "id": 505,
    "code": "S46",
    "name": "Boards of Trade"
  },
  {
    "parentCode": "S",
    "id": 506,
    "code": "S47",
    "name": "Real Estate Organizations"
  },
  {
    "parentCode": "S",
    "id": 507,
    "code": "S50",
    "name": "Nonprofit Management"
  },
  {
    "parentCode": "S",
    "id": 508,
    "code": "S80",
    "name": "Community Service Clubs"
  },
  {
    "parentCode": "S",
    "id": 509,
    "code": "S81",
    "name": "Women's Service Clubs"
  },
  {
    "parentCode": "S",
    "id": 510,
    "code": "S82",
    "name": "Men's Service Clubs"
  },
  {
    "parentCode": "S",
    "id": 511,
    "code": "S99",
    "name": "Community Improvement, Capacity Building N.E.C."
  },
  {
    "parentCode": "7",
    "id": 512,
    "code": "T",
    "name": "Philanthropy, Voluntarism and Grantmaking Foundations "
  },
  {
    "parentCode": "T",
    "id": 513,
    "code": "T01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "T",
    "id": 514,
    "code": "T02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "T",
    "id": 515,
    "code": "T03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "T",
    "id": 516,
    "code": "T05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "T",
    "id": 517,
    "code": "T11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "T",
    "id": 518,
    "code": "T12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "T",
    "id": 519,
    "code": "T19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "T",
    "id": 520,
    "code": "T20",
    "name": "Private Grantmaking Foundations"
  },
  {
    "parentCode": "T",
    "id": 521,
    "code": "T21",
    "name": "Corporate Foundations"
  },
  {
    "parentCode": "T",
    "id": 522,
    "code": "T22",
    "name": "Private Independent Foundations"
  },
  {
    "parentCode": "T",
    "id": 523,
    "code": "T23",
    "name": "Private Operating Foundations"
  },
  {
    "parentCode": "T",
    "id": 524,
    "code": "T30",
    "name": "Public Foundations"
  },
  {
    "parentCode": "T",
    "id": 525,
    "code": "T31",
    "name": "Community Foundations"
  },
  {
    "parentCode": "T",
    "id": 526,
    "code": "T40",
    "name": "Voluntarism Promotion"
  },
  {
    "parentCode": "T",
    "id": 527,
    "code": "T50",
    "name": "Philanthropy, Charity, Voluntarism Promotion, General"
  },
  {
    "parentCode": "T",
    "id": 528,
    "code": "T70",
    "name": "Fund Raising Organizations That Cross Categories"
  },
  {
    "parentCode": "T",
    "id": 529,
    "code": "T90",
    "name": "Named Trusts/Foundations N.E.C. "
  },
  {
    "parentCode": "T",
    "id": 530,
    "code": "T99",
    "name": "Philanthropy, Voluntarism, and Grantmaking Foundations N.E.C."
  },
  {
    "parentCode": "7",
    "id": 531,
    "code": "U",
    "name": "Science and Technology Research Institutes, Services "
  },
  {
    "parentCode": "U",
    "id": 532,
    "code": "U01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "U",
    "id": 533,
    "code": "U02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "U",
    "id": 534,
    "code": "U03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "U",
    "id": 535,
    "code": "U05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "U",
    "id": 536,
    "code": "U11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "U",
    "id": 537,
    "code": "U12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "U",
    "id": 538,
    "code": "U19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "U",
    "id": 539,
    "code": "U20",
    "name": "Science, General"
  },
  {
    "parentCode": "U",
    "id": 540,
    "code": "U21",
    "name": "Marine Science and Oceanography"
  },
  {
    "parentCode": "U",
    "id": 541,
    "code": "U30",
    "name": "Physical Sciences, Earth Sciences Research and Promotion"
  },
  {
    "parentCode": "U",
    "id": 542,
    "code": "U31",
    "name": "Astronomy"
  },
  {
    "parentCode": "U",
    "id": 543,
    "code": "U33",
    "name": "Chemistry, Chemical Engineering"
  },
  {
    "parentCode": "U",
    "id": 544,
    "code": "U34",
    "name": "Mathematics"
  },
  {
    "parentCode": "U",
    "id": 545,
    "code": "U36",
    "name": "Geology"
  },
  {
    "parentCode": "U",
    "id": 546,
    "code": "U40",
    "name": "Engineering and Technology Research, Services"
  },
  {
    "parentCode": "U",
    "id": 547,
    "code": "U41",
    "name": "Computer Science"
  },
  {
    "parentCode": "U",
    "id": 548,
    "code": "U42",
    "name": "Engineering"
  },
  {
    "parentCode": "U",
    "id": 549,
    "code": "U50",
    "name": "Biological, Life Science Research"
  },
  {
    "parentCode": "U",
    "id": 550,
    "code": "U99",
    "name": "Science and Technology Research Institutes, Services N.E.C."
  },
  {
    "parentCode": "7",
    "id": 551,
    "code": "V",
    "name": "Social Science Research Institutes, Services"
  },
  {
    "parentCode": "V",
    "id": 552,
    "code": "V01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "V",
    "id": 553,
    "code": "V02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "V",
    "id": 554,
    "code": "V03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "V",
    "id": 555,
    "code": "V05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "V",
    "id": 556,
    "code": "V11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "V",
    "id": 557,
    "code": "V12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "V",
    "id": 558,
    "code": "V19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "V",
    "id": 559,
    "code": "V20",
    "name": "Social Science Institutes, Services"
  },
  {
    "parentCode": "V",
    "id": 560,
    "code": "V21",
    "name": "Anthropology, Sociology"
  },
  {
    "parentCode": "V",
    "id": 561,
    "code": "V22",
    "name": "Economics (as a social science)"
  },
  {
    "parentCode": "V",
    "id": 562,
    "code": "V23",
    "name": "Behavioral Science"
  },
  {
    "parentCode": "V",
    "id": 563,
    "code": "V24",
    "name": "Political Science"
  },
  {
    "parentCode": "V",
    "id": 564,
    "code": "V25",
    "name": "Population Studies"
  },
  {
    "parentCode": "V",
    "id": 565,
    "code": "V26",
    "name": "Law, International Law, Jurisprudence"
  },
  {
    "parentCode": "V",
    "id": 566,
    "code": "V30",
    "name": "Interdisciplinary Research"
  },
  {
    "parentCode": "V",
    "id": 567,
    "code": "V31",
    "name": "Black Studies"
  },
  {
    "parentCode": "V",
    "id": 568,
    "code": "V32",
    "name": "Women's Studies"
  },
  {
    "parentCode": "V",
    "id": 569,
    "code": "V33",
    "name": "Ethnic Studies"
  },
  {
    "parentCode": "V",
    "id": 570,
    "code": "V34",
    "name": "Urban Studies"
  },
  {
    "parentCode": "V",
    "id": 571,
    "code": "V35",
    "name": "International Studies"
  },
  {
    "parentCode": "V",
    "id": 572,
    "code": "V36",
    "name": "Gerontology (as a social science)"
  },
  {
    "parentCode": "V",
    "id": 573,
    "code": "V37",
    "name": "Labor Studies"
  },
  {
    "parentCode": "V",
    "id": 574,
    "code": "V99",
    "name": "Social Science Research Institutes, Services N.E.C."
  },
  {
    "parentCode": "7",
    "id": 575,
    "code": "W",
    "name": "Public, Society Benefit - Multipurpose and Other"
  },
  {
    "parentCode": "W",
    "id": 576,
    "code": "W01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "W",
    "id": 577,
    "code": "W02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "W",
    "id": 578,
    "code": "W03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "W",
    "id": 579,
    "code": "W05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "W",
    "id": 580,
    "code": "W11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "W",
    "id": 581,
    "code": "W12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "W",
    "id": 582,
    "code": "W19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "W",
    "id": 583,
    "code": "W20",
    "name": "Government and Public Administration"
  },
  {
    "parentCode": "W",
    "id": 584,
    "code": "W22",
    "name": "Public Finance, Taxation, Monetary Policy"
  },
  {
    "parentCode": "W",
    "id": 585,
    "code": "W24",
    "name": "Citizen Participation"
  },
  {
    "parentCode": "W",
    "id": 586,
    "code": "W30",
    "name": "Military, Veterans' Organizations"
  },
  {
    "parentCode": "W",
    "id": 587,
    "code": "W40",
    "name": "Public Transportation Systems, Services"
  },
  {
    "parentCode": "W",
    "id": 588,
    "code": "W50",
    "name": "Telephone, Telegraph and Telecommunication Services"
  },
  {
    "parentCode": "W",
    "id": 589,
    "code": "W60",
    "name": "Financial Institutions, Services (Non-Government Related)"
  },
  {
    "parentCode": "W",
    "id": 590,
    "code": "W61",
    "name": "Credit Unions"
  },
  {
    "parentCode": "W",
    "id": 591,
    "code": "W70",
    "name": "Leadership Development"
  },
  {
    "parentCode": "W",
    "id": 592,
    "code": "W80",
    "name": "Public Utilities"
  },
  {
    "parentCode": "W",
    "id": 593,
    "code": "W90",
    "name": "Consumer Protection, Safety"
  },
  {
    "parentCode": "W",
    "id": 594,
    "code": "W99",
    "name": "Public, Society Benefit - Multipurpose and Other N.E.C."
  },
  {
    "parentCode": "8",
    "id": 595,
    "code": "X",
    "name": "Religion-Related, Spiritual Development "
  },
  {
    "parentCode": "X",
    "id": 596,
    "code": "X01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "X",
    "id": 597,
    "code": "X02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "X",
    "id": 598,
    "code": "X03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "X",
    "id": 599,
    "code": "X05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "X",
    "id": 600,
    "code": "X11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "X",
    "id": 601,
    "code": "X12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "X",
    "id": 602,
    "code": "X19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "X",
    "id": 603,
    "code": "X20",
    "name": "Christian"
  },
  {
    "parentCode": "X",
    "id": 604,
    "code": "X21",
    "name": "Protestant"
  },
  {
    "parentCode": "X",
    "id": 605,
    "code": "X22",
    "name": "Roman Catholic"
  },
  {
    "parentCode": "X",
    "id": 606,
    "code": "X30",
    "name": "Jewish"
  },
  {
    "parentCode": "X",
    "id": 607,
    "code": "X40",
    "name": "Islamic"
  },
  {
    "parentCode": "X",
    "id": 608,
    "code": "X50",
    "name": "Buddhist"
  },
  {
    "parentCode": "X",
    "id": 609,
    "code": "X70",
    "name": "Hindu"
  },
  {
    "parentCode": "X",
    "id": 610,
    "code": "X80",
    "name": "Religious Media, Communications Organizations "
  },
  {
    "parentCode": "X",
    "id": 611,
    "code": "X81",
    "name": "Religious Film, Video"
  },
  {
    "parentCode": "X",
    "id": 612,
    "code": "X82",
    "name": "Religious Television"
  },
  {
    "parentCode": "X",
    "id": 613,
    "code": "X83",
    "name": "Religious Printing, Publishing"
  },
  {
    "parentCode": "X",
    "id": 614,
    "code": "X84",
    "name": "Religious Radio "
  },
  {
    "parentCode": "X",
    "id": 615,
    "code": "X90",
    "name": "Interfaith Issues"
  },
  {
    "parentCode": "X",
    "id": 616,
    "code": "X99",
    "name": "Religion Related, Spiritual Development N.E.C."
  },
  {
    "parentCode": "9",
    "id": 617,
    "code": "Y",
    "name": "Mutual/Membership Benefit Organizations, Other "
  },
  {
    "parentCode": "Y",
    "id": 618,
    "code": "Y01",
    "name": "Alliance/Advocacy Organizations "
  },
  {
    "parentCode": "Y",
    "id": 619,
    "code": "Y02",
    "name": "Management & Technical Assistance "
  },
  {
    "parentCode": "Y",
    "id": 620,
    "code": "Y03",
    "name": "Professional Societies, Associations "
  },
  {
    "parentCode": "Y",
    "id": 621,
    "code": "Y05",
    "name": "Research Institutes and/or Public Policy Analysis "
  },
  {
    "parentCode": "Y",
    "id": 622,
    "code": "Y11",
    "name": "Single Organization Support "
  },
  {
    "parentCode": "Y",
    "id": 623,
    "code": "Y12",
    "name": "Fund Raising and/or Fund Distribution "
  },
  {
    "parentCode": "Y",
    "id": 624,
    "code": "Y19",
    "name": "Nonmonetary Support N.E.C."
  },
  {
    "parentCode": "Y",
    "id": 625,
    "code": "Y20",
    "name": "Insurance Providers, Services"
  },
  {
    "parentCode": "Y",
    "id": 626,
    "code": "Y22",
    "name": "Local Benevolent Life Insurance Associations, Mutual Irrigation and Telephone Companies, and Like Or"
  },
  {
    "parentCode": "Y",
    "id": 627,
    "code": "Y23",
    "name": "Mutual Insurance Company or Association"
  },
  {
    "parentCode": "Y",
    "id": 628,
    "code": "Y24",
    "name": "Supplemental Unemployment Compensation"
  },
  {
    "parentCode": "Y",
    "id": 629,
    "code": "Y25",
    "name": "State-Sponsored Worker's Compensation Reinsurance Organizations"
  },
  {
    "parentCode": "Y",
    "id": 630,
    "code": "Y30",
    "name": "Pension and Retirement Funds"
  },
  {
    "parentCode": "Y",
    "id": 631,
    "code": "Y33",
    "name": "Teachers Retirement Fund Association"
  },
  {
    "parentCode": "Y",
    "id": 632,
    "code": "Y34",
    "name": "Employee Funded Pension Trust"
  },
  {
    "parentCode": "Y",
    "id": 633,
    "code": "Y35",
    "name": "Multi-Employer Pension Plans"
  },
  {
    "parentCode": "Y",
    "id": 634,
    "code": "Y40",
    "name": "Fraternal Beneficiary Societies"
  },
  {
    "parentCode": "Y",
    "id": 635,
    "code": "Y42",
    "name": "Domestic Fraternal Societies"
  },
  {
    "parentCode": "Y",
    "id": 636,
    "code": "Y43",
    "name": "Voluntary Employees Beneficiary Associations (Non-Government)"
  },
  {
    "parentCode": "Y",
    "id": 637,
    "code": "Y44",
    "name": "Voluntary Employees Beneficiary Associations (Government) "
  },
  {
    "parentCode": "Y",
    "id": 638,
    "code": "Y50",
    "name": "Cemeteries, Burial Services"
  },
  {
    "parentCode": "Y",
    "id": 639,
    "code": "Y99",
    "name": "Mutual/Membership Benefit Organizations, Other N.E.C."
  },
  {
    "parentCode": "10",
    "id": 640,
    "code": "Z",
    "name": "Unknown"
  },
  {
    "parentCode": "Z",
    "id": 641,
    "code": "Z99",
    "name": "Unknown"
  },
  {
    "parentCode": "B",
    "id": 642,
    "code": "B29",
    "name": "Charter Schools"
  },
  {
    "parentCode": "P",
    "id": 643,
    "code": "P71",
    "name": "Adult Day Care"
  },
  {
    "parentCode": "A",
    "id": 644,
    "code": "A82",
    "name": "Historical Societies & Historic Preservation"
  },
  {
    "parentCode": "R",
    "id": 645,
    "code": "R65",
    "name": "Freedom of Religion Issues"
  },
  {
    "parentCode": "L",
    "id": 646,
    "code": "L24",
    "name": "Independent Housing for People with Disabilities"
  },
  {
    "parentCode": "R",
    "id": 647,
    "code": "R21",
    "name": "Immigrants Rights"
  },
  {
    "parentCode": "Q",
    "id": 648,
    "code": "Q51",
    "name": "International Economic & Trade Policy"
  },
  {
    "parentCode": "R",
    "id": 649,
    "code": "R27",
    "name": "Patients Rights"
  },
  {
    "parentCode": "G",
    "id": 650,
    "code": "G32",
    "name": "Breast Cancer"
  },
  {
    "parentCode": "P",
    "id": 651,
    "code": "P88",
    "name": "LGBT Centers"
  },
  {
    "parentCode": "Q",
    "id": 652,
    "code": "Q36",
    "name": "International Science & Technology Development "
  },
  {
    "parentCode": "R",
    "id": 653,
    "code": "R29",
    "name": "Employee and Workers Rights"
  },
  {
    "parentCode": "P",
    "id": 654,
    "code": "P83",
    "name": "Womens Centers"
  },
  {
    "parentCode": "A",
    "id": 655,
    "code": "A27",
    "name": "Community Celebrations"
  },
  {
    "parentCode": "P",
    "id": 656,
    "code": "P47",
    "name": "Pregnancy Centers"
  },
  {
    "parentCode": "M",
    "id": 657,
    "code": "M60",
    "name": "Public Safety Benevolent Associations"
  },
  {
    "parentCode": "P",
    "id": 658,
    "code": "P76",
    "name": "Homes for Children & Adolescents"
  },
  {
    "parentCode": "Q",
    "id": 659,
    "code": "Q34",
    "name": "International Educational Development "
  },
  {
    "parentCode": "Y",
    "id": 660,
    "code": "Y41",
    "name": "Fraternal Beneficiary Societies"
  },
  {
    "parentCode": "Q",
    "id": 661,
    "code": "Q38",
    "name": "International Environment, Population & Sustainability"
  },
  {
    "parentCode": "A",
    "id": 662,
    "code": "A24",
    "name": "Folk Arts"
  },
  {
    "parentCode": "H",
    "id": 663,
    "code": "H32",
    "name": "Breast Cancer Research"
  },
  {
    "parentCode": "Q",
    "id": 664,
    "code": "Q50",
    "name": "International Affairs, Foreign Policy, & Globalization"
  },
  {
    "parentCode": "R",
    "id": 665,
    "code": "R28",
    "name": "Childrens Rights"
  },
  {
    "parentCode": "Q",
    "id": 666,
    "code": "Q39",
    "name": "International Health Development"
  },
  {
    "parentCode": "Q",
    "id": 667,
    "code": "Q35",
    "name": "International Democracy & Civil Society Development"
  }
]