<div *ngIf="!!audience"
  class="d-flex m-3 flex-column mb-3">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <!-- Name -->
      <div class="flyout-header">
        {{ audience.name }}
      </div>
      <div>
        {{ audience.description }}
      </div>
    </div>
    <!-- Edit -->
    <yc-button
      class="me-2"
      [primary]="true"
      [langKey]="'common:textEdit'"
      [defaultText]="'Edit'"
      (onClick)="editAudience()">
    </yc-button>
  </div>

  <!-- Users -->
  <yc-expandable-container
    [header]="'GLOBAL:textUsers' | ycI18n: 'Users'"
    [subHeader]="usersSubHeader"
    [hasData]="audience.members.length > 0">
    <gc-manage-audience-table
      [selectedUsers]="audience.members"
      [isEditMode]="false"
      [tableKey]="'AUDIENCE_USERS_FLYOUT_' + audience.id">
    </gc-manage-audience-table>
  </yc-expandable-container>

  <!-- Usage -->
  <yc-expandable-container
    [header]="'common:hdrAudienceUsage' | ycI18n: 'Audience Usage'"
    [subHeader]="usageSubHeader"
    [hasData]="usage.length > 0">
    <gc-audience-usage-table
      [audienceId]="audienceId"
      [audienceUsage]="audience.usage">
    </gc-audience-usage-table>
  </yc-expandable-container>
  
</div>

