import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { SpinnerService } from '@core/services/spinner.service';
import { I18nService } from '@yourcause/common/i18n';


@Component({
  selector: 'yc-grants-connect',
  template: `
    <yc-router-resolve-block></yc-router-resolve-block>
    <yc-block-ui
      [message]="loadingMessage$ | async"
      [blocking]="blocking$ | async">
    </yc-block-ui>
    <router-outlet *ngIf="langsResolved"></router-outlet>
  `
})
export class AppComponent implements OnInit {
  blocking$ = this.spinnerService.changesTo$('loading');
  loadingMessage$ = this.spinnerService.changesTo$('loadingMessage');
  helpOpen: boolean;
  langsResolved = false;

  constructor (
    private spinnerService: SpinnerService,
    cdr: ChangeDetectorRef,
    private determinationService: PortalDeterminationService,
    i18n: I18nService
  ) {
    spinnerService.startSpinner();
    i18n.namespaceReady()
      .then(() => {
        spinnerService.stopSpinner();
        this.langsResolved = true;
      });
    spinnerService.changesTo$('loading')
      .subscribe(() => {
        cdr.markForCheck();
      });
  }

  ngOnInit () {
    window.document.title = this.determinationService.isManager ?
      'YourCause - GrantsConnect' :
      'YourCause - Grants Portal';
  }

}
