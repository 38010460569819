import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { UserSignature } from '@core/typings/user.typing';
import { ApplicationFormSignature, UserSignatureForBulk } from '@features/configure-forms/form.typing';

@Injectable({ providedIn: 'root' })
export class SignatureResources {

  constructor (
    private portal: PortalDeterminationService,
    private http: HttpRestService
  ) { }

  getUserSignature (): Promise<UserSignature> {
    const endpoint = this.portal.isApply ?
      'api/portal/applicant/GetApplicantSignatureInfo' :
      'api/manager/user/GetGrantManagerSignatureInfo';

    return this.http.get(endpoint);
  }

  getSignaturesBulk (
    applicationIds: number[],
    formIds: number[]
  ): Promise<UserSignatureForBulk[]> {
    const endpoint = 'api/manager/applications/BulkApplicationFormUserSignatures';

    return this.http.post(endpoint, {
      applicationIds,
      formIds
    });
  }

  uploadSignature (
    file: File
  ): Promise<number> {
    const endpoint = this.portal.isApply ?
      'api/portal/applicant/UploadApplicantSignature' :
      'api/manager/user/UploadGrantManagerSignature';

    return this.http.postFile(endpoint, file, null, 'signature.png');
  }

  getSignatureForApplicationFormResponse (
    applicationId: number,
    applicationFormId: number
  ): Promise<ApplicationFormSignature[]> {
    const endpoint = this.portal.isApply ?
      `api/portal/applications/ApplicationFormUserSignatures/${applicationFormId}` :
      `api/manager/applications/${applicationId}/applicationFormUserSignatures/${applicationFormId}`;

    return this.http.get(endpoint);
  }
}
