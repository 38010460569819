import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PaginatedResponse, PaginationOptions } from '@yourcause/common';
import { ApplicationActivityFromApi } from './activity.typing';

@Injectable({ providedIn: 'root' })
export class ApplicationActivityResources {
  constructor (
    private http: HttpRestService
  ) { }

  getApplicationActivity (
    id: number,
    paginationOptions: PaginationOptions<ApplicationActivityFromApi>
  ): Promise<PaginatedResponse<ApplicationActivityFromApi>> {
    const endpoint = `api/manager/activity/${id}`;

    return this.http.post(endpoint, {
      paginationOptions
    });
  }

  getMyActivity (
    paginationOptions: PaginationOptions<ApplicationActivityFromApi>
  ): Promise<PaginatedResponse<ApplicationActivityFromApi>> {
    const endpoint = 'api/manager/activity/MyActivity';

    return this.http.post(endpoint, {
      paginationOptions
    });
  }
}
