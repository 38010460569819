import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { CurrencyService } from '@core/services/currency.service';
import { FormMaskingService } from '@core/services/form-masking.service';
import { TimeZoneService } from '@core/services/time-zone.service';
import { AdHocReportingAPI } from '@core/typings/api/ad-hoc-reporting.typing';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { TimeZone } from '@core/typings/api/time-zone.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ReferenceFieldsUI, STANDARD_FIELDS_CATEGORY_ID } from '@core/typings/ui/reference-fields.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { GCDashboards } from '@features/dashboards/dashboards.typing';
import { FormFieldAdHocService } from '@features/form-fields/services/form-field-ad-hoc.service';
import { FormFieldCategoryService } from '@features/form-fields/services/form-field-category.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { ComponentHelperService, NominationFormObject } from '@features/forms/services/component-helper/component-helper.service';
import { APISortColumn, CSV_COLUMN_REGEX, ColumnFilterRow, FIRST_CHARACTER_REGEX, FilterColumn, FilterHelpersService, FilterModalTypes, GetNestedPipe, PaginationOptions, SimpleNumberMap, TableColumnDirective, ValueComparisonService } from '@yourcause/common';
import { SelectOption, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { DATE_TIME_FORMAT, DateService } from '@yourcause/common/date';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmationModalComponent, ModalFactory } from '@yourcause/common/modals';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { isUndefined } from 'lodash';
import { AdHocReportingDefinitions, RootObjectNames } from './ad-hoc-reporting-definitions.service';

@Injectable({ providedIn: 'root' })
export class AdHocReportingMappingService {
  filterValueSplit = '<=>';
  private getNested = new GetNestedPipe();
  private decimal = new DecimalPipe('en-US');

  constructor (
    private currencyService: CurrencyService,
    private adHocReportingDefinitions: AdHocReportingDefinitions,
    private i18n: I18nService,
    private clientSettingsService: ClientSettingsService,
    private componentHelper: ComponentHelperService,
    private timeZoneService: TimeZoneService,
    private ahs: ArrayHelpersService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService,
    private formMaskingService: FormMaskingService,
    private filterHelperService: FilterHelpersService,
    private valueComparisonService: ValueComparisonService,
    private formFieldCategoryService: FormFieldCategoryService,
    private formFieldHelperService: FormFieldHelperService,
    private formFieldAdHocService: FormFieldAdHocService,
    private dateService: DateService,
    private modalFactory: ModalFactory
  ) { }

  getColumnIdentifier (definition: AdHocReportingUI.ColumnDefinition) {
    if (!!definition.referenceFieldTableId) {
      const relatedField = this.formFieldHelperService.allReferenceFields.find((refField) => {
        return refField.referenceFieldTableId === definition.referenceFieldTableId;
      });

      return `${definition.parentBucket}.${relatedField.key}.${definition.column}`;
    }

    return `${definition.parentBucket}.${definition.column}`;
  }

  getBlankFilter (definition: AdHocReportingUI.ColumnDefinition): FilterColumn<any> {
    return {
      columnName: this.getColumnIdentifier(definition),
      filters: []
    };
  }

  getObjectByReportModelType (
    reportType: AdHocReportingAPI.AdHocReportModelType
  ): RootObjectNames {
    switch (reportType) {
      case AdHocReportingAPI.AdHocReportModelType.Application:
        return 'application';
      case AdHocReportingAPI.AdHocReportModelType.ApplicationForm:
        return 'customForm';
      case AdHocReportingAPI.AdHocReportModelType.Award:
        return 'award';
      case AdHocReportingAPI.AdHocReportModelType.Payment:
        return 'payment';
      case AdHocReportingAPI.AdHocReportModelType.ApplicationInKindAmountRequested:
        return 'applicationInKind';
      case AdHocReportingAPI.AdHocReportModelType.InKindAwardItems:
        return 'awardInKind';
      case AdHocReportingAPI.AdHocReportModelType.InKindPaymentItems:
        return 'paymentInKind';
      case AdHocReportingAPI.AdHocReportModelType.Budgets:
        return 'budgets';
      case AdHocReportingAPI.AdHocReportModelType.FundingSources:
        return 'fundingSources';
      case AdHocReportingAPI.AdHocReportModelType.TodaysDate:
        return 'todaysDate';
      case AdHocReportingAPI.AdHocReportModelType.Table:
        return 'table';
      case AdHocReportingAPI.AdHocReportModelType.FieldGroup:
        return 'fieldGroup';
    }
  }

  mapColumnsToColumnDirective (columns: AdHocReportingUI.ColumnImplementation[]) {
    return columns.map((col) => {
      const { definition } = col;
      if (!col.tableColumn) {
        this.mapColToDirective(definition, col);
      } else {
        col.tableColumn.ycTableColumnOverrideVisible = col.visibleInReport;
      }

      return col.tableColumn;
    });
  }

  private mapColToDirective (
    definition: AdHocReportingUI.ColumnDefinition,
    col: AdHocReportingUI.ColumnImplementation
  ) {
    const dir = new TableColumnDirective(null, null);
    dir.ycTableColumnLabelOnly = true;
    dir.ycTableColumn = this.getColumnLabel(definition);
    dir.ycTableColumnClass = definition.class;
    dir.ycTableColumnProp = this.getColumnIdentifier(definition);
    dir.ycTableColumnFilterType = definition.type;
    dir.ycTableColumnOverrideVisible = col.visibleInReport;
    dir.ycTableColumnNoFiltering = col.definition.noFiltering;
    if (definition.type === 'typeaheadSingleEquals') {
      dir.ycTableColumnFilterType = 'list';
    }
    if (definition.type === 'currency') {
      dir.ycTableColumnFilterType = 'number';
    }
    if ('filterOptions' in definition) {
      dir.ycTableColumnOptions = definition.filterOptions;
    }
    col.tableColumn = dir;
  }

  getColumnLabel (definition: AdHocReportingUI.ColumnDefinition): string {
    return definition.display + ' (' + definition.parentBucketName + ')';
  }

  mapApiToColumnFilters (
    column: AdHocReportingAPI.UserSavedReportColumn
  ): FilterColumn<any> {
    return {
      columnName: column.columnName,
      filters: column.userSavedFilterColumns.map(filterColumn => {
        if (filterColumn.filterType === FilterModalTypes.between) {
          filterColumn.filterValue = (filterColumn.filterValue as string).split(
            this.filterValueSplit).map(val => new Date(val)
          ) as any;
        }

        return filterColumn;
      })
    };
  }

  getReferenceFieldAnswersMaps (
    refFieldAnswers: ReferenceFieldAPI.ApplicationRefFieldResponse[],
    isNominationReportField = false
  ): {
    referenceFieldValueMap: Record<string, string>;
    referenceFieldDetailMap: AdHocReportingUI.ReferenceFieldResponseMap;
  } {
    const referenceFieldDetailMap: AdHocReportingUI.ReferenceFieldResponseMap = {};
    const referenceFieldValueMap = (refFieldAnswers || []).reduce((acc, current) => {
      const refKey = isNominationReportField ?
        this.componentHelper.getNominatorReportKey(current.referenceFieldKey) :
        current.referenceFieldKey;
      referenceFieldDetailMap[refKey] = current;
      if (!!current.currencyValue) {
        return {
          ...acc,
          [refKey + '.currencyValue']: current.currencyValue,
          [refKey]: current.value
        };
      } else if (!!current.addressValue) {
        const mappedAddressFields = Object.keys(current.addressValue).reduce((_acc, addressAttr) => {
          return {
            ..._acc,
            [`${refKey}.${addressAttr}`]: current.addressValue[
              addressAttr as keyof ReferenceFieldAPI.FormFieldAddressResponse
            ]
          };
        }, {});

        return {
          ...acc,
          ...mappedAddressFields
        };
      } else {
        return {
          ...acc,
          [refKey]: current.value
        };
      }
    }, {});

    return {
      referenceFieldValueMap,
      referenceFieldDetailMap
    };
  }

   /**
    *
    * @param def The column definition, used for formatting data
    * @param initialRow Used for the value of the report cell
    * @param masked Determines the visibility of the data
    */
  getValueForColumnFromRow (
    def: AdHocReportingUI.ColumnDefinition,
    initialRow: AdHocReportingUI.ReportFieldResponseRow,
    masked: boolean,
    isNominationReportField = false
  ) {
    const {
      referenceFieldValueMap,
      referenceFieldDetailMap
    } = this.getReferenceFieldAnswersMaps(
      isNominationReportField ? initialRow.originalNominationResponse : initialRow.referenceFields,
      isNominationReportField
    );
    const adaptedRow = {
      ...initialRow,
      referenceFieldDetailMap,
      referenceFieldValueMap
    };
    const value = this.isRefFieldColumn(def.parentBucket) ?
      adaptedRow.referenceFieldValueMap[def.column] :
      this.getNested.transform(adaptedRow, {
        key: def.parentBucket as RootObjectNames,
        subKey: def.column
      });

    return this.getFormattedDisplayValue(
      value,
      def,
      adaptedRow,
      masked,
      false
    );
  }
   /**
    *
    * @param value The value of the record
    * @param def Used for formatting the record
    * @param row Contains additional data for formatting and reference field info
    * @param masked Determines visibility of the data
    * @param displayNoneForNoValue Shows 'None' if no data
    */
  getFormattedDisplayValue (
    value: any,
    def: AdHocReportingUI.ColumnDefinition,
    row: AdHocReportingUI.AdaptedReportResponseRow,
    masked: boolean,
    displayNoneForNoValue = false
  ) {
    const field = this.formFieldHelperService.getReferenceFieldByKey(def.column);
    const parentBucket = field ? 'referenceFieldValueMap' : def.parentBucket;

    if (masked && field?.isMasked) {
      return this.formMaskingService.formFieldMask;
    }

    // Multiple value answers are stored as a separated list
    // so format it for display
    let separator = ',';
    if (field) {
      separator = this.formFieldHelperService.getSupportsMultipleSeparator(
        field
      );
    }
    if ('filterOptions' in def) {
      if (def.format === 'label') {
        let foundLabel: string;
        const options: (TypeaheadSelectOption<any>|SelectOption<any>)[] = def.filterOptions;
        if (
          value &&
          (field?.supportsMultiple || def.type === 'multiValueList')
        ) {
          foundLabel = value.split(separator).map((val: string) => {
            return this.getSelectedOption(val, options);
          }).filter((val: string) => !!val).join(', ');
        } else {
          foundLabel = this.getSelectedOption(value, options);
        }
        if (foundLabel) {
          return foundLabel;
        }
      }
    } else if (value) {
      if (field && (def as AdHocReportingUI.FileColumnDefinition).format === 'file') {
        return this.adaptFilesForDisplay(
          row,
          field
        );
      } else if (field?.supportsMultiple) {
        value = value.split(separator).join(', ');
      }
    }

    const noValue = isUndefined(value) || value  === null;
    if (noValue) {
      if (displayNoneForNoValue) {
        return this.i18n.translate('common:textNone', {}, 'None');
      } else if (def.defaultDisplay || def.defaultI18nKey) {
        if (def.defaultI18nKey) {
          return this.i18n.translate(
            def.defaultI18nKey,
            {},
            def.defaultDisplay
          );
        }

        return def.defaultDisplay;
      }
    }

    switch (def.type) {
      case 'boolean':
        if (noValue) {
          return '';
        }

        if (typeof(value) === 'string') {
          const string = value;
          if (value.length && !isNaN(+string)) {
            value = +value;
          } else {
            return '';
          }
        }

        return this.i18n.translate(
          value ?
            'common:textYes' :
            'common:textNo'
        );
      case 'date':
        const timezoneID = this.clientSettingsService.clientSettings ?
          this.clientSettingsService.clientSettings.defaultTimezone || 'UTC' :
          'UTC';
        const timezone = this.timeZoneService.returnTimeZoneFromID(timezoneID);

        return value ?
          this.returnDateOrDateTime(value, def, timezone) :
          '';
      case 'currency':
        if (def.format === 'text') {
          return value;
        } else {
          return this.currencyService.formatMoney(
            value,
            def.format === 'otherColumn' ?
              row[parentBucket as RootObjectNames][def.formatSource] :
              undefined
          );
        }
      case 'number':
        if (def.format === 'id') {
          return value;
        } else if (def.format === 'percent') {
          const percentVal = '' + ((value || 0) * 100);

          return parseFloat(percentVal).toFixed(2) + '%';
        } else if (def.format === 'wholeNumberPercent') {
          const percentVal = '' + (value || 0);

          return parseFloat(percentVal).toFixed(2) + '%';
        } else {
          return this.valueComparisonService.isNumber(value) ?
            this.decimal.transform(value) :
            value;
        }
    }

    return value;
  }

  adaptFilesForDisplay (
    row: AdHocReportingUI.AdaptedReportResponseRow,
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel
  ) {
    const detail = row.referenceFieldDetailMap[field.key];
    const files = field.supportsMultiple ?
      detail.files :
      [detail.file];

    const adapted = this.formFieldHelperService.getFilesFromApplicationRefFieldResponse(
      files,
      +row.application.id,
      +row.form?.applicationFormId
    );

    return adapted.map((file) => {
      return file.fileUrl;
    }).join(', ');
  }

  getSelectedOption (
    value: any,
    options: (TypeaheadSelectOption<any>|SelectOption<any>)[]
  ) {
    const foundOption = options.find((option) => {
      return option.value === value || +option.value === +value;
    });

    return foundOption?.label;
  }

  mapColumnToTableFilter (
    column: AdHocReportingUI.ColumnImplementation
  ): ColumnFilterRow<any>[] {
    if (!column.tableColumn) {
      const { definition } = column;
      this.mapColToDirective(definition, column);
    }

    return this.filterHelperService.adaptColumnToColumnFilterRow(
      column.tableColumn.forApiFilter(),
      column.filterColumn.filters
    );
  }

  // Make sure column implementation has between filters stored
  ensureBetweenFiltersAccurate (
    columns: AdHocReportingUI.ColumnImplementation[],
    paginationOptions: PaginationOptions<any>
  ) {
    const filterColumns = paginationOptions.filterColumns;

    columns.forEach(column => {
      const foundFilterColumns = filterColumns.filter(filterColumn => {
        const columnKey = this.getColumnIdentifier(column.definition);

        return columnKey === filterColumn.columnName;
      });
      const columnHasFilters = foundFilterColumns.length > 0;

      if (!columnHasFilters) {
        column.filterColumn.filters = [];
      } else {
        column.filterColumn = {
          columnName: column.definition.column,
          filters: foundFilterColumns.reduce((acc, val) => {
            return [
              ...acc,
              ...val.filters
            ];
          }, [])
        };
      }

      if (column.definition.type === 'date') {
        const afterFilterColumn = foundFilterColumns
          .find(filterColumn => filterColumn.filters
            .some(filter => filter.filterType === FilterModalTypes.greaterThan)
          );
        const beforeFilterColumn = foundFilterColumns
          .find(filterColumn => filterColumn.filters
            .some(filter => filter.filterType === FilterModalTypes.lessThan)
          );

        if (
          afterFilterColumn &&
          beforeFilterColumn
        ) {

          column.filterColumn = {
            columnName: column.definition.column,
            filters: [{
              filterType: FilterModalTypes.between,
              filterValue: [
                afterFilterColumn.filters[0].filterValue as any,
                beforeFilterColumn.filters[0].filterValue
              ]
            }]
          };
        }
      }
    });
  }

  isRefFieldColumn (columnName: string) {
    return [
      'category.',
      'referenceFields.',
      NominationFormObject
    ].some((key) => columnName.includes(key));
  }

  getRefFieldByColumnName (columnName: string) {
    if (this.isRefFieldColumn(columnName)) {
      let refField: ReferenceFieldAPI.ReferenceFieldDisplayModel;
      const columnNameParts = columnName.split('.');
      let refFieldKey = columnNameParts[columnNameParts.length - 1];
      if (this.isAddressFieldColumn(columnName)) {
        refFieldKey = columnNameParts[columnNameParts.length - 2];
      }
      if (!!refFieldKey) {
        refField = this.formFieldHelperService.getReferenceFieldByKey(refFieldKey);
      }
    
      return refField;
    }

    return null;
  }

  isAddressFieldColumn (columnName: string) {
    const columnNameParts = columnName.split('.');
    if (columnNameParts.length === 4) {
      // Address columns look like this: `category.2353.addressFieldKey.city`
      const potentialAddressAttr = columnNameParts[columnNameParts.length - 1];
      const couldBeAddressAttr = [
        'address1',
        'address2',
        'city',
        'postalCode',
        'stateProvRegCode',
        'countryCode',
        'lat',
        'lng',
        'formattedAddress',
        'craTractCode',
        'craMsaCode',
        'craStateCode',
        'craCountyCode'
      ].includes(potentialAddressAttr);
      if (couldBeAddressAttr) {
        const parentAddressFieldKey = columnNameParts[columnNameParts.length - 2];
        const refField = this.formFieldHelperService.getReferenceFieldByKey(parentAddressFieldKey);
        
        return refField?.type === ReferenceFieldsUI.ReferenceFieldTypes.Address;
      }
    }
      
    return false;
  }

  adaptFormColumnNameForApi (columnName: string) {
    if (this.isRefFieldColumn(columnName)) {
      const refField = this.getRefFieldByColumnName(columnName);
      if (refField.isStandardProductField) {
        columnName = columnName.replace(/category\.\w+\./, 'referenceFields.');
      } else {
        columnName = columnName.replace(/category\.\d+\./, 'referenceFields.');
      }
    }

    return columnName;
  }

  adaptFormColumnNameForView (columnName: string) {
    const columnParts = columnName.split('.');
    const bucket = columnParts[0];
    if (bucket === 'referenceFields') {
      let key = columnParts[columnParts.length - 1];
      if (columnParts.length === 3) {
        // This will be true for data points and address fields
        const middle = columnParts[1];
        // Determine if this is an address field or a field group
        const found = this.formFieldHelperService.getReferenceFieldByKey(middle);
        if (found?.type === ReferenceFieldsUI.ReferenceFieldTypes.Address) {
          key = middle;
        }
      }
      const field = this.formFieldHelperService.getReferenceFieldByKey(key);
      if (field) {
        const categoryId = field.isStandardProductField ?
          STANDARD_FIELDS_CATEGORY_ID :
          (field.categoryId || 0);
        columnParts.shift();
        const adaptedParts = [
          'category',
          categoryId,
          ...columnParts
        ];

        return adaptedParts.join('.');
      } else {
        // If the field is not found, that means it was removed
        return null;
      }
    }

    return columnName;
  }

  additionalKeyString (columnName: string) {
    const addCurrency = columnName.includes('currencyValue');
    const additionalKeyString = '.currencyValue';

    return addCurrency ? additionalKeyString : '';
  }

  mapColumnsForPagination (
    columns: AdHocReportingUI.ColumnImplementation[],
    paginationOptions: PaginationOptions<any>,
    rowsPerPage = 10
  ): PaginationOptions<any> {
    this.ensureBetweenFiltersAccurate(columns, paginationOptions);
    let sortColumns: APISortColumn<any>[] = [];
    if (paginationOptions.sortColumns.length) {
      sortColumns = paginationOptions.sortColumns.map((col) => {
        return {
          ...col,
          columnName: this.adaptFormColumnNameForApi(col.columnName)
        };
      });
    } else {
      sortColumns = columns.filter((column) => {
        return column.sortType !== AdHocReportingAPI.SortTypes.NoSort;
      }).map((col) => {
        const columnName = this.getColumnIdentifier(col.definition);

        return {
          sortAscending: col.sortType === AdHocReportingAPI.SortTypes.Ascending,
          columnName
        };
      });
    }

    const options = {
      ...paginationOptions,
      rowsPerPage,
      filterColumns: paginationOptions.filterColumns.map(column => {
        if (this.isRefFieldColumn(column.columnName)) {
          column = {
            ...column,
            columnName: this.adaptFormColumnNameForApi(column.columnName)
          };
        }

        return column;
      }),
      sortColumns
    };

    if (!options.sortColumns.length) {
      const column = columns[0];
      const columnKey = this.getColumnIdentifier(column.definition);
      options.sortColumns = [{
        sortAscending: true,
        columnName: this.adaptFormColumnNameForApi(columnKey)
      }];
    }

    return options;
  }

  mapColumnsToApi (
    columns: AdHocReportingUI.ColumnImplementation[]
  ) {
    return columns.map<AdHocReportingAPI.UserSavedReportColumn>((column, index) => {
      const columnName = this.getColumnIdentifier(column.definition);
      const refField = this.getRefFieldByColumnName(columnName);

      return {
        referenceFieldId: refField?.referenceFieldId,
        referenceFieldTableId: column.definition.referenceFieldTableId,
        columnName: this.adaptFormColumnNameForApi(columnName),
        sortType: column.sortType,
        sortPriority: index + 1,
        userSavedFilterColumns: this.mapColumnFiltersToApiFilters(
          column.filterColumn
        ),
        isVisible: column.visibleInReport,
        displayName: column.columnNameOverride,
        isChartAggregate: false,
        isChartGroupingColumn: false,
        isChartSubGroupingColumn: false,
        chartAggregateType: null
      };
    });
  }

  mapColumnFiltersToApiFilters (
    columnFilters: FilterColumn<any>
  ) {
    return columnFilters.filters
      .map<AdHocReportingAPI.UserSavedFilterColumn>(columnFilter => ({
        filterType: columnFilter.filterType,
        filterValue: columnFilter.filterValue instanceof Array ?
          columnFilter.filterValue.join(this.filterValueSplit) :
          columnFilter.filterValue as any
      }));
  }

  returnDateOrDateTime (
    value: any,
    def: AdHocReportingUI.DateColumnDefinition,
    timezone: TimeZone
  ) {
    if (def.format === 'date') {
      // when we format as date we ignore time (and timezone) because clients are often looking
      // for the scheduled date
      return this.dateService.getStartOrEndOfDayInUtcFormatted(value);
    } else {
      return this.dateService.applyOffsetAndFormat(value, timezone.offset, DATE_TIME_FORMAT);
    }
  }

  mapSimpleColumnToColumnImplementation (
    simpleCols: GCDashboards.SimpleColumn[],
    buckets: AdHocReportingUI.ColumnBucket[]
  ): AdHocReportingUI.ColumnImplementation[] {
    const allColumns = buckets.reduce((acc, bucket) => {
      return [
        ...acc,
        ...bucket.allColumns
      ];
    }, [] as AdHocReportingUI.ColumnImplementation[]);

    return simpleCols.map((col) => {
      const found = allColumns.find((bucketCol) => {
        const columnKey = this.getColumnIdentifier(bucketCol.definition);

        return columnKey === col.column;
      });

      return {
        ...found,
        columnNameOverride: col.columnNameOverride
      };
    });
  }

  getBucketMap (
    buckets: AdHocReportingUI.ColumnBucket[]
  ): Record<string, AdHocReportingUI.ColumnBucket> {
    return buckets.reduce((acc, bucket) => {
      return {
        ...acc,
        [bucket.property]: bucket
      };
    }, {});
  }

  mapReportColumnToColumnImplementation (
    userSavedReportColumns: AdHocReportingAPI.UserSavedReportColumn[],
    rootObject: AdHocReportingUI.RootObject,
    relatedObjects: (AdHocReportingUI.RootObject|AdHocReportingUI.RelatedObject)[],
    buckets: AdHocReportingUI.ColumnBucket[]
  ) {
    const bucketMap = this.getBucketMap(buckets);

    return this.ahs.sort(userSavedReportColumns, 'sortPriority')
      .map<AdHocReportingUI.ColumnImplementation>((column) => {
        const columnName = this.adaptFormColumnNameForView(column.columnName);
        if (columnName) {
          column.columnName = columnName;

          const foundObj = this.findAdHocObject(column, rootObject, relatedObjects, bucketMap);

          if (!foundObj) {
            // this usually means a form was removed
            return undefined;
          }

          const relatedBucket = buckets.find((bucket) => {
            return bucket.property === foundObj.property;
          });
          const foundColumn = relatedBucket.columns.find((relatedColumn) => {
            const columnKey = this.getColumnIdentifier(relatedColumn.definition);

            return column.columnName === columnKey;
          });
          if (!foundColumn) {
            // this usually means a form component was removed from the form
            return undefined;
          }

          return {
            ...foundColumn,
            columnNameOverride: column.displayName,
            filterColumn: this.mapApiToColumnFilters(column),
            visibleInReport: column.isVisible,
            sortType: column.sortType
          };
        } else {
          // Reference field no longer exists
          return undefined;
        }
      }).filter((item) => !!item);
  }

  findAdHocObject (
    column: AdHocReportingAPI.UserSavedReportColumn,
    rootObject: AdHocReportingUI.RootObject,
    relatedObjects: (AdHocReportingUI.RootObject|AdHocReportingUI.RelatedObject)[],
    bucketMap: Record<string, AdHocReportingUI.ColumnBucket>
  ) {
    const useRoot = this.propertyIsSubstringOfColumn(
      column.columnName,
      rootObject.property,
      bucketMap
    );
    const foundObj = useRoot ?
      rootObject :
      relatedObjects.find((relatedObj) => {
        return this.propertyIsSubstringOfColumn(
          column.columnName,
          relatedObj.property,
          bucketMap
        );
      });

    return foundObj;
  }

  private propertyIsSubstringOfColumn (
    column: string,
    property: string,
    bucketMap: Record<string, AdHocReportingUI.ColumnBucket>
  ): boolean {
    if (
      column.startsWith('category.') &&
      !property.startsWith('category.')
    ) {
      return false;
    }
    const columnParts = column.split('.');

    const passes = property.split('.').every((relatedPart) => {
      return columnParts.includes(relatedPart);
    });
    if (!passes) {
      // See if the column has a customParentBucket
      const bucket = bucketMap[property];
      if (!!bucket) {
        const columnLivesInThisBucket = bucket.columns.some((col) => {
          const columnKey = this.getColumnIdentifier(col.definition);

          return columnKey === column;
        });

        return columnLivesInThisBucket;
      }
    }

    return passes;
  }

  getCategoryBuckets (
    formIds: number[],
    componentMap: SimpleNumberMap<AdHocReportingUI.FormComponentSummary[]>,
    usage = AdHocReportingUI.Usage.AD_HOC_BUILDER,
    rootObject?: AdHocReportingUI.RootObject<string>
  ): AdHocReportingUI.RootObject<string>[] {
    const categoryMap = this.formFieldCategoryService.getCategoryMapFromFormIds(
      formIds,
      componentMap,
      this.formFieldTableAndSubsetService.tableColumnsMap,
      usage,
      rootObject
    );

    return Object.keys(categoryMap).map((categoryId) => {
      return this.getCategoryObject(categoryId, categoryMap[categoryId]);
    });
  }

  getCategoryObject (
    categoryId: number|string,
    fields: ReferenceFieldAPI.ReferenceFieldAdHocModel[]
  ): AdHocReportingUI.RootObject {
    return {
      ...this.adHocReportingDefinitions.customForm,
      property: `category.${categoryId}`,
      display: categoryId === STANDARD_FIELDS_CATEGORY_ID ?
        this.i18n.translate(
          'common:textStandardFields',
          {},
          'Standard fields'
        ) :
        this.formFieldCategoryService.categoryNameMap[categoryId],
      i18nKey: null,
      columns: fields.reduce((acc, field) => {
        return [
          ...acc,
          ...this.formFieldAdHocService.getReferenceFieldColumnDef(field)
        ];
      }, [])
    };
  }

  getInvalidColumnHeaders (
    columns: AdHocReportingUI.ColumnImplementation[]
  ) {
    const columnNames = columns.filter((col) => {
      return !!col.definition;
    }).map((column) => {
      return column.columnNameOverride ||
        `${column.definition.display} (${column.definition.parentBucketName })`;
    });

    return columnNames.filter((name) => {
      return !this.isColumnHeaderValid(name);
    });
  }


  isColumnHeaderValid (columnName: string) {
    if (!columnName) {
      return true;
    }
    
    return (!CSV_COLUMN_REGEX.test(columnName) && !FIRST_CHARACTER_REGEX.test(columnName));
  }

  getInvalidColumnHeadersText (invalidColumnHeaders: string[]) {
    let confirmText: string;
    if (invalidColumnHeaders.length > 0) {
      confirmText = this.i18n.translate(
        'common:textColumnHeadersInvalidConfirm',
        {},
        'The following column header(s) have a special character that is invalid. Please update these headers to proceed with saving changes.'
      ) + '<br>';
      invalidColumnHeaders.forEach((columnName) => {
        confirmText += `<li>
          ${columnName}
        </li>`;
      });
    }

    return confirmText;
  }

  async openInvalidColumnHeadersModal (
    invalidColumnHeaders: string[]
  ) {
    const confirmText = this.getInvalidColumnHeadersText(invalidColumnHeaders);
    await this.modalFactory.open(
      ConfirmationModalComponent,
      {
        modalHeader: this.i18n.translate(
          'common:textInvalidColumnHeaders',
          {},
          'Invalid Column Header(s)'
        ),
        confirmButtonText: this.i18n.translate(
          'CONFIG:textProceedToChanges',
          {},
          'Proceed to changes'
        ),
        confirmText
      }
    );
  }

  mapQuickAddToColumns (
    selectedFields: ReferenceFieldsUI.QuickAddField[],
    existingColumns: AdHocReportingUI.ColumnImplementation[],
    allColumnsMap: Record<string, AdHocReportingUI.ColumnImplementation>
  ): AdHocReportingUI.ColumnImplementation[] {
    const mapped = [
      ...existingColumns,
      ...selectedFields.map<AdHocReportingUI.ColumnImplementation>((column) => {
        const uniqueId = this.getColumnIdentifier({
          parentBucket: 'referenceFields',
          column: column.key,
          referenceFieldTableId: column.referenceFieldTableId
        } as AdHocReportingUI.ColumnDefinition);

        return {
          ...allColumnsMap[uniqueId],
          columnNameOverride: column.label
        };
      })
    ];

    return mapped;
  }
}
