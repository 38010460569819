import { ApplicationEditMap, ApplicationViewMap } from '@core/typings/application.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class ApplicationViewState extends BaseYcState {
  readonly applicationViewMap: ApplicationViewMap = {};
  readonly applicationEditMap: ApplicationEditMap = {};

  readonly triggerApproveModal: void;
  readonly triggerActivityTabUpdate: void;
}
