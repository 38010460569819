import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { TokenResponse } from '@core/typings/token.typing';
import { PaginationOptions } from '@yourcause/common';
import { ImpersonationLogRecord } from './impersonation.typing';

@Injectable({ providedIn: 'root' })
export class ImpersonationResources {

  constructor (
    private http: HttpRestService
  ) { }

  impersonateUser (
    userId: number,
    clientId: number,
    clientIdentifier: string
  ): Promise<TokenResponse> {
    const endpoint = 'api/admin/Impersonation/ImpersonateUser';

    return this.http.post(endpoint, {
      userId,
      clientId,
      clientIdentifier
    });
  }

  getImpersonationHistory (
    paginationOptions: PaginationOptions<ImpersonationLogRecord>
  ) {
    const endpoint = 'api/admin/Impersonation/ImpersonationHistory';

    return this.http.post(endpoint, {
      paginationOptions
    });
  }
}

