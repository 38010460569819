import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PolicyService } from '@core/services/policy.service';
import { SpecialHandlingService } from '@core/services/special-handling.service';
import { SpinnerService } from '@core/services/spinner.service';
import { ValidatorsService } from '@core/services/validators.service';
import { ApplicantFormForUI, ApplicationViewPage, NominationForm, Nominee } from '@core/typings/application.typing';
import { OrgInfoForPDF } from '@core/typings/pdf.typing';
import { ApplicationStatuses } from '@core/typings/status.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ApplicationDownloadService } from '@features/application-download/application-download.service';
import { ApplicationFormService } from '@features/application-forms/services/application-forms.service';
import { ApplicationActionService } from '@features/application-manager/services/application-actions/application-actions.service';
import { ApplicationViewService } from '@features/application-view/application-view.service';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { ApplicationViewFormForUI, BaseApplicationForLogic, FormAudience, FormChanges, FormData, FormDefinitionForUi, FormResponse, FormTranslations, FormTypes, SaveFormResponse } from '@features/configure-forms/form.typing';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormFieldService } from '@features/form-fields/services/form-field.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { InKindService } from '@features/in-kind/in-kind.service';
import { LogicState } from '@features/logic-builder/logic-builder.typing';
import { OfflineGrantsSubmitApplicationModalComponent } from '@features/offline-grants/offline-grants-submit-application/offline-grants-submit-application-modal/offline-grants-submit-application-modal.component';
import { ProgramService } from '@features/programs/services/program.service';
import { SignatureService } from '@features/signature/signature.service';
import { WorkflowService } from '@features/workflow/workflow.service';
import { SimpleStringMap, TextFriendlySpecialCharCleaner } from '@yourcause/common';
import { CallMaker, CallMakerFactory } from '@yourcause/common/call-maker';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { ModalFactory } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { Bypass, SignatureModalComponent, SignatureModalResponse } from '@yourcause/common/signature';
import { YCTwoWayEmitter } from '@yourcause/common/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gc-form-response-manager-portal',
  templateUrl: './form-response-manager-portal.component.html',
  styleUrls: ['./form-response-manager-portal.component.scss']
})
export class FormResponseManagerPortalComponent implements OnInit, OnChanges {
  @Input() editing: boolean;
  @Input() isModalView = false;
  @Input() isEditModeForDraft: boolean;
  @Input() form: ApplicationViewFormForUI|ApplicantFormForUI;
  @Input() programId: number;
  @Input() formId: number;
  @Input() readOnly: boolean;
  @Input() application: ApplicationViewPage;
  @Input() response: FormResponse;
  @Input() isManagerEditingApplicantForm = false;
  @Input() forceDefaultCurrency: boolean;
  @Input() masked: boolean;
  @Input() requireSignature: boolean;
  @Input() signatureDescription: string;
  @Input() supportsBypassSignature: boolean;
  @Input() refIdsChanged: number[];
  @Input() standardFieldsChanged: ReferenceFieldsUI.StandardFieldTypes[];
  @Input() submitFormTrigger = new YCTwoWayEmitter<Promise<boolean>>();
  @Input() scrollBoxClass: string;
  @Input() position: 'bottom-right' | 'bottom-left' | 'top-left' | 'top-right' = 'bottom-right';
  @Output() onSavingFormChange = new EventEmitter<boolean>();
  @Output() onCancelEditMode = new EventEmitter();
  @Output() onToggleMask = new EventEmitter<boolean>();
  @Output() onFormSubmitted = new EventEmitter();
  @Output() onTranslationsReady = new EventEmitter<FormTranslations>();
  @Output() onSignatureModalToggle = new EventEmitter<boolean>();

  statusText: string;
  statusIcon: string;
  nominationForm: NominationForm;
  nomineeFormValid: boolean;
  markForValidity: boolean;
  formData: FormData;
  formDefinition: FormDefinitionForUi[];
  completedByMeSection: boolean;
  isEligibilityForm = false;
  pendingFormChanges: FormChanges[] = [];
  formSubmit = new YCTwoWayEmitter<Promise<boolean>>();
  ApplicationStatuses = ApplicationStatuses;
  conditionalVisibilityState: LogicState<BaseApplicationForLogic, boolean>;
  hideForm = false;
  translations: SimpleStringMap<string> = {};
  richTextTranslations: SimpleStringMap<string> = {};
  callMaker: CallMaker<FormChanges[], boolean> = CallMakerFactory.create(
    (changes) => this.handleAutoSave(changes),
    500,
    false,
    true
  );
  sub = new Subscription();

  constructor (
    private formService: FormsService,
    private notifier: NotifierService,
    private i18n: I18nService,
    private formFieldHelperService: FormFieldHelperService,
    private logger: LogService,
    private formFieldService: FormFieldService,
    private applicationFormService: ApplicationFormService,
    private specialHandlingService: SpecialHandlingService,
    private validatorsService: ValidatorsService,
    private formHelperService: FormHelperService,
    private applicationActionService: ApplicationActionService,
    private clientSettingsService: ClientSettingsService,
    private activatedRoute: ActivatedRoute,
    private programService: ProgramService,
    private workflowService: WorkflowService,
    private modalFactory: ModalFactory,
    private signatureService: SignatureService,
    private spinnerService: SpinnerService,
    private inKindService: InKindService,
    private applicationViewService: ApplicationViewService,
    private applicationDownloadService: ApplicationDownloadService,
    private policyService: PolicyService
  ) { }

  get applicationMap () {
    return this.isEdit ?
      this.applicationViewService.applicationEditMap :
      this.applicationViewService.applicationViewMap;
  }

  get currentFormType () {
    if ('formType' in this.form) {
      return this.form.formType;
    } else if ('formTypeId' in this.form) {
      return this.form.formTypeId;
    }

    return null;
  }

  get clientBranding () {
    return this.clientSettingsService.clientBranding;
  }

  get isNomination () {
    return location.pathname.includes('nomination');
  }

  get isEdit (): boolean {
    return this.activatedRoute.snapshot.data.isEdit;
  }

  get currentFormAudience () {
    if ('audience' in this.form) {
      return this.form.audience;
    }

    return FormAudience.APPLICANT;
  }

  get nominee () {
    return this.application ?
      (this.application.nominee || {} as Nominee) :
      {} as Nominee;
  }

  get isNominationForm () {
    if (this.form) {
      return this.currentFormType === FormTypes.NOMINATION;
    }

    return false;
  }

  ngOnInit () {
    this.setNominationAttrs();
  }

  async ngOnChanges (changes: SimpleChanges) {
    this.isEligibilityForm = this.formService.getIsEligibilityForm(this.formId);
    if (this.submitFormTrigger && changes.submitFormTrigger) {
      this.registerTriggerSubmit();
    }
    if (changes.form || changes.readOnly || changes.response) {
      this.formDefinition = null;
      this.formData = null;
      if (this.response) {
        setTimeout(() => {
          this.formData = this.response.formData;
          this.formDefinition = this.response.formDefinition;
        });
      }
    }
  }

  private registerTriggerSubmit () {
    if (this.submitFormTrigger) {
      this.submitFormTrigger.registerAction(async () => {
        return this.submit();
      });
    }
  }

  onResponseChange (change: FormChanges) {
    if (!this.readOnly) {
      this.pendingFormChanges = [
        ...this.pendingFormChanges,
        change
      ];
      this.callMaker.invoke(() => this.pendingFormChanges);
    }
  }

  setNominationAttrs () {
    this.nominationForm = {
      email: this.nominee?.email ?? '',
      firstName: this.nominee?.firstName ?? '',
      lastName: this.nominee?.lastName ?? '',
      phoneNumber: this.nominee?.phoneNumber ?? '',
      position: this.nominee?.position ?? ''
    };
    const nomineeFormValid = !!this.nominee?.email &&
      !!this.nominee?.firstName &&
      !!this.nominee?.lastName &&
      !Validators.email(new UntypedFormControl(this.nominee?.email));
    this.onNomineeValidityChange(nomineeFormValid);
  }

  nominationFormChanged (form: NominationForm) {
    this.nominationForm = form;
    // Will trigger application change because isReferenceField is false.
    // Other fields are irrelevant.
    this.onResponseChange({
      key: '',
      type: '',
      isReferenceField: false,
      value: null
    });
  }

  onNomineeValidityChange (valid: boolean) {
    this.nomineeFormValid = valid;
  }

  setTranslations (translations: FormTranslations) {
    this.translations = translations.translations;
    this.richTextTranslations = translations.richTextTranslations;
    this.onTranslationsReady.emit(translations);
  }

  toggleSaving (saving: boolean) {
    if (saving) {
      this.statusText = this.i18n.translate(
        'GLOBAL:textSaving',
        {},
        'Saving'
      );
      this.statusIcon = 'ellipsis-h-alt';
      this.onSavingFormChange.emit(true);
    } else {
      this.onSavingFormChange.emit(false);
      this.statusText = this.i18n.translate(
        'GLOBAL:textSaved',
        {},
        'Saved'
      );
      this.statusIcon = 'check-circle';
    }
  }

  async handleAutoSave (changes: FormChanges[]) {
    try {
      this.toggleSaving(true);
      const response = this.formFieldHelperService.handleChangeTracking(
        changes,
        this.application
      );
      const appNeedsUpdated = response.appNeedsUpdated;
      const refChangeTracker = response.refChangeTracker;
      if (Object.keys(refChangeTracker).length > 0) {
        await this.saveRefFields(refChangeTracker);
      }
      if (appNeedsUpdated) {
        await this.saveApplication(true, false);
      }
      this.pendingFormChanges = this.pendingFormChanges.filter(change => {
        return !changes.includes(change);
      });
      // this makes sure that any onChanges listeners are aware of the changes to this.application
      this.application = { ...this.application };
      this.toggleSaving(false);
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorSavingChanges',
        {},
        'There was an error saving the changes'
      ));
    }

    return true;
  }

  async saveRefFields (
    refChangeTracker: ReferenceFieldsUI.RefResponseMap
  ): Promise<boolean> {
    const response = this.formFieldService.adaptFormChangesForSave(
      refChangeTracker,
      this.response.applicationFormId,
      this.application.applicationId,
      this.response.formRevisionId,
      false,
      this.currentFormAudience === FormAudience.MANAGER
    );
    const passResponse = await this.applicationFormService.handleSaveOfChangedFields(
      response,
      this.application.applicationId,
      this.form.formId,
      this.response.formRevisionId,
      this.response.applicationFormId,
      this.isManagerEditingApplicantForm,
      this.isEdit
    );

    this.toggleSaving(false);

    return passResponse.standardPassed && passResponse.tablePassed;
  }

  async saveApplication (
    isDraft = true,
    submittingApplication = false,
    workflowLevelId?: number,
    userSignatureId?: number,
    userSignatureBypassed?: boolean
  ): Promise<boolean> {
    const specialHandling = this.specialHandlingService.getSpecialHandlingForSave(
      this.application.specialHandling,
      this.application.defaultSpecialHandling
    );
    const designationValid = this.validatorsService.getDesignationValidity(this.application.designation);
    const specialHandlingValid = this.specialHandlingService.isSpecialHandlingValid(
      this.application.specialHandling
    );
    const nominationValid = !this.isNominationForm || this.nomineeFormValid;
    if (specialHandlingValid && nominationValid && designationValid) {  // save fails if not valid
      const {
        reviewerRecommendedFundingAmountRequired,
        decisionRequired,
        amountRequestedRequired,
        careOfRequired,
        paymentDesignationRequired
      } = this.formHelperService.getRequiredStandardFields(
        this.response.formDefinition
      );
      const currencyRequested = this.application.currencyRequested ||
        this.clientSettingsService.defaultCurrency;
      const data: SaveFormResponse = {
        ...specialHandling,
        formId: this.form.formId,
        formRevisionId: this.response.formRevisionId,
        nominee: this.isNominationForm ?
          this.nominationForm :
          null,
        isDraft,
        applicationFormId: this.response.applicationFormId,
        revisionNotes: '',
        careOf: this.application.careOf,
        amountRequested: this.application.amountRequestedForEdit || 0,
        saveAmountRequestedInDefaultCurrency: this.forceDefaultCurrency,
        currencyRequested,
        paymentDesignation: TextFriendlySpecialCharCleaner(
          this.application.designation ||
          this.application.paymentDesignation
        ),
        decision: this.application.decision,
        reviewerRecommendedFundingAmount: this.application.reviewerRecommendedFundingAmount,
        inKindItems: this.inKindService.getInKindItemsForSave(
          this.application.inKindItems
        ),
        requiredReferenceFieldKeys: this.formHelperService.getRequiredReferenceFieldKeys(
          this.conditionalVisibilityState,
          this.response.formDefinition
        ),
        reviewerRecommendedFundingAmountRequired,
        decisionRequired,
        amountRequestedRequired,
        careOfRequired,
        paymentDesignationRequired,
        editingApplicationView: this.isEdit,
        submittingApplication,
        workflowLevelId,
        userSignatureId,
        userSignatureBypassed
      };
      if (this.isNominationForm) {
        data.nominee = {
          ...this.nominationForm,
          orgId: this.application.organizationId
        };
      }
      const {
        automaticallyRouted
      } = await this.applicationFormService.saveFormResponse(data, this.application.applicationId);

      if (automaticallyRouted) {
        this.applicationActionService.showAutomaticallyRoutedToaster(
          this.isNomination
        );
      }

      return automaticallyRouted;
    }

    return false;
  }

  async submit (): Promise<boolean> {
    let passed = false;
    this.toggleSaving(true);
    const isValid = await this.formSubmit.emit();
    if (this.isNominationForm && !this.nomineeFormValid) {
      this.markForValidity = true;
    } else {
      this.markForValidity = false;
    }
    if (isValid) {
      if (this.markForValidity) {
        this.formInvalidError();
        this.hideForm = true;
        // Form thinks submit was successful so it hides the form / submit button.
        // Set timeout will re-render and force it to show.
        setTimeout(() => {
          this.hideForm = false;
        });

        passed = false;
      } else {
        passed = await this.proceedWithSubmission();
      }
    } else {
      this.formInvalidError();

      passed = false;
    }

    this.onToggleMask.emit(true);
    this.toggleSaving(false);

    return passed;
  }

  formInvalidError () {
    this.toggleSaving(false);
    this.notifier.error(this.i18n.translate(
      'APPLY:textFormIsInvalid',
      {},
      'Form is invalid or incomplete. Fix errors to continue.'
    ));
  }

  async signatureModal (): Promise<SignatureModalResponse> {
    this.onSignatureModalToggle.emit(true);
    this.spinnerService.startSpinner();
    const {
      supportsBypass,
      signatureDescription
    } = this.signatureService.getSignatureFormDescriptionManagerPortal(
      this.form.name,
      this.currentFormAudience,
      this.form.signatureDescription,
      this.isNomination
    );
    await this.signatureService.setSignature();
    this.spinnerService.stopSpinner();

    const response = await this.modalFactory.open(
      SignatureModalComponent,
      {
        savedSignature: this.signatureService.savedSignatureFile,
        supportsBypass,
        signatureDescription
      }
    );
    this.onSignatureModalToggle.emit(false);

    return response;
  }

  async handleSignature () {
    let signatureIsValid = !this.form.requireSignature;
    let userSignatureId: number;
    let userSignatureBypassed: boolean;
    let proceed = true;
    if (this.form.requireSignature) {
      const signatureResponse = await this.signatureModal();
      if (signatureResponse) {
        if (signatureResponse.type !== Bypass) {
          /** Handle Signature */
          this.spinnerService.startSpinner();
          userSignatureId = await this.signatureService.handleSignatureModalResponse(
            signatureResponse
          );
          this.spinnerService.stopSpinner();
          signatureIsValid = !!userSignatureId;
        } else {
          /** Bypass */
          signatureIsValid = true;
          userSignatureBypassed = true;
        }
      } else {
        /** Cancel */
        proceed = false;
      }
    }

    return {
      signatureIsValid,
      userSignatureBypassed,
      userSignatureId,
      proceed
    };
  }

  async proceedWithSubmission (): Promise<boolean> {
    let submitPassed = false;
    const {
      signatureIsValid,
      userSignatureBypassed,
      userSignatureId,
      proceed
    } = await this.handleSignature();
    if (proceed) {
      if (signatureIsValid) {
        if (this.isEditModeForDraft) {
          const response = await this.openSubmitApplicationModal(
            userSignatureId,
            userSignatureBypassed
          );
          if (response) {
            this.onCancelEditMode.emit();
          }
        } else {
          this.onCancelEditMode.emit();
          this.spinnerService.startSpinner();
          submitPassed = await this.doSubmission(
            null,
            userSignatureId,
            userSignatureBypassed
          );
          this.spinnerService.stopSpinner();
        }
      }
    }

    return submitPassed;
  }

  async openSubmitApplicationModal (
    userSignatureId: number,
    userSignatureBypassed: boolean
  ) {
    const defaultWorkflowLevelId = await this.programService.getDefaultWflId(
      this.application.programId
    );
    const response = await this.modalFactory.open(
      OfflineGrantsSubmitApplicationModalComponent,
      {
        isNomination: this.isNomination,
        workflow: await this.workflowService.getAndSetWorkflowMap(
          this.application.currentWorkFlowId
        ),
        defaultWorkflowLevelId
      }
    );
    if (response) {
      this.spinnerService.startSpinner();
      const passes = await this.doSubmission(
        response.workflowLevelId,
        userSignatureId,
        userSignatureBypassed
      );
      this.spinnerService.stopSpinner();
      if (passes && response.approveAfterSubmit) {
        this.applicationViewService.triggerApproveModal();
      }
    }

    return response;
  }

  async doSubmission (
    workflowLevelId?: number,
    userSignatureId?: number,
    userSignatureBypassed?: boolean
  ) {
    let submitPassed = false;
    const submittingApplication = this.isEditModeForDraft;
    try {
      const shouldAwait = !this.policyService.grantApplication.canManageAllApplications() &&
        !this.policyService.grantApplication.canTakeActionsOnAllApps();
      // When capturing a snapshot, it slows down the submission so we prefer to *not* await this call if possible
      // The scenario we do need to await are users who potentially may lose access to the current app through WFL automation.
      if (shouldAwait) {
        await this.capturePdfOfSubmission();
      } else {
        this.capturePdfOfSubmission();
      }
      await this.saveApplication(
        false,
        submittingApplication,
        workflowLevelId,
        userSignatureId,
        userSignatureBypassed
      );
      if (submittingApplication) {
        this.notifier.success(this.i18n.translate(
          this.isNomination ?
            'APPLY:textSuccessSubmittingNomination' :
            'APPLY:textSuccessSubmittingApplication',
          {},
          this.isNomination ?
            'Successfully submitted the nomination' :
            'Successfully submitted the application'
        ));
      } else {
        this.notifier.success(this.i18n.translate(
          'APPLICATION:textSuccessfullySubmittedForm',
          {},
          'Successfully submitted the form'
        ));
      }
      submitPassed = true;
    } catch (e) {
      this.logger.error(e);
      if (submittingApplication) {
        this.notifier.error(this.i18n.translate(
          this.isNomination ?
            'APPLY:textErrorSubmittingNomination' :
            'APPLY:textErrorSubmittingApplication',
          {},
          this.isNomination ?
            'There was an error submitting the nomination' :
            'There was an error submitting the application'
        ));
      } else {
        this.notifier.error(this.i18n.translate(
          'APPLICATION:textErrorSubmittingForm',
          {},
          'There was an error submitting the form'
        ));
      }
    }
    this.onFormSubmitted.emit();

    return submitPassed;
  }

  async capturePdfOfSubmission () {
    try {
      let orgInfo: OrgInfoForPDF;
      if (this.application.organizationId) {
        orgInfo = {
          name: this.application.organizationName,
          addressString: this.application.organizationAddress,
          registrationId: this.application.organizationIdentification,
          phone: this.applicationMap[this.application.applicationId].organization.phone
        };
      }
      await this.applicationDownloadService.downloadFormPDF(
        this.application,
        orgInfo,
        this.response,
        this.form,
        this.application.submittedDate,
        this.isNomination,
        this.nominee,
        this.masked,
        this.clientBranding.name,
        this.translations,
        this.richTextTranslations,
        true,
        this.response.applicationFormId
      );
    } catch (e) {
      this.logger.error(e);
      this.logger.log('PDF Snapshot Failed', {
        client: this.clientBranding.name,
        applicationId: '' + this.application.applicationId,
        applicationFormId: '' + this.response.applicationFormId
      });
    }
  }
}

