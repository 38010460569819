import { ConfigureProgramMap, GrantProgramCycleBudgetFundingSource, OfflineProgramDetail, Program, ProgramChange, ProgramDashboardMap, ProgramDetail, ProgramDetailFromApi, ProgramForDashboard } from '@core/typings/program.typing';
import { SimpleStringMap } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class ProgramState extends BaseYcState {
  readonly programCycleBudgetFundingSource: Record<number, GrantProgramCycleBudgetFundingSource[]> = {};
  readonly allPrograms: Program[];
  readonly allPublishedPrograms: Program[];
  readonly allPublishedActivePrograms: Program[];
  readonly allActiveManagerPrograms: Program[]; // segmented
  readonly allManagerPrograms: Program[]; // segmented
  readonly allActiveManagerProgramOptions: TypeaheadSelectOption[];
  readonly dashboardPrograms: ProgramForDashboard[];
  readonly nonMaskedDashboardPrograms: ProgramForDashboard[];

  readonly programMap: SimpleStringMap<ProgramDetailFromApi> = {};
  readonly applicantProgramMap: Record<number|string, ProgramDetail> = {};
  readonly offlineProgramMap: SimpleStringMap<OfflineProgramDetail> = {};
  readonly programDashboardMap: ProgramDashboardMap = {};
  readonly configureProgramMap: ConfigureProgramMap = {};
  readonly programChanges: ProgramChange[];
  readonly cycleBudgetsMap: SimpleStringMap<number[]> = {};

  readonly activeProgramId: string;
  readonly ycProcessingPrograms: Program[];
  readonly clientProcessingPrograms: Program[];

  // Track the cycle ids we are filtering on the program dashboard for use in child tabs
  readonly currentProgramDashCycleIds: number[];
}
