import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { AllFormsResponse } from '@features/configure-forms/form.typing';

@Injectable({ providedIn: 'root' })
export class ApplicationDownloadResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getAllFormResponsesForApplication (
    applicationId: number
  ): Promise<AllFormsResponse> {
    const endpoint = `/api/manager/applications/FormResponsesForApplication?applicationId=${applicationId}`;

    return this.httpRestService.get(endpoint);
  }

  getAllFormResponsesForCurrentWorkflowLevel (
    applicationId: number
  ): Promise<AllFormsResponse> {
    const endpoint = `/api/manager/applications/FormResponsesForApplicationCurrentWorkflowLevel?applicationId=${applicationId}`;

    return this.httpRestService.get(endpoint);
  }
}
