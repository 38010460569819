import { AbstractControl } from '@angular/forms';

export function MaxItemsValidator<T> (
  maxItems: number,
  customErrorMessage: string,
  defaultErrorMessage: string
) {
  return (control: AbstractControl) => {
    const value = control.value as T[] ?? [];
    const isValid = value.length <= maxItems;

    if (!isValid) {
      return {
        hasSelectedQuantityError: {
          i18nKey: '',
          defaultValue: '',
          errorMessage: customErrorMessage || defaultErrorMessage
        }
      };
    }

    return null;
  };

};
