export namespace OpenCloseBudgetAPI {
 export interface CloseBudgetPayload {
  budgetIds: number[];
  remainingFundsDecisionId: RemainingFundsDecision;
  moveFundingSourceToBudgetModels: MoveFundingSourceToBudgetModel[];
 }
 export interface MoveFundingSourceToBudgetModel {
  fromFundingSourceId: number;
  toBudgetId: number;
}
 export enum RemainingFundsDecision {
   SELECT_BUDGET = 1,
   BACK_TO_FS = 2,
   DO_NOTHING = 3
 }
}
