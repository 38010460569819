import { SimpleStringMap } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { CustomDataTable, CustomDataTableOption, KeyValue } from './custom-data-tables.typing';

@RegisterYCState()
export class CustomDataTablesState extends BaseYcState {
  readonly customDataTables: CustomDataTable[];
  readonly customDataTableMap: {
    [x: string]: CustomDataTableOption[];
  } = {};

  readonly allCustomDataTableMap: {
    [x: string]: CustomDataTableOption[];
  } = {};
  readonly customDataTableOptionsMap: {
    [x: string]: KeyValue[];
  } = {};
  readonly guidToNameMap: SimpleStringMap<string> = {};
}
