<yc-modal *ngIf="formGroup"
  [formGroup]="formGroup"
  [modalHeader]="'GLOBAL:hdrDownloadForm' | ycI18n: 'Download Form'"
  [modalSubHeader]="formName"
  [primaryDisabled]="formGroup.invalid"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  [primaryButtonText]="'common:textDownload' | ycI18n: 'Download'"
  (onCancel)="closeModal.emit()"
  (onPrimaryClick)="onDownload()">

  <div class="mb-2">
    <yc-typeahead-select
      name="visibility"
      [label]="'GLOBAL:textDownloadFormVisibilityLabel' | ycI18n: 'What should show in the download?'"
      [showClear]="false"
      [items]="visibilityOptions">
    </yc-typeahead-select>
  </div>

  <div>
    <yc-checkbox
      name="includeFormVersion"
      [optionLabel]="'GLOBAL:textxIncludeFormVersionOnDownload' | ycI18n: 'Include form version on download'">
    </yc-checkbox>
  </div>
</yc-modal>