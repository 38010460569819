import { SidebarLink } from '@yourcause/common';
import { ButtonAction } from '@yourcause/common/buttons';
import { CachedAttr, CACHE_TYPES } from '@yourcause/common/cache';
import { BaseYCActionState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class AppShellState extends BaseYCActionState {
  readonly showHeaderButtons: boolean = true;

  @CachedAttr(CACHE_TYPES.LOCALSTORAGE)
  readonly sidebarClosed: boolean;

  readonly sidebarLinks: SidebarLink[] = [];

  readonly supportLinks: ButtonAction[] = [];

  readonly activeHelpLink: string = '';
}
