import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsoResources } from '@core/resources/sso.resources';
import { SSOConfigState } from '@core/states/sso-config.state';
import { Applicant } from '@core/typings/applicant.typing';
import { User } from '@core/typings/client-user.typing';
import { LoginBehaviors } from '@core/typings/login-behaviors.typing';
import { SSOConfigurationForUi, SSOTokenKey, SsoData, SsoMessage } from '@core/typings/sso-configuration.typing';
import { TokenResponse } from '@core/typings/token.typing';
import { environment } from '@environment';
import { LogService } from '@yourcause/common/logging';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { GuidService } from '@yourcause/common/utils';
import { DeepLinkingService } from './deep-linking.service';
import { PortalDeterminationService } from './portal-determination.service';
import { SpinnerService } from './spinner.service';
import { AuthBehaviors } from './token/token-behaviors';

@AttachYCState(SSOConfigState)
@Injectable({ providedIn: 'root' })
export class SSOService extends BaseYCService<SSOConfigState> {

  constructor (
    private spinnerService: SpinnerService,
    private portal: PortalDeterminationService,
    private deepLinkingService: DeepLinkingService,
    private guidService: GuidService,
    private authBehaviors: AuthBehaviors,
    private ssoResources: SsoResources,
    private logger: LogService
  ) {
    super();
  }

  get ssoConfig () {
    return this.get('ssoConfig');
  }

  private get id_token () {
    return localStorage.getItem(SSOTokenKey);
  }

  private set id_token (id_token: string) {
    if (id_token === null) {
      localStorage.removeItem(SSOTokenKey);
    } else {
      localStorage.setItem(SSOTokenKey, id_token);
    }
  }

  setSSOConfig (config: SSOConfigurationForUi) {
    this.set('ssoConfig', config);
  }

  getIdToken () {
    return this.id_token;
  }

  setIdToken (token: string) {
    this.id_token = token;
  }

  logout () {
    if (this.id_token) {
      const prefix = this.portal.getCurrentPrefix();
      const post_logout_redirect_uri = this.portal.isManager ?
        `https://${environment.locationBase}/management/ssologout${prefix ? `?prefix=${prefix}` : ''}` :
        `https://apply.${environment.locationBase}/apply/ssologout${prefix ? `?prefix=${prefix}` : ''}`;

      const id_token_hint = this.id_token;
      const result = {
        post_logout_redirect_uri,
        id_token_hint
      };

      const url = environment.identityServer + '/connect/endsession?' + Object.keys(result)
        .map(key => key as keyof typeof result)
        .map((key) => `${key}=${encodeURIComponent(result[key])}`)
        .join('&');

      this.id_token = null;

      return url;
    }

    return '';
  }

  generateNonce () {
    let text = '';
    const possible = '0123456789abcdef';
    for (let i = 0; i < 64; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  generateUrl (tenantId: string, prefix: string, clientId: number) {
    const locationBase: string = environment.isLocalhost ?
      'yourcausegrantsqa.com' :
      environment.locationBase;

    const routeBase = this.portal.isManager ?
      'management' :
      'apply';

    const result = {
      acr_values: `tenant:${tenantId}`,
      client_id: this.portal.isManager ?
        environment.ssoClientId :
        environment.ssoApplicantClientId,
      response_type: 'id_token token',
      scope: 'openid',
      nonce: this.guidService.nonce(),
      state: this.guidService.nonce(),
      redirect_uri: `https://${prefix}.${locationBase}/${routeBase}/sso_redirect?clientId=${clientId}`
    };
    const urlStart = environment.identityServer + '/connect/authorize?';
    const url = urlStart + Object.keys(result)
      .map(key => key as keyof typeof result)
      .map((key) => `${key}=${result[key]}`)
      .join('&');

    return url;
  }

  private createAndWaitOnSubdomain (newHost: string, message: SsoMessage): Promise<any> {
    return new Promise<void>((resolve) => {
      const newPath = `${newHost}${location.pathname}`;
      const iframe = document.createElement('iframe');
      iframe.src = newPath;
      async function listen (
        {
          data: inboundMessage
        }: SsoData
      ) {
        if (!inboundMessage || typeof inboundMessage === 'string') {
          // We are getting a session watcher inbound message that is a string, I believe from BBId
          // We need to filter this out
          return;
        }
        if ('ycLoaded' in inboundMessage && inboundMessage.ycLoaded) {
          iframe.contentWindow.postMessage(message, newHost);
        } else if ('ycAcknowledged' in inboundMessage) {
          document.body.removeChild(iframe);
          window.removeEventListener('message', listen);
          resolve();
        }
      }
      window.addEventListener('message', listen);
      document.body.appendChild(iframe);
    });
  }

  async handOffToSubdomain (
    ...args: [
      string,
      string,
      TokenResponse,
      User|Applicant,
      string,
      string
    ]
  ) {
    const [
      prefix,
      nextRoute,
      token,
      user,
      clientIdentifier,
      ssoToken
    ] = args;
    const newHost = `https://${prefix}.${environment.locationBase}${+location.port > 443 ? `:${location.port}` : ''}`;
    this.spinnerService.startSpinner();
    const info: SsoMessage = {
      token,
      user,
      clientIdentifier,
      attemptedRoute: this.deepLinkingService.getAttemptedRoute(),
      ssoToken: ssoToken || undefined
    };
    await this.createAndWaitOnSubdomain(newHost, info);
    localStorage.removeItem(this.authBehaviors.current.userTokenKey);
    localStorage.removeItem(this.authBehaviors.current.userKey);

    const href = this.getSubdomainUrl(prefix, nextRoute);
    location.href = href;
  }

  getSubdomainUrl (
    prefix: string,
    nextRoute: string
  ) {
    const newHost = `https://${prefix}.${environment.locationBase}${+location.port > 443 ? `:${location.port}` : ''}`;

    return newHost + nextRoute;
  }

  async getSSOConfigurationBySubDomain (
    subDomain: string,
    skipSetOnState = false
  ) {
    try {
      const config = await this.ssoResources.getSSOConfigurationBySubDomain(subDomain);
      const adapted: SSOConfigurationForUi = {
        ...config,
        ssoLoginUrl: ''
      };
      if (config.loginBehavior !== LoginBehaviors.PlainLogin) {
        adapted.ssoLoginUrl = this.generateUrl(
          config.affiliateId,
          subDomain,
          config.clientId
        );
      }
      if (!skipSetOnState) {
        this.setSSOConfig(adapted);
      }

      return adapted;
    } catch (err) {
      const e = err as HttpErrorResponse;
      if (e.status === 404) {
        return null;
      }
      this.logger.error(e);
      throw e;
    }
  }

  /**
   * On login page, we get the SSO settings for the current subdomain
   * If we need to route to a newer subdomain, we do that here
   */
  async handleSubDomainOnLoginPage () {
    const subdomain = this.portal.getCurrentPrefix();
    const config = await this.getSSOConfigurationBySubDomain(subdomain, true);
    if (!!config) {
      const needsUpdated = !!config.subDomain &&
        !location.hostname.toLowerCase().includes(config.subDomain.toLowerCase());
      if (needsUpdated) {
        const url = this.getSubdomainUrl(config.subDomain, location.pathname);
        location.href = url;
      } else {
        this.setSSOConfig(config);
      }
    }
  }
}
