import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { EmailByApplication, EmailByProgramResponse } from '@features/system-emails/email.typing';

@Injectable({ providedIn: 'root' })
export class ExportEmailResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getEmailsByProgramAndLang (
    grantProgramId: number,
    languageId: string
  ): Promise<EmailByProgramResponse[]> {
    const endpoint = '/api/manager/programs/ProgramEmailsByLanguage';

    return this.httpRestService.post(endpoint, {
      grantProgramId,
      languageId
    });
  }


  getApplicationEmails (applicationId: number): Promise<EmailByApplication[]> {
    const endpoint = `api/manager/applications/${applicationId}/ApplicationSentEmails`;

    return this.httpRestService.post(endpoint, {});
  }
}

