import { Injectable } from '@angular/core';
import { CountriesState } from '@core/states/countries.state';
import countriesWithRegions from '@core/static-assets/countries-with-regions';
import { CountryWithRegions, Region } from '@core/typings/location.typing';
import { AllowedLocation } from '@core/typings/program.typing';
import { ALL_SKIP_FILTER } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';

@AttachYCState(CountriesState)
@Injectable({ providedIn: 'root' })
export class CountriesService extends BaseYCService<CountriesState> {

  constructor (
    private arrayHelper: ArrayHelpersService,
    private i18n: I18nService
  ) {
    super();
  }

  get countriesWithRegions () {
    return this.get('countriesWithRegions');
  }

  get countrySelects () {
    return this.get('countrySelects');
  }

  get countryToRegionsMap () {
    return this.get('countryToRegionsMap');
  }

  get countryCodeToRegionOptionsMap () {
    return this.get('countryCodeToRegionOptionsMap');
  }

  get countryIdToRegionOptionsMap () {
    return this.get('countryIdToRegionOptionsMap');
  }

  get countryIdToNameMap () {
    return this.get('countryIdToNameMap');
  }

  get countryCodeToNameMap () {
    return this.get('countryCodeToNameMap');
  }

  get countryIdToCountryMap () {
    return this.get('countryIdToCountryMap');
  }

  get regionIdToRegionMap () {
    return this.get('regionIdToRegionMap');
  }

  /**
   * Set Countries with Regions Data on State
   */
  setCountriesWithRegions () {
    if (!this.countriesWithRegions) {
      const adaptedList = (countriesWithRegions as CountryWithRegions[]).map((country) => {
        return {
          ...country,
          regions: country.regions.map((region) => {
            return {
              ...region,
              regionCode: region.regionCode || region.regionName
            };
          })
        };
      });
      this.set('countriesWithRegions', adaptedList);
      this.setCountrySelects();
      this.setCountryToRegionsMaps();
    }
  }

  /**
   * Set Country Selects
   */
  setCountrySelects () {
    const options = this.countriesWithRegions.map((opt) => {
      return {
        label: opt.countryName,
        value: opt.countryCode
      };
    });
    this.set('countrySelects', this.arrayHelper.sort(options, 'label'));
  }

  /**
   * Set Map Data on State
   */
  setCountryToRegionsMaps () {
    let countryToRegionsMap: Record<string, Region[]> = {};
    let countryIdToNameMap: Record<number, string> = {};
    let countryCodeToNameMap: Record<string, string> = {};
    let countryIdToRegionOptionsMap: Record<number, TypeaheadSelectOption[]> = {};
    let countryIdToCountryMap: Record<number, CountryWithRegions> = {};
    let regionIdToRegionMap: Record<number, Region> = {};
    const countryCodeToRegionOptionsMap = this.countriesWithRegions.reduce((acc, country) => {
      countryToRegionsMap[country.countryCode] = country.regions;
      countryIdToNameMap[country.countryId] = country.countryName;
      countryCodeToNameMap[country.countryCode] = country.countryName;
      countryIdToCountryMap[country.countryId] = country;

      countryIdToRegionOptionsMap[country.countryId] = [
        {
          label: this.i18n.translate('common:textAllRegions', {}, 'All regions'),
          value: ALL_SKIP_FILTER
        },
        ...country.regions.map((region) => {
          return {
            label: region.regionName,
            value: region.regionId
          };
        })
      ];

      return {
        ...acc,
        [country.countryCode]: country.regions.map((region) => {
          regionIdToRegionMap[region.regionId] = region;

          return {
            label: region.regionName,
            value: region.regionCode
          };
      })
      };
    }, {} as Record<string, TypeaheadSelectOption[]>);
    this.set('countryToRegionsMap', countryToRegionsMap);
    this.set('countryCodeToRegionOptionsMap', countryCodeToRegionOptionsMap);
    this.set('countryIdToRegionOptionsMap', countryIdToRegionOptionsMap);
    this.set('countryIdToNameMap', countryIdToNameMap);
    this.set('countryCodeToNameMap', countryCodeToNameMap);
    this.set('countryIdToCountryMap', countryIdToCountryMap);
    this.set('regionIdToRegionMap', regionIdToRegionMap);
  }

  /**
   * Check if the given country and state pass the allowed locations
   *
   * @param countryCode: Country Code to Check
   * @param stateProvRegCode: State Code To Check
   * @param allowedLocations: Allowed Locations
   * @returns if the given location passes the allowed locations array
   */
  checkIfPassesLocationFiltering (
    countryCode: string,
    stateProvRegCode: string,
    allowedLocations: AllowedLocation[]
  ) {
    if (!allowedLocations || allowedLocations.length === 0) {
      return true;
    }

    return allowedLocations.some((location) => {
      const allowedCountryCode = this.countryIdToCountryMap[location.countryId].countryCode;
      const allowedStateCodes = location.regionIds.map((regionId) => {
        return this.regionIdToRegionMap[regionId].regionCode;
      });

      return allowedCountryCode === countryCode &&
        (
          allowedStateCodes.length === 0 ||
          allowedStateCodes.includes(stateProvRegCode)
        );
    });
  }
}
