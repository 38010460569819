import { AdHocReportingAPI } from '@core/typings/api/ad-hoc-reporting.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { SimpleNumberMap } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { ManageReportFormData } from '../pages/manage-ad-hoc-report-modal/manage-ad-hoc-report-modal.component';
import { AudienceMember } from '@features/audience/audience.typing';

@RegisterYCState()
export class AdHocReportingState extends BaseYcState {
  readonly reports: AdHocReportingAPI.UserSavedReport[];
  readonly reportDetails: SimpleNumberMap<AdHocReportingAPI.UserSavedReport> = {};
  readonly currentReportDetails: ManageReportFormData;
  readonly currentReportColumns: AdHocReportingUI.ColumnImplementation[];
  readonly formComponentMap: SimpleNumberMap<AdHocReportingUI.FormComponentSummary[]> = {};
  readonly tableComponentMap: SimpleNumberMap<AdHocReportingAPI.FormComponentSummary[]> = {};
  readonly formMap: SimpleNumberMap<AdHocReportingAPI.FormDisplayModel[]> = {};
  readonly reportSchedulesMap: Record<number, AdHocReportingUI.AdHocReportSchedule[]> = {};
  readonly reportUsersMap: Record<number, AudienceMember[]> = {};
}
