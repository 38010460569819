import { Injectable } from '@angular/core';
import JSZip from 'jszip';

@Injectable({ providedIn: 'root' })
export class JsZipService {

  /**
   *
   * @returns the new zip
   */
  createZip () {
    return new JSZip();
  }

  /**
   *
   * @param zip: zip to add folder to
   * @param folderName: the folder name
   * @returns the new zip folder
   */
  addFolder (
    zip: JSZip,
    folderName: string
  ) {
    return zip.folder(folderName);
  }

  /**
   *
   * @param zip: the zip to add file to
   * @param fileName: the file name
   * @param file: the file
   */
  addFile (
    zip: JSZip,
    fileName: string,
    file: any
  ) {
    zip.file(fileName, file);
  }

  /**
   *
   * @param zip: the zip to generate
   * @returns the Blob
   */
  generateAsync (zip: JSZip) {
    return zip.generateAsync({ type: 'blob' });
  }
}
