import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { SpinnerService } from '@core/services/spinner.service';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory, YCModalComponent } from '@yourcause/common/modals';
import { AudienceUsageModalComponent } from '../audience-usage-modal/audience-usage-modal.component';
import { AudienceService } from '../audience.service';
import { Audience, AudienceDetail, AudienceMember, AudienceModalResponse } from '../audience.typing';

interface AudienceModalFormGroup {
  audienceName: string;
  description: string;
}

@Component({
  selector: 'gc-audience-modal',
  templateUrl: './audience-modal.component.html',
  styleUrls: ['./audience-modal.component.scss']
})
export class AudienceModalComponent extends YCModalComponent<
  AudienceModalResponse
> implements OnInit {
  @Input() audience: Audience;

  tableKey: string;
  audienceDetail: AudienceDetail;
  formGroup: TypeSafeFormGroup<AudienceModalFormGroup>;
  selectedUsers: AudienceMember[] = [];
  addingExternalUser = false;
  alertMessage = '';
  hideModal = false;

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private audienceService: AudienceService,
    private modalFactory: ModalFactory,
    private spinnerService: SpinnerService,
    private analyticsService: AnalyticsService,
    private i18n: I18nService
  ) {
    super();
  }

  async ngOnInit () {
    this.spinnerService.startSpinner();
    this.tableKey = 'AUDIENCE_MEMBERS_' +
      (this.audience ? this.audience.id : 0);
    if (!!this.audience) {
      await this.audienceService.setAudienceDetail(this.audience.id);
      this.audienceDetail = this.audienceService.audienceDetailMap[
        this.audience.id
      ];
      this.selectedUsers = this.audienceDetail.members;
    } else {
      this.selectedUsers = [];
    }
    this.setAlertMessage();
    const detail = this.audienceDetail || {} as AudienceDetail;
    this.formGroup = this.formBuilder.group({
      audienceName: [
        detail.name || '',
        [Validators.required, Validators.maxLength(50)]
      ],
      description: detail.description || ''
    });
    this.spinnerService.stopSpinner();
  }

  setAlertMessage () {
    if (!!this.audience) {
      const usage = this.audienceDetail.usage;
      if (this.audienceService.hasUsage(usage)) {
        this.alertMessage = this.i18n.translate(
          'common:textAudienceUsageDesc',
          {},
          `Changes to audience members could affect other configurations. Click "See usage" to see a list of all places this audience is used.`
        );
      }
    }
  }

  async seeAudienceUsage () {
    this.hideModal = true;
    await this.modalFactory.open(
      AudienceUsageModalComponent,
      {
        audienceId: this.audience.id,
        audienceName: this.audience.name,
        audienceUsage: this.audienceDetail.usage
      }
    );
    this.hideModal = false;
  }

  onSubmit () {
    const formValue = this.formGroup.value;
    this.closeModal.emit({
      id: this.audience ? this.audience.id : null,
      name: formValue.audienceName,
      description: formValue.description,
      members: this.selectedUsers
    });
    this.analyticsService.emitEvent({
      eventName: 'Audience modal submit',
      eventType: EventType.Click,
      extras: null
    });
  }

  onCancel () {
    this.closeModal.emit();
  }

}
