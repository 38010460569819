import { Component, Input, OnInit } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';
import { UserFromApi } from '@core/typings/client-user.typing';
import { YCModalComponent } from '@yourcause/common/modals';
import { AudienceService } from '../audience.service';
import { AudienceDetail } from '../audience.typing';

@Component({
  selector: 'gc-remove-user-from-audience-modal',
  templateUrl: './remove-user-from-audience-modal.component.html',
  styleUrls: ['./remove-user-from-audience-modal.component.scss']
})
export class RemoveUserFromAudienceModalComponent extends YCModalComponent<boolean> implements OnInit {
  @Input() audienceId: number;
  @Input() user: UserFromApi;

  ready = false;
  hasUsage = false;
  audienceDetail: AudienceDetail;

  constructor (
    private audienceService: AudienceService,
    private spinnerService: SpinnerService
  ) {
    super();
  }

  async ngOnInit () {
    this.spinnerService.startSpinner();
    await this.audienceService.setAllAudiences();
    const audience = this.audienceService.audienceSimpleMap[this.audienceId];
    await this.audienceService.setAudienceDetail(audience.id);
    this.spinnerService.stopSpinner();
    this.audienceDetail = this.audienceService.audienceDetailMap[this.audienceId];
    this.hasUsage = this.audienceService.hasUsage(this.audienceDetail.usage);
    this.ready = true;
  }
}
