import { Injectable } from '@angular/core';
import { HttpRestService } from '../http-rest.service';
import { TokenStorageService } from './token-storage.service';

@Injectable({ providedIn: 'root' })
export class TokenRevocationResources {

  constructor (
    private storage: TokenStorageService,
    private http: HttpRestService
  ) { }

  async revokeToken () {
    const clientIdentifier = this.storage.clientIdentifier;
    await this.http.post<null>('/api/token/Refresh/RevokeForClient', {
      clientIdentifier
    });

    this.storage.revoke();
  }

  revokeAll () {
    const endpoint = 'api/token/Refresh/RevokeAll';

    return this.http.post(endpoint, {});
  }
}
