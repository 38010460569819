import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TypeToken } from '@yourcause/common';
import { GuidService } from '@yourcause/common/utils';
import { LogicBuilderService } from '../logic-builder.service';
import { LogicColumn, LogicColumnDisplay, LogicCondition, LogicGroupType } from '../logic-builder.typing';
import { SwitchState } from '@yourcause/common/switch';

@Component({
  selector: 'gc-conditional-logic-builder-group',
  templateUrl: './conditional-logic-builder-group.component.html',
  styleUrls: ['./conditional-logic-builder-group.component.scss']
})
export class ConditionalLogicBuilderGroupComponent<T, V> implements OnInit, OnChanges {
  @Input() group: LogicGroupType<T, V>;
  @Input() availableColumns: LogicColumnDisplay<T>[];
  @Input() allowNoConditions = true;
  @Input() currentDepth: number;
  @Input() maxDepth: number;
  @Input() isViewOnly = false;
  @Output() groupChange = new EventEmitter<LogicGroupType<T, V>>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() onRemove = new EventEmitter<void>();

  SwitchState = SwitchState;
  validMap: Record<string, boolean> = {};
  afterInit = false;
  _useAndSwitchState: SwitchState = SwitchState.Untoggled;
  $logicConditionType = new TypeToken<LogicCondition<T, LogicColumn<T>>>();
  $logicGroupType = new TypeToken<LogicGroupType<T, V>>();

  constructor (
    private logicBuilderService: LogicBuilderService,
    private guidService: GuidService
  ) { }

  get useAndSwitchState (): SwitchState {
    return this._useAndSwitchState;
  }

  set useAndSwitchState (value: SwitchState) {
    this._useAndSwitchState = value;
    this.handleSwitchStateChange();
  }

  get hasConditions () {
    return this.group?.conditions?.length > 0;
  }

  get useAnd () {
    return this.useAndSwitchState === SwitchState.Toggled;
  }

  ngOnInit () {
    let useAnd = this.group.conditions.filter((condition) => {
      return !('conditions' in condition);
    }).every((condition) => condition.useAnd);
    if (this.group.conditions.length === 0) {
      useAnd = false;
    }

    this.useAndSwitchState = useAnd ? SwitchState.Toggled : SwitchState.Untoggled;

    this.group.conditions.forEach((_, index) => {
      this.validMap[index] = true;
    });

    if (!this.group.identifier) {
      this.group.identifier = this.guidService.nonce();
      this.onGroupChange();
    }

    this.emitValidity();
    this.afterInit = true;
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.group && this.afterInit) {
      this.emitValidity();
    }
  }

  onGroupChange () {
    this.groupChange.emit(this.group);
    this.emitValidity();
  }

  andOrChange (state: SwitchState) {
    this.useAndSwitchState = state;
  }

  trackBy (_: number, row: LogicGroupType<T, V>|LogicCondition<T, LogicColumn<T>>) {
    return row.identifier;
  }

  emitValidity () {
    const allValid = Object.keys(this.validMap).every((key) => {
      return this.validMap[key];
    });
    const passes = allValid &&
      (this.allowNoConditions ? true : this.hasConditions);

    this.validChange.emit(passes);
  }

  handleSwitchStateChange () {
    this.group = {
      ...this.group,
      useAnd: this.useAnd,
      conditions: this.group.conditions.map((condition) => {
        if (condition.useAnd !== this.useAnd) {
          return {
            ...condition,
            useAnd: this.useAnd
          };
        }

        return condition;
      })
    };

    this.onGroupChange();
  }

  handleConditionOrGroupValidChange (
    valid: boolean,
    index: number
  ) {
    this.validMap = {
      ...this.validMap,
      [index]: valid
    };
    this.emitValidity();
  }

  handleConditionOrGroupChange (
    conditionOrGroup: LogicCondition<T, LogicColumn<T>>|LogicGroupType<T, V>,
    index: number
  ) {
    this.group = {
      ...this.group,
      conditions: [
        ...this.group.conditions.slice(0, index),
        conditionOrGroup,
        ...this.group.conditions.slice(index + 1)
      ]
    };
    this.onGroupChange();

    if ('conditions' in conditionOrGroup && conditionOrGroup.conditions.length === 0) {
      this.handleRemoveCondition(index);
    }
  }

  handleRemoveCondition (index: number) {
    this.group = {
      ...this.group,
      conditions: [
        ...this.group.conditions.slice(0, index),
        ...this.group.conditions.slice(index + 1)
      ]
    };
    delete this.validMap[index];
    this.onGroupChange();
  }


  addCondition () {
    const newCondition = this.logicBuilderService.getDefaultCondition<T>(this.useAnd);

    this.group = {
      ...this.group,
      conditions: [
        ...this.group.conditions,
        newCondition
      ]
    };

    this.onGroupChange();
  }

  addGroup () {
    const newGroup: LogicGroupType<T, V> = {
      conditions: [
        this.logicBuilderService.getDefaultCondition(this.useAnd)
      ],
      useAnd: this.useAnd,
      identifier: this.guidService.nonce()
    };

    this.group = {
      ...this.group,
      conditions: [
        ...this.group.conditions,
        newGroup
      ]
    };
    this.onGroupChange();
  }
}
