<div class="mb-3">
  {{ description }}
</div>

<yc-alert *ngIf="!hasAtLeastOneColumn"
  [alertClass]="'danger'"
  [message]="'common:textPleaseSelectAtLeastOneColumn' | ycI18n: 'Please select at least one column'">
</yc-alert>

<!-- Select / Unselect All -->
<div class="d-flex flex-grow-1 justify-content-between mb-3">
  <yc-button
    [link]="true"
    [langKey]="'common:textEnableAll'"
    [defaultText]="'Enable all'"
    (onClick)="toggleSelectAll(true)"
    (keydown.enter)="toggleSelectAll(true)">
  </yc-button>
  <yc-button
    [link]="true"
    [langKey]="'common:textDisableAll'"
    [defaultText]="'Disable all'"
    (onClick)="toggleSelectAll(false)"
    (keydown.enter)="toggleSelectAll(false)">
  </yc-button>
</div>

<div *ngIf="ready">
  <yc-table
    [rows]="rows"
    [topLevelFilters]="topLevelFilters"
    [hidePagination]="true"
    [noTableScrollerClass]="true"
    [simple]="true">

    <!-- Is Visible -->
    <ng-container *ycTableColumn="'GLOBAL:textEnabled';
      class: 'checkbox';
      let row;
      let index = index;">
      <yc-checkbox
        [name]="index + '_enabled'"
        [label]="row.originalLabel + ' ' + ('GLOBAL:textEnabled' | ycI18n: 'Enabled')"
        [srOnlyLabel]="true"
        [disabled]="!row.canTurnOffRequiredAndVisible"
        [(value)]="row.isVisible"
        (valueChange)="visibleChange(index)">
      </yc-checkbox>
    </ng-container>

    <!-- Label -->
    <ng-container *ycTableColumn="'common:hdrLabel';
      class: 'text';
      let row;
      let index = index;">
      <yc-textarea
        [name]="index + '_label'"
        [label]="row.originalLabel + ' ' + ('common:hdrLabel' | ycI18n: 'Label')"
        [srOnlyLabel]="true"
        [(value)]="row.label"
        (valueChange)="labelChange(index)">
      </yc-textarea>
    </ng-container>

    <!-- Required -->
    <ng-container *ycTableColumn="'common:textRequired';
      class: 'checkbox';
      let row;
      let index = index;">
      <yc-checkbox
        [name]="index + '_is-required'"
        [label]="row.originalLabel + ' ' + ('common:textRequired' | ycI18n: 'Required')"
        [srOnlyLabel]="true"
        [(value)]="row.isRequired"
        [disabled]="!row.canTurnOffRequiredAndVisible"
        (valueChange)="requiredChange(index)">
      </yc-checkbox>
    </ng-container>

  </yc-table>
</div>