import { CommunicationVisibility } from '@features/communications/communications.typing';
import { FormAudience } from '@features/configure-forms/form.typing';
import { ExistingGenericFile, GenericFile } from '@yourcause/common/files';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';

export enum EmailMergeModel {
  Application = 1,
  ClientUser = 2,
  ApplicationForm = 3,
  Applicant = 4,
  Unused = 5,
  Award = 6,
  Payment = 7
}

export enum EmailNotificationType {
  WelcomeRegistrationForClientUser = 1,
  RequestPasswordChangeForClientUser = 2,
  WelcomeRegistrationForApplicant	= 3,
  RequestPasswordChangeForApplicant	= 4,
  ApplicationSubmittedConfirmation = 5,
  WelcomeRegistrationClientSetupForRootUser	= 6,
  ApplicationReviewReminderForClientUser = 7,
  ApplicationStatusWithCustomMsgForApplicant = 8,
  ExportNotification = 9,
  RequestRevision	= 10,
  CanceledRequestRevision = 11,
  RequestRevisionHandled = 12,
  ApplicationApproved	= 13,
  ApplicationDeclined	= 14,
  ApplicationAwarded = 15,
  ApplicantAddedToApplication	= 16,
  ApplicantAddedByUser= 17,
  ApplicationFormSent	= 18,
  ApplicationFormRecalled	= 19,
  NominationAcceptedForNominatingApplicant = 20,
  NominationDeclinedForNominatingApplicant = 21,
  NomineeApplicantInvitation = 22,
  NominationFormSent = 23,
  NominationSubmittedConfirmation	= 24,
  NominationStatusWithCustomMsgForApplicant	= 25,
  InvitationToGrantsConnect = 26,
  RequestRevisionHandledNom = 27,
  RequestRevisionNom = 28,
  CanceledRequesRevisionNom = 29,
  NominationFormRecalled = 30,
  NominationReviewReminder = 31,
  AppAdditionalFormSubmittedApplicant = 32,
  AppAdditionalFormSubmittedManager = 33,
  NomAdditionalFormSubmittedApplicant = 34,
  NomAdditionalFormSubmittedManager = 35,
  AppRequestRevisionReminder = 36,
  NomRequestRevisionReminder = 37,
  WorkflowLevelSummaryForClientUser = 38,
  PaymentComplete = 39,
  ApplicationHasEnteredYourWorkFlowLevel = 40,
  NominationHasEnteredYourWorkFlowLevel = 41,
  VettingDeclinedApplicant = 42,
  VettingDeclinedManager = 43,
  VettingApprovedApplicant = 44,
  VettingApprovedManager = 45,
  FormToCompleteApplication = 46,
  FormToCompleteNomination = 47,
  ProgramClosingReminderForApplicant = 48,
  ProgramClosingReminderForNominator = 49,
  ApplicationApprovedAndAwarded = 50,
  ApplicationApprovedAndAwardedInKind = 51,
  ApplicationApprovedAndAwardedInKindAndCash = 52,
  ApplicationAwardedInKind = 53,
  PaymentFulfilled = 54,
  DownloadReportSecureStorage = 55,
  DownloadReportSftp = 56,
  PaymentFileNowAvailable = 57,
  InvitationToApply = 58,
  InvitationReminder = 59,
  WelcomeRegistrationForPlatformAdmin = 60,
  FormDueForApplicant = 61,
  FormPastDueForApplicant = 62,
  NominationFormDueForApplicant = 63,
  NominationFormPastDueForApplicant = 64,
  FormDueForGrantManager = 65,
  FormPastDueForGrantManager = 66,
  NominationFormDueForGrantManager = 67,
  NominationFormPastDueForGrantManager = 68,
  ApplicationAutomaticallyDeclined = 69,
  MailMerge = 70,
  AddressRequestReadyForReview = 71,
  VettingRequired = 72,
  ApplicationMovedToDifferentProgram = 73,
  SendPaymentInfoFromBatch = 74,
  YourApplicationIsCanceled = 78,
  ApplicationCanceled = 79,
  YourNominationIsCanceled = 80,
  NominationCanceled = 81
}

export enum EmailUsageTypes {
  System = 1,
  GrantProgram = 2,
  NominationProgram = 3,
  AllPrograms = 4
}

export enum EmailStatus {
  Ready = 1,
  Processing = 2,
  Sent = 3,
  Failed = 4,
  OnHold = 5
}

export enum EmailFrequency {
  IMMEDIATE = 1,
  DAILY = 2,
  WEEKLY = 3,
  MONTHLY = 4
}

export interface Email {
  mergeModelType: EmailMergeModel;
  subject: string;
  name: string;
  description: string;
  title: string;
  emailNotificationType: number;
  canBeDisabledByClient: boolean;
  isDisabled: boolean;
  audienceType: FormAudience;
  heroImage: string;
  customizable: boolean;
  maxCopies: number;
  emailUsageType: EmailUsageTypes;
  nomination: boolean;
  numberOfClientCopies: number;
  supportsCarbonCopy: boolean;
  useClientDefaultCopy: boolean;
  allowDocumentTemplates: boolean;
}

export interface EmailForUI extends Email {
  emailNumber: string;
}

export interface EmailDetail {
  template?: string;
  tokens?: EmailToken[];
  email?: Email;
  clientTemplates?: ClientEmailTemplateForUI[];
  programTemplates?: Record<number, ClientEmailTemplateForUI[]>;
}

export interface EmailTemplateTranslationsMap {
  [langKey: string]: ClientEmailTemplateFromAPI;
}

export interface SimpleEmail {
  id?: number;
  subject: string;
  emailNumber: string;
  body: string;
  emailNotificationType: EmailNotificationType;
  audienceType: FormAudience;
  title: string;
  isEdit?: boolean;
  description: string;
  active: boolean;
  ccEmails: string[];
  bccEmails: string[];
  emailAttachments: GenericFile[];
  allowCarbonCopyUpdates?: boolean;
  allowDocumentTemplates?: boolean;
  supportsCarbonCopy?: boolean;
  documentTemplates?: EmailDocTemp[];
  buttonText: string;
}

export interface EmailToken {
  name: string;
  token: string;
  description: string;
  defaultValue: string;
  typeId: number;
}

export interface ClientEmailTemplateBase {
  emailNumber: string;
  name: string;
  clientId: number;
  emailNotificationTypeId: EmailNotificationType;
  body: string;
  subject: string;
  grantPrograms: {
    id: number;
    name: string;
  }[];
  description: string;
  title: string;
  allowCarbonCopyUpdates: boolean;
  ccEmails: string[];
  bccEmails: string[];
  documentTemplates: EmailDocTemp[];
  translatedLanguages: string[];
}

export interface ClientEmailTranslatedTemplate extends ClientEmailTemplateBase {
  emailAttachments: number[];
}

export interface ClientEmailTemplateFromAPI extends ClientEmailTemplateBase {
  id: number;
  copy: boolean;
  active: boolean;
  emailAttachments: ExistingGenericFile[];
  grantProgramWorkflowLevels: {
    id: number;
    name: string;
    workflowLevelId: number;
    workflowLevelName: string;
  }[];
}

export interface ClientEmailTemplateForUI extends ClientEmailTemplateBase {
  buttonText: string;
  isProgramDefault: boolean;
  isProgramTemplateActive: boolean;
  isClientTemplateActive: boolean;
  emailNotificationType: EmailNotificationType;
  clientEmailTemplateId: number;
  programClientEmailTemplateId: number;
  emailAttachments: ExistingGenericFile[];
  grantProgramWorkflowLevels: {
    id: number;
    name: string;
    workflowLevelId: number;
    workflowLevelName: string;
  }[];
}

export interface ProgramEmailTemplateFromAPI {
  id: number;
  active: boolean;
  default: boolean;
  clientEmailTemplateId: number;
  grantProgramId: number;
  clientEmailTemplate: ClientEmailTemplateFromAPI;
}

export interface EmailDocTemp {
  documentTemplateId: number;
  applicantCanView: boolean;
  documentVisibility: CommunicationVisibility;
}


export interface BaseEmailTemplateCopy {
  id?: number;
  name: string;
  emailNotificationTypeId: EmailNotificationType;
  title: string;
  body: string;
  subject: string;
  default: boolean;
  description: string;
  languageId: string;
  defaultLanguageId: string;
  ccEmails: string[];
  bccEmails: string[];
  allowCarbonCopyUpdates: boolean;
  documentTemplates: EmailDocTemp[];
}

export interface EmailTemplateCopyForUI extends BaseEmailTemplateCopy {
  emailAttachments?: GenericFile[];
  buttonText: string;
}

export interface EmailTemplateCopyForAPI extends BaseEmailTemplateCopy {
  emailAttachments: number[];
}

export interface SendTestEmailPayload {
  clientEmailTemplateId?: number;
  toEmail: string;
  emailNotificationType?: EmailNotificationType;
  grantProgramId?: number;
  languageId?: string;
}

export interface EmailEditableChunk {
  nonEditableChunk: string;
  editableChunk: string;
}

export interface EmailCommGroup {
  name: string;
  group: EmailSetupPrefs[];
}

export interface EmailSetupPrefs {
  type: EmailNotificationType;
  canUnsubscribe: boolean;
  canChangeFrequency: boolean;
  name: string;
  description: string;
  frequencyOptions: TypeaheadSelectOption<EmailFrequency>[];
  frequency: EmailFrequency;
  receiveEmail: boolean;
}

export interface UpdateCommPrefsPayload {
  emailNotificationType: EmailNotificationType;
  frequency: EmailFrequency;
  optedOut: boolean;
}

export interface CommunicationPrefs {
  frequency: EmailFrequency;
  optedOut: boolean;
  emailNotificationType: EmailNotificationType;
}

export interface BulkClientEmailTranslationsPayload {
  clientEmailTemplateId: number;
  translations: ClientEmailTranslationPayload[];
}

export interface ClientEmailTranslationPayload {
  subjectText: string;
  titleText: string;
  bodyText: string;
  buttonText: string;
  languageId: string;
}

export interface EmailLog {
  id: number;
  clientId: number;
  fromAddress: string;
  fromFriendlyName: string;
  toAddress: string;
  toFriendlyName: string;
  subject: string;
  sentDate: string;
  createdDate: string;
  updatedDate: string;
  emailNotificationType: EmailNotificationType;
  audienceType: FormAudience;
  emailStatusType: EmailStatus;
  workflowLevelName?: string;
  workflowName?: string;
  ccEmails: string[];
  bccEmails: string[];
  emailAttachments: ExistingGenericFile[];
  clientEmailTemplateId: number;
}

export interface EmailLogDetail {
  audienceType: FormAudience;
  body: string;
  clientEmailTemplateId: number;
  clientId: number;
  createdDate: string;
  emailNotificationType: EmailNotificationType;
  emailStatusType: EmailStatus;
  fromAddress: string;
  fromFriendlyName: string;
  id: number;
  sentDate: string;
  subject: string;
  toAddress: string;
  toFriendlyName: string;
  updatedDate: string;
}

export interface BaseEmailOptionsModel {
  clientEmailTemplateId: number;
  customMessage: string;
  ccEmails: string[];
  bccEmails: string[];
  attachments: GenericFile[];
}

export interface EmailOptionsModelForSave {
  ccEmails: string[];
  bccEmails: string[];
  attachments: number[];
  clientEmailTemplateId?: number;
  customMessage?: string;
}

export interface CopyEmailModalResponse {
  templates: EmailTemplateCopyForUI[];
  attachments: GenericFile[];
}

export interface ProgramEmailInfo {
  id: number;
  active: boolean;
  default: boolean;
  clientEmailTemplate: {
    id: number;
    name: string;
    body: string;
    subject: string;
    copy: boolean;
    description: string;
    title: string;
  };
  emailNotificationTypeId: EmailNotificationType;
}

export interface EmailByProgramResponse {
  emailInfo: ProgramEmailInfo[];
}

export interface ProgramEmailPdf {
  id?: number;
  emailNotificationName: string;
  subject: string;
  audienceText: string;
  description: string;
  body: string;
  name: string;
  title: string;
  emailNotificationTypeId: EmailNotificationType;
}

export interface EmailByApplication {
  body: string;
  emailNotificationTypeId: EmailNotificationType;
  subject: string;
  toAddress: string;
  toName: string;
  sentTime: string;
  multipleToAddresses: string[];
  carbonCopyRecipients: string[];
}

export interface ApplicationEmailPdf extends ProgramEmailPdf {
  toAddress: string;
  toName: string;
  sentTime: string;
  multipleToAddresses: string[];
  carbonCopyRecipients: string[];
}

export enum EmailPdfType {
  Program = 1,
  Application = 2
}

export type EmailPdfForUi = ProgramEmailPdf|ApplicationEmailPdf;

export enum BlockedReason {
  Permanent_Bounce = 1
}

export interface BlockedEmail {
  emailAddress: string;
  blockedDate: string;
  blockedReason: BlockedReason;
}

