import { Injectable } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';
import { ActivityHelpers, GrantActivityTypes } from './activity.typing';

@Injectable({ providedIn: 'root' })
export class ActivityStatusDefinitions {

  constructor (
    private i18n: I18nService
  ) { }

  get activityMap (): ActivityHelpers {
    return {
      [GrantActivityTypes.ApplicationReviewed]: {
        icon: 'comments',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textCommentOnNomination',
          {},
          'Comment on nomination'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textCommentOnApplication',
          {},
          'Comment on application'
        )
      },
      [GrantActivityTypes.ApplicationAdvanced]: {
        icon: 'code-branch',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominationRoutedToNewLevel',
          {},
          'Nomination routed to a new level'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationRoutedToNewLevel',
          {},
          'Application routed to a new level'
        )
      },
      [GrantActivityTypes.ApplicationApproved]: {
        icon: 'check',
        iconClass: 'text-success',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominationApproved',
          {},
          'Nomination approved'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationApproved',
          {},
          'Application approved'
        )
      },
      [GrantActivityTypes.ApplicationDeclined]: {
        icon: 'times',
        iconClass: 'text-danger',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominationDeclined',
          {},
          'Nomination declined'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationDeclined',
          {},
          'Application declined'
        )
      },
      [GrantActivityTypes.FormReviewed]: {
        icon: 'comments',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textFormComment',
          {},
          'Form comment'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textFormComment',
          {},
          'Form comment'
        )
      },
      [GrantActivityTypes.FormApproved]: {
        icon: 'check',
        iconClass: 'text-success',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textFormApproved',
          {},
          'Form approved'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textFormApproved',
          {},
          'Form approved'
        )
      },
      [GrantActivityTypes.FormDeclined]: {
        icon: 'times',
        iconClass: 'text-danger',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textFormDeclined',
          {},
          'Form declined'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textFormDeclined',
          {},
          'Form declined'
        )
      },
      [GrantActivityTypes.ApplicationAwarded]: {
        icon: 'money-bill-wave',
        iconClass: 'text-success',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominationAwarded',
          {},
          'Nomination awarded'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationAwarded',
          {},
          'Application awarded'
        )
      },
      [GrantActivityTypes.ApplicationOnHold]: {
        icon: 'pause',
        iconClass: 'text-warning',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominationOnHold',
          {},
          'Nomination on hold'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationOnHold',
          {},
          'Application on hold'
        )
      },
      [GrantActivityTypes.FormRevisionRequest]: {
        icon: 'file-exclamation',
        iconClass: 'text-warning',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textFormRevisionRequested',
          {},
          'Form revision requested'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textFormRevisionRequested',
          {},
          'Form revision requested'
        )
      },
      [GrantActivityTypes.PaymentCreated]: {
        icon: 'money-bill-wave-alt',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textPaymentCreated',
          {},
          'Payment created'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textPaymentCreated',
          {},
          'Payment created'
        )
      },
      [GrantActivityTypes.ApplicationStatusChangedToInProgress]: {
        icon: 'exchange',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'nonprofits:textStatusChangedToInProgress',
          {},
          'Status changed to in progress'
        ),
        appTranslated: this.i18n.translate(
          'nonprofits:textStatusChangedToInProgress',
          {},
          'Status changed to in progress'
        )
      },
      [GrantActivityTypes.ApplicationArchived]: {
        icon: 'archive',
        iconClass: 'text-warning',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominationArchived',
          {},
          'Nomination archived'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationArchived',
          {},
          'Application archived'
        )
      },
      [GrantActivityTypes.ApplicationUnarchived]: {
        icon: 'undo',
        iconClass: 'text-warning',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominationUnarchived',
          {},
          'Nomination unarchived'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationUnarchived',
          {},
          'Application unarchived'
        )
      },
      [GrantActivityTypes.ApplicationCycleUpdated]: {
        icon: 'calendar-alt',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textCycleUpdated',
          {},
          'Cycle updated'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textCycleUpdated',
          {},
          'Cycle updated'
        )
      },
      [GrantActivityTypes.RevisionRequestCanceled]: {
        icon: 'file-times',
        iconClass: 'text-warning',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textFormRevisionCanceled',
          {},
          'Form revision canceled'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textFormRevisionCanceled',
          {},
          'Form revision canceled'
        )
      },
      [GrantActivityTypes.ApplicationNoLongerOnHold]: {
        icon: 'play',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNomLongerOnHold',
          {},
          'Nomination no longer on hold'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textAppLongerOnHold',
          {},
          'Application no longer on hold'
        )
      },
      [GrantActivityTypes.FormRevisionSubmitted]: {
        icon: 'file-check',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textFormRevisionSubmitted',
          {},
          'Form revision submitted'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textFormRevisionSubmitted',
          {},
          'Form revision submitted'
        )
      },
      [GrantActivityTypes.ApplicationBudgetFundingSourceUpdated]: {
        icon: 'dollar-sign',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textBudgetAssigned',
          {},
          'Budget assigned'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textBudgetAssigned',
          {},
          'Budget assigned'
        )
      },
      [GrantActivityTypes.ApplicantAdded]: {
        icon: 'user-plus',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominatorAdded',
          {},
          'Nominator added'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicantAdded',
          {},
          'Applicant added'
        )
      },
      [GrantActivityTypes.ApplicantRemoved]: {
        icon: 'user-times',
        iconClass: 'text-danger',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominatorRemoved',
          {},
          'Nominator removed'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicantRemoved',
          {},
          'Applicant removed'
        )
      },
      [GrantActivityTypes.ApplicantPermissionUpdated]: {
        icon: 'user-edit',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominatorPermissionUpdated',
          {},
          'Nominator permission updated'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicanPermissionUpdated',
          {},
          'Applicant permission updated'
        )
      },
      [GrantActivityTypes.DueDateExtended]: {
        icon: 'hourglass',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textDueDateExtended',
          {},
          'Due date extended'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textDueDateExtended',
          {},
          'Due date extended'
        )
      },
      [GrantActivityTypes.FormRecalled]: {
        icon: 'file-import',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textFormRecalled',
          {},
          'Form recalled'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textFormRecalled',
          {},
          'Form recalled'
        )
      },
      [GrantActivityTypes.BudgetAssigned]: {
        icon: 'dollar-sign',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textBudgetAssigned',
          {},
          'Budget assigned'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textBudgetAssigned',
          {},
          'Budget assigned'
        )
      },
      [GrantActivityTypes.ReminderSentToGrantManager]: {
        icon: 'envelope',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textReminderSentToGrantManager',
          {},
          'Reminder sent to grant manager'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textReminderSentToGrantManager',
          {},
          'Reminder sent to grant manager'
        )
      },
      [GrantActivityTypes.NotifyOfStatusSent]: {
        icon: 'envelope',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNotifyOfStatusSent',
          {},
          'Notify of status sent'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textNotifyOfStatusSent',
          {},
          'Notify of status sent'
        )
      },
      [GrantActivityTypes.ApplicationMovedToAwaitingReview]: {
        icon: 'ellipsis-h',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominationMovedToAwaitingReview',
          {},
          'Nomination moved to awaiting review'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationMovedToAwaitingReview',
          {},
          'Application moved to awaiting review'
        )
      },
      [GrantActivityTypes.InKindPaymentCreated]: {
        icon: 'gift',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textInKindPaymentCreated',
          {},
          'Inkind payment created'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textInKindPaymentCreated',
          {},
          'Inkind payment created'
        )
      },
      [GrantActivityTypes.InKindAwardCreated]: {
        icon: 'box-usd',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textInKindAwardCreated',
          {},
          'Inkind award created'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textInKindAwardCreated',
          {},
          'Inkind award created'
        )
      },
      [GrantActivityTypes.TagAdded]: {
        icon: 'tag',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textTagAdded',
          {},
          'Tag added'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textTagAdded',
          {},
          'Tag added'
        )
      },
      [GrantActivityTypes.ApplicationDownloaded]: {
        icon: 'download',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominationDownloaded',
          {},
          'Nomination downloaded'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationDownloaded',
          {},
          'Application downloaded'
        )
      },
      [GrantActivityTypes.FormRevisionReminderSent]: {
        icon: 'paper-plane',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textFormRevisionReminderSent',
          {},
          'Form revision reminder sent'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textFormRevisionReminderSent',
          {},
          'Form revision reminder sent'
        )
      },
      [GrantActivityTypes.FormRevisionCanceled]: {
        icon: 'paper-plane',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textFormRevisionCanceled',
          {},
          'Form revision canceled'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textFormRevisionCanceled',
          {},
          'Form revision canceled'
        )
      },
      [GrantActivityTypes.AttachmentRemoved]: {
        icon: 'file-minus',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textAttachmentRemoved',
          {},
          'Attachment removed'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textAttachmentRemoved',
          {},
          'Attachment removed'
        )
      },
      [GrantActivityTypes.AttachmentAdded]: {
        icon: 'file-plus',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textAttachmentAdded',
          {},
          'Attachment added'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textAttachmentAdded',
          {},
          'Attachment added'
        )
      },
      [GrantActivityTypes.CommunicationRemoved]: {
        icon: 'comment-minus',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textCommunicationRemoved',
          {},
          'Communication removed'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textCommunicationRemoved',
          {},
          'Communication removed'
        )
      },
      [GrantActivityTypes.CommunicationAdded]: {
        icon: 'comment-plus',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textCommunicationAdded',
          {},
          'Communication added'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textCommunicationAdded',
          {},
          'Communication added'
        )
      },
      [GrantActivityTypes.OrganizationUpdated]: {
        icon: 'ribbon',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textOrganizationUpdated',
          {},
          'Organization updated'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textOrganizationUpdated',
          {},
          'Organization updated'
        )
      },
      [GrantActivityTypes.GrantManagerFormSubmitted]: {
        icon: 'ribbon',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textGrantManagerFormSubmitted',
          {},
          'Grant manager form submitted'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textGrantManagerFormSubmitted',
          {},
          'Grant manager form submitted'
        )
      },
      [GrantActivityTypes.ApplicantFormSubmitted]: {
        icon: 'file-check',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominatorFormSubmitted',
          {},
          'Nominator form submitted'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicantFormSubmitted',
          {},
          'Applicant form submitted'
        )
      },
      [GrantActivityTypes.ManualApplicantFormSent]: {
        icon: 'hand-paper',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textManualNominatorFormSent',
          {},
          'Manual nominator form sent'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textManualApplicantFormSent',
          {},
          'Manual applicant form sent'
        )
      },
      [GrantActivityTypes.AutomaticApplicantFormSent]: {
        icon: 'bolt',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textAutomaticNominatorFormSent',
          {},
          'Automatic nominator form sent'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textAutomaticApplicantFormSent',
          {},
          'Automatic applicant form sent'
        )
      },
      [GrantActivityTypes.ApplicationCreated]: {
        icon: 'file-edit',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominationCreated',
          {},
          'Nomination created'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationCreated',
          {},
          'Application created'
        )
      },
      [GrantActivityTypes.ApplicationSubmitted]: {
        icon: 'clipboard-list',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominationSubmitted',
          {},
          'Nomination submitted'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationSubmitted',
          {},
          'Application submitted'
        )
      },
      [GrantActivityTypes.RecommendedFundingAmountSet]: {
        icon: 'money-bill-wave-alt',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textFundingAmountRecommended',
          {},
          'Funding amount recommended'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textFundingAmountRecommended',
          {},
          'Funding amount recommended'
        )
      },
      [GrantActivityTypes.ProgramUpdated]: {
        icon: 'rocket',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textProgramUpdated',
          {},
          'Program updated'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textProgramUpdated',
          {},
          'Program updated'
        )
      },
      [GrantActivityTypes.AwardUpdated]: {
        icon: 'money-bill-wave-alt',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textApplicationAwardUpdated',
          {},
          'Application award updated'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationAwardUpdated',
          {},
          'Application award updated'
        )
      },
      [GrantActivityTypes.InKindAwardUpdated]: {
        icon: 'box-usd',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textInkindAwardUpdated',
          {},
          'Inkind award updated'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textInkindAwardUpdated',
          {},
          'Inkind award updated'
        )
      },
      [GrantActivityTypes.PaymentUpdated]: {
        icon: 'money-bill-alt',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textPaymentUpdated',
          {},
          'Payment updated'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textPaymentUpdated',
          {},
          'Payment updated'
        )
      },
      [GrantActivityTypes.InKindPaymentUpdated]: {
        icon: 'gift',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textInkindPaymentUpdated',
          {},
          'Inkind payment updated'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textInkindPaymentUpdated',
          {},
          'Inkind payment updated'
        )
      },
      [GrantActivityTypes.AmountRequestedUpdated]: {
        icon: 'file-invoice-dollar',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textAmountRequestedUpdated',
          {},
          'Amount requested updated'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textAmountRequestedUpdated',
          {},
          'Amount requested updated'
        )
      },
      [GrantActivityTypes.InKindAmountRequestedUpdated]: {
        icon: 'gifts',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textInkindAmountRequestedUpdated',
          {},
          'Inkind amount requested updated'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textInkindAmountRequestedUpdated',
          {},
          'Inkind amount requested updated'
        )
      },
      [GrantActivityTypes.StatusUpdated]: {
        icon: 'check',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'common:textStatusUpdated',
          {},
          'Status updated'
        ),
        appTranslated: this.i18n.translate(
          'common:textStatusUpdated',
          {},
          'Status updated'
        )
      },
      [GrantActivityTypes.PaymentStatusChange]: {
        icon: 'money-bill-alt',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textPaymentStatusUpdated',
          {},
          'Payment status updated'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textPaymentStatusUpdated',
          {},
          'Payment status updated'
        )
      },
      [GrantActivityTypes.HistoricalDataImported]: {
        icon: 'file-import',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textNominationImported',
          {},
          'Nomination imported'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textApplicationImported',
          {},
          'Application imported'
        )
      },
      [GrantActivityTypes.PaymentDeleted]: {
        icon: 'money-bill-alt',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textPaymentDeleted',
          {},
          'Payment deleted'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textPaymentDeleted',
          {},
          'Payment deleted'
        )
      },
      [GrantActivityTypes.InKindPaymentDeleted]: {
        icon: 'gift',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textInkindPaymentDeleted',
          {},
          'Inkind payment deleted'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textInkindPaymentDeleted',
          {},
          'Inkind payment deleted'
        )
      },
      [GrantActivityTypes.AwardDeleted]: {
        icon: 'money-bill-wave',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textAwardDeleted',
          {},
          'Award deleted'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textAwardDeleted',
          {},
          'Award deleted'
        )
      },
      [GrantActivityTypes.InkindAwardDeleted]: {
        icon: 'box-usd',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'GLOBAL:textInkindAwardDeleted',
          {},
          'Inkind award deleted'
        ),
        appTranslated: this.i18n.translate(
          'GLOBAL:textInkindAwardDeleted',
          {},
          'Inkind award deleted'
        )
      },
      [GrantActivityTypes.ApplicationCanceled]: {
        icon: 'trash-alt',
        iconClass: 'text-danger',
        nomTranslated: this.i18n.translate(
          'common:textApplicationCanceled',
          {},
          'Application canceled'
        ),
        appTranslated: this.i18n.translate(
          'common:textApplicationCanceled',
          {},
          'Application canceled'
        )
      },
      [GrantActivityTypes.NominationCanceled]: {
        icon: 'trash-alt',
        iconClass: 'text-danger',
        nomTranslated: this.i18n.translate(
          'common:textNominationCanceled',
          {},
          'Nomination canceled'
        ),
        appTranslated: this.i18n.translate(
          'common:textNominationCanceled',
          {},
          'Nomination canceled'
        )
      },
      [GrantActivityTypes.FormSigned]: {
        icon: 'file-image',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'common:textFormSigned',
          {},
          'Form signed'
        ),
        appTranslated: this.i18n.translate(
          'common:textFormSigned',
          {},
          'Form signed'
        )
      },
      [GrantActivityTypes.FormSignatureBypassed]: {
        icon: 'undo',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'common:textFormSignatureBypassed',
          {},
          'Form signature bypassed'
        ),
        appTranslated: this.i18n.translate(
          'common:textFormSignatureBypassed',
          {},
          'Form signature bypassed'
        )
      },
      [GrantActivityTypes.PaymentRemovedFromBatch]: {
        icon: 'money-bill-wave-alt',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'common:textPaymentRemovedFromBatch',
          {},
          'Payment removed from batch'
        ),
        appTranslated: this.i18n.translate(
          'common:textPaymentRemovedFromBatch',
          {},
          'Payment removed from batch'
        )
      },
      [GrantActivityTypes.PaymentBudgetFsUpdated]: {
        icon: 'money-bill-wave-alt',
        iconClass: 'text-primary',
        nomTranslated: this.i18n.translate(
          'common:textPaymentBudgetFsUpdated',
          {},
          'Payment budget / funding source updated'
        ),
        appTranslated: this.i18n.translate(
          'common:textPaymentBudgetFsUpdated',
          {},
          'Payment budget / funding source updated'
        )
      }
    };
  }
}

