import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIExternalAPI } from '@core/typings/api/external-api.typing';
import { PaginatedResponse, PaginationOptions } from '@yourcause/common';
import { ClientOutboundMetricsFromApi, CreateOutboundApiRecord, CreateOutboundApiResponse, OutboundApiRecordFromApi, UpdateOutboundApiRecord } from './outbound-api.typing';

@Injectable({ providedIn: 'root' })
export class ExternalAPIResources {

  constructor (
    private http: HttpRestService
  ) { }

  getIpAddresses () {
    return this.http.get('api/lookup/IpAddresses');
  } 

  createExternalAPIIntegration (
    integration: APIExternalAPI.ExternalAPIPayload
  ): Promise<void> {
    const endpoint = '/api/manager/ExternalApiRequest';

    return this.http.post(endpoint, integration);
  }

  getExternalAPIIntegrationsPaginated (
    paginationOptions: PaginationOptions<APIExternalAPI.ExternalAPIConfiguration>
  ): Promise<PaginatedResponse<APIExternalAPI.ExternalAPIConfiguration>> {
    const endpoint = '/api/manager/ExternalApiRequest/Paginated';

    return this.http.post(endpoint, {
      paginationOptions
    });
  }

  getAllExternalApiIntegrations (
  ): Promise<APIExternalAPI.SimpleExternalAPIConfiguration[]> {
    const endpoint = 'api/manager/ExternalApiRequest/List';

    return this.http.get(endpoint);
  }

  updateExternalAPIIntegration (
    integration: APIExternalAPI.ExternalAPIPayload
  ) {
    const endpoint = `/api/manager/ExternalApiRequest/${integration.id}`;

    return this.http.post(endpoint, integration);
  }

  deleteExternalAPIIntegration (
    integration: APIExternalAPI.ExternalAPIConfiguration
  ) {
    const endpoint = `/api/manager/ExternalApiRequest/${integration.id}`;

    return this.http.delete(endpoint);
  }

  executeExternalAPIIntegrationForApplication (
    guid: string,
    body: any
  ): Promise<APIExternalAPI.ExternalAPIResponse> {
    return this.http.post(`api/portal/applications/ExternalApiRequest/${guid}/ExecuteForApplication`, body);
  }

  executeExternalAPIIntegrationForApplicant (
    guid: string,
    body: any
  ): Promise<APIExternalAPI.ExternalAPIResponse> {
    return this.http.post(`api/portal/applications/ExternalApiRequest/${guid}/ExecuteForApplicant`, body);
  }

  executeExternalAPIIntegrationForManager (
    guid: string,
    body: any,
    endpoint: string
  ): Promise<APIExternalAPI.ExternalAPIResponse> {
    return this.http.post(`api/manager/ExternalApiRequest/${guid}${endpoint}`, body);
  }

  getOutboundApiRecords (
    paginationOptions: PaginationOptions<OutboundApiRecordFromApi>
  ): Promise<PaginatedResponse<OutboundApiRecordFromApi>> {
    const endpoint = 'api/manager/clientAPI';

    return this.http.post(endpoint, {
      paginationOptions
    });
  }

  createOutboundApiRecord (
    payload: CreateOutboundApiRecord
  ): Promise<CreateOutboundApiResponse> {
    const endpoint = 'api/manager/clientAPI/CreateClientAPIToken';

    return this.http.post(endpoint, payload);
  }

  updateOutboundApiRecord (
    clientApiTokenId: number,
    payload: UpdateOutboundApiRecord
  ) {
    const endpoint = `api/manager/clientAPI/UpdateClientAPIToken/${clientApiTokenId}`;

    return this.http.post(endpoint, payload);
  }

  generateApiKey (
    clientApiTokenId: number
  ): Promise<{ id: number; token: string }> {
    const endpoint = `api/manager/clientAPI/GenerateClientAPIToken/${clientApiTokenId}`;

    return this.http.post(endpoint, {});
  }

  toggleEnablingOutboundApiKey (
    clientApiTokenId: number,
    isEnabled: boolean
  ) {
    const endpoint = `api/manager/clientAPI/EnableClientAPIToken/${clientApiTokenId}`;

    return this.http.post(endpoint, {
      isEnabled
    });
  }

  getOutboundApiClientMetrics (): Promise<ClientOutboundMetricsFromApi> {
    const endpoint = 'api/manager/clientAPI/Metrics';

    return this.http.get(endpoint);
  }
}

