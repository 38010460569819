<div>
  <div class="mb-3">
    {{ description }}
  </div>

  <div *ngIf="ready" 
    [formGroup]="formGroup">
    <yc-checkbox-group
      [disabled]="isViewOnly"
      [filtered]="true"
      [customSearchPlaceholder]="searchByName"
      [showSelectAllOptions]="true"
      [maxFilteredResults]="0"
      [name]="'visibleCdtKeys'"
      [useCdkVirtualScroll]="true"
      [options]="cdtOptions"
      (onChange)="optionsChanged()">
    </yc-checkbox-group>
  </div>
</div>
