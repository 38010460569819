import { BasicFormForUi, FormAudience, Forms, FormType } from '@features/configure-forms/form.typing';
import { StandardFormTemplate } from '@features/platform-admin/standard-product-configuration/standard-product-configuration.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class FormsState extends BaseYcState implements Forms {
  readonly draft: BasicFormForUi[];
  readonly published: BasicFormForUi[];
  readonly forms: BasicFormForUi[];
  readonly standardFormTemplates: StandardFormTemplate[];
  readonly loaded: boolean = false;
  readonly formTypes: FormType[];
  readonly formTypeMap: {
    [x: string]: string;
  } = {};
  readonly typeAudienceMap: Record<number, FormAudience> = {};
  readonly formAndRevisionIdMap: Record<string, BasicFormForUi> = {};

  readonly allFormOptions: TypeaheadSelectOption[] = [];
  readonly allPublishedFormOptions: TypeaheadSelectOption[] = [];
  readonly applicantFormOptions: TypeaheadSelectOption[] = [];
  readonly managerFormOptions: TypeaheadSelectOption[] = [];
  readonly nominationFormOptions: TypeaheadSelectOption[] = [];

  // Segmented
  readonly myFormOptions: TypeaheadSelectOption[] = [];
  readonly myApplicantFormOptions: TypeaheadSelectOption[] = [];
  readonly myManagerFormOptions: TypeaheadSelectOption[] = [];
  readonly myDependentFormFilteringOptions: TypeaheadSelectOption[];


}
