import { SimpleGrantsAddress } from '@yourcause/common';

export enum AddressRequestStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  OnHold = 4
}

export enum FileType {
  APPLICANT_REQUEST = 1,
  APPROVAL = 2
}

export enum RejectAddressRequestReason {
  NOT_FOR_CHARITABLE_PURPOSE = 1,
  INSUFFICIENT_DOCUMENTATION = 2,
  OTHER = 3
}

export interface AddressRequestFromApi {
  addressRequestId: number;
  applicantFullName: string;
  applicantEmail: string;
  applicantFirstName: string;
  applicantLastName: string;
  applicantId: number;
  applicantAddressInfo: SimpleGrantsAddress;
  applicantPhoneNumber: string;
  nonprofitGuid: string;
  organizationId: number;
  organizationName: string;
  orgnizationImageUrl: string;
  organizationIdentification: string;
  isPrivateOrg: boolean;
  organizationAddressInfo: SimpleGrantsAddress;
  applicationId: number;
  paymentId: number;
  oldAddress: SimpleGrantsAddress;
  newAddress: SimpleGrantsAddress;
  addressRequestStatus: AddressRequestStatus;
  dateStatusUpdated: string;
  lastUpdatedBy: string;
  notes: string;
  reason: string;
  fileUrl: string;
  rejectReason: RejectAddressRequestReason;
  approveRejectComment: string;
  clientId: number;
  clientName: string;
}

export interface AddressRequestForUI extends AddressRequestFromApi {
  oldAddressString: string;
  newAddressString: string;
  applicantAddressString: string;
  orgAddressString: string;
}

export interface ToggleHoldStatusModalResponse {
  comment: string;
}

export interface AddressRequestFileFromApi {
  fileType: FileType;
  fileName: string;
  url: string;
  fileId: number;
}

export interface AddressRequestFileForUI extends AddressRequestFileFromApi {
  fileTypeTranslated: string;
  urlNeedsDecoded: boolean;
}

export interface AddressRequestApproveApi {
  addressRequestId: number;
  fileId: number;
  comment: string;
}

export interface AddressRequestRejectApi {
  addressRequestId: number;
  comment: string;
  reason: RejectAddressRequestReason;
}

export interface AddressRequestFile {
  url: string;
  fileUploadId: number;
}

export interface AddressRequestFileUpload {
  id: number;
  contentType: string;
  fileName: string;
  accessUrl: string;
  accessUrlExpiration: string;
}
