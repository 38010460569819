import { AbstractControl } from '@angular/forms';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { ValidatorMessageObj } from '@yourcause/common';

export function DataSetTotalValidator (
  comparisonValue: number,
  customErrorMessage: string,
  defaultErrorMessage: string,
  componentHelper: ComponentHelperService
) {
  return (control: AbstractControl) => {
    const val = control.value as ReferenceFieldsUI.TableResponseRowForUi[];
    const errorMessage = customErrorMessage || defaultErrorMessage;
    const errorObj: ValidatorMessageObj = {
      mustEqualTotalValue: {
        i18nKey: '',
        defaultValue: '',
        errorMessage
      }
    };

    const sumOfFields = componentHelper.getFieldGroupTotal(val);

    const valid = sumOfFields === comparisonValue;

    return valid ? null : errorObj;
  };

}
