import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GcFlyoutService } from '@core/services/gc-flyout.service';
import { AddEditWorkflow, SimpleWorkflowLevelUser, SimpleWorkflowUser, ToggleWorkflowLevelResponse, UsersAndRoutesLevel, Workflow, WorkflowDetailForUi, WorkflowGrantProgram, WorkflowLevel, WorkflowLevelAudience, WorkflowLevelPermissions, WorkflowLevelUser, WorkflowManager, WorkflowManagerActions } from '@core/typings/workflow.typing';
import { AudienceService } from '@features/audience/audience.service';
import { UserService } from '@features/users/user.service';
import { WorkflowResources } from '@features/workflow/workflow.resources';
import { DragItem, SimpleStringMap } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { FlyoutService } from '@yourcause/common/flyout';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { difference } from 'lodash';
import { BulkWflAudienceModalResponse } from './bulk-workflow-level-audience-modal/bulk-workflow-level-audience-modal.component';
import { WorkflowDetailFlyoutComponent } from './workflow-detail-flyout/workflow-detail-flyout.component';
import { WorkflowState } from './workflow.state';
export const Workflows_Table_Key = 'WORKFLOWS_TABLE';

@AttachYCState(WorkflowState)
@Injectable({ providedIn: 'root' })
export class WorkflowService extends BaseYCService<WorkflowState> {

   constructor (
    private logger: LogService,
    private workflowResources: WorkflowResources,
    private i18n: I18nService,
    private notifier: NotifierService,
    private userService: UserService,
    private arrayHelper: ArrayHelpersService,
    private flyoutService: FlyoutService,
    private gcFlyoutService: GcFlyoutService,
    private router: Router,
    private confirmAndTakeAction: ConfirmAndTakeActionService,
    private audienceService: AudienceService
  ) {
    super();
  }

  get workflows () {
    return this.get('workflows');
  }

  get workflowMap () {
    return this.get('workflowMap');
  }

  get myWorkflowOptions () {
    return this.get('myWorkflowOptions');
  }

  get myWorkflowLevelOptions () {
    return this.get('myWorkflowLevelOptions');
  }

  get myWorkflowManagerRolesMap () {
    return this.get('myWorkflowManagerRolesMap');
  }

  get allWfManagerActions () {
    return Object.keys(WorkflowManagerActions).filter((key) => {
      return isNaN(+key);
    }).map((key) => {
      return key as keyof typeof WorkflowManagerActions;
    }).map((key) =>  {
      return WorkflowManagerActions[key];
    });
  }

  get standardWfManagerActions () {
    return this.allWfManagerActions.filter((val) => {
      return !this.draftWfManagerActions.includes(val);
    });
  }

  get draftWfManagerActions () {
    return [
      WorkflowManagerActions.ViewCommunications
    ];
  }

  get workflowUsersMap () {
    return this.get('workflowUsersMap');
  }

  async resolveWorkflowDetail (id: number|string) {
    if (id !== 'new') {
      await this.getAndSetWorkflowMap(id as number, true);
    }
  }

  setWorkflowMap (map: {
    [w: string]: WorkflowDetailForUi;
  }) {
    this.set('workflowMap', map);
  }

  setMapForNewWorkflow (
    name: string,
    description: string
  ) {
    const newWorkflow: WorkflowDetailForUi = {
      id: null,
      name,
      description,
      levels: [],
      hasApplications: false,
      active: true,
      workflowLevelManagers: [],
      grantPrograms: []
    };
    this.setWorkflowMap({
      ...this.get('workflowMap'),
      new: newWorkflow
    });
  }

  async resetWorkflows () {
    this.set('workflows', undefined);
    await this.getWorkflows();
  }

  async getWorkflows (): Promise<Workflow[]> {
    if (!this.workflows) {
      const workflows = await this.workflowResources.getWorkflows();
      workflows.forEach((workflow) => {
        workflow.workflowLevelManagers.forEach((manager) => {
          manager.fullName = manager.firstName + ' ' + manager.lastName;
        });
      });

      this.set('workflows', workflows);
      await this.setMyWorkflowOptions();

      return workflows;
    }

    return this.workflows;
  }

  async getWorkflowLevelUsers (
    workflowId: number,
    workflowLevelId: number
  ): Promise<WorkflowLevelUser[]> {
    await this.getAndSetWorkflowMap(workflowId);
    const level = this.findWorkflowLevel(
      this.workflowMap[workflowId],
      workflowLevelId
    );

    if (level) {
      level.workflowLevelUsers.forEach((user) => {
        user.fullName = `${user.firstName} ${user.lastName}`;
      });
    }

    return level?.workflowLevelUsers || [];
  }

  findWorkflowLevel (
    workflow: WorkflowDetailForUi|Workflow,
    workflowLevelId: number
  ) {
    let foundLevel: WorkflowLevel;
    workflow.levels.forEach((level) => {
      if (level.id === workflowLevelId) {
        foundLevel = level;
      }
      level.subLevels.forEach((subLevel) => {
        if (subLevel.id === workflowLevelId) {
          foundLevel = subLevel;
        }
      });
    });

    return foundLevel;
  }

  async setMyWorkflowOptions () {
    const [
      myWorkflows,
      myWorkflowLevels
    ] = await Promise.all([
      this.workflowResources.getWorkflows(true),
      this.workflowResources.getMyWorkflowLevels()
    ]);
    const myWorkflowOptions = myWorkflows.map((workflow) => {
      return {
        label: workflow.name,
        value: workflow.id
      };
    });
    const myWorkflowLevelOptions: TypeaheadSelectOption[] = [];
    this.workflows.forEach((workflow) => {
      workflow.levels.forEach((level) => {
        if (myWorkflowLevels.includes(level.id)) {
          myWorkflowLevelOptions.push({
            label: level.name,
            value: level.id
          });
        }
        level.subLevels.forEach((sub) => {
          if (myWorkflowLevels.includes(sub.id)) {
            myWorkflowLevelOptions.push({
              label: sub.name,
              value: sub.id
            });
          }
        });
      });
    });
    this.set(
      'myWorkflowOptions',
      this.arrayHelper.sort(myWorkflowOptions, 'label')
    );
    this.set(
      'myWorkflowLevelOptions',
      this.arrayHelper.sort(myWorkflowLevelOptions, 'label')
    );
  }

  async getAndSetWorkflowMap (id: number, force = false) {
    if (!this.workflowMap[id] || force) {
      const detailFromApi = await this.workflowResources.getWorkflow(id, true);
      this.arrayHelper.sort(detailFromApi.levels, 'sortOrder');
      const grantPrograms: WorkflowGrantProgram[] = [];
      detailFromApi.levels.forEach((level) => {
        let subIsDefaultOnProgram = false;
        this.addToProgramsArray(level.grantPrograms, grantPrograms);
        level.subLevels.forEach((sub) => {
          if (sub.isDefaultLevelOnProgram) {
            subIsDefaultOnProgram = true;
          }
          this.addToProgramsArray(sub.grantPrograms, grantPrograms);
        });
        level.isDefaultLevelOnProgram = level.isDefaultLevelOnProgram ||
          subIsDefaultOnProgram;
        this.arrayHelper.sort(level.subLevels, 'sortOrder');
      });
      const adaptedWorkflow: WorkflowDetailForUi = {
        ...detailFromApi,
        grantPrograms: this.arrayHelper.sort(grantPrograms, 'name'),
        workflowLevelManagers: this.arrayHelper.sort(
          detailFromApi.workflowLevelManagers.map((manager) => {
            manager.fullName = manager.firstName + ' ' + manager.lastName;

            return manager;
          }),
          'fullName'
        )
      };
      this.set('workflowMap', {
        ...this.workflowMap,
        [id]: adaptedWorkflow
      });
    }

    return this.workflowMap[id];
  }

  addToProgramsArray (
    programsInLevel: WorkflowGrantProgram[],
    programs: WorkflowGrantProgram[]
  ) {
    const names = programs.map((prog) => prog.name);
    programsInLevel.forEach((program) => {
      if (!names.includes(program.name)) {
        programs.push(program);
      }
    });
  }

  async resetWorkflowDetail (id: number) {
    this.set('workflowMap', {
      ...this.workflowMap,
      [id]: undefined
    });
    await this.getAndSetWorkflowMap(id);
  }

  async setMyWorkflowManagerRolesMap () {
    if (!this.myWorkflowManagerRolesMap) {
      const response = await this.workflowResources.getMyWorkflowManagerRoles();
      const map: SimpleStringMap<WorkflowManagerActions[]> = {};
      response.forEach((item) => {
        map[item.workflowId] = item.workflowActions;
      });
      this.set('myWorkflowManagerRolesMap', map);
    }
  }

  async resetMyWorkflowManagerRolesMap () {
    this.set('myWorkflowManagerRolesMap', undefined);
    await this.setMyWorkflowManagerRolesMap();
  }

  async handleSaveWorkflowLevels (payload: AddEditWorkflow) {
    try {
      const id = await this.workflowResources.saveWorkflow(payload);
      this.notifier.success(this.i18n.translate(
        'WORKFLOW:textSuccessSavingWorkflowLevel',
        {},
        'Successfully saved the workflow level'
      ));
      await this.resetWorkflows();
      await this.resetWorkflowDetail(id);

      return id;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'WORKFLOW:textErrorSavingWorkflowLevel',
        {},
        'There was an error saving the workflow level'
      ));

      return null;
    }
  }

  async handleDeleteWorkflow (id: number) {
    try {
      await this.workflowResources.deleteWorkflow(id);
      this.notifier.success(this.i18n.translate(
        'WORKFLOW:textSuccessfullyDeletedWorkflow',
        {},
        'Successfully deleted workflow'
      ));
      await this.resetWorkflows();
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'WORKFLOW:textErrorDeletingWorkflow',
        {},
        'There was an error deleting the workflow'
      ));
    }
  }

  async handleDisableEnableWorkflowLevel (payload: ToggleWorkflowLevelResponse) {
    try {
      if (payload.disable) {
        await this.workflowResources.disableWorkflowLevel(payload.workflowLevelId);
      } else {
        await this.workflowResources.enableWorkflowLevel(payload.workflowLevelId);
      }

      this.notifier.success(this.i18n.translate(
        payload.disable ? 'WORKFLOW:textSuccessfullyDisableWorkflowLevel' : 'WORKFLOW:textSuccessfullyEnableWorkflowLevel',
        {},
        payload.disable ? 'Successfully disabled workflow level' : 'Successfully enabled workflow level'
      ));
    } catch (error) {
      this.logger.error(error);

      this.notifier.error(this.i18n.translate(
        payload.disable ? 'WORKFLOW:textErrorDisableWorkflowLevel' : 'WORKFLOW:textErrorEnableWorkflowLevel',
        {},
        payload.disable ? 'There was an error disabling the workflow level' : 'There was an error enabling the workflow level'
      ));
    }
  }

  async handleUpdateWorkflowDetails (
    id: number,
    name: string,
    description: string
  ) {
    try {
      await this.workflowResources.updateWorkflowDetails(
        id,
        name,
        description
      );
      this.notifier.success(this.i18n.translate(
        'WORKFLOW:textSuccessfullyUpdatedWorkflowDetails',
        {},
        'Successfully updated workflow details'
      ));
      await this.resetWorkflows();
      await this.resetWorkflowDetail(id);
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'WORKFLOW:textErrorUpdatingWorkflowDetails',
        {},
        'There was an error updating workflow details'
      ));
    }
  }

  async updateWorkflowLevelUsers (
    workflowId: number,
    workflowLevelId: number,
    workflowLevelAudiences: WorkflowLevelAudience[],
    individualWorkflowLevelUsers: SimpleWorkflowLevelUser[]
  ) {
    await this.workflowResources.updateWorkflowLevelUsers(
      workflowId,
      workflowLevelId,
      workflowLevelAudiences,
      individualWorkflowLevelUsers
    );
    await Promise.all([
      this.audienceService.resetAllAudiences(),
      this.resetWorkflows(),
      this.resetWorkflowDetail(workflowId),
      this.userService.resetAllUsers()
    ]);
    this.audienceService.resetAudienceDetailMap();
  }

  async handleUpdateWorkflowLevelUsers (
    workflowId: number,
    workflowLevelId: number,
    workflowLevelAudiences: WorkflowLevelAudience[],
    individualWorkflowLevelUsers: SimpleWorkflowLevelUser[]
  ) {
    const response = await this.confirmAndTakeAction.genericTakeAction(
      () => this.updateWorkflowLevelUsers(
        workflowId,
        workflowLevelId,
        workflowLevelAudiences,
        individualWorkflowLevelUsers
      ),
      this.i18n.translate(
        'WORKFLOW:textSuccessfullyUpdatedWorkflowLevelUsers',
        {},
        'Successfully updated workflow level users'
      ),
      this.i18n.translate(
        'WORKFLOW:textErrorUpdatingWorkflowLevelUsers',
        {},
        'There was an error updating workflow level users'
      )
    );

    return response.passed;
  }

  async handleRouteChanges (
    workflowId: number,
    level: UsersAndRoutesLevel,
    routes: DragItem[]
  ) {
    const existingRouteIds = level.routes.map((route) => route.canRouteToWorkflowLevelId);
    const newRoutes = routes.map((route) => route.context.id);
    const routesToAdd = difference(newRoutes, existingRouteIds);
    const routesToRemove = difference(existingRouteIds, newRoutes);

    try {
      await Promise.all(routesToAdd.map(async (route) => {
        await this.workflowResources.addLevelToWorkflowLevelRoutes(
          workflowId,
          level.id,
          route
        );
      }));
      await Promise.all(routesToRemove.map(async (route) => {
        await this.workflowResources.deleteLevelFromWorkflowLevelRoutes(
          workflowId,
          level.id,
          route
        );
      }));
      await this.resetWorkflowDetail(workflowId);
      this.notifier.success(this.i18n.translate(
        'WORKFLOW:textSuccessfullyUpdatedWorkflowLevelRoutes',
        {},
        'Successfully updated workflow level routes'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'WORKFLOW:textErrorUpdatingWorkflowLevelRoutes',
        {},
        'There was an error updating workflow level routes'
      ));
    }
  }

  async handleAddWorkflowManager (
    workflowId: number,
    manager: WorkflowManager,
    isNew = false
  ) {
    try {
      await this.workflowResources.addOrUpdateWorkflowManager({
        workflowId,
        clientUserId: manager.clientUserId,
        workflowLevelActions: manager.workflowActions
      });
      await this.resetWorkflows();
      await this.resetWorkflowDetail(workflowId);
      if (manager.clientUserId === this.userService.currentUser.id) {
        await this.resetMyWorkflowManagerRolesMap();
      }
      this.notifier.success(this.i18n.translate(
        isNew ?
          'WORKFLOW:textSuccessfullyAddedWorkflowManager' :
          'WORKFLOW:textSuccessfullyUpdatedWorkflowManager',
        {},
        isNew ?
          'Successfully added the workflow manager' :
          'Successfully updated the workflow manager'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        isNew ?
          'WORKFLOW:textErrorAddingWorkflowManager' :
          'WORKFLOW:textErrorUpdatingWorkflowManager',
        {},
        isNew ?
          'There was an error adding the workflow manager' :
          'There was an error updating the workflow manager'
      ));
    }
  }

  async handleRemoveWorkflowManager (
    workflowId: number,
    managerId: number
  ) {
    try {
      await this.workflowResources.removeWorkflowManager(
        workflowId,
        managerId
      );
      if (managerId === this.userService.currentUser.id) {
        await this.resetMyWorkflowManagerRolesMap();
      }
      this.notifier.success(this.i18n.translate(
        'WORKFLOW:textSuccessfullyRemovedWorkflowManager',
        {},
        'Successfully removed the workflow manager'
      ));
      await this.resetWorkflows();
      await this.resetWorkflowDetail(workflowId);
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'WORKFLOW:textErrorRemovingWorkflowManager',
        {},
        'There was an error removing the workflow manager'
      ));
    }
  }

  getWorkflowForEdit (id: number): WorkflowDetailForUi {
    // this filters out the potential disabled routes so they are not visible in the routing modal until enabled again.
    const workflow = this.workflowMap[id];
    if (workflow) {
      return {
        ...workflow,
        levels: workflow.levels.map((level) => {
          level.routes = level.routes.filter((route) => !route.disabled);
          level.subLevels = level.subLevels.map((sub) => {
            sub.routes = sub.routes.filter((route) => !route.disabled);

            return sub;
          });

          return level;
        })
      };
    } else {
      return undefined;
    }
  }

  getLevelOptions (workflowId: number) {
    const workflow = this.workflows.find((flow) => {
      return flow.id === workflowId;
    });
    const workflowLevelOptions: TypeaheadSelectOption[] = [];
    if (workflow) {
      workflow.levels.forEach((level) => {
        if (!level.disabled) {
          workflowLevelOptions.push({
            label: level.name,
            value: level.id
          });
        }
        level.subLevels.forEach((subLevel) => {
          if (!subLevel.disabled) {
            workflowLevelOptions.push({
              label: subLevel.name,
              value: subLevel.id
            });
          }
        });
      });
    }

    return this.arrayHelper.sort(workflowLevelOptions, 'label');
  }

  getWorkflowUserPermissionDisplay (
    clientUserId: number,
    workflowLevelAccessType: WorkflowLevelPermissions,
    workflowManagers: WorkflowManager[]
  ) {
    const isManager = workflowManagers.some((user) => {
      return user.clientUserId === clientUserId;
    });
    if (isManager) {
      return this.i18n.translate(
        'WORKFLOW:textWorkflowManager',
        {},
        'Workflow manager'
      );
    }
    if (workflowLevelAccessType === WorkflowLevelPermissions.LEVEL_ONLY) {
      return this.i18n.translate(
        'WORKFLOW:textWorkflowLevelOnly',
        {},
        'Workflow level only '
      );
    } else if (workflowLevelAccessType === WorkflowLevelPermissions.PASSTHROUGH) {
      return this.i18n.translate(
        'WORKFLOW:textWorkflowPassthrough',
        {},
        'Workflow passthrough'
      );
    }

    return '';
  }

  getWorkflowForms (programId: number) {
    return this.workflowResources.getWorkflowForms(programId);
  }

  /**
   * Routes to the workflow detail
   *
   * @param workflowId: Workflow ID to navigate to
   */
  navigateToWorkflowDetail (workflowId: number|string) {
    if (!!this.gcFlyoutService.currentIdForFlyout) {
      this.flyoutService.closeFlyout();
    }
    this.router.navigate([`/management/program-setup/workflows/detail/${workflowId}/structure`]);
  }

  /**
   * Get all Levels for a Workflow
   *
   * @param levels: Workflow's Levels
   * @returns an array of all levels, including sub levels
   */
  getAllLevels (levels: WorkflowLevel[]) {
    const allLevels: UsersAndRoutesLevel[] = [];
    const deactivatedUsers = this.userService.allUsers.filter((user) => {
      return user.isDeactivated;
    }).map((user) => user.id);
    levels.forEach((level) => {
      level.workflowLevelUsers = this.getFilteredUsers(level.workflowLevelUsers, deactivatedUsers) as WorkflowLevelUser[];
      allLevels.push({
        ...level,
        parentName: ''
      });

      level.subLevels.forEach((sub) => {
        sub.workflowLevelUsers = this.getFilteredUsers(sub.workflowLevelUsers, deactivatedUsers) as WorkflowLevelUser[];
        allLevels.push({
          ...sub,
          parentName: level.name
        });
      });
    });

    return allLevels;
  }

  /**
   * Set Workflow Users Map
   */
  async setWorkflowUsersMap () {
    const map: Record<number, SimpleWorkflowUser[]> = {};
    await this.userService.setAllUsers();
    const deactivatedUsers = this.userService.allUsers.filter((user) => {
      return user.isDeactivated;
    }).map((user) => user.id);
    this.workflows.forEach((workflow: Workflow) => {
      const managers = this.getFilteredUsers(workflow.workflowLevelManagers, deactivatedUsers);
      map[workflow.id] = managers.map((manager) => {
        return {
          id: manager.clientUserId,
          name: manager.firstName + ' ' + manager.lastName,
          permissions: [WorkflowLevelPermissions.MANAGER],
          workflowLevelNames: []
        };
      });
      workflow.levels.forEach((level) => {
        const workflowLevelUsers = this.getFilteredUsers(
          level.workflowLevelUsers,
          deactivatedUsers
        );
        this.addUsersToMap(
          workflow,
          map,
          workflowLevelUsers as WorkflowLevelUser[],
          level
        );
        level.subLevels.forEach((sub) => {
          const subLevelUsers = this.getFilteredUsers(
            sub.workflowLevelUsers,
            deactivatedUsers
          );
          this.addUsersToMap(
            workflow,
            map,
            subLevelUsers as WorkflowLevelUser[],
            sub
          );
        });
      });
    });
    Object.keys(map).forEach((workflowId) => {
      map[+workflowId] = this.arrayHelper.sort(map[+workflowId], 'name');
    });

    this.set('workflowUsersMap', map);
  }

  /**
   * Get Filtered Users for Workflow
   *
   * @param users: Users to filter
   * @param deactivatedUsers: Deactivated Users
   * @returns the filtered users
   */
  getFilteredUsers (
    users: (WorkflowManager|WorkflowLevelUser)[],
    deactivatedUsers: number[]
  ) {
    return users.filter((user) => {
      return !deactivatedUsers.includes(user.clientUserId);
    });
  }

  /**
   * Add Users to the Workflow Map
   *
   * @param workflow: Workflow
   * @param map: Map of Users
   * @param users: Users Array
   * @param level: Workflow Level
   */
  addUsersToMap (
    workflow: Workflow,
    map: SimpleStringMap<SimpleWorkflowUser[]>,
    users: WorkflowLevelUser[],
    level: WorkflowLevel
  ) {
    const workflowUsers = users.filter((user) => {
      let alreadyExists = false;
      (map[workflow.id] || []).forEach((existingUser) => {
        if (existingUser.id === user.clientUserId) {
          alreadyExists = true;
          existingUser.workflowLevelNames.push(level.name);
          if (!existingUser.permissions.includes(user.workflowLevelAccessType)) {
            existingUser.permissions.push(user.workflowLevelAccessType);
          }
        }
      });

      return !alreadyExists;
    }).map((user) => {
      return {
        id: user.clientUserId,
        name: user.firstName + ' ' + user.lastName,
        permissions: [user.workflowLevelAccessType],
        workflowLevelNames: [level.name]
      };
    });
    map[workflow.id] = (map[workflow.id] || []).concat(workflowUsers);
  }

  /**
   * Opens the workflow flyout for the given record
   *
   * @param workflow: Workflow to open flyout for
   */
  async openWorkflowFlyout (workflow: Workflow): Promise<void> {
    this.gcFlyoutService.setInfoForFlyout(workflow, Workflows_Table_Key, 'id');
    const only1Record = this.gcFlyoutService.idsForFlyout.length === 1;
    await this.flyoutService.openFlyout(
      WorkflowDetailFlyoutComponent,
      {
        defaultWidth: 600,
        showIterator: !only1Record
      },
      this.gcFlyoutService.onNextFlyoutRecord,
      this.gcFlyoutService.onPreviousFlyoutRecord,
      this.prepareWorkflowFlyout,
      this.gcFlyoutService.onInitialFlyoutRecord
    );
  }

  /**
   * Prepare the Workflow Flyout
   */
  prepareWorkflowFlyout = async (workflowId: number|string) => {
    await Promise.all([
      this.setWorkflowUsersMap(),
      this.getAndSetWorkflowMap(workflowId as number)
    ]);
  };

  /**
   * Handles the bulk workflow level audience updates
   *
   * @param response: Modal Response
   * @param workflowId: Workflow ID
   */
  async doBulkWflAudienceUpdates (
    response: BulkWflAudienceModalResponse,
    workflowId: number
  ) {
    await this.workflowResources.saveBulkWflAudienceUpdates(response.workflowLevelAudiences);
    await this.resetWorkflowDetail(workflowId);
  }

  async handleBulkWflAudienceModal (
    response: BulkWflAudienceModalResponse,
    workflowId: number
  ) {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doBulkWflAudienceUpdates(response, workflowId),
      this.i18n.translate(
        'common:textSuccessUpdateUsers',
        {},
        'Successfully updated the users'
      ),
      this.i18n.translate(
        'common:textErrorUpdatingUsers',
        {},
        'There was an error updating the users'
      )
    );
  }
}


