import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { APIResultData, PaginationOptions } from '@yourcause/common';
type PaginatedResponse = APIResultData<ReferenceFieldAPI.ReferenceFieldPaginatedModel>;

@Injectable({ providedIn: 'root' })
export class FormFieldResources {
  apiBase = '/api/manager/forms/ReferenceFields';

  constructor (
    private http: HttpRestService,
    private portal: PortalDeterminationService
  ) { }

  getReferenceFieldResponses (
    applicationId: number,
    applicationFormId: number
  ): Promise<ReferenceFieldAPI.ApplicationRefFieldResponse[]> {
    const endpoint = `api/${this.portal.isManager ? 'manager' : 'portal'}/applications/${applicationId}/ApplicationForms/${applicationFormId}/ReferenceFields`;

    return this.http.get(endpoint);
  }

  getReferenceFieldsByFormRevisionId (
    formRevisionId: number
  ): Promise<ReferenceFieldAPI.ReferenceFieldDisplayModel[]> {
    const endpoint = `api/portal/applications/FormRevisions/${formRevisionId}/ReferenceFields`;

    return this.http.get(endpoint);
  }

  searchReferenceFields (
    paginationOptions: PaginationOptions<ReferenceFieldAPI.ReferenceFieldDisplayModel>,
    formIds?: number[]
  ): Promise<PaginatedResponse> {
    const endpoint = this.apiBase + '/Paginated';

    return this.http.post(endpoint, {
      paginationOptions,
      formIds
    });
  }

  getAllReferenceFields (): Promise<ReferenceFieldAPI.ReferenceFieldDisplayModel[]> {
    const endpoint = 'api/manager/forms/ReferenceFields';

    return this.http.get(endpoint);
  }

  createOrUpdateField (
    referenceFieldId: number,
    payload: ReferenceFieldAPI.CreateUpdateReferenceField
  ): Promise<ReferenceFieldAPI.ReferenceFieldDisplayModel> {
    const endpoint = referenceFieldId ?
      `${this.apiBase}/${referenceFieldId}` :
      this.apiBase;

    return this.http.post(endpoint, payload);
  }

  removeReferenceField (
    fieldId: number
  ) {
    return this.http.delete<void>(`${this.apiBase}/${fieldId}`);
  }

  bulkRemoveReferenceFields (
    referenceFieldIds: number[]
  ) {
    const endpoint = 'api/manager/forms/ReferenceFields/BulkDelete';

    return this.http.post(endpoint, {
      referenceFieldIds
    });
  }

  exportReferenceFields (
    ids: number[]
  ): Promise<ReferenceFieldAPI.ExportReferenceField[]> {
    return this.http.post('api/manager/forms/export/ReferenceFields', {
      ids
    });
  }

  importReferenceFields (referenceFields: ReferenceFieldAPI.ExportReferenceField[]) {
    const endpoint = 'api/manager/forms/import/ReferenceFields';

    return this.http.post(endpoint, {
      referenceFields
    });
  }

  mergeFields (payload: ReferenceFieldAPI.MergeFormFieldsApi) {
    const endpoint = 'api/manager/forms/MergeReferenceFields';

    return this.http.post(endpoint, payload);
  }

  bulkCreateReferenceFields (
    fields: ReferenceFieldAPI.BulkCreateReferenceField[]
  ) {
    const endpoint = 'api/manager/forms/BulkInsertReferenceFields';

    return this.http.post(endpoint, fields);
  }

  getReferenceFieldDetail (
    referenceFieldId: number
  ): Promise<ReferenceFieldAPI.ReferenceFieldDetail> {
    const endpoint = `api/manager/forms/ReferenceFields/Details/${referenceFieldId}`;

    return this.http.get(endpoint);
  }

  getApplicationResponsesForMerge (
    referenceFieldId1: number,
    referenceFieldId2: number
  ): Promise<ReferenceFieldAPI.ApplicationResponse[]> {
    const endpoint = 'api/manager/forms/GetApplicationResponsesForReferenceFields';

    return this.http.post(endpoint, {
      referenceFieldId1,
      referenceFieldId2
    });
  }

  getCanUpdateRefFieldToSingleResponse (
    referenceFieldId: number
  ) {
    const endpoint = `api/manager/forms/CanSimplyUpdateReferenceFieldToSingleResponse/${referenceFieldId}`;

    return this.http.get(endpoint);
  }

  checkMergeForConflicts (
    standardProductReferenceFieldId: number,
    idsToMerge: number[]
  ) {
    const endpoint = 'api/manager/forms/CheckReferenceFieldMergeForConflicts';

    return this.http.post(endpoint, {
      standardProductReferenceFieldId,
      referenceFieldList: idsToMerge.map((referenceFieldId) => {
        return {
          referenceFieldId
        };
      })
    });
  }

  mergeWithStandardProductField (
    standardProductReferenceFieldId: number,
    idsToMerge: number[]
  ) {
    const endpoint = 'api/manager/forms/MergeToStandardProductField';

    return this.http.post(endpoint, {
      standardProductReferenceFieldId,
      referenceFieldList: idsToMerge.map((referenceFieldId) => {
        return {
          referenceFieldId
        };
      })
    });
  }

  validateMultiResponseToSingleResponseFieldConversion (
    referenceFieldId: number
  ): Promise<ReferenceFieldAPI.ConvertFieldMultiToSingleValidationResponse> {
    const endpoint = `api/manager/forms/CanUpdateReferenceFieldToSingleResponse/${referenceFieldId}`;

    return this.http.get(endpoint);
  }

  validateTextToNumberFieldConversion (
    referenceFieldId: number
  ): Promise<ReferenceFieldAPI.ConvertFieldTypeValidationResponse> {
    const endpoint = `api/manager/forms/CanConvertTextFieldToNumeric/${referenceFieldId}`;

    return this.http.get(endpoint);
  }

  validateNumberToTextFieldConversion (
    referenceFieldId: number
  ): Promise<ReferenceFieldAPI.ConvertFieldTypeValidationResponse> {
    const endpoint = `api/manager/forms/CanConvertNumericFieldToText/${referenceFieldId}`;

    return this.http.get(endpoint);
  }

  validateTextToDateFieldConversion (
    referenceFieldId: number
  ): Promise<ReferenceFieldAPI.ConvertTextToDateValidationResponse> {
    const endpoint = `api/manager/forms/CanConvertTextFieldToDate/${referenceFieldId}`;

    return this.http.get(endpoint);
  }

  validateDateToTextFieldConversion (
    referenceFieldId: number
  ): Promise<ReferenceFieldAPI.ConvertFieldTypeValidationResponse> {
    const endpoint = `api/manager/forms/CanConvertDateFieldToText/${referenceFieldId}`;

    return this.http.get(endpoint);
  }

  validateCurrencyToNumberFieldConversion (): Promise<ReferenceFieldAPI.ConvertFieldTypeValidationResponse> {
    return new Promise((resolve) => {
      resolve({
        canConvert: true,
        affectedWflAutomationRules: [],
        affectedAdHocFilters: []
      });
    });
  }

  validateFieldEncryption (
    referenceFieldId: number
  ): Promise<ReferenceFieldAPI.ConvertFieldToEncryptedAffectedAreas> {
    const endpoint = `api/manager/forms/referenceFields/${referenceFieldId}/CanConvertFieldToEncrypted`;

    return this.http.get(endpoint);
  }

  validateNumberToCurrencyFieldConversion (
    referenceFieldId: number
  ): Promise<ReferenceFieldAPI.ConvertNumberToCurrencyValidationResponse> {
    const endpoint = `api/manager/forms/ChangeNumberReferenceFieldToCurrencyGetAffectedForms/${referenceFieldId}`;

    return this.http.get(endpoint);
  }

  convertNumberFieldToText (referenceFieldId: number) {
    const endpoint = `api/manager/forms/ChangeNumberReferenceFieldToText/${referenceFieldId}`;
    // In the future we may want to support other text type fields like textArea, for now we are always sending textField

    return this.http.post(endpoint, {
      textType: ReferenceFieldAPI.TextFieldConversionType.TEXT_FIELD
    });
  }

  convertTextFieldToNumber (referenceFieldId: number) {
    const endpoint = `api/manager/forms/ChangeTextReferenceFieldToNumber/${referenceFieldId}`;

    return this.http.post(endpoint, {});
  }

  convertTextFieldToDate (referenceFieldId: number) {
    const endpoint = `api/manager/forms/ChangeTextReferenceFieldToDate/${referenceFieldId}`;

    return this.http.post(endpoint, {});
  }

  convertDateFieldToText (referenceFieldId: number) {
    const endpoint = `api/manager/forms/ChangeDateReferenceFieldToText/${referenceFieldId}`;

    return this.http.post(endpoint, {
      textType: ReferenceFieldAPI.TextFieldConversionType.TEXT_FIELD
    });
  }

  convertMultiResponseFieldToSingleResponse (referenceFieldId: number) {
    const endpoint = `api/manager/forms/ChangeMultiResponseReferenceFieldToSingleResponse/${referenceFieldId}`;

    return this.http.post(endpoint, {});
  }

  convertCurrencyFieldToNumber (referenceFieldId: number) {
    const endpoint = `api/manager/forms/ChangeCurrencyReferenceFieldToNumber/${referenceFieldId}`;

    return this.http.post(endpoint, {});
  }

  convertNumberFieldToCurrency (referenceFieldId: number, conversionCurrency: string) {
    const endpoint = `api/manager/forms/ChangeNumberReferenceFieldToCurrency`;

    return this.http.post(endpoint, {
      referenceFieldId,
      currencyType: conversionCurrency
    });
  }

  encryptFieldRetroactively (referenceFieldId: number) {
    const endpoint = `api/manager/forms/referenceFields/${referenceFieldId}/EncryptExistingField`;

    return this.http.post(endpoint, {});
  }
}
