<yc-currency
  [amount]="data.amountForControl"
  [label]="label"
  [description]="description"
  [isRequired]="isRequired"
  [hideLabel]="hideLabel"
  [disabled]="isDisabled"
  [currency]="defaultCurrency"
  [errors]="control.errors"
  [isDirtyOverride]="control.dirty"
  [canToggle]="false"
  [currencyLocked]="true"
  [formattingData]="formattingData"
  (amountChange)="onAmountChange($event)">
</yc-currency>