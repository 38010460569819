import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { BaseTranslation, BulkTranslationsUpdate, TranslationKeyValue, TranslationsByLang } from '@core/typings/translation.typing';
import { PaginatedResponse, PaginationOptions } from '@yourcause/common';
export type GetTranslationsFunctionNames = 'getFormTranslations'|'getDataTableTranslations'|'getProgramTranslations'|'getAutomationTranslations'|'getInKindTranslations';
export type GetTranslationsDownloadFunctionNames = 'downloadFormTranslations'|'downloadDataTableTranslations'|'downloadProgramTranslations'|'downloadInKindTranslations'|'downloadAutomationTranslations';
export type BulkUpdateFunctionNames = 'bulkUpdateFormTranslations'|'bulkUpdateDataTableTranslations'|'bulkUpdateProgramTranslations'|'bulkUpdateAutomationTranslations'|'bulkUpdateInKindTranslations';
export type MachineTranslateSingleFunctionNames = 'machineTranslateForFormsSingle'|'machineTranslateForDataTablesSingle'|'machineTranslateForProgramsSingle'|'machineTranslateForAutomationSingle'|'machineTranslateForInKindSingle';
export type MachineTranslateBulkFunctionNames = 'machineTranslateForFormsBulk'|'machineTranslateForDataTablesBulk'|'machineTranslateForProgramsBulk'|'machineTranslateForAutomationBulk'|'machineTranslateForInKindBulk';
export type GetTranslationsByLanguageFunctionNames = 'getFormTranslationsByLanguageApplicant'|'getFormTranslationsByLanguage';

@Injectable({ providedIn: 'root' })
export class TranslationResources {

  constructor (
    private httpRestService: HttpRestService,
    private portal: PortalDeterminationService
  ) { }

  getTranslationsForView (): Promise<TranslationKeyValue[]> {
    const endpoint = `api/${
      this.portal.isManager ? 'manager' : 'portal'
    }/programs/Translations`;

    return this.httpRestService.get(endpoint);
  }

  // ** Start Form Specific ** //

  syncFormTranslations (
    formId: number,
    formAttributes: string[],
    richTextAttributes: string[]
  ) {
    const endpoint = 'api/manager/forms/SyncFormTranslations';

    return this.httpRestService.post(endpoint, {
      formId,
      formAttributes,
      richTextAttributes
    });
  }

  getFormTranslations (
    paginationOptions: PaginationOptions<BaseTranslation>,
    formIds: number[]|string[],
    searchTerm: string,
    language: string
  ): Promise<PaginatedResponse<BaseTranslation>> {
    const endpoint = 'api/manager/forms/GetFormTranslations';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      formIds,
      searchTerm,
      language
    });
  }

  bulkUpdateFormTranslations (
    translationInfo: BulkTranslationsUpdate[]
  ) {
    const endpoint = 'api/manager/forms/BulkUpdateFormTranslations';

    return this.httpRestService.post(endpoint, {
      translationInfo
    });
  }

  getFormTranslationsByLanguage (
    formId: number,
    language: string
  ): Promise<TranslationsByLang[]> {
    const endpoint = 'api/manager/forms/GetTranslationsForFormAndLanguage';

    return this.httpRestService.post(endpoint, {
      formId,
      language
    });
  }

  getFormTranslationsByLanguageApplicant (
    formId: number,
    language: string
  ): Promise<TranslationsByLang[]> {
    const endpoint = 'api/portal/applicant/GetTranslationsForFormAndLanguage';

    return this.httpRestService.post(endpoint, {
      formId,
      language
    });
  }

  machineTranslateForFormsSingle (
    languageKeyId: number,
    language: string,
    defaultText: string
  ) {
    const endpoint = 'api/manager/forms/MachineTranslateById';

    return this.httpRestService.post(endpoint, {
      languageKeyId,
      language,
      defaultText
    });
  }

  machineTranslateForFormsBulk (
    languages: string[]
  ) {
    const endpoint = 'api/manager/forms/BulkMachineTranslate';

    return this.httpRestService.post(endpoint, {
      languages
    });
  }

  downloadFormTranslations (
    paginationOptions: PaginationOptions<BaseTranslation>,
    formIds: (number|string)[],
    searchTerm: string,
    language: string
  ) {
    const endpoint = 'api/manager/forms/GetFormTranslationsReportDownload';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      formIds,
      searchTerm,
      language
    });
  }

  // ** End Form Specific ** //

  // ** Start Data Table Specific ** //

  getDataTableTranslations (
    paginationOptions: PaginationOptions<BaseTranslation>,
    picklistGuids: string[]|number[],
    searchTerm: string,
    language: string
  ): Promise<PaginatedResponse<BaseTranslation>> {
    const endpoint = 'api/client/picklist/GetPicklistTranslations';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      picklistGuids,
      searchTerm,
      language
    });
  }

  bulkUpdateDataTableTranslations (
    translationInfo: BulkTranslationsUpdate[]
  ) {
    const endpoint = 'api/client/picklist/BulkUpdateTranslations';

    return this.httpRestService.post(endpoint, {
      translationInfo
    });
  }

  machineTranslateForDataTablesSingle (
    languageKeyId: number,
    language: string,
    defaultText: string
  ) {
    const endpoint = 'api/client/picklist/MachineTranslateById';

    return this.httpRestService.post(endpoint, {
      languageKeyId,
      language,
      defaultText
    });
  }

  machineTranslateForDataTablesBulk (
    languages: string[]
  ) {
    const endpoint = 'api/client/picklist/BulkMachineTranslate';

    return this.httpRestService.post(endpoint, {
      languages
    });
  }

  downloadDataTableTranslations (
    paginationOptions: PaginationOptions<BaseTranslation>,
    picklistGuids: (number|string)[],
    searchTerm: string,
    language: string
  ) {
    const endpoint = 'api/client/picklist/GetPicklistTranslationsReportDownload';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      picklistGuids,
      searchTerm,
      language
    });
  }

  // ** End Data Table Specific ** //


  // ** Start Program Specific ** //

  getProgramTranslations (
    paginationOptions: PaginationOptions<BaseTranslation>,
    programIds: number[]|string[],
    searchTerm: string,
    language: string
  ): Promise<PaginatedResponse<BaseTranslation>> {
    const endpoint = 'api/manager/programs/GetProgramTranslations';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      programIds,
      searchTerm,
      language
    });
  }

  bulkUpdateProgramTranslations (
    translationInfo: BulkTranslationsUpdate[]
  ) {
    const endpoint = 'api/manager/programs/BulkUpdateTranslations';

    return this.httpRestService.post(endpoint, {
      translationInfo
    });
  }

  machineTranslateForProgramsSingle (
    languageKeyId: number,
    language: string,
    defaultText: string
  ) {
    const endpoint = 'api/manager/programs/MachineTranslateById';

    return this.httpRestService.post(endpoint, {
      languageKeyId,
      language,
      defaultText
    });
  }

  machineTranslateForProgramsBulk (
    languages: string[]
  ) {
    const endpoint = 'api/manager/programs/BulkMachineTranslate';

    return this.httpRestService.post(endpoint, {
      languages
    });
  }

  downloadProgramTranslations (
    paginationOptions: PaginationOptions<BaseTranslation>,
    programIds: (number|string)[],
    searchTerm: string,
    language: string
  ) {
    const endpoint = 'api/manager/programs/GetGrantProgramTranslationsReportDownload';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      programIds,
      searchTerm,
      language
    });
  }

  // ** End Program Specific ** //


  // ** Start Program Automation Specific ** //

  getAutomationTranslations (
    paginationOptions: PaginationOptions<BaseTranslation>,
    grantProgramRoutingDetailIds: number[]|string[],
    searchTerm: string,
    language: string
  ): Promise<PaginatedResponse<BaseTranslation>> {
    const endpoint = 'api/manager/GrantProgramRoutingAutomation/GetTranslations';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      grantProgramRoutingDetailIds,
      searchTerm,
      language
    });
  }

  bulkUpdateAutomationTranslations (
    translationInfo: BulkTranslationsUpdate[]
  ) {
    const endpoint = 'api/manager/GrantProgramRoutingAutomation/BulkUpdateTranslations';

    return this.httpRestService.post(endpoint, {
      translationInfo
    });
  }

  machineTranslateForAutomationSingle (
    languageKeyId: number,
    language: string,
    defaultText: string
  ) {
    const endpoint = 'api/manager/GrantProgramRoutingAutomation/MachineTranslateById';

    return this.httpRestService.post(endpoint, {
      languageKeyId,
      language,
      defaultText
    });
  }

  machineTranslateForAutomationBulk (
    languages: string[]
  ) {
    const endpoint = 'api/manager/GrantProgramRoutingAutomation/BulkMachineTranslate';

    return this.httpRestService.post(endpoint, {
      languages
    });
  }

  downloadAutomationTranslations (
    paginationOptions: PaginationOptions<BaseTranslation>,
    grantProgramRoutingDetailIds: (number|string)[],
    searchTerm: string,
    language: string
  ) {
    const endpoint = 'api/manager/GrantProgramRoutingAutomation/GetProgramAutomationTranslationsReportDownload';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      grantProgramRoutingDetailIds,
      searchTerm,
      language
    });
  }

  // ** End Program Automation Specific ** //

  // ** Start In Kind Category Specific ** //

  getInKindTranslations (
    paginationOptions: PaginationOptions<BaseTranslation>,
    categoryIds: number[]|string[],
    searchTerm: string,
    language: string
  ): Promise<PaginatedResponse<BaseTranslation>> {
    const endpoint = 'api/manager/inkind/paginated/translations';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      categoryIds,
      searchTerm,
      language
    });
  }

  bulkUpdateInKindTranslations (
    translationInfo: BulkTranslationsUpdate[]
  ) {
    const endpoint = 'api/manager/inkind/translations/bulkupdate';

    return this.httpRestService.post(endpoint, {
      translationInfo
    });
  }

  machineTranslateForInKindSingle (
    languageKeyId: number,
    language: string,
    defaultText: string
  ) {
    const endpoint = 'api/manager/inkind/translations/machinetranslatebyid';

    return this.httpRestService.post(endpoint, {
      languageKeyId,
      language,
      defaultText
    });
  }

  machineTranslateForInKindBulk (
    languages: string[]
  ) {
    const endpoint = 'api/manager/inkind/translations/bulkmachinetranslate';

    return this.httpRestService.post(endpoint, {
      languages
    });
  }

  downloadInKindTranslations (
    paginationOptions: PaginationOptions<BaseTranslation>,
    categoryIds: (number|string)[],
    searchTerm: string,
    language: string
  ) {
    const endpoint = 'api/manager/inkind/GetInKindTranslationsReportDownload';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      categoryIds,
      searchTerm,
      language
    });
  }

}

