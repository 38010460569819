<div>
  <!-- This component will become -->
  <div class="mb-3">
    <yc-typeahead-select
      name="willBeValid"
      [disabled]="isViewOnly"
      [label]="'common:textThisComponentWillBecome' | ycI18n: 'This component will become'"
      [(value)]="willBeValid"
      [items]="willBeValidOptions"
      (valueChange)="onValidationChange()">
    </yc-typeahead-select>
  </div>

  <!-- When the user has requested -->
  <div class="mb-3">
    <yc-typeahead-select
      name="validationType"
      [disabled]="isViewOnly"
      [label]="('common:textWhenTheUserHasRequested' | ycI18n: 'When the user has requested') | lowercase"
      [(value)]="validationType"
      [items]="validationTypeOptions"
      (valueChange)="onValidationChange()">
    </yc-typeahead-select>
  </div>

  <!-- Validation Amount -->
  <div *ngIf="validationType !== ValidationTypes.HasSelectedItem"
    class="mb-3">
    <yc-input
      name="validationAmount"
      [disabled]="isViewOnly"
      [isNumber]="true"
      [label]="'common:textValidationAmount' | ycI18n: 'Validation amount'"
      [(value)]="validationAmount"
      (valueChange)="onValidationChange()">
    </yc-input>
  </div>

  <!-- Validation Item -->
  <div *ngIf="validationType === ValidationTypes.HasSelectedItem" 
    class="mb-3">
    <yc-typeahead-select
      name="validationItem"
      [disabled]="isViewOnly"
      [label]="'common:textValidationItem' | ycI18n: 'Validation item'"
      [(value)]="validationItem"
      [items]="validationItemOptions"
      (valueChange)="onValidationChange">
    </yc-typeahead-select>
  </div>

  <!-- Custom Error Message -->
  <div>
    <yc-input
      name="validationErrorMessage"
      [disabled]="isViewOnly"
      [label]="'common:textErrorMessage' | ycI18n: 'Error message'"
      [(value)]="validationErrorMessage"
      (valueChange)="onValidationChange()">
    </yc-input>
  </div>
</div>