import { ProfileRouterLink } from '@core/typings/applicant.typing';
import { NonprofitProfileData } from '@core/typings/organization.typing';
import { SDGMetadata } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class NonprofitState extends BaseYcState {
  readonly nonprofitMap: {
    [g: string]: NonprofitProfileData;
  } = {};

  readonly sdgs: SDGMetadata[];
  readonly nonprofitProfileRouterLink: ProfileRouterLink = {};
}
