import { Component } from '@angular/core';
import { LocationService } from '@core/services/location.service';
import { ReleaseNoteService } from '@features/platform-admin/release-notes/services/release-notes.service';
import { YCModalComponent } from '@yourcause/common/modals';

@Component({
  selector: 'gc-release-notes-modal',
  templateUrl: './release-notes-modal.component.html',
  styleUrls: ['./release-notes-modal.component.scss']
})
export class ReleaseNotesModalComponent extends YCModalComponent<boolean> {
  releaseNote = this.releaseNoteService.releaseNoteForManager;
  doNotShowAgain = false;

  constructor (
    private releaseNoteService: ReleaseNoteService,
    private locationService: LocationService
  ) {
    super();
  }

  viewReleaseNotes () {
    this.locationService.openExternalUrl(this.releaseNote.releaseNotesUrl);
  }
}
