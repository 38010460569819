import { Pipe, PipeTransform } from '@angular/core';
import { FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { ComponentHelperService } from '../services/component-helper/component-helper.service';

@Pipe({
  name: 'gcCanAddColumns'
})
export class CanAddColumnsPipe implements PipeTransform {

  constructor (
    private componentHelper: ComponentHelperService
  ) { }

  transform (
    columns: FormDefinitionComponent[]
  ) {
    return this.componentHelper.canColumnCompAddColumns(columns);
  }
}
