import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { StandardFormTemplate, StandardProductDashboard, StandardReportTemplate } from './standard-product-configuration.typing';

@RegisterYCState()
export class StandardProductConfigurationState extends BaseYcState {
  readonly standardFormTemplates: StandardFormTemplate[];
  readonly standardReportTemplates: StandardReportTemplate[];
  readonly standardProductDashboards: StandardProductDashboard[];
  readonly standardApplicantFormOptions: TypeaheadSelectOption[] = [];
  readonly standardManagerFormOptions: TypeaheadSelectOption[] = [];
  readonly fileIsStaged: boolean;
  readonly areRecordsAvailableToPush: boolean;
}
