
export enum InKindCategories {
  Goods = 1,
  Services = 2,
  Space = 3,
  Other = 4
}

export interface InKindCategory {
  id: number;
  type: InKindCategories;
  name: string;
  numberOfItems: number;
  createdBy: string;
  createdDate: string;
  createImpersonatedBy: string;
  updatedBy: string;
  updatedDate: string;
  impersonatedBy: string;
  language: string;
}

export interface ImportCategoryItem {
  identification: string;
  name: string;
  units: number;
  value: number;
}

export interface InKindCategoryItem {
  inUse: boolean;
  identification: string;
  name: string;
  units: number;
  value: number;
}

export interface InKindRequestedItem {
  count: number;
  itemIdentification: string;
}

export interface InKindCategoryItemStat extends InKindCategoryItem {
  unitsPaid: number;
  unitsRemaining: number;
  categoryId: number;
  selected?: boolean;
  unitsEntered?: number;
}

export interface AddEditItemModalResponse {
  name: string;
  units: number;
  value: number;
  identification: string;
}

export interface ApplicantInKindItem {
  itemIdentification: string;
  count: number;
  value: number;
  name?: string;
  inUse?: boolean;
  categoryId?: number;
}

export interface InKindItemToAwardOrPay {
  identification: string;
  name: string;
  value: number;
  unitsRemaining: number;
  categoryId: number;
  unitsEntered: number;
  inUse?: boolean;
}

export interface InKindAwardedItemApi {
  count: number;
  itemIdentification: string;
  value: number;
}


