<yc-modal *ngIf="formGroup"
  [modalHeader]="modalHeader"
  [primaryButtonText]="'common:btnSave' | ycI18n: 'Save'"
  [primaryDisabled]="formGroup.invalid"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  (onCancel)="onCancel()"
  (onPrimaryClick)="onSubmit()">

  <ng-container [formGroup]="formGroup">

    <yc-alert *ngIf="!!alertText"
      alertClass="info"
      [message]="alertText">
    </yc-alert>

    <!-- Name -->
    <div class="row">
      <div class="col-md-6">
        <yc-input
          name="firstName"
          [disabled]="noEditsAllowed"
          [label]="'common:lblFirstName' | ycI18n: 'First name'">
        </yc-input>
      </div>
      <div class="col-md-6">
        <yc-input
          name="lastName"
          [disabled]="noEditsAllowed"
          [label]="'common:lblLastName' | ycI18n: 'Last name'">
        </yc-input>
      </div>
    </div>

    <!-- Job Title / Email -->
    <div class="row">
      <div class="col-md-6">
        <yc-input
          name="jobTitle"
          [label]="'GLOBAL:textJobTitle' | ycI18n: 'Job title'">
        </yc-input>
      </div>

      <div class="col-md-6">
        <yc-input
          name="email"
          [disabled]="noEditsAllowed"
          [label]="'common:textEmail' | ycI18n: 'Email'">
        </yc-input>
      </div>
    </div>

    <div class="mt-3">
      <yc-checkbox
        name="isSSO"
        [optionLabel]="'GLOBAL:textSSOUser' | ycI18n: 'SSO user'"
        (onChange)="setHelpers()">
      </yc-checkbox>
    </div>

  </ng-container>

</yc-modal>