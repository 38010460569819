export default [
  {
    "id": 1,
    "name": "Pending"
  },
  {
    "id": 2,
    "name": "Scheduled"
  },
  {
    "id": 3,
    "name": "Processing"
  },
  {
    "id": 4,
    "name": "Outstanding"
  },
  {
    "id": 5,
    "name": "Cleared"
  },
  {
    "id": 6,
    "name": "Voided"
  },
  {
    "id": 7,
    "name": "Fulfilled"
  },
  {
    "id": 8,
    "name": "ProcessingHold"
  },
  {
    "id": 9,
    "name": "SentToPartner"
  },
  {
    "id": 10,
    "name": "Invoiced"
  }
]