import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { AddBatchForApi, BatchItemFromApi, DisbursementReportRow, ExpeditePaymentForApi, InKindPaymentStats, PaymentForProcess, PaymentStatsFromApi, PaymentSummaryResponse, PaymentUpdateImport, ProcessAllAvailableApi, ProcessingTypes, RecallPaymentPayload, SimplePaymentStats, UpdateBatchForApi, UpdatePaymentsApi, UpdatePaymentSpecialHandling } from '@core/typings/payment.typing';
import { BatchStatuses, PaymentStatus } from '@core/typings/status.typing';
import { EmailOptionsModelForSave } from '@features/system-emails/email.typing';
import { APIResultData, PaginatedResponse, PaginationOptions } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class PaymentProcessingResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  importApPayments (paymentUpdates: PaymentUpdateImport[]) {
    const endpoint = 'api/manager/paymentbatch/UpdatePaymentsFromExternalImport';

    return this.httpRestService.post(endpoint, {
      paymentUpdates
    });
  }

  getPaymentStats (
    processingTypeId: ProcessingTypes,
    grantProgramIds: number[] = []
  ): Promise<PaymentStatsFromApi> {
    const endpoint = 'api/manager/paymentbatch/getpaymentstats';

    return this.httpRestService.post(endpoint, {
      processingTypeId,
      grantProgramIds
    });
  }

  getPaymentStatsFromPagination (
    paginationOptions: PaginationOptions<PaymentForProcess>
  ): Promise<SimplePaymentStats> {
    const endpoint = 'api/manager/paymentbatch/GetPaymentStatsFromPagination';

    return this.httpRestService.post(endpoint, {
      paginationOptions
    });
  }

  getAvailableOrBatchPayments (
    options: {
      paginationOptions: PaginationOptions<PaymentForProcess>;
      batchId: number;
      includeProgramFacet: boolean;
    },
    isAvailable: boolean,
    isIncluded: boolean
  ): Promise<APIResultData<PaymentForProcess>&{
    programFacets: Record<string, string>
  }> {
    const available = 'api/manager/paymentbatch/getavailablepayments';
    const endpoint = isAvailable ?
      available :
      (
        !isIncluded ?
          available :
          'api/manager/paymentbatch/getbatchpayments'
      );

    return this.httpRestService.post(endpoint, options);
  }

  getInKindPayments (paginationOptions: PaginationOptions<PaymentForProcess>) {
    const endpoint = 'api/manager/paymentbatch/getallpayments';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      inKindOnly: true,
      includeProgramFacet: true
    });
  }

  getInKindPaymentStats (): Promise<InKindPaymentStats> {
    const endpoint = 'api/manager/paymentbatch/GetInKindPaymentStats';

    return this.httpRestService.get(endpoint, {});
  }

  getBatch (batchId: number): Promise<BatchItemFromApi> {
    const endpoint = `api/manager/paymentbatch/getbatch?batchId=${batchId}`;

    return this.httpRestService.get(endpoint);
  }

  updatePayments (
    data: UpdatePaymentsApi,
    isSinglePayment = false
  ): Promise<{
    automaticallyRouted: boolean;
  }> {
    let endpoint = 'api/manager/paymentbatch/BulkUpdatePayments';
    if (isSinglePayment) {
      endpoint = `api/manager/paymentbatch/${data.paymentIds[0]}`;
      delete data.paymentIds;
    }

    return this.httpRestService.post(endpoint, data);
  }

  getPaymentNotes (id: number) {
    const endpoint = `api/manager/paymentbatch/GetPaymentNotes?paymentId=${id}`;

    return this.httpRestService.get(endpoint);
  }

  updateBatch (data: UpdateBatchForApi) {
    const endpoint = 'api/manager/paymentbatch/updateBatch';

    return this.httpRestService.post(endpoint, data);
  }

  processBatch (
    batchId: number,
    batchNotes: string,
    markAllPaymentsCleared: boolean,
    markAllPaymentsToStatus: PaymentStatus,
    markAllPaymentsIssuedDate: string
  ) {
    const endpoint = 'api/manager/paymentbatch/ProcessBatch';

    return this.httpRestService.post(endpoint, {
      batchId,
      batchNotes,
      markAllPaymentsToStatus,
      markAllPaymentsIssuedDate,
      markAllPaymentsCleared
    });
  }

  processAllAvailable (data: ProcessAllAvailableApi) {
    const endpoint = 'api/manager/paymentbatch/AddAvailablePaymentsToBatch';

    return this.httpRestService.post(endpoint, data);
  }

  expeditePayment (data: ExpeditePaymentForApi) {
    const endpoint = 'api/manager/paymentbatch/ExpeditePayment';

    return this.httpRestService.post(endpoint, data);
  }

  updateBatchStatus (
    batchId: number,
    batchStatusId: BatchStatuses,
    statusUpdateNote?: string,
    updatePaymentsToStatusId?: PaymentStatus
  ) {
    const endpoint = 'api/manager/paymentbatch/updatebatchstatus';

    return this.httpRestService.post(endpoint, {
      batchId,
      batchStatusId,
      statusUpdateNote,
      updatePaymentsToStatusId
    });
  }

  addPaymentsToBatch (paymentIds: number[], batchId: number) {
    const endpoint = 'api/manager/paymentbatch/addpaymenttobatch';

    return this.httpRestService.post(endpoint, {
      paymentIds,
      batchId
    });
  }

  removePaymentsFromBatch (paymentIds: number[], batchId: number) {
    const endpoint = 'api/manager/paymentbatch/removepaymentfrombatch';

    return this.httpRestService.post(endpoint, {
      paymentIds,
      batchId
    });
  }

  updatePaymentHold (
    paymentId: number,
    onHold: boolean,
    notes: string
  ) {
    const endpoint = 'api/manager/paymentbatch/holdpayment';

    return this.httpRestService.post(endpoint, {
      paymentId,
      onHold,
      notes
    });
  }

  getBatches (
    paginationOptions: PaginationOptions<BatchItemFromApi>
  ): Promise<PaginatedResponse<BatchItemFromApi>> {
    const endpoint = 'api/manager/paymentbatch/getbatches';

    return this.httpRestService.post(endpoint, {
      paginationOptions
    });
  }

  addBatch (data: AddBatchForApi) {
    const endpoint = 'api/manager/paymentbatch/addbatch';

    return this.httpRestService.post(endpoint, data);
  }

  getAllPayments (
    paginationOptions: PaginationOptions<PaymentForProcess>
  ) {
    const endpoint = 'api/manager/paymentbatch/getallpayments';

    return this.httpRestService.post(endpoint, {
      paginationOptions,
      includeProgramFacet: true
    });
  }

  deleteBatch (batchId: number) {
    const endpoint = 'api/manager/paymentbatch/deletebatch';

    return this.httpRestService.post(endpoint, {
      batchId
    });
  }

  addPaymentsToBatchPaginated (
    batchId: number,
    paginationOptions: PaginationOptions<PaymentForProcess>
  ) {
    const endpoint = 'api/manager/paymentbatch/AddPaymentsToBatchPaginated';

    return this.httpRestService.post(endpoint, {
      batchId,
      paginationOptions
    });
  }

  validatePaymentIds (
    paymentIds: number[]
  ) {
    const endpoint = 'api/manager/paymentbatch/ValidateImportPaymentIds';

    return this.httpRestService.post<number[]>(endpoint, {
      paymentIds
    });
  }

  async downloadPaymentBatchReport (batchId: number) {
    const endpoint = `api/manager/paymentbatch/DownloadPaymentBatchReport/${batchId}`;
    const response = await this.httpRestService.get<{ file: string }>(endpoint);

    return response.file;
  }

  updateSpecialHandling (payload: UpdatePaymentSpecialHandling) {
    const endpoint = 'api/manager/paymentbatch/AlternateAddressRequest';

    return this.httpRestService.post(endpoint, {
      reason: payload.specialHandlingReason,
      fileUrl: payload.specialHandlingFileUrl,
      fileUploadId: payload.fileUploadId,
      name: payload.specialHandlingName,
      notes: payload.specialHandlingNotes,
      address1: payload.specialHandlingAddress1,
      address2: payload.specialHandlingAddress2,
      city: payload.specialHandlingCity,
      state: payload.specialHandlingStateProvinceRegion,
      country: payload.specialHandlingCountry,
      postalCode: payload.specialHandlingPostalCode,
      paymentId: payload.paymentId
    });
  }

  getDisbursementSummary (
    startDate: string,
    endDate: string,
    programIds: number[]
  ): Promise<PaymentSummaryResponse> {
    const endpoint = '/api/manager/paymentbatch/DisbursementSummary';

    return this.httpRestService.post(endpoint, {
      startDate,
      endDate,
      programIds
    });
  }

  getDisbursementRows (
    startDate: string,
    endDate: string,
    programIds: number[]
  ): Promise<DisbursementReportRow[]> {

    const endpoint = '/api/manager/paymentbatch/DisbursementReport';

    return this.httpRestService.post(endpoint, {
      startDate,
      endDate,
      programIds
    });
  }

  notifyPayeees (
    emailOptions: EmailOptionsModelForSave,
    paymentIds: number[]
  ) {
    const endpoint = 'api/manager/paymentbatch/sendpaymentbatchinfoemails';

    return this.httpRestService.post(endpoint, {
      paymentIds,
      clientEmailTemplateId: emailOptions.clientEmailTemplateId,
      customMessage: emailOptions.customMessage,
      emailOptions
    });
  }

  async paymentIsEligibleForRecall (paymentId: number){
    const endpoint = `api/manager/paymentbatch/${paymentId}/CanRecallPaymentFromProcessingTool`;

    return this.httpRestService.get(endpoint);
  }

  async recallPayment (payload: RecallPaymentPayload){
    const endpoint = 'api/manager/paymentbatch/RecallPaymentInProcessingTool';

    return this.httpRestService.post(endpoint, payload);
  }
}
