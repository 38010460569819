import { AbstractControl } from '@angular/forms';
import { IsValidTypes } from '@features/forms/component-configuration/component-configuration.typing';
import { InKindRequestedItem } from '@features/in-kind/in-kind.typing';

export function HasSelectedQuantityValidator (
  validationAmount: number,
  isValidType: IsValidTypes,
  customErrorMessage: string,
  defaultErrorMessage: string
) {
  return (control: AbstractControl) => {
    const value = control.value as InKindRequestedItem[] ?? [];
    let isValid: boolean;
    if (isValidType === IsValidTypes.Valid) {
      isValid = value?.length === validationAmount;
    } else {
      isValid = value?.length !== validationAmount;
    }

    if (!isValid) {
      return {
        hasSelectedQuantityError: {
          i18nKey: '',
          defaultValue: '',
          errorMessage: customErrorMessage || defaultErrorMessage
        }
      };
    }

    return null;
  };

};
