import { Collaborator } from '@core/typings/collaboration.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class CollaborationState extends BaseYcState {
  readonly collaboratorMap: {
    [applicationId: number]: Collaborator[];
  } = {};
}
