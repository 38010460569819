import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { SSOConfigurationFromApi } from '@core/typings/sso-configuration.typing';

@Injectable({ providedIn: 'root' })
export class SsoResources {

  constructor (
    private http: HttpRestService
  ) { }

  getSSOConfigurationBySubDomain (subDomain: string) {
    const endpoint = 'api/lookup/GetSSOConfigurationBySubDomain/' + subDomain;

    return this.http.get<SSOConfigurationFromApi>(endpoint);
  }
}
