import { Pipe, PipeTransform } from '@angular/core';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { FormAnswerValues } from '@features/configure-forms/form.typing';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';

@Pipe({
  name: 'gcGetFormattedTableResponse'
})
export class GetFormattedTableResponsePipe implements PipeTransform {

  constructor (
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService
  ) { }

  transform (
    row: ReferenceFieldsUI.TableResponseRowForUiMapped,
    column: ReferenceFieldsUI.TableFieldForUi,
    value: FormAnswerValues
  ) {
    return this.formFieldTableAndSubsetService.formatTableResponse(
      row,
      column,
      value
    );
  }
}
