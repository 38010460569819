import { Component } from '@angular/core';
import { MAX_COMPONENTS_PER_PAGE } from '@features/configure-forms/form.typing';
import { YCModalComponent } from '@yourcause/common/modals';

@Component({
  selector: 'gc-component-limit-met-modal',
  templateUrl: './component-limit-met-modal.component.html',
  styleUrls: ['./component-limit-met-modal.component.scss']
})
export class ComponentLimitMetModalComponent extends YCModalComponent<boolean> {
  MAX_COMPONENTS_PER_PAGE = MAX_COMPONENTS_PER_PAGE;
}
