import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { PolicyService } from '@core/services/policy.service';
import { AdHocReportingAPI } from '@core/typings/api/ad-hoc-reporting.typing';
import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { StandardProductConfigurationService } from '@features/platform-admin/standard-product-configuration/standard-product-configuration.service';
import { AdHocReportingMappingService } from '@features/reporting/services/ad-hoc-reporting-mapping.service';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { TypeSafeFormBuilder, TypeSafeFormGroup, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { RootObjectNames } from '../../services/ad-hoc-reporting-definitions.service';

export type ManageReportFormData = AdHocReportingUI.ManageModalFormData<RootObjectNames>;
export type StartFromReportTemplate = AdHocReportingAPI.UserSavedReportForUi;

@Component({
  selector: 'gc-manage-ad-hoc-report-modal',
  templateUrl: './manage-ad-hoc-report-modal.component.html',
  styleUrls: ['./manage-ad-hoc-report-modal.component.scss']
})
export class ManageAdHocReportModalComponent extends YCModalComponent<
  ManageReportFormData
> implements OnInit {
  @Input() existingDetail: ManageReportFormData;
  @Input() startFromTemplate: StartFromReportTemplate;
  @Input() columns: AdHocReportingUI.ColumnImplementation[] = [];
  @Input() requireFormSelection: boolean;

  invalidColumnHeadersAlert: string;
  isNew: boolean;
  hideFundingSources = this.clientSettingsService.hideFundingSources;
  canSeeBudgets = this.policyService.system.canManageBudgets() ||
    this.policyService.insights.canViewBudgets();
  canSeeSources = (
    this.policyService.system.canManageFundingSources() ||
    this.policyService.insights.canViewFundingSources()
   ) && !this.hideFundingSources;
  hasNominations = this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.HasNominations);
  referenceFieldTableOptions: TypeaheadSelectOption<string>[] = [];
  objectOptions: TypeaheadSelectOption<string>[] = this.arrayHelper.sort([{
    label: this.i18n.translate(
      this.hasNominations ?
      'common:lblApplicationNomination' :
      'common:lblApplication',
      {},
      this.hasNominations ?
      'Application / Nomination' :
      'Application'
    ),
    value: 'application'
  }, {
    label: this.i18n.translate(
      'common:lblAward',
      {},
      'Award'
    ),
    value: 'award'
  }, {
    label: this.i18n.translate(
      'common:lblPayment',
      {},
      'Payment'
    ),
    value: 'payment'
  }, {
    label: this.i18n.translate(
      'FORMS:textTable',
      {},
      'Table'
    ),
    value: 'table'
  }, {
    label: this.i18n.translate(
      'common:lblCustomForms',
      {},
      'Custom forms'
    ),
    value: 'customForm'
  }, {
    label: this.i18n.translate(
      'common:hdrInKindAmountRequested',
      {},
      'In kind amount requested'
    ),
    value: 'applicationInKind'
  }, {
    label: this.i18n.translate(
      'common:hdrInKindItemsAwarded',
      {},
      'In kind items awarded'
    ),
    value: 'awardInKind'
  }, {
    label: this.i18n.translate(
      'common:hdrInKindItemsPaid',
      {},
      'In kind items paid'
    ),
    value: 'paymentInKind'
  }, this.canSeeBudgets ? {
    label: this.i18n.translate(
      'common:lblBudget',
      {},
      'Budget'
    ),
    value: 'budgets'
  } : undefined, this.canSeeSources ? {
    label: this.i18n.translate(
      'GLOBAL:textFundingSources2',
      {},
      'Funding sources'
    ),
    value: 'fundingSources'
  } : undefined].filter((item) => !!item), 'label');
  formGroup: TypeSafeFormGroup<ManageReportFormData>;
  customFormAlertMessage = '';
  ready = false;
  gmFormsWithSingleResponseFields: number[] = [];
  startFromTemplateOptions: TypeaheadSelectOption<StartFromReportTemplate>[] = [];
  createAndEditAdHocReportHelpLink = 'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-ad-hoc-reports.html';

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private i18n: I18nService,
    private formService: FormsService,
    private arrayHelper: ArrayHelpersService,
    private policyService: PolicyService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService,
    private standardProductService: StandardProductConfigurationService,
    private adHocMappingService: AdHocReportingMappingService,
    private analyticsService: AnalyticsService,
    private clientSettingsService: ClientSettingsService
  ) {
    super();
  }

  async ngOnInit () {
    const detail = this.existingDetail;
    const invalidColumnHeaders = this.adHocMappingService.getInvalidColumnHeaders(this.columns);
    this.invalidColumnHeadersAlert = this.adHocMappingService.getInvalidColumnHeadersText(invalidColumnHeaders);
    this.isNew = !detail;
    let object = detail ? detail.object : 'application';
    let reportName = detail ? detail.reportName : '';
    let reportDescription = detail ? detail.reportDescription : '';
    let referenceFieldTableKey = detail ? detail.referenceFieldTableKey : null;
    const template = this.startFromTemplate;
    if (template) {
      object = this.adHocMappingService.getObjectByReportModelType(
        template.reportModelType
      );
      reportName = template.name;
      reportDescription = template.description;
      referenceFieldTableKey = template.referenceFieldTableKey;
    }
    this.setTemplateOptions(object);
    let startFromTemplate: StartFromReportTemplate;
    if (this.startFromTemplateOptions.length > 0) {
      startFromTemplate = template ?
        this.startFromTemplateOptions.find((opt) => {
          return opt.value.id === template.id;
        })?.value :
        null;
    }
    this.gmFormsWithSingleResponseFields = await this.formService.getGmFormsWithSingleResponseFields();
    this.referenceFieldTableOptions = this.formFieldTableAndSubsetService.getTableFieldSelectOptions();
    this.setCustomFormAlertMessage();
    this.formGroup = this.formBuilder.group<ManageReportFormData>({
      reportName: [reportName, Validators.required],
      reportDescription,
      object,
      program: null,
      primaryFormId: detail ? detail.primaryFormId : null,
      referenceFieldTableKey,
      forms: [
        detail ? this.returnApplicantForms(detail) : [],
        this.requireFormSelection ? Validators.required : undefined
      ],
      startFromTemplate
    }, {
      validator: [
        this.formRequired(),
        this.tableFieldRequired()
      ]
    });
    this.ready = true;
  }


  setTemplateOptions (object = this.formGroup.value.object) {
    this.startFromTemplateOptions = this.standardProductService.getReportTemplateOptionsByObject(
      object
    );
    /** If no templates are available, reset startFromTemplate */
    if (
      this.formGroup &&
      this.startFromTemplateOptions.length === 0
    ) {
      this.formGroup.get('startFromTemplate').setValue(null);
    }
  }

  setCustomFormAlertMessage () {
    this.customFormAlertMessage = this.i18n.translate(
      'reporting:textCustomFormAlertMessage4',
      {},
      'Each form submission creates one row on the report. The number of rows will vary based on the number of responses submitted per application.'
    );
  }

  returnApplicantForms (detail: ManageReportFormData) {
    return detail.forms.filter(form => form !== detail.primaryFormId);
  }

  private isCustomFormReport (formGroup: TypeSafeFormGroup<ManageReportFormData>) {
    return formGroup && formGroup.value && formGroup.value.object === 'customForm';
  }

  tableFieldRequired () {
    return (group: TypeSafeFormGroup<ManageReportFormData>) => {
      // must select one table
      const tableFieldControl = group.get('referenceFieldTableKey');
      if (group.value.object === 'table') {
        if (
          !tableFieldControl.value &&
          tableFieldControl.valid
        ) {
          tableFieldControl.setErrors({
            required: true
          });
        } else if (
          tableFieldControl.value &&
          tableFieldControl.invalid
        ) {
          tableFieldControl.setErrors(null);
        }
      } else {
        tableFieldControl.setErrors(null);
      }
    };
  }

  formRequired () {
    return (group: TypeSafeFormGroup<ManageReportFormData>) => {
      const primaryFormControl = group.get('primaryFormId');
      if (this.isCustomFormReport(group)) {
        if (
          !primaryFormControl.value &&
          primaryFormControl.valid
        ) {
          primaryFormControl.setErrors({
            required: true
          });
        } else if (
          primaryFormControl.value &&
          primaryFormControl.invalid
        ) {
          primaryFormControl.setErrors(null);
        }
      } else {
        primaryFormControl.setErrors(null);
      }
    };
  }

  startFromTemplateChanged (startFromTemplate: StartFromReportTemplate) {
    if (startFromTemplate) {
      this.clearPrimaryFormId();
      this.clearForms();
      if (startFromTemplate.referenceFieldTableKey) {
      const tableKeyControl = this.formGroup.get('referenceFieldTableKey');
        tableKeyControl.setValue(startFromTemplate.referenceFieldTableKey);
      }
    }
  }

  objectChanged (
    newObject: RootObjectNames
  ) {
    if (this.isNew) {
      if ((newObject === 'customForm')) {
        this.clearPrimaryFormId();
      } else {
        this.clearForms();
      }
    }
    this.setTemplateOptions();
  }

  clearForms () {
    this.formGroup.get('forms').setValue([]);
  }

  clearPrimaryFormId () {
    this.formGroup.get('primaryFormId').setValue(null);
  }

  cancel () {
    this.closeModal.emit();
  }

  submit () {
    this.closeModal.emit(this.formGroup.value);
    this.analyticsService.emitEvent({
      eventName: 'Save ad hoc report',
      eventType: EventType.Click,
      extras: null
    });
  }
}
