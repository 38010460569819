<div *ngIf="formGroup"
  [formGroup]="formGroup">
  <!-- Data Object -->
  <div class="mb-3">
    <yc-typeahead-select
      name="reportFieldObject"
      [disabled]="isViewOnly"
      [items]="objectOptions"
      [addRequiredAsterisk]="true"
      [showClear]="false"
      [label]="'common:textDataObject' | ycI18n: 'Data object'"
      (onChange)="objectChanged($event)">
    </yc-typeahead-select>
  </div>

  <!-- Nomination Form -->
  <div *ngIf="isNominationField && !isEditAndNoFormFound"
    class="mb-3">
    <yc-typeahead-select
      name="nominationFormId"
      [disabled]="isViewOnly"
      [items]="nominationFormOptions"
      [addRequiredAsterisk]="true"
      [showClear]="false"
      [label]="'GLOBAL:textForm' | ycI18n: 'Form'"
      (onChange)="handleDataChange(); setDisplayOptions(false)">
    </yc-typeahead-select>
  </div>

  
  <!-- Data To Display -->
  <div>
    <yc-typeahead-select
      name="reportFieldDisplay"
      [items]="displayOptions"
      [disabled]="isViewOnly"
      [addRequiredAsterisk]="true"
      [showClear]="false"
      [label]="'common:textDataToDisplauy' | ycI18n: 'Data to display'"
      (onChange)="handleDataChange()">
    </yc-typeahead-select>
  </div>
</div>