import { Injectable } from '@angular/core';
import { BBAuth, BBOmnibar, BBOmnibarConfig } from '@blackbaud/auth-client';
import { DetermineSignInFlow } from '@core/typings/auth.typing';
import { environment } from '@environment';
import { LogService } from '@yourcause/common/logging';
import { LocationService } from './location.service';
import { PortalDeterminationService } from './portal-determination.service';
const BbidProfileUrl = 'https://app.blackbaud.com/user';

@Injectable({ providedIn: 'root' })
export class BBIDService {

  constructor (
    private logger: LogService,
    private portal: PortalDeterminationService,
    private locationService: LocationService
  ) { }

  /**
   * Get the most up to date token from BBAuth
   *
   * @returns the BBAuth Token
   */
  async getToken () {
    try {
      const token = await BBAuth.getToken();      

      return token;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  /**
   * Do the Service and Environment IDs match GrantsConnect?
   *
   * @param svcId: Service ID
   * @param envId: Environment ID
   * @returns if the given IDs are for GrantsConnect
   */
  isGrantsConnectBbid (
    svcId: string,
    envId: string
  ) {
    const envIdPasses = envId === environment.bbidGcEnvironmentId ||
      environment.bbidPreviousEnvironmentIds.includes(envId);

    return svcId === environment.bbidGcServiceId &&
      envIdPasses;
  }

  /**
   * Loads the Omnibar with the given serice and environment IDs
   *
   * @param svcId: Service ID
   * @param envId: Environment ID
   */
  async loadOmnibar (
    svcId: string,
    envId: string
  ) {
    const isBbgm = !!svcId && !!envId && !this.isGrantsConnectBbid(svcId, envId);
    const payload: BBOmnibarConfig = {
      serviceName: 'GrantsConnect'
    };
    if (isBbgm) {
      payload.svcId = svcId;
      payload.envId = envId;
    }

    await BBOmnibar.load(payload);
    if (isBbgm) {
      setTimeout(() => {
        const iframes = document.getElementsByClassName('sky-omnibar-iframe');
        const omnibar = iframes[0];
        omnibar.classList.add('is-bbgm');
      });
    }
  }

  /**
   * Handle redirecting to BBID for authentication
   *
   * @param signInFlow: Sign in Flow Details for the User
   * @param email: Email
   * @param gcAccountNotCreatedYet: GC account is not created yet
   */
  redirectToBbid (
    signInFlow: DetermineSignInFlow,
    email: string,
    gcAccountNotCreatedYet: boolean
  ) {
    const returnLoginUrl = this.getReturnLoginUrl(
      signInFlow.isBbidLinked,
      email,
      gcAccountNotCreatedYet,
      location.href
    );
    const redirectRoute = this.getRedirectRoute(signInFlow.hasExistingBbidAccount, email, returnLoginUrl);

    this.doRedirect(redirectRoute);
  }

  /**
   * Get Redirect Route to Authenticate with BBID
   *
   * @param hasExistingBbidAccount: Does the user have an existing account in BBID?
   * @param email: Email
   * @param returnLoginUrl: Return Login URL
   * @returns the redirect route to authenticate a user in BBID
   */
  getRedirectRoute (
    hasExistingBbidAccount: boolean,
    email: string,
    returnLoginUrl: string
  ) {
    const encodedReturnUrl = encodeURIComponent(returnLoginUrl);
    let encodedEmailQueryParam = '';
    if (!!email) {
      encodedEmailQueryParam = `&login_hint=${encodeURIComponent(email)}`;
    }
    if (!hasExistingBbidAccount) {
      return `https://app.blackbaud.com/signin/sign-up?&redirectUrl=${encodedReturnUrl}${encodedEmailQueryParam}`;
    } else {
      return `https://app.blackbaud.com/signin?&redirectUrl=${encodedReturnUrl}${encodedEmailQueryParam}`;
    }
  }

  /**
   * Get the return login URL
   *
   * @param isBbidLinked: Is BBID already linked?
   * @param email: Email
   * @param gcAccountNotCreatedYet: Is GC account not created yet?
   * @returns the return login URL
   */
  getReturnLoginUrl (
    isBbidLinked: boolean,
    email: string,
    gcAccountNotCreatedYet: boolean,
    currentRoute: string
  ) {
    const route = this.portal.getRoute('auth/bbid-signin');
    const envId = environment.bbidGcEnvironmentId;
    const svcId = environment.bbidGcServiceId;
    const initialParams = `envId=${encodeURIComponent(envId)}&svcId=${encodeURIComponent(svcId)}`;
    let queryParams = initialParams;
    if (!!email) {
      queryParams = `${queryParams}&email=${encodeURIComponent(email)}`;
    }
    if (!isBbidLinked) {
      const baseParams = `needToLinkBbid=true&inSystem=${!gcAccountNotCreatedYet}`;
      queryParams = `${queryParams}&${baseParams}`;
      queryParams = `${queryParams}&initialRoute=${encodeURIComponent(currentRoute)}`;
    }
    const returnLoginUrl = this.locationService.getRelativeUrl(route, queryParams);

    return returnLoginUrl;
  }

  /**
   * Logout of BBID
   */
  logoutOfBbid (returnLoginUrl?: string) {
    if (!returnLoginUrl) {
      const route = this.portal.getRoute('auth/signin');
      returnLoginUrl = this.locationService.getRelativeUrl(route);
    }
    const encodedReturnUrl = encodeURIComponent(returnLoginUrl);
    const redirectRoute = `https://app.blackbaud.com/signin/sign-out?redirectUrl=${encodedReturnUrl}`;
    this.doRedirect(redirectRoute);
  }

  /**
   * Do redirect with location.href
   *
   * @param redirectRoute: Redirect to route
   */
  doRedirect (redirectRoute: string) {
    location.href = redirectRoute;
  }

  /**
   * Open the user's BBID profile in a new tab
   */
  openBbidProfile () {
    window.open(BbidProfileUrl, '_blank');
  }
}

