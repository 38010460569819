import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { EvaluationType, LogicColumnDisplay, LogicEvaluationTypeDisplayOptionsConditional, LogicEvaluationTypeDisplayOptionsValidity } from '../logic-builder.typing';

@Component({
  selector: 'gc-evaluation-type-selector',
  templateUrl: './evaluation-type-selector.component.html',
  styleUrls: ['./evaluation-type-selector.component.scss']
})
export class EvaluationTypeSelectorComponent<T> implements OnInit {
  @Input() evaluationType: EvaluationType;
  @Input() evaluationTypeOptions: LogicEvaluationTypeDisplayOptionsConditional|LogicEvaluationTypeDisplayOptionsValidity;
  @Input() availableColumns: LogicColumnDisplay<T>[];
  @Input() isViewOnly = false;
  @Output() onEvaluationTypeChange = new EventEmitter<EvaluationType>();

  evaluationTypeSelectOptions: TypeaheadSelectOption<EvaluationType>[];

  ngOnInit () {
    if (this.availableColumns.length === 0) {
      this.evaluationTypeSelectOptions = this.evaluationTypeOptions.filter(option => {
        return option.value === EvaluationType.AlwaysFalse || option.value === EvaluationType.AlwaysTrue;
      });
    } else {
      this.evaluationTypeSelectOptions = this.evaluationTypeOptions;
    }
  }

  handleEvaluationTypeChange (evaluationType: EvaluationType) {
    this.onEvaluationTypeChange.emit(evaluationType);
  }
}
