import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenResponse } from '@core/typings/token.typing';
import { LogService } from '@yourcause/common/logging';
import { lastValueFrom } from 'rxjs';
import { PortalDeterminationService } from '../portal-determination.service';
import { TokenAdapterService } from './token-adapter.service';

@Injectable({ providedIn: 'root' })
export class TokenRefreshResources {

  constructor (
    private http: HttpClient,
    private portal: PortalDeterminationService,
    private adapter: TokenAdapterService,
    private logger: LogService
  ) { }

  protected refreshApplicantToken (refreshToken: string, userId: number, clientIdentifier: string) {
    return lastValueFrom(this.http.post<TokenResponse>('/api/token/Refresh/Portal', {
      refreshToken,
      userId,
      clientIdentifier
    }));
  }

  protected refreshManagerToken (
    refreshToken: string,
    userId: number,
    clientIdentifier: string,
    clientId: number
  ) {
    return lastValueFrom(this.http.post<TokenResponse>('/api/token/Refresh/Manager', {
      refreshToken,
      userId,
      clientId,
      clientIdentifier
    }));
  }

  protected refreshAdminToken (refreshToken: string, userId: number, clientIdentifier: string) {
    return lastValueFrom(this.http.post<TokenResponse>('/api/token/Refresh/Admin', {
      refreshToken,
      userId,
      clientIdentifier
    }));
  }

  async refreshToken (
    refreshToken: string,
    userId: number,
    clientIdentifier: string,
    clientId: number
  ): Promise<TokenResponse> {
    try {
      let response: TokenResponse;
      if (this.portal.isPlatform) {
        response = await this.refreshAdminToken(refreshToken, userId, clientIdentifier);
      } else if (this.portal.isApply) {
        response = await this.refreshApplicantToken(refreshToken, userId, clientIdentifier);
      } else {
        response = await this.refreshManagerToken(refreshToken, userId, clientIdentifier, clientId);
      }

      return this.adapter.handleTokenRequest(response);
    } catch (err) {
      this.logger.error(err);

      return null;
    }
  }
}
