import { Injectable } from '@angular/core';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { RegexUI, RegexValidator, ValidatorMessageObj } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';


@Injectable({
  providedIn: 'root'
})
export class InputRegexService {
  mustBeValidEmailMessage = 'This must be a valid email address';
  mustBeValidEin = 'This must be a valid EIN';
  mustBeValidUrlReqHttp = 'This must be a valid URL with \'http\' or \'https\'';
  mustBeValidUrl = 'This must be a valid URL';
  mustBe24HourTime = 'This must be a valid 24 hour time';
  mustBe12HourTime = 'This must be a valid 12 hour time with AM or PM';
  mustAddUpTo100 = 'Must add up to 100%';

  constructor (
    private i18n: I18nService
  ) { }

  emailErrorObj: ValidatorMessageObj = {
    mustBeAValidEmail: {
      i18nKey: 'common:textMustBeAValidEmail',
      defaultValue: this.mustBeValidEmailMessage
    }
  };

  einErrorObj: ValidatorMessageObj = {
    mustBeAValidEIN: {
      i18nKey: 'common:textMustBeAValidEIN',
      defaultValue: this.mustBeValidEin
    }
  };

  urlHttpReqErrorObj: ValidatorMessageObj = {
    mustBeValidURLHttpReq: {
      i18nKey: 'common:textMustBeValidURLWithHttp',
      defaultValue: this.mustBeValidUrlReqHttp
    }
  };

  urlErrorObj: ValidatorMessageObj = {
    mustBeAValidURL: {
      i18nKey: 'common:textMustBeAValidURL',
      defaultValue: this.mustBeValidUrl
    }
  };

  time24HourErrorObj: ValidatorMessageObj = {
    mustBeAValidTime24: {
      i18nKey: 'common:textMustBeAValidTime24',
      defaultValue: this.mustBe24HourTime
    }
  };

  time12HourErrorObj: ValidatorMessageObj = {
    mustBeAValid12HourTime: {
      i18nKey: 'common:textMustBeAValidTime12',
      defaultValue: this.mustBe12HourTime
    }
  };

  /**
   * @param formatting: Enum for preset formats on text fields
   * @returns Formatting details will include the name of the option (eg. Email or URL), the regex for that format, a validator for formControls with a preset error message, and components of the validator message
   */
  getSelectedFormattingDetails (
    formatting: ReferenceFieldAPI.ReferenceFieldFormattingType,
    errorMessageOverride?: string
  ): RegexUI.FormattingDetails {
    let result: RegexUI.FormattingDetails;
    const errorObjOveride = errorMessageOverride ? {
      incorrectFormatCustomMsg: {
        i18nKey: '',
        defaultValue: '',
        errorMessage: errorMessageOverride
      }
    } : null;
    switch (formatting) {
      case (ReferenceFieldAPI.ReferenceFieldFormattingType.EMAIL):
        result = {
          name: this.i18n.translate('common:textEmail', {}, 'Email'),
          regex: ReferenceFieldAPI.ReferenceFieldFormattingValues.EMAIL,
          validator: RegexValidator(
            ReferenceFieldAPI.ReferenceFieldFormattingValues.EMAIL,
            errorObjOveride ?? this.emailErrorObj
          ),
          errorObj: this.emailErrorObj,
          errorKey: 'mustBeAValidEmail'
        };
        break;
      case (ReferenceFieldAPI.ReferenceFieldFormattingType.EIN):
        result = {
          name: this.i18n.translate('common:textEIN', {}, 'EIN'),
          regex: ReferenceFieldAPI.ReferenceFieldFormattingValues.EIN,
          validator: RegexValidator(
            ReferenceFieldAPI.ReferenceFieldFormattingValues.EIN,
            errorObjOveride ?? this.einErrorObj
          ),
          errorObj: this.einErrorObj,
          errorKey: 'mustBeAValidEIN'
        };
        break;
      case (ReferenceFieldAPI.ReferenceFieldFormattingType.URL_OPT_HTTP):
        result = {
          name: this.i18n.translate('common:textURL', {}, 'URL'),
          regex: ReferenceFieldAPI.ReferenceFieldFormattingValues.URL_OPT_HTTP,
          validator: RegexValidator(
            ReferenceFieldAPI.ReferenceFieldFormattingValues.URL_OPT_HTTP,
            errorObjOveride ?? this.urlErrorObj
          ),
          errorObj: this.urlErrorObj,
          errorKey: 'mustBeAValidURL'
        };
        break;
      case (ReferenceFieldAPI.ReferenceFieldFormattingType.URL_REQ_HTTP):
        result = {
          name: this.i18n.translate('common:textURLHttpRequired', {}, 'URL (http/https required)'),
          regex: ReferenceFieldAPI.ReferenceFieldFormattingValues.URL_REQ_HTTP,
          validator: RegexValidator(
            ReferenceFieldAPI.ReferenceFieldFormattingValues.URL_REQ_HTTP,
            errorObjOveride ?? this.urlHttpReqErrorObj
          ),
          errorObj: this.urlHttpReqErrorObj,
          errorKey: 'mustBeValidURLHttpReq'
        };
        break;
      case (ReferenceFieldAPI.ReferenceFieldFormattingType.TIME_12_HOUR):
        result = {
          name: this.i18n.translate('common:textTimeTwelveHour', {}, 'Time (12 hour)'),
          regex: ReferenceFieldAPI.ReferenceFieldFormattingValues.TIME_12_HOUR,
          validator: RegexValidator(
            ReferenceFieldAPI.ReferenceFieldFormattingValues.TIME_12_HOUR,
            errorObjOveride ?? this.time12HourErrorObj
          ),
          errorObj: this.time12HourErrorObj,
          errorKey: 'mustBeAValid12HourTime'
        };
        break;
      default:
      case (ReferenceFieldAPI.ReferenceFieldFormattingType.TIME_24_HOUR):
        result = {
          name: this.i18n.translate('common:textTimeTwentyFourHour', {}, 'Time (24 hour)'),
          regex: ReferenceFieldAPI.ReferenceFieldFormattingValues.TIME_24_HOUR,
          validator: RegexValidator(
            ReferenceFieldAPI.ReferenceFieldFormattingValues.TIME_24_HOUR,
            errorObjOveride ?? this.time24HourErrorObj
          ),
          errorObj: this.time24HourErrorObj,
          errorKey: 'mustBeAValidTime24'
        };
        break;
      }

      return result;
  }
}
