import { Injectable } from '@angular/core';
import { CollaboratorInfo } from '@core/typings/collaboration.typing';
import { ApplicationViewService } from '@features/application-view/application-view.service';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { CollaborationResources } from './collaboration.resources';
import { CollaborationState } from './collaboration.state';


@AttachYCState(CollaborationState)
@Injectable({ providedIn: 'root' })
export class CollaborationService extends BaseYCService<CollaborationState> {

  constructor (
    private collaborationResources: CollaborationResources,
    private applicationViewService: ApplicationViewService
  ) {
    super();
  }

  get collaboratorMap () {
    return this.get('collaboratorMap');
  }

  async resetCollaborators (
    applicationId: number,
    updateApplicationViewMap = false
  ) {
    this.applicationViewService.triggerActivityTabUpdate();
    await Promise.all([
      this.fetchCollaborators(applicationId),
      updateApplicationViewMap ?
        this.applicationViewService.setApplicationViewMap(applicationId) :
        null
    ]);
  }

  async fetchCollaborators (applicationId: number) {
    const collaborators = await this.collaborationResources.getCollaborators(
      applicationId
    );
    this.set('collaboratorMap', {
      ...this.collaboratorMap,
      [applicationId]: collaborators
    });

    return collaborators;
  }

  fetchCollaboratorsBulk (
    applicationIds: number[]
  ) {
    return this.collaborationResources.getCollaboratorsBulk(applicationIds);
  }

  async addCollaborator (
    applicationId: number,
    applicantInfo: CollaboratorInfo
  ) {
    await this.collaborationResources.addCollaborator(applicationId, applicantInfo);
    await this.resetCollaborators(applicationId);
  }

  async detachCollaborator (
    applicationId: number,
    applicantId: number,
    currentUserRemoved = false
  ) {
    await this.collaborationResources.detachCollaborator(
      applicationId,
      applicantId
    );
    if (!currentUserRemoved) {
      await this.resetCollaborators(applicationId);
    } else {
      this.applicationViewService.triggerActivityTabUpdate();
    }
  }

  async makeCollaboratorOwner (
    applicationId: number,
    applicantId: number,
    updateApplicationViewMap = false
  ) {
    await this.collaborationResources.makeCollaboratorOwner(
      applicationId,
      applicantId
    );
    await this.resetCollaborators(applicationId, updateApplicationViewMap);
  }

  async updateCollaboratorPermissions (
    applicationId: number,
    applicantInfo: CollaboratorInfo
  ) {
    await this.collaborationResources.updateCollaboratorPermissions(
      applicationId,
      applicantInfo
    );
    await this.resetCollaborators(applicationId);
  }

  async getApplicantsForPDF (
    applicationId: number,
    isMasked = false
  ) {
    if (!isMasked) {
      const collaborators = await this.fetchCollaborators(applicationId);

      return collaborators.map((collab) => {
        return {
          name: collab.firstName + ' ' + collab.lastName,
          addressString: collab.addressString,
          email: collab.email,
          phone: collab.phoneNumber
        };
      });
    }

    return [];
  }
}

