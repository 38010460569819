import { Component, Input } from '@angular/core';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { YCModalComponent } from '@yourcause/common/modals';

export enum EditConfirmModalResponse {
    Edit_Exising = 1,
    Edit_Latest = 2
}

@Component({
    selector: 'gc-edit-form-confirm-modal',
    templateUrl: './edit-form-confirm-modal.component.html',
    styleUrls: ['./edit-form-confirm-modal.component.scss']
})
export class EditFormConfirmModalComponent extends YCModalComponent<EditConfirmModalResponse> {
  @Input() formName: string;
  @Input() formRevisionNumber: number;
  EditConfirmModalResponse = EditConfirmModalResponse;

  constructor (
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  handlePrimaryClick () {
    this.closeModal.emit(EditConfirmModalResponse.Edit_Exising);
    this.analyticsService.emitEvent({
      eventName: 'Confirm edit form existing',
      eventType: EventType.Click,
      extras: null
    });
  }

  handleSecondaryClick () {
    this.closeModal.emit(EditConfirmModalResponse.Edit_Latest);
    this.analyticsService.emitEvent({
      eventName: 'Confirm edit form latest',
      eventType: EventType.Click,
      extras: null
    });
  }
}
