import { CancelReasons } from '@core/typings/ui/cancel-application.typing';
import { InKindAwardedItemApi } from '@features/in-kind/in-kind.typing';

export enum GrantActivityTypes {
  ApplicationReviewed = 1,
  ApplicationAdvanced = 2,
  ApplicationApproved = 3,
  ApplicationDeclined = 4,
  FormReviewed = 5,
  FormApproved = 6,
  FormDeclined = 7,
  ApplicationAwarded = 8,
  ApplicationOnHold = 9,
  FormRevisionRequest = 10,
  PaymentCreated = 11,
  ApplicationStatusChangedToInProgress = 12,
  ApplicationArchived = 13,
  ApplicationUnarchived = 14,
  ApplicationCycleUpdated = 15,
  RevisionRequestCanceled = 16,
  ApplicationNoLongerOnHold = 17,
  FormRevisionSubmitted = 18,
  ApplicationBudgetFundingSourceUpdated = 19,
  ApplicantAdded = 20,
  ApplicantRemoved = 21,
  ApplicantPermissionUpdated = 22,
  DueDateExtended = 23,
  FormRecalled = 24,
  BudgetAssigned = 25,
  ReminderSentToGrantManager = 26,
  NotifyOfStatusSent = 27,
  ApplicationMovedToAwaitingReview = 28,
  StatusUpdated = 29,
  TagAdded = 32,
  ApplicationDownloaded = 33,
  FormRevisionReminderSent = 34,
  FormRevisionCanceled = 35,
  AttachmentRemoved = 36,
  AttachmentAdded = 37,
  CommunicationRemoved = 38,
  CommunicationAdded = 39,
  OrganizationUpdated = 40,
  GrantManagerFormSubmitted = 41,
  ApplicantFormSubmitted = 42,
  ManualApplicantFormSent = 43,
  AutomaticApplicantFormSent = 44,
  ApplicationCreated = 45,
  ApplicationSubmitted = 46,
  RecommendedFundingAmountSet = 47,
  ProgramUpdated = 48,
  AwardUpdated = 49,
  AwardDeleted = 50,
  PaymentUpdated = 51,
  PaymentDeleted = 52,
  AmountRequestedUpdated = 53,
  PaymentStatusChange = 54,
  HistoricalDataImported = 55,
  ApplicationCanceled = 56,
  NominationCanceled = 57,
  FormSigned = 58,
  FormSignatureBypassed = 59,
  PaymentRemovedFromBatch = 60,
  PaymentBudgetFsUpdated = 61,
  // helper activity types for the UI
  InKindPaymentCreated = 201,
  InKindAwardCreated = 202,
  InKindAwardUpdated = 203,
  InKindPaymentUpdated = 204,
  InKindAmountRequestedUpdated = 205,
  InKindPaymentDeleted = 206,
  InkindAwardDeleted = 207
}


export interface ApplicationActivityFromApi {
  id: number;
  applicationId: number;
  applicantName: string;
  createdByName: string;
  grantProgramName: string;
  organizationName: string;
  workFlowLevelName: string;
  grantProgramCycleName: string;
  previousWorkFlowLevelName: string;
  previousGrantProgramCycleName: string;
  formName: string;
  activityTypeId: GrantActivityTypes;
  activityTypeName: string;
  comment: string;
  createdDate: string;
  isMasked: boolean;
  canViewMaskedApplicantInfo: boolean;
  isNomination: boolean;
  amountRequested: number;
  inKindAmountRequested: number;
  createdImpersonatedByName: string;
  assignedBudgetName: string;
  assignedFundingSourceName: string;
  applicantFullNameForActivity: string;
  grantManagerFullNameForActivity: string;
  formDueDate: string;
  applicantPermissions: {
    canManageApplicants: boolean;
    canReceiveEmails: boolean;
    isOwner: boolean;
  };
  workflowLevelAutomationRuleName: string;
  fileName: string;
  communicationSubject: string;
  previousOrganizationName: string;
  applicationTagList: string;
  amount: number;
  paymentInKindItems: InKindAwardedItemApi[];
  awardInKindItems: InKindAwardedItemApi[];
  managerFormResponsesRequired: number;
  managerFormResponsesCompleted: number;
  formId: number;
  isAutomated: boolean;
  archiveReasonCode: number;
  currencyRequested: string;
  currencyRequestedAmountEquivalent: number;
  recommendedFundingAmount: number;
  previousGrantProgramId: number;
  newGrantProgramId: number;
  grantActivityChangeJson: string;
  isInKindPaymentOrAward: boolean;
}

export interface ApplicationActivityForUi extends ApplicationActivityFromApi {
  descriptionArray: string[];
  descriptionString: string;
  isInKind: boolean;
  previousAmount: string;
  currentAmount: string;
  previousInKindItems: InKindAwardedItemApi[];
  currentInKindItems: InKindAwardedItemApi[];
  isPayment: boolean;
  isAwardOrPaymentUpdate: boolean;
  isAwardOrPaymentCreateOrDelete: boolean;
  grantActivityChange: GrantActivityChange;
}

export interface GrantActivityChange {
  previous: string;
  current: string;
  previousRecommendedFundingAmount: string;
  currentRecommendedFundingAmount: string;
  fieldType: GrantActivityChangeFieldType;
  currentInKindItems: InKindAwardedItemApi[];
  previousInKindItems: InKindAwardedItemApi[];
  canceledReason: CancelReasons;
  previousBudgetId: number;
  updatedBudgetId: number;
  previousFundingSourceId: number;
  updatedFundingSourceId: number;
  paymentId: number;
}

export enum PreviousToCurrentActivityEnum {
  NotApplicable = 1,
  ApplicantPermissionWithCurrentPrevious = 2,
  CycleUpdated = 3,
  AppRouted = 4,
  OrgUpdated = 5,
  ProgramUpdated = 6,
  InKindAwardUpdated = 7,
  InKindPaymentUpdated = 8,
  AmontRequestedUpdated = 9,
  InKindRequestedUpdated = 10,
  PaymentStatusChanged = 11,
  CashPaymentUpdated = 12,
  CashAwardUpdated = 13,
  PaymentBudgetFsUpdated = 14
}

export enum GrantActivityChangeFieldType {
  AmountRequestedUpdated = 1,
  InKindAmountRequestedUpdated = 2,
  PaymentStatusChange = 3
}

export interface ActivityHelper {
  icon: string;
  iconClass: string;
  nomTranslated: string;
  appTranslated: string;
}

export type ActivityHelpers = Record<GrantActivityTypes, ActivityHelper>;
