import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CurrencyService } from '@core/services/currency.service';
import { OpenCloseBudgetAPI } from '@core/typings/api/open-close-budget.typing';
import { Budget, BudgetDrilldownInfo, BudgetFundingSourceDetail } from '@core/typings/budget.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { TypeSafeFormBuilder, TypeSafeFormGroup, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
import { ArrayHelpersService } from '@yourcause/common/utils';
interface ActionGroup {
  actionType: OpenCloseBudgetAPI.RemainingFundsDecision;
}
interface FundingSourceGroup {
  [x: string]: number;
}

@Component({
  selector: 'yc-open-close-budget-modal',
  templateUrl: './open-close-budget-modal.component.html',
  styleUrls: ['./open-close-budget-modal.component.scss']
})
export class OpenCloseBudgetModalComponent extends YCModalComponent<OpenCloseBudgetAPI.CloseBudgetPayload | number> implements OnInit {
  @Input() budgets: Budget[];
  @Input() budgetDetails?: BudgetDrilldownInfo;
  @Input() context: 'open' | 'close';

  actionFormGroup: TypeSafeFormGroup<ActionGroup>;
  fsFormGroup: TypeSafeFormGroup<FundingSourceGroup>;
  fundingSourcesOptions: Record<string, TypeaheadSelectOption<number>[]>;
  OpenCloseBudgetAPI = OpenCloseBudgetAPI;
  showFSTable = false;
  primaryButtonText: string;
  modalHeader: string;
  modalSubHeader: string;
  fsWithAvailableFunds: BudgetFundingSourceDetail[];
  scenarioText: string;
  remainingFundsDecisionOptions: TypeaheadSelectOption<OpenCloseBudgetAPI.RemainingFundsDecision>[];
  hideFundingSources = this.clientSettingsService.hideFundingSources;
  enforceFsFormGroup = false;
  hasMoneyLeftover = true;

  constructor (
    private i18n: I18nService,
    private formBuilder: TypeSafeFormBuilder,
    private currencyService: CurrencyService,
    private arrayHelper: ArrayHelpersService,
    private analyticsService: AnalyticsService,
    private clientSettingsService: ClientSettingsService
  ) {
    super();
  }

  ngOnInit () {
    if (this.context === 'close' && !this.hideFundingSources) {
      if (this.budgets.length === 1) {
        const budget = this.budgets[0];
        this.hasMoneyLeftover = budget.totalAmountAvailable > 0;
      }
      if (this.hasMoneyLeftover) {
        this.fsWithAvailableFunds = this.budgetDetails?.budgetFundingSourceDetailModel
          .filter((bfs) => {
            return bfs.budgetFundingSourceAvailableAmount > 0;
          }) ?? [];
        this.setFundingSourceOptionsMap();
        const fsGroups = this.fsWithAvailableFunds.reduce((acc, _, index) => {
          return {
            ...acc,
            [index]: new UntypedFormControl(null, this.fundingSourcesOptions[index].length > 0 ? Validators.required : null)
          };
        }, {} as FundingSourceGroup);
        this.fsFormGroup = this.formBuilder.group<FundingSourceGroup>(
          fsGroups
        );
        this.showFSTable = (this.budgets.length === 1) &&
          Object.keys(this.fundingSourcesOptions).some((fso) => {
            return !!this.fundingSourcesOptions[fso].length;
          }) &&
          (this.fsWithAvailableFunds.length > 0);
      }
    }

    this.actionFormGroup = this.formBuilder.group<ActionGroup>({
      actionType: [
        OpenCloseBudgetAPI.RemainingFundsDecision.DO_NOTHING
      ]
    });
    this.setActionOptions();
    this.setModalScenario();
    this.setModalText();
    this.setEnforceFsFormGroup();
  }

  setEnforceFsFormGroup () {
    this.enforceFsFormGroup = this.showFSTable &&
      this.actionFormGroup?.value.actionType === OpenCloseBudgetAPI.RemainingFundsDecision.SELECT_BUDGET;
  }

  onSubmit () {
    const moveFSArray = [] as OpenCloseBudgetAPI.MoveFundingSourceToBudgetModel[];
    const budgetIds = this.budgets.map((budget) => {
      return budget.id;
    });
    if (this.actionFormGroup.value.actionType === OpenCloseBudgetAPI.RemainingFundsDecision.SELECT_BUDGET) {
      Object.keys(this.fsFormGroup.controls).forEach((control) => {
        moveFSArray.push({
          fromFundingSourceId: this.fsWithAvailableFunds[+control].fundingSourceId,
          toBudgetId: this.fsFormGroup.get(control).value
        });
      });
    }
    const payload = {
      budgetIds,
      remainingFundsDecisionId: this.actionFormGroup.value.actionType,
      moveFundingSourceToBudgetModels: moveFSArray
    };
    if (this.context === 'close') {
      this.closeModal.emit(payload);
    } else {
      this.closeModal.emit(this.budgets[0].id);
    }
    this.analyticsService.emitEvent({
      eventName: 'Open close budget modal submit',
      eventType: EventType.Click,
      extras: null
    });
  }

  setModalScenario () {
    switch (this.context) {
      case 'open':
        this.modalHeader = this.i18n.translate(
          'BUDGET:hdrOpenBudget',
          {},
          'Open Budget'
        );
        this.primaryButtonText = this.i18n.translate(
          'GLOBAL:textOpen',
          {},
          'Open'
        );
        break;
      case 'close':
        this.modalHeader = this.i18n.translate(
          'BUDGET:hdrCloseBudget',
          {},
          'Close Budget'
        );
        this.primaryButtonText = this.i18n.translate(
          'BUDGET:textCloseBudget',
          {},
          'Close budget'
        );
        break;
    }
  }

  setFundingSourceOptionsMap () {
    this.fundingSourcesOptions = this.fsWithAvailableFunds
      .reduce((acc, fs, index) => {
        const options = fs.budgetsUsingThisFs
          .filter((budget) => {
            return (budget.id !== this.budgets[0].id) && !budget.isClosed;
          })
          .map((budget) => {
            return {
              value: budget.id,
              label: budget.name
            };
          });

        return {
          ...acc,
          [index]: this.arrayHelper.sort(options, 'label')
        };
      }, {} as Record<string, TypeaheadSelectOption<number>[]>);
  }

  setActionOptions () {
    this.remainingFundsDecisionOptions = [this.hideFundingSources ? null : {
      value: OpenCloseBudgetAPI.RemainingFundsDecision.BACK_TO_FS,
      label: this.i18n.translate(
        'BUDGET:textAddUnusedFundsBack',
        {},
        'Add unused funds back into each source to be available for new budgets.'
      )
    }, {
      value: OpenCloseBudgetAPI.RemainingFundsDecision.DO_NOTHING,
      label: this.i18n.translate(
        'BUDGET:textDoNothingRemainingFunds',
        {},
        'Do nothing. These funds will become unavailable for payment.'
      )
    }].concat(this.showFSTable ? [{
      value: OpenCloseBudgetAPI.RemainingFundsDecision.SELECT_BUDGET,
      label: this.i18n.translate(
        'BUDGET:textAllocateRemainingFunds',
        {},
        'Allocate remaining funds to a new budget using the same source.'
      )
    }] : []).filter((item) => !!item);
  }

  setModalText () {
    const budget = this.budgets[0];
    switch (this.context) {
      case 'open':
        // No Bulk functionality for Open, so we can assume there's only one
        this.modalSubHeader = budget.name;
        if (budget.totalUnavailableAmount > 0) {
          this.scenarioText = this.i18n.translate(
            this.hideFundingSources ? 'BUDGET:textOpenBudgetNoFsText' : 'BUDGET:textOpenBudgetWithFundsText',
            {
              budgetName: budget.name,
              unusedFunds: this.currencyService.formatMoney(budget.totalUnavailableAmount)
            },
            this.hideFundingSources ?
              'Opening the __budgetName__ budget will make it avilable for future payments. There is __unusedFunds__ allocated to the budget  that is unavailable for payment due to the budget being closed. Open the budget to access these funds.' :
              'Opening the __budgetName__ budget will make the budget available for future payments. There is __unusedFunds__ allocated to the budget from attached funding sources that is unavailable for payment due to the budget being closed. Open the budget to access these funds.'
          );
        } else {
          this.scenarioText = this.i18n.translate(
            'BUDGET:textOpenBudgetWithoutFundsText',
            {
              budgetName: this.budgets[0].name
            },
            'Opening the __budgetName__ budget will make the budget available for future payments. All allocated funds to the budget have been used. Opening the budget will allow allocating additional funds.'
          );
        }
        break;
      case 'close':
        if (this.budgets.length === 1) {
          this.modalSubHeader = budget.name;
        }
        if (this.budgets.length > 1) {
          const hasActions = this.remainingFundsDecisionOptions.length > 1;
          this.scenarioText = this.i18n.translate(
            hasActions ? 'BUDGET:textCloseBudgetsWarning' : 'BUDGET:textCloseBudgetsDesc',
            {},
            hasActions ?
              'Closing the following budgets will prevent them from being used for future payments. Select an option below to determine what to do with the remaining funds.' :
              'Closing the following budgets will prevent them from being used for future payments.'
          );
        } else {
          if (this.hasMoneyLeftover && !this.hideFundingSources) {
            this.scenarioText = this.i18n.translate(
              'BUDGET:textCloseBudgetWithFundsAndReservedText',
              {
                budgetName: budget.name,
                remainingFunds: this.currencyService.formatMoney(
                  budget.totalAmountAvailable
                ),
                reservedAmount: this.currencyService.formatMoney(
                  budget.reservedAmount
                )
              },
              'Closing the __budgetName__ budget will prevent it from being used for future payments. There is __remainingFunds__ remaining and __reservedAmount__ reserved from attached funding sources. Select an option below to determine what to do with these funds.'
            );
          } else {
            this.scenarioText = this.i18n.translate(
              'BUDGET:textCloseBudgetWithoutFundsAndReservedText',
              {
                budgetName: budget.name
              },
              'Closing the __budgetName__ budget will prevent it from being used for future payments.'
            );
          }
        }

        break;
    }
  }
}
