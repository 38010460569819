import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';

@Injectable({ providedIn: 'root' })
export class FormFieldCategoryResources {

  constructor (
    private http: HttpRestService
  ) { }

  getCategories (): Promise<ReferenceFieldAPI.Category[]> {
    const endpoint = 'api/manager/referencefieldcategory';

    return this.http.get(endpoint);
  }

  bulkUpdateCategory (
    referenceFieldIds: number[],
    categoryId: number
  ) {
    const endpoint = 'api/manager/referencefieldcategory/BulkUpdateReferenceFieldCategory';

    return this.http.post(endpoint, {
      referenceFieldIds,
      categoryId
    });
  }

  createOrEditCategory (
    categoryId: number,
    name: string
  ): Promise<number> {
    const endpoint = categoryId ?
      `api/manager/referencefieldcategory/${categoryId}` :
      'api/manager/referencefieldcategory';

    return this.http.post(endpoint, {
      name
    });
  }

  deleteCategory (categoryId: number) {
    const endpoint = `api/manager/referencefieldcategory/${categoryId}`;

    return this.http.delete(endpoint);
  }

}

