import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { BaseApplication } from '@core/typings/application.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ApplicationAttachmentService } from '@features/application-view/application-attachments/application-attachments.service';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { ErrorForDisplay } from '@features/forms/form-renderer/form-error-summary/form-error-summary.component';
import { TypeToken } from '@yourcause/common';
import { TableDataDownloadFormat } from '@yourcause/common/files';
import { ModalFactory } from '@yourcause/common/modals';
import { isEqual } from 'lodash';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subscription } from 'rxjs';
import { ImportTableRowsModalComponent } from '../import-table-rows-modal/import-table-rows-modal.component';
import { TableFieldCrudModalComponent } from '../table-field-crud-modal/table-field-crud-modal.component';

@Component({
  selector: 'gc-form-table-field',
  templateUrl: './form-table-field.component.html',
  styleUrls: ['form-table-field.component.scss']
})
export class FormTableFieldComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: ReferenceFieldsUI.TableResponseRowForUi[];
  @Input() hideLabel: boolean;
  @Input() disabled: boolean;
  @Input() tabIndex: number;
  @Input() label: string;
  @Input() isFormBuilderView: boolean;
  @Input() description: string;
  @Input() rowsPerPage: number;
  @Input() parentFields: Partial<BaseApplication>;
  @Input() tooltipText: string;
  @Input() srOnlyLabel: boolean;
  @Input() field: ReferenceFieldAPI.ReferenceFieldDisplayModel;
  @Input() hiddenTableColumnKeys: string[];
  @Input() requiredOverrideKeys: string[];
  @Input() labelOverrideMap: Record<string, string> = {};
  @Input() errorMessages: ErrorForDisplay[] = [];
  @Input() showErrorSummary: boolean;
  @Input() component: FormDefinitionComponent;
  @Input() translations: Record<string, string> = {};
  @Input() notAutoSave: boolean;
  @Input() customLabelIcon: string;
  @Input() customLabelIconTooltip: string;
  @Input() customLabelIconClass: string;
  @Output() onDataChanged = new EventEmitter();

  rows: ReferenceFieldsUI.TableResponseRowForUi[];
  rowsForTable: ReferenceFieldsUI.TableResponseRowForUiMapped[];
  visibleColumns: ReferenceFieldsUI.TableFieldForUi[];
  sub = new Subscription();
  FieldTypes = ReferenceFieldsUI.ReferenceFieldTypes;
  defaultCurrency = this.clientSettingsService.defaultCurrency;
  TableDataDownloadFormat = TableDataDownloadFormat;
  afterInit = false;
  $stringArray = new TypeToken<string[]>();

  constructor (
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService,
    private modalFactory: ModalFactory,
    private clientSettingsService: ClientSettingsService,
    private spinnerService: SpinnerService,
    private applicationAttachmentService: ApplicationAttachmentService
  ) {
    /* This map holds the most recent value of rows after save */
    /* The main reason we need this is when we create new rows */
    /* This map will have the updated values for rowId */
    this.sub.add(
      this.formFieldTableAndSubsetService.changesTo$('applicationFormTableRowsMap')
        .subscribe((value) => {
          if (
            this.component &&
            this.field &&
            this.visibleColumns
          ) {
            if (
              this.parentFields?.applicationFormId ||
              this.parentFields?.revisionId
            ) {
              const key = this.formFieldTableAndSubsetService.getTableFormKey(
                this.parentFields.applicationFormId ||
                  this.parentFields.revisionId,
                this.field.referenceFieldId
              );
              const updatedRows = value[key];
              if (
                updatedRows &&
                !isEqual(updatedRows, this.rows)
              ) {
                this.setRowsFromData(updatedRows);
                this.emitRows();
              }
            }
          }
        })
    );
  }

  async ngOnInit () {
    if (this.component) {
      await this.setVisibleColumns();
      this.setRowsFromData();
    }
    this.afterInit = true;
  }

  async ngOnChanges (changes: SimpleChanges) {
    if (this.afterInit) {
      if (
        changes.field ||
        changes.hiddenTableColumnKeys ||
        changes.labelOverrideMap ||
        changes.data
      ) {
        this.spinnerService.startSpinner();
        this.visibleColumns = undefined;
        await this.setVisibleColumns();
        this.setRowsFromData();
        this.spinnerService.stopSpinner();
      }
    }
  }

  async setVisibleColumns () {
    await this.formFieldTableAndSubsetService.setColumnsForTable(
      this.field.referenceFieldId
    );
    this.visibleColumns = this.formFieldTableAndSubsetService.getVisibleTableColumns(
      this.field.referenceFieldId,
      this.hiddenTableColumnKeys,
      this.labelOverrideMap,
      this.translations
    );
  }

  setRowsFromData (data = this.data) {
    this.rows = [
      ...(data || [])
    ];
    this.rowsForTable = this.formFieldTableAndSubsetService.mapRowsForTable(
      this.rows,
      this.field.referenceFieldId,
      this.hiddenTableColumnKeys,
      this.labelOverrideMap,
      false,
      false,
      false
    );
  }

  emitRows () {
    this.onDataChanged.emit(this.rows);
  }

  async addNewRecord () {
    this.formFieldTableAndSubsetService.setTableFieldModalOpen(true);
    const response = await this.modalFactory.open(
      TableFieldCrudModalComponent,
      {
        formId: this.parentFields?.formId,
        tableLabel: this.label,
        tableReferenceFieldId: this.field.referenceFieldId,
        applicationId: this.parentFields.applicationId,
        applicationFormId: this.parentFields.applicationFormId,
        notAutoSave: this.notAutoSave,
        translations: this.translations,
        hiddenTableColumnKeys: this.hiddenTableColumnKeys ?? [],
        labelOverrideMap: this.labelOverrideMap || {},
        requiredOverrideKeys: this.requiredOverrideKeys
      }
    );
    this.formFieldTableAndSubsetService.setTableFieldModalOpen(false);
    if (response) {
      this.rows = [
        ...this.rows,
        response.row
      ];
      this.emitRows();
      if (response.addAnother) {
        this.addNewRecord();
      }
    }
  }

  async viewOrEditRecord (
    row: ReferenceFieldsUI.TableResponseRowForUiMapped,
    index: number,
    isView: boolean
  ) {
    this.formFieldTableAndSubsetService.setTableFieldModalOpen(true);
    const response = await this.modalFactory.open(
      TableFieldCrudModalComponent,
      {
        tableLabel: this.label,
        tableReferenceFieldId: this.field.referenceFieldId,
        isView,
        formId: this.parentFields?.formId,
        currentRow: row,
        disableNextBtn: this.rows[index+1] ? false : true,
        disablePreviousBtn: this.rows[index-1] ? false : true,
        applicationId: this.parentFields.applicationId,
        applicationFormId: this.parentFields.applicationFormId,
        translations: this.translations,
        hiddenTableColumnKeys: this.hiddenTableColumnKeys || [],
        labelOverrideMap: this.labelOverrideMap || {},
        requiredOverrideKeys: this.requiredOverrideKeys || []
      }
    );
    this.formFieldTableAndSubsetService.setTableFieldModalOpen(false);
    /* Edit Record */
    if (response && !isView) {
      this.rows = [
        ...this.rows.slice(0, index),
        response.row,
        ...this.rows.slice(index + 1)
      ];
      this.emitRows();
    }

    /* View Record */
    if (response && isView) {
      /* Open Next Record */
      if(response.openNext) {
        /* If the next record exists */
        if(this.rowsForTable[index+1]) {
          this.viewOrEditRecord(this.rowsForTable[index+1], index+1, true);
        }
      }

      /* Open Previous Record */
      if(response.openPrevious){
        /* If the previous record exists */
        if(this.rowsForTable[index-1]) {
            this.viewOrEditRecord(this.rowsForTable[index-1], index-1, true);
        }
      }
    }
  }

  openDeletePopover (popover: PopoverDirective) {
    setTimeout(() => {
      popover.show();
    });
  }

  deleteRecord (
    index: number
  ) {
    this.rows = [
      ...this.rows.slice(0, index),
      ...this.rows.slice(index + 1)
    ];
    this.emitRows();
  }

  async importToTable () {
    this.formFieldTableAndSubsetService.setTableFieldModalOpen(true);
    await this.modalFactory.open(
      ImportTableRowsModalComponent,
      {
        applicationId: this.parentFields.applicationId,
        applicationFormId: this.parentFields.applicationFormId,
        tableLabel: this.label,
        tableId: this.field.referenceFieldId,
        rowsForTable: this.formFieldTableAndSubsetService.mapRowsForTable(
          this.rows,
          this.field.referenceFieldId,
          this.hiddenTableColumnKeys,
          this.labelOverrideMap,
          false,
          true,
          true
        ),
        formId: this.parentFields.formId,
        hiddenTableColumnKeys: this.hiddenTableColumnKeys || [],
        labelOverrideMap: this.labelOverrideMap || {},
        translations: this.translations,
        requiredOverrideKeys: this.requiredOverrideKeys || []
      }
    );
    this.formFieldTableAndSubsetService.setTableFieldModalOpen(false);
  }

  async handleOpenFile (
    fileUrl: string
  ) {
    this.spinnerService.startSpinner();
    await this.applicationAttachmentService.openReferenceFieldFromUrl(fileUrl);
    this.spinnerService.stopSpinner();
  }

  downloadTable (format: TableDataDownloadFormat) {
    this.formFieldTableAndSubsetService.returnTableRowImportData(
      this.field.referenceFieldId,
      this.formFieldTableAndSubsetService.mapRowsForTable(
        this.rows,
        this.field.referenceFieldId,
        this.hiddenTableColumnKeys,
        this.labelOverrideMap,
        false,
        true,
        true
      ),
      true,
      format,
      this.hiddenTableColumnKeys,
      this.labelOverrideMap,
      this.requiredOverrideKeys,
      this.translations
    );
  }

  onPopoverReady (
    row: ReferenceFieldsUI.TableResponseRowForUiMapped,
    popover: PopoverDirective
  ) {
    if (!!popover) {
      row.popover = popover;
    }
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
