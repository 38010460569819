<yc-modal
  [modalHeader]="'common:hdrSpellCheck' | ycI18n: 'Spell Check'"
  [primaryButtonText]="'common:textMakeCorrections' | ycI18n: 'Make corrections'"
  [primaryDisabled]="bulkSelected.length === 0"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  (onCancel)="closeModal.emit()"
  (onPrimaryClick)="closeModal.emit(bulkSelected)">

  <div class="mb-3 font-550 font-875-rem">
    <yc-i18n
      key="common:textUseTheCheckboxesToSelectCorrections"
      defaultValue="We found __number__ correction(s). Use the checkboxes below to choose which corrections you'd like to apply to the form."
      [context]="{
        number: corrections.length
      }">
    </yc-i18n>
  </div>

  <yc-table
    [rows]="corrections"
    [bulkOptions]="bulkOptions"
    [bulkSelected]="bulkSelected"
    [hidePagination]="true"
    [maxHeightBeforeScroll]="'80vh'"
    [simple]="true"
    (bulkSelectedChanged)="bulkSelected = $event">

    <!-- Page -->
    <ng-container *ycTableColumn="'common:textPage';
      visible: hasPages,
      let row;">
      {{ row.pageName }}
    </ng-container>

    <!-- Component -->
    <ng-container *ycTableColumn="'common:textField';
      let row;">
      {{ row.componentTypeTranslated }}
    </ng-container>

    <!-- Attribute -->
    <ng-container *ycTableColumn="'common:textAttribute';
      let row;">
      {{ row.attributeTranslated }}
    </ng-container>

    <!-- Before -->
    <ng-container *ycTableColumn="'common:textBefore';
      let row;">
      {{ row.oldVal }}
    </ng-container>

    <!-- After -->
    <ng-container *ycTableColumn="'common:textAfter';
      let row;">
      {{ row.newVal }}
    </ng-container>
  </yc-table>

</yc-modal>