/// <reference path="../i18n/Translations.d.ts" />

import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DeepLinkingService } from '@core/services/deep-linking.service';
import { Behaviors } from '@core/services/token/token-behaviors';
import { User } from '@core/typings/client-user.typing';
import { SSOTokenKey } from '@core/typings/sso-configuration.typing';
import { TokenResponse } from '@core/typings/token.typing';
import { environment } from '@environment';
import { browserVersion } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { AppModule } from './app.module';

const base = document.head.querySelector('base')?.href;

if (base) {
  I18nService.i18nPrefix = base + './assets/i18n/';
}

(window as any).version = require('./app/environments/version.json');


interface TokenInput {
  data: {
    token: TokenResponse;
    user: User;
    clientIdentifier: string;
    attemptedRoute: string;
    ssoToken: string;
  };
}

const handleTokens = (input: TokenInput) => {
  const { data: message } = input;
  if (!!message) {
    localStorage.setItem(Behaviors[environment.routeBase].userKey, JSON.stringify(message.user));
    localStorage.setItem(Behaviors[environment.routeBase].userTokenKey, JSON.stringify(message.token));
    localStorage.setItem(Behaviors[environment.routeBase].clientIdentifierKey, message.clientIdentifier);
    const key = environment.routeBase === 'apply' ?
      DeepLinkingService.AttemptedRouteApplicantKey :
      environment.routeBase === 'management' ?
      DeepLinkingService.AttemptedRouteManagerKey :
      environment.routeBase === 'platform' ?
      DeepLinkingService.AttemptedRoutePlatformKey : '';

    if (key && message.attemptedRoute) {
      localStorage.setItem(key, message.attemptedRoute);
    }

    if (message.ssoToken) {
      localStorage.setItem(SSOTokenKey, message.ssoToken);
    }

    localStorage.setItem('handedOff', 'true');

    parent.postMessage({
      ycAcknowledged: true,
      ycMessage: message
    }, '*');

    document.removeEventListener('message', handleTokens as any);
  }

};

if (environment.production) {
  enableProdMode();
}
if (typeof(parent) !== 'undefined' && parent !== window) {
  parent.postMessage({
    ycLoaded: true
  }, `*`);

  window.addEventListener('message', handleTokens);
} else {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((moduleRef) => {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const componentRef = applicationRef.components[0];

      // this console allows us to profile the app (get performance metrics for the app) via the console
      console.log(() => enableDebugTools(componentRef));
    });
}

if (browserVersion === 'MSIE 9' || browserVersion === 'MSIE 10') {
  location.href = '/assets/browser-support.html';
}
