import { Applicant } from './applicant.typing';
import { User } from './client-user.typing';
import { LoginBehaviors } from './login-behaviors.typing';
import { TokenResponse } from './token.typing';

export const SSOTokenKey = `_yc_SSOToken`;

export interface SSOConfigurationFromApi {
  clientId: number;
  affiliateId: string;
  loginBehavior: LoginBehaviors;
  logoutURL: string;
  subDomain: string;
}

export interface SSOConfigurationForUi extends SSOConfigurationFromApi {
  ssoLoginUrl: string;
}

export interface SsoMessage {
  token: TokenResponse;
  user: User|Applicant;
  clientIdentifier: string;
  attemptedRoute: string;
  ssoToken: string;
}

interface YcLoaded {
  ycLoaded: boolean;
}

interface YcMessage {
  ycAcknowledged: boolean;
  ycMessage: any;
}

export interface SsoData {
  data: YcLoaded|YcMessage|string;
}
