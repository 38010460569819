<yc-modal *ngIf="ready"
  [modalHeader]="'common:hdrRemoveUserFromAudience' | ycI18n: 'Remove User From Audience'"
  [primaryButtonText]="'common:textRemove' | ycI18n: 'Remove'"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  (onCancel)="closeModal.emit()"
  (onPrimaryClick)="closeModal.emit(true)">

  <div class="mb-3">
    <yc-i18n
      [key]="hasUsage ?
        'common:textRemoveUserAudienceHasUsage2' :
        'common:textConfirmRemoveUserAudience2'"
      [context]="{
        userName: user.fullName,
        audienceName: audienceDetail.name
      }"
      [defaultValue]="hasUsage ?
        'See below for a list of places this audience is used. By removing this user, they may no longer have access to the listed items. Are you sure you want to remove __userName__ from the __audienceName__ audience?' : 
        'Are you sure you want to remove __userName__ from the __audienceName__ audience?'">
    </yc-i18n>
  </div>

  <gc-audience-usage-table *ngIf="hasUsage"
    [audienceId]="audienceId"
    [audienceUsage]="audienceDetail.usage">
  </gc-audience-usage-table>
</yc-modal>