import { Component, Input, OnInit } from '@angular/core';
import { WorkflowDetailForUi } from '@core/typings/workflow.typing';
import { OfflineGrantsService } from '@features/offline-grants/offline-grants.service';
import { SubmitApplicationOptions } from '@features/offline-grants/offline-grants.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { YCModalComponent } from '@yourcause/common/modals';
export interface OfflineGrantsSubmitModalResponse {
  runInitialWflAutomation: boolean;
  workflowLevelId: number;
  approveAfterSubmit: boolean;
}

@Component({
  selector: 'gc-offline-grants-submit-application-modal',
  templateUrl: './offline-grants-submit-application-modal.component.html',
  styleUrls: ['./offline-grants-submit-application-modal.component.scss']
})
export class OfflineGrantsSubmitApplicationModalComponent extends YCModalComponent<
  OfflineGrantsSubmitModalResponse
> implements OnInit{
  @Input() isNomination = false;
  @Input() workflow: WorkflowDetailForUi;
  @Input() defaultWorkflowLevelId: number;

  submitOptionValue = SubmitApplicationOptions.ENTER_DEFAULT_LEVEL;
  SubmitApplicationOptions = SubmitApplicationOptions;
  submitOptions: TypeaheadSelectOption[] = [];
  levelOptions: TypeaheadSelectOption[] = [];
  workflowLevelId: number;

  constructor (
    private offlineGrantsService: OfflineGrantsService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit () {
    this.workflowLevelId = this.defaultWorkflowLevelId;
    this.submitOptions = this.offlineGrantsService.getSubmitOptions(
      this.isNomination
    );
    const levelOptions: TypeaheadSelectOption[] = [];
    this.workflow.levels.forEach((level) => {
      if (!level.disabled) {
        levelOptions.push({
          label: level.name,
          value: level.id
        });
      }
      level.subLevels.forEach((sub) => {
        if (!sub.disabled) {
          levelOptions.push({
            label: sub.name,
            value: sub.id
          });
        }
      });
    });
    this.levelOptions = levelOptions;
  }

  submit (approveAfterSubmit = false) {
    this.closeModal.emit({
      runInitialWflAutomation: this.submitOptionValue === SubmitApplicationOptions.ENTER_DEFAULT_LEVEL,
      workflowLevelId: this.workflowLevelId,
      approveAfterSubmit
    });
    this.analyticsService.emitEvent({
      eventName: 'Submit offline grants application',
      eventType: EventType.Click,
      extras: null
    });
  }
}
