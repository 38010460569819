<div class="wrapper"
  [class.border]="currentDepth !== 0">

  <!-- Evaluation Options  -->
  <yc-typeahead-select *ngIf="currentDepth === 0"
    [label]="'common:textIWantTheFollowingValues' | ycI18n: 'I want the following values'"
    [items]="evaluationOptions"
    [disabled]="isViewOnly"
    [value]="step.type"
    [showClear]="false"
    (onChange)="updateEvaluationType($event)">
  </yc-typeahead-select>

  <div class="formula-actions">
    <!-- Swap -->
    <yc-button *ngIf="isDestructive"
      class="me-3"
      [link]="true"
      [disabled]="isViewOnly"
      [fontAwesomeIcon]="'sync'"
      [langKey]="'common:linkSwapOrder'"
      [defaultText]="'Swap order'"
      (onClick)="swapOrder()">
    </yc-button>
    
    <!-- Add Value -->
    <yc-button *ngIf="shouldShowAddButton"
      class="me-3"
      [fontAwesomeIcon]="'plus'"
      [disabled]="isViewOnly"
      [langKey]="'common:linkAddValue'"
      [defaultText]="'Add value'"
      [link]="true"
      (onClick)="addFixedValue()">
    </yc-button>

    <!-- Add New Operation -->
    <yc-button *ngIf="currentDepth < maxDepth"
      class="me-3"
      [fontAwesomeIcon]="'abacus'"
      [disabled]="isViewOnly"
      [langKey]="'common:linkAddNewOperation'"
      [defaultText]="'Add new operation'"
      [link]="true"
      (onClick)="addNestedOperation()">
    </yc-button>

    <!-- Delete Operation -->
    <yc-button *ngIf="currentDepth !== 0"
      [link]="true"
      [fontAwesomeIcon]="'times'"
      [textDanger]="true"
      [disabled]="isViewOnly"
      [langKey]="'common:linkDeleteOperation'"
      [defaultText]="'Delete operation'"
      (onClick)="removeStep.emit()">
    </yc-button>
  </div>

  <div *ngFor="let value of step.values; let index = index;"
    [ngSwitch]="value.type"
    class="value">
    <div class="placeholder">
      
      <!-- Nested Evaluation Options -->
      <yc-typeahead-select *ngIf="index === 1 && currentDepth !== 0"
        [showClear]="false"
        [disabled]="isViewOnly"
        [label]="'common:labelTheValuesShouldBe' | ycI18n:'The values should be evaluated with the following operation'"
        [srOnlyLabel]="true"
        [items]="nestedEvaluationOptions"
        [value]="step.type"
        (onChange)="updateEvaluationType($event)">
      </yc-typeahead-select>

      <!-- Evaluation Icon -->
      <ng-container *ngIf="index > 1 || (index > 0 && currentDepth === 0)"
        [ngSwitch]="step.type">
        <yc-fa *ngSwitchCase="FormulaEvaluationType.Add" 
          icon="plus">
        </yc-fa>
        <yc-fa *ngSwitchCase="FormulaEvaluationType.Average"
          icon="plus">
        </yc-fa>
        <yc-fa *ngSwitchCase="FormulaEvaluationType.Subtract"
          icon="minus">
        </yc-fa>
        <yc-fa *ngSwitchCase="FormulaEvaluationType.Multiply"
          icon="times">
        </yc-fa>
        <yc-fa *ngSwitchCase="FormulaEvaluationType.Divide"
          icon="divide">
        </yc-fa>
      </ng-container>
    </div>

    <!-- Value Type Options -->
    <yc-typeahead-select *ngIf="value.type !== FormulaStepValueType.NestedStep"
      [items]="valueTypeOptions"
      [value]="value.type"
      [disabled]="isViewOnly"
      (onChange)="updateValueType($event, index)">
    </yc-typeahead-select>

    <!-- Nested Step -->
    <gc-formula-builder-step *ngSwitchCase="FormulaStepValueType.NestedStep"
      [currentDepth]="currentDepth + 1"
      [maxDepth]="maxDepth"
      [step]="value.value | ycAs: $formulaStepType"
      [columns]="columns"
      [isViewOnly]="isViewOnly"
      (stepChange)="updateValue($event, index)"
      (removeStep)="removeValue(index)"
      (validityChanged)="nestedValidityChange($event, index)">
    </gc-formula-builder-step>

    <!-- Fixed Number -->
    <yc-input *ngSwitchCase="FormulaStepValueType.Fixed"
      [isNumber]="true"
      [disabled]="isViewOnly"
      [value]="value.value | ycAs: $number"
      (valueChange)="updateValue($event, index)">
    </yc-input>

    <!-- Parent Value -->
    <yc-typeahead-select *ngSwitchCase="FormulaStepValueType.ParentValue"
      [value]="value.value"
      [disabled]="isViewOnly"
      [items]="columns"
      (valueChange)="updateValue($event, index)">
    </yc-typeahead-select>

    <!-- Remove -->
    <yc-button *ngIf="shouldShowRemoveButton &&
      (value.type !== FormulaStepValueType.NestedStep)"
      [fontAwesomeIcon]="'times'"
      [textDanger]="true"
      [disabled]="isViewOnly"
      [tooltipText]="'common:textRemove' | ycI18n: 'Remove'"
      [icon]="true"
      (onClick)="removeValue(index)">
    </yc-button>
  </div>
</div>