
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { Award, MyAward } from './typings/award.typing';

@RegisterYCState()
export class AwardState extends BaseYcState {
  readonly awards: Award[];
  readonly myAwards: MyAward[];
  readonly awardNameMap: {
    [b: string]: string;
  } = {};
}
