import { FooterStates } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class FooterState extends BaseYcState {
  readonly footerState: FooterStates = FooterStates.STANDARD;

  readonly footerActionDisabled: boolean;
  readonly footerAction: () => any;
  readonly footerActionLabel: string;

  readonly footerCancelLabel: string;
  readonly footerCancelAction: () => any;

  readonly footerSecondaryLabel: string;
  readonly footerSecondaryDisabled: boolean;
  readonly footerSecondaryAction: () => any;

  readonly footerTertiaryLabel: string;
  readonly footerTertiaryDisabled: boolean;
  readonly footerTertiaryAction: () => any;

  readonly footerQuaternaryLabel: string;
  readonly footerQuaternaryDisabled: boolean;
  readonly footerQuaternaryAction: () => any;

  readonly footerInfoText: string;
  readonly footerInfoIcon: string;
  readonly footerInfoTooltip: string;
}
