import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseApplicationForLogic, FormAnswerValues, FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { FormLogicService } from '@features/forms/services/form-logic/form-logic.service';
import { FormulaBuilderModalComponent } from '@features/formula-builder/formula-builder-modal/formula-builder-modal.component';
import { FormulaBuilderService } from '@features/formula-builder/formula-builder.service';
import { RootFormula } from '@features/formula-builder/formula-builder.typing';
import { GlobalValueLogicGroup, LogicColumnDisplay, LogicValueFormatType } from '@features/logic-builder/logic-builder.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory } from '@yourcause/common/modals';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { SelectOption } from '@yourcause/common/core-forms';

@Component({
  selector: 'gc-formula-builder-manager',
  templateUrl: './formula-builder-manager.component.html',
  styleUrls: ['./formula-builder-manager.component.scss']
})
export class FormulaBuilderManagerComponent extends BaseComponentConfigSettingsComponent implements OnInit, OnChanges {
  @Input() formula: RootFormula<BaseApplicationForLogic>;
  @Input() component: FormDefinitionComponent;
  @Input() conditionalValue: GlobalValueLogicGroup<BaseApplicationForLogic, FormAnswerValues>[];
  @Output() onModalOpenOrClose = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<RootFormula<BaseApplicationForLogic>>();

  availableColumns: LogicColumnDisplay<BaseApplicationForLogic>[];
  noFormulaAlertMessage = this.i18n.translate(
    'common:textNoValueFormulaRulesHelp',
    {},
    `Click "Manage calculated value formula" below to manage calculated value logic to assign this component a value.`
  );
  alertMessage: string;
  options: (TypeaheadSelectOption|SelectOption)[] = [];
  logicValueFormatType: LogicValueFormatType;
  columns: TypeaheadSelectOption<string>[];
  logicEnabled: boolean;

  constructor (
    private i18n: I18nService,
    private modalFactory: ModalFactory,
    private formulaBuilderService: FormulaBuilderService,
    private formLogicService: FormLogicService,
    private componentHelper: ComponentHelperService,
    private formBuilderService: FormBuilderService,
    private arrayHelper: ArrayHelpersService
  ) {
    super();
  }


  ngOnInit () {
    this.setColumns();
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.conditionalValue) {
      this.logicEnabled = (this.conditionalValue?.length ?? 0) === 0;
      this.determineAlertText();

      if (!this.logicEnabled) {
        this.alertMessage = this.i18n.translate(
          'common:textFormulaBuilderUnavailable',
          {},
          '"Calculated Value" and "Set Value" cannot be used together, please clear any rules from the "Set Value" tab to continue.'
        );
      }
    }
  }

  setColumns () {
    const {
      availableColumns
    } = this.formLogicService.getAvailableLogicColumnsForComponent(
      this.component,
      false,
      this.formBuilderService.currentFormBuilderDefinition,
      this.formBuilderService.currentFormBuilderIndex,
      this.formBuilderService.currentFormBuilderFormAudience
    );
    this.columns = this.arrayHelper.sort(availableColumns.filter(column => {
      return column.type === 'number' || column.type === 'currency';
    }).map(column => {
      return {
        label: column.label,
        value: column.column.join('.') as string
      };
    }), 'label');
  }

  private determineAlertText () {
    if (this.logicEnabled) {
      if (this.formula?.step) {
        if (!this.columns) {
          this.setColumns();
        }
        this.alertMessage = this.formulaBuilderService.generateFormulaString<BaseApplicationForLogic>(this.formula, this.columns);
      } else {
        this.alertMessage = this.noFormulaAlertMessage;
      }
    }
  }

  async openLogicBuilderModal () {
    this.onModalOpenOrClose.emit(true);
    const otherFormulas: RootFormula<BaseApplicationForLogic>[] = this.formBuilderService.currentFormBuilderDefinition.reduce((acc, def) => {
      this.componentHelper.eachComponent(def.components, (comp) => {
        if (comp.formula && (comp.key !== this.component.key)) {
          acc.push(comp.formula);
        }
      });

      return [
        ...acc
      ];
    }, []);

    const formula: RootFormula<BaseApplicationForLogic> = this.formula;
    const rootProperty = this.component.type.split('-').join('.') as string;
    const response = await this.modalFactory.open<FormulaBuilderModalComponent<BaseApplicationForLogic>>(
      FormulaBuilderModalComponent as any,
      {
        columns: this.columns,
        formula,
        rootProperty,
        otherFormulas,
        isViewOnly: this.isViewOnly
      }
    );
    this.onModalOpenOrClose.emit(false);
    if (response) {
      this.formula = response.step ? response : { step: null, property: rootProperty };
      this.onChange.emit(this.formula);
    }
    this.determineAlertText();
  }
}
