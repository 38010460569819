import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { FeatureFlag, FeatureFlags } from '@core/typings/feature-flag.typing';
import { FeatureStatus } from '@yourcause/common/feature-flag';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsResources {
  constructor (
    private http: HttpRestService,
    private portal: PortalDeterminationService
  ) { }

  async updateFeatureFlagStatus (
    flag: FeatureFlags,
    status: FeatureStatus
  ) {
    await this.http.post(`/api/admin/Features/${flag}/status/${status}`, {});
  }

  getFeatureFlags () {
    return this.portal.isApply ?
      this.getApplicantFeatureFlags() :
      this.portal.isManager ?
        this.getManagerFeatureFlags() :
        this.getAdminFeatureFlags();
  }

  getAdminFeatureFlags (): Promise<FeatureFlag[]> {
    return this.http.get('/api/admin/Features');
  }


  async getManagerFeatureFlags (): Promise<FeatureFlag[]> {
    return this.http.get('/api/client/Features');
  }


  async getApplicantFeatureFlags (): Promise<FeatureFlag[]> {
    return this.http.get('/api/portal/applicant/Features');
  }
}
