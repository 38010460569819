import { Injectable } from '@angular/core';
import { FeatureFlag, FeatureFlags } from '@core/typings/feature-flag.typing';
import { FeatureFlagService as CommonFeatureFlagService, FeatureStatus } from '@yourcause/common/feature-flag';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { FeatureFlagsResources } from '../resources/feature-flags.resources';
import { FeatureFlagsState } from '../states/feature-flags.state';

export enum GC_Feature_Flags {
  ReferenceFieldTypeConverter = 1,
  ClientUserUpdate = 2,
  GrantProgramAutomation = 3,
  AddressFormField = 4,
  NewApplicantsUseBbid = 5,
  ImpactEgdeLink = 6
}

@Injectable({ providedIn: 'root' })
@AttachYCState(FeatureFlagsState)
export class FeatureFlagsService extends BaseYCService<FeatureFlagsState> {

  constructor (
    private resources: FeatureFlagsResources,
    private featureFlagService: CommonFeatureFlagService
  ) {
    super();
  }

  get featureFlags () {
    return this.get('featureFlags');
  }

  private adaptGCFeatureFlagsToCommonFlags (
    flags: FeatureFlag[]
  ): Record<number, FeatureStatus> {
    return flags.reduce((acc, flag) => ({
      ...acc,
      [flag.feature]: flag.status
    }), {});
  }

  async fetchFlags () {
    const flags = await this.resources.getFeatureFlags();

    const adaptedFeatureFlags = this.adaptGCFeatureFlagsToCommonFlags(flags);
    this.featureFlagService.setFeatureFlags(adaptedFeatureFlags);

    this.set('featureFlags', flags);
  }

  async updateFlag (
    flag: FeatureFlags,
    status: FeatureStatus
  ) {
    await this.resources.updateFeatureFlagStatus(flag, status);

    await this.fetchFlags();
  }
}
