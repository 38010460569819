import { ApplicationFormSignature, FormData, FormDecisionTypes, FormDefinitionComponent, FormDefinitionForUi, FormStatuses } from '@features/configure-forms/form.typing';
import { EmployeeSSOFieldsData } from '@features/employee-sso-fields/employee-sso-fields.typing';
import { SpecialHandling } from '@features/forms/form-renderer-components/standard-form-components/form-special-handling/form-special-handling.component';
import { InKindCategoryItemStat, InKindRequestedItem } from '@features/in-kind/in-kind.typing';
import { SimpleStringMap } from '@yourcause/common';
import { ReferenceFieldAPI } from './api/reference-fields.typing';
import { AdHocReportingUI } from './ui/ad-hoc-reporting.typing';
import { ReferenceFieldsUI } from './ui/reference-fields.typing';

export interface OrgInfoForPDF {
  name: string;
  addressString: string;
  registrationId: string;
  phone: string;
}

export interface ApplicantInfoForPDF {
  name: string;
  addressString: string;
  email: string;
  phone: string;
}

export interface ApplicationInfoForPDF {
  appId: number;
  isMasked: boolean;
  programName: string;
  cycleName: string;
  amountRequested: number|string;
  currencyRequestedAmountEquivalent: number;
  currencyRequested: string;
  inKindItems: InKindRequestedItem[];
  availableItemsForApplicant?: InKindCategoryItemStat[];
  specialHandling: SpecialHandling;
  status: string;
  submittedDate?: string;
  tags: string[];
  workflowLevelName: string;
  workflowName: string;
  careOf: string;
  reportFieldResponse: AdHocReportingUI.ReportFieldResponseRow;
  employeeInfo: EmployeeSSOFieldsData;
  designation: string;
}

export interface BaseFormInfoForPdf {
  formId: number;
  formDefinition: FormDefinitionForUi[];
}

export interface FormInfoForPDF extends BaseFormInfoForPdf {
  formName: string;
  formHeader?: string;
  formFooter?: string;
  formSubmittedOn: string;
  formSubmittedBy: string;
  formData: FormData;
  formStatus: FormStatuses;
  applicationFormId: number;
  referenceFields: ReferenceFieldsUI.RefResponseMap;
  decision: FormDecisionTypes;
  specialHandling: SpecialHandling;
  reviewerRecommendedFundingAmount: number;
  signature: ApplicationFormSignature;
  translations: SimpleStringMap<string>;
  richTextTranslations: SimpleStringMap<string>;
}

export interface GeneratePdfPayload {
  margins: {
    marginTop: string;
    marginBottom: string;
    marginLeft: string;
    marginRight: string;
  };
  headerTemplate: string;
  footerTemplate: string;
  htmlContent: string;
  applicationFormId?: number; // only passed when generating snapshot on form submission
}

export interface FileUploadForPDF {
  applicationId: number;
  applicationFormId: number;
  fileId: number;
  fileName: string;
  fileUrl: string;
}

export interface TableCsvForPdf {
  csv: string;
  referenceFieldId: number;
  fileName: string;
  rows: ReferenceFieldsUI.TableResponseRowForUiMapped[];
}

export interface CsvForDownload {
  csv: string;
  fileName: string;
}

export interface FormComponentForPdf extends FormDefinitionComponent {
  totalTableRows: number;
  referenceField: ReferenceFieldAPI.ReferenceFieldDisplayModel;
  visibleColumns: ReferenceFieldsUI.TableFieldForUi[];
  isTotaled: boolean;
  isLayoutComponent: boolean;
}

export const AppPdfFooterCss = `
.footer {
	 display: flex;
	 justify-content: space-between;
	 width: 100%;
	 font-size: 8px;
	 border-top: 1px solid gainsboro;
	 padding-top: 0.15in;
	 margin-left: 0.25in;
	 margin-right: 0.25in;
	 font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.footer .page-wrapper {
	 display: inline;
	 justify-content: flex-end;
	 margin-left: 0.125in;
	 white-space: nowrap;
}`;

export const DownloadFormCss = `
.pdf-wrapper {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #4a4d4f;
}
.pdf-wrapper .full-page {
  break-after: page;
}
.pdf-wrapper .page-header {
  border-bottom: 1px dashed gainsboro;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.pdf-wrapper .page-header .left-side-header {
  flex-basis: 75%;
}
.pdf-wrapper .page-header .form-desc {
  display: block;
  font-weight: 500;
}
.pdf-wrapper .page-header .sub-header {
  right: 0;
  font-weight: 300;
}
.pdf-wrapper .tab-name {
  text-decoration: underline;
  font-size: 22px;
  margin-bottom: 1rem;
}
.pdf-wrapper .form-page .form-response {
  margin-bottom: 1rem;
  break-inside: avoid;
}
.pdf-wrapper .form-page .form-response table th {
  vertical-align: bottom;
  border-top: none;
  border-right: 1px solid #dee2e6;
  padding: 0.75rem;
  font-weight: 550;
  font-size: 14px;
  max-width: 200px;
}
.pdf-wrapper .form-page .form-response table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  max-width: 200px;
}
.pdf-wrapper .form-page .form-response .standard-field {
  margin-bottom: 3rem;
}
.pdf-wrapper .form-page .form-response .select-text {
  margin-bottom: 1rem;
  font-size: 0.75rem;
}
.pdf-wrapper .form-page .form-response .component-label {
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.pdf-wrapper .form-page .form-response .component-label .required-label {
  color: red;
}
.pdf-wrapper .form-page .form-response .panel-title {
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 0.5rem;
}
.pdf-wrapper .checkbox-label {
  display: inline-flex;
  margin-left: 0.5rem;
}`;

export const AppPdfCss = `
${AppPdfFooterCss}
${DownloadFormCss}
.pdf-wrapper {
	font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
}
.pdf-wrapper .page-header {
  border-bottom: 1px dashed gainsboro;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.pdf-wrapper .page-header .right-side-header {
  font-weight: 500;
  text-align: right;
  font-size: 12px;
}
.pdf-wrapper .page-header .sub-header {
  font-weight: 300;
  font-size: 12px;
}
.pdf-wrapper .detail-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  break-after: page;
}
.comp-wrapper {
  break-inside: avoid;
}
.pdf-wrapper .detail-page .logo-wrapper {
  width: 100%;
  display: block;
}
.pdf-wrapper .detail-page .logo-wrapper img {
  width: 100px;
  display: block;
  margin: auto;
}
.pdf-wrapper .detail-page .detail-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right:  1rem;
}
.pdf-wrapper .detail-page .detail-section .applicant-block {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0.5rem;
}
.pdf-wrapper .detail-page .detail-section .detail-label {
  display: flex;
  width: 25%;
}
.pdf-wrapper .detail-page .detail-section .detail-info {
  display: flex;
  width: 75%;
  right: 0;
  text-align: right;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0.25rem;
  margin-right: 1rem;
}
.pdf-wrapper .detail-page .detail-section .detail-info .secondary {
  font-size: 12px;
}
.pdf-wrapper .form-page .tab-wrapper {
  margin-bottom: 2rem;
  margin-left: 1rem;
}
.pdf-wrapper .form-page .tab-wrapper .tab-name {
  font-size: 22px;
  margin-bottom: 1rem;
}
.pdf-wrapper .form-page .tab-wrapper .form-response {
  margin-left: 1rem;
  margin-bottom: 1rem;
  break-inside: auto;
}
.pdf-wrapper .form-page .tab-wrapper .form-response .component-label {
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.pdf-wrapper .form-page .tab-wrapper .form-response .component-label .required-label {
  color: red;
}
.pdf-wrapper .form-page .signature-block {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.pdf-wrapper .form-page .signature-block .signed-by-block {
  display: flex;
  justify-content: center;
}
.pdf-wrapper .form-page .signature-block .signature-container {
  display: flex;
  flex-wrap: wrap;
}
.pdf-wrapper .form-page .signature-block .signature-container .image-container {
  flex: 1;
  display: flex;
  justify-content: center;
}
.pdf-wrapper .form-page .signature-block .signature-container .image-container img {
  max-height: 100px;
}
.pdf-wrapper legend {
  font-weight: 700;
  font-size: 18px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
 .pdf-wrapper .layout-component-wrapper, .pdf-wrapper .table-component-cell, .pdf-wrapper .column-component {
	 padding: 1rem;
	 padding-top: 0;
}
 .pdf-wrapper .component-panel {
	 border: 1px solid gainsboro;
}
 .pdf-wrapper .component-panel legend {
	 background-color: rgba(0, 0, 0, .03);
	 padding: 1rem;
	 margin: 0 -1rem;
	 border-bottom: 1px solid gainsboro;
}
 .pdf-wrapper .component-columns, .pdf-wrapper .component-table {
	 border: none;
}
 .pdf-wrapper .component-well {
	 border: 1px solid gainsboro;
}
 .pdf-wrapper .component-fieldset {
	 padding: none;
}
.pdf-wrapper .layout-component-wrapper {
  margin-top: 1rem;
  border-radius: 5px;
}
 .pdf-wrapper .table-component-wrapper {
	 display: flex;
	 flex-direction: column;
	 justify-content: space-around;
}
 .pdf-wrapper .table-component-wrapper .table-component-row {
	 display: flex;
	 border-top: 1px solid gainsboro;
}
 .pdf-wrapper .table-component-wrapper .table-component-row .table-component-cell {
	 flex: 1 0 0%;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.table-cell {
  width: 40%;
  word-break: break-word;
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid #dee2e6;
}
.table-row-label {
  width: 50%;
  margin-right: 1rem;
  font-weight: 550;
}
.table-row-answer {
  width: 50%;
}
.table-cell-not-last {
  margin-right: 1rem;
}
.flex-column {
  flex-direction: column;
}
.lowercase {
  text-transform: lowercase;
}
table {
  width: 100%;
}
table th {
  vertical-align: bottom;
  border-top: none;
  text-align: left;
  border-right: 1px solid #dee2e6;
  padding: 0.75rem;
  font-weight: 550;
  font-size: 13px;
  max-width: 200px;
}
table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  max-width: 200px;
  font-size: 13px;
  word-wrap: break-word;
}
.award-header {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.award-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.award-section .left {
  flex-basis: 30%;
  margin-right: 1rem;
}
.award-section .left .left-award-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.award-section .right {
  flex-basis: 70%;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.col-sm-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-sm-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-sm-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-sm-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-sm-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-sm-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-sm-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-sm-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-sm-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-sm-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-sm-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-sm-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.ms-1 {
  margin-left: 0.5rem;
}
.me-2 {
  margin-right: 0.75rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.ps-0 {
  padding-left: 0;
}
.pe-3 {
  padding-right: 1rem;
}
.ps-3 {
  padding: 1rem;
}`;

export const EmailPdfCss = `
.email-wrapper {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #4a4d50;
  font-size: 13px;
  display: flex;
  margin-bottom: 3rem;
  width: 100%;
  break-after: page;
}
.email-wrapper .email-info {
  margin-bottom: 1rem;
  font-weight: 550;
}
.program-email-container {
  margin-bottom: 1.5rem;
  border: 1px solid #f0f0f0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2rem;
  font-size: 13px;
}
center > table {
  background-color: #f0f0f0;
  border-collapse: unset !important;
}
center > table table {
	border-collapse: unset !important;
}
.left-side {
  width: 25%;
  margin-right: 2rem;
  vertical-align: top;
}
.right-side {
  display: flex;
  width: 70%;
  vertical-align: top;
  margin-bottom: 3rem;
}`;

export const EmailHeaderBlockCss = `
.email-header-block {
  text-align: center;
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-header-block div {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
}`;

export const EmailsCss = `
${EmailHeaderBlockCss}
${EmailPdfCss}
div.rich-email-content table[bgcolor="#606972"] {
	display: none;
}
div.rich-email-content center {
	background-color: inherit !important;
}
div.rich-email-content table[align="center"] {
	background-color: inherit;
}
div.rich-email-content html, div.rich-email-content body {
  margin: 0 auto !important;
  padding: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
div.rich-email-content * {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
div.rich-email-content div[style*="margin: 16px 0"] {
  margin: 0 !important;
}
div.rich-email-content table tr, div.rich-email-content td tr {
  border-left: none;
}
div.rich-email-content ul li {
  font-size: 13px !important;
}
div.rich-email-content.is-modal-view table[align="center"] {
  width: auto !important;
}
div.rich-email-content table[align="left"] {
  display: none;
}
div.rich-email-content table table table {
  table-layout: auto;
}
div.rich-email-content img {
  -ms-interpolation-mode: bicubic;
}
div.rich-email-content *[x-apple-data-detectors], div.rich-email-content .x-gmail-data-detectors, div.rich-email-content .x-gmail-data-detectors *, div.rich-email-content .aBn {
  border-bottom: 0 !important;
  cursor: default !important;
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}
div.rich-email-content .a6S {
  display: none !important;
  opacity: 0.01 !important;
}
div.rich-email-content img.g-img + div {
	display: none !important;
}
div.rich-email-content .button-link {
	text-decoration: none !important;
}
div.rich-email-content .email-container {
	border: 1px solid gainsboro;
	width: 500px !important;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
  div.rich-email-content .email-container {
		min-width: 375px !important;
	}
}
@media screen and (max-width: 480px) {
	div.rich-email-content div > u ~ div .gmail {
		min-width: 100vw;
	}
}
div.rich-email-content .button-td, div.rich-email-content .button-a {
  transition: all 100ms ease-in;
}
div.rich-email-content .button-td:hover, div.rich-email-content .button-a:hover {
  opacity: 0.9;
}
@media screen and (max-width: 600px) {
  div.rich-email-content .email-container {
    width: 100% !important;
    margin: auto !important;
	}
  div.rich-email-content .fluid {
    max-width: 100% !important;
    height: auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
	}
  div.rich-email-content .stack-column, div.rich-email-content .stack-column-center {
    display: block !important;
    width: 100% !important;
    max-width: 100% !important;
    direction: ltr !important;
	}
	div.rich-email-content .stack-column-center {
		text-align: center !important;
	}
  div.rich-email-content .center-on-narrow {
    text-align: center !important;
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
	}
	div.rich-email-content table.center-on-narrow {
		display: inline-block !important;
	}
}
 `;


export enum PdfType {
  APP = 1,
  FORM = 2,
  EMAIL = 3
}

export const PdfCssByType = {
  [PdfType.APP]: AppPdfCss,
  [PdfType.FORM]: DownloadFormCss,
  [PdfType.EMAIL]: EmailsCss
};
