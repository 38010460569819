import { Component, OnInit, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { BaseApplication } from '@core/typings/application.typing';
import { DATE_SSO_FIELDS } from '@features/employee-sso-fields/employee-sso-fields.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { BaseFormComponent } from '../../base/base.component';
import { TypeSafeFormBuilder } from '@yourcause/common/core-forms';
import { DateService } from '@yourcause/common/date';

@Component({
  selector: 'gc-form-employee-sso-field',
  templateUrl: './form-employee-sso-field.component.html',
  styleUrls: ['./form-employee-sso-field.component.scss']
})
export class FormEmployeeSSOFieldComponent extends BaseFormComponent<string> implements OnInit {

  constructor (
    private dateService: DateService,
    formBuilder: TypeSafeFormBuilder,
    formBuilderService: FormBuilderService,
    componentHelper: ComponentHelperService,
    @Optional() formGroupDir: FormGroupDirective,
    @Optional() renderer: FormRendererComponent<BaseApplication>
  ) {
    super(renderer, formGroupDir, formBuilder, formBuilderService, componentHelper);
  }

  async ngOnInit () {
    super.ngOnInit();
    const attr = this.extractFieldAttr();
    const isDate = DATE_SSO_FIELDS.includes(attr);
    let formVal = this.data;
    if (isDate) {
      if (this.data && !Array.isArray(this.data)) {
        formVal = this.dateService.formatDate(this.data);
      }
    }
    this.setFormGroup(formVal || '', []);
  }


  extractFieldAttr () {
    return this.comp.type.split('-')[1];
  }
}
