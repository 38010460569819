import { Component, OnDestroy } from '@angular/core';
import { GcFlyoutService } from '@core/services/gc-flyout.service';
import { AudienceService } from '@features/audience/audience.service';
import { I18nService } from '@yourcause/common/i18n';
import { Subscription } from 'rxjs';
import { AudienceDetail, AudienceUsageForUi } from '../audience.typing';
import { UserService } from '@features/users/user.service';

@Component({
  selector: 'gc-audience-detail-flyout',
  templateUrl: './audience-detail-flyout.component.html',
  styleUrls: ['./audience-detail-flyout.component.scss']
})
export class AudienceDetailFlyoutComponent implements OnDestroy {
  audience: AudienceDetail;
  usersSubHeader: string;
  usageSubHeader: string;
  usage: AudienceUsageForUi[] = [];
  sub = new Subscription();

  constructor (
    private gcFlyoutService: GcFlyoutService,
    private i18n: I18nService,
    private audienceService: AudienceService,
    private userService: UserService
  ) {
    this.sub.add(this.gcFlyoutService.changesTo$('currentIdForFlyout').subscribe(() => {
      this.setHelpers();
    }));
    this.sub.add(this.audienceService.changesTo$('audienceDetailMap').subscribe(() => {
      this.setHelpers();
    }));
  }

  get audienceId () {
    return +this.gcFlyoutService.currentIdForFlyout;
  }

  setHelpers () {
    const audience = this.audienceService.audienceDetailMap[this.audienceId];
    if (!!audience) {
      this.audience = audience;
      this.usersSubHeader = this.i18n.translate(
        this.audience.members.length === 1 ? 'common:textOneUser' : 'common:textNumberUsers',
        {
          number: this.audience.members.length
        },
        this.audience.members.length === 1 ?
          'One user' :
          '__number__ users'
      );
      this.usage = this.audienceService.adaptAudienceUsage(this.audience.usage);
      this.usageSubHeader = this.i18n.translate(
        this.usage.length === 1 ? 'common:textUsedInOnePlace' : 'common:textUsedInNumberPlaces',
        {
          number: this.usage.length
        },
        this.usage.length === 1 ?
          'Used in one place' :
          'Used in __number__ places'
      );
    }
  }

  async editAudience () {
    await this.audienceService.handleAudienceModal(this.audience);
    this.userService.resetUserAudienceMap();
  }

  ngOnDestroy (): void {
    this.sub.unsubscribe();
  }
}
