import { Address } from '@yourcause/common';
import { YcFile } from '@yourcause/common/files';
import { ProcessingTypes } from '../payment.typing';

export namespace AddOrgUI {
  export enum AddOrgModalPages {
    ID_LOOKUP,
    DETAILS,
    ADDRESS,
    CONFIRMATION
  }
  export enum SelectionType {
    PRIVATE_ORG,
    VETTED_ORG,
    UNVETTED_ORG,
    NEW_ORG
  }
  export interface OrgLookupPayload {
    id: string;
    country: string;
  }
  export interface AddOrgPayload extends OrgLookupPayload {
    name: string;
    address: Address;
    website?: string;
    registrationId: string;
    image?: YcFile;
    imageUrl?: string;
    imageName?: string;
  }
  export interface VettingContactInfo {
    contactFullName: string;
    contactEmail: string;
    contactWebsite: string;
  }
  export interface ProgramInfo {
    programId: number;
    processorType: ProcessingTypes;
    cycleId: number;
    clientId: number;
  }
}
