<yc-modal *ngIf="formGroup"
  [formGroup]="formGroup"
  [modalHeader]="'MANAGE:hdrAlternateAddressRequest' | ycI18n: 'Alternate Address Request'"
  [modalSubHeader]="'MANAGE:hdrPaymentIdDynamic' | ycI18n: 'PaymentID:__paymentId__' : {
    paymentId
  }"
  [primaryButtonText]="isViewOnly ? 
    ('common:textClose' | ycI18n: 'Close') :
    ('common:btnSave' | ycI18n: 'Save')"
  [cancelButtonText]="isViewOnly ?
    '' :
    'common:btnCancel' | ycI18n"
  [primaryDisabled]="isViewOnly ? false : formGroup.invalid || saving"
  (onCancel)="closeModal.emit()"
  (onPrimaryClick)="save()">

  <div *ngIf="!isViewOnly">
    <!-- Alert -->
    <yc-alert *ngIf="!isClientProcessed"
      alertClass="danger"
      customIcon="exclamation-circle"
      [message]="'MANAGE:textImportantChangesMadePaymentOverride2' | ycI18n: 'Important: ANY Alternate Address Request made for this payment must be approved by Blackbaud Compliance. If approved, the payment will be generated as a check instead of ACH.'">
    </yc-alert>
  
    <!-- Update Address Checkbox-->
    <div class="d-flex mt-2">
      <yc-checkbox
        name="updateAddress"
        [optionLabel]="'MANAGE:textThisPaymentRequiresAChangeOfAddress' | ycI18n: 'This payment requires a change of address'">
      </yc-checkbox>
    </div>
  </div>

  <ng-container *ngIf="isViewOnly || formGroup.value.updateAddress">
    <!-- Name -->
    <div>
      <yc-input
        name="name"
        [label]="'common:lblName' | ycI18n: 'Name'"
        [disabled]="isViewOnly || !isClientProcessed">
      </yc-input>
    </div>

    <!-- Address Block -->
    <div>
      <gc-address-block
        [addressFormGroup]="formGroup"
        [enforceCustomStateValidation]="false"
        [disableInputs]="isViewOnly">
      </gc-address-block>
    </div>

    <!-- File Upload -->
    <div class="mt-3">
      <yc-multi-dropzone-input
        name="file"
        [label]="'common:textUploadDocumentAddressRequest' | ycI18n: 'Upload supporting document for alternative address request'"
        [disabled]="isViewOnly"
        [fileDownloadFunc]="downloadFile"
        (onChange)="onFileChange()">
      </yc-multi-dropzone-input>
    </div>

    <!-- Reason -->
    <div>
      <yc-textarea
        name="reason"
        [disabled]="isViewOnly"
        [label]="'common:textReasonForAlternativeAddressRequest' | ycI18n: 'Reason for alternative address request'">
      </yc-textarea>
    </div>

    <!-- Notes -->
    <div>
      <yc-textarea
        name="notes"
        [disabled]="isViewOnly"
        [label]="'GLOBAL:NOTES' | ycI18n: 'Notes'">
      </yc-textarea>
    </div>
  </ng-container>
</yc-modal>