<div *ngIf="formGroup"
  [formGroup]="formGroup">

  <!-- Allow Multiple? -->
  <div class="mb-3">
    <yc-checkbox
      name="allowMultiple"
      [disabled]="isViewOnly"
      [optionLabel]="'common:textAllowApplicantToRequestMulti' | ycI18n: 'Allow applicant to request multiple items'"
      (onChange)="onSettingsChange()">
    </yc-checkbox>
  </div>

  <!-- Max Items -->
  <div *ngIf="formGroup.value.allowMultiple"
    class="mb-3">
    <yc-input
      name="maxItems"
      [disabled]="isViewOnly"
      [isNumber]="true"
      [numberOfDecimals]="0"
      [label]="'common:textMaxItemsRequested' | ycI18n: 'Maximum items that can be requested'"
      (onChange)="onSettingsChange()">
    </yc-input>
  </div>

  <!-- Show In-Kind Item Values? -->
  <div class="mb-3">
    <yc-checkbox
      name="displayInKindValues"
      [optionLabel]="'common:textDisplayInKindItemValues' | ycI18n: 'Display in-kind item values'"
      (onChange)="onSettingsChange()">
    </yc-checkbox>
  </div>

  <!-- Show Category? -->
  <div class="mb-3">
    <yc-checkbox
      name="showCategory"
      [disabled]="isViewOnly"
      [optionLabel]="'common:textShowCategoryForFiltereingOfItems' | ycI18n: 'Show in-kind categories to allow for easier filtering of items'"
      (onChange)="onSettingsChange()">
    </yc-checkbox>
  </div>

  <!-- Category Selector -->
  <yc-typeahead-select
    class="d-block pb-2"
    name="categories"
    [disabled]="isViewOnly"
    [label]="'GLOBAL:textCategories' | ycI18n: 'Categories'"
    [multi]="true"
    [items]="categories"
    (onChange)="setItems()">
  </yc-typeahead-select>

  <!-- Items Selector -->
  <yc-checkbox-group *ngIf="items"
    class="d-block pb-2"
    name="items"
    [disabled]="isViewOnly"
    [maxFilteredResults]="0"
    [filtered]="true"
    [filterObjectName]="'common:lblItem' | ycI18n:'Item'"
    [filterObjectNamePlural]="'common:lblItems' | ycI18n:'Items'"
    [filteredTableView]="true"
    [label]="'CONFIG:textItemsThatCanBeRequested' | ycI18n:'Items available for request'"
    [options]="itemOptions"
    (onChange)="onSettingsChange()">
  </yc-checkbox-group>
</div>
