<div>
  <!-- Allow Recused? -->
  <div class="mb-3">
    <yc-checkbox
      name="allowRecused"
      [disabled]="isViewOnly"
      [optionLabel]="'common:textAddOptionAllowingRecusal' | ycI18n: 'Add option allowing reviewer to recuse themselves due to conflicts of interest.  Recused responses will not be included in decision calculations.'"
      [(value)]="allowRecused"
      (valueChange)="onSettingChange()">
    </yc-checkbox>
  </div>

  <!-- Recused Text -->
  <div *ngIf="allowRecused">
    <yc-input
      name="recuseValue"
      [disabled]="isViewOnly"
      [(value)]="recuseValue"
      [label]="'common:textForRecuse' | ycI18n: 'Text for recuse'"
      (valueChange)="onSettingChange()">
    </yc-input>
  </div>
</div>