import { NgModule } from '@angular/core';
import { GCCoreModule } from '@core/_core.module';
import { AudienceDetailFlyoutComponent } from './audience-detail-flyout/audience-detail-flyout.component';
import { AudienceModalComponent } from './audience-modal/audience-modal.component';
import { AudienceUsageModalComponent } from './audience-usage-modal/audience-usage-modal.component';
import { AudienceUsageTableComponent } from './audience-usage-table/audience-usage-table.component';
import { AudienceUsersDisplayModalComponent } from './audience-users-display-modal/audience-users-display-modal.component';
import { AudiencesPageComponent } from './audiences-page/audiences-page.component';
import { ManageAudienceTableComponent } from './manage-audience-table/manage-audience-table.component';
import { RemoveUserFromAudienceModalComponent } from './remove-user-from-audience-modal/remove-user-from-audience-modal.component';

@NgModule({
  imports: [
    GCCoreModule
  ],
  declarations: [
    AudiencesPageComponent,
    AudienceModalComponent,
    ManageAudienceTableComponent,
    AudienceUsersDisplayModalComponent,
    AudienceUsageModalComponent,
    AudienceUsageTableComponent,
    RemoveUserFromAudienceModalComponent,
    AudienceDetailFlyoutComponent
  ],
  exports: [
    ManageAudienceTableComponent,
    AudiencesPageComponent,
    AudienceUsersDisplayModalComponent,
    AudienceUsageTableComponent,
    RemoveUserFromAudienceModalComponent
  ]
})
export class AudienceModule { }
