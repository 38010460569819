import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';
export interface DecisionSettingsChange {
  allowRecused: boolean;
  recuseValue: string;
}

@Component({
  selector: 'gc-decision-recused-settings',
  templateUrl: './decision-recused-settings.component.html',
  styleUrls: ['./decision-recused-settings.component.scss']
})
export class DecisionRecusedSettingsComponent extends BaseComponentConfigSettingsComponent {
  @Input() allowRecused: boolean;
  @Input() recuseValue: string;

  @Output() onChange = new EventEmitter<DecisionSettingsChange>();

  onSettingChange () {
    this.onChange.emit({
      allowRecused: this.allowRecused,
      recuseValue: this.recuseValue
    });
  }

}
