import { Injectable } from '@angular/core';
import { CurrencyResources } from '@core/resources/currency.resources';
import { CurrencyState } from '@core/states/currency.state';
import currencyFormatting from '@core/static-assets/currency-formatting';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { CurrencyFormatting, CurrencyFormattingObj, CurrencyRadioOptions, ExchangeRate, MoneyService } from '@yourcause/common/currency';
import { I18nService } from '@yourcause/common/i18n';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { SpinnerService } from './spinner.service';
import { ArrayHelpersService } from '@yourcause/common/utils';
export const Set_Value_Key_Prefix = 'SET_VALUE_';

@AttachYCState(CurrencyState)
@Injectable({ providedIn: 'root' })
export class CurrencyService extends BaseYCService<CurrencyState> {

  constructor (
    private currencyResources: CurrencyResources,
    private spinnerService: SpinnerService,
    private clientSettingsService: ClientSettingsService,
    private moneyService: MoneyService,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService
  ) {
    super();

    this.i18n.language$.subscribe(() => {
      this.setCurrencyFormatting();
    });
  }

  get exchangeRateMap () {
    return this.get('exchangeRateMap');
  }

  get precisionMap () {
    return this.get('precisionMap');
  }

  get formattingData () {
    return this.get('formattingData');
  }

  makeNumberPrecise (
    amount: number,
    currency = this.clientSettingsService.defaultCurrency
  ) {
    if (!amount && amount !== 0) {
      return amount;
    }

    return +((+amount).toFixed(this.precisionMap[currency]));
  }

  convertToDefault (
    amount: number,
    rate = 1
  ) {
    const converted = amount * rate;

    return this.makeNumberPrecise(converted);
  }

  convertToEquivalent (
    amount: number,
    currency: string,
    rate = 1
  ) {
    const converted = amount / rate;

    return this.makeNumberPrecise(converted, currency);
  }

  formatMoney (
    amount: string|number,
    currency = this.clientSettingsService.defaultCurrency,
    showCurrencyAtEnd = false
  ) {
    const formatted = this.moneyService.formatMoney(
      amount,
      currency,
      this.formattingData[currency]
    );

    return showCurrencyAtEnd ? `${formatted} ${currency}` : formatted;
  }

  setPrecisionMap () {
    if (Object.keys(this.precisionMap).length === 0) {
      const map: {
        [x: string]: number;
      } = {};
      Object.keys(this.formattingData).map((key) => {
        const item = (this.formattingData)[key];
        map[key] = item.precision;
      });
      this.set('precisionMap', map);
    }
  }

  setCurrencyFormatting () {
    const { formattingInfo } = currencyFormatting as CurrencyFormatting;
    const formatted: Record<string, CurrencyFormattingObj> = {};
    Object.keys(formattingInfo).forEach((key) => {
      const info = formattingInfo[key];
      formatted[key.toUpperCase()] = {
        ...info,
        thousand: this.i18n.numberFormattingData.group || info.thousand,
        decimal: this.i18n.numberFormattingData.decimal || info.decimal
      };
    });
    this.set('formattingData', formatted);
    this.setPrecisionMap();
  }

  async setExchangeRateMap (base: string) {
    if (!this.exchangeRateMap[base]) {
      this.spinnerService.startSpinner();
      const rates = await this.currencyResources.getExchangeRates(base);
      this.spinnerService.stopSpinner();
      this.set('exchangeRateMap', {
        ...this.exchangeRateMap,
        [base]: rates
      });
    }
  }

  async getExchangeRate (
    base: string,
    convertTo: string
  ): Promise<ExchangeRate> {
    await this.setExchangeRateMap(base);
    const rates = this.exchangeRateMap[base];
    const found = rates.find((item) => {
      return item.code === convertTo;
    });

    return found;
  }

  /**
   * Get Currency Options for Component
   *
   * @param selectedCurrency: currently selected currency
   * @param useCustomCurrency: custom currency setting
   * @param customCurrency: custom currency selected in config
   * @returns the currency options from the component
   */
  getCurrencyOptionsForComponent (
    selectedCurrency: string,
    useCustomCurrency: CurrencyRadioOptions,
    customCurrency: string
  ) {
    let _currencies = this.clientSettingsService.selectedCurrencies;
    if (useCustomCurrency === CurrencyRadioOptions.USE_ONE_CURRENCY && customCurrency) {
      _currencies = [{
        displayName: '',
        code: customCurrency,
        default: true
      }];
    }

    if (_currencies.length === 0) {
      _currencies = [{
        displayName: 'US Dollar',
        code: 'USD',
        default: true
      }];
    }
    if (selectedCurrency) {
      // Make sure selected currency is in list. If not, add it.
      const mapped = _currencies.map((curr) => {
        return curr.code;
      });
      if (!mapped.includes(selectedCurrency)) {
        const found = this.clientSettingsService.currencies.find((curr) => {
          return curr.code === selectedCurrency;
        });
        if (found) {
          const selectedCurrencyObj = {
            displayName: found.displayName,
            code: found.code,
            default: true
          };
          _currencies.push(selectedCurrencyObj);
        }
      }
    }

    return this.arrayHelper.sort(_currencies.map((curr) => {
      return {
        label: curr.code,
        value: curr.code
      };
    }), 'label');
  }
}


