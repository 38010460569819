import { BaseRunResult } from '../logic-builder/logic-builder.typing';
import { BaseLogicState } from '../logic-builder/logic-state.typing';

export enum FormulaEvaluationType {
  Add = 0,
  Subtract = 1,
  Divide = 2,
  Multiply = 3,
  Average = 4
}

export enum FormulaStepValueType {
  Fixed = 0,
  NestedStep = 1,
  ParentValue = 2
}

export interface FixedFormulaStepValue {
  type: FormulaStepValueType.Fixed;
  value: number;
}

export interface NestedStepFormulaValue<T> {
  type: FormulaStepValueType.NestedStep;
  value: FormulaStep<T>;
}

export interface ParentFormulaValue {
  type: FormulaStepValueType.ParentValue;
  value: string;
}

export type FormulaStepValue<T> = FixedFormulaStepValue|NestedStepFormulaValue<T>|ParentFormulaValue;

export interface ConstructiveFormulaStep<T> {
  type: FormulaEvaluationType.Multiply|FormulaEvaluationType.Add|FormulaEvaluationType.Average;
  values: FormulaStepValue<T>[];
}


export interface DestructiveFormulaStep<T> {
  type: FormulaEvaluationType.Divide|FormulaEvaluationType.Subtract;
  values: [
    FormulaStepValue<T>,
    FormulaStepValue<T>
  ];
}

export type FormulaStep<T> = DestructiveFormulaStep<T>|ConstructiveFormulaStep<T>;

export interface RootFormula<T> {
  step: FormulaStep<T>;
  property: string;
}

export type FormulaRunResult = BaseRunResult<string, number>;
export type FormulaForColumn<T> = RootFormula<T>&FormulaRunResult;
export type FormulaState<T> = BaseLogicState<FormulaForColumn<T>, string>;
