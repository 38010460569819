export default [
  {
    "culture": "ca-ES",
    "displayName": "Catalan"
  },
  {
    "culture": "cs-CZ",
    "displayName": "Czech"
  },
  {
    "culture": "cy-GB",
    "displayName": "Welsh (United Kingdom)"
  },
  {
    "culture": "da-DK",
    "displayName": "Danish"
  },
  {
    "culture": "de-DE",
    "displayName": "German"
  },
  {
    "culture": "el-GR",
    "displayName": "Greek"
  },
  {
    "culture": "en-CA",
    "displayName": "English (Canadian)"
  },
  {
    "culture": "en-GB",
    "displayName": "English (British)"
  },
  {
    "culture": "en-US",
    "displayName": "English"
  },
  {
    "culture": "es-ES",
    "displayName": "Spanish (Spain)"
  },
  {
    "culture": "es-MX",
    "displayName": "Spanish"
  },
  {
    "culture": "fr-CA",
    "displayName": "French (Canadian)"
  },
  {
    "culture": "fr-FR",
    "displayName": "French"
  },
  {
    "culture": "hi-IN",
    "displayName": "Hindi"
  },
  {
    "culture": "hu-HU",
    "displayName": "Hungarian"
  },
  {
    "culture": "it-IT",
    "displayName": "Italian"
  },
  {
    "culture": "ja-JP",
    "displayName": "Japanese"
  },
  {
    "culture": "ko-KR",
    "displayName": "Korean"
  },
  {
    "culture": "ms-MY",
    "displayName": "Malay"
  },
  {
    "culture": "nl-NL",
    "displayName": "Dutch"
  },
  {
    "culture": "pl-PL",
    "displayName": "Polish"
  },
  {
    "culture": "pt-BR",
    "displayName": "Portuguese (Brazil)"
  },
  {
    "culture": "pt-PT",
    "displayName": "Portuguese"
  },
  {
    "culture": "ro-RO",
    "displayName": "Romanian"
  },
  {
    "culture": "ru-RU",
    "displayName": "Russian"
  },
  {
    "culture": "sv-SE",
    "displayName": "Swedish"
  },
  {
    "culture": "tr-TR",
    "displayName": "Turkish"
  },
  {
    "culture": "uk-UA",
    "displayName": "Ukranian"
  },
  {
    "culture": "zh-CHS",
    "displayName": "Chinese (Simplified)"
  },
  {
    "culture": "zh-CHT",
    "displayName": "Chinese (Traditional)"
  }
]