<yc-modal
  [modalHeader]="'common:hdrComponentLimitMet' | ycI18n: 'Component Limit Met'"
  [primaryButtonText]="'common:textCreateNewPage' | ycI18n: 'Create new page'"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  (onCancel)="closeModal.emit(false)"
  (onPrimaryClick)="closeModal.emit(true)">
  <div>
    <yc-i18n
      key="common:textComponentLimitMetDesc"
      [context]="{
        maxNumber: MAX_COMPONENTS_PER_PAGE
      }"
      defaultValue="The current page has reached a maximum of __maxNumber__ components. In order to add the new component, please create a new page.">
    </yc-i18n>
  </div>
</yc-modal>