import { Pipe, PipeTransform } from '@angular/core';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({
  name: 'gcInvalidDependentPicklistsErrorMessage'
})
export class InvalidDependentPicklistsErrorMessage implements PipeTransform {

  constructor (
    private i18n: I18nService
  ) { }

  transform (
    invalidDependentPicklists: ReferenceFieldsUI.InvalidDependentPicklist[]
  ) {
    const errorMessage = this.i18n.translate(
      'common:textTheFollowingPicklists',
      {},
      'The following child picklists require the parent to be added to proceed.'
    );
    const parentText = this.i18n.translate(
      'common:textParent',
      {},
      'Parent'
    );

    return invalidDependentPicklists.reduce((acc, item) => {
      return `${acc} <li>
        ${item.fieldWithoutParent.name} (${parentText}: ${item.parentPicklist.name})
      </li>`;
    }, errorMessage);
  }
}
