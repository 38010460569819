import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { CommunicationVisibility } from '@features/communications/communications.typing';
import { ApplicationAttachmentFromApi, AttachmentType, MailMergeModalResponse } from './application-attachments.typing';

@Injectable({ providedIn: 'root' })
export class ApplicationAttachmentResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getApplicationAttachments (
    applicationId: number
  ): Promise<ApplicationAttachmentFromApi> {
    const endpoint = `api/manager/applications/${applicationId}/FileInfo`;

    return this.httpRestService.get(endpoint);
  }

  removeAttachment (
    fileUploadId: number,
    applicationId: number,
    type: AttachmentType
  ) {
    const endpoint = type === AttachmentType.EXTERNAL ?
      `api/manager/applications/${applicationId}/RemoveFileFromApplication/${fileUploadId}` :
      `api/manager/DocumentTemplate/${applicationId}/RemoveTemplatePdfFromApplication/${fileUploadId}`;

    return this.httpRestService.delete(endpoint);
  }

  attachExternalFileToApplication (
    file: File,
    applicationId: number,
    visibility: CommunicationVisibility,
    applicantCanView: boolean
  ) {
    const endpoint = `api/manager/applications/${applicationId}/ExternalAttachment?visibility=${visibility}&applicantCanView=${applicantCanView}`;

    return this.httpRestService.postFile(endpoint, file);
  }

  attachMergeDocumentToApplication (
    templateId: number,
    applicationId: number,
    visibility: CommunicationVisibility,
    applicantCanView: boolean
  ) {
    const endpoint = 'api/manager/DocumentTemplate/AddDocumentPdfToApplication';

    return this.httpRestService.post(endpoint, {
      templateId,
      applicationId,
      visibility,
      applicantCanView
    });
  }

  async getFileAccessUrlForApplicant (fileUploadId: number) {
    const endpoint = `api/portal/applications/GetAccessUrl/${fileUploadId}`;
    const response = await this.httpRestService.get<{ file: string }>(endpoint);

    return response.file;
  }

  async getFileAccessUrlForManager (
    fileUploadId: number,
    applicationId: number,
    attachmentType: AttachmentType
  ) {
    const endpoint = `api/manager/applications/${applicationId}/AccessUrl/${fileUploadId}/${attachmentType}`;
    const response = await this.httpRestService.get<{ file: string }>(endpoint);

    return response.file;
  }

  async getMergePreview (
    documentTemplateId: number,
    applicationId: number
  ) {
    const endpoint = `api/manager/DocumentTemplate/${applicationId}/CreateMergedTemplate/${documentTemplateId}`;
    const response = await this.httpRestService.post<{ file: string }>(endpoint, {});

    return response.file;
  }

  async attachMergeDocumentToApplicationBulk (
    response: MailMergeModalResponse
  ) {
    const endpoint = 'api/manager/applications/AttachMergeDocToApplications';
    const fileResponse = await this.httpRestService.post<{ file: string }>(endpoint, {
      documentTemplateId: response.documentTemplateId,
      applicationIds: response.applicationIds,
      visibility: response.documentVisibility,
      applicantCanView: response.applicantCanView
    });

    return fileResponse.file;
  }

  sendMailMergeBulk (
    response: MailMergeModalResponse
  ) {
    const endpoint = 'api/manager/applications/SendMailMergeDocumentEmail';

    return this.httpRestService.post(endpoint, {
      clientEmailTemplateId: response.emailOptions.clientEmailTemplateId,
      emailOptionsRequest: response.emailOptions,
      documentTemplateId: response.documentTemplateId,
      applicationIds: response.applicationIds,
      visibility: response.documentVisibility,
      applicantCanView: response.applicantCanView
    });
  }
}

