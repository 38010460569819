<yc-table
  [key]="'AUDIENCE_USAGE_' + audienceId"
  [rows]="rows"
  [topLevelFilters]="topLevelFilters"
  [simple]="true">
  
  <!-- Usage Type -->
  <ng-container *ycTableColumn="'common:hdrType';
    let row;">
    {{ row.usageTypeTranslated }}
  </ng-container>

  <!-- Usage Name -->
  <ng-container *ycTableColumn="'common:lblName';
    let row;">
    {{ row.usageName }}
    <small *ngIf="!!row.usageSubName"
      class="d-block">
      {{ row.usageSubName }}
    </small>
  </ng-container>

</yc-table>