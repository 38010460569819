import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { SystemTags } from './typings/system-tags.typing';

@Injectable({ providedIn: 'root' })
export class SystemTagsResources {
  private apiBase = '/api/manager/tags';

  constructor (
    private httpRestService: HttpRestService
  ) { }

  private getAndSetTagEndpointByTagType (
    tagType: SystemTags.Buckets,
    id?: string|number
  ) {
    let endpoint = this.apiBase;

    switch (tagType) {
      case SystemTags.Buckets.Application:
      case SystemTags.Buckets.Nomination:
        endpoint += '/Application';
        break;
      case SystemTags.Buckets.NonprofitProfile:
        endpoint += '/Organization';
        break;
      case SystemTags.Buckets.Payment:
        endpoint += '/Payment';
        break;
      case SystemTags.Buckets.Applicant:
        endpoint += '/Applicant';
        break;
      case SystemTags.Buckets.Budget:
        endpoint += '/Budget';
        break;
    }

    return id ? `${endpoint}/${id}` : endpoint;
  }

  private bulkAddTagEndpointByTagType (
    tagType: SystemTags.Buckets,
    id?: string|number
  ) {
    let endpoint = this.apiBase;

    switch (tagType) {
      case SystemTags.Buckets.Application:
      case SystemTags.Buckets.Nomination:
        endpoint += '/Applications';
        break;
    }

    return id ? `${endpoint}/${id}` : endpoint;
  }

  private bulkGetTagEndpointByTagType (
    tagType: SystemTags.Buckets,
    id?: string|number
  ) {
    let endpoint = this.apiBase;

    switch (tagType) {
      case SystemTags.Buckets.Application:
      case SystemTags.Buckets.Nomination:
        endpoint += '/GetBulkApplicationTags';
        break;
    }

    return id ? `${endpoint}/${id}` : endpoint;
  }

  addOrUpdateRecord (
    body: SystemTags.AddTagRequest|SystemTags.UpdateTagRequest
  ): Promise<number> {
    return this.httpRestService.post(this.apiBase, body);
  }

  getAll (): Promise<SystemTags.TagDefinitionForApi[]> {
    return this.httpRestService.get(this.apiBase + '/all');
  }

  updateRecord<T extends 'Promote'|'Enable'|'Disable'> (
    tagId: number,
    action: T
  ) {
    return this.httpRestService.get(this.apiBase + '/' + tagId + '/' + action);
  }

  removeRecord (tagId: number) {
    return this.httpRestService.delete(this.apiBase + '/' + tagId);
  }

  importTags (tagList: SystemTags.TagImportForApi[]) {
    return this.httpRestService.post(this.apiBase + '/BulkInsertUpdateTags', {
      tagList
    });
  }

  getTags (
    tagType: SystemTags.Buckets,
    entityId: string|number
  ) {
    return this.httpRestService.get<SystemTags.EntityTagFromApi[]>(
      this.getAndSetTagEndpointByTagType(tagType, entityId)
    );
  }

  setTags (
    tagType: SystemTags.Buckets,
    body: SystemTags.SetTags
  ) {
    return this.httpRestService.post(
      this.getAndSetTagEndpointByTagType(tagType),
      body
    );
  }

  bulkAddTags (
    tagIds: number[],
    relatedEntityIds: number[],
    tagType: SystemTags.Buckets
  ) {
    return this.httpRestService.post(this.bulkAddTagEndpointByTagType(tagType), {
      tagIds,
      relatedEntityIds
    });
  }

  getTagsBulk (
    relatedEntityIds: number[],
    tagType: SystemTags.Buckets
  ): Promise<SystemTags.BulkTagResponse[]> {
    return this.httpRestService.post(this.bulkGetTagEndpointByTagType(tagType), {
      relatedEntityIds
    });
  }
}

