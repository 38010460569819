import { SimpleWorkflowUser, Workflow, WorkflowDetailForUi, WorkflowManagerActions } from '@core/typings/workflow.typing';
import { SimpleStringMap } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class WorkflowState extends BaseYcState {
  readonly workflows: Workflow[];
  readonly workflowMap: Record<number, WorkflowDetailForUi> = {};
  readonly myWorkflowManagerRolesMap: SimpleStringMap<WorkflowManagerActions[]>;
  readonly myWorkflowOptions: TypeaheadSelectOption[] = [];
  readonly myWorkflowLevelOptions: TypeaheadSelectOption[] = [];
  readonly workflowUsersMap: Record<number, SimpleWorkflowUser[]> = {};
}
