import { NgModule } from '@angular/core';
import { GCCoreModule } from '@core/_core.module';
import { FormulaBuilderModule } from '@features/formula-builder/formula-builder.module';
import { LogicBuilderModule } from '@features/logic-builder/logic-builder.module';
import { CollapsibleSectionModule } from '@yourcause/common/collapsible-section';
import { DoublePicklistModule } from '@yourcause/common/double-picklist';
import { FormReferenceFieldsModule } from '../form-renderer-components/form-fields/form-reference-fields.module';
import { CanAddColumnsPipe } from '../pipes/can-add-columns.pipe';
import { BaseComponentConfigSettingsComponent } from './base-component-config-settings/base-component-config-settings.component';
import { CdtFieldVisibleOptionsComponent } from './cdt-field-visible-options/cdt-field-visible-options.component';
import { ColumnPropertiesComponent } from './column-properties/column-properties.component';
import { ComponentConfigurationModalComponent } from './component-configuration-modal/component-configuration-modal.component';
import { ComponentConfigurationTabComponent } from './component-configuration-tab/component-configuration-tab.component';
import { ConditionalLogicFormBuilderSettingsComponent } from './conditional-logic-form-builder-settings/conditional-logic-form-builder-settings.component';
import { ConditionalValueFormBuilderSettingsComponent } from './conditional-value-form-builder-settings/conditional-value-form-builder-settings.component';
import { CurrencySettingsComponent } from './currency-settings/currency-settings.component';
import { CustomJavascriptSettingsComponent } from './custom-javascript-settings/custom-javascript-settings.component';
import { DecisionRecusedSettingsComponent } from './decision-recused-settings/decision-recused-settings.component';
import { DependentPicklistOptionsSettingsComponent } from './dependent-picklist-options-settings/dependent-picklist-options-settings.component';
import { ExternalApiFormFieldSettingsComponent } from './external-api-form-field-settings/external-api-form-field-settings.component';
import { ExternalAPISelectorSettingsComponent } from './external-api-selector-settings/external-api-selector-settings.component';
import { FormulaBuilderManagerComponent } from './formula-builder-manager/formula-builder-manager.component';
import { InKindDataSettingsComponent } from './in-kind-data-settings/in-kind-data-settings.component';
import { InKindValidationSettingsComponent } from './in-kind-validation-settings/in-kind-validation-settings.component';
import { RegexValidationSettingComponent } from './regex-validation-setting/regex-validation-setting.component';
import { ReportFieldDataOptionsComponent } from './report-field-data-options-widget/report-field-data-options-widget.component';
import { TableComponentVisibleColumnsComponent } from './table-component-visible-columns/table-component-visible-columns.component';
import { ValidationBuilderManagerComponent } from './validation-builder-manager/validation-builder-manager.component';

@NgModule({
  imports: [
    GCCoreModule,
    LogicBuilderModule,
    FormulaBuilderModule,
    FormReferenceFieldsModule,
    CollapsibleSectionModule,
    DoublePicklistModule
  ],
  declarations: [
    ComponentConfigurationModalComponent,
    ComponentConfigurationTabComponent,
    DependentPicklistOptionsSettingsComponent,
    ExternalAPISelectorSettingsComponent,
    InKindValidationSettingsComponent,
    InKindDataSettingsComponent,
    DecisionRecusedSettingsComponent,
    ReportFieldDataOptionsComponent,
    RegexValidationSettingComponent,
    ValidationBuilderManagerComponent,
    FormulaBuilderManagerComponent,
    ConditionalValueFormBuilderSettingsComponent,
    ConditionalLogicFormBuilderSettingsComponent,
    CurrencySettingsComponent,
    ColumnPropertiesComponent,
    CustomJavascriptSettingsComponent,
    ExternalApiFormFieldSettingsComponent,
    CanAddColumnsPipe,
    TableComponentVisibleColumnsComponent,
    CdtFieldVisibleOptionsComponent,
    BaseComponentConfigSettingsComponent
  ],
  exports: [
    ComponentConfigurationModalComponent,
    ComponentConfigurationTabComponent,
    DependentPicklistOptionsSettingsComponent,
    ExternalAPISelectorSettingsComponent,
    InKindValidationSettingsComponent,
    InKindDataSettingsComponent,
    DecisionRecusedSettingsComponent,
    ReportFieldDataOptionsComponent,
    RegexValidationSettingComponent,
    ValidationBuilderManagerComponent,
    FormulaBuilderManagerComponent,
    ConditionalValueFormBuilderSettingsComponent,
    ConditionalLogicFormBuilderSettingsComponent,
    CurrencySettingsComponent,
    ColumnPropertiesComponent,
    CustomJavascriptSettingsComponent,
    ExternalApiFormFieldSettingsComponent,
    TableComponentVisibleColumnsComponent,
    CdtFieldVisibleOptionsComponent,
    BaseComponentConfigSettingsComponent
  ]
})
export class FormBuilderEditComponentsModule { }
