import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { EmailExtensionValidator } from '@yourcause/common';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
export interface UserAddEditGroup {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  isSSO: boolean;
  ssoId: string;
  isBbidLinked: boolean;
}
export interface UserAddEdit extends UserAddEditGroup {
  ssoId: string;
  isBbidLinked: boolean;
}

@Component({
  selector: 'gc-add-edit-user-modal',
  templateUrl: 'add-edit-user-modal.component.html',
  styleUrls: ['./add-edit-user-modal.component.scss']
})
export class AddEditUserModalComponent extends YCModalComponent<UserAddEditGroup> implements OnInit {
  @Input() existingUser: UserAddEdit;

  formGroup: TypeSafeFormGroup<UserAddEditGroup>;
  alertText = '';
  noEditsAllowed = false;
  modalHeader: string;
  createUserHeader = this.i18n.translate(
    'USERS:hdrCreateUser',
    {},
    'Create User'
  );
  editUserHeader = this.i18n.translate(
    'USERS:hdrEditUser',
    {},
    'Edit User'
  );

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private analyticsService: AnalyticsService,
    private i18n: I18nService
  ) {
    super();
  }

  ngOnInit () {
    this.formGroup = this.formBuilder.group<UserAddEditGroup>({
      firstName: [
        this.existingUser?.firstName || '',
        [Validators.required, Validators.maxLength(50)]
      ],
      lastName: [
        this.existingUser?.lastName || '',
        [Validators.required, Validators.maxLength(50)]
      ],
      jobTitle: [
        this.existingUser?.jobTitle || '',
        [Validators.required, Validators.maxLength(50)]
      ],
      email: [
        this.existingUser?.email || '', [
          Validators.required,
          EmailExtensionValidator,
          Validators.maxLength(254)
        ]
      ],
      isSSO: this.existingUser?.isSSO || false,
      ssoId: this.existingUser?.ssoId,
      isBbidLinked: this.existingUser?.isBbidLinked
    });
    this.modalHeader = this.existingUser ? this.editUserHeader : this.createUserHeader;
    this.setHelpers();
  }

  setHelpers () {
    let alertText = '';
    if (!!this.existingUser) {
      const isSsoUser = !!this.formGroup.value.isSSO || !!this.formGroup.value.ssoId;
      const isBbidLinked = this.formGroup.value.isBbidLinked;
      this.noEditsAllowed = isBbidLinked;
      if (isSsoUser && !isBbidLinked) {
        alertText = this.i18n.translate(
          'USERS:textUserIsCurrentlySSOAlert',
          {},
          'User is currently configured for SSO.  On next login, their account will be updated with information from your HR file, possibly overwriting any updates.'
        );
      } else if (isSsoUser && isBbidLinked) {
        alertText = this.i18n.translate(
          'USERS:textUserIsCurrentlySsoAndBbidAlert',
          {},
          `User is configured for SSO and Blackbaud ID (BBID). Account details (Name, Email, etc.) should be updated in both the HR file and their BBID account to keep this information in sync and prevent accidentally overwriting their most current details. Updates made to either source will appear in GrantsConnect on their next login.`
        );
      }
    }
    this.alertText = alertText;
  }

  onCancel () {
    this.closeModal.emit();
  }

  onSubmit () {
    this.closeModal.emit(this.formGroup.value);
    this.analyticsService.emitEvent({
      eventName: this.existingUser ? 'Edit user submit' : 'Add user submit',
      eventType: EventType.Click,
      extras: null
    });
  }
}
