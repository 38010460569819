import { Injectable } from '@angular/core';
import { FlyoutState } from '@core/states/flyout.state';
import { TableRepositoryFactory } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';

@AttachYCState(FlyoutState)
@Injectable({ providedIn: 'root' })
export class GcFlyoutService extends BaseYCService<FlyoutState> {

  get currentIdForFlyout () {
    return this.get('currentIdForFlyout');
  }

  get idsForFlyout () {
    return this.get('idsForFlyout');
  }

  constructor (
    private arrayHelper: ArrayHelpersService,
    private confirmAndTakeAction: ConfirmAndTakeActionService,
    private tableRepoFactory: TableRepositoryFactory,
    private i18n: I18nService
  ) {
    super();
  }

  /**
   * Set current ID for flyout
   *
   * @param id: ID to set
   */
  setCurrentIdForFlyout (id: string|number) {
    this.set('currentIdForFlyout', id);
  }

  /**
   * Set IDs for Flyout
   *
   * @param ids: IDs
   */
  setIdsForFlyout (ids: (number|string)[]) {
    this.set('idsForFlyout', ids);
  }

  /**
   * Handles the Initial Flyout Resolve
   */
  onInitialFlyoutRecord = async (resolver?: (id: string|number) => Promise<void>) => {
    if (!!resolver) {
      const {
        passed
      } = await this.confirmAndTakeAction.genericTakeAction(
        () => resolver(this.currentIdForFlyout),
        '',
        this.i18n.translate(
          'common:textErrorOpeningFlyout',
          {},
          'There was an error opening the flyout'
        )
      );

      return passed;
    }

    return true;
  };

  /**
   * Navigate to the next flyout record
   */
  onNextFlyoutRecord = async (resolver?: (id: string|number) => Promise<void>) => {
    const nextId = this.arrayHelper.getNextRecordId(
      this.currentIdForFlyout,
      this.idsForFlyout
    );
    await this.resolveAndGoToRecord(nextId, resolver);
  };

  /**
   * Navigate to the previous flyout record
   */
  onPreviousFlyoutRecord = async (resolver?: (id: string|number) => Promise<void>) => {
    const previousId = this.arrayHelper.getPreviousRecordId(
      this.currentIdForFlyout,
      this.idsForFlyout
    );
    await this.resolveAndGoToRecord(previousId, resolver);
  };

  /**
   * Resolves the data if necessary and goes to the next (or previous) flyout record
   *
   * @param recordId: Record ID to navigate to
   * @param resolver: Resolver func
   */
  async resolveAndGoToRecord (
    recordId: number|string,
    resolver?: (id: string|number) => Promise<void>
  ) {
    if (!!resolver) {
      const {
        passed
      } = await this.confirmAndTakeAction.genericTakeAction(
        () => resolver(recordId),
        '',
        this.i18n.translate(
          'common:textErrorNavigatingToNextRecord',
          {},
          'There was an error navigating to the next record'
        )
      );
      if (passed) {
        this.setCurrentIdForFlyout(recordId);  
      }
    } else {
      this.setCurrentIdForFlyout(recordId);
    }
  }

  /**
   * Sets all the info needed for the flyout
   *
   * @param record: Record to set flyout info for
   */
  setInfoForFlyout<T> (
    record: T,
    tableKey: string,
    idAttribute: keyof T,
    recordsIfNoRepoExists?: T[]
  ) {
    const repo = this.tableRepoFactory.getRepository(tableKey);
    let records: T[] = [];
    if (!!repo) {
      records = repo.clientSideFilteredRows as T[];
    } else {
      records = recordsIfNoRepoExists;
    }
    const recordIds = records.map((row) => {
      return row[idAttribute];
    }).filter((value) => {
      return !!value;
    }) as (string|number)[];
    this.setIdsForFlyout(recordIds);
    this.setCurrentIdForFlyout(record[idAttribute] as string|number);
  }

}
