<yc-modal *ngIf="formGroup"
  [ycHidden]="hideModal"
  [formGroup]="formGroup"
  [modalHeader]="'common:textAudience' | ycI18n: 'Audience'"
  [modalSubHeader]="'CONFIG:textAudienceModalDesc' | ycI18n: 'Designate the internal and external members of this audience'"
  [primaryButtonText]="'common:btnSave' | ycI18n: 'Save'"
  [primaryDisabled]="formGroup.invalid ||
    addingExternalUser ||
    selectedUsers.length === 0"
  [cancelButtonText]="'common:btnCancel' | ycI18n"
  (onCancel)="onCancel()"
  (onPrimaryClick)="onSubmit()">

  <yc-alert *ngIf="!!alertMessage"
    [alertClass]="'warning'"
    [message]="alertMessage"
    [buttonLinkText]="'common:textSeeUsage' | ycI18n: 'See usage'"
    (onButtonLinkClick)="seeAudienceUsage()">
  </yc-alert>

  <!-- Name -->
  <div class="mb-3">
    <yc-input
      name="audienceName"
      [label]="'CONFIG:textAudienceName' | ycI18n: 'Audience name'">
    </yc-input>
  </div>

  <!-- Description -->
  <div class="mb-3">
    <yc-input
      name="description"
      [label]="'common:textDescription' | ycI18n: 'Description'">
    </yc-input>
  </div>

  <gc-manage-audience-table
    [selectedUsers]="selectedUsers"
    [tableKey]="tableKey"
    (onSelectedUsersChange)="selectedUsers = $event"
    (onAddingExternalUserChange)="addingExternalUser = $event">
  </gc-manage-audience-table>
</yc-modal>