<div *ngIf="errorMessages?.length > 0"
  class="alert alert-danger">
  <div class="mb-2">
    <yc-i18n
      key="common:textPleaseFixTheFollowingErrorsBeforeSubmit"
      defaultValue="Please fix the following errors before submitting.">
    </yc-i18n>
  </div>
  <ul>
    <li *ngFor="let error of errorMessages; let index = index;"
      class="font-550"
      [ycHidden]="!showMore && ((index + 1) > showMoreMinErrors)">
      <yc-button
        [link]="true"
        [translatedText]="error.componentLabel"
        [tooltipText]="'common:textGoToComponent' | ycI18n: 'Go to component'"
        (onClick)="onGoToComponent(error)">
      </yc-button>
      <ul>
        <li>
          {{ error.errorMessage }}
        </li>
      </ul>
    </li>
  </ul>

  <yc-button *ngIf="errorMessages.length > showMoreMinErrors"
    [link]="true"
    [langKey]="showMore ? 'common:linkTextShowLess' : 'common:linkTextShowMore'"
    [defaultText]="showMore ? 'Show less' : 'Show more'"
    (onClick)="showMore = !showMore">
  </yc-button>
</div>