import { Route, Routes } from '@angular/router';
import { classificationResolver } from '@core/resolvers/classification.resolver';
import { countryResolver } from '@core/resolvers/country.resolver';
import { currenciesResolver } from '@core/resolvers/currencies.resolver';
import { currencyFormattingResolver } from '@core/resolvers/currency-formatting-resolver';
import { sdgResolver } from '@core/resolvers/sdg.resolver';
import { statusResolver } from '@core/resolvers/status.resolver';
import { timeZoneResolver } from '@core/resolvers/time-zone.resolver';
import { archiveReasonCodesResolver } from '@features/archive/resolvers/archive-reason-codes.resolver';

const staticResolvers = {
  countries: countryResolver,
  classifications: classificationResolver,
  currencies: currenciesResolver,
  currencyFormatting: currencyFormattingResolver,
  timezones: timeZoneResolver,
  statuses: statusResolver,
  archiveReasonCodes: archiveReasonCodesResolver,
  sdgs: sdgResolver
};

const ApplyPortalRoute: Route = {
  path: 'apply',
  loadChildren: () => import('./app/apply-portal-routing.module').then(m => m.ApplyPortalRoutingModule)
};
const ManagerPortalRoute = {
  path: 'management',
  loadChildren: () => import('./app/management-portal-routing.module').then(m => m.ManagementPortalRoutingModule)
};
const PlatformPortalRoute = {
  path: 'platform',
  loadChildren: () => import('./app/platform-portal-routing.module').then(m => m.PlatformPortalRoutingModule)
};
export const routes: Routes = [{
  path: '',
  resolve: staticResolvers,
  children: [ApplyPortalRoute, ManagerPortalRoute, PlatformPortalRoute]
}];

