<!-- Primary Form -->
<div *ngIf="object === 'customForm'" 
  class="mb-2">
  <yc-typeahead-select
    [name]="primaryFormControlName"
    [label]="'reporting:textIWantToReportOnAppsWithSubmissionsTo' | ycI18n: 'I want to report on applications with submissions to'"
    [items]="allFormOptions"
    [disabled]="primaryFormDisabled"
    (onChange)="formChange()">
  </yc-typeahead-select>
</div>

<!-- Additional Forms -->
<div class="mb-2">
  <yc-checkbox-dropdown-filter
    [name]="formsControlName"
    [filterObjectName]="'GLOBAL:textForm' | ycI18n | lowercase"
    [filterObjectNamePlural]="'common:textForms' | ycI18n | lowercase"
    [label]="'reporting:textIAlsoWantToIncludeDataFromTheseForms2' | ycI18n: 'I also want to include data from the following forms'"
    [help]="requireFormSelection ?
      '' :
      ('reporting:textAdditonalApplicantFormsAreOptional' | ycI18n: 'Additional applicant forms are optional')"
    [options]="singleResponseFormOptions"
    [disabled]="!singleResponseFormOptions || !singleResponseFormOptions.length"
    (onChange)="formChange()">
  </yc-checkbox-dropdown-filter>
</div>

<!-- Form Tags -->
<yc-tags #tags
  [placeholder]="''"
  [secondaryPlaceholder]="''"
  [autocompleteItems]="singleResponseFormOptions"
  [name]="formsControlName"
  (onChange)="setFormOptions()">
</yc-tags>